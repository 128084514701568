import { Formik, Form } from 'formik';
import { isEmpty } from 'lodash';
import * as Yup from 'yup';

import {
  Button,
  Col,
  Input,
  Modal,
  Row,
  MultiSelectChip,
  useFormError,
} from '@perts/ui';
import validateFromYupSchema from 'utils/validateFromYupSchema';
import { useTerms } from 'components/TermsContext';

const EmailValidation = Yup.string().email();
const emailYupValidation = validateFromYupSchema(EmailValidation);

// Email validation for MultiSelectChip's `customValidation`.
const multiSelectChipValidation = (email: string) => {
  if (emailYupValidation(email)) {
    return `Email address "${email}" is not valid.`;
  } else if (email.length > 200) {
    return 'Email should be 200 characters or less.';
  }

  return undefined;
};

const validateNetworkMembersAddForm = (values) => {
  // If the email array is not empty OR
  // the current input value is not empty and is a valid email
  if (
    !isEmpty(values.emails) ||
    (!isEmpty(values.currentValue) &&
      isEmpty(emailYupValidation(values.currentValue)))
  ) {
    return {};
  }

  return {
    emails: 'At least one email address is required.',
  };
};

export type NetworkMembersAddFormValues = {
  emails: string[];
};

export type NetworkMembersAddFormProps = {
  close: () => void;
  onSubmit: (values: NetworkMembersAddFormValues) => void;
};

export const NetworkMembersAddForm = ({
  close,
  onSubmit,
}: NetworkMembersAddFormProps) => {
  const [FormError, showFormError] = useFormError();
  const terms = useTerms();

  return (
    <Modal close={close}>
      <Modal.Title className="center">
        Add Network {terms.networkMembers}
      </Modal.Title>

      <Formik
        enableReinitialize={true}
        initialValues={{
          emails: [],
          currentValue: '',
        }}
        validate={(values) => validateNetworkMembersAddForm(values)}
        onSubmit={async ({ emails = [], currentValue }) => {
          try {
            // Clear existing form error.
            await showFormError(false);

            const values = {
              emails: emails.length > 0 ? emails : [currentValue],
            };

            // Perform form onSubmit.
            await onSubmit(values);

            // Close modal on successful form onSubmit.
            close();
          } catch (error) {
            // Display form error.
            showFormError(true);
          }
        }}
      >
        {({ isSubmitting, isValid, dirty, errors, values }) => (
          <Form>
            <Input
              id="emails"
              name="emails"
              label={`${terms.networkMember} emails`}
              placeholder={`${terms.networkMember} email address`}
              labelPlacement="top"
              customValidation={multiSelectChipValidation}
              component={MultiSelectChip}
              disabled={isSubmitting}
              error={values.currentValue === '' && errors.emails}
            />

            <Row>
              <Col>
                <FormError />
              </Col>
            </Row>

            <Row>
              <Col>
                <Button
                  fullHeight
                  type="button"
                  color="secondary"
                  fullWidth
                  onClick={close}
                  disabled={isSubmitting}
                >
                  Cancel
                </Button>
              </Col>

              <Col>
                <Button
                  type="submit"
                  fullWidth
                  disabled={!isValid || !dirty || isSubmitting}
                  loading={isSubmitting}
                >
                  Add {terms.networkMember}(s)
                </Button>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
