import styled from 'styled-components/macro';

export const ReactTableHead = styled.thead`
  tr:not(:first-child) {
    th {
      padding-top: 4px;
    }
  }

  tr:not(:last-child) {
    th {
      padding-bottom: 4px;
    }
  }
`;
