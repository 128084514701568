/**
 * Standard Cancel/Save Form Buttons.
 *
 * If `close` is not provided, then it is assumed that the form is not appearing
 * within a Modal and Formik's resetForm function will instead be called.
 */

import { useFormikContext } from 'formik';
import { Button, Col, Row } from '@perts/ui';

export const FormButtons = ({
  close,
  submitButtonText,
}: {
  close?: () => void;
  submitButtonText: React.ReactNode;
}) => {
  const { dirty, isSubmitting, isValid, resetForm } = useFormikContext();

  const onClickCancel = () => {
    if (close) {
      close();
    } else {
      resetForm();
    }
  };

  return (
    <Row>
      <Col cols={6} colsSm={12}>
        <Button
          type="button"
          color="secondary"
          fullWidth
          onClick={onClickCancel}
          disabled={isSubmitting}
          fullHeight
        >
          Cancel
        </Button>
      </Col>

      <Col cols={6} colsSm={12} hAlign="flex-end">
        <Button
          type="submit"
          fullWidth
          disabled={!isValid || isSubmitting || !dirty}
          loading={isSubmitting}
          data-testid="submit-btn"
        >
          {submitButtonText}
        </Button>
      </Col>
    </Row>
  );
};
