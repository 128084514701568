import { Class } from 'models';

export function getPortalMessageFromClasses(classes: Class[]) {
  const [{ portal_message, participant_pattern }] = classes;

  const allEqualParticipantPattern = classes.every(
    (cls) => cls.participant_pattern === participant_pattern,
  );

  return allEqualParticipantPattern ? portal_message || '' : '';
}
