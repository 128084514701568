import type { TargetGroupTableRow } from '@perts/model';

export enum DatasetEntityType {
  Classroom = 'Classroom',
  Organization = 'Organization',
  Network = 'Network',
}

export type Dataset = {
  id: string;
  entity_type: DatasetEntityType;
  n_responded_participants: string;
  pct_responded: string;
  csv_download_df: any[];
  csv_download_cols: string[];
};

export type ClassDefault21Dataset = Dataset & {
  student_experience_overview: StudentExperienceOverviewRow[];
  survey_sample_overview: SurveySampleOverviewRow[];
  fidelity_honest: number | null;
  fidelity_make_better: number | null;
  metrics: MetricDataOpenResponses[];
};

export type GroupDefault21Dataset = Dataset & {
  fidelity_honest: number | null;
  fidelity_make_better: number | null;
  student_experience_overview: StudentExperienceOverviewRow[];
  survey_sample_overview: SurveySampleOverviewRow[];
  metrics: MetricData[];
  target_group_table?: TargetGroupTableRow[];
};

export type NetworkDefault21Dataset = Dataset & {
  // These types are being deprecated, and this property is removed in newer
  // versions. Leave a permissive type here until it's safe to delete.
  fidelity_child_table: any;
  metrics: MetricData[];
  student_experience_overview: StudentExperienceOverviewRow[];
  survey_sample_overview: SurveySampleOverviewRow[];
  target_group_table?: TargetGroupTableRow[];
};

export type AnyDataset =
  | ClassDefault21Dataset
  | GroupDefault21Dataset
  | NetworkDefault21Dataset;

export type StudentExperienceRow = {
  rated_positive_change_adv_race: string;
  rated_positive_change_adv_race_num: number;
  rated_positive_change_all: string;
  rated_positive_change_all_num: number;
  rated_positive_change_disadv_race: string;
  rated_positive_change_disadv_race_num: number;
  rated_positive_change_female: string;
  rated_positive_change_female_num: number;
  rated_positive_change_high_financial_stress?: string;
  rated_positive_change_high_financial_stress_num?: number;
  rated_positive_change_low_financial_stress?: string;
  rated_positive_change_low_financial_stress_num?: number;
  rated_positive_change_male: string;
  rated_positive_change_male_num: number;
  rated_positive_change_not_target_group?: string;
  rated_positive_change_not_target_group_num?: number;
  rated_positive_change_target_group?: string;
  rated_positive_change_target_group_num?: number;
  rated_positive_recent_adv_race: string;
  rated_positive_recent_adv_race_num: number;
  rated_positive_recent_all: string;
  rated_positive_recent_all_num: number;
  rated_positive_recent_disadv_race: string;
  rated_positive_recent_disadv_race_num: number;
  rated_positive_recent_female: string;
  rated_positive_recent_female_num: number;
  rated_positive_recent_high_financial_stress?: string;
  rated_positive_recent_high_financial_stress_num?: number;
  rated_positive_recent_low_financial_stress?: string;
  rated_positive_recent_low_financial_stress_num?: number;
  rated_positive_recent_male: string;
  rated_positive_recent_male_num: number;
  rated_positive_recent_not_target_group?: string;
  rated_positive_recent_not_target_group_num?: number;
  rated_positive_recent_target_group?: string;
  rated_positive_recent_target_group_num?: number;
};

export type StudentExperienceOverviewRow = StudentExperienceRow & {
  metric_label: string;
};

export type StudentExperienceChildRow = StudentExperienceRow & {
  child_entity_type: string;
  child_name: string;
  child_short_uid: string;
};

export type SurveySampleOverviewRow = {
  subset_type: string;
  subset_value?: string;
  n: string;
  pct_respondents?: string;
};

export type MetricData = {
  label: string;
  rated_positive_change_str?: string;
  rated_positive_change_num?: number;
  bar_chart_64?: string;
  bar_chart_by_question_64?: string;
  timeline_chart_64?: string;
  timeline_chart_by_question_64?: string;
  timeline_charts_64?: string[];
  // As of 2022-03-23 this data is in datasets, but not used.
  // timeline_chart_by_question_data?: TimelineChartByQuestionRow[];
};

export type TimelineChartRow = {
  cycle_ordinal: number;
  reporting_unit_name: string;
  reporting_unit_id: string;
  pct_good: number | null;
};

export type OpenResponseQuestionData = {
  question_text: string;
  responses: string[];
};

export type MetricDataOpenResponses = MetricData & {
  open_responses: OpenResponseQuestionData[];
};

export type MetricDataChildTable = MetricData & {
  student_experience_child_table: StudentExperienceChildRow[];
};

export type MetricDataTimelineTable = MetricData & {
  timeline_charts_data: TimelineChartRow[];
};

// Type guards

export const isNetworkDefault21Dataset = (
  dataset: Dataset,
): dataset is NetworkDefault21Dataset =>
  dataset.entity_type === DatasetEntityType.Network;

export const isGroupDefault21Dataset = (
  dataset: Dataset,
): dataset is GroupDefault21Dataset =>
  dataset.entity_type === DatasetEntityType.Organization;

export const isClassDefault21Dataset = (
  dataset: Dataset,
): dataset is ClassDefault21Dataset =>
  dataset.entity_type === DatasetEntityType.Classroom;
