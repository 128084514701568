import moment from 'moment';

import { Participant, ParticipantProgress } from 'models';
export const sortParticipantByStudentId = (a, b) =>
  a.student_id.localeCompare(b.student_id);

export const selectParticipantProgress = (
  participant: Participant,
  participantProgress: ParticipantProgress | undefined,
  lastRun: string,
) => {
  const pptIsNew = moment(lastRun).isBefore(moment(participant.created));

  return pptIsNew
    ? null // ppt is newer than our data, cannot know their status
    : participantProgress
    ? parseInt(participantProgress.progress, 10) // parse data
    : 0; // no data on this ppt, that means they haven't done the survey
};
