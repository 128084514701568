import React from 'react';
import styled from 'styled-components/macro';
import { css } from 'styled-components';
import { theme } from '../..';

type FixedActionbarContainerStyledProps = {
  // If true this will be the top to sticky container
  isFixedUnderTopBar?: boolean;

  // If defined this will be the top to sticky container
  top?: string;
};

type FixedActionbarContainerProps = FixedActionbarContainerStyledProps & {
  children: React.ReactNode;
};

export const FixedActionbarContainerStyled = styled.div<FixedActionbarContainerStyledProps>`
  position: sticky;
  background-color: ${theme.colors.appBackground};
  z-index: 100;
  width: fit-content;
  min-width: 100%;
  display: flex;
  flex-direction: column;

  ${({ isFixedUnderTopBar }) =>
    isFixedUnderTopBar
      ? css`
          // This bar should overlay and obscure the padding of AppContent so
          // content does not show through the padding when scrolling.

          // 1. Give the bar opaque padding matching AppContent's padding.
          padding: ${theme.units.bodyPadding} 0 ${theme.units.bodyPaddingSm};

          // 2. Shift it upward so it overlays AppContent padding.
          margin-top: -${theme.units.bodyPadding};

          // 3. Add a top rule that matches margin-top, which doesn't change
          // the position, but prevents the scrolling portion of sticky
          // behavior, making it more like fixed. Not sure why this exact
          // combination works. Shrug.
          top: -${theme.units.bodyPadding};

          // Repeat rules 1-3 for each padding size.
          @media (max-width: ${theme.units.gridBreakpointMedium}px) {
            padding: ${theme.units.bodyPaddingMd} 0;
            margin-top: -${theme.units.bodyPaddingMd};
            top: -${theme.units.bodyPaddingMd};
          }

          @media (max-width: ${theme.units.gridBreakpointSmall}px) {
            padding: ${theme.units.bodyPaddingSm} 0;
            margin-top: -${theme.units.bodyPaddingSm};
            top: -${theme.units.bodyPaddingSm};
          }
        `
      : css`
          padding-top: 5px;
          padding-bottom: ${theme.units.padding};
          top: 0;
        `};

  // Custom top
  ${({ top }) =>
    top &&
    css`
      top: ${top};
    `};
`;

export const FixedActionbarContainer = ({
  children,
  isFixedUnderTopBar = true,
  ...rest
}: FixedActionbarContainerProps) => (
  <FixedActionbarContainerStyled
    {...rest}
    isFixedUnderTopBar={isFixedUnderTopBar}
  >
    {children}
  </FixedActionbarContainerStyled>
);
