import styled from 'styled-components/macro';
import { IconSort, IconSortDown, IconSortUp } from '@perts/ui';
import { theme } from 'components';

const ReactTableSortIconStyled = styled.span<{ isSorted: boolean }>`
  color: ${theme.colors.primary};

  position: relative;
  top: 2px;
`;

type ReactTableSortIconProps = {
  canSort: boolean;
  isSorted: boolean;
  isSortedDesc: boolean;
};

export const ReactTableSortIcon = ({
  canSort,
  isSorted,
  isSortedDesc,
}: ReactTableSortIconProps) =>
  canSort ? (
    <ReactTableSortIconStyled role="img" isSorted={isSorted}>
      {!isSorted ? (
        <IconSort />
      ) : isSortedDesc ? (
        <IconSortDown />
      ) : (
        <IconSortUp />
      )}
    </ReactTableSortIconStyled>
  ) : null;
