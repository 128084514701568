import React from 'react';

import { StudentExperienceOverviewRow } from 'models';
import { ChangeText } from '../components';

// What's in props?
// https://github.com/tannerlinsley/react-table/blob/master/docs/api/useTable.md#user-content-column-object-cell

// What's in a row?
// https://github.com/tannerlinsley/react-table/blob/master/docs/api/useTable.md#user-content-row-properties

type CellProps = {
  row: { original: StudentExperienceOverviewRow };
};

export const cellChange =
  (key) =>
  ({ row }: CellProps) => {
    const rp = 'rated_positive';
    const changeDisplay = row.original[`${rp}_change_${key}`] || '';
    const changeNum = row.original[`${rp}_change_${key}_num`] || 0;

    return <ChangeText data={changeNum}>{changeDisplay}</ChangeText>;
  };
