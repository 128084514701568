// Returns location state.checked, default return is `{}` when no state.checked.

import { useLocation } from 'react-router-dom';
import { CheckedStates, ToggleStates } from '../useToggles';

export const useGetCheckedStates = () => {
  const { state = {} } = useLocation<CheckedStates | ToggleStates>();
  const { checked = {} } = state;
  return checked;
};
