import { attemptInterval, maxWait } from './config';

/**
 * Ensure that we can run JavaScript from our firebase-controlled auth domain.
 * We've seen some school networks that truncate, block, or otherwise corrupt
 * web traffic, resulting in browser errors like:
 *
 * > GET https://auth.perts.net/__/auth/iframe.js net::ERR_INCOMPLETE_CHUNKED_ENCODING 200 (OK)
 *
 * The strategy here is to load the required javascript file and make sure
 * it successfully executes and defines the `firebase` global variable.
 *
 * @param  {string} url      firebase javascript bundle
 * @return {Promise<string>} describing result
 */

export const loadFirebase = (url: string): Promise<string> =>
  new Promise((resolve) => {
    (window as any).firebase = undefined;
    let timeout;

    const resolveLater = (result) =>
      setTimeout(() => {
        resolve(result);
      }, attemptInterval);

    setTimeout(() => {
      clearTimeout(timeout);
      if ((window as any) === undefined) {
        resolve('timeout: script failed to execute');
      }
      resolve('load');
    }, maxWait);

    const unixTimestamp = Math.floor(Date.now() / 1000);
    const script = document.createElement('script');
    script.src = `${url}&nocache=${unixTimestamp}`;
    script.async = true;

    script.onload = () => {
      if ((window as any).firebase) {
        timeout = resolveLater('load');
      } else {
        timeout = resolveLater('error: failed to execute script');
      }
    };

    script.onerror = (/* event */) => {
      // Note: `onerror` provides an Event object that does not contain HTTP
      // status or message properties that would help in determining the cause
      // of the error when it's due to the HTTP request.
      timeout = resolveLater('error: failed to connect');
    };

    document.head.appendChild(script);
  });
