// https://docs.google.com/spreadsheets/d/1ZX4hcUol-_frFLfUYRnZ-UPEvpGsVOsWsJRw1g5tDBM

import keyBy from 'lodash/keyBy';

import { locales } from '../i18n';
import { CompositeMetricConfig, MetricItemConfig } from './types';

export const facultyMindsetBeliefsItemConfig: MetricItemConfig[] = [
  {
    label: 'faculty_mindset_beliefs_certain',
    likertN: 6,
    likertReverse: true,
    minGood: 5,
    prompts: {
      [locales.ENGLISH]:
        'To be honest, students have a certain amount of intelligence, ' +
        'and they really can’t do much to change it.',
    },
  },
  {
    label: 'faculty_mindset_beliefs_learn',
    likertN: 6,
    likertReverse: true,
    minGood: 5,
    prompts: {
      [locales.ENGLISH]:
        'Your intelligence is something about you that you can’t change ' +
        'very much.',
    },
  },
];

export const facultyMindsetBeliefsConfig: CompositeMetricConfig = {
  label: 'faculty-mindset-beliefs',
  items: facultyMindsetBeliefsItemConfig,
  itemIndex: keyBy(facultyMindsetBeliefsItemConfig, 'label'),
};
