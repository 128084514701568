// https://docs.google.com/spreadsheets/d/1ZX4hcUol-_frFLfUYRnZ-UPEvpGsVOsWsJRw1g5tDBM

import keyBy from 'lodash/keyBy';

import { locales } from '../i18n';
import { CompositeMetricConfig, MetricItemConfig } from './types';

export const authoritarianDisciplineBeliefsItemConfig: MetricItemConfig[] = [
  {
    label: 'authoritarian_discipline_beliefs_misbehave',
    likertN: 6,
    likertReverse: true,
    minGood: 5,
    prompts: {
      [locales.ENGLISH]:
        'When students misbehave, it’s important for me to assert my ' +
        'authority.',
    },
  },
];

export const authoritarianDisciplineBeliefsConfig: CompositeMetricConfig = {
  label: 'authoritarian-discipline-beliefs',
  items: authoritarianDisciplineBeliefsItemConfig,
  itemIndex: keyBy(authoritarianDisciplineBeliefsItemConfig, 'label'),
};
