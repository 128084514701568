import { useQueryClient, useMutation } from 'react-query';
import {
  User,
  queryKeyUsersByGroup,
  updateUsers,
  useGroupGetByParams,
  useUsersQueryByParams,
} from 'models';
import { useParams } from 'pages';
import { useCloseModal, useGetCheckedStates } from 'utils';

import { MembersPinForm } from './MembersPinForm';
import { Modal } from '@perts/ui';
import { ErrorMessageBox } from 'components/ErrorMessageBox';
import Loading from 'components/Loading';
import { getMessageFromErrors } from '@perts/util/src';
import { message } from 'antd';
import { uniqBy } from 'lodash';

export const MembersPin: React.FC = () => {
  const { groupId } = useParams();
  const checked = useGetCheckedStates();
  const closeModal = useCloseModal();

  const queryClient = useQueryClient();
  const queryUpdateFn = updateUsers;
  const queryKey = queryKeyUsersByGroup(groupId);

  const {
    data: group,
    error: groupError,
    isLoading: groupIsLoading,
    isSuccess: groupIsSuccess,
  } = useGroupGetByParams();

  // Note: Since this is the Group Members page, the users are being referred to
  // as `members`, but they actually have the `User` type here since we don't
  // need the additional `GroupMember` entity properties.
  const {
    data: members = [],
    error: membersError,
    isLoading: membersIsLoading,
    isSuccess: membersIsSuccess,
  } = useUsersQueryByParams();

  const mutation = useMutation(
    async (updatedMembers: User[]) => {
      await queryUpdateFn(updatedMembers);
    },
    {
      onMutate: (updatedMembers: User[]) => {
        // Snapshot previous.
        const previous = queryClient.getQueryData<User[]>(queryKey);

        // Optimistic update.
        const optimisticCacheValue = previous
          ? // if existing cache, combine updated with existing cache value
            uniqBy([...updatedMembers, ...members], 'uid')
          : // else, just add update to the cache
            updatedMembers;

        queryClient.setQueryData<User[]>(queryKey, optimisticCacheValue);

        // Return previous snapshot for rollbacks.
        return { previous };
      },

      onSuccess: () => {
        message.success('Pin/unpin successful.');
      },

      onError: (_err, _data, context: any) => {
        message.error('Pin/unpin error.');

        // Rollback any optimistic updates performed.
        if (context?.previous) {
          queryClient.setQueryData(queryKey, context.previous);
        }
      },

      // Always refetch after an error or success.
      onSettled: () => {
        queryClient.invalidateQueries(queryKey);
      },
    },
  );

  const isLoading = groupIsLoading || membersIsLoading;

  if (isLoading) {
    return (
      <Modal close={closeModal}>
        <Loading />
      </Modal>
    );
  }

  if (!groupIsSuccess || !membersIsSuccess) {
    return (
      <ErrorMessageBox>
        {getMessageFromErrors([groupError, membersError])}
      </ErrorMessageBox>
    );
  }

  const checkedMembers = members.filter((m) => checked[m.uid]);

  const onSubmit = async (values: User[]) => {
    await mutation.mutateAsync(values);
  };

  return (
    <MembersPinForm
      close={closeModal}
      group={group}
      members={checkedMembers}
      onSubmit={onSubmit}
    />
  );
};
