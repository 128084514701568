import { useState } from 'react';
import sortBy from 'lodash/sortBy';

import { Card, Col, HelpText, Row, Show, Text, useBroadcast } from '@perts/ui';

import { channelFullwindow, helpArticles } from 'config';
import { toArchiveGroupReportIdSettings, useParams } from 'pages';

import { ArchivedWarning } from 'components/ArchivedWarning';
import { useTerms } from 'components/TermsContext';
import {
  ChangeLegend,
  EquityGapLegend,
  getEnabledMetrics,
  MetricSection,
  removeDisabledMetricsFromDataset,
  ReportSettingsLink,
  ReportStyled,
  SurveyFidelityAverage,
} from '../components';
import { Group, GroupDefault21Dataset, Metric, Program } from 'models';
import { usePermissions } from 'utils';

import {
  ReportNavigation,
  StudentExperienceTable,
  StudentExperienceOverviewTable,
  SurveySampleOverviewTable,
  TargetGroupTable,
} from '../';

type Props = {
  archived: boolean;
  programMetrics: Metric[];
  reportMetrics: Metric[];
  group: Group;
  dataset: GroupDefault21Dataset;
  program: Program;
  reportDate: string;
};

export const GroupReportDefault21Render = ({
  archived,
  programMetrics,
  reportMetrics,
  group,
  dataset: unfilteredDataset,
  program,
  reportDate,
}: Props) => {
  const { reportId } = useParams();
  const { mayGroupEdit } = usePermissions();
  const terms = useTerms();

  const [isFullwindow, setFullwindow] = useState(false);
  const { listen } = useBroadcast(channelFullwindow);
  listen((shouldExpand) => setFullwindow(shouldExpand));

  const enabledMetrics = getEnabledMetrics(group, reportMetrics);

  const dataset = removeDisabledMetricsFromDataset<GroupDefault21Dataset>(
    unfilteredDataset,
    enabledMetrics,
  );

  return (
    <ReportStyled fullscreen={isFullwindow}>
      <ReportNavigation metrics={enabledMetrics} program={program} />

      <Show when={archived}>
        <ArchivedWarning isReport={true} />
      </Show>

      <Row>
        <Col cols={1}></Col>
        <Col cols={10}>
          <h1>
            {program.name} {terms.group} Report for {group.name}
          </h1>
        </Col>
        <Col cols={1} colsSm={12} vAlign="center" hAlign="center">
          <Show when={mayGroupEdit}>
            <ReportSettingsLink
              enabledMetrics={enabledMetrics}
              reportMetrics={reportMetrics}
              to={toArchiveGroupReportIdSettings(group.uid, reportId)}
            />
          </Show>
        </Col>
      </Row>
      <Row>
        <Col cols={1}></Col>
        <Col cols={10}>
          <h2 id="student-experience-overview">
            {terms.participant} Experience Overview
          </h2>
        </Col>
        <Col cols={1}></Col>
      </Row>
      <Card>
        <Card.Content>
          <Row>
            <Col cols={6}>
              <HelpText articleId={helpArticles.studentExperienceOverviewTable}>
                How Can I Use the {terms.participant} Experience Overview?
              </HelpText>
            </Col>
            <Col cols={6} hAlign="flex-end">
              <ChangeLegend />
            </Col>
          </Row>
          <StudentExperienceOverviewTable
            data={dataset.student_experience_overview}
            metrics={programMetrics}
            nResponded={dataset.n_responded_participants}
            pctResponded={dataset.pct_responded}
            reportDate={reportDate}
          />
        </Card.Content>
      </Card>

      <h2 id="equity-overview">Equity Overview</h2>

      <Card>
        <Card.Content>
          <Row>
            <Col cols={6}>
              <HelpText articleId={helpArticles.equityOverviewTable}>
                How Can I Use the Equity Overview?
              </HelpText>
            </Col>
            <Col cols={6} hAlign="flex-end">
              <EquityGapLegend />
            </Col>
          </Row>
          <StudentExperienceTable
            data={dataset.student_experience_overview}
            metrics={programMetrics}
            nResponded={dataset.n_responded_participants}
            pctResponded={dataset.pct_responded}
            reportDate={reportDate}
          />
        </Card.Content>
      </Card>

      <h2 id="survey-response-rates">Survey Response Rates</h2>

      <Card>
        <Card.Content>
          <Row>
            <Col>
              <Text as="h3">Participation by Subpopulation</Text>
            </Col>
          </Row>
          <Row>
            <Col>
              <HelpText
                articleId={helpArticles.participationBySubpopulationTable}
              >
                How Can I Use the Participation by Subpopulation Table?
              </HelpText>
            </Col>
          </Row>
          <SurveySampleOverviewTable
            data={dataset.survey_sample_overview}
            reportDate={reportDate}
            orgId={group.short_uid}
          />
        </Card.Content>
      </Card>

      {/* applies to GROUP reports */}
      {/* applies to NETWORK reports */}
      {/* Commented out per https://github.com/PERTS/perts/issues/529 */}
      {/*
    <Card>
      <Card.Content>
        <Row>
          <Col>
            <Text as="h3">Participation by {childTerm}</Text>
          </Col>
        </Row>
        <Row>
          <Col>
            <HelpText articleId={helpArticles.participationByChildTable}>
              How Can I Use the Participation Table?
            </HelpText>
          </Col>
        </Row>
        <ChildParticipationTable
          childTerm={childTerm}
          data={dataset.child_participation_table}
          reportDate={reportDate}
        />
      </Card.Content>
    </Card>
    */}

      {/* applies to GROUP reports */}
      {/* applies to NETWORK reports */}
      <h2 id="survey-fidelity">Survey Fidelity</h2>

      <Card>
        <Card.Content>
          <SurveyFidelityAverage
            fidelityMakeBetter={dataset.fidelity_make_better}
            fidelityHonest={dataset.fidelity_honest}
          />
        </Card.Content>
      </Card>

      {sortBy(reportMetrics, 'name').map((metric) => {
        const metricData = dataset.metrics.find(
          (md) => metric.label === md.label.replace(/_/g, '-'),
        );

        return metricData ? (
          <MetricSection
            metric={metric}
            metricData={metricData}
            key={metric.label}
          />
        ) : null;
      })}

      {dataset.target_group_table && (
        <>
          {/* applies to GROUP reports */}
          {/* applies to NETWORK reports */}
          <h2 id="focal-group-summary">Focal Group Summary</h2>

          <Card>
            <Card.Content>
              <Row>
                <Col>
                  <HelpText articleId={helpArticles.targetGroupSummary}>
                    How Can I Use the Focal Group Summary?
                  </HelpText>
                </Col>
              </Row>
              <TargetGroupTable data={dataset.target_group_table} />
            </Card.Content>
          </Card>
        </>
      )}
    </ReportStyled>
  );
};
