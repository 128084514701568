// Returns a react-query mutation for deletes.

// Assumptions:
// - The deletes will be performed on an array of entities.
// - The queryKey points to a cache storing an array of entities.

// TODO
// - Can handle single entity deletes.

// See for more details:
//
// - Mutations
//   https://react-query.tanstack.com/guides/mutations
// - Optimistic Updates
//   https://react-query.tanstack.com/guides/optimistic-updates
// - Query Cancellation
//   https://react-query.tanstack.com/guides/query-cancellation

import { QueryKey, useMutation, useQueryClient } from 'react-query';
import { message } from 'antd';
import { Entity } from 'models';

type Options = {
  // If true, wait until success before performing the optimistic update. This
  // is useful when the remove action is initiated in a dialog/modal so that the
  // entities aren't removed from the list until the dialong/modal is closed.
  waitForSuccess?: boolean;

  // Toast message to display on successful update.
  toastSuccessMsg?: string;

  // Toast message to display on error.
  toastErrorMsg?: string;
};

// The form won't be providing values in the case of deletions.
type FormValuesType = {};

export function useMutationDelete<EntityType extends Entity>(
  // The delete function.
  // This function will be provided the `entitiesToDelete` array.
  deleteFn: (entities: EntityType[]) => void,

  // Array of entities to delete.
  entitiesToDelete: EntityType[],

  // react-query Query Key
  // https://react-query.tanstack.com/guides/query-keys
  queryKey: QueryKey,
  { waitForSuccess = false, toastSuccessMsg, toastErrorMsg }: Options = {},
) {
  const queryClient = useQueryClient();

  type ContextType = {
    previous: EntityType[];
  };

  // Performs the query cache update. Returns the previous value found in the
  // react-query cache so that it can be used for rollbacks.
  const updateQueryCache = async () => {
    // Cancel any outgoing refetches
    // (so they don't overwrite our optimistic update)
    await queryClient.cancelQueries(queryKey);

    // Snapshot the previous value.
    const previous = queryClient.getQueryData<EntityType[]>(queryKey);

    // Update: remove deleted items from cache.
    const idsToDelete = entitiesToDelete.map((e) => e.uid);

    const values = previous
      ? previous.filter((entity) => !idsToDelete.includes(entity.uid))
      : [];

    queryClient.setQueryData<EntityType[]>(queryKey, values);

    return previous;
  };

  return useMutation<EntityType, Error, FormValuesType, ContextType>(
    // Mutation function
    // @ts-ignore
    async function mutateFn() {
      await deleteFn(entitiesToDelete);
    },
    {
      onMutate: async () => {
        if (waitForSuccess) {
          return {};
        }

        const previous = await updateQueryCache();

        // Return a context object with the snapshot value.
        return { previous };
      },
      onSuccess: () => {
        // Pop a success toast, if a message was provided.
        toastSuccessMsg && message.success(toastSuccessMsg);

        if (waitForSuccess) {
          updateQueryCache();
        }
      },
      onError: (err, formValues, context) => {
        // Pop an error toast, if a message was provided.
        toastErrorMsg && message.error(toastErrorMsg);

        // Rollback to previous if there's an error.
        if (context?.previous) {
          queryClient.setQueryData<EntityType[]>(queryKey, context.previous);
        }
      },
      onSettled: () => {
        // Invalidate cache to force a requery.
        queryClient.invalidateQueries(queryKey);
      },
    },
  );
}
