import { Metric } from '../';
import { query } from 'services/triton/metrics';

// -----------------------------------------------------------------------------
//   API Functions
// -----------------------------------------------------------------------------

// Query all of the metrics

type MetricsQuery = () => Promise<Metric[]>;

export const metricsQuery: MetricsQuery = query;
