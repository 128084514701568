import { useContext } from 'react';
import { message } from 'antd';
import { useQueryClient } from 'react-query';

import {
  Participant,
  updateParticipant,
  queryKeyParticipantsByClass,
  invalidateUpdateParticipants,
} from 'models';
import { useParams } from 'pages';

import { ROSTER_ID_MIN_LENGTH } from 'config';

import { Row, Col, Show, EditInPlaceForm } from '@perts/ui';
import TermsContext from 'components/TermsContext';
import { getMessageFromErrors } from '@perts/util';

type ParticipantCardProps = {
  // useToggles Toggle JSX
  //   Example: <Toggle id={member.uid} />
  toggle: React.ReactElement;

  // Participant to be displayed.
  participant: Participant;

  // If true, the target group text is shown
  maySeeTargetGroup: boolean;
};

export const ParticipantCard = ({
  participant,
  toggle,
  maySeeTargetGroup,
}: ParticipantCardProps) => {
  const { classId } = useParams();
  const terms = useContext(TermsContext);
  const queryClient = useQueryClient();

  const queryInvalidateFn = () =>
    invalidateUpdateParticipants(queryClient, classId);

  const uniqComparator = (previous, current) => previous.uid === current.uid;

  return (
    <Row alignItems="center">
      <Col cols={1}>{toggle}</Col>
      <Col data-uid={participant.uid} cols={7}>
        <EditInPlaceForm<Participant>
          name="student_id"
          label={`Edit in place ${participant.student_id}`}
          value={participant}
          emptyValueMessage={`No ${terms.participant.toLowerCase()} id set`}
          queryKey={queryKeyParticipantsByClass(classId)}
          queryUpdateFn={updateParticipant}
          queryInvalidateFn={queryInvalidateFn}
          uniqComparator={uniqComparator}
          successFn={() =>
            message.success(
              `Successfully updated ${terms.participant.toLowerCase()}.`,
            )
          }
          errorFn={(error) => {
            const e = error ? getMessageFromErrors([error]) : '';
            if (e.includes('Duplicate entry')) {
              message.error('Duplicate ID');
            } else {
              message.error(
                `There was an error updating the ${terms.participant.toLowerCase()}.`,
              );
            }
          }}
          minLength={ROSTER_ID_MIN_LENGTH}
        />
      </Col>
      <Col cols={2} hAlign="center">
        <Show when={!participant.verified}>
          <span>yes</span>
        </Show>
      </Col>
      <Show when={maySeeTargetGroup && participant.in_target_group}>
        <Col cols={2} hAlign="center">
          <span>yes</span>
        </Col>
      </Show>
    </Row>
  );
};
