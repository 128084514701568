import { useEffect, useMemo } from 'react';
import {
  useTable,
  useExpanded,
  useFilters,
  usePagination as usePaginationHook,
} from 'react-table';
import { Tooltip } from 'antd';
import { omit } from 'lodash';

import { Col, Row, Show } from '@perts/ui';

import {
  ReactTable,
  ReactTableFooter,
  ReactTablePagination,
  ReactTableTd,
  ReactTableTh,
  ReactTableTr,
  ReactTableWrap,
} from 'components';
import { DEFAULT_PAGE_SIZE, EXPERIENCE_GAP_THRESHOLD } from 'config';
import type { DemographicRow } from '../types';
import {
  TdChangeFromStart,
  TdMetric,
  TdRatedPositively,
} from './DisaggregationTableStyled';
import { disaggregationSubrowSort } from './disaggregationSubrowSort';
import { DisaggregationTableFilter } from './DisaggregationTableFilter';
import { DisaggregationTableGapCheckbox } from './DisaggregationTableGapCheckbox';

type DisaggregationTableProps = {
  data: DemographicRow[];
  gapFiltering?: {
    checked: boolean;
    disabled: boolean;
    onChange: (args: any) => void;
  };
  primaryHeader: string;
  usePagination?: boolean;
  useTextFiltering?: boolean;
  showTextAndGapFiltering?: boolean;
};

export const DisaggregationTable = ({
  data,
  gapFiltering,
  primaryHeader,
  usePagination = false,
  useTextFiltering = false,
  showTextAndGapFiltering = true,
}: DisaggregationTableProps) => {
  const tableData = useMemo(
    () =>
      data
        .filter((row) => row.byAttribute.length > 0)
        .map((row) => {
          // Make sure the subrows are in a predictable order.
          // The first group is "All" and, according to react table, is not a
          // "sub row", so filter it out here.
          const [subrowAll, ...subrowOthers] = [...row.byAttribute].sort(
            disaggregationSubrowSort,
          );

          return {
            ...omit(row, 'byAttribute'),

            // Add helpers for UI.
            expanded: true,
            isFirstGroupRow: true,
            isLastGroupRow: subrowOthers.length === 0,
            disaggregateGroupName: `${subrowAll.attributeValueName} (${row.sampleSize})`,

            // Build subrows. Structure important for filtering and paging.
            subRows: subrowOthers.map((subrow, index) => ({
              ...subrow,

              // Although not displayed, subrows must have a value here so the
              // filter can target them. Otherwise subrows would be hidden when
              // filtering to a valid name.
              name: row.name,

              // Add helpers for UI.
              expanded: false,
              isFirstGroupRow: false,
              isLastGroupRow: index === subrowOthers.length - 1,
              disaggregateGroupName: (
                <Tooltip
                  title={subrow.attributeValueName}
                  trigger={['hover', 'click']}
                >
                  {`${subrow.attributeValueShortName} (${subrow.sampleSize})`}
                </Tooltip>
              ),
            })),
          };
        }),
    [data],
  );

  const columns = useMemo(
    () => [
      {
        Header: primaryHeader,
        accessor: 'name',
        align: 'left',
      },
      {
        Header: 'Responses',
        accessor: 'disaggregateGroupName',
        align: 'left',
        disableFilters: true,
      },
      {
        Header: 'Rated Positively on Last Survey',
        accessor: 'ratedPositively',
        align: 'center',
        disableFilters: true,
      },
      {
        Header: '± From First Survey',
        accessor: 'cumulativeChange',
        align: 'center',
        disableFilters: true,
      },
    ],
    [primaryHeader],
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    // from useFilters
    rows: rowsFiltered,
    // pagination
    page,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    setFilter,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data: tableData,
      // All subrows will always be expanded. The `useExpanded` hook is only
      // being used in order to provide the subrows grouping needed by the
      // usePagination hook.
      // https://react-table-v7.tanstack.com/docs/api/useExpanded
      manualExpandedKey: 'expanded',

      // Keep subpopulation group rows with their parent "all" row.
      // https://react-table-v7.tanstack.com/docs/api/usePagination
      paginateExpandedRows: false,

      initialState: {
        // Using Infinity as a page size doesn't work :-(
        pageSize: usePagination ? DEFAULT_PAGE_SIZE : 1000,
      },

      // Prevent react-query stale prevention updates from reseting the filter.
      autoResetFilters: false,
    },
    useExpanded,
    useFilters,
    // The usePagination plugin hook must be placed after useFilters.
    usePaginationHook,
  );

  useEffect(() => {
    /**
     * If the advanced filter is not visible, clear the text filter.
     */
    if (!showTextAndGapFiltering) {
      setFilter(headerGroups[0].headers[0].id, '');
    }
  }, [headerGroups, setFilter, showTextAndGapFiltering]);

  return (
    <>
      <Show
        when={
          showTextAndGapFiltering ?? (useTextFiltering || Boolean(gapFiltering))
        }
      >
        <Row>
          <Col cols={8} shrink={false}>
            <DisaggregationTableFilter
              by={primaryHeader}
              column={headerGroups[0].headers[0]}
              setFilter={setFilter}
            />
          </Col>
          {gapFiltering && (
            <Col cols={4} vAlign="flex-end" hAlign="flex-end" shrink={false}>
              <DisaggregationTableGapCheckbox
                checked={gapFiltering.checked}
                disabled={gapFiltering.disabled}
                label={`Filter to equity gaps ≥ ${EXPERIENCE_GAP_THRESHOLD}`}
                onChange={gapFiltering.onChange}
              />
            </Col>
          )}
        </Row>
      </Show>

      <ReactTableWrap>
        <ReactTable {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <ReactTableTh
                    {...column.getHeaderProps([{ align: column.align }])}
                  >
                    {column.render('Header')}
                  </ReactTableTh>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);

              return (
                <ReactTableTr
                  {...row.getRowProps()}
                  isFirstGroupRow={row.original.isFirstGroupRow}
                  isLastGroupRow={row.original.isLastGroupRow}
                >
                  <TdMetric
                    cell={row.cells[0]}
                    isComposite={row.original.isComposite}
                  />
                  <ReactTableTd cell={row.cells[1]} />
                  <TdRatedPositively cell={row.cells[2]} />
                  <TdChangeFromStart cell={row.cells[3]} />
                </ReactTableTr>
              );
            })}
          </tbody>
        </ReactTable>

        {usePagination && (
          <ReactTableFooter>
            <ReactTablePagination
              canNextPage={canNextPage}
              canPreviousPage={canPreviousPage}
              gotoPage={gotoPage}
              nextPage={nextPage}
              pageCount={pageCount}
              pageIndex={pageIndex}
              pageSize={pageSize}
              previousPage={previousPage}
              setPageSize={setPageSize}
              totalRows={rowsFiltered.length}
            />
          </ReactTableFooter>
        )}
      </ReactTableWrap>
    </>
  );
};
