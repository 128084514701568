import React from 'react';
import styled from 'styled-components/macro';

import { Card, Col, Logo, Row } from '@perts/ui';

const AuthWrapperPage = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: auto;
  height: auto;

  display: flex;
  justify-content: center;
  align-items: center;
`;

const AuthWrapperContainer = styled.div`
  width: 400px;

  p {
    margin: 0;
  }
`;

const AuthWrapperFooter = styled(Card.Content)`
  text-align: center;

  > :not(:first-child) {
    margin-top: 8px;
  }
`;

const AuthWrapper = (props) => {
  const { children, footer } = props;

  return (
    <AuthWrapperPage className="LaunchPage">
      <AuthWrapperContainer>
        <Card flat>
          <Card.Content>
            <Row>
              <Col hAlign="center">
                <Logo variant="color" />
              </Col>
            </Row>
            <Row className="center">
              <Col>{children}</Col>
            </Row>
          </Card.Content>
          {footer && <AuthWrapperFooter>{footer}</AuthWrapperFooter>}
        </Card>
      </AuthWrapperContainer>
    </AuthWrapperPage>
  );
};

export default AuthWrapper;
