import { differenceBy, uniqBy } from 'lodash';
import { idsStringToArray } from './idsStringToArray';
import {
  isParticipantIdMinLength,
  isParticipantIdUnique,
  isRosterRuleFollowed,
} from './validateFunctions';
import { validateItems } from './validateItems';
import { Class } from 'models';

export const parseStudentIds = (
  cls: Class,
  initialIdsStr: string,
  currentIdsStr: string,
) => {
  const participantIdsInitial = idsStringToArray(initialIdsStr);
  const participantIdsCurrent = idsStringToArray(currentIdsStr);

  // Error level, invalid here won't be added.
  const { valid: validIds, invalid: invalidIds } = validateItems(
    participantIdsCurrent,
    isParticipantIdMinLength,
  );

  const uniqueValidIds = uniqBy(validIds, 'stripped_student_id');

  const addingIds = differenceBy(
    uniqueValidIds,
    participantIdsInitial,
    'stripped_student_id',
  );
  const removingIds = differenceBy(
    participantIdsInitial,
    uniqueValidIds,
    'stripped_student_id',
  );

  // Warning level, invalid here will be added.
  const { invalid: invalidUnique } = validateItems(
    participantIdsCurrent,
    isParticipantIdUnique,
  );

  // Warning level, invalid here will be added.
  const { invalid: invalidRosterRule } = validateItems(
    participantIdsCurrent,
    isRosterRuleFollowed(cls.participant_pattern),
  );

  return {
    validIds,
    invalidIds,
    addingIds,
    removingIds,
    invalidUnique,
    invalidRosterRule,
  };
};
