/* eslint-disable */
import React from 'react';
import styled from 'styled-components/macro';

// Ascend @todo: places images at these paths and uncomment, when available.
// import srcBelongingCertainty from '../../assets/images/metrics/belonging_certainty.png';
// import srcIdentitySafety from '../../assets/images/metrics/identity_safety.png';
// import srcInstitutionalGms from '../../assets/images/metrics/institutional_gms.png';
// import srcSelfEfficacy from '../../assets/images/metrics/self_efficacy.png';
// import srcSocialBelonging from '../../assets/images/metrics/social_belonging.png';
// import srcSocialConnectedness from '../../assets/images/metrics/social_connectedness.png';
// import srcTrustFairness from '../../assets/images/metrics/trust_fairness.png';

// Catalyze @todo: places images at these paths and uncomment, when available.
// import srcCollectiveVision from '../../assets/images/metrics/collective_vision.png';
// import srcInclusiveEmpowerment from '../../assets/images/metrics/inclusive_empowerment.png';
// import srcLearningCulture from '../../assets/images/metrics/learning_culture.png';
// import srcSupportiveLeadership from '../../assets/images/metrics/supportive_leadership.png';
// import srcTransformativeEquity from '../../assets/images/metrics/transformative_equity.png';
// import srcTrustingCommunity from '../../assets/images/metrics/trusting_community.png';

// Elevate
import srcAffirmingIdentities from '../../assets/images/metrics/affirming_identities.png';
import srcClassroomCommunity from '../../assets/images/metrics/classroom_community.png';
import srcFeedbackForGrowth from '../../assets/images/metrics/feedback_for_growth.png';
import srcLearningGoals from '../../assets/images/metrics/learning_goals.png';
import srcMeaningfulWork from '../../assets/images/metrics/meaningful_work.png';
import srcStudentVoice from '../../assets/images/metrics/student_voice.png';
import srcSupportiveTeaching from '../../assets/images/metrics/supportive_teaching.png';
import srcTeacherCaring from '../../assets/images/metrics/teacher_caring.png';
import srcWellOrganizedClass from '../../assets/images/metrics/well_organized_class.png';

export const MetricLogoStyled = styled.img``;

const defaultAlt = 'learning condition logo';

// Ascend
// export const LogoBelongingCertainty = () => (
//   <MetricLogoStyled src={srcBelongingCertainty} alt={defaultAlt} />
// );

// export const LogoIdentitySafety = () => (
//   <MetricLogoStyled src={srcIdentitySafety} alt={defaultAlt} />
// );

// export const LogoInstitutionalGms = () => (
//   <MetricLogoStyled src={srcInstitutionalGms} alt={defaultAlt} />
// );

// export const LogoSelfEfficacy = () => (
//   <MetricLogoStyled src={srcSelfEfficacy} alt={defaultAlt} />
// );

// export const LogoSocialBelonging = () => (
//   <MetricLogoStyled src={srcSocialBelonging} alt={defaultAlt} />
// );

// export const LogoSocialConnectedness = () => (
//   <MetricLogoStyled src={srcSocialConnectedness} alt={defaultAlt} />
// );

// export const LogoTrustFairness = () => (
//   <MetricLogoStyled src={srcTrustFairness} alt={defaultAlt} />
// );

// Catalyze
// export const LogoCollectiveVision = () => (
//   <MetricLogoStyled src={srcCollectiveVision} alt={defaultAlt} />
// );

// export const LogoInclusiveEmpowerment = () => (
//   <MetricLogoStyled src={srcInclusiveEmpowerment} alt={defaultAlt} />
// );

// export const LogoLearningCulture = () => (
//   <MetricLogoStyled src={srcLearningCulture} alt={defaultAlt} />
// );

// export const LogoSupportiveLeadership = () => (
//   <MetricLogoStyled src={srcSupportiveLeadership} alt={defaultAlt} />
// );

// export const LogoTransformativeEquity = () => (
//   <MetricLogoStyled src={srcTransformativeEquity} alt={defaultAlt} />
// );

// export const LogoTrustingCommunity = () => (
//   <MetricLogoStyled src={srcTrustingCommunity} alt={defaultAlt} />
// );

// Elevate
export const LogoAffirmingIdentities = () => (
  <MetricLogoStyled
    src={srcAffirmingIdentities}
    alt="two figures superimposed on stars"
  />
);

export const LogoClassroomCommunity = () => (
  <MetricLogoStyled
    src={srcClassroomCommunity}
    alt="colorful hands joined around a table"
  />
);

export const LogoFeedbackForGrowth = () => (
  <MetricLogoStyled
    src={srcFeedbackForGrowth}
    alt="teacher giving feedback to student"
  />
);

export const LogoLearningGoals = () => (
  <MetricLogoStyled
    src={srcLearningGoals}
    alt="student icons on a bar graph with a rising trend"
  />
);

export const LogoMeaningfulWork = () => (
  <MetricLogoStyled
    src={srcMeaningfulWork}
    alt="student reading with fire icon"
  />
);

export const LogoStudentVoice = () => (
  <MetricLogoStyled
    src={srcStudentVoice}
    alt="hands of many colors raised up"
  />
);

export const LogoSupportiveTeaching = () => (
  <MetricLogoStyled
    src={srcSupportiveTeaching}
    alt="student icons held by a supportive hand"
  />
);

export const LogoTeacherCaring = () => (
  <MetricLogoStyled
    src={srcTeacherCaring}
    alt="teacher with students and heart icon"
  />
);

export const LogoWellOrganizedClass = () => (
  <MetricLogoStyled
    src={srcWellOrganizedClass}
    alt="teacher and students superimposed on meshing gears"
  />
);

type LogoComponent = () => JSX.Element;

export const metricLogoIndex: { [label: string]: LogoComponent } = {
  // Ascend
  // 'belonging-certainty': LogoBelongingCertainty,
  // 'identity-safety': LogoIdentitySafety,
  // 'institutional-gms': LogoInstitutionalGms,
  // 'self-efficacy': LogoSelfEfficacy,
  // 'social-belonging': LogoSocialBelonging,
  // 'social-connectedness': LogoSocialConnectedness,
  // 'trust-fairness': LogoTrustFairness,

  // Catalyze
  // 'collective-vision': LogoCollectiveVision,
  // 'inclusive-empowerment': LogoInclusiveEmpowerment,
  // 'learning-culture': LogoLearningCulture,
  // 'supportive-leadership': LogoSupportiveLeadership,
  // 'transformative-equity': LogoTransformativeEquity,
  // 'trusting-community': LogoTrustingCommunity,

  // Elevate
  'affirming-identities': LogoAffirmingIdentities,
  'classroom-community': LogoClassroomCommunity,
  'feedback-for-growth': LogoFeedbackForGrowth,
  'learning-goals': LogoLearningGoals,
  'meaningful-work': LogoMeaningfulWork,
  'student-voice': LogoStudentVoice,
  'supportive-teaching': LogoSupportiveTeaching,
  'teacher-caring': LogoTeacherCaring,
  'well-organized-class': LogoWellOrganizedClass,
};

const DefaultLogo = () => null;

export const getMetricLogo = (metricLabel: string): LogoComponent =>
  metricLogoIndex[metricLabel] || DefaultLogo;
