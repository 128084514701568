import { useContext } from 'react';
import { useParams } from 'react-router-dom';

import {
  Actionbar,
  Button,
  Col,
  IconAdd,
  IconChevronRight,
  Row,
  Show,
  Display,
} from '@perts/ui';
import {
  PagesParams,
  toGroupClassesAdd,
  toGroupClassesDefaultSettings,
} from 'pages';
import TermsContext from 'components/TermsContext';
import { useOpenModal } from 'utils';

export const ClassesActionbarAdd = ({
  mayAddClasses,
  mayEditDefaultSettings,
  filterComponent,
  SortByComponent,
}) => {
  const { groupId } = useParams<PagesParams>();
  const terms = useContext(TermsContext);

  const openModal = useOpenModal();

  return (
    <Actionbar paddingLeft={0}>
      <Row justifyContent="space-between" alignItems="flex-end">
        <Col colsLg={12} orderLg={2}>
          <Row>
            <Col colsSm={12} cols={5}>
              {filterComponent}
            </Col>
            <Col shrink vAlign="flex-end">
              <SortByComponent anchor="right" />
            </Col>
          </Row>
        </Col>
        <Col colsLg={12} orderLg={1} shrink vAlign="flex-end">
          <Row justifyContent="flex-end">
            <Show when={mayAddClasses}>
              <Col colsLg={6} shrink>
                <Button
                  onClick={() => openModal(toGroupClassesAdd(groupId))}
                  iconLeft={
                    <Display whenMd whenLg whenXl display="contents">
                      <IconAdd />
                    </Display>
                  }
                  color="secondary"
                  fullWidth
                  fullHeight
                >
                  Create {terms.class}
                </Button>
              </Col>
            </Show>
            <Show when={mayEditDefaultSettings}>
              <Col colsLg={6} shrink>
                <Button
                  to={toGroupClassesDefaultSettings(groupId)}
                  iconRight={
                    <Display whenMd whenLg whenXl display="contents">
                      <IconChevronRight />
                    </Display>
                  }
                  color="secondary"
                  fullWidth
                  fullHeight
                >
                  Default Settings
                </Button>
              </Col>
            </Show>
          </Row>
        </Col>
      </Row>
    </Actionbar>
  );
};
