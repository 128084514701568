/**
 * Convert a decimal into an integer string or invalid indicator. Does
 * NOT include a percent sign. Used to display percentage point differences.
 * For example the difference between 15% and 10% (stored as 0.15 and 0.1) is
 * "5".
 * @param {number} x a decimal to convert
 * @returns {string} integer string or em dash.
 */
export const formatPercentagePoints = (x: number): string =>
  isNaN(x) || !isFinite(x) ? '—' : String(Math.round(x * 100));
