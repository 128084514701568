// In order to tightern up the spacing on the Home page, the Card component
// needs to lose its margin-bottom. Layout components should handle layout
// margins and padding so that components like Card can work anywhere.
//
// Instead of
//   <Card />
//   <Card />
//
// We should use layout components like:
//   <Row><Col><Card /></Col></Row>
//   <Row><Col><Card /></Col></Row>
//
// Updating all uses of Card throughout the app would take more time then we
// have with launch coming up, so this serves as a temporary fix for just the
// Home page.

import styled from 'styled-components/macro';
import theme from 'components/theme';
import { CardStyled } from '@perts/ui/src/components/Card/CardStyled';

export const HomeTemporaryStyles = styled.div`
  ${CardStyled} {
    margin-bottom: 0;
  }

  /* Divider between Network and Group listings. */
  hr {
    margin-top: ${theme.units.paddingLg};
    margin-bottom: ${theme.units.paddingLg};

    border: 1px solid ${theme.colors.grayLight};
    border-bottom: 0;
  }
`;
