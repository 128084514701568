import { useContext } from 'react';
import { Formik, Form } from 'formik';
import { Network, Group } from 'models';
import pluralize from 'pluralize';
import {
  Button,
  Col,
  InfoBox,
  Row,
  Modal,
  Show,
  useFormError,
} from '@perts/ui';
import { ListGroupsToRemove } from './ListGroupsToRemove';
import TermsContext from 'components/TermsContext';

export type NetworkGroupsRemoveFormValues = {};

export type NetworkGroupsRemoveFormProps = {
  close: (params?: any) => void;
  onSubmit: (values: NetworkGroupsRemoveFormValues) => void;
  network: Network;
  groupsToRemove: Group[];
  groupsToRemoveIncludesAllGroups: boolean;
};

export const NetworkGroupsRemoveForm = ({
  close,
  onSubmit,
  network,
  groupsToRemove,
  groupsToRemoveIncludesAllGroups,
}: NetworkGroupsRemoveFormProps) => {
  const [FormError, showFormError] = useFormError();
  const terms = useContext(TermsContext);

  const validate = ({ groups }) => ({
    ...(groups.length === 0 ? { groups: 'invalid' } : {}),
  });

  return (
    <Modal close={close}>
      <Modal.Title className="center">{`Remove ${terms.network} ${terms.groups}`}</Modal.Title>

      <Formik
        enableReinitialize={true}
        initialValues={{
          groups: groupsToRemove,
        }}
        validate={validate}
        validateOnMount={true}
        onSubmit={async (values) => {
          try {
            // Clear existing form error.
            showFormError(false);

            // Perform form onSubmit.
            await onSubmit(values);

            // Close modal on successful form onSubmit.
            close();
          } catch (error) {
            // Display form error.
            showFormError(true);
          }
        }}
      >
        {({ isSubmitting, isValid, values }) => (
          <Form>
            <Show when={groupsToRemoveIncludesAllGroups}>
              <Row>
                <Col>
                  <InfoBox color="warning" data-testid="groups-list-self">
                    This action would remove all {terms.groups.toLowerCase()}{' '}
                    from the network.
                  </InfoBox>
                </Col>
              </Row>
            </Show>
            <Show when={!groupsToRemoveIncludesAllGroups}>
              <ListGroupsToRemove network={network} groups={values.groups} />
            </Show>

            <Row>
              <Col>
                <FormError />
              </Col>
            </Row>

            <Row>
              <Col cols={6} colsSm={12}>
                <Button
                  type="button"
                  color="secondary"
                  fullWidth
                  onClick={close}
                  disabled={isSubmitting}
                  fullHeight
                >
                  Cancel
                </Button>
              </Col>

              <Col cols={6} colsSm={12} hAlign="flex-end">
                <Button
                  type="submit"
                  fullWidth
                  data-testid="submit-button"
                  disabled={!isValid || isSubmitting}
                  loading={isSubmitting}
                >
                  Remove {pluralize(terms.groups, groupsToRemove.length, true)}
                </Button>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
