import { useQuery } from 'react-query';
import {
  Network,
  networkGetById,
  networksQuery,
  networksQueryByUser,
  networksQueryPagination,
  useCurrentUserQuery,
  selectUserIsAdmin,
  useQueryWithPagination,
  useProgramId,
} from 'models';
import { useParams } from 'pages';
import { QueryParams } from 'services/triton/helpers';

// -----------------------------------------------------------------------------
//   queryKey Generators
// -----------------------------------------------------------------------------

export const queryKeyNetwork = (networkId: string) => ['network', networkId];

export const queryKeyNetworks = () => ['networks'];

export const queryKeyNetworksByProgramGeneric = ['networks', 'byProgram'];

export const queryKeyNetworksByUser = (userId: string) => [
  'networks',
  'byUser',
  userId,
];

export const queryKeyNetworksByProgram = (programId: string, paging?: any) => [
  ...queryKeyNetworksByProgramGeneric,
  programId,
  paging,
];

// -----------------------------------------------------------------------------
//   API Hooks
// -----------------------------------------------------------------------------

// useNetworksQuery
// - will return all networks for admin users
// - will return user associated networks for non-admin users
// - will scope to program if `queryParams.programId` is provided
// - will scope to user if `queryParams.userId` is provided
// - handles pagination

const queryFnNetworksQuery = (user, queryParams: QueryParams) => {
  // Requesting a specific user's networks.
  if (queryParams.userId) {
    return () => networksQueryByUser(queryParams.userId || '', queryParams);
  }

  // Admin, queries for all networks.
  if (user && selectUserIsAdmin(user)) {
    return () => networksQuery(queryParams);
  }

  // Non-admin, queries for user's networks.
  return () => networksQueryByUser(user?.uid || '', queryParams);
};

export const useNetworksQuery = (
  queryParams: QueryParams = {},
  queryOptions = {},
) => {
  const programId = useProgramId();
  const programQueryParams: QueryParams = { programId, ...queryParams };
  const {
    data: user,
    isError: userIsError,
    error: userError,
    isLoading: userIsLoading,
  } = useCurrentUserQuery();

  const queryFn = queryFnNetworksQuery(user, programQueryParams);
  const queryKey = queryKeyNetworksByProgram(programId, programQueryParams);

  const result = useQueryWithPagination<Network[]>(queryKey, queryFn, {
    // We never want to run this before we know the user since the queryFn used
    // is determined by the user type. NOTE: If you need to pass a custom
    // queryOptions.enabled, this will need to be adjusted.
    enabled: Boolean(user && user.uid),

    // Note: If you override `enabled`, you'll need to manually ensure that
    // `enabled` is not set to true until the user is available.
    ...queryOptions,
  });

  return {
    ...result,
    isLoading: result.isLoading || userIsLoading,
    isError: result.isError || userIsError,
    error: result.error || userError,
  };
};

export const useNetworkGetById = (networkId: string) =>
  useQuery<Network, Error>(
    // queryKey
    queryKeyNetwork(networkId),
    // queryFn
    () => networkGetById(networkId),
    {
      enabled: Boolean(networkId),
    },
  );

export const useNetworkGetByParams = () => {
  const { networkId } = useParams();
  return useNetworkGetById(networkId);
};

export const useNetworksByUserId = () => {
  const { data: user } = useCurrentUserQuery();

  const queryFn = () => networksQueryByUser(user?.uid || '', {});
  const queryKey = queryKeyNetworksByUser(user?.uid || '');

  const userNetworks = useQuery<Network[], Error>(queryKey, queryFn, {
    enabled: Boolean(user?.uid),
  });

  return userNetworks;
};

// useNetworksPagination
// - for use on /archives page

export const useNetworksPagination = (
  queryParams: QueryParams = {},
  queryOptions = {},
) => {
  const { programId } = queryParams;
  const queryFn = () => networksQueryPagination(queryParams);
  const queryKey = queryKeyNetworksByProgram(programId || '', {
    name: queryParams.name,
    pageIndex: queryParams.pageIndex,
  });
  return useQueryWithPagination<Network[]>(queryKey, queryFn, queryOptions);
};
