import { Col, Input, Row, Switch } from '@perts/ui';
import { useTerms } from 'components';
import { SurveysEditAdvancedFormValues } from './SurveysEditAdvancedForm';

const switchLabel = (value, terms) => {
  const checked = Boolean(value);
  const indeterminate = value == null;

  const mixedSwitchLabel = (
    <span>
      {terms.classes} have mixed settings for whether students will be asked
      about their gender identity.
    </span>
  );
  const enabledSwitchLabel = (
    <span>
      <strong>Enabled</strong>: {terms.participants} will be asked about their
      gender identity.
    </span>
  );
  const disabledSwitchLabel = (
    <span>
      <strong>Disabled (not recommended)</strong>: {terms.participants} will NOT
      be asked about their gender identity and reports will show them as having
      skipped the gender question.
    </span>
  );

  const label = indeterminate
    ? mixedSwitchLabel
    : checked
    ? enabledSwitchLabel
    : disabledSwitchLabel;

  return label;
};

export const SurveysEditAdvancedFields = ({
  isSubmitting,
  values,
}: {
  isSubmitting: boolean;
  values: SurveysEditAdvancedFormValues;
}) => {
  const terms = useTerms();

  return (
    <>
      <Row>
        <Col>
          <Input
            component={Switch}
            id="genderQuestionEnabled"
            name="genderQuestionEnabled"
            label={switchLabel(values.genderQuestionEnabled, terms)}
            labelPlacement="end"
            disabled={isSubmitting}
            checked={values.genderQuestionEnabled}
            indeterminate={values.genderQuestionEnabled === null}
          />
        </Col>
      </Row>
    </>
  );
};
