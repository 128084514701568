import { groupBy, mean, sortBy } from 'lodash';

import type { ChildSurveyRow, TimelineChartRow } from '../types';

// Transform RServe data into a table-friendly format.
export const mutateToChildSurveyTable = (
  data: TimelineChartRow[],
): ChildSurveyRow[] => {
  const numCycles = data.reduce(
    (acc, row) => Math.max(acc, row.cycle_ordinal),
    1,
  );

  // RServe data has separate rows for each child-cycle. Group by child.
  const dataIndex = groupBy(data, 'reporting_unit_id');

  const childSurveyRows: ChildSurveyRow[] = []; // collect output

  for (const [, rows] of Object.entries(dataIndex)) {
    // Ensure cycles are sorted.
    const rowsSorted = sortBy(rows, 'cycle_ordinal');

    // Collect cycle data.
    const ratedPositive = rowsSorted.map((r) => r.pct_good);

    // Comparison value for cumulative change.
    const lastRatedPositive = ratedPositive[ratedPositive.length - 1];

    // No change for cycle 1.
    const ratedPositiveChangeNum = ratedPositive.map((n, i) =>
      i === 0 ? null : Math.round((n - ratedPositive[i - 1]) * 100),
    );

    // No cumulative change if there's only 1 cycle.
    const cumulativeChangeNum =
      numCycles === 1
        ? null
        : Math.round((lastRatedPositive - ratedPositive[0]) * 100);

    childSurveyRows.push({
      child_name: rows[0].reporting_unit_name,
      avg_rated_positive: mean(ratedPositive),
      pct_rated_positive: ratedPositive,
      rated_positive_change: ratedPositiveChangeNum.map(String),
      rated_positive_change_num: ratedPositiveChangeNum,
      cumulative_change: String(cumulativeChangeNum),
      cumulative_change_num: cumulativeChangeNum,
    });
  }

  return childSurveyRows;
};
