// Custom React hook that can be used to display a Form level error. This will
// most commonly be used to display errors based on server responses that aren't
// attached to a specific field.
//
// This also allows us to display an error outside of Formik's error flow. The
// benefit to this is that errors in Formik prevent forms from being submitted
// (because we always set <Button disabled={invalid} />). But we would like
// users to be able to retry form submissions on server errors.
//
// Example usage:
//
// import { Formik, Form } from 'formik';
// import { useFormError } from '@perts/ui';`
//
// const ExampleForm = ({ onSubmit }) => {
//   const [FormError, showFormError] = useFormError(
//     /*
//       Optional error message to be displayed.
//       If none is specified, than DEFAULT_FORM_ERROR will be used.
//     */
//   );
//
//   return (
//     <Formik
//       onSubmit={async (values) => {
//         try {
//           // Clear existing form error.
//           showFormError(false);
//
//           // Perform form onSubmit.
//           await onSubmit(values);
//         } catch (error) {
//           // Display form error.
//           showFormError(true);
//         }
//       }}
//     >
//       <Form>
//         FORM CONTENT
//         <FormError />
//         SUBMIT BUTTON
//       </Form>
//     </Formik>
//   );
// };

import { useState, useEffect, useRef } from 'react';
import { InfoBox, Row, Col } from '../../';

export const DEFAULT_FORM_ERROR =
  'There was a problem saving. Please try again.';

type FormErrorProps = {
  // The FormError error message.
  children?: React.ReactNode;
};

const FormError = ({ children }: FormErrorProps) => {
  if (!children) {
    return null;
  }

  return (
    <InfoBox color="error" aria-live="assertive">
      <Row>
        <Col>{children}</Col>
      </Row>
    </InfoBox>
  );
};

type UseFormError = (
  errorMessage?: string,
) => [
  FormError: () => JSX.Element | null,
  setShowError: (value: boolean) => void,
];

export const useFormError: UseFormError = (errorMsg = DEFAULT_FORM_ERROR) => {
  const isMounted = useRef(false);

  useEffect(() => {
    isMounted.current = true;

    return () => {
      isMounted.current = false;
    };
  }, []);

  const [showError, setShowError] = useState(false);

  const setShowErrorWhenMounted = (value: boolean) => {
    // Check that the component is mounted before attempting to update state.
    if (isMounted.current) {
      setShowError(value);
    }
  };

  const FormErrorComponent = () =>
    showError ? <FormError>{errorMsg}</FormError> : null;

  return [FormErrorComponent, setShowErrorWhenMounted];
};
