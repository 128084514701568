import styled, { css } from 'styled-components/macro';
import { theme } from 'components';

export const ReactTableTdStyled = styled.td<{
  align?: string;
  vAlign?: string;
}>`
  padding-top: calc(${theme.units.paddingSm} / 2);
  padding-bottom: calc(${theme.units.paddingSm} / 2);
  padding-left: ${theme.units.paddingSm};
  padding-right: ${theme.units.paddingSm};

  @media only screen and (max-width: ${theme.units.gridBreakpointMedium}px) {
    padding-top: calc(${theme.units.paddingSm} / 2);
    padding-bottom: calc(${theme.units.paddingSm} / 2);
    padding-left: ${theme.units.paddingSm};
    padding-right: ${theme.units.paddingSm};
  }

  text-align: ${(props) => props.align || 'left'};

  ${(props) =>
    props.vAlign &&
    css`
      vertical-align: ${props.vAlign};
    `}
`;

export const ReactTableTd = ({
  cell,
  children = undefined,
  ...rest
}: {
  cell: any;
  children?: React.ReactNode;
  [key: string]: any;
}) => {
  const props = { align: cell.column.align };

  return (
    <ReactTableTdStyled {...cell.getCellProps([props])} {...rest}>
      {children || cell.render('Cell')}
    </ReactTableTdStyled>
  );
};
