// Pagination is a wrapper around antd's Pagination component that includes an
// optional loading indicator for use with server pagination. Use this anywhere
// you'd use pagination.

// TODO Does not support direct to first and last page navigation yet as that's
// not built into antd's Pagination.

import { Pagination as AntdPagination } from 'antd';
import styled from 'styled-components/macro';
import theme from '../theme';
import { Spinner } from '../Spinner';

type PaginationProps = {
  total?: number;
  pageSize?: number;
  pageIndex?: number;
  onChange?: ((page: number, pageSize: number) => void) | undefined;
  isLoading?: boolean;
};

const PaginationWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const PaginationStyled = styled(AntdPagination)`
  display: flex;
  justify-content: center;

  // Allow to wrap pagination in small screens if it is neccessary
  @media only screen and (max-width: ${theme.units.gridBreakpointSmall}px) {
    flex-wrap: wrap;
  }
`;

export const Pagination = ({
  total = 1,
  pageSize = 10,
  pageIndex = 0,
  onChange = () => undefined,
  isLoading = false,
}: PaginationProps) => (
  <PaginationWrapper>
    {isLoading && <Spinner />}
    <PaginationStyled
      current={pageIndex + 1}
      onChange={(page) => onChange(page - 1, pageSize)}
      pageSize={pageSize}
      total={total}
      size="small"
      showSizeChanger={false}
    />
  </PaginationWrapper>
);
