// https://firebase.google.com/docs/reference/js
// https://firebase.google.com/docs/web/modular-upgrade
import { EmailAuthProvider, GoogleAuthProvider, getAuth } from 'firebase/auth';
import FirebaseAuth from 'react-firebaseui/FirebaseAuth';
import styled from 'styled-components/macro';

import theme from 'components/theme';

const FirebaseUIRestyled = styled.div`
  #firebaseui_container {
    // Make sure terms-of-service and privacy-policy links are as big as
    // other text, which is a legal requirement.
    .firebaseui-tos {
      font-family: ${theme.fontFamily};
      font-size: ${theme.units.paragraphFontSize};
    }

    // For some reason the normal colors don't come through in the built client.
    // Set them explicitly and use PERTS colors.
    .firebaseui-idp-button.firebaseui-idp-google {
      background-color: ${theme.colors.white};
    }
    .firebaseui-idp-button.firebaseui-idp-password {
      background-color: ${theme.colors.redDark};
    }

    .firebaseui-link.firebaseui-tos-link:hover,
    .firebaseui-link.firebaseui-pp-link:hover {
      font-weight: 400;
    }

    .mdl-shadow--2dp {
      box-shadow: none;
    }

    .firebaseui-card-header {
      padding: ${theme.units.paddingLg} 0 0 0;
    }

    .firebaseui-card-content {
      padding: 0;
    }

    .firebaseui-card-actions {
      padding: ${theme.units.paddingSm} 0 ${theme.units.paddingLg} 0;
    }
  }
`;

// FirebaseUI config.
const uiConfig = {
  callbacks: { signInSuccessWithAuthResult: () => false },
  signInFlow: 'popup',
  // We don't need firebaseui to do any redirecting for us, rather
  // Login will detect the change of state and redirect.
  signInSuccessUrl: undefined,
  signInOptions: [
    {
      provider: GoogleAuthProvider.PROVIDER_ID,
      customParameters: {
        // https://github.com/firebase/firebaseui-web#configure-oauth-providers
        // Forces account selection even when one account is available. This
        // gives the user the opportunity to select the account they were
        // invited with.
        prompt: 'select_account',
      },
    },
    {
      provider: EmailAuthProvider.PROVIDER_ID,
      customParameters: {
        // https://github.com/firebase/firebaseui-web#configure-oauth-providers
        // Forces password re-entry. This gives the user the opportunity to
        // login with the account they were invited with.
        auth_type: 'reauthenticate',
      },
    },
  ],
  tosUrl: 'https://www.perts.net/terms-of-use',
  privacyPolicyUrl: 'https://www.perts.net/privacy',
};

const LoginForm = () => (
  <>
    <div className="LoginForm">
      <FirebaseUIRestyled>
        <FirebaseAuth uiConfig={uiConfig} firebaseAuth={getAuth()} />
      </FirebaseUIRestyled>
      <div
        id="firebaseui-auth-container"
        data-testid="firebaseui-auth-container"
      ></div>
    </div>
  </>
);

export default LoginForm;
