import { useRef } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';

import {
  Button,
  Col,
  IconChevronCircleLeft,
  IconChevronCircleRight,
  Row,
  useFormError,
} from '@perts/ui';
import { Program } from 'models';
import { ScheduleTableHeader } from '../ScheduleTable/ScheduleTableHeader';
import { SchedulesTableDefaultSettings } from './SchedulesTableDefaultSettings';
import { serverDateFormat } from 'config';
import {
  ScheduleTableContainer,
  ScheduleTableContent,
  ButtonScrollRight,
  ButtonScrollLeft,
} from '../ScheduleTable';
import { useTerms } from 'components/TermsContext';

export type CycleType = {
  start_date: string | null;
  end_date: string | null;
};

type SurveysDefaultSettingsFormType = {
  cycles: CycleType[];
  program: Program;
  onSubmit: (values: CycleType[]) => void;
};

const SurveysDefaultSettingsSchema = Yup.object();

export const SchedulesDefaultSettingsForm = ({
  cycles = [],
  onSubmit,
  program,
}: SurveysDefaultSettingsFormType) => {
  const terms = useTerms();
  const [FormError, showFormError] = useFormError();

  const initialValues = {};

  cycles.forEach((schedule, index) => {
    initialValues[`cycle-${index}`] = {
      start_date: schedule.start_date
        ? moment(schedule.start_date).format(serverDateFormat)
        : null,
      end_date: schedule.end_date
        ? moment(schedule.end_date).format(serverDateFormat)
        : null,
    };
  });

  const scrollContainer = useRef<HTMLDivElement>(null);

  const handleLeftScroll = () => {
    if (scrollContainer && scrollContainer.current) {
      scrollContainer.current.scrollLeft -= 250;
    }
  };

  const handleRightScroll = () => {
    if (scrollContainer && scrollContainer.current) {
      scrollContainer.current.scrollLeft += 250;
    }
  };

  const maxWidthTable = `calc(${250 + 180 * (cycles.length + 1)}px)`;

  return (
    <Row justifyContent="center">
      <Col>
        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          validationSchema={SurveysDefaultSettingsSchema}
          validateOnBlur={false}
          onSubmit={async (values) => {
            try {
              // Clear existing form error.
              showFormError(false);

              // Transform cycles object to schedules array
              const schedules: CycleType[] = Object.values(values);

              // Perform form onSubmit.
              await onSubmit(schedules);
            } catch (error) {
              // Display form error.
              showFormError(true);
            }
          }}
        >
          {({ isSubmitting, isValid, values, dirty, resetForm }) => {
            const currentMaxCycles = Object.keys(values).length;

            return (
              <Form>
                <ScheduleTableContainer maxWidth={maxWidthTable} fixedHeight>
                  <ButtonScrollLeft
                    role="button"
                    onClick={handleLeftScroll}
                    aria-label="Scroll left"
                  >
                    <IconChevronCircleLeft size={26} />
                  </ButtonScrollLeft>

                  <ButtonScrollRight
                    role="button"
                    onClick={handleRightScroll}
                    aria-label="Scroll right"
                  >
                    <IconChevronCircleRight size={26} />
                  </ButtonScrollRight>
                  <ScheduleTableContent
                    ref={scrollContainer}
                    maxWidth={maxWidthTable}
                    allPageIsSelected={false}
                    someUnscheduledClasses={false}
                    fixedHeight={false}
                  >
                    <ScheduleTableHeader
                      height={65}
                      currentMaxCycles={Object.keys(values).length}
                    />

                    <SchedulesTableDefaultSettings
                      currentMaxCycles={currentMaxCycles}
                      program={program}
                      onSubmit={onSubmit}
                    />
                  </ScheduleTableContent>
                </ScheduleTableContainer>

                <Row>
                  <Col>
                    <FormError />
                  </Col>
                </Row>

                <Row>
                  <Col cols={12}>
                    <Button
                      type="submit"
                      fullWidth
                      disabled={!isValid || isSubmitting || !dirty}
                      loading={isSubmitting}
                    >
                      Save Schedule for New {terms.classes}
                    </Button>
                  </Col>
                </Row>
              </Form>
            );
          }}
        </Formik>
      </Col>
    </Row>
  );
};
