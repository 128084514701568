import styled, { css } from 'styled-components/macro';
import { SelectFilterCheckbox } from './SelectFilterCheckbox';
import { SelectFilterRadio } from './SelectFilterRadio';

type SelectFilterIconProps = {
  multiple?: boolean;
  color?: string;
  selected?: boolean;
};

const IconStyles = styled.div`
  display: flex;

  svg {
    height: 20px;
    width: 20px;
  }

  ${(props) =>
    props.color &&
    css`
      color: ${props.color};
    `};
`;

export const SelectFilterIndicator = ({
  multiple,
  color,
  selected = false,
}: SelectFilterIconProps) => {
  const IconComponent = multiple ? SelectFilterCheckbox : SelectFilterRadio;
  return (
    <IconStyles color={color}>
      <IconComponent checked={selected} />
    </IconStyles>
  );
};
