import React from 'react';

import { EXPERIENCE_GAP_THRESHOLD } from 'config';
import { EquityGapIcon } from './';

export const equityGapText = `Equity gap of ≥ ${EXPERIENCE_GAP_THRESHOLD} compared to All`;

export const EquityGapLegend = () => (
  <span>
    <EquityGapIcon />
    {equityGapText}
  </span>
);
