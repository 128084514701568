// These are "results" in the sense that they represent data that have been
// calculated from survey responses. This kind of data differs from
// "particpation" data which is less sensitive and has no access to the contents
// of survey responses.

// Organized for ease and flexibility of access in report display. Typically
// indexed by metric and subpopulation.

// Stored as part of the payload of a Dataset entity. See type Dataset.

export type DataOverTime = (number | null)[];

export type ExperienceByAttribute = {
  // attributePath e.g. "gender4.men" or "all_participants"
  [attributePath: string]: DataOverTime;
};

// To what degree each metric was "rated positively" on the survey. Captures
// the student experience, thus "experience".
export type ExperienceResults = {
  // Composite means all the questions/items in a metric averaged together.
  composite: ExperienceByAttribute;
  by_item: {
    [metricItemLabel: string]: ExperienceByAttribute;
  };
};

// Calculated from fidelity questions re: how effectively the survey is
// introduced and framed for the participants.
export type FidelityResults = {
  make_better: DataOverTime;
  honest?: DataOverTime;
};

// How many of what kinds of people responded.
export type SampleResults = {
  // attributePath e.g. "gender4.men" or "all_participants"
  [attributePath: string]: number;
};

export type MetricSampleResults = {
  // Composite means all the questions/items in a metric averaged together.
  composite: SampleResults;
  by_item: {
    [itemLabel: string]: SampleResults;
  };
};

export type OpenResponseResults = {
  // Arrays of answer texts, within an array by cycle ordinal.
  [metricItemLabel: string]: string[][];
};

// Name and size of target groups.
export type TargetGroupTableRow = {
  short_uid: string;
  team_name: string;
  target_group_name: string;
  participants_n: number;
};

export type NetworkResults = {
  experience: {
    [metricLabel: string]: ExperienceResults;
  };
  sample: {
    all_responses: SampleResults;
    by_metric: {
      [metricLabel: string]: MetricSampleResults;
    };
  };
};

export type OrganizationResults = {
  fidelity: FidelityResults;
  experience: {
    [metricLabel: string]: ExperienceResults;
  };
  sample: {
    all_responses: SampleResults;
    by_metric: {
      [metricLabel: string]: MetricSampleResults;
    };
  };
  target_group?: TargetGroupTableRow[];
};

export type TeamResults = {
  fidelity: FidelityResults;
  experience: {
    [metricLabel: string]: ExperienceResults;
  };
  sample: {
    all_responses: SampleResults;
    by_metric: {
      [metricLabel: string]: MetricSampleResults;
    };
  };
  open_responses: {
    [metricLabel: string]: OpenResponseResults;
  };
};

export type Results = NetworkResults | OrganizationResults | TeamResults;

// A simpler version of OrganizationResults, available in network datasets.
export type OrganizationExperienceResults = {
  fidelity: FidelityResults;
  experience: {
    [metricLabel: string]: ExperienceResults;
  };
  sample: {
    [metricLabel: string]: SampleResults;
  };
};

export const isTeamResults = (results: Results): results is TeamResults =>
  'fidelity' in results && 'open_responses' in results;
