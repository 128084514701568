import { Input, Select } from '@perts/ui';

export const InputSelectProgram = ({ isLoading, programs }) => (
  <Input
    id="programId"
    name="programId"
    label="Select an archived program"
    labelPlacement="top"
    options={programs}
    keyBy="short_uid"
    keyName="displayName"
    component={Select}
    fullWidth
    placeholder="Select an archived program"
    displayErrors={false}
    disabled={isLoading}
  />
);
