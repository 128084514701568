// https://docs.google.com/spreadsheets/d/1EKKzZjI-eCtekB_S_SsNnBQpGEKVIKueZIkBD6W8QJc

import keyBy from 'lodash/keyBy';

import { locales } from '../i18n';
import { CompositeMetricConfig, MetricItemConfig } from './types';

const options = {
  [locales.ENGLISH]: [
    {
      label: 'Never',
      value: 1,
    },
    {
      label: 'Rarely',
      value: 2,
    },
    {
      label: 'Sometimes',
      value: 3,
    },
    {
      label: 'Often',
      value: 4,
    },
    {
      label: 'Always',
      value: 5,
    },
  ],
};

export const perspectiveTakingItemConfig: MetricItemConfig[] = [
  {
    label: 'perspective_taking_imagine',
    prompts: {
      [locales.ENGLISH]:
        `I try to understand my students better by imagining how things ` +
        `look from their perspective.`,
    },
    options,
    minGood: 4,
  },
  {
    label: 'perspective_taking_upset',
    prompts: {
      [locales.ENGLISH]:
        `When I’m upset at a student, I try to put myself in the student's ` +
        `shoes for a while.`,
    },
    options,
    minGood: 4,
  },
];

export const perspectiveTakingConfig: CompositeMetricConfig = {
  label: 'perspective-taking',
  items: perspectiveTakingItemConfig,
  itemIndex: keyBy(perspectiveTakingItemConfig, 'label'),
};
