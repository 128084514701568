import { useQueryClient, useMutation } from 'react-query';
import { message } from 'antd';

import {
  invalidateUsersInviteToGroup,
  useGroupGetByParams,
  usersInviteToGroup,
} from 'models';
import { useParams } from 'pages';
import { useCloseModal } from 'utils';

import { MembersAddForm, MemberAddFormValues } from './MembersAddForm';
import Loading from 'components/Loading';
import { ErrorMessageBox } from 'components/ErrorMessageBox';
import { getMessageFromErrors } from '@perts/util';

export const MembersAdd = () => {
  const { groupId } = useParams();
  const queryClient = useQueryClient();

  // Close the modal.
  const closeModal = useCloseModal({ ignoreEmptyState: true });

  // Query for Group.
  const {
    isLoading: groupIsLoading,
    isError: groupIsError,
    data: group,
    error: groupError,
  } = useGroupGetByParams();

  // Mutation: Invite users to group.
  // https://react-query.tanstack.com/guides/mutations
  const mutation = useMutation(
    // Transform form values into a shape that can be sent to the api function.
    async ({ emails: emailsFromForm }: MemberAddFormValues) => {
      if (!group) {
        throw new Error('Group required to invite user.');
      }

      const invitees = emailsFromForm.map((email) => ({
        email,
        name: '',
        receive_email: true,
      }));

      await usersInviteToGroup(invitees, group);
    },
    {
      // Handle successful mutation.
      onSuccess: (data, { emails }) => {
        message.success('Successfully added the email(s).');

        // Invalidate users to force a refetch of current users.
        invalidateUsersInviteToGroup(queryClient, groupId);
      },
    },
  );

  // TODO DRY https://github.com/PERTS/perts/issues/61
  // Display loading when users data is loading.
  if (groupIsLoading) {
    return <Loading />;
  }

  // TODO DRY https://github.com/PERTS/perts/issues/61
  // Display any errors.
  if (groupIsError) {
    return (
      <ErrorMessageBox>{getMessageFromErrors([groupError])}</ErrorMessageBox>
    );
  }

  // https://stackoverflow.com/questions/65760158/react-query-mutation-typescript
  // Formik onSubmit handler
  const onSubmit = async ({ emails }: MemberAddFormValues) => {
    await mutation.mutateAsync({ emails });
  };

  return <MembersAddForm close={closeModal} onSubmit={onSubmit} />;
};
