import { useContext } from 'react';
import { useParams } from 'react-router-dom';

import { toNetworkGroupsRemove, PagesParams, toNetworkGroupsAdd } from 'pages';
import { useOpenModal } from 'utils';

import {
  Actionbar,
  Button,
  Col,
  FixedActionbarContainer,
  IconAdd,
  IconRemove,
  Row,
} from '@perts/ui';
import TermsContext from 'components/TermsContext';

type Props = {
  checked: { [key: string]: boolean };
  ControlToggle: any;
  disabled: boolean;
};

export const NetworkGroupsActionbar = ({
  checked,
  ControlToggle,
  disabled,
}: Props) => {
  const { networkId } = useParams<PagesParams>();
  const openModal = useOpenModal();
  const terms = useContext(TermsContext);

  const openGroupNetworkGroupsRemove = () => {
    openModal(toNetworkGroupsRemove(networkId), { checked });
  };

  return (
    <FixedActionbarContainer>
      <Actionbar>
        <Row justifyContent="flex-end">
          <Col colsSm={12} cols={0}>
            <Button
              to={toNetworkGroupsAdd(networkId)}
              iconLeft={<IconAdd />}
              color="secondary"
              fullWidth
            >
              Add {terms.groups}
            </Button>
          </Col>
        </Row>
      </Actionbar>

      <Actionbar>
        <Row alignItems="center">
          <Col cols={0}>
            <ControlToggle />
          </Col>

          <Col colsSm={12} cols={0}>
            <Button
              onClick={openGroupNetworkGroupsRemove}
              disabled={disabled}
              fullWidth
              iconLeft={<IconRemove />}
            >
              Remove {terms.groups}
            </Button>
          </Col>
        </Row>
      </Actionbar>
    </FixedActionbarContainer>
  );
};
