import {
  Class,
  Cycle,
  selectClassCycles,
  selectGroupProgram,
  sortClassByName,
  sortCycleByOrdinal,
  useClassesQueryByParams,
  useCyclesQueryByParams,
  useGroupGetByParams,
  useProgramsQuery,
  selectUserIsAdmin,
  selectUserIsGroupManager,
  selectUserIsMember,
  useParams,
  useCurrentUserQuery,
} from 'models';
import { getMessageFromErrors } from '@perts/util';
import Loading from 'components/Loading';
import { ErrorMessageBox } from 'components/ErrorMessageBox';

import { SchedulesRender } from './SchedulesRender';

// Convenient nested data for rendering the table.
export type ClassWithCycles = Class & { cycles: Cycle[] };

// State for sorting the schedule table. Null if no sort is active.
export type ScheduleSort = null | {
  ordinal: number;
  ascending: boolean;
};

export type SetSort = (sort: ScheduleSort) => void;

// eslint-disable-next-line complexity
export const Schedules = () => {
  const { groupId } = useParams();

  const currentUserQuery = useCurrentUserQuery();

  // Query for Classes associated with Group.
  const classesQuery = useClassesQueryByParams();

  // Query for cycles from all classes.
  const cyclesQuery = useCyclesQueryByParams();

  // Query for Group.
  const groupQuery = useGroupGetByParams();

  // Query for Programs.
  const programsQuery = useProgramsQuery();

  // Display loading when users data is loading.
  const isLoading =
    currentUserQuery.isLoading ||
    classesQuery.isLoading ||
    cyclesQuery.isLoading ||
    groupQuery.isLoading ||
    programsQuery.isLoading;

  // Display loading.
  if (isLoading) {
    return <Loading />;
  }

  // Display error.
  if (
    !currentUserQuery.isSuccess ||
    !classesQuery.isSuccess ||
    !cyclesQuery.isSuccess ||
    !groupQuery.isSuccess ||
    !programsQuery.isSuccess
  ) {
    return (
      <ErrorMessageBox>
        {getMessageFromErrors([
          currentUserQuery.error,
          classesQuery.error,
          cyclesQuery.error,
          groupQuery.error,
          programsQuery.error,
        ])}
      </ErrorMessageBox>
    );
  }

  const currentUser = currentUserQuery.data;

  const userIsMember = selectUserIsMember(currentUser, groupId);
  const userIsAdmin = selectUserIsAdmin(currentUser);
  const userIsManager = selectUserIsGroupManager(currentUser, groupId);

  // Fill classes with cycles.
  const classesFilled: ClassWithCycles[] = classesQuery.data
    .sort(sortClassByName)
    .map((cls) => ({
      ...cls,

      cycles: selectClassCycles(cls, cyclesQuery.data).sort(sortCycleByOrdinal),
    }));

  // Determine group program.
  const program = selectGroupProgram(groupQuery.data, programsQuery.data);

  return (
    <SchedulesRender
      archived={groupQuery.data.archived}
      classes={classesFilled}
      program={program}
      mayAddCycles={userIsMember || userIsAdmin}
      mayUpdateCycles={userIsMember || userIsAdmin}
      mayEditDefaultSettings={userIsManager || userIsAdmin}
    />
  );
};
