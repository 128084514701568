import { StyledIconButton } from './StyledIconButton';
import { ButtonProps } from '../Button';

type ButtonState = 'stateless' | 'active' | 'inactive';

export type IconButtonProps = Omit<ButtonProps, 'aria-label' | 'children'> & {
  /**
   * The aria-label attribute is used to define a string that labels the current
   * element. Use it in cases where a text label is not visible on the screen.
   * https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Attributes/aria-label
   */
  'aria-label': string;

  /**
   * The button icon to display.
   */
  children?: React.ReactNode;

  /**
   * The button icon to display. Alternative to `children`.
   */
  icon?: React.ReactNode;

  /**
   * The button's current state. Default `stateless`.
   */
  state?: ButtonState;

  /**
   * `true` will remove the border from the button.
   */
  noBorder?: boolean;
};

export const IconButton = ({
  children,
  icon,
  state = 'stateless',
  ...props
}: IconButtonProps) => {
  return (
    <StyledIconButton {...props} state={state}>
      {icon || children || ''}
    </StyledIconButton>
  );
};
