import React from 'react';
import styled from 'styled-components/macro';
import { theme, Show } from '@perts/ui';

import FakeLink from 'components/FakeLink';

const ExpanderStyled = styled.div`
  font-size: 14px;
  margin-bottom: ${theme.units.padding};
  text-align: center;
`;

export const DataTableExpander = ({ expanded, setExpanded }) => (
  <ExpanderStyled>
    <Show when={expanded}>
      <FakeLink onClick={() => setExpanded(false)}>Collapse subgroups</FakeLink>
    </Show>
    <Show when={!expanded}>
      <FakeLink onClick={() => setExpanded(true)}>Expand subgroups</FakeLink>
    </Show>
  </ExpanderStyled>
);
