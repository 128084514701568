import { get as _get, values as _values, isEqual } from 'lodash';
import { useFormikContext } from 'formik';
import Truncate from 'react-truncate';
import styled from 'styled-components/macro';

import {
  Class,
  Cycle,
  Program,
  useCyclesCreateByClasses,
  useCyclesRemove,
} from 'models';
import { useParams } from 'pages';
import { antdLocale, dateFormatShort, serverDateFormat } from 'config';
import { ToggleType } from 'utils/useToggles';

import { Button, Flag, IconAdd, Input, RangePicker, Show } from '@perts/ui';
import { RangePickerFooter } from '../RangePickerFooter';
import { ScheduleTableRow } from '../ScheduleTableRow';
import { ScheduleTableCol } from '../ScheduleTableCol';
import { getCycleErrors } from '../../SchedulesEditCycles/getCycleErrors';
import { getDatePickerName } from 'pages/Schedules/getDatePickerName';
import { theme } from 'components';
import { useQueryParams } from 'utils/useQueryParams';

type ScheduleTableRowsProps = {
  checked: { [key: string]: boolean };

  // Classes to be displayed.
  classes: Class[];

  // Max cycles from all classes.
  currentMaxCycles: number;

  mayAddCycles: boolean;

  mayUpdateCycles: boolean;

  program: Program;

  // Toggle component, from useToggles
  Toggle: ToggleType;
};

const ToggleLabelContainer = styled.div`
  > :nth-child(2) {
    margin-left: 5px;
  }
`;

const ToggleLabel = ({ classFlagType, clsName }) => (
  <ToggleLabelContainer>
    <Truncate
      lines={1}
      trimWhitespace
      width={classFlagType === 'hidden' ? 180 : 110}
    >
      {clsName}
    </Truncate>
    <Flag type={classFlagType} />
  </ToggleLabelContainer>
);

// Callback function tirggered by a cycle update
const handleChangeCallback =
  (
    values: any,
    cls: Class,
    cycle: Cycle,
    errors: any,
    setErrors: (errors: any) => void,
  ) =>
  (start_date: string | null, end_date: string | null) => {
    // Get cycles of class related to modified cycle
    const classCyclesFromForm = _get(values, `classes.${cls.uid}.cycles`, {});

    // Transform class cycles object to class cycles array
    const cyclesValues = _values(classCyclesFromForm);

    // Determine updated errors
    const updatedErrors = getCycleErrors(
      errors,
      cls.uid,
      cycle.uid,
      cyclesValues,
      start_date,
      end_date,
    );

    setErrors(updatedErrors);
  };

export const ScheduleTableRows = ({
  Toggle,
  checked,
  classes = [],
  currentMaxCycles,
  mayAddCycles,
  mayUpdateCycles,
  program,
}: ScheduleTableRowsProps) => {
  const { groupId } = useParams();
  const { get } = useQueryParams();
  const deleteCyclesMutation = useCyclesRemove();
  const cyclesCreateForClassesMutation = useCyclesCreateByClasses();
  const { values, initialValues, errors, setErrors, dirty } =
    useFormikContext<any>();

  const paramElementId = get('elementId');

  const isCycleUnscheduled = (cycle: Cycle) => {
    const formCycle = values.classes[cycle.team_id].cycles[cycle.uid];
    return !(formCycle.start_date && formCycle.end_date);
  };

  return (
    <div data-testid="class-cycles-container">
      {classes.map((cls) => {
        const { cycles = [] } = cls;
        const allowedAddCycle =
          program.max_cycles > cycles.length && mayAddCycles;

        // Determine number of empty columns to add to complete row
        const emptyColumns =
          currentMaxCycles -
          cycles.length -
          (program.max_cycles === currentMaxCycles ? 1 : 0);

        const classHasConflicts = _get(errors, `classes.${cls.uid}`);
        const classIsPristine = isEqual(
          _get(values, `classes.${cls.uid}`),
          _get(initialValues, `classes.${cls.uid}`),
        );

        const isActive = paramElementId === cls.uid;

        const classFlagType = classHasConflicts
          ? 'conflict'
          : classIsPristine
          ? 'hidden'
          : 'pending';

        const borderColor = isActive
          ? theme.colors.borderColorRedOnActive
          : classHasConflicts
          ? theme.colors.redDark
          : classIsPristine
          ? ''
          : theme.colors.primaryDark;

        return (
          <ScheduleTableRow
            key={cls.uid}
            data-testid={`schedule-table-row-${cls.uid}`}
            borderColor={borderColor}
            active={isActive}
            checked={checked[cls.uid]}
          >
            {/* Column for the row's checkbox (row's first column) */}
            <ScheduleTableCol>
              <Show when={mayAddCycles || mayUpdateCycles}>
                <Toggle
                  id={cls.uid}
                  label={
                    <ToggleLabel
                      classFlagType={classFlagType}
                      clsName={cls.name}
                    />
                  }
                  aria-label={`Class ${cls.name}`}
                />
              </Show>
              <Show when={!mayAddCycles && !mayUpdateCycles}>
                <ToggleLabel classFlagType={classFlagType} clsName={cls.name} />
              </Show>
            </ScheduleTableCol>
            {/* Columns for each cycle that exists anywhere in the group  */}
            {cycles.map((cycle) => (
              <ScheduleTableCol key={cycle.uid} justifyContent="center">
                <Input
                  id={`cycle-${cycle.uid}`}
                  name={getDatePickerName(cls.uid, cycle.uid)}
                  warning={
                    paramElementId === cycle.uid && isCycleUnscheduled(cycle)
                  }
                  label=""
                  labelPlacement="top"
                  formatDate={serverDateFormat}
                  formatDateToShow={dateFormatShort}
                  component={RangePicker}
                  onChangeCallback={handleChangeCallback(
                    values,
                    cls,
                    cycle,
                    errors,
                    setErrors,
                  )}
                  disabledDate={(d) =>
                    !d ||
                    d.isBefore(program.start_date) ||
                    d.isAfter(program.end_date)
                  }
                  disabled={!mayUpdateCycles}
                  renderExtraFooter={() => (
                    <Show when={cycles.length > program.min_cycles}>
                      <RangePickerFooter
                        onClick={() =>
                          deleteCyclesMutation.mutateAsync({
                            cycles: [cycle],
                            groupId,
                          })
                        }
                        disabled={dirty}
                      />
                    </Show>
                  )}
                  antdLocale={antdLocale}
                />
              </ScheduleTableCol>
            ))}

            {/* Column to show add button cycle if  is allowed */}
            <Show when={allowedAddCycle}>
              <ScheduleTableCol>
                <Button
                  color="text"
                  aria-label="Add Cycle"
                  iconLeft={<IconAdd />}
                  disabled={dirty}
                  onClick={() =>
                    cyclesCreateForClassesMutation.mutateAsync({
                      classes: [cls],
                      groupId,
                    })
                  }
                >
                  Add Cycle
                </Button>
              </ScheduleTableCol>
            </Show>

            {/* Empty columns to complete row size */}
            {Array.from({ length: emptyColumns }, (_, i) => (
              <ScheduleTableCol key={i} />
            ))}
          </ScheduleTableRow>
        );
      })}
    </div>
  );
};
