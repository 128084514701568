import * as Yup from 'yup';
import { Formik, Form } from 'formik';

import { Col, HelpText, Row, Show, Text, useFormError } from '@perts/ui';
import { DefaultSettingsWarning, FormButtons, useTerms } from 'components';
import { helpArticles } from 'config';
import { RosterSignOnLockFields } from './RosterSignOnLockFields';

const SignOnTypeHelpText = () => (
  <Col shrink vAlign="center" hAlign="flex-end">
    <HelpText articleId={helpArticles.whatIsRosterLocking}>
      What is roster locking?
    </HelpText>
  </Col>
);

const ValidationSchema = Yup.object().shape({
  rosterLocked: Yup.boolean().nullable(),
  ruleEnabled: Yup.boolean().nullable(),
});

export type RosterSignOnLockFormValues = Yup.InferType<typeof ValidationSchema>;

type Props = {
  close?: () => void;
  submitButtonText: string;
  onSubmit: (values: RosterSignOnLockFormValues) => void;
  initialValues: RosterSignOnLockFormValues;
  defaultValues: RosterSignOnLockFormValues | null;
};

export const RosterSignOnLockForm = ({
  close,
  submitButtonText,
  onSubmit,
  initialValues,
  defaultValues,
}: Props) => {
  const terms = useTerms();
  const [FormError, showFormError] = useFormError();

  return (
    <>
      <Row alignItems="center">
        <Col shrink vAlign="center" hAlign="center">
          <Text as="h2">
            <span>Roster Locking</span>
          </Text>
        </Col>

        <Show when={!Boolean(close)}>
          <Col></Col>
          <SignOnTypeHelpText />
        </Show>
      </Row>

      <Formik
        initialValues={initialValues}
        validationSchema={ValidationSchema}
        enableReinitialize={true}
        onSubmit={async (values) => {
          try {
            // Clear existing form error.
            showFormError(false);

            // Perform form onSubmit.
            await onSubmit(values);

            // Close modal on successful form onSubmit.
            close && close();
          } catch (error) {
            // Display form error.
            showFormError(true);
          }
        }}
      >
        {({ values, isSubmitting }) => (
          <Form data-testid="roster-locking">
            <Row>
              <Col>
                <RosterSignOnLockFields
                  isSubmitting={isSubmitting}
                  values={values}
                />
              </Col>
            </Row>

            <Row>
              <Col>
                <FormError />
                <DefaultSettingsWarning
                  hideWhenErrors={true}
                  message={`This change doesn't match the ${terms.group}'s default settings.`}
                  defaultValues={defaultValues}
                />
              </Col>
            </Row>

            <Show when={Boolean(close)}>
              <Row>
                <SignOnTypeHelpText />
              </Row>
            </Show>

            <FormButtons close={close} submitButtonText={submitButtonText} />
          </Form>
        )}
      </Formik>
    </>
  );
};
