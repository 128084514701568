// https://docs.google.com/spreadsheets/d/1ZX4hcUol-_frFLfUYRnZ-UPEvpGsVOsWsJRw1g5tDBM

import keyBy from 'lodash/keyBy';

import { locales } from '../i18n';
import { CompositeMetricConfig, MetricItemConfig } from './types';
import { catalyze24CompletelyTrueOptions } from './answerOptions/catalyze24CompletelyTrueOptions';

export const plc24EquityResponsiveItemConfig: MetricItemConfig[] = [
  {
    label: 'plc_includes_underserved',
    likertN: 4,
    minGood: 3,
    options: catalyze24CompletelyTrueOptions,
    prompts: {
      [locales.ENGLISH]:
        'My professional learning activities include ideas and strategies ' +
        'for teaching historically underserved groups.',
    },
  },
  {
    label: 'plc_cultural_respect',
    likertN: 4,
    minGood: 3,
    options: catalyze24CompletelyTrueOptions,
    prompts: {
      [locales.ENGLISH]:
        'My professional learning activities are improving my use of ' +
        'teaching strategies that show respect for the cultural backgrounds ' +
        'of my students.',
    },
  },
  {
    label: 'plc_relevance_underserved',
    likertN: 4,
    minGood: 3,
    options: catalyze24CompletelyTrueOptions,
    prompts: {
      [locales.ENGLISH]:
        'My professional learning activities are helping me to make this ' +
        'subject relevant for my students from historically underserved ' +
        'groups/communities.',
    },
  },
  {
    label: 'plc_underserved_belong',
    likertN: 4,
    minGood: 3,
    options: {
      [locales.ENGLISH]: [
        { value: 4, label: 'Very confident' },
        { value: 3, label: 'Confident' },
        { value: 2, label: 'Somewhat confident' },
        { value: 1, label: 'Not at all confident' },
      ],
    },
    prompts: {
      [locales.ENGLISH]:
        'How confident are you in helping students from historically ' +
        'underserved communities feel like important members of your ' +
        'classroom?',
    },
  },
];

export const plc24EquityResponsiveConfig: CompositeMetricConfig = {
  label: 'plc-equity-responsive',
  items: plc24EquityResponsiveItemConfig,
  itemIndex: keyBy(plc24EquityResponsiveItemConfig, 'label'),
  name: 'Equity / Culturally-Responsive Teaching',
  description:
    'Culturally-responsive teaching is important for professional learning ' +
    'communities because it equips educators with the understanding and ' +
    'skills to create inclusive learning environments that acknowledge and ' +
    'leverage the diverse backgrounds and experiences of all students.',
};
