import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Formik, Form } from 'formik';
import pluralize from 'pluralize';

import { Button, Col, InfoBox, Modal, Row, useFormError } from '@perts/ui';
import { useTerms } from 'components';

type SchedulesRemoveFormProps = {
  close: () => void;
  onSubmit: (cycleUids: string[]) => void;
};

export const SchedulesRemoveForm = ({
  close = () => null,
  onSubmit = (cycleUids) => null,
}: SchedulesRemoveFormProps) => {
  const [FormError, showFormError] = useFormError();
  const terms = useTerms();

  const { state } = useLocation<{ cycleUids: string[] }>();
  const { cycleUids = [] } = state;
  const numOfCyclesToRemove = cycleUids.length;

  // Close the modal if opened with no Cycles or no valid Cycles to remove.
  useEffect(() => {
    if (numOfCyclesToRemove < 1) {
      close();
    }
  }, [close, numOfCyclesToRemove]);

  const title = `Delete ${pluralize(
    'Cycles',
    numOfCyclesToRemove,
    false, // don't prefix with number
  )} in ${pluralize(
    terms.classes,
    numOfCyclesToRemove,
    true, // prefix with number
  )}`;

  return (
    <Modal close={close}>
      <Modal.Title className="center">{title}</Modal.Title>

      <Formik
        enableReinitialize={true}
        initialValues={{}}
        onSubmit={async () => {
          try {
            // Clear existing form error.
            showFormError(false);

            // Perform form onSubmit.
            await onSubmit(cycleUids);

            // Close modal on successful form onSubmit.
            close();
          } catch (error) {
            // Display form error.
            showFormError(true);
          }
        }}
      >
        {({ isSubmitting, isValid }) => (
          <Form>
            <InfoBox color="warning">
              Are you sure you&rsquo;d like to delete{' '}
              {pluralize(
                'Cycles',
                numOfCyclesToRemove,
                true, // prefix with number
              ).toLowerCase()}
              ?
            </InfoBox>

            <Row>
              <Col>
                <FormError />
              </Col>
            </Row>

            <Row>
              <Col cols={6} colsSm={12}>
                <Button
                  type="button"
                  color="secondary"
                  fullWidth
                  onClick={close}
                  disabled={isSubmitting}
                  fullHeight
                >
                  Cancel
                </Button>
              </Col>

              <Col cols={6} colsSm={12}>
                <Button
                  type="submit"
                  fullWidth
                  disabled={!isValid || isSubmitting}
                  loading={isSubmitting}
                >
                  Delete{' '}
                  {pluralize(
                    'Cycles',
                    numOfCyclesToRemove,
                    false, // don't prefix with number
                  )}
                </Button>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
