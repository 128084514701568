import { lighten, darken } from 'polished';

// The lighten and darken only should used to define to new colors in colorsBrand.
// call the new colors primaryDarkenX and primaryLightenX where X is the number used to define it.
// It is useful if add the new colors to colorAliases and name them according to their use case.

// PERTS Style Guide
// https://docs.google.com/document/d/1OLRm8TcnhIVQ-UW0cCWvTjVrbwYlJZVGvjpwAMWoVwQ

const colorsBrand = {
  primary: '#1C9DE3',
  primaryDark: '#061D2A',
  primaryDarken05: darken(0.05, '#1C9DE3'),
  primaryLighten1: lighten(0.1, '#1C9DE3'),
  primaryLight: '#ACDBF4',

  secondary: '#283043',
  secondaryDark: '#1f2637',
  secondaryLight: '#3d435d',

  grayDark: '#202020',
  grayMedium: '#464646',
  grayLighten3: lighten(0.3, '#464646'),
  grayLighten65: lighten(0.65, '#464646'),
  grayLight: '#DDDDDD',

  green: '#44E790',
  greenDark: '#2B8254',
  red: '#E74444',
  redDark: '#CE4545',
  yellow: '#E7DA44',

  offWhite: '#F7F8FA',
  white: '#FFFFFF',
  black: '#000000',
};

const colorsModified = {
  grayLighten3: lighten(0.3, colorsBrand.grayMedium), // #939393
  grayLighten65: lighten(0.65, colorsBrand.grayMedium), // #ececec
  greenDarken3: darken(0.3, colorsBrand.green), // #118145
  greenLighten35: lighten(0.35, colorsBrand.green), // #e2fbee
  primaryDarken05: darken(0.05, colorsBrand.primary), // #198dcc
  primaryLighten1: lighten(0.1, colorsBrand.primary), // #49b1e9
  redDarken1: darken(0.1, colorsBrand.red), // #dc1c1c
  redDarken2: darken(0.2, colorsBrand.red), // #af1616
  redLighten1: lighten(0.1, colorsBrand.red), // #ed7171
  redLighten2: lighten(0.2, colorsBrand.red), // #f39e9e
  redLighten35: lighten(0.35, colorsBrand.red), // #fbe2e2
};

const colorsAliases = {
  info: colorsBrand.yellow,
  success: colorsBrand.green,
  warning: colorsBrand.red,
  disabled: colorsBrand.grayMedium,
  text: colorsBrand.grayMedium,
  textDisabled: colorsBrand.grayMedium,
  fieldBackgroundDisabled: colorsModified.grayLighten65,
  textBgGreen: colorsModified.greenLighten35,
  textBgRed: colorsModified.redLighten35,
  textColorLightGrayOnWhite: colorsModified.grayLighten3,
  textColorGreenOnGreen: colorsModified.greenDarken3,
  textColorRedOnRed: colorsModified.redDarken2,
  borderColorRedOnActive: colorsModified.redLighten2,
  boxShadowLightRedOnFocus: colorsModified.redLighten2,
  buttonBgDarkBlueOnActive: colorsModified.primaryDarken05,
  buttonBgDarkBlueOnHover: colorsModified.primaryLighten1,
  buttonBgDarkRedOnActive: colorsModified.redDarken1,
  buttonBgDarkRedOnHover: colorsModified.redLighten1,
  appBackground: colorsBrand.offWhite,
};

// https://dribbble.com/shots/2903444-12-Color-Blindness-Safe-Graph-Colors
const colorsLineGraph = [
  colorsBrand.primary, // originally #2196F3
  '#F13447',
  '#7B1FA2',
  '#15B777',
  '#FF803D',
  '#283593',
  '#C0CA33',
  '#D601BD',
  '#004D40',
  '#00E5FF',
  '#9F2C10',
  '#FDD835',
  // Adding more colors from the original 12 in the resource linked above.
  '#00A2AB',
  '#F683E9',
  colorsModified.grayLighten3,
  '#000000',
];

const units = {
  appBarHeight: '80px',
  appSidebarMenuMarginTop: '48px',
  appSidebarWidth: '220px',
  appSidebarWidthCollapsed: '42px',

  // Padding at AppContent
  bodyPadding: '30px',
  bodyPaddingMd: '20px',
  bodyPaddingSm: '10px',

  // App wide border-radius.
  borderRadius: '5px',

  // App wide checkbox width/height.
  checkboxSize: '24px',

  paddingExpander: '6px',

  // Width of Action Toggle or Expand, in pixels
  cardMarginAction: 34,

  // Padding
  paddingSm: '8px',
  paddingMd: '16px',
  paddingLg: '22px',

  // Padding between field elements.
  fieldPadding: '12px',
  // Padding between internal to field elements: label, input, error text.
  fieldPaddingInternal: '8px',

  // Field and button heights should match so they align when side by side.
  fieldHeight: '40px',
  buttonHeight: '40px',

  // Padding button.
  buttonPadding: '12px',
  buttonPaddingMd: '10px',
  buttonPaddingSm: '8px',

  // App font size base
  fontFamily: '"Open Sans", sans-serif',
  fontBase: '16px',
  lineHeight: '1.4em',

  // Used in cards for the first row.
  // Should match a typical text field, 22 + 2 * padding + 2 * border
  lineHeightTitle: '40px',

  // Grids
  gridColumns: 12,
  gridBreakpointSmall: 600, // in pixels
  gridBreakpointMedium: 992, // in pixels
  gridBreakpointLarge: 1200, // in pixels
  gridDefaultGutter: 16, // in pixels

  // menu
  menuWidth: '200px',
  menuIconSize: '22px',
  menuCollapseWidth: 992,

  // weights
  boldWeight: 600,

  liFontTopPosition: '.14em',
  padding: '16px',
};

const theme = {
  colors: {
    ...colorsAliases,
    ...colorsBrand,
  },
  colorsLineGraph,
  units,
};

export default theme;
