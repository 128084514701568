import { PortalType } from '@perts/model';

import { Group } from 'models';
import { getPortalMessageFromFormValues } from 'pages/Classes/shared';

// Combines defaults for team, classroom, and survey settings from two places:
// * The group's `default_settings` property
// * Hard-coded defaults, like `portal_type`
export const getGroupDefaultSettings = (group: Group) => {
  // Hard-coded defaults:
  const defaultPortalType = PortalType.EMAIL;

  // Group defaults:
  const {
    metrics,
    schedules = [],
    participant_pattern,
    participant_ending,
    portal_message: groupDefaultPortalMessage,
    portal_type: groupDefaultPortalType = defaultPortalType,
    target_group_name,
    roster_locked,
    parent_organizations = [],
    gender_question_disabled,
  } = group.default_settings;

  // Combine:
  const portal_type: PortalType = groupDefaultPortalType || defaultPortalType;
  const portal_message =
    groupDefaultPortalMessage ||
    getPortalMessageFromFormValues({
      portal_type,
      // If there's no groupDefaultPortalMessage, then the rule is definitely
      // off/false/disabled.
      ruleSwitch: false,
    });

  return {
    metrics,
    // All active metrics have open_responses active.
    open_responses: metrics,
    schedules,
    participant_pattern,
    participant_ending,
    portal_message,
    portal_type,
    target_group_name,
    roster_locked,
    parent_organizations,
    gender_question_disabled,
  };
};
