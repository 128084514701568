import { useContext } from 'react';
import { Formik, Form } from 'formik';
import { User, Class, selectUserClasses } from 'models';
import { Button, Col, Row, Modal, useFormError, Show } from '@perts/ui';
import { MemberLeaveFormMessage } from './MemberLeaveFormMessage';

import TermsContext from 'components/TermsContext';

export type MemberLeaveFormValues = {
  user: User;
};

export type MemberLeaveFormProps = {
  close: (params?: any) => void;
  onSubmit: (values: MemberLeaveFormValues) => void;
  user: User;
  memberToLeaveIsUniqueLead: boolean;
  classes: Class[];
};

export const MemberLeaveForm = ({
  close,
  onSubmit,
  user,
  memberToLeaveIsUniqueLead,
  classes,
}: MemberLeaveFormProps) => {
  const [FormError, showFormError] = useFormError();
  const terms = useContext(TermsContext);

  return (
    <Modal close={close}>
      <Modal.Title className="center">{`Leave ${terms.group}`}</Modal.Title>

      <Formik
        enableReinitialize={true}
        initialValues={{
          user,
        }}
        onSubmit={async (values) => {
          try {
            // Clear existing form error.
            showFormError(false);

            // Perform form onSubmit.
            await onSubmit(values);

            // Close modal on successful form onSubmit.
            close({ redirectToHome: true });
          } catch (error) {
            // Display form error.
            showFormError(true);
          }
        }}
      >
        {({ isSubmitting, isValid, values }) => {
          const mayLeaveGroup =
            selectUserClasses(values.user, classes).length === 0;

          return (
            <Form>
              <Row>
                <Col>
                  <MemberLeaveFormMessage
                    mayLeaveGroup={mayLeaveGroup}
                    memberToLeaveIsUniqueLead={memberToLeaveIsUniqueLead}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormError />
                </Col>
              </Row>
              <Row>
                <Col cols={6} colsSm={12}>
                  <Button
                    fullHeight
                    type="button"
                    color="secondary"
                    fullWidth
                    onClick={close}
                    disabled={isSubmitting}
                  >
                    Cancel
                  </Button>
                </Col>

                <Show when={mayLeaveGroup && !memberToLeaveIsUniqueLead}>
                  <Col cols={6} colsSm={12}>
                    <Button
                      type="submit"
                      fullWidth
                      disabled={!isValid || isSubmitting}
                      loading={isSubmitting}
                    >
                      {`Leave ${terms.group}`}
                    </Button>
                  </Col>
                </Show>
              </Row>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};
