type GetUpdatedErrorsFromCycle = {
  cycleId: string;
  modifiedCycleId: string;
  currentCycleErrors: string[];
  errorsToAddModifiedCycle: string[];
  errorsToRemoveModifiedCycle: string[];
};

export const getUpdatedErrorsFromCycle = ({
  cycleId,
  modifiedCycleId,
  currentCycleErrors = [],
  errorsToAddModifiedCycle = [],
  errorsToRemoveModifiedCycle = [],
}: GetUpdatedErrorsFromCycle) => {
  let updatedCycleErrors: string[];

  if (modifiedCycleId === cycleId) {
    // Add new errors to the modified cycle
    updatedCycleErrors = [...currentCycleErrors, ...errorsToAddModifiedCycle];

    // Remove errors from the modified cycle
    updatedCycleErrors = updatedCycleErrors.filter(
      (c) => !errorsToRemoveModifiedCycle.includes(c),
    );
  } else {
    const hasConflict = errorsToAddModifiedCycle.includes(cycleId);

    if (hasConflict) {
      // Add modified cycle id to the current cycle errors
      updatedCycleErrors = [...currentCycleErrors, modifiedCycleId];
    } else {
      // Remove modified cycle id from the current cycle errors
      updatedCycleErrors = currentCycleErrors.filter(
        (uid) => uid !== modifiedCycleId,
      );
    }
  }

  return updatedCycleErrors;
};
