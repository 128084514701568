import { User } from 'models';
import { Show } from '@perts/ui';
import { EmptyList } from 'components/EmptyList';
import { NetworkMemberCard } from './NetworkMemberCard';
import { NetworkMembersActionbar } from './NetworkMembersActionbar';
import useToggles from 'utils/useToggles';
import useFilterField from 'utils/useFilterField';
import { useTerms } from 'components/TermsContext';

export type NetworkMembersRenderProps = {
  members: User[];
  mayAddMembers: boolean;
  mayRemoveMembers: boolean;
};

export const NetworkMembersRender = ({
  members,
  mayAddMembers,
  mayRemoveMembers,
}: NetworkMembersRenderProps) => {
  const terms = useTerms();
  const { filteredItems, FilterField } = useFilterField(
    members,
    ['name', 'email'],
    {
      label: `Filter by ${terms.networkMember.toLowerCase()} name or email`,
    },
  );
  const { ControlToggle, Toggle, checked, someMarked } = useToggles(
    members,
    'uid',
  );

  return (
    <>
      <NetworkMembersActionbar
        checked={checked}
        ControlToggle={ControlToggle}
        disabled={!someMarked}
        mayAddMembers={mayAddMembers}
        mayRemoveMembers={mayRemoveMembers}
        filterComponent={FilterField}
      />
      <Show when={members.length === 0}>
        <EmptyList />
      </Show>

      {filteredItems.map((member) => {
        const toggle = mayRemoveMembers ? (
          <Toggle id={member.uid} label="" aria-label={member.name} />
        ) : null;
        return (
          <NetworkMemberCard key={member.uid} member={member} toggle={toggle} />
        );
      })}
    </>
  );
};
