import { useQuery } from 'react-query';

import {
  Group,
  Report,
  reportsQueryByUserIdAndGroupId,
  useCurrentUserQuery,
  reportGetById,
  reportsQueryByNetworkId,
} from 'models';
import { useParams } from 'pages';

import { useQueriesTyped } from 'utils/useQueriesTyped';

// -----------------------------------------------------------------------------
//   queryKey Generators
// -----------------------------------------------------------------------------

export const queryKeyReport = (reportId: string) => ['report', reportId];

export const queryKeyReportsByGroup = (groupId: string) => [
  'reports',
  'byGroup',
  groupId,
];

export const queryKeyReportsByNetwork = (networkId: string) => [
  'reports',
  'byNetwork',
  networkId,
];

// -----------------------------------------------------------------------------
//   API Hooks
// -----------------------------------------------------------------------------

export const useReportsQueryByGroupId = (
  userId: string = '',
  groupId: string = '',
) =>
  useQuery<Report[], Error>(
    queryKeyReportsByGroup(groupId),
    () => reportsQueryByUserIdAndGroupId(userId, groupId),
    {
      enabled: Boolean(userId) && Boolean(groupId),
    },
  );

export const useReportGetById = (reportId: string) =>
  useQuery<Report, Error>(
    // queryKey
    queryKeyReport(reportId),
    // queryFn
    () => reportGetById(reportId),
  );

export const useReportsQueryByParams = () => {
  const { groupId } = useParams();
  const { data: user } = useCurrentUserQuery();

  return useReportsQueryByGroupId(user?.uid, groupId);
};

export const useReportQueryByParams = () => {
  const { reportId } = useParams();
  return useReportGetById(reportId);
};

// Query Reports of provided Group[] `groups`.

type UseReportsQueryByGroups = (
  // Optional, it's a dependent query so the parent may not have resolved yet.
  groups?: Group[],
) => any;

export const useReportsQueryByGroups: UseReportsQueryByGroups = (
  groups = [],
) => {
  const { data: user } = useCurrentUserQuery();

  const queries = useQueriesTyped(
    groups.map((group) => ({
      queryKey: queryKeyReportsByGroup(group.uid),
      queryFn: user
        ? () => reportsQueryByUserIdAndGroupId(user.uid, group.uid)
        : () => [],
    })),
  );

  const data = queries
    // Filter to arrays only, so we can map over them.
    .filter((query) => Array.isArray(query.data))
    // Flatten data arrays from each query into a single array.
    .flatMap((query) => query.data);

  // If any query is loading, marking isLoading as true.
  const isLoading = queries.some((query) => query.isLoading);

  // Default error state to no error.
  let isError = false;
  let error: Error | null = null;

  // Then override with the first error found, if any.
  const queryWithError = queries.find((query) => query.isError);

  if (queryWithError) {
    ({ isError, error } = queryWithError);
  }

  return {
    data,
    error,
    isLoading,
    isError,
  };
};

export const useReportsQueryByNetworkId = (networkId: string) =>
  useQuery<Report[], Error>(queryKeyReportsByNetwork(networkId), () =>
    reportsQueryByNetworkId(networkId),
  );
