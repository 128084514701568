import type { DemographicRow } from './types';
import { getAttrLabelsWithGaps } from './getAttrLabelsWithGaps';

// Figure out which items contain gaps among any of their values. For
// instance, if the attribute value "basic needs insecure" has a gap compared
// to "all" for the item "teacher_caring_1", then the item "teacher_caring_1"
// should be listed.
export const getItemsWithGaps = (data: DemographicRow[]) =>
  data
    .filter((row) => getAttrLabelsWithGaps([row]).length > 0)
    .map((row) => row.label);
