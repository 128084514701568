import {
  selectUserIsAdmin,
  selectUserIsNetworkManager,
  sortUserByEmail,
  useCurrentUserQuery,
  User,
  useUsersQueryByNetworkId,
} from 'models';
import { useParams } from 'pages';

import { NetworkMembersRender } from './NetworkMembersRender';
import { ErrorMessageBox } from 'components/ErrorMessageBox';
import Loading from 'components/Loading';
import { getMessageFromErrors } from '@perts/util';

export const NetworkMembers = () => {
  const { networkId } = useParams();

  const currentUserQuery = useCurrentUserQuery();

  // Query for Users of Network.
  const queryUsers = useUsersQueryByNetworkId(networkId);

  if (queryUsers.isLoading || currentUserQuery.isLoading) {
    return <Loading />;
  }

  // Display any errors.
  if (!queryUsers.isSuccess || !currentUserQuery.isSuccess) {
    return (
      <ErrorMessageBox>
        {getMessageFromErrors([queryUsers.error])}
      </ErrorMessageBox>
    );
  }

  const members: User[] = queryUsers.data
    .map((user) => ({
      ...user,
    }))
    .sort(sortUserByEmail);

  const user = currentUserQuery.data;
  const userIsAdmin = selectUserIsAdmin(user);
  const userIsNetworkManager = selectUserIsNetworkManager(user, networkId);

  return (
    <NetworkMembersRender
      members={members}
      mayAddMembers={userIsAdmin || userIsNetworkManager}
      mayRemoveMembers={userIsAdmin || userIsNetworkManager}
    />
  );
};
