import { Group, Program } from 'models';

export default function defaultSettingsMetrics(group: Group, program: Program) {
  // If default metrics have been set on the Group, return these.
  const { metrics: defaultGroupMetricIds } = group.default_settings;

  if (defaultGroupMetricIds) {
    return defaultGroupMetricIds;
  }

  // Fallback to returning the default_active metrics on Program.
  const defaultProgramMetricIds = program.metrics
    .filter(({ default_active }) => default_active)
    .map(({ uid = '' }) => uid);

  return defaultProgramMetricIds;
}
