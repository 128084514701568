import {
  getAuth,
  onAuthStateChanged,
  User as FirebaseUser,
} from 'firebase/auth';

export const getFirebaseUser = (): Promise<FirebaseUser> =>
  new Promise((resolve) => {
    onAuthStateChanged(getAuth(), (firebaseUser) => {
      if (firebaseUser) {
        resolve(firebaseUser);
      }
    });
  });
