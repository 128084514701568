import {
  useClassesQueryByParams,
  useCyclesQueryByParams,
  useProgramGetByParams,
} from 'models';
import { ParticipationByParticipantRender } from './ParticipationByParticipantRender';
import Loading from 'components/Loading';
import { ErrorMessageBox } from 'components/ErrorMessageBox';

import { getMessageFromErrors } from '@perts/util';

export const ParticipationByParticipant = () => {
  // Query for Classes associated with Group.
  const classesQuery = useClassesQueryByParams();

  // Query for cycles from all classes.
  const cyclesQuery = useCyclesQueryByParams();

  const programQuery = useProgramGetByParams();

  // Display loading.
  if (
    classesQuery.isLoading ||
    cyclesQuery.isLoading ||
    programQuery.isLoading
  ) {
    return <Loading />;
  }

  // Display any errors.
  if (!classesQuery.isSuccess || !cyclesQuery.isSuccess || !programQuery.data) {
    return (
      <ErrorMessageBox>
        {getMessageFromErrors([
          classesQuery.error,
          cyclesQuery.error,
          programQuery.error,
        ])}
      </ErrorMessageBox>
    );
  }

  return (
    <ParticipationByParticipantRender
      classes={classesQuery.data}
      cycles={cyclesQuery.data}
      program={programQuery.data}
    />
  );
};
