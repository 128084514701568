import { Formik, Form } from 'formik';
import pluralize from 'pluralize';

import { User } from 'models';

import { Button, Col, Row, Modal, useFormError, Show } from '@perts/ui';
import { useTerms } from 'components/TermsContext';

export type MembersSendInvitationFormValues = {
  members: User[];
};

export type MembersSendInvitationFormProps = {
  close: () => void;
  onSubmit: (values: MembersSendInvitationFormValues) => void;
  checkedMembers: User[];
  membersToSendInvitation: User[];
};

export const MembersSendInvitationForm = ({
  close,
  onSubmit,
  checkedMembers,
  membersToSendInvitation,
}: MembersSendInvitationFormProps) => {
  const [FormError, showFormError] = useFormError();
  const terms = useTerms();

  const totalCheckedMembers = checkedMembers.length;
  const totalInvitationsMembers = membersToSendInvitation.length;
  const totalUnsendable = totalCheckedMembers - totalInvitationsMembers;

  return (
    <Modal close={close}>
      <Modal.Title className="center">Send Invitations</Modal.Title>

      <Formik
        enableReinitialize={true}
        initialValues={{
          members: membersToSendInvitation,
        }}
        onSubmit={async (values) => {
          try {
            // Clear existing form error.
            showFormError(false);

            // Perform form onSubmit.
            await onSubmit(values);

            // Close modal on successful form onSubmit.
            close();
          } catch (error) {
            // Display form error.
            showFormError(true);
          }
        }}
      >
        {({ isSubmitting, isValid }) => (
          <Form>
            <Row>
              <Col>
                <Show when={totalCheckedMembers > totalInvitationsMembers}>
                  {totalUnsendable} of {totalCheckedMembers} selected{' '}
                  {pluralize(
                    terms.groupMember.toLowerCase(),
                    totalCheckedMembers,
                  )}{' '}
                  have an existing PERTS account.{' '}
                </Show>
                <Show when={totalInvitationsMembers === 0}>
                  No invitations can be sent.
                </Show>
                <Show when={totalInvitationsMembers > 0}>
                  Email invitations can be sent to{' '}
                  {pluralize(
                    terms.groupMember.toLowerCase(),
                    totalInvitationsMembers,
                    true,
                  )}
                  .
                </Show>
              </Col>
            </Row>

            <Row>
              <Col>
                <FormError />
              </Col>
            </Row>

            <Row>
              <Col cols={6} colsSm={12}>
                <Button
                  type="button"
                  color="secondary"
                  fullWidth
                  onClick={close}
                  disabled={isSubmitting}
                  fullHeight
                >
                  Cancel
                </Button>
              </Col>

              <Col cols={6} colsSm={12} hAlign="flex-end">
                <Show when={totalInvitationsMembers > 0}>
                  <Button
                    type="submit"
                    fullWidth
                    disabled={!isValid || isSubmitting}
                    loading={isSubmitting}
                  >
                    Send Email to{' '}
                    {pluralize(
                      terms.groupMember,
                      membersToSendInvitation.length,
                      true,
                    )}
                  </Button>
                </Show>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
