// https://docs.google.com/spreadsheets/d/1EKKzZjI-eCtekB_S_SsNnBQpGEKVIKueZIkBD6W8QJc

import keyBy from 'lodash/keyBy';

import { locales } from '../i18n';
import { CompositeMetricConfig, MetricItemConfig } from './types';

export const schoolStudentVoiceItemConfig: MetricItemConfig[] = [
  {
    label: 'school_student_voice_ideas',
    likertN: 6,
    prompts: {
      [locales.ENGLISH]: `In this school, adults take my ideas seriously.`,
      [locales.SPANISH]: `En esta escuela, los adultos se toman en serio mis ideas.`,
    },
    minGood: 5,
  },
];

export const schoolStudentVoiceConfig: CompositeMetricConfig = {
  label: 'school-student-voice',
  items: schoolStudentVoiceItemConfig,
  itemIndex: keyBy(schoolStudentVoiceItemConfig, 'label'),
};
