import styled from 'styled-components/macro';
import { theme } from 'components';
import { Button, IconUserX } from '@perts/ui';

import { useCurrentUserQuery } from 'models';
import { useImpersonate } from 'utils/useImpersonate';

export const TopMenuButtonImpersonateStyled = styled(Button)`
  color: ${theme.colors.warning};
  background: ${theme.colors.white};
  border: 1px solid ${theme.colors.warning};

  &:hover {
    color: ${theme.colors.warning};
    background: ${theme.colors.white};
    border: 1px solid ${theme.colors.warning};
  }

  svg path {
    stroke: ${theme.colors.warning};
  }

  span {
    font-size: 13px;
  }
`;

export const TopMenuButtonImpersonate = () => {
  const { getImpersonateUid, setImpersonateUid } = useImpersonate();
  const { data: user } = useCurrentUserQuery();

  const impersonateUid = getImpersonateUid();

  if (!impersonateUid || !user) {
    return null;
  }

  const stopImitatingUser = () => setImpersonateUid(null);

  return (
    <TopMenuButtonImpersonateStyled
      onClick={stopImitatingUser}
      iconRight={<IconUserX />}
    >
      <span>{user.email}</span>
    </TopMenuButtonImpersonateStyled>
  );
};
