import { useMemo } from 'react';
import groupBy from 'lodash/groupBy';
import omit from 'lodash/omit';

import {
  AttributeConfig,
  attributeValueSort,
  getParticipantAttributes,
} from '@perts/config';
import { SampleResults } from '@perts/model';
import { Col, Link, Row } from '@perts/ui';
import { formatPercent } from '@perts/util';

import { toGroupParticipation, toNetworkParticipation } from 'pages/routes';
import {
  ReactTable,
  ReactTableFooter,
  ReactTableHead,
  // Importing `td` styles directly since this table isn't using react-table and
  // the `td` component expects a react-table `cell` to be provided.
  ReactTableTdStyled,
  ReactTableTh,
  ReactTableTr,
  ReactTableWrap,
} from 'components';

type InvalidAttrData = {
  conf?: AttributeConfig;
  valueLabel: string;
  responseCount: number;
};

type ValidAttrData = Required<InvalidAttrData>;

const wrangleData = (programLabel: string, sampleData: SampleResults) => {
  const allParticipantsCount = sampleData.all_participants;
  const attrData = omit(sampleData, 'all_participants');
  const participantAttributes = getParticipantAttributes(programLabel);

  const data = Object.entries(attrData)
    .map(([attrPath, responseCount]): InvalidAttrData | ValidAttrData => {
      const [attributeLabel, attributeValue] = attrPath.split('.');
      const attributeConfig = participantAttributes.find(
        (c) => c.label === attributeLabel,
      );
      if (!attributeConfig) {
        // eslint-disable-next-line no-console
        console.debug(`Ignoring participant attribute: "${attrPath}".`);
      }
      return {
        conf: attributeConfig,
        valueLabel: attributeValue,
        responseCount,
      };
    })
    .filter((x): x is ValidAttrData => Boolean(x.conf))
    .map(({ conf, valueLabel, responseCount }) => ({
      attributeLabel: conf.label,
      attributeName: conf.name,
      valueLabel,
      valueName:
        conf.values.find(({ label }) => label === valueLabel)?.name || '',
      responseCount: String(responseCount),
      percent: formatPercent(responseCount / allParticipantsCount),
    }));

  data.unshift({
    attributeLabel: 'all_participants',
    attributeName: 'All',
    valueLabel: '',
    valueName: 'All Responses',
    responseCount: String(allParticipantsCount),
    percent: '—',
  });

  return data;
};

type Props = {
  groupId?: string;
  lastRun: string;
  networkId?: string;
  programLabel: string;
  sampleData: SampleResults;
};

export const SurveySampleOverviewTable = ({
  groupId,
  lastRun,
  networkId,
  programLabel,
  sampleData,
}: Props) => {
  const rowsData = useMemo(
    () => wrangleData(programLabel, sampleData),
    [programLabel, sampleData],
  );
  const rowsBySubset = groupBy(rowsData, 'attributeLabel');

  return (
    <ReactTableWrap>
      <ReactTable>
        <ReactTableHead>
          <tr>
            <ReactTableTh>Variable</ReactTableTh>
            <ReactTableTh>Category</ReactTableTh>
            <ReactTableTh># of responses</ReactTableTh>
            <ReactTableTh>% of responses</ReactTableTh>
          </tr>
        </ReactTableHead>

        <tbody>
          {Object.entries(rowsBySubset).map(([attributeLabel, rows]) =>
            [...rows]
              .sort((rowA, rowB) =>
                attributeValueSort(rowA.valueName, rowB.valueName),
              )
              .map((row, i) => (
                <ReactTableTr key={`${attributeLabel}-${row.valueLabel}`}>
                  {i === 0 && (
                    <ReactTableTdStyled rowSpan={rows.length}>
                      {row.attributeName}
                    </ReactTableTdStyled>
                  )}
                  <ReactTableTdStyled>{row.valueName}</ReactTableTdStyled>
                  <ReactTableTdStyled>{row.responseCount}</ReactTableTdStyled>
                  <ReactTableTdStyled>{row.percent}</ReactTableTdStyled>
                </ReactTableTr>
              )),
          )}
        </tbody>
      </ReactTable>

      {lastRun && (
        <ReactTableFooter>
          <Row>
            <Col hAlign="flex-end">
              {groupId && (
                <em>
                  <Link to={toGroupParticipation(groupId)}>
                    Participation details
                  </Link>
                </em>
              )}
              {networkId && (
                <em>
                  <Link to={toNetworkParticipation(networkId)}>
                    Participation details
                  </Link>
                </em>
              )}
            </Col>
          </Row>
        </ReactTableFooter>
      )}
    </ReactTableWrap>
  );
};
