import { getMessageFromErrors } from '@perts/util';
import { Show } from '@perts/ui';
import { ErrorMessageBox, Loading } from 'components';
import {
  selectUserIsAdmin,
  useClassGetByParams,
  useCurrentUserQuery,
  useGroupGetByParams,
  useProgramGetByParams,
} from 'models';

import { ClassRosterSettingsSignOnTypeForm } from './ClassRosterSettingsSignOnTypeForm/ClassRosterSettingsSignOnTypeForm';
import { ClassRosterSettingsSignOnRuleForm } from './ClassRosterSettingsSignOnRuleForm/ClassRosterSettingsSignOnRuleForm';
import { ClassRosterSettingsSignOnLockForm } from './ClassRosterSettingsSignOnLockForm/ClassRosterSettingsSignOnLockForm';
import { ClassRosterSettingsTargetGroupForm } from './ClassRosterSettingsTargetGroupForm/ClassRosterSettingsTargetGroupForm';

export const ClassRosterSettings = () => {
  const userQuery = useCurrentUserQuery();
  const programQuery = useProgramGetByParams();
  const groupQuery = useGroupGetByParams();
  const classQuery = useClassGetByParams();

  // Display loading.
  if (
    userQuery.isLoading ||
    programQuery.isLoading ||
    groupQuery.isLoading ||
    classQuery.isLoading
  ) {
    return <Loading />;
  }

  // Display any errors.
  if (
    !userQuery.isSuccess ||
    !programQuery.isSuccess ||
    !groupQuery.isSuccess ||
    !classQuery.isSuccess
  ) {
    return (
      <ErrorMessageBox>
        {getMessageFromErrors([
          userQuery.error,
          programQuery.error,
          groupQuery.error,
          classQuery.error,
        ])}
      </ErrorMessageBox>
    );
  }

  const program = programQuery.data;
  const userIsAdmin = selectUserIsAdmin(userQuery.data);

  return (
    <>
      <ClassRosterSettingsSignOnTypeForm
        group={groupQuery.data}
        cls={classQuery.data}
      />

      <ClassRosterSettingsSignOnRuleForm
        group={groupQuery.data}
        cls={classQuery.data}
        userIsAdmin={userIsAdmin}
      />

      <ClassRosterSettingsSignOnLockForm
        group={groupQuery.data}
        cls={classQuery.data}
      />

      <Show when={program && program.target_group_enabled}>
        <ClassRosterSettingsTargetGroupForm
          group={groupQuery.data}
          cls={classQuery.data}
        />
      </Show>
    </>
  );
};
