import { useState } from 'react';

import { Row, Col } from '@perts/ui';

import { classGetById, Class, Cycle, Program, selectClassCycles } from 'models';
import {
  ChooseCycleProgressForm,
  ChooseCycleProgressFormValues,
} from '../ChooseCycleProgressForm';
import { ParticipationSingleCycle } from './ParticipationSingleCycle';
import { ParticipationMultiCycle } from './ParticipationMultiCycle';

type Props = {
  classes: Class[];
  cycles: Cycle[];
  program: Program;
};

type State = { selectedClass?: Class; selectedCycle?: Cycle };

export const ParticipationByParticipantRender = ({
  classes,
  cycles,
  program,
}: Props) => {
  // Important to update these two state values atomically. We don't want to
  // trigger renders with inconsistent values.
  const [{ selectedClass, selectedCycle }, setSelected] = useState<State>({});

  const onSubmit = async (values: ChooseCycleProgressFormValues) => {
    const { cls, cycle } = values;

    // Clean previous selected values to hide ParticipationProgress
    setSelected({ selectedClass: undefined, selectedCycle: undefined });

    // Call to update special JWT related to class selected
    await classGetById(cls.uid);

    // Update selected values
    setSelected({ selectedClass: cls, selectedCycle: cycle });
  };

  const classCycles = selectedClass
    ? selectClassCycles(selectedClass, cycles)
    : [];
  const displayMultiCycleTable = selectedClass && !selectedCycle;
  const displaySingleCycleTable = selectedClass && selectedCycle;

  return (
    <Row>
      <Col>
        <ChooseCycleProgressForm
          classes={classes}
          cycles={cycles}
          onSubmit={onSubmit}
        />
        {displayMultiCycleTable && (
          <ParticipationMultiCycle
            cls={selectedClass}
            cycles={classCycles}
            program={program}
          />
        )}
        {displaySingleCycleTable && (
          <ParticipationSingleCycle
            cls={selectedClass}
            cycle={selectedCycle}
            program={program}
          />
        )}
      </Col>
    </Row>
  );
};
