import { locales } from '../../i18n';

export const catalyze23AnswerOptions = {
  [locales.ENGLISH]: [
    { value: 1, label: 'Not at all true' },
    { value: 2, label: 'A little true' },
    { value: 3, label: 'Somewhat true' },
    { value: 4, label: 'Mostly true' },
    { value: 5, label: 'Completely true' },
  ],
};
