import { useRef, useState } from 'react';
import { CustomLegend } from './CustomLegend';

// Prevent the custom legend from rendering until the `chartInstance` has been
// created and provided by Chart JS so that the CustomLegend's useState is
// properly initialized.
const CustomComponentLoader = ({ isChartRefReady, chartInstance }) =>
  isChartRefReady ? <CustomLegend chartInstance={chartInstance} /> : null;

export function useCustomLegend<T>() {
  const chartRef = useRef<T | null>(null);
  const [isChartRefReady, setIsChartRefReady] = useState(false);

  const handleChartRef = (ref: T | null) => {
    if (ref) {
      chartRef.current = ref;

      // Note: Since React components won't rerender based on ref value changes,
      // this state change is used to signal that the chartInstance is ready.
      setIsChartRefReady(true);
    }
  };

  return {
    chartRef,
    handleChartRef,
    CustomLegend: () => (
      <CustomComponentLoader
        isChartRefReady={isChartRefReady}
        chartInstance={chartRef.current}
      />
    ),
  };
}
