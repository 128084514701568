export enum ProgressDescription {
  unknown = '(data not available)',
  completed = '✓',
  started = 'started',
  notStarted = '',
}

export const getProgressDescription = (progress: number | null) => {
  if (progress === null) {
    return ProgressDescription.unknown;
  }

  if (isNaN(progress)) {
    throw new Error(`Invalid progress value: "${progress}".`);
  }

  return progress === 100
    ? ProgressDescription.completed
    : progress > 0
    ? ProgressDescription.started
    : ProgressDescription.notStarted;
};
