import { cycleCompletionThreshold } from '@perts/config';

import type {
  OrganizationRespondentsByCycle,
  TeamRespondentsByCycle,
} from '@perts/model';

// All these types have:
// * participants_by_cycle: number[];
// * respondents_by_cycle: number[];
type ChildRespondentsByCycle =
  | OrganizationRespondentsByCycle // from NetworkData
  | TeamRespondentsByCycle; // from OrganizationData or OrganizationUserData

// For use with datasets, specifically respondent counts.
// Which classes (of a community) or communities (of a network) have reached
// 50% participation in any cycle?
export const getActiveChildren = (data: ChildRespondentsByCycle[]) =>
  data.filter((row) =>
    row.respondents_by_cycle
      .map((count, i) => count / (row.participants_by_cycle[i] || NaN))
      .some((ratio) => ratio >= cycleCompletionThreshold),
  );
