import groupBy from 'lodash/groupBy';

import { SurveySampleOverviewRow } from 'models';
import { toGroupParticipation, toNetworkParticipation } from 'pages/routes';
import DataTable from 'components/DataTable';
import { Col, Link, Row } from '@perts/ui';

const subsetLabels = {
  all: <>All</>,
  race: <>Race/ethnicity group</>,
  gender: <>Gender group</>,
  financial_stress: <>Financial Stress</>,
  target_group: <>Focal group</>,
};

// The keys here are defined in rserve/copilot_subset_config.csv. We want to
// be able to rename them for display.
const subsetValueMap = {
  'Target Grp.': 'Focal Group',
  'Not Target Grp.': 'Not Focal Group',
};

type Props = {
  data: SurveySampleOverviewRow[];
  reportDate: string;
  orgId?: string;
  networkId?: string;
};

export const SurveySampleOverviewTable = ({
  data,
  reportDate,
  orgId,
  networkId,
}: Props) => {
  const rowsBySubset = groupBy(data, 'subset_type');

  return (
    <>
      <DataTable>
        <thead>
          <tr>
            <th>Variable</th>
            <th>Category</th>
            <th># of respondents</th>
            <th>% of respondents</th>
          </tr>
        </thead>
        <tbody>
          {Object.entries(rowsBySubset).map(([subset, rows]) =>
            rows.map((row, i) => (
              <tr key={`${subset}-${row.subset_value}`}>
                {i === 0 && (
                  <td rowSpan={rows.length}>{subsetLabels[subset]}</td>
                )}
                <td>
                  {subsetValueMap[row.subset_value || ''] || row.subset_value}
                </td>
                <td>{row.n}</td>
                <td>{row.pct_respondents || '—'}</td>
              </tr>
            )),
          )}
        </tbody>
      </DataTable>
      <Row>
        <Col hAlign="flex-start">
          <em>Data refreshed {reportDate}</em>
        </Col>
        <Col hAlign="flex-end">
          {orgId && (
            <em>
              <Link to={toGroupParticipation(orgId)}>
                Participation details
              </Link>
            </em>
          )}
          {networkId && (
            <em>
              <Link to={toNetworkParticipation(networkId)}>
                Participation details
              </Link>
            </em>
          )}
        </Col>
      </Row>
    </>
  );
};
