import { useCallback } from 'react';
import { useFormikContext } from 'formik';
import { cloneDeep, get, set, values as _values } from 'lodash';
import moment from 'moment';

import { antdLocale, dateFormatShort, serverDateFormat } from 'config';
import { Class, Program } from 'models';

import { RangePicker } from '@perts/ui';
import theme from 'components/theme';
import { getCycleErrors } from '../../SchedulesEditCycles/getCycleErrors';
import { getDatePickerName } from '../../getDatePickerName';
import { ScheduleTableCol } from '../ScheduleTableCol';
import { ScheduleTableRow } from '../ScheduleTableRow';
import { Footer } from './Footer';

type ScheduleTableBatchEditProps = {
  // The number of cycles in the longest schedule.
  currentMaxCycles: number;

  mayUpdateCycles: boolean;

  program: Program;

  // Is true if any item is either checked or indeterminate.
  someMarked: boolean;

  // Selected classes.
  selectedClasses: Class[];
};

export const ScheduleTableBatchEdit = ({
  currentMaxCycles,
  mayUpdateCycles,
  program,
  someMarked,
  selectedClasses = [],
}: ScheduleTableBatchEditProps) => {
  const { values, setValues, touched, setTouched, errors, setErrors } =
    useFormikContext<any>();

  const handleChangeBatchDate = (ordinalCycleToUpdate) => (dates) => {
    let updatedValues = cloneDeep(values);
    let updatedTouched = cloneDeep(touched);
    let updatedErrors = cloneDeep(errors);

    selectedClasses.forEach((cls) => {
      const { cycles = [] } = cls;
      const cycle = cycles.find((c) => c.ordinal === ordinalCycleToUpdate);

      if (!cycle) {
        return;
      }

      // Path related to input name
      const inputName = getDatePickerName(cls.uid, cycle.uid);

      let end_date, start_date;

      // Update start_date and end_date with the batch values
      if (Array.isArray(dates)) {
        const [momentStartDate, momentEndDate] = dates;
        start_date = moment(momentStartDate).format(serverDateFormat);
        end_date = moment(momentEndDate).format(serverDateFormat);
      } else {
        start_date = null;
        end_date = null;
      }

      // Get current cycle value
      const currentCycleValue = get(updatedValues, inputName, {});

      // Update cycle value in updatedValues var
      updatedValues = set(updatedValues, inputName, {
        ...currentCycleValue,
        start_date,
        end_date,
      });

      // Update cycle touched in updatedTouched var
      updatedTouched = set(updatedTouched, inputName, true);

      // Get cycles of current class
      const classCyclesFromForm = get(values, `classes.${cls.uid}.cycles`, {});

      // Transform class cycles object to class cycles array
      const cyclesValues = _values(classCyclesFromForm);

      // Update errors var
      updatedErrors = getCycleErrors(
        updatedErrors,
        cls.uid,
        cycle.uid,
        cyclesValues,
        start_date,
        end_date,
      );
    });

    // Set formik values, touched and errors
    setValues(updatedValues, false);
    setTouched(updatedTouched, false);
    setErrors(updatedErrors);
  };

  const classesHaveSameCycleDates = useCallback(
    (ordinalCycle) => {
      const classCycleDates = selectedClasses.map((cls) => {
        const { cycles = [] } = cls;
        const cycle = cycles.find((c) => c.ordinal === ordinalCycle);

        if (!cycle) {
          return { start_date: null, end_date: null };
        }

        // Path related to input name
        const inputName = getDatePickerName(cls.uid, cycle.uid);

        // Get current cycle value
        const currentCycleValue = get(values, inputName, {});

        const { start_date, end_date } = currentCycleValue;

        return {
          start_date,
          end_date,
        };
      });

      const allEqual =
        classCycleDates.length > 0 &&
        classCycleDates.every(
          (cycle) =>
            cycle.start_date &&
            cycle.start_date === classCycleDates[0].start_date &&
            cycle.end_date &&
            cycle.end_date === classCycleDates[0].end_date,
        );

      if (allEqual) {
        return {
          start_date: moment(classCycleDates[0].start_date),
          end_date: moment(classCycleDates[0].end_date),
        };
      }

      return {
        start_date: null,
        end_date: null,
      };
    },
    [selectedClasses, values],
  );

  return (
    <ScheduleTableRow backgroundColor={theme.colors.primaryLight}>
      {/* Column to show description 
     related to Batch action (row's first column) */}
      <ScheduleTableCol>
        <span>
          {someMarked
            ? 'Batch edit selected classes'
            : 'Select classes to batch edit'}
        </span>
      </ScheduleTableCol>

      {/* Columns for each cycle that exists anywhere in the group  */}
      {Array.from({ length: currentMaxCycles }, (_, i) => (
        <ScheduleTableCol key={i} justifyContent="center">
          <RangePicker
            antdLocale={antdLocale}
            id={`batch-survey-${i + 1}`}
            name={`batch-survey-${i + 1}`}
            value={classesHaveSameCycleDates(i + 1)}
            initialValue={classesHaveSameCycleDates(i + 1)}
            formatDate={serverDateFormat}
            formatDateToShow={dateFormatShort}
            disabled={!someMarked || !mayUpdateCycles}
            customHandleChange={handleChangeBatchDate(i + 1)}
            disabledDate={(d) =>
              !d ||
              d.isBefore(program.start_date) ||
              d.isAfter(program.end_date)
            }
            renderExtraFooter={() => (
              <Footer
                program={program}
                selectedClasses={selectedClasses}
                cycleOrdinal={i + 1}
              />
            )}
          />
        </ScheduleTableCol>
      ))}
      {/* Extra column for the add cycle button */}
      <ScheduleTableCol />
    </ScheduleTableRow>
  );
};
