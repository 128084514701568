import { Formik, Form } from 'formik';
import { Group, User } from 'models';
import { PinButton } from '@perts/ui';

export type GroupPinFormProps = {
  user: User;
  group: Group;
  onSubmit: (values: User) => void;
};

export const GroupPinForm = ({ user, group, onSubmit }: GroupPinFormProps) => {
  const pinned = user.pinned_organizations.includes(group.uid);

  return (
    <Formik
      initialValues={user}
      onSubmit={async (values: User) => {
        const pinned_organizations = pinned
          ? // If currently pinned, a click will unpin the Group/Organization.
            user.pinned_organizations.filter((uid) => uid !== group.uid)
          : // If currently unpinned, a click will pin the Group/Organization.
            [...user.pinned_organizations, group.uid];

        const updatedUser = { ...user, pinned_organizations };

        await onSubmit(updatedUser);
      }}
    >
      {({ handleSubmit, isSubmitting }) => (
        <Form>
          <PinButton
            isPinned={pinned}
            disabled={isSubmitting}
            onClick={handleSubmit}
          />
        </Form>
      )}
    </Formik>
  );
};
