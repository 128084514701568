import { matchPath } from 'react-router-dom';
import {
  toClassReport,
  toClassReportSettings,
  toGroupReport,
  toGroupReportSettings,
  toGroupReports,
  toNetworkReport,
  toNetworkReportSettings,
  toNetworkReports,
  toClassReportPrint,
} from '.';

// react-router isActive function
// https://v5.reactrouter.com/web/api/NavLink/isactive-func
export const isActiveMenuClasses = (match, location) => {
  const routes = [
    toClassReport(),
    toClassReportSettings(),
    toClassReportPrint(),
  ];

  return (
    // matches class route
    match &&
    // but doesn't match any class report routes
    !routes.some((path) =>
      matchPath(location.pathname, { path, exact: true, strict: false }),
    )
  );
};

// react-router isActive function
// https://v5.reactrouter.com/web/api/NavLink/isactive-func
export const isActiveMenuReports = (_match, location) => {
  const routes = [
    toClassReport(),
    toClassReportSettings(),
    toClassReportPrint(),
    toGroupReport(),
    toGroupReports(),
    toGroupReportSettings(),
    toNetworkReport(),
    toNetworkReports(),
    toNetworkReportSettings(),
  ];

  return routes.some((path) =>
    matchPath(location.pathname, { path, exact: true, strict: false }),
  );
};
