import styled from 'styled-components/macro';

import { Link } from '@perts/ui';

import {
  getLinkStyled,
  MenuItemIconStyled,
  MenuItemTextStyled,
} from './MenuItem';

export type MenuItemIconStyledProps = {
  iconLetter?: string;
};

export type MenuItemProps = MenuItemIconStyledProps & {
  children: React.ReactNode;
  icon: React.ReactNode;
  to: string;

  // When true, navigation will be disabled.
  disabled?: boolean;
};

const LinkStyled = getLinkStyled(Link);

const MenuItemStyled = styled.div``;

/**
 * Similar to MenuItem, but since it's not based on NavLink it accepts external
 * URLs and not just local paths.
 */
export const MenuItemLink = ({
  icon,
  iconLetter,
  children,
  disabled,
  ...linkProps
}: MenuItemProps) => {
  // Handle to disabled navigation action from Link
  const handleClick = (e: any) => {
    e.preventDefault();
  };

  const updatedLinkProps = {
    ...linkProps,
    ...(disabled ? { onClick: handleClick } : {}),
    noIcon: true,
    disabled,
  };

  return (
    <MenuItemStyled>
      <LinkStyled {...updatedLinkProps}>
        <MenuItemIconStyled iconLetter={iconLetter}>{icon}</MenuItemIconStyled>{' '}
        <MenuItemTextStyled>{children}</MenuItemTextStyled>
      </LinkStyled>
    </MenuItemStyled>
  );
};
