import { Card as CardComponent } from './Card';
import { CardTitle, CardTitleText } from './CardTitle';
import { CardCollapsed } from './CardCollapsed';
import { CardContent } from './CardContent';
import { CardDivider } from './CardDivider';
import { CardStatusIcon } from './CardStatusIcon';

export const Card = Object.assign(CardComponent, {
  Collapsed: CardCollapsed,
  Content: CardContent,
  Divider: CardDivider,
  Title: CardTitle,
  TitleText: CardTitleText,
  StatusIcon: CardStatusIcon,
});

export * from './CardStyled';
export type { CardProps } from './Card';
