import { Route, Switch } from 'react-router-dom';
import {
  ClassReport23,
  GroupReport23,
  NetworkReport23,
  Reports,
  ReportSettingsClass,
  ReportSettingsGroup,
  ReportSettingsNetwork,
  ReportsExport,
  toClassReport,
  toClassReportPrint,
  toClassReportSettings,
  toGroupReport,
  toGroupReports,
  toGroupReportSettings,
  toNetworkReport,
  toNetworkReportExport,
  toNetworkReportSettings,
} from 'pages';

export const ReportsRouter = () => (
  <>
    <Switch>
      <Route path={toGroupReports()} exact>
        <Reports />
      </Route>
      <Route path={toNetworkReport()}>
        <NetworkReport23 />
      </Route>
      <Route path={toNetworkReportSettings()}>
        <ReportSettingsNetwork />
      </Route>
      <Route path={toClassReport()}>
        <ClassReport23 />
      </Route>
      <Route path={toClassReportSettings()}>
        <ReportSettingsClass />
      </Route>
      <Route path={toGroupReport()}>
        <GroupReport23 />
      </Route>
      <Route path={toGroupReportSettings()}>
        <ReportSettingsGroup />
      </Route>
    </Switch>

    <Switch>
      <Route path={toNetworkReportExport()}>
        <ReportsExport />
      </Route>
      <Route path={toClassReportPrint()}>
        <ClassReport23 />
      </Route>
    </Switch>
  </>
);
