import { LOCAL_STORAGE_AUTH_USER } from '../config';

/**
 * Get authenticated user from localStorage.
 * @return {Object} auth user or empty object
 */
export const getAuthUser = () => {
  const userString = localStorage.getItem(LOCAL_STORAGE_AUTH_USER);

  try {
    return JSON.parse(userString || '{}');
  } catch (error) {
    return {};
  }
};
