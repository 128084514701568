import styled from 'styled-components/macro';

import theme from '../theme';

type DropdownSectionProps = {
  theme: any;
};

export const DropdownSection = styled.div<DropdownSectionProps>`
  padding: ${(props) => props.theme.units.paddingSm}
    ${(props) => props.theme.units.paddingMd};
  font-weight: ${(props) => props.theme.units.boldWeight};

  & > *:first-child {
    margin-right: 5px;
  }
`;

DropdownSection.defaultProps = {
  theme,
};
