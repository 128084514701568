import { Parser as CsvParser } from 'json2csv';

import { OrganizationExperienceResults } from '@perts/model';

import { Group, Metric, Network } from 'models';
import { useCloseModal } from 'utils';
import { helpArticles } from 'config';
import { Button, Col, HelpText, Modal, Row } from '@perts/ui';

import { buildNetworkReportExport } from './components';
import { NetworkReportLoader } from './NetworkReport23';

export const convertDataToCsv = (fields, data) =>
  new CsvParser({ fields }).parse(data);

const getDownloadURI = (fields, data) =>
  `data:text/csv;charset=utf-8,${encodeURIComponent(
    data && data.length > 0 ? convertDataToCsv(fields, data) : '',
  )}`;

type Props = {
  groups: Group[];
  metrics: Metric[];
  lastRun: string;
  network: Network;
  resultsByGroup: { [organizationId: string]: OrganizationExperienceResults };
};

// eslint-disable-next-line complexity
export const ReportsExportRender = ({
  groups,
  metrics,
  lastRun,
  network,
  resultsByGroup,
}: Props) => {
  // Close the modal.
  const closeModal = useCloseModal({ ignoreEmptyState: true });

  const { data: exportRows, columns } = buildNetworkReportExport(
    network,
    groups,
    metrics,
    resultsByGroup,
  );

  const downloadName = `${network.name} Report ${lastRun}.csv`;

  return (
    <Modal close={closeModal}>
      <Modal.Title className="center">Export Report Data to CSV</Modal.Title>
      <Row>
        <Col>
          <HelpText articleId={helpArticles.howToUseExportedData}>
            Access codebooks for exported data
          </HelpText>
          <Button
            to={getDownloadURI(columns, exportRows)}
            download={downloadName}
            fullWidth
            color="primary"
          >
            Download CSV File
          </Button>
        </Col>
      </Row>
    </Modal>
  );
};

export const ReportsExport = () => (
  <NetworkReportLoader>
    {({ groups, lastRun, network, programMetrics, resultsByGroup }) => (
      <ReportsExportRender
        groups={groups}
        metrics={programMetrics}
        lastRun={lastRun}
        network={network}
        resultsByGroup={resultsByGroup}
      />
    )}
  </NetworkReportLoader>
);
