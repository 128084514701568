import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import pluralize from 'pluralize';
import uri from 'urijs';
import styled from 'styled-components/macro';
import { sampleSize } from 'lodash';

import { Class, Metric, Survey, Program, Group } from 'models';
import {
  toGroupClassSurveyInstructions,
  toGroupSurveysEditAdvanced,
  toGroupSurveysEditMetrics,
  toGroupSurveyQRCode,
  useParams,
} from 'pages';
import { useQueryParams } from 'utils';
import { getSurveyParams } from 'services/triton/surveys';

import {
  Card,
  Col,
  CopyButton,
  IconButton,
  IconChevronRight,
  IconClipboardList,
  IconExternalLink,
  IconQRCode,
  Link,
  Row,
  Show,
  theme,
} from '@perts/ui';
import TermsContext from 'components/TermsContext';
import { CardButton } from 'components/CardButton';
import { AdvancedSettingsButton } from './AdvancedSettingsButton';

type SurveyClassCardProps = {
  group: Group;
  cls: Class;
  conditions: Metric[];
  toggle: React.ReactNode;
  program: Program;
  survey?: Survey;
  checked?: boolean;
  mayEdit: boolean;
};

const BoldText = styled.span`
  font-weight: 600;
`;

const ButtonWrapper = styled.span`
  margin-left: 5px;
`;

const ExternalLinkIconStyled = styled(IconExternalLink)`
  margin-left: ${(props) => theme.units.fieldPaddingInternal};
`;

const LinkConditionsContainer = styled.div`
  font-weight: 600;

  > :not(:first-child) {
    margin-left: 5px;
  }
`;

export const surveyPreviewUrl = (
  survey: Survey | undefined,
  program: Program,
) => {
  if (survey && program) {
    const params = getSurveyParams(survey);

    // Limit to one random open response, just like the portal does.
    const openResponseMetrics = JSON.parse(params.open_response_lcs);
    const limited = sampleSize(openResponseMetrics, 1);
    params.open_response_lcs = JSON.stringify(limited);

    return uri(program.preview_url)
      .setSearch({ ...params, testing: 'true' })
      .toString();
  }
  return '';
};

export const SurveyClassCard = ({
  group,
  cls,
  conditions,
  toggle,
  program,
  survey,
  checked,
  mayEdit,
}: SurveyClassCardProps) => {
  const conditionsTotal = conditions.length;
  const terms = useContext(TermsContext);
  const history = useHistory();
  const { get } = useQueryParams();
  const { groupId } = useParams();

  const historyPush = (route: string) => {
    const params = new URLSearchParams();
    const pageParam = get('page');
    if (pageParam) {
      params.append('page', pageParam);
    }

    history.push({
      pathname: route,
      search: params.toString(),
      state: {
        checked: {
          [cls.uid]: true,
        },
      },
    });
  };

  const participantLink = `https://perts.me/${cls.code}`;
  const participantLinkText = `perts.me/${cls.code}`;

  return (
    <Card toggle={toggle} checked={checked}>
      <Card.Title>
        <Row alignItems="center">
          <Col cols={3} colsLg={3} colsMd={12}>
            <Card.TitleText overflowWrap="anywhere">{cls.name}</Card.TitleText>
          </Col>
          <Col cols={4} colsLg={4} colsMd={12} display="inline-flex">
            <Show when={mayEdit}>
              <Show when={program.label.includes('elevate')}>
                <AdvancedSettingsButton
                  onClick={() =>
                    historyPush(toGroupSurveysEditAdvanced(groupId))
                  }
                />
              </Show>

              <CardButton
                color="text"
                iconLeft={<IconClipboardList />}
                onClick={() => historyPush(toGroupSurveysEditMetrics(groupId))}
                fullWidth
              >
                {`${conditionsTotal} ${pluralize(
                  'condition',
                  conditionsTotal,
                )}`}
              </CardButton>
            </Show>

            <Show when={!mayEdit}>
              <LinkConditionsContainer>
                <IconClipboardList />

                <Link to="http://perts.net/ascend/measures" noIcon>
                  {`${conditionsTotal} ${pluralize(
                    'condition',
                    conditionsTotal,
                  )}`}
                </Link>
              </LinkConditionsContainer>
            </Show>
          </Col>

          <Show when={!group.archived}>
            <Col cols={5} colsLg={5} colsMd={12}>
              <CardButton
                color="text"
                iconRight={<IconChevronRight />}
                to={toGroupClassSurveyInstructions(
                  group.short_uid,
                  cls.short_uid,
                )}
                fullWidth
              >
                {terms.class} survey instructions
              </CardButton>
            </Col>
          </Show>
        </Row>
      </Card.Title>

      <Card.Content>
        <Row>
          <Col cols={3} colsLg={4} colsMd={12}>
            <Card.TitleText overflowWrap="anywhere">
              {terms.class} Code: <BoldText>{cls.code}</BoldText>
            </Card.TitleText>
          </Col>
          <Show when={!group.archived}>
            <Col
              cols={5}
              colsLg={8}
              colsMd={12}
              vAlign="center"
              hAlign="flex-end"
              hAlignMd="flex-start"
            >
              <Card.TitleText overflowWrap="anywhere">
                {terms.participant} Link:{' '}
                <BoldText>{participantLinkText}</BoldText>
              </Card.TitleText>
              <ButtonWrapper>
                <CopyButton
                  aria-label={`Copy ${terms.participant} Link`}
                  textToCopy={participantLink}
                  successMessage="Link copied"
                />
              </ButtonWrapper>
              <ButtonWrapper>
                <IconButton
                  aria-label="Get QR code"
                  icon={<IconQRCode />}
                  onClick={() => historyPush(toGroupSurveyQRCode(groupId))}
                />
              </ButtonWrapper>
            </Col>
          </Show>
          <Show when={Boolean(survey) && Boolean(program)}>
            <Col cols={4} colsLg={12} vAlign="center">
              <CardButton
                to={surveyPreviewUrl(survey, program)}
                target="_blank"
                color="text"
                fullWidth
              >
                Preview survey <ExternalLinkIconStyled />
              </CardButton>
            </Col>
          </Show>
        </Row>
      </Card.Content>
    </Card>
  );
};
