import { useMutation, useQueryClient } from 'react-query';
import { User, invalidateCurrentUser, updateCurrentUser } from 'models';

export type CurrentUserUpdate = (user: User) => Promise<void>;

export const useCurrentUserUpdate = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(
    async (user: User) => {
      await updateCurrentUser(user);
      invalidateCurrentUser(queryClient);

      // TODO: use optimistic updates, once that pattern is packaged up.
    },
    {
      onSuccess: (data, classes) => {
        // TODO toast
        // eslint-disable-next-line
        console.log('Successully updated the current user.');
      },
      onError: (error) => {
        // TODO toast
        console.error('Unable to update current user:', error);
      },
    },
  );

  const currentUserUpdate: CurrentUserUpdate = mutation.mutateAsync;

  return currentUserUpdate;
};
