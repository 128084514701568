import styled from 'styled-components/macro';
import { theme } from 'components';

export const TopMenu = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  > :not(:first-child) {
    margin-left: ${theme.units.paddingSm};
  }

  @media print {
    // No need for log out buttons when printing.
    display: none;
  }
`;
