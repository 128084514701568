type OneAndOnlyOne = (
  /**
   * Array of values to inspect.
   */
  values: any[],

  /**
   * The method that returns `true` for values that satisfy the condition.
   */
  predicate?: (value: any) => boolean,
) => boolean;

/**
 *
 * Checks if one and only one value in the array satisfies the provided predicate
 * function. Predicate function defaults to returning true for truthy values.
 */
export const oneAndOnlyOne: OneAndOnlyOne = (values, predicate = Boolean) => {
  const predicateFn = predicate || Boolean;
  return values.filter(predicateFn).length === 1;
};
