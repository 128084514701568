import { useParams } from 'react-router-dom';
import { GuideBox, HelpText, Link } from '@perts/ui';
import { PagesParams, toHomeGroupsAdd } from 'pages';
import { useTerms } from 'components/TermsContext';
import { helpArticles } from 'config';

export const HomeGuideBox = () => {
  const { programId = '' } = useParams<PagesParams>();
  const terms = useTerms();

  return (
    <GuideBox
      action={
        <Link to={toHomeGroupsAdd(programId)}>
          Create {terms.aGroup} to get started.
        </Link>
      }
      faq={
        <HelpText articleId={helpArticles.guideboxHome}>
          What is {terms.aGroup}?
        </HelpText>
      }
    />
  );
};
