import { Group, getGroupDefaultSettings } from 'models';
import { getRule } from './getRule';
import { splitMinMax } from './splitMinMax';
import { ClassDefaultSettingsFormValues } from './types';

export function getDefaultValuesSignOn(group: Group) {
  const {
    portal_type,
    portal_message,
    participant_ending,
    participant_pattern,
    roster_locked,
    target_group_name,
    parent_organizations,
  } = getGroupDefaultSettings(group);

  const studentEmailEnding = getRule(
    participant_pattern || null,
    participant_ending || null,
  );
  const [studentIdMin, studentIdMax] = splitMinMax(participant_ending || '');

  const defaultValues: ClassDefaultSettingsFormValues = {
    ruleEnabled: Boolean(participant_pattern),

    portalType: portal_type,
    portalMessage: portal_message || '',

    studentEmailEnding,

    studentIdMin,
    studentIdMax,

    rosterLocked: Boolean(roster_locked),

    targetGroupEnabled: Boolean(target_group_name),
    targetGroupName: target_group_name || '',

    parent_organizations,
  };

  return defaultValues;
}
