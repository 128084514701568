import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import {
  useCurrentUserQuery,
  selectUserIsAdmin,
  useGroupsPagination,
  useNetworksPagination,
  useUsersPagination,
} from 'models';
import { PagesParams } from 'pages';
import { getMessageFromErrors } from '@perts/util';
import { ErrorMessageBox, Loading, PaginationUseQuery } from 'components';
import { SearchRender } from './SearchRender';

const useSearchQuery = () => {
  const { programId = '', searchQuery = '' } = useParams<PagesParams>();
  const [pageIndexNetworks, setPageIndexNetworks] = useState(0);
  const [pageIndexGroups, setPageIndexGroups] = useState(0);
  const [pageIndexUsers, setPageIndexUsers] = useState(0);

  useEffect(() => {
    setPageIndexNetworks(0);
    setPageIndexGroups(0);
    setPageIndexUsers(0);
  }, [searchQuery]);

  const resultsNetworks = useNetworksPagination(
    {
      programId,
      name: searchQuery,
      pageIndex: pageIndexNetworks,
      pageSize: 10,
    },
    {
      keepPreviousData: true,
    },
  );

  const resultsGroups = useGroupsPagination(
    {
      programId,
      name: searchQuery,
      pageIndex: pageIndexGroups,
      pageSize: 10,
    },
    {
      keepPreviousData: true,
    },
  );

  const resultsUsers = useUsersPagination(
    {
      name: searchQuery,
      pageIndex: pageIndexUsers,
      pageSize: 10,
    },
    {
      keepPreviousData: true,
    },
  );

  const isLoading =
    resultsNetworks.isLoading ||
    resultsGroups.isLoading ||
    resultsUsers.isLoading;

  return {
    isLoading,
    resultsNetworks,
    resultsGroups,
    resultsUsers,
    setPageIndexNetworks,
    setPageIndexGroups,
    setPageIndexUsers,
  };
};

export const Search = () => {
  const userQuery = useCurrentUserQuery();
  const currentUser = userQuery.data;
  const currentUserIsAdmin = selectUserIsAdmin(currentUser);

  const {
    isLoading,
    resultsNetworks,
    resultsGroups,
    resultsUsers,
    setPageIndexNetworks,
    setPageIndexGroups,
    setPageIndexUsers,
  } = useSearchQuery();

  if (isLoading || !currentUser) {
    return <Loading />;
  }

  // Display any errors.
  if (
    !resultsNetworks.isSuccess ||
    !resultsGroups.isSuccess ||
    !resultsUsers.isSuccess
  ) {
    return (
      <ErrorMessageBox>
        {getMessageFromErrors([
          resultsNetworks.error,
          resultsGroups.error,
          resultsUsers.error,
        ])}
      </ErrorMessageBox>
    );
  }

  const networks = resultsNetworks.data || [];
  const organizations = resultsGroups.data || [];
  const users = resultsUsers.data || [];

  return (
    <SearchRender
      networks={networks}
      networksTotal={resultsNetworks.pagination.total}
      networksPagination={
        <PaginationUseQuery
          useQueryResults={resultsNetworks}
          onChange={setPageIndexNetworks}
        />
      }
      organizations={organizations}
      organizationsTotal={resultsGroups.pagination.total}
      organizationsPagination={
        <PaginationUseQuery
          useQueryResults={resultsGroups}
          onChange={setPageIndexGroups}
        />
      }
      users={users}
      usersTotal={resultsUsers.pagination.total}
      usersPagination={
        <PaginationUseQuery
          useQueryResults={resultsUsers}
          onChange={setPageIndexUsers}
        />
      }
      currentUser={currentUser}
      currentUserIsAdmin={currentUserIsAdmin}
    />
  );
};
