import { Form, Formik } from 'formik';
import { Row, Col, Input, Switch, useFormError } from '@perts/ui';
import { FormButtons, useTerms } from 'components';

export type ReportSettingsGroupDisableFormValues = {
  report_hidden: boolean;
};

type Props = {
  close?: () => void;
  onSubmit: (values: ReportSettingsGroupDisableFormValues) => void;
  initialValues: ReportSettingsGroupDisableFormValues;
};

export const ReportSettingsGroupDisableForm = ({
  initialValues,
  onSubmit,
  close,
}: Props) => {
  const [FormError, showFormError] = useFormError();
  const terms = useTerms();

  const Label = ({ hidden }: { hidden: boolean }) =>
    hidden ? (
      <span>
        <strong>Hidden</strong>: Reports are hidden for this{' '}
        {terms.group.toLowerCase()}.
      </span>
    ) : (
      <span>
        <strong>Visible</strong>: Reports are visible for this{' '}
        {terms.group.toLowerCase()}.
      </span>
    );

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      onSubmit={async (values) => {
        try {
          // Clear existing form error.
          showFormError(false);

          // Perform form onSubmit.
          await onSubmit(values);

          // Close modal on successful form onSubmit.
          close && close();
        } catch (error) {
          // Display form error.
          showFormError(true);
        }
      }}
    >
      {({ values, isSubmitting }) => (
        <Form>
          <Row>
            <Col shrink vAlign="center">
              <Input
                component={Switch}
                id="report_hidden"
                name="report_hidden"
                label={<Label hidden={values.report_hidden} />}
                labelPlacement="end"
                disabled={isSubmitting}
                checked={values.report_hidden}
              />
            </Col>
          </Row>

          <Row>
            <Col>
              <FormError />
            </Col>
          </Row>

          <FormButtons close={close} submitButtonText="Save Setting" />
        </Form>
      )}
    </Formik>
  );
};
