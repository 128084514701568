import styled from 'styled-components/macro';
import { Row, Col, Show, theme } from '../../';

const GuideBoxContainer = styled.div`
  font-weight: 600;

  a {
    color: ${theme.colors.primary};
    text-decoration: underline;
  }

  margin-bottom: ${theme.units.paddingLg};

  /* Padding for narrower/mobile screens. */
  @media only screen and (max-width: ${theme.units.gridBreakpointMedium}px) {
    margin-bottom: ${theme.units.paddingMd};
  }
  @media only screen and (max-width: ${theme.units.gridBreakpointSmall}px) {
    margin-bottom: ${theme.units.paddingSm};
  }
`;

const GuideBoxSection = styled.div`
  padding: 10px 20px;
  height: 100%;

  background: ${theme.colors.white};

  border: 1px dashed ${theme.colors.grayMedium};
  border-radius: ${theme.units.borderRadius};
`;

type GuideBoxProps = {
  // JSX of action users should take.
  action: React.ReactNode;

  // JSX of Freshworks FAQ article text/link.
  faq: React.ReactNode;

  // JSX of next page link.
  next?: React.ReactNode;
};
export const GuideBox = ({ action, faq, next }: GuideBoxProps) => (
  <GuideBoxContainer>
    <Row>
      <Col>
        <GuideBoxSection>
          <Show when={Boolean(action)}>
            <Row>
              <Col hAlign="flex-start">{action}</Col>
            </Row>
          </Show>

          <Show when={Boolean(faq)}>
            <Row>
              <Col hAlign="flex-start">{faq}</Col>
            </Row>
          </Show>
        </GuideBoxSection>
      </Col>

      {next && (
        // Not using Show here because TypeScript doesn't understand that it
        // assures that we won't render Link when !Boolean(next).
        <Col cols={4} colsSm={12}>
          <GuideBoxSection>
            <Row>
              <Col hAlign="flex-end">What's next?</Col>
            </Row>

            <Row>
              <Col hAlign="flex-end">{next}</Col>
            </Row>
          </GuideBoxSection>
        </Col>
      )}
    </Row>
  </GuideBoxContainer>
);
