import { dateFormatShort } from 'config';
import moment from 'moment';
import styled from 'styled-components';

const ReportUpdatedStyled = styled.div`
  font-size: 12px;
`;

type Props = {
  dateString?: string;
};

export const reportUpdatedFrequencyFormat = (dateString: string) => {
  const ago = moment(dateString).fromNow();
  const date = moment(dateString).format(dateFormatShort);
  const time = moment(dateString).format('LT');
  const dateFormatted = `${date} ${time} (${ago})`;
  return dateFormatted;
};

export const ReportUpdatedFrequency = ({ dateString }: Props) => {
  let lastUpdated = '';

  if (dateString) {
    const dateFormatted = reportUpdatedFrequencyFormat(dateString);
    lastUpdated = `This report last updated ${dateFormatted}.`;
  }

  return (
    <ReportUpdatedStyled data-testid="report-updated">
      {lastUpdated} PERTS checks for fresh data every Monday.
    </ReportUpdatedStyled>
  );
};
