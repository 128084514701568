import { useFormikContext } from 'formik';
import { HelpText, Input, Show, TextField } from '@perts/ui';
import { helpArticles } from 'config';
import { RosterSignOnRuleFormValues } from './RosterSignOnRuleForm';

type Props = {
  label: string;
  onChange: (event: any) => void;
  patternIsRegexMode: boolean;
};

export const FieldsEndingPattern = ({
  label,
  onChange,
  patternIsRegexMode,
}: Props) => {
  const { values, touched, errors, isSubmitting } =
    useFormikContext<RosterSignOnRuleFormValues>();

  return (
    <Input
      component={TextField}
      id="studentEmailEnding"
      name="studentEmailEnding"
      label={label}
      labelPlacement="top"
      fullWidth
      disabled={!values.ruleEnabled || isSubmitting}
      error={
        values.ruleEnabled &&
        touched.studentEmailEnding &&
        errors.studentEmailEnding
          ? errors.studentEmailEnding
          : ''
      }
      onChange={onChange}
      helpText={
        <Show when={patternIsRegexMode}>
          <HelpText articleId={helpArticles.pertsAppliedRuleToRoster}>
            PERTS has applied a custom rule for this roster.
          </HelpText>
        </Show>
      }
      maxLength={200}
    />
  );
};
