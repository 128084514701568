import { useQuery, useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';
import uniqBy from 'lodash/uniqBy';

import {
  Class,
  classesQueryByGroupId,
  classesQueryByUserGroupId,
  classGetById,
  selectUserMayGroupClassView,
  useCurrentUserQuery,
  Group,
} from 'models';
import { useParams } from 'pages';

import { useQueriesTyped } from 'utils/useQueriesTyped';
import errorHandler from 'utils/errorHandler';

// TODO This needs to be extended to allow for useQuery options, like
//   { enabled: someDependentVariable }
// but I need to work out how to wrangle TypeScript to allow for this.
//
// See the following for possible help:
// - https://blog.johnnyreilly.com/2021/01/03/strongly-typing-react-query-s-usequeries/
// - https://github.com/tannerlinsley/react-query/pull/1527

// -----------------------------------------------------------------------------
//   queryKey Generators
// -----------------------------------------------------------------------------

export const queryKeyClassesByGroup = (groupId: string) => [
  'classes',
  'byGroup',
  groupId,
];

export const queryKeyClass = (classId: string) => ['class', classId];

// -----------------------------------------------------------------------------
//   API Hooks
// -----------------------------------------------------------------------------

export const useClassesQueryByGroupId = (groupId: string) => {
  const queryClient = useQueryClient();
  const history = useHistory();

  const {
    data: user,
    isError: userIsError,
    error: userError,
    isLoading: userIsLoading,
  } = useCurrentUserQuery();

  const queryFn = !user
    ? () => [] // user isn't logged in, don't query data
    : selectUserMayGroupClassView(user, groupId)
    ? () => classesQueryByGroupId(groupId)
    : () => classesQueryByUserGroupId(user, groupId);

  const response = useQuery<Class[], Error>(
    queryKeyClassesByGroup(groupId),
    queryFn,
    {
      enabled: Boolean(user),
      onError: errorHandler(queryClient, history),
    },
  );

  // If the query is loading or current user query is loading,
  // marking isLoading as true.
  return {
    ...response,
    isLoading: response.isLoading || userIsLoading,
    isError: response.isError || userIsError,
    error: response.error || userError,
  };
};

export const useClassesQueryByParams = () => {
  const { groupId } = useParams();
  return useClassesQueryByGroupId(groupId);
};

export const useClassGetById = (classId = '', options?) =>
  useQuery<Class, Error>(
    // queryKey
    queryKeyClass(classId),
    // queryFn
    () => classGetById(classId),
    {
      enabled: Boolean(classId),
      ...options,
    },
  );

export const useClassGetByParams = () => {
  const { classId } = useParams();
  return useClassGetById(classId);
};

// Query Classes of provided Group[] `groups`.

type UseClassesQueryByGroups = (
  // Optional, it's a dependent query so the parent may not have resolved yet.
  groups?: Group[],
) => any;

export const useClassesQueryByGroups: UseClassesQueryByGroups = (
  groups = [],
) => {
  const { data: user, isLoading: userIsLoading } = useCurrentUserQuery();
  const queries = useQueriesTyped(
    groups.map((group) => ({
      queryKey: queryKeyClassesByGroup(group.uid),
      queryFn: !user
        ? () => [] // user isn't logged in, don't query data
        : selectUserMayGroupClassView(user, group.uid)
        ? () => classesQueryByGroupId(group.uid)
        : () => classesQueryByUserGroupId(user, group.uid),
      options: {
        enabled: Boolean(user),
      },
    })),
  );

  // If any query is loading or current user query is loading,
  // marking isLoading as true.
  const isLoading = queries.some((query) => query.isLoading) || userIsLoading;

  // Default error state to no error.
  let isError = false;
  let error: Error | null = null;

  // Then override with the first error found, if any.
  const queryWithError = queries.find((query) => query.isError);

  if (queryWithError) {
    ({ isError, error } = queryWithError);
  }

  const flatDuplicatedData = queries
    // Filter to arrays only, so we can map over them.
    .filter((query) => Array.isArray(query.data))
    // Flatten data arrays from each query into a single array.
    .flatMap((query) => query.data);

  // Because a Class can be in more than one Group, the merger of all these
  // results may have the same Class more than once.
  const data = uniqBy(flatDuplicatedData, 'uid');

  return {
    data,
    error,
    isLoading,
    isError,
  };
};
