import moment from 'moment';

const areDateRangesOverlapped = (
  dateRange1: (string | null)[],
  dateRange2: (string | null)[],
): boolean => {
  const [startDate1, endDate1] = dateRange1;
  const [startDate2, endDate2] = dateRange2;

  // Validate if all dates have values
  if (startDate1 && endDate1 && startDate2 && endDate2) {
    const momentStartDate1 = moment(startDate1);
    const momentEndDate1 = moment(endDate1);
    const momentStartDate2 = moment(startDate2);
    const momentEndDate2 = moment(endDate2);

    if (
      momentStartDate1.isSameOrBefore(momentEndDate2) &&
      momentStartDate2.isSameOrBefore(momentEndDate1)
    ) {
      return true;
    }
  }
  return false;
};

export default areDateRangesOverlapped;
