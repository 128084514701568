import { useParams } from 'react-router-dom';
import { useQueryClient, useMutation } from 'react-query';
import { message } from 'antd';

import {
  groupsAddToNetwork,
  invalidateGroupsAddToNetwork,
  selectIsNetworkGroup,
  selectUserIsAdmin,
  selectUserIsGroupManager,
  useCurrentUserQuery,
  useGroupsQuery,
  useNetworkGetByParams,
} from 'models';
import { PagesParams } from 'pages';
import { useCloseModal } from 'utils';

import {
  NetworkGroupsAddForm,
  NetworkGroupsAddFormValues,
} from './NetworkGroupsAddForm';
import Loading from 'components/Loading';
import { ErrorMessageBox } from 'components/ErrorMessageBox';
import { useTerms } from 'components/TermsContext';

import { createUid, getMessageFromErrors } from '@perts/util';

export const NetworkGroupsAdd = () => {
  const queryClient = useQueryClient();
  const { networkId: shortNetworkId = '' } = useParams<PagesParams>();
  const networkId = createUid('Network', shortNetworkId);
  const terms = useTerms();

  // Close the modal.
  const closeModal = useCloseModal({ ignoreEmptyState: true });

  // Query for Network, user, and their groups.
  const networkQuery = useNetworkGetByParams();
  const currentUserQuery = useCurrentUserQuery();

  const queryParams = {};
  const userGroupsQuery = useGroupsQuery(queryParams, {
    enabled: currentUserQuery.isSuccess && networkQuery.isSuccess,
  });

  const mutation = useMutation(
    async ({ groupIds: groupIdsToAdd }: NetworkGroupsAddFormValues) => {
      if (!networkQuery.data) {
        throw new Error(
          `Network required to add ${terms.groups.toLowerCase()}.`,
        );
      }

      await groupsAddToNetwork(groupIdsToAdd, networkQuery.data);
    },
    {
      onSuccess: () => {
        message.success(
          `Successfully added the ${terms.groups.toLowerCase()} .`,
        );

        // Invalidate network groups to force a refetch.
        invalidateGroupsAddToNetwork(queryClient, networkId);
      },
    },
  );

  if (
    networkQuery.isLoading ||
    currentUserQuery.isLoading ||
    userGroupsQuery.isLoading
  ) {
    return <Loading />;
  }

  if (
    !networkQuery.isSuccess ||
    !currentUserQuery.isSuccess ||
    !userGroupsQuery.isSuccess
  ) {
    return (
      <ErrorMessageBox>
        {getMessageFromErrors([
          networkQuery.error,
          currentUserQuery.error,
          userGroupsQuery.error,
        ])}
      </ErrorMessageBox>
    );
  }

  const network = networkQuery.data;
  const currentUser = currentUserQuery.data;
  const userGroups = userGroupsQuery.data || [];

  const eligibleGroups = userGroups
    .filter((g) => selectUserIsGroupManager(currentUser, g.uid))
    .filter((g) => !selectIsNetworkGroup(network, g));

  // https://stackoverflow.com/questions/65760158/react-query-mutation-typescript
  // Formik onSubmit handler
  const onSubmit = async (values: NetworkGroupsAddFormValues) => {
    await mutation.mutateAsync(values);
  };

  return (
    <NetworkGroupsAddForm
      close={closeModal}
      isAdmin={selectUserIsAdmin(currentUser)}
      onSubmit={onSubmit}
      eligibleGroups={eligibleGroups}
    />
  );
};
