import { get, keyBy } from 'lodash';
import { useEffect, useMemo, useState } from 'react';

import {
  getAttributeValueName,
  getAttributeValueShortName,
} from '@perts/config';
import type { OrganizationExperienceResults } from '@perts/model';
import { Col, HelpText, IconButton, IconFilter, Row, Text } from '@perts/ui';

import { useTerms } from 'components/TermsContext';
import { Group } from 'models';
import {
  getCumulativeChange,
  getIsEquityGap,
  getRecentCycleValue,
} from 'utils';
import { helpArticles } from 'config';
import { toGroupReport } from 'pages';

import { DISAGGREGATE_BY_ALL } from './constants';
import type { DemographicRow } from './types';
import { getAttrLabelsWithGaps } from './getAttrLabelsWithGaps';

import { DisaggregationTable } from './DisaggregationTable';
import { SelectFilterDisaggregate } from './SelectFilterDisaggregate';
import { useStateFilterDisaggregateBy } from './useStateFilterDisaggregateBy';

type Props = {
  groups: Group[];
  metricLabel: string;
  metricName: string;
  programLabel: string;
  resultsByGroup: { [groupId: string]: OrganizationExperienceResults };
  sampleSizeTotal: number;
};

export const MetricGroupExperience = ({
  groups,
  metricLabel,
  metricName,
  programLabel,
  resultsByGroup,
  sampleSizeTotal,
}: Props) => {
  const terms = useTerms();
  const {
    disaggregateBy: selectedAttribute,
    setDisaggregateBy: setSelectedAttribute,
    applyToAll,
  } = useStateFilterDisaggregateBy();
  const [showGapsOnly, setShowGapsOnly] = useState(false);
  const [showTextAndGapFiltering, setShowTextAndGapFiltering] = useState(false);

  const toggleTextAndGapFiltering = () => {
    setShowTextAndGapFiltering(!showTextAndGapFiltering);
  };

  const groupsById = keyBy(groups, 'uid');

  const data: DemographicRow[] = Object.entries(resultsByGroup).map(
    ([groupId, { experience, sample }]) => {
      const cycleValuesAll =
        experience[metricLabel]?.composite.all_participants || [];
      const recentAll = getRecentCycleValue(cycleValuesAll);

      return {
        cumulativeChange: getCumulativeChange(cycleValuesAll),
        isComposite: true, // makes group name bold
        label: groupId,
        link: toGroupReport(groupId),
        name: groupsById[groupId]?.name || 'Unknown',
        ratedPositively: recentAll,
        sampleSize: sample[metricLabel]?.all_participants || 0,
        sampleSizeTotal,

        byAttribute: Object.entries(
          experience[metricLabel].composite || {},
        ).map(([attributePath, cycleValues]) => {
          const [attributeLabel, attributeValue] = attributePath.split('.');
          const recent = getRecentCycleValue(cycleValues);

          return {
            attributeLabel,
            attributePath,
            attributeValue,
            attributeValueName: getAttributeValueName(attributePath),
            attributeValueShortName: getAttributeValueShortName(attributePath),
            cumulativeChange: getCumulativeChange(cycleValues),
            isEquityGap: getIsEquityGap(recentAll, recent),
            ratedPositively: recent,
            sampleSize: get(sample, [metricLabel, attributePath], 0),
          };
        }),
      };
    },
  );

  // Apply the current filter state.
  const dataFiltered = useMemo(() => {
    let filtered: DemographicRow[] = [];

    // Note that the "All" subgroup should always be visible.
    const selectedAttributes = [DISAGGREGATE_BY_ALL, selectedAttribute];

    // Prune the `byAttribute` property prior to applying the optional
    // equity gap filter so that we aren't including rows that contain
    // equity gaps for attributes that aren't currently visible.
    filtered = data.map((row) => ({
      ...row,
      byAttribute: row.byAttribute.filter(({ attributeLabel }) =>
        selectedAttributes.includes(attributeLabel),
      ),
    }));

    // Optionally, filter out by equity gaps.
    filtered = filtered.filter((row) =>
      showGapsOnly
        ? row.byAttribute.some(({ isEquityGap }) => isEquityGap)
        : true,
    );

    return filtered;
  }, [data, selectedAttribute, showGapsOnly]);

  const attrLabelsWithGaps = getAttrLabelsWithGaps(data);

  useEffect(() => {
    /**
     * If the advanced filter is not visible,
     * clear the checkbox for gaps only.
     */
    if (!showTextAndGapFiltering) {
      setShowGapsOnly(false);
    }
  }, [showTextAndGapFiltering]);

  return (
    <>
      <Row>
        <Col shrink={false} cols={6} vAlign="center">
          <Text as="h3" id="experience-overview" includeMargin={false}>
            {metricName} by {terms.group}
          </Text>
        </Col>
        <Col shrink={false} cols={6} vAlign="center" hAlign="flex-end">
          <HelpText articleId={helpArticles.studentExperienceByCommunity}>
            Tips for using this
          </HelpText>
        </Col>
      </Row>

      <Row>
        <Col shrink={false} cols={11}>
          <SelectFilterDisaggregate
            programLabel={programLabel}
            attrLabelsWithGaps={attrLabelsWithGaps}
            disaggregateBy={selectedAttribute}
            setDisaggregateBy={setSelectedAttribute}
            applyToAll={applyToAll}
          />
        </Col>
        <Col shrink={false} cols={1} display="flex" hAlign="flex-end">
          <IconButton
            icon={<IconFilter />}
            onClick={toggleTextAndGapFiltering}
            aria-label="Toggle text and gap filtering"
            state={showTextAndGapFiltering ? 'active' : 'inactive'}
          />
        </Col>
      </Row>

      <DisaggregationTable
        showTextAndGapFiltering={showTextAndGapFiltering}
        data={dataFiltered}
        primaryHeader={terms.group}
        useTextFiltering={true}
        gapFiltering={{
          checked: showGapsOnly,
          disabled: selectedAttribute === DISAGGREGATE_BY_ALL,
          onChange: () => setShowGapsOnly(!showGapsOnly),
        }}
        usePagination={true}
      />
    </>
  );
};
