import { all, call, put, takeLatest } from 'redux-saga/effects';

import * as authActions from 'state/auth/actions';
import * as types from './actionTypes';
import * as uiActions from 'state/ui/actions';
import authApi from 'services/auth';
import { signOut } from 'services/auth/googleAuth';
import { CLEAR_FLAGS } from 'state/actionTypes';

import * as route from 'routes';

export function* loginRequest(action) {
  try {
    // There are two login methods, with different properties on the action
    //
    // * email and password (via the login form)
    // * token (via a query string param)

    const { googleUser, token } = action;

    const user = token ? yield call(authApi.tokenLogin, token) : googleUser;

    // LOGIN_SUCCESS will tell the auth reducer to store the logged in user.
    // Then the toLogin route will be invalid, and Login will
    // take care of redirection, which includes the `continue_url` param.
    yield put({ type: types.LOGIN_SUCCESS, user });

    yield call(authApi.addUserToErrorTracking, user);

    if (token) {
      // Removes the token from the query string, signals to components that
      // the login process is complete.
      yield put(uiActions.redirectTo(action.redirect));
    } else {
      // This may not seem like it's needed, but it is needed for effects from
      // LOGIN_REQUEST action.
      yield put({ type: CLEAR_FLAGS });
    }
  } catch (error) {
    yield put({ type: types.LOGIN_FAILURE, error });

    // Don't put CLEAR_FLAGS here because the UI needs the error state to know
    // when to display error messages. If the error state is cleared, it may
    // just try to log the user in again.
    // See: github.com/PERTS/triton/issues/2014
  }
}

export function* logoutRequest(action) {
  try {
    // Note that another important piece of state to clear is react-query, which
    // must be accessed from within react. See the Logout component.

    // Clear PERTS cookies and end session on server.
    yield call(authApi.logout);

    // Sign out of firebase.
    yield signOut();

    // Clear PERTS auth tokens from local storage.
    yield call(authApi.removeAuthFromLocalStorage);

    // Stop associating Sentry errors with the current user.
    yield call(authApi.clearUserFromErrorTracking);

    // Remove the user from the redux state.
    // Important b/c onbeforeunload the app will try to save redux's auth user
    // to local storage.
    yield put({ type: types.LOGOUT_SUCCESS });

    // Don't use a react-based redirect here, because we want to entirely avoid
    // rendering more components, which might trigger other queries or effects
    // and re-populate all the stuff we just cleared.
    window.location = action.redirect || route.toLogin();
  } catch (error) {
    yield put({ type: types.LOGOUT_FAILURE, error });
  }
}

export function* registerRequest(action) {
  try {
    yield call(authApi.register, action.email);
    yield put({ type: types.REGISTER_SUCCESS, email: action.email });
  } catch (error) {
    yield put({ type: types.REGISTER_FAILURE, error });
  }
}

export function* setPasswordRequest(action) {
  try {
    const user = yield call(authApi.setPassword, action.token, action.password);
    yield put({ type: types.PASSWORD_SET_SUCCESS });
    // Automatically log the user in after setting/resetting their password.
    yield put(
      authActions.loginUser(user.email, action.password, action.redirect),
    );
  } catch (error) {
    yield put({ type: types.PASSWORD_SET_FAILURE, error });
  }
}

export function* resetPasswordRequest(action) {
  try {
    yield call(authApi.resetPassword, action.email, action.captchaResponse);
    yield put({ type: types.PASSWORD_RESET_SUCCESS, email: action.email });
  } catch (error) {
    yield put({ type: types.PASSWORD_RESET_FAILURE, error });
  }
}

export function* checkTokenRequest(action) {
  try {
    yield call(authApi.checkToken, action.token);
    yield put({ type: types.CHECK_TOKEN_SUCCESS });
  } catch (error) {
    yield put({ type: types.CHECK_TOKEN_FAILURE, error });
  }
}

export default function* authSaga() {
  yield all([
    takeLatest(types.LOGIN_REQUEST, loginRequest),
    takeLatest(types.LOGOUT_REQUEST, logoutRequest),
    takeLatest(types.REGISTER_REQUEST, registerRequest),
    takeLatest(types.PASSWORD_SET_REQUEST, setPasswordRequest),
    takeLatest(types.PASSWORD_RESET_REQUEST, resetPasswordRequest),
    takeLatest(types.CHECK_TOKEN_REQUEST, checkTokenRequest),
  ]);
}
