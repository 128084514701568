import { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';

import { logoutUser } from 'state/auth/actions';
import { redirectTo } from 'state/ui/actions';
import {
  selectUserSession,
  useCurrentUserQuery,
  useCurrentUserUpdate,
} from 'models';
import { toHome } from 'pages';
import { reloadUser, sendVerificationEmail } from 'services/auth/googleAuth';
import { getJwtPayload } from 'services/triton/helpers';
import fromSearch from 'utils/fromSearch';

import { VerifyEmailRender } from './VerifyEmailRender';

const VerifyEmailForm = (props) => {
  const { actions, emailVerified, touAgreed, onClickBack } = props;
  const { program: programLabel } = fromSearch(props);

  // We'll swap out the form with a confirmation or error display if we send
  // email on submit. Set up state to track this.
  const [sendEmailErrors, setErrors] = useState(0);
  const [sendAttempted, setSendAttempted] = useState(false);

  useEffect(() => {
    const reloadAndRedirect = async () => {
      if (programLabel) {
        await reloadUser();

        // Reloading the user should have set up a session.
        const hasSession = selectUserSession(getJwtPayload() || {});

        if (hasSession) {
          // // eslint-disable-next-line no-console
          // console.log('VerifyEmail useEffect dispatching redirect');
          actions.redirectTo(toHome(programLabel));
        } else {
          // // eslint-disable-next-line no-console
          // console.log('VerifyEmail NOT redirecting');
        }
      }
    };

    reloadAndRedirect();
  }, [actions, programLabel]);

  // Refresh our view of the current user, which is may be null if the user
  // started logged out.
  const { data: user } = useCurrentUserQuery();
  const currentUserUpdate = useCurrentUserUpdate();

  const validate = (values) => {
    const errors = {};
    if (!touAgreed && !values.educator) {
      errors.educator = 'Required';
    }
    if (!touAgreed && !values.legal) {
      errors.legal = 'Required';
    }
    return errors;
  };

  const onSubmit = async () => {
    if (!touAgreed) {
      // Save their new agreement to the terms.
      const tou_agreed = moment().utc().format();
      await currentUserUpdate({ uid: user.uid, tou_agreed });
    }

    if (emailVerified) {
      // If their email has already been verified, we can skip the remaining
      // steps, just enter the app.
      actions.redirectTo('/home');
      return;
    }

    // If their email hasn't been verified, try to send them a link now.
    try {
      await sendVerificationEmail(programLabel);
    } catch (e) {
      console.error(e);
      setErrors(sendEmailErrors + 1);
    }
    setSendAttempted(true);
  };

  return (
    <VerifyEmailRender
      agreedEducator={touAgreed}
      agreedLegal={touAgreed}
      emailVerified={emailVerified}
      handleBackToSignIn={onClickBack}
      onSubmit={onSubmit}
      sendAttempted={sendAttempted}
      sendEmailErrors={sendEmailErrors}
      user={user}
      validate={validate}
    />
  );
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ logoutUser, redirectTo }, dispatch),
});

export default connect(null, mapDispatchToProps)(VerifyEmailForm);
