import { Col, Row, Show } from '@perts/ui';
import { EmptyList, Loading, PaginationUseQuery } from 'components';
import { useTerms } from 'components/TermsContext';
import { useCurrentUserQuery, useGroupsPagination } from 'models';
import { ArchiveResultsCard } from './ArchiveResultsCard';

export const ArchiveResultsGroups = ({
  programId,
  filter,
  activePageIndex,
  setActivePageIndex,
}) => {
  const currentUser = useCurrentUserQuery();
  const terms = useTerms();

  // Scope to authenticated user when not super_admin (includes imitated).
  const user_id =
    currentUser.isSuccess && currentUser.data.user_type !== 'super_admin'
      ? { user_id: currentUser.data.uid }
      : {};

  const result = useGroupsPagination(
    {
      programId,
      name: filter,
      ...user_id,
      pageIndex: activePageIndex,
      pageSize: 10,
    },
    {
      keepPreviousData: true,
    },
  );
  const { data: entities = [], isLoading } = result;

  return (
    <>
      <Show when={isLoading}>
        <Row>
          <Col hAlign="center">
            <Loading />
          </Col>
        </Row>
      </Show>

      <Show when={!isLoading}>
        <>
          {!entities.length && (
            <EmptyList text={`No matching ${terms.groups.toLowerCase()}`} />
          )}

          {entities.map((entity) => (
            <ArchiveResultsCard key={entity.uid} entity={entity} />
          ))}
        </>
      </Show>

      <Row>
        <Col vAlign="center" hAlign="flex-end" hAlignSm="center" cols={12}>
          <PaginationUseQuery
            useQueryResults={result}
            onChange={setActivePageIndex}
          />
        </Col>
      </Row>
    </>
  );
};
