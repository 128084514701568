import styled from 'styled-components/macro';
import { theme, Logo, Program } from '../../';

const LogoStyled = styled.div`
  display: block;

  @media (max-width: ${theme.units.menuCollapseWidth}px) {
    display: none;
  }
`;

const LogoBoxStyled = styled.div`
  display: none;

  @media (max-width: ${theme.units.menuCollapseWidth}px) {
    display: block;

    transform: scale(0.75);
  }
`;

type Props = {
  program?: Program;
};

export const LogoResponsive = ({ program }: Props) => (
  <>
    <LogoStyled>
      <Logo program={program} title={Boolean(program)} />
    </LogoStyled>

    <LogoBoxStyled>
      <Logo title={false} />
    </LogoBoxStyled>
  </>
);
