import { ParticipationData } from './types';
import { QueryClient } from 'react-query';
import {
  Participant,
  ParticipantForBatchAdd,
  queryKeyParticipantsByClass,
} from '../';
import {
  postBatch,
  queryByTeam,
  update,
  queryParticipationByParticipant,
} from 'services/triton/participants';
import { pick } from 'lodash';
// -----------------------------------------------------------------------------
//   API Functions
// -----------------------------------------------------------------------------

// Query all of the Participants that belong to the Class with `classId`.

type ParticipantsQueryByClassId = (classId: string) => Promise<Participant[]>;

export const participantsQueryByClassId: ParticipantsQueryByClassId =
  queryByTeam;

const getParticipantForUpdate = (
  participant: Participant,
): Partial<Participant> => {
  const allowedAttributes = [
    'uid',
    'student_id',
    'classroom_ids',
    'in_target_group',
  ];
  return pick(participant, allowedAttributes);
};

// Add multiple particpants at once.

type AddParticipants = (
  participants: ParticipantForBatchAdd[],
) => Promise<Participant[]>;

export const addParticipants: AddParticipants = (participants) =>
  postBatch(participants);

// Update participant

type UpdateParticipant = (participant: Participant) => Promise<Participant>;

export const updateParticipant: UpdateParticipant = (participant) =>
  update(getParticipantForUpdate(participant));

// Update participants

type UpdateParticipants = (
  participants: Participant[],
) => Promise<Participant[]>;

export const updateParticipants: UpdateParticipants = (participants) =>
  Promise.all(
    participants.map((p) => update(getParticipantForUpdate(p))),
  ).then();

// Invalidate participants update participants
export const invalidateUpdateParticipants = (
  queryClient: QueryClient,
  classId: string,
) => queryClient.invalidateQueries(queryKeyParticipantsByClass(classId));

// Remove participants

type RemoveParticipantsByClassroomId = (
  participants: Participant[],
  classroomId: string,
) => Promise<void>;

export const removeParticipants: RemoveParticipantsByClassroomId = (
  participantsToRemove,
  classroomId,
) =>
  Promise.all(
    participantsToRemove.map((participant) => {
      participant.classroom_ids = participant.classroom_ids.filter(
        (id) => id !== classroomId,
      );
      return update(getParticipantForUpdate(participant));
    }),
  ).then();

type ParticipationByParticipant = (
  code: string,
  start_date: string,
  end_date: string,
) => Promise<ParticipationData>;

export const participationByParticipant: ParticipationByParticipant =
  queryParticipationByParticipant;
