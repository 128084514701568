import styled, { css } from 'styled-components/macro';

type TagProps = { label: React.ReactNode; color?: string };

const TagDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  background: #efefef;
  border-radius: 4px;
`;

const TagColor = styled.div`
  display: none;
  ${(props) =>
    props.color &&
    css`
      display: block;
      height: 24px;
      width: 24px;
      border-radius: 4px;
      background: ${props.color};

      @media print {
        display: block;
        -webkit-print-color-adjust: exact;
        print-color-adjust: exact;
      }
    `};
`;

const TagLabel = styled.div`
  padding: 0 8px;
  display: flex;
  align-items: center;
  gap: 2px;
`;

export const SelectFilterTag = ({ label, color }: TagProps) => {
  return (
    <TagDiv>
      <TagColor color={color} />
      <TagLabel>{label}</TagLabel>
    </TagDiv>
  );
};
