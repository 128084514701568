import { InfoBox, Show, Row, Col, Visible } from '@perts/ui';
import { useTerms } from 'components';

export const RosterSignOnTypeMixed = ({
  allEqualPortalTypes,
  isBatchEditForm,
  someClassesWithRule,
  dirty,
  initialValues,
  values,
}) => {
  const terms = useTerms();

  const isFromOrToStudentId =
    initialValues.portalType === 'student_id' ||
    values.portalType === 'student_id';

  const displayRuleWillBeDisabled =
    dirty &&
    someClassesWithRule &&
    isFromOrToStudentId &&
    ((allEqualPortalTypes && isBatchEditForm) || !isBatchEditForm);

  return (
    <>
      {/* This warning is never needed if this isn't a batch-edit form. */}
      <Show when={isBatchEditForm && !displayRuleWillBeDisabled}>
        <Row>
          <Col>
            <Show when={!values.portalType}>
              <Visible when={!values.portalType}>
                <InfoBox>{terms.classes} have mixed sign-on types.</InfoBox>
              </Visible>
            </Show>
            <Show when={Boolean(values.portalType)}>
              <Visible when={dirty && !allEqualPortalTypes}>
                <InfoBox
                  color="warning"
                  aria-label="Saving will overwrite mixed settings."
                >
                  The selected {terms.classes.toLowerCase()} have mixed
                  settings. Saving will overwrite them.
                </InfoBox>
              </Visible>
            </Show>
          </Col>
        </Row>
      </Show>

      <Show when={displayRuleWillBeDisabled}>
        <Row>
          <Col>
            <InfoBox color="warning">
              Your {terms.participant.toLowerCase()} sign-on rule will be
              disabled.
            </InfoBox>
          </Col>
        </Row>
      </Show>
    </>
  );
};
