import { message } from 'antd';
import { useContext } from 'react';
import { useQueryClient, useMutation } from 'react-query';
import { difference } from 'lodash';

import {
  GroupMember,
  queryKeyUsersByGroup,
  queryKeyCurrentUser,
  transformUsersRemoveGroup,
  selectUserClasses,
  selectUserIsGroupManager,
  sortUserByEmail,
  updateUsers,
  useClassesQueryByParams,
  useCurrentUserQuery,
  useGroupGetByParams,
  User,
  useUsersQueryByParams,
} from 'models';
import { toHomeNoProgram, useParams } from 'pages';
import { getAuthUser } from 'services/triton/helpers/getAuthUser';
import { useCloseModal, useGetCheckedStates } from 'utils';

import Loading from 'components/Loading';
import { ErrorMessageBox } from 'components/ErrorMessageBox';

import { MembersRemoveForm } from './MembersRemoveForm';
import TermsContext from 'components/TermsContext';
import { getMessageFromErrors } from '@perts/util';

// eslint-disable-next-line complexity
export const MembersRemove = () => {
  const { groupId } = useParams();
  const queryKeyGroupUsers = queryKeyUsersByGroup(groupId);
  const terms = useContext(TermsContext);
  const checked = useGetCheckedStates();

  // Close the modal.
  const closeModal = useCloseModal();
  const closeModalHome = useCloseModal({ pathname: toHomeNoProgram() });
  const close = ({ redirectToHome }) =>
    redirectToHome ? closeModalHome() : closeModal();

  const groupQuery = useGroupGetByParams();
  const classesQuery = useClassesQueryByParams();
  const usersQuery = useUsersQueryByParams();
  const currentUserQuery = useCurrentUserQuery();

  const classes = classesQuery.data || [];
  const users = usersQuery.data || [];

  // Create GroupMembers from Users.
  // TODO Convert to a custom useQuery hook.
  // ISSUE https://github.com/PERTS/perts/issues/67
  const members: GroupMember[] = users
    .map((user) => ({
      ...user,
      classes: selectUserClasses(user, classes),
      isManager: user.managed_organizations.includes(groupId),
    }))
    .sort(sortUserByEmail);

  // Determine members that have been selected.
  const membersSelected = members.filter((member) => checked[member.uid]);

  // From selected, filter members that can be removed, those with no classes.
  const membersToRemove = membersSelected.filter(
    (member) => member.classes.length === 0,
  );

  // From selected, filter members that cannot be removed, those with classes.
  const membersWithClasses = membersSelected.filter(
    (member) => member.classes.length > 0,
  );

  // From membersToRemove, determine if authenticated user is included.
  const membersToRemoveIncludesSelf = membersToRemove.some(
    (member) => member.uid === getAuthUser().uid,
  );

  const managerIds = members
    .filter((u) => selectUserIsGroupManager(u, groupId))
    .map((u) => u.uid);
  const removedIds = membersToRemove.map((u) => u.uid);
  const membersToRemoveIncludesAllManagers =
    difference(managerIds, removedIds).length === 0;

  const toastSuccessMsg =
    `Successfully removed ${terms.classManagers.toLowerCase()}(s) from the ` +
    `${terms.group.toLowerCase()}.`;
  const toastErrorMsg =
    `There was a problem removing ${terms.classManagers.toLowerCase()}(s) from ` +
    `the ${terms.group.toLowerCase()}.`;

  const queryClient = useQueryClient();
  const mutation = useMutation(updateUsers, {
    onMutate: async (updatedUsers: User[]) => {
      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries(queryKeyGroupUsers);
      await queryClient.cancelQueries(queryKeyCurrentUser());

      // Snapshot the previous value.
      const previousGroupUsers =
        queryClient.getQueryData<User[]>(queryKeyGroupUsers);
      const previousCurrentUser = queryClient.getQueryData<User>(
        queryKeyCurrentUser(),
      );

      // Update: remove deleted items from cache.
      const idsToDelete = updatedUsers.map((u) => u.uid);
      const optimisticGroupUsers = previousGroupUsers
        ? previousGroupUsers.filter((user) => !idsToDelete.includes(user.uid))
        : [];
      const optimisticCurrentUser = updatedUsers.find(
        (u) => u.uid === currentUserQuery.data?.uid,
      );

      queryClient.setQueryData<User[]>(
        queryKeyGroupUsers,
        optimisticGroupUsers,
      );
      if (optimisticCurrentUser) {
        // Note: the current user may not be among the group members being
        // removed, e.g. if the group is being viewed by a network lead.
        queryClient.setQueryData<User>(
          queryKeyCurrentUser(),
          optimisticCurrentUser,
        );
      }

      return { previousCurrentUser, previousGroupUsers };
    },

    onSuccess: () => {
      // Pop a success toast, if a message was provided.
      message.success(toastSuccessMsg);
    },

    onError: (err, formValues, context) => {
      // Pop an error toast, if a message was provided.
      message.error(toastErrorMsg);

      // Rollback to previous if there's an error.
      if (context?.previousCurrentUser) {
        queryClient.setQueryData<User>(
          queryKeyCurrentUser(),
          context.previousCurrentUser,
        );
      }
      if (context?.previousGroupUsers) {
        queryClient.setQueryData<User[]>(
          queryKeyGroupUsers,
          context.previousGroupUsers,
        );
      }
    },
  });

  // https://stackoverflow.com/questions/65760158/react-query-mutation-typescript
  // Formik onSubmit handler
  const onSubmit = async () => {
    await mutation.mutateAsync(
      transformUsersRemoveGroup(membersToRemove, groupId),
    );
  };

  if (
    groupQuery.isLoading ||
    classesQuery.isLoading ||
    usersQuery.isLoading ||
    currentUserQuery.isLoading
  ) {
    return <Loading />;
  }

  if (
    !groupQuery.isSuccess ||
    !usersQuery.isSuccess ||
    !classesQuery.isSuccess ||
    !currentUserQuery.isSuccess
  ) {
    return (
      <ErrorMessageBox>
        {getMessageFromErrors([
          groupQuery.error,
          usersQuery.error,
          classesQuery.error,
          currentUserQuery.error,
        ])}
      </ErrorMessageBox>
    );
  }

  return (
    <MembersRemoveForm
      close={close}
      onSubmit={onSubmit}
      group={groupQuery.data}
      membersToRemove={membersToRemove}
      membersToRemoveIncludesAllManagers={membersToRemoveIncludesAllManagers}
      membersToRemoveIncludesSelf={membersToRemoveIncludesSelf}
      membersWithClasses={membersWithClasses}
    />
  );
};
