import { useMutation, useQueryClient } from 'react-query';
import { message } from 'antd';

import { useUserGetByParams, updateUser, queryKeyUser, User } from 'models';
import { useParams } from 'pages';

import { ErrorMessageBox } from 'components/ErrorMessageBox';
import { UserAccountForm, UserAccountFormValues } from './UserAccountForm';
import Loading from 'components/Loading';
import { getMessageFromErrors } from '@perts/util';

export const UserAccount = () => {
  const { userId } = useParams();
  const userQuery = useUserGetByParams();
  const queryClient = useQueryClient();
  const queryKey = queryKeyUser(userId);

  const mutation = useMutation(
    ({ name, receive_email }: UserAccountFormValues) =>
      updateUser({ uid: userId, name, receive_email }),
    {
      onSuccess: (data) => {
        queryClient.setQueryData<User>(queryKey, data);
        message.success('Successfully updated user account.');
      },
      onError: () => {
        message.error('Unable to update user account.');
      },
    },
  );

  if (userQuery.isLoading) {
    return <Loading />;
  }

  // Display error.
  if (!userQuery.isSuccess) {
    return (
      <ErrorMessageBox>
        {getMessageFromErrors([userQuery.error])}
      </ErrorMessageBox>
    );
  }

  return (
    <UserAccountForm user={userQuery.data} onSubmit={mutation.mutateAsync} />
  );
};
