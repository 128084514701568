import { Switch, Route } from 'react-router-dom';
import styled from 'styled-components/macro';

import { toArchive, toArchiveProgram, toUser, useParams } from 'pages';

import { Text, Row, Col, Link } from '@perts/ui';
import { BreadcrumbsGroup } from './BreadcrumbsGroup';
import { BreadcrumbsNetwork } from './BreadcrumbsNetwork';
import { IconBreadcrumbChevron } from './IconBreadcrumbChevron';
import theme from 'components/theme';
import { BreadcrumbsArchive } from './BreadcrumbsArchive';

const BreadcrumbAccount = () => (
  <>
    <IconBreadcrumbChevron />
    <span>Account</span>
  </>
);

const BreadcrumbHome = () => (
  <Row>
    <Col vAlign="center" flexWrap="wrap">
      <Text as="nav">
        <Link to="/">Home</Link>
      </Text>
    </Col>
  </Row>
);

const BreadcrumbsUser = () => (
  <Row>
    <Col vAlign="center" flexWrap="wrap">
      <Text as="nav">
        <Link to="/">Home</Link>
        <Switch>
          <Route path={toUser()}>
            <BreadcrumbAccount />
          </Route>
        </Switch>
      </Text>
    </Col>
  </Row>
);

const BreadcrumbsNavigationContainer = styled.div`
  display: flex;
  nav > :not(:first-child) {
    margin-left: 0;
  }

  @media only screen and (max-width: ${theme.units.gridBreakpointSmall}px) {
    nav > *:nth-last-child(n + 6) {
      display: none;
    }
  }
`;

export const BreadcrumbsNavigationComponent = () => {
  const { userId, groupId, networkId } = useParams();

  return (
    <Switch>
      {userId && <BreadcrumbsUser />}
      {networkId && <BreadcrumbsNetwork />}
      {groupId && <BreadcrumbsGroup />}
      <Route path={[toArchiveProgram(), toArchive()]}>
        <BreadcrumbsArchive />
      </Route>
      <BreadcrumbHome />
    </Switch>
  );
};

export const BreadcrumbsNavigation = () => (
  <BreadcrumbsNavigationContainer>
    <BreadcrumbsNavigationComponent />
  </BreadcrumbsNavigationContainer>
);
