import styled from 'styled-components/macro';

export const GraphWrapper = styled.figure`
  margin: 0 auto;
  padding: 30px 15px;
  display: flex;
  flex-direction: row;
  max-width: 700px;

  img {
    width: 100%;
    max-width: 100%;
    object-fit: cover;
    height: auto;
  }

  @media print {
    max-width: 60%;
  }
`;
