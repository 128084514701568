import { useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { redirectClear } from 'state/ui/actions';
import selectors from 'state/selectors';

const Redirector = ({ redirect: { path, push }, clearRedirect }) => {
  useEffect(() => {
    if (path) {
      clearRedirect();
    }
  }, [clearRedirect, path]);

  // Don't redirect if we're at /logout. Why? On a 401 response from an API
  // call, handleApiResponse dispatches a redirect request. In many cases, we've
  // queued many API calls. If multiple come back 401, then we'll trigger
  // multiple redirects. The first one will properly set the continue_url,
  // allowing the user to get back to their previous view after logging back in,
  // but subsequent logouts will wipe that out.
  if (window.location.pathname !== '/logout' && path) {
    return <Redirect to={path} push={push} />;
  }
  return null;
};

const mapStateToProps = (state, props) => ({
  redirect: selectors.redirect(state, props),
});

const mapDispatchToProps = (dispatch) => ({
  clearRedirect: bindActionCreators(redirectClear, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Redirector);
