import { QueryClient } from 'react-query';
import {
  Group,
  GroupDefaultSettings,
  queryKeyGroups,
  queryKeyGroup,
} from 'models';
import {
  get,
  post,
  query,
  queryByIds,
  queryByNetwork,
  queryByUser,
  queryPagination,
  remove,
  update,
} from 'services/triton/organizations';

// -----------------------------------------------------------------------------
//   API Functions
// -----------------------------------------------------------------------------

// Get the Group with `groupId`.

type GroupGetById = (groupId: string) => Promise<Group>;

export const groupGetById: GroupGetById = get;
export const groupsQuery = query;
export const groupsQueryByUser = queryByUser;
export const groupsQueryPagination = queryPagination;

// Add group to Program.

type GroupsAddToProgram = (
  name: string,
  program_id: string,
  default_settings?: GroupDefaultSettings,
) => Promise<Group>;

export const groupsAddToProgram: GroupsAddToProgram = (
  name,
  program_id,
  default_settings = {},
) => post({ name, program_id, default_settings });

export const invalidateGroupsAddToProgram = (queryClient: QueryClient) =>
  queryClient.invalidateQueries(queryKeyGroups());

// Remove Group.

type GroupRemove = (group: Group) => Promise<void>;

export const groupRemove: GroupRemove = (group) => remove(group.uid);

export const invalidateGroupRemove = (queryClient: QueryClient) =>
  queryClient.invalidateQueries(queryKeyGroups());
// Update group

type UpdateGroup = (group: Group) => Promise<Group>;

export const updateGroup: UpdateGroup = update;

export const invalidateUpdateGroup = (
  queryClient: QueryClient,
  groupId: string,
) => queryClient.invalidateQueries(queryKeyGroup(groupId));

type GroupsQueryByNetwork = (networkId: string) => Promise<Group[]>;

export const groupsQueryByNetwork: GroupsQueryByNetwork = async (networkId) => {
  const organizations = await queryByNetwork(networkId);

  return organizations;
};

type GroupsQueryByIds = (groupIds: string[]) => Promise<Group[]>;

export const groupsQueryByIds: GroupsQueryByIds = queryByIds;
