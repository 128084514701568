import { cloneDeep, set } from 'lodash';

import { Cycle } from 'models';
import { cleanCycleErrors } from './cleanCycleErrors';
import { getDatePickerName } from '../getDatePickerName';
import { getUpdatedErrorsFromCycle } from './getUpdatedErrorsFromCycle';
import { getCyclesToAddOrRemoveFromErrors } from './getCyclesToAddOrRemoveFromErrors';

export const getCycleErrors = (
  currentErrors: any,
  classId: string,
  modifiedCycleId: string,
  cyclesValues: Cycle[],
  start_date: string | null,
  end_date: string | null,
) => {
  let updatedErrors = cloneDeep(currentErrors);

  // Determine cycles to compare date ranges,
  // removing current cycle
  const cyclesToCompareRaw = cyclesValues.filter(
    (c) => c.uid !== modifiedCycleId,
  );

  const cyclesToCompare = {};

  cyclesToCompareRaw.forEach((c) => {
    cyclesToCompare[c.uid] = {
      start_date: c.start_date,
      end_date: c.end_date,
    };
  });

  const [errorsToAddModifiedCycle, errorsToRemoveModifiedCycle] =
    getCyclesToAddOrRemoveFromErrors({
      cyclesToCompare,
      start_date,
      end_date,
    });

  cyclesValues.forEach((cycleToUpdate) => {
    const { classes = {} } = updatedErrors;
    const { [classId]: currentClass = {} } = classes;
    const { cycles = {} } = currentClass;
    const { [cycleToUpdate.uid]: currentCycleErrors = [] } = cycles;
    // Path related to input name
    const inputName = getDatePickerName(classId, cycleToUpdate.uid);

    const updatedCycleErrors = getUpdatedErrorsFromCycle({
      cycleId: cycleToUpdate.uid,
      modifiedCycleId,
      currentCycleErrors,
      errorsToAddModifiedCycle,
      errorsToRemoveModifiedCycle,
    });

    // Update errors
    updatedErrors = set(updatedErrors, inputName, updatedCycleErrors);
  });

  return cleanCycleErrors(updatedErrors, classId);
};
