import { Switch, Route } from 'react-router-dom';
import {
  toGroupSurveys,
  toGroupSurveysDefaultSettings,
  toGroupSurveysEditMetrics,
  toGroupSurveysInstructions,
  toGroupClassSurveyInstructions,
  toGroupSurveyQRCode,
  SurveysEditMetrics,
  SurveysEditAdvanced,
  SurveyInstructions,
  SurveysDefaultSettings,
  SurveyQRCode,
  Surveys,
  toGroupSurveysEditAdvanced,
} from 'pages';

export const SurveysRouter = () => (
  <>
    <Switch>
      <Route path={toGroupSurveysDefaultSettings()}>
        <SurveysDefaultSettings />
      </Route>
      <Route path={toGroupClassSurveyInstructions()}>
        <SurveyInstructions />
      </Route>
      <Route path={toGroupSurveysInstructions()}>
        <SurveyInstructions />
      </Route>
      <Route path={toGroupSurveys()}>
        <Surveys />
      </Route>
    </Switch>

    <Route path={toGroupSurveysEditMetrics()}>
      <SurveysEditMetrics />
    </Route>
    <Route path={toGroupSurveysEditAdvanced()}>
      <SurveysEditAdvanced />
    </Route>
    <Route path={toGroupSurveyQRCode()}>
      <SurveyQRCode />
    </Route>
  </>
);
