import { useState } from 'react';
import { keyBy, map, maxBy, reverse, sortBy } from 'lodash';
import { Formik, Form } from 'formik';

import { Show } from '@perts/ui';
import { useClientPagination } from 'utils/useClientPagination';
import getUnscheduledClasses from 'utils/getUnscheduledClasses';
import useToggles from 'utils/useToggles';

import { Class, Cycle, Program } from 'models';
import { ClassWithCycles, ScheduleSort } from '../';
import { ScheduleTable } from '../ScheduleTable';
import { diffClassesCycles } from './diffClassesCycles';
import { SchedulesActionbar } from '../SchedulesActionbar';
import { useQueryParams } from 'utils/useQueryParams';
import useFilterField from 'utils/useFilterField';
import { useTerms } from 'components/TermsContext';

export type SchedulesEditCyclesFormValues = {
  cycles: Cycle[];
};

export type SchedulesEditCyclesFormProps = {
  classes: ClassWithCycles[];
  program: Program;
  onSubmit: (values: SchedulesEditCyclesFormValues) => void;
  mayAddCycles: boolean;
  mayUpdateCycles: boolean;
  mayEditDefaultSettings: boolean;
};

export type CycleClassValuesProps = {
  classes?: { [uid: string]: Class };
};

export const SchedulesEditCyclesForm = ({
  classes: classesUnsorted,
  onSubmit,
  program,
  mayAddCycles,
  mayUpdateCycles,
  mayEditDefaultSettings,
}: SchedulesEditCyclesFormProps) => {
  const terms = useTerms();
  const { get, remove } = useQueryParams();

  // Sorting.
  const [sort, setSort] = useState<ScheduleSort>(null);
  const sortByStart = (ordinal) => (cls) => cls.cycles[ordinal - 1]?.start_date;
  const sortByEnd = (ordinal) => (cls) => cls.cycles[ordinal - 1]?.end_date;
  // Native sort mutates the array (bad for React props, can trigger recursive
  // updates) and isn't necessarily stable. Lodash sortBy solves both.
  // https://medium.com/@fsufitch/is-javascript-array-sort-stable-46b90822543f
  let classes = classesUnsorted;
  if (sort) {
    const ascending = sortBy(classesUnsorted, [
      sortByStart(sort.ordinal),
      sortByEnd(sort.ordinal),
    ]);
    classes = sort.ascending ? ascending : reverse(ascending);
  }

  // Filtering.
  const { FilterField, filteredItems, clearFilter } = useFilterField(
    classes,
    ['name'],
    {
      label: '',
      placeholder: `Filter by ${terms.class.toLowerCase()} name`,
      matchesText: false,
    },
  );

  // Paging.
  const { currentPageItems, Pagination, setCurrentPage } = useClientPagination({
    data: filteredItems,
  });

  // Selection.
  const {
    someMarked,
    selected,
    checked,
    allPageIsSelected,
    toggleAll,
    toggleAllFilter,
    ControlToggle,
    Toggle,
  } = useToggles(classes, 'uid', {
    pageItems: currentPageItems,
    filteredItems,
  });

  const classesInitialValues = keyBy(
    classes.map((cls) => ({
      ...cls,
      cycles: keyBy(cls.cycles, 'uid'),
    })),
    'uid',
  );

  const removeElementIdFromUrl = () => {
    const paramElementId = get('elementId');
    if (paramElementId) {
      remove('elementId');
    }
  };

  const unscheduledClasses = getUnscheduledClasses(classes);

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        classes: classesInitialValues,
      }}
      onSubmit={async ({ classes: classesFromForm }) => {
        try {
          // Transform classesFromForm to class array
          const formattedClassesFromForm = map(classesFromForm, (cls) => ({
            ...cls,
            cycles: map(cls.cycles),
          }));

          const updatedCycles = diffClassesCycles(
            classes,
            formattedClassesFromForm,
          );
          removeElementIdFromUrl();
          await onSubmit({ cycles: updatedCycles });

          // TODO display success toast
        } catch (error) {
          // TODO display failure toast
        }
      }}
    >
      {({ dirty, isSubmitting, isValid, values, errors, handleReset }) => {
        // Count number of pending cycle changes
        const { classes: classesFromForm } = values;

        // Transform classesFromForm to class array
        const formattedClassesFromForm = map(classesFromForm, (cls) => ({
          ...cls,
          cycles: map(cls.cycles),
        }));

        const numberOfPendingChanges = diffClassesCycles(
          classes,
          formattedClassesFromForm,
        ).length;

        const clsWithMoreCycles = maxBy(
          classes,
          ({ cycles = [] }) => cycles.length,
        );
        const currentMaxCycles = clsWithMoreCycles?.cycles?.length || 0;

        return (
          <>
            <Form>
              <SchedulesActionbar
                program={program}
                classes={classes}
                mayAddCycles={mayAddCycles}
                mayUpdateCycles={mayUpdateCycles}
                someMarked={someMarked}
                dirty={dirty}
                selected={selected}
                isValid={isValid}
                isSubmitting={isSubmitting}
                numberOfPendingChanges={numberOfPendingChanges}
                handleReset={() => {
                  removeElementIdFromUrl();
                  handleReset();
                }}
                Pagination={Pagination}
                toggleAll={toggleAll}
                toggleAllFilter={toggleAllFilter}
                ControlToggle={ControlToggle}
                errors={errors}
                setCurrentPage={setCurrentPage}
                clearFilter={clearFilter}
                mayEditDefaultSettings={mayEditDefaultSettings}
                filteredItems={filteredItems}
                formValues={values}
              />
              <Show when={classes.length > 0}>
                <ScheduleTable
                  currentMaxCycles={currentMaxCycles}
                  pagedClasses={currentPageItems}
                  Toggle={Toggle}
                  someMarked={someMarked}
                  checked={checked}
                  selected={selected}
                  program={program}
                  mayAddCycles={mayAddCycles}
                  mayUpdateCycles={mayUpdateCycles}
                  allPageIsSelected={allPageIsSelected}
                  unscheduledClasses={unscheduledClasses}
                  FilterField={FilterField}
                  setSort={setSort}
                  sort={sort}
                />
              </Show>
            </Form>
          </>
        );
      }}
    </Formik>
  );
};
