import styled from 'styled-components/macro';
import { theme } from 'components';

export const ReportNavigationStyled = styled.div`
  // <Topbar-like styles>
  // Makes it look like an extension of Topbar by (1) sticky/floating behavior
  // and (2) override the padding that normally appears around AppContent.
  position: sticky;
  z-index: 100;
  display: flex;
  flex-direction: column;

  top: -${theme.units.bodyPadding};
  margin-top: -${theme.units.bodyPadding};
  margin-right: -${theme.units.bodyPadding};
  margin-left: -${theme.units.bodyPadding};

  @media (max-width: ${theme.units.gridBreakpointMedium}px),
    (max-height: ${theme.units.gridBreakpointMedium}px) {
    top: -${theme.units.bodyPaddingMd};
    margin-top: -${theme.units.bodyPaddingMd};
    margin-right: -${theme.units.bodyPaddingMd};
    margin-left: -${theme.units.bodyPaddingMd};
  }

  @media (max-width: ${theme.units.gridBreakpointSmall}px),
    (max-height: ${theme.units.gridBreakpointSmall}px) {
    top: -${theme.units.bodyPaddingSm};
    margin-top: -${theme.units.bodyPaddingSm};
    margin-right: -${theme.units.bodyPaddingSm};
    margin-left: -${theme.units.bodyPaddingSm};
  }
  // </Topbar-like styles>

  @media print {
    // Don't need navigation when printing.
    display: none;
  }
`;
