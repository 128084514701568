import * as Yup from 'yup';
import { Formik } from 'formik';
import keyBy from 'lodash/keyBy';

import {
  Participant,
  ParticipantForBatchAdd,
  useClassGetByParams,
  useParticipantsByParams,
  useParticipantsBatchEdit,
} from 'models';
import { useParams } from 'pages';

import { ClassRosterBatchEditForm } from './ClassRosterBatchEditForm';
import { parseStudentIds, participantsToIdsString } from './helpers';
import Loading from 'components/Loading';
import { ErrorMessageBox } from 'components/ErrorMessageBox';
import { getMessageFromErrors } from '@perts/util';

type BatchEditFormValues = { studentIds: string; roster_locked: boolean };

export const ClassRosterBatchEdit = () => {
  const { classId } = useParams();

  // Query for Class.
  const classQuery = useClassGetByParams();

  // Query for Participants associated with Class.
  const paticipantsQuery = useParticipantsByParams();

  const batchEditMutation = useParticipantsBatchEdit(classId);

  // Display loading.
  if (classQuery.isLoading || paticipantsQuery.isLoading) {
    return <Loading />;
  }

  // Display any errors.
  if (!classQuery.isSuccess || !paticipantsQuery.isSuccess) {
    return (
      <ErrorMessageBox>
        {getMessageFromErrors([classQuery.error, paticipantsQuery.error])}
      </ErrorMessageBox>
    );
  }

  const cls = classQuery.data;

  const rosteredParticipants = paticipantsQuery.data.filter((p) => {
    const [classroomId] = p.classroom_ids;
    return classroomId === cls.classroom_id;
  });
  const initialValues: BatchEditFormValues = {
    studentIds: participantsToIdsString(rosteredParticipants),
    roster_locked: cls.roster_locked,
  };

  const hasVerifiedParticipants = rosteredParticipants.some((r) =>
    Boolean(r.verified),
  );

  const onSubmit = async ({
    studentIds,
    roster_locked,
  }: BatchEditFormValues) => {
    const { addingIds, removingIds } = parseStudentIds(
      classQuery.data,
      initialValues.studentIds,
      studentIds,
    );

    // Build new participants for each added id.
    const toAdd: ParticipantForBatchAdd[] = addingIds.map((pid) => ({
      // Special property for creation within a single classroom. See
      // server handler for details.
      classroom_id: cls.classroom_id,
      team_id: cls.uid,
      ...pid,
    }));

    // Look up participants matching the removed ids.
    // Their associations will be modified downstream.
    const participantsById = keyBy(rosteredParticipants, 'stripped_student_id');
    const toRemove: Participant[] = removingIds.map(
      (pid) => participantsById[pid.stripped_student_id],
    );

    await batchEditMutation.mutateAsync({
      classroomId: cls.classroom_id,
      toAdd,
      toRemove,
      roster_locked,
    });
  };

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      validationSchema={Yup.object().shape({
        studentIds: Yup.string().required('Required.'),
        roster_locked: Yup.bool(),
      })}
      onSubmit={onSubmit}
      validateOnBlur={true}
    >
      <ClassRosterBatchEditForm
        cls={cls}
        hasVerifiedParticipants={hasVerifiedParticipants}
      />
    </Formik>
  );
};
