// Reference: https://headlessui.dev/react/dialog

// TODO Create this component.
// - This is curently just stubbed out to get modal routing working with some
//   minimal styles snagged from Tailwind CSS.
// - I left in Transition and styles as found in the demo linked above.
//   We'll want to this get this working.

import { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import styled from 'styled-components/macro';
import { theme } from '../../';

const DialogStyled = styled(Dialog)<any>`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 105;
  overflow-y: auto;

  @media print {
    display: none;
  }
`;

const ContainerTransitionChild = styled.div`
  min-height: 100vh;
  text-align: center;

  @media only screen and (max-width: ${theme.units.gridBreakpointMedium}px) {
    margin-left: ${theme.units.appSidebarWidthCollapsed};
  }
`;

const DialogOverlay = styled(Dialog.Overlay)`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  --tw-bg-opacity: 1;
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  opacity: 0.3;

  @media print {
    display: none;
  }
`;

const SpanElement = styled.span`
  display: inline-block;
  height: 100vh;
  vertical-align: middle;
`;

const DialogChildrenStyled = styled.div`
  display: inline-block;
  width: 100%;
  max-width: 40rem;
  padding: 1.5rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
  text-align: left;
  vertical-align: middle;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 0.15s;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  border-radius: 1rem;

  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-bg-opacity: 1;
  --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
    0 10px 10px -5px rgba(0, 0, 0, 0.04);

  transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
`;

export type ModalProps = {
  // The Modal content.
  children: React.ReactNode;

  // If true, the Modal is rendered. I'm not sure this is necessary since it
  // seems we'll be using routing to determine the modal state. But I'm leaving
  // this as a reminder to figure out how to get Transition working. TODO.
  isOpen?: boolean;

  // Callback function to close the Modal. Not providing a `close` function
  // disables both the `Escape` key and clicking outside the modal to close. In
  // this case, you'll want to make sure to provide a way to close from within
  // the Modal.
  close?: () => void;
};

const noop = () => {};

const ModalComponent = ({
  children,
  isOpen = true,
  close = noop,
}: ModalProps) => {
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <DialogStyled open={isOpen} onClose={close}>
        <ContainerTransitionChild>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <DialogOverlay />
          </Transition.Child>

          {/*
              This element is to trick the browser into vertically centering the
              modal contents.
            */}
          <SpanElement aria-hidden="true">&#8203;</SpanElement>

          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <DialogChildrenStyled data-testid="Modal">
              {children}
            </DialogChildrenStyled>
          </Transition.Child>
        </ContainerTransitionChild>
      </DialogStyled>
    </Transition>
  );
};

export const Modal = Object.assign(ModalComponent, {
  Title: ({ children, ...rest }: any) => (
    <Dialog.Title {...rest}>{children}</Dialog.Title>
  ),
  Description: ({ children, ...rest }: any) => (
    <Dialog.Description {...rest}>{children}</Dialog.Description>
  ),
});
