import isEqual from 'lodash/isEqual';
import styled from 'styled-components/macro';

import { IconWarning, Link, Show, theme } from '@perts/ui';

const LinkWrapper = styled.span`
  white-space: nowrap;

  svg {
    position: relative;
    top: 2px;

    color: ${theme.colors.primary};
  }
`;

export const ReportSettingsLink = ({ enabledMetrics, reportMetrics, to }) => (
  <LinkWrapper>
    <Show when={!isEqual(enabledMetrics, reportMetrics)}>
      {/* Show a warning icon to indicate that some data is being hidden. */}
      <IconWarning title="Current settings are hiding data." />{' '}
    </Show>
    <Link to={to}>Settings</Link>
  </LinkWrapper>
);
