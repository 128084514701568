import styled from 'styled-components';
import { theme } from 'components';

const BAR_BORDER_SIZE = 2;

export const BarChartHorizontalContainer = styled.div<{ height: number }>`
  height: ${(props) => props.height}px;

  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const BarChartHorizontalHiddenLabel = styled.span`
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
`;

export const BarChartHorizontalLabelValue = styled.div`
  min-width: 40px;
  text-align: right;
`;

export const BarChart = styled.div<{ height: number }>`
  position: relative;

  height: ${(props) => props.height}px;
  flex-grow: 1;

  border: ${BAR_BORDER_SIZE}px solid ${theme.colors.grayLight};
  border-radius: 6px;

  margin-left: ${theme.units.paddingSm};

  overflow: hidden;
`;

export const BarChartThreshold = styled.div<{
  height: number;
  threshold: number;
  value: number;
}>`
  position: absolute;
  left: ${(props) => props.threshold * 100}%;

  width: 1px;
  height: ${(props) => props.height - 2 * BAR_BORDER_SIZE}px;

  border-color: ${(props) =>
    props.value > props.threshold
      ? theme.colors.white
      : theme.colors.grayMedium};
  border-style: solid;
  border-width: 1px;
`;

export const BarChartBar = styled.div<{
  height: number;
  color: string;
  percent: number;
}>`
  @media print {
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
  }

  background-color: ${(props) => props.color};

  border-color: ${theme.colors.grayMedium};
  border-style: solid;
  border-width: 1px;
  border-radius: 4px;

  width: ${(props) => props.percent}%;
  height: ${(props) => props.height - 2 * BAR_BORDER_SIZE}px;
`;

export const BarChartIconWrapper = styled.div`
  margin-left: ${theme.units.paddingSm};
`;
