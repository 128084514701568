import { useEffect, useState } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { Formik, Form } from 'formik';
import { useParams, useProgramsArchived } from 'models';
import { toArchiveProgram } from 'pages';
import { ArchiveResults } from './ArchiveResults';

export type ArchiveForm = {
  programId: string;
  filter: string;
};

export const Archive = () => {
  const history = useHistory();
  const { programId } = useParams();
  const [filter, setFilter] = useState('');

  const { data: programs = [], isLoading: programIsLoading } =
    useProgramsArchived();

  useEffect(() => {
    // If no program is selected, redirect to first program found.
    if (!programId && programs.length) {
      const toProgramLabel = programs[0].label;
      history.replace(toArchiveProgram(toProgramLabel));
    }
  }, [history, programs, programId]);

  useEffect(() => {
    // Reset filter when user switches program.
    setFilter('');
  }, [programId]);

  const program = programs.find((p) => p.uid === programId);

  const initialValues = {
    programId: program?.short_uid || '',
    filter: '',
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        onSubmit={(formValues: ArchiveForm) => {
          setFilter(formValues.filter);
        }}
      >
        {({ values }) => (
          <Form>
            {values.programId !== '' && values.programId !== programId && (
              /* Redirect on program select change. */
              <Redirect to={toArchiveProgram(values.programId)} />
            )}

            {program && (
              <ArchiveResults
                programs={programs}
                programId={program.uid}
                filter={filter}
                programIsLoading={programIsLoading}
              />
            )}
          </Form>
        )}
      </Formik>
    </>
  );
};
