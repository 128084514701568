import { Redirect } from 'react-router-dom';

import Loading from 'components/Loading';
import { NetworkReport23, toArchiveNetworkReportId, useParams } from 'pages';
import {
  selectMostCurrentReport,
  useDatasetNetwork,
  useReportsQueryByNetworkId,
} from 'models';

const OldReportRedirect = () => {
  const { networkId } = useParams();

  const reportsQuery = useReportsQueryByNetworkId(networkId);

  if (reportsQuery.isLoading) {
    return <Loading />;
  }

  const mostCurrentReport = selectMostCurrentReport(reportsQuery.data || []);

  if (!mostCurrentReport) {
    return <>No report found.</>;
  }

  return (
    <Redirect to={toArchiveNetworkReportId(networkId, mostCurrentReport.uid)} />
  );
};

export const ArchiveNetworkReport = () => {
  const { networkId } = useParams();

  const datasetQuery = useDatasetNetwork(networkId);

  if (datasetQuery.isLoading) {
    return <Loading />;
  }

  if (!datasetQuery.data?.results) {
    return <OldReportRedirect />;
  }

  return <NetworkReport23 />;
};
