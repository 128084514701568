import { useParams } from 'react-router-dom';

import { GuideBox, HelpText, Link } from '@perts/ui';

import {
  PagesParams,
  toGroupClasses,
  toGroupReports,
  toGroupSchedules,
} from 'pages';
import { helpArticles } from 'config';
import { useTerms } from 'components/TermsContext';

export const ParticipationGuideBox = ({
  // Are there Classes?
  isClasses = false,

  // Have schedules been set for some of those Classes?
  isSchedulesSet = false,
}) => {
  const { groupId = '' } = useParams<PagesParams>();
  const terms = useTerms();

  if (!isClasses) {
    return (
      <GuideBox
        action={
          <Link to={toGroupClasses(groupId)}>
            Create {terms.aClass} to see participation data.
          </Link>
        }
        faq={
          <HelpText articleId={helpArticles.participationHowToUseCommunity}>
            How to use this dashboard
          </HelpText>
        }
        next={<Link to={toGroupReports(groupId)}>Reports</Link>}
      />
    );
  }

  if (isClasses && !isSchedulesSet) {
    return (
      <GuideBox
        action={
          <Link to={toGroupSchedules(groupId)}>Set a survey schedule.</Link>
        }
        faq={
          <HelpText articleId={helpArticles.participationHowToUseCommunity}>
            How to use this dashboard
          </HelpText>
        }
        next={<Link to={toGroupReports(groupId)}>Reports</Link>}
      />
    );
  }

  return null;
};
