import { useParams } from 'react-router-dom';
import styled from 'styled-components/macro';
import { programs as programConfs } from '@perts/config';
import {
  IconCalendar,
  IconConversation,
  IconClipboardList,
  IconAward,
  IconReport,
  IconUsergroup,
  IconWarning,
  IconUserVoice,
  Menu,
  Show,
} from '@perts/ui';

import {
  PagesParams,
  isActiveMenuClasses,
  isActiveMenuReports,
  toGroupClasses,
  toGroupMembers,
  toGroupParticipation,
  toGroupReports,
  toGroupSchedules,
  toGroupSurveys,
} from 'pages';
import {
  selectClassCycles,
  useClassesQueryByParams,
  useCyclesQueryByParams,
  useGroupGetByParams,
} from 'models';

import { CANVAS_DOMAIN, CANVAS_PROTOCOL } from 'services/triton/config';
import { useTerms } from 'components/TermsContext';
import theme from 'components/theme';
import getUnscheduledClasses from 'utils/getUnscheduledClasses';

const IconWarningStyled = styled(IconWarning)`
  font-size: 22px;
  color: ${theme.colors.white};
`;

export const GroupPagesMenu = () => {
  const { groupId } = useParams<PagesParams>();
  // Query for Classes associated with Group.
  const classesQuery = useClassesQueryByParams();

  // Query for cycles from all classes.
  const cyclesQuery = useCyclesQueryByParams();

  const groupQuery = useGroupGetByParams();

  const terms = useTerms();

  const isLoading =
    !terms.groupMembers ||
    !terms.classes ||
    classesQuery.isLoading ||
    cyclesQuery.isLoading ||
    groupQuery.isLoading;

  if (isLoading) {
    return null;
  }

  // Set default values
  const classes = classesQuery.data || [];
  const cycles = cyclesQuery.data || [];
  const group = groupQuery.data;

  // Fill classes with cycles.
  const classesFilled = classes.map((cls) => ({
    ...cls,
    cycles: selectClassCycles(cls, cycles),
  }));

  const unscheduledClasses = getUnscheduledClasses(classesFilled);

  const programConf = programConfs.find((c) => c.uid === group?.program_id);
  const canvasProgramName = programConf?.canvasProgram?.name || '';

  return (
    <Menu>
      <Show when={Boolean(group?.course_id)}>
        <Menu.ItemLink
          to={`${CANVAS_PROTOCOL}://${CANVAS_DOMAIN}/courses/${group?.course_id}`}
          icon={<IconAward />}
        >
          {canvasProgramName}
        </Menu.ItemLink>
      </Show>

      <Menu.Item icon={<IconUsergroup />} to={toGroupMembers(groupId)}>
        {terms.groupMembers}
      </Menu.Item>

      <Menu.Item
        icon={<IconUserVoice />}
        to={toGroupClasses(groupId)}
        isActive={isActiveMenuClasses}
      >
        {terms.classes}
      </Menu.Item>

      <Menu.Item icon={<IconCalendar />} to={toGroupSchedules(groupId)}>
        <span>Schedules</span>
        <Show when={unscheduledClasses.length > 0}>
          <IconWarningStyled />
        </Show>
      </Menu.Item>

      <Menu.Item icon={<IconClipboardList />} to={toGroupSurveys(groupId)}>
        Surveys
      </Menu.Item>

      <Menu.Item icon={<IconConversation />} to={toGroupParticipation(groupId)}>
        Participation
      </Menu.Item>

      <Menu.Item
        icon={<IconReport />}
        to={toGroupReports(groupId)}
        isActive={isActiveMenuReports}
      >
        Reports
      </Menu.Item>
    </Menu>
  );
};
