/**
 * One of several show/hide components: Show (hide children by not rendering),
 * Visible (hide children with `visibility: hidden`), and Display (hide children
 * with `display: none`)
 */

export type ShowProps = {
  children: React.ReactNode | any | undefined;
  when?: boolean | number;
};

export const Show = ({ children, when }: ShowProps) => (when ? children : null);
