import { useState } from 'react';
import styled from 'styled-components/macro';

import {
  channelExpandAll,
  channelFullwindow,
  helpArticles,
  programCatalyzePattern,
} from 'config';

import {
  Button,
  Card,
  CardStyled,
  Col,
  Dropdown,
  IconChevronDown,
  IconExport,
  IconFullwindow,
  IconFullwindowExit,
  IconPrint,
  IconTableExpand,
  Link,
  Row,
  Show,
  theme,
  useBroadcast,
} from '@perts/ui';
import { toArchiveNetworkReportIdExport, useParams } from 'pages';
import { Metric, Program } from 'models';
import { useTerms } from 'components/TermsContext';

const ArticleOrBookmark = ({ children, articleId, bookmark }) => {
  const fw = (window as any).FreshworksWidget;
  return articleId ? (
    <span onClick={() => fw('open', 'article', { id: articleId })}>
      {children}
    </span>
  ) : bookmark ? (
    <a href={bookmark}>{children}</a>
  ) : (
    children
  );
};

const ItemLinkStyled = styled.div`
  button {
    padding-left: 30px;
  }

  svg {
    position: relative;
    top: 2px;
    margin-right: 5px;
  }
`;

type MenuItemProps = {
  articleId?: string;
  bookmark?: string;
  link?: string;
  text: string;
};

const MenuItem = ({ articleId, bookmark, link, text }: MenuItemProps) => (
  <ItemLinkStyled>
    <ArticleOrBookmark articleId={articleId} bookmark={bookmark}>
      <Dropdown.Item>
        {link ? <Link to={link}>{text}</Link> : text}
      </Dropdown.Item>
    </ArticleOrBookmark>
  </ItemLinkStyled>
);

const ReportNavigationStyled = styled.div`
  // <Topbar-like styles>
  // Makes it look like an extension of Topbar by (1) sticky/floating behavior
  // and (2) override the padding that normally appears around AppContent.
  position: sticky;
  z-index: 100;
  display: flex;
  flex-direction: column;

  top: -${theme.units.bodyPadding};
  margin-top: -${theme.units.bodyPadding};
  margin-right: -${theme.units.bodyPadding};
  margin-left: -${theme.units.bodyPadding};

  @media (max-width: ${theme.units.gridBreakpointMedium}px),
    (max-height: ${theme.units.gridBreakpointMedium}px) {
    top: -${theme.units.bodyPaddingMd};
    margin-top: -${theme.units.bodyPaddingMd};
    margin-right: -${theme.units.bodyPaddingMd};
    margin-left: -${theme.units.bodyPaddingMd};
  }

  @media (max-width: ${theme.units.gridBreakpointSmall}px),
    (max-height: ${theme.units.gridBreakpointSmall}px) {
    top: -${theme.units.bodyPaddingSm};
    margin-top: -${theme.units.bodyPaddingSm};
    margin-right: -${theme.units.bodyPaddingSm};
    margin-left: -${theme.units.bodyPaddingSm};
  }
  // </Topbar-like styles>

  ${CardStyled} {
    overflow: initial; // Important to allow dropdown menus.
  }

  @media print {
    // Don't need navigation when printing.
    display: none;
  }
`;

const IconButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  & > button {
    margin-right: ${theme.units.bodyPaddingSm};
    min-width: 40px;
    white-space: nowrap; // don't wrap button text
  }
`;

type ReportNavigationProps = {
  program: Program;
  metrics: Metric[];
  showExport?: boolean;
};

export const ReportNavigation = ({
  metrics,
  program,
  showExport = false,
}: ReportNavigationProps) => {
  const { networkId, reportId } = useParams();
  const terms = useTerms();

  const [isExpanded, setExpanded] = useState(false);
  const [isFullwindow, setFullwindow] = useState(false);
  const { broadcast: broadcastExapnded } = useBroadcast(channelExpandAll);
  const { broadcast: broadcastFullwindow } = useBroadcast(channelFullwindow);

  const toExport = networkId
    ? toArchiveNetworkReportIdExport(networkId, reportId)
    : '';

  const resultsMenu = [
    {
      bookmark: '#student-experience-overview',
      text: `${terms.participant} Experience Overview`,
    },
    { bookmark: '#equity-overview', text: 'Equity Overview' },
  ];
  for (const metric of metrics) {
    resultsMenu.push({
      bookmark: `#${metric.label}-data`,
      text: metric.name,
    });
  }

  const participationMenu = [
    { bookmark: '#survey-response-rates', text: 'Survey Response Rates' },
    { bookmark: '#survey-fidelity', text: 'Survey Fidelity' },
  ];

  const programName = program.name.toLocaleLowerCase();

  const howToUse = {
    articleId: helpArticles.howToUseReport,
    text: 'How to Use This Report',
  };
  const recommended = {
    link: helpArticles.recommendedPratices[programName],
    text: 'Recommended Practices',
  };
  const communityResources = {
    articleId: helpArticles.communityOfPracticeResources[programName],
    text: `${terms.group} of Practice Resources`,
  };
  const sharing = {
    articleId: helpArticles.sharingYourReport,
    text: 'Sharing Your Report',
  };
  const resourcesMenu = programCatalyzePattern.test(program.label)
    ? [howToUse, recommended, sharing]
    : [howToUse, recommended, communityResources, sharing];

  // May need to be adjusted if the content of the menu changes.
  // Important for allowing the menu to scroll if the viewport has low height.
  const dropdownHeightProps = {
    dropdownHeight: '65vh',
    dropdownMaxHeight: '510px',
  };

  return (
    <ReportNavigationStyled role="navigation">
      <Card>
        <Card.Content>
          <Row>
            <Col>
              <IconButtonWrapper>
                {/* Maximize / Fullwindow */}
                <Button
                  color="secondary"
                  onClick={() => {
                    setFullwindow(!isFullwindow);
                    broadcastFullwindow(!isFullwindow);
                  }}
                  aria-label="maximize"
                >
                  {isFullwindow ? <IconFullwindowExit /> : <IconFullwindow />}
                </Button>
                {/* Print */}
                <Button
                  onClick={() => window.print()}
                  aria-label="print"
                  color="secondary"
                >
                  <IconPrint />
                </Button>
                {/* Toggle Details*/}
                <Button
                  onClick={() => {
                    setExpanded(!isExpanded);
                    broadcastExapnded(!isExpanded);
                  }}
                  color="secondary"
                  iconLeft={<IconTableExpand />}
                >
                  {isExpanded ? <>Hide All Details</> : <>Show All Details</>}
                </Button>
                {/* Export CSV */}
                <Show when={Boolean(showExport && toExport)}>
                  <Button
                    to={toExport}
                    iconLeft={<IconExport />}
                    color="secondary"
                  >
                    Export
                  </Button>
                </Show>
              </IconButtonWrapper>
            </Col>
            <Col hAlign="right" cols={4} colsSm={12}>
              <Dropdown
                menuButton={
                  <>
                    <span>Contents</span> <IconChevronDown />
                  </>
                }
                color="secondary"
                fullWidth
                openDirection="right"
                {...dropdownHeightProps}
              >
                <Dropdown.Section>Results</Dropdown.Section>
                {resultsMenu.map((config, i) => (
                  <MenuItem key={i} {...config} />
                ))}
                <Dropdown.Section>Participation</Dropdown.Section>
                {participationMenu.map((config, i) => (
                  <MenuItem key={i} {...config} />
                ))}
                <Dropdown.Section>Resources</Dropdown.Section>
                {resourcesMenu.map((config, i) => (
                  <MenuItem key={i} {...config} />
                ))}
              </Dropdown>
            </Col>
          </Row>
        </Card.Content>
      </Card>
    </ReportNavigationStyled>
  );
};
