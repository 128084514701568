import { QueryKey, useQueryClient, useMutation } from 'react-query';
import { EditInPlaceFormRender } from './EditInPlaceFormRender';

export type EditInPlaceFormProps<EntityType> = {
  // The name of the EditInPlace input. This is the property of the entity
  // object that is being edited by this form. For example, if the
  // EditInPlaceForm is updating a User's `email` field, then provide "email"
  // for the `name`.
  name: string;

  // The label of the EditInPlace input.
  label: string;

  // The value of the EditInPlace input. The entire entity object. For example,
  // if this EditInPlaceForm is updating a User, pass in a `user` object as the
  // value for `value`.
  value: any;

  // A message that is displayed when the value of value[name] is == false.
  emptyValueMessage?: string;

  // react-query queryKey. Must refer to an array of data.
  queryKey: QueryKey;

  // Function called on the updated value. This is the entire entity object with
  // the new, user submitted, value for value[name].
  queryUpdateFn: (newValue: EntityType) => Promise<any>;

  // react-query invalidate function. Called onSettled to force a refetch.
  queryInvalidateFn: () => Promise<void>;

  // Comparator function used to determine which entity in the react-query cache
  // to replace with the newly updated element. Returns true if they match.
  uniqComparator: (previous: any, current: any) => boolean;

  // Function called on successful toggle.
  successFn?: () => void;

  // Function called on failed toggle.
  errorFn?: (err?: Error) => void;

  // The minimum character length allowed to value
  minLength?: number;

  // The maximum character length allowed to value
  maxLength?: number;
};

export function EditInPlaceForm<EntityType>({
  name,
  label,
  value,
  emptyValueMessage,
  queryKey,
  queryUpdateFn,
  queryInvalidateFn,
  uniqComparator,
  successFn = () => undefined,
  errorFn = () => undefined,
  minLength = 1,
  maxLength = 200,
  ...rest
}: EditInPlaceFormProps<EntityType>) {
  const queryClient = useQueryClient();

  const mutation = useMutation(
    async (valueFromForm: EntityType) => {
      await queryUpdateFn(valueFromForm);
    },
    {
      onMutate: async (valueFromForm: EntityType) => {
        // Cancel any outgoing refetches.
        //   (So they don't overwrite our optimistic update.)
        await queryClient.cancelQueries(queryKey);

        // Snapshot the previous value.
        const previous = queryClient.getQueryData<EntityType[]>(queryKey);

        // Optimistically update to the new value.
        if (!previous) {
          // No previous data? Then set cache to new value.
          queryClient.setQueryData(queryKey, [valueFromForm]);
        }

        if (previous) {
          // Merge/replace the newly updated element into the query cache.
          const dataWithUpdatedValue = previous.map((prev) => {
            if (uniqComparator(prev, valueFromForm)) {
              return valueFromForm;
            }

            return prev;
          });

          queryClient.setQueryData(queryKey, dataWithUpdatedValue);
        }

        // Return a context object with the snapshot value.
        return { previous };
      },

      // Handle successful mutation.
      onSuccess: (_data, _updatedValue) => {
        successFn();
      },

      // If the mutation fails, use the context returned to roll back.
      onError: (err: Error, _values, context) => {
        if (context?.previous) {
          queryClient.setQueryData<EntityType[]>(queryKey, context.previous);
        }

        errorFn(err);
      },

      // Always refetch after an error or success.
      onSettled: () => {
        queryInvalidateFn();
      },
    },
  );

  // https://stackoverflow.com/questions/65760158/react-query-mutation-typescript
  // Formik onSubmit handler
  const onSubmit = async (values: EntityType) => {
    await mutation.mutateAsync(values);
  };

  return (
    <EditInPlaceFormRender
      onSubmit={onSubmit}
      name={name}
      label={label}
      value={value}
      emptyValueMessage={emptyValueMessage}
      minLength={minLength}
      maxLength={maxLength}
      {...rest}
    />
  );
}
