import { Col, Input, Row, Switch, TextField } from '@perts/ui';
import { useTerms } from 'components';

const switchLabel = (value, terms) => {
  const checked = Boolean(value);
  const indeterminate = value == null;

  const mixedSwitchLabel = (
    <span>
      <strong>{terms.classes} have mixed settings.</strong>
    </span>
  );
  const enabledSwitchLabel = (
    <span>
      <strong>Enabled</strong>
    </span>
  );
  const disabledSwitchLabel = (
    <span>
      <strong>Disabled</strong>
    </span>
  );

  const label = indeterminate
    ? mixedSwitchLabel
    : checked
    ? enabledSwitchLabel
    : disabledSwitchLabel;

  return label;
};

export const RosterSignOnTargetGroupFields = ({
  isSubmitting,
  setFieldTouched,
  setValues,
  values,
}) => {
  const terms = useTerms();

  const onChange = (e) => {
    const updatedValues = {
      ...values,
      // Update switch value.
      targetGroupEnabled: e.target.checked,
      // Clear input field when switched off.
      ...(!e.target.checked ? { targetGroupName: '' } : {}),
    };

    // Display the "required" warning.
    if (updatedValues.targetGroupEnabled) {
      setFieldTouched('target_group_name');
    }

    setValues(updatedValues);
  };

  return (
    <>
      <Row>
        <Col>
          <Input
            component={Switch}
            id="focalGroupEnabled"
            name="targetGroupEnabled"
            label={switchLabel(values.targetGroupEnabled, terms)}
            labelPlacement="end"
            disabled={isSubmitting}
            checked={values.targetGroupEnabled}
            indeterminate={values.targetGroupEnabled === null}
            onChange={onChange}
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <Input
            id="input-focal-group-name"
            name="targetGroupName"
            label="Focal Group Name"
            labelPlacement="top"
            component={TextField}
            fullWidth
            disabled={isSubmitting || !values.targetGroupEnabled}
          />
        </Col>
      </Row>
    </>
  );
};
