import { Col, Row } from '@perts/ui';

const MagicLinkSent = ({ email }) => (
  <div data-test="MagicLinkSent">
    <Row>
      <Col>
        <h2>Magic Link Sent!</h2>
        <p>
          We just sent an email to you at <strong>{email}</strong>. It contains
          a link that&rsquo;ll sign you right in!
        </p>
      </Col>
    </Row>
  </div>
);

export default MagicLinkSent;
