import useToggles from 'utils/useToggles';
import { ArchivedWarning } from 'components/ArchivedWarning';
import { EmptyList } from 'components/EmptyList';
import { SurveysActionbar } from './SurveysActionbar';
import { SurveysGuideBox } from './SurveysGuideBox';
import { SurveyClassCard } from './SurveyClassCard';
import {
  Actionbar,
  Button,
  Col,
  FixedActionbarContainer,
  IconChevronRight,
  Row,
  Show,
  Display,
} from '@perts/ui';

import {
  Class,
  Group,
  Metric,
  Program,
  selectClassMetricLabels,
  selectClassMetrics,
  transformClassToSurvey,
} from 'models';
import {
  toGroupSurveysInstructions,
  toGroupSurveysDefaultSettings,
} from 'pages';

import { useClientPagination } from 'utils/useClientPagination';
import useFilterField from 'utils/useFilterField';
import { useTerms } from 'components/TermsContext';

type SurveysRenderProps = {
  classes: Class[];
  group: Group;
  mayEdit: boolean;
  mayEditDefaultSettings: boolean;
  metrics: Metric[];
  program: Program;
  SortByComponent?: React.FC<any>;
};

export const SurveysRender = ({
  classes,
  group,
  mayEdit,
  mayEditDefaultSettings,
  metrics,
  program,
  SortByComponent = () => null,
}: SurveysRenderProps) => {
  const terms = useTerms();

  const { filteredItems, FilterField } = useFilterField(classes, ['name'], {
    label: `Filter by ${terms.class.toLowerCase()} name`,
  });
  const { currentPageItems, Pagination } = useClientPagination({
    data: filteredItems,
  });

  const {
    ControlToggle,
    Toggle,
    checked,
    selected,
    someMarked,
    toggleAll,
    toggleAllFilter,
  } = useToggles(classes, 'uid', {
    pageItems: currentPageItems,
    filteredItems,
  });

  const isClasses = classes.length > 0;

  // TODO: Determine if schedules have been set for some Classes.
  const isSchedulesSet = true;

  return (
    <>
      <FixedActionbarContainer>
        <Show when={group.archived}>
          <ArchivedWarning />
        </Show>

        <Actionbar paddingLeft={0}>
          <Row alignItems="flex-end">
            {/* Filter UI */}
            <Col colsLg={12} orderLg={2}>
              <Row>
                <Col colsSm={12} cols={5}>
                  {FilterField}
                </Col>
                <Col shrink vAlign="flex-end">
                  <SortByComponent />
                </Col>
              </Row>
            </Col>

            {/* Survey Settings UI */}
            <Col shrink colsLg={12} orderLg={1}>
              <Row>
                <Col shrink colsLg={mayEditDefaultSettings ? 6 : 12}>
                  <Button
                    to={toGroupSurveysInstructions(group.short_uid)}
                    iconRight={
                      <Display whenMd whenLg whenXl display="contents">
                        <IconChevronRight />
                      </Display>
                    }
                    color="secondary"
                    fullWidth
                  >
                    Survey Instructions
                  </Button>
                </Col>

                <Show when={mayEditDefaultSettings}>
                  <Col shrink colsLg={6}>
                    <Button
                      to={toGroupSurveysDefaultSettings(group.uid)}
                      iconRight={
                        <Display whenMd whenLg whenXl display="contents">
                          <IconChevronRight />
                        </Display>
                      }
                      color="secondary"
                      fullWidth
                      fullHeight
                    >
                      Default Survey
                    </Button>
                  </Col>
                </Show>
              </Row>
            </Col>
          </Row>
        </Actionbar>

        <Show when={classes.length !== 0}>
          <SurveysActionbar
            ControlToggle={ControlToggle}
            checked={checked}
            selected={selected}
            someMarked={someMarked}
            mayEdit={mayEdit}
            Pagination={Pagination}
            classes={classes}
            toggleAll={toggleAll}
            toggleAllFilter={toggleAllFilter}
            filteredItems={filteredItems}
          />
        </Show>
      </FixedActionbarContainer>

      <Show when={classes.length === 0}>
        <EmptyList />
      </Show>

      <div data-testid="survey-list-container">
        {currentPageItems.map((cls) => {
          const conditions = selectClassMetrics(cls, metrics);
          const metric_labels = selectClassMetricLabels(cls, metrics);
          const survey = { ...transformClassToSurvey(cls), metric_labels };
          const toggle = mayEdit ? (
            <Toggle id={cls.uid} aria-label={`Selector ${cls.name}`} />
          ) : null;

          return (
            <SurveyClassCard
              key={cls.uid}
              cls={cls}
              conditions={conditions}
              group={group}
              program={program}
              survey={survey}
              toggle={toggle}
              checked={checked[cls.uid]}
              mayEdit={mayEdit}
            />
          );
        })}
      </div>

      <SurveysGuideBox isClasses={isClasses} isSchedulesSet={isSchedulesSet} />
    </>
  );
};
