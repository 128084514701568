import styled from 'styled-components/macro';
import { css } from 'styled-components';
import theme from '../theme';

export type InfoBoxProps = {
  // The display. Default 'flex'.
  display?:
    | 'block'
    | 'inline'
    | 'inline-block'
    | 'flex'
    | 'inline-flex'
    | 'grid'
    | 'inline-grid';

  // The align-items. Default 'center'.
  alignItems?:
    | 'center'
    | 'start'
    | 'end'
    | 'flex-start'
    | 'flex-end'
    | 'normal'
    | 'stretch';

  // The InfoBox content.
  children?: React.ReactNode;

  // The color of the InfoBox. Default 'primary'.
  color?: 'primary' | 'secondary' | 'info' | 'success' | 'error' | 'warning';

  // The flex-direction. Default 'column'.
  flexDirection?: 'column' | 'row';

  // The justify-content. Default 'center'.
  justifyContent?:
    | 'center'
    | 'start'
    | 'end'
    | 'flex-start'
    | 'flex-end'
    | 'left'
    | 'right'
    | 'normal'
    | 'space-between'
    | 'space-around'
    | 'space-evenly'
    | 'stretch';

  // The text-align. Default 'center'.
  textAlign?: 'center' | 'left' | 'right' | 'justify';
};

// -----------------------------------------------------------------------------
//   Style Components
// -----------------------------------------------------------------------------

const InfoBoxStyled = styled.div<InfoBoxProps>`
  display: ${(props) => props.display || 'flex'};

  flex-direction: ${(props) => props.flexDirection || 'column'};
  justify-content: ${(props) => props.justifyContent || 'center'};
  align-items: ${(props) => props.alignItems || 'center'};
  text-align: ${(props) => props.textAlign || 'center'};

  width: 100%;
  padding: 10px 20px;

  border: 1px dashed ${theme.colors.primary};
  border-radius: ${theme.units.borderRadius};

  ${(props) =>
    props.color === 'secondary' &&
    css`
      color: ${theme.colors.primary};
      background: ${theme.colors.white};
      border-color: ${theme.colors.primary};
    `};

  ${(props) =>
    props.color === 'info' &&
    css`
      color: ${theme.colors.text};
      background: ${theme.colors.white};
      border-color: ${theme.colors.grayMedium};
    `};

  ${(props) =>
    props.color === 'success' &&
    css`
      border-color: ${theme.colors.success};
      background: ${theme.colors.white};
    `};

  ${(props) =>
    props.color === 'error' &&
    css`
      border-color: ${theme.colors.warning};
      background: ${theme.colors.warning};
      color: ${theme.colors.white};
    `};

  ${(props) =>
    props.color === 'warning' &&
    css`
      border-color: ${theme.colors.warning};
      border-width: 3px;
    `};
`;

export const InfoBox = ({ children, color, ...rest }: InfoBoxProps) => (
  <InfoBoxStyled
    color={color}
    role={color === 'error' ? 'alert' : 'status'}
    {...rest}
  >
    {children}
  </InfoBoxStyled>
);

InfoBox.displayName = 'InfoBox';
