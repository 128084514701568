// Used to define dynamic axis step sizes on charts.
// Step sizes jump to the next power of 10 after 20, 200, 2000, etc
export default function getAxisStepSize(range, minStepSize) {
  const power = Math.ceil(Math.log10(range / 2)) - 1;
  return Math.max(10 ** power, minStepSize);
}

// | range | tick step size |
// |-------|----------------|
// |     2 |              1 |
// |    10 |              1 |
// |    20 |              1 |
// |    21 |             10 |
// |   200 |             10 |
// |   201 |            100 |
