import { Class } from 'models';
import { createUnscheduledCyclesMap } from './createUnscheduledCyclesMap';
import { incrementUpTo } from './incrementUpTo';

export const getNextUnscheduledCycle = (
  currentCycleUid: string,
  classes: Class[],
  formValues: any,
) => {
  const unscheduledCyclesMap = createUnscheduledCyclesMap(classes, formValues);

  const currentIndex = unscheduledCyclesMap.findIndex(
    (c) => c.uid === currentCycleUid,
  );
  const maxIndex = unscheduledCyclesMap.length - 1;
  const nextIndex = incrementUpTo(currentIndex, maxIndex, 0);

  const nextCycle = unscheduledCyclesMap[nextIndex];

  if (!nextCycle) {
    return { uid: null, page: null };
  }

  return {
    uid: nextCycle.uid,
    ordinal: nextCycle.ordinal,
    classUid: nextCycle.classUid,
    classPageIndex: nextCycle.classPageIndex,
    page: nextCycle.page,
  };
};
