import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import styled from 'styled-components/macro';

import { User } from 'models';
import {
  Button,
  Card,
  Col,
  Input,
  Row,
  Switch,
  TextField,
  useFormError,
} from '@perts/ui';

export type UserAccountFormValues = {
  email: string;
  name: string;
  receive_email: boolean;
};

export type UserAccountFormProps = {
  user: User;
  onSubmit: (values: UserAccountFormValues) => void;
};

const LabelContainer = styled.label`
  display: flex;
  align-items: center;
`;

const UserAccountFormSchema = Yup.object().shape({
  email: Yup.string(),
  name: Yup.string()
    .required('Name is required')
    .max(200, 'Name should be 200 characters or less.'),
  receive_email: Yup.boolean(),
});

export const UserAccountForm = ({ onSubmit, user }: UserAccountFormProps) => {
  const [FormError, showFormError] = useFormError();

  const initialValues = {
    email: user.email,
    name: user.name || '',
    receive_email: user.receive_email,
  };

  return (
    <Card>
      <Card.Title>
        <Row>
          <Col hAlign="center" vAlign="center">
            <h2>User Account</h2>
          </Col>
        </Row>
      </Card.Title>

      <Card.Content data-testid="container-report-cards">
        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          validationSchema={UserAccountFormSchema}
          onSubmit={async (values) => {
            try {
              // Clear existing form error.
              showFormError(false);

              // Perform form onSubmit.
              await onSubmit(values);
            } catch (error) {
              // Display form error.
              showFormError(true);
            }
          }}
        >
          {({ isSubmitting, isValid, dirty, values, setFieldValue }) => (
            <Form>
              <Row>
                <Col cols={12}>
                  <Input
                    id="email"
                    name="email"
                    label="Email"
                    labelPlacement="top"
                    component={TextField}
                    readOnly
                  />
                </Col>
                <Col cols={12}>
                  <Input
                    id="name"
                    name="name"
                    label="Name"
                    labelPlacement="top"
                    component={TextField}
                    disabled={isSubmitting}
                  />
                </Col>
                <Col cols={12} vAlign="center" hAlign="space-between">
                  <LabelContainer htmlFor="receive-email-switch">
                    Receive email notifications
                  </LabelContainer>
                  <Input
                    id="receive-email-switch"
                    name="receive_email"
                    label=""
                    labelPlacement="end"
                    aria-label="receive-email-switch"
                    component={Switch}
                    checked={values.receive_email}
                    onChange={() => {
                      setFieldValue('receive_email', !values.receive_email);
                    }}
                    disabled={isSubmitting}
                  />
                </Col>
              </Row>

              <Row>
                <Col>
                  <FormError />
                </Col>
              </Row>

              <Row>
                <Col>
                  <Button
                    type="submit"
                    fullWidth
                    disabled={!isValid || isSubmitting || !dirty}
                    loading={isSubmitting}
                    data-testid="submit-btn"
                  >
                    Save Changes
                  </Button>
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      </Card.Content>
    </Card>
  );
};
