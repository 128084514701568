import React from 'react';
import styled from 'styled-components/macro';

import { theme } from '@perts/ui';

const NavContainer = styled.div`
  text-align: center;
  font-style: normal;
`;

const SelectStyled = styled.span`
  padding: 0 ${theme.units.bodyPaddingSm};
`;

type Props = {
  pageSize: number;
  pageSizeOptions?: number[];
  setPageSize: (pageSize: number) => void;
  totalRows: number;
};

export const DataTablePageSize = ({
  pageSize,
  pageSizeOptions = [10, 25, 50],
  setPageSize,
  totalRows,
}: Props) => {
  // Only keep options strictly less than total. Then push the total to
  // guarantee we have an "all rows" options.
  const options = pageSizeOptions.filter((o) => o < totalRows);

  options.push(totalRows);

  return (
    <NavContainer>
      Show
      <SelectStyled>
        <select
          value={pageSize}
          onChange={(e) => {
            setPageSize(Number(e.target.value));
          }}
        >
          {options.map((p) => (
            <option key={p} value={p}>
              {p}
            </option>
          ))}
        </select>
      </SelectStyled>
      of {totalRows}
    </NavContainer>
  );
};
