import { Redirect } from 'react-router-dom';
import { useParams, toNetworkMembers } from 'pages';
import { getShortUid } from '@perts/util';

export const NetworksRedirect = () => {
  const { networkId } = useParams();

  return (
    <Redirect to={toNetworkMembers(getShortUid(networkId))} push={false} />
  );
};
