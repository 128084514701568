import { useState } from 'react';
import styled from 'styled-components';

import { Button, Card, CardStyled, Col, Row, theme } from '../../';

const ExpandButtonStyled = styled(Button)`
  text-transform: uppercase;
  font-size: 12px;
  padding: 0 10px;
  min-height: 10px;
`;

const ExpandButtonRowStyled = styled.div`
  ${Row} {
    position: absolute;
    z-index: 1;
    width: 100%;
    top: -10px;

    @media print {
      display: none;
    }
  }
`;

type CardExpanderStyledProps = {
  isExpanded: boolean;
};

const CardExpanderStyled = styled.div<CardExpanderStyledProps>`
  position: relative;

  ${CardStyled} {
    // Slightly narrower and centered.
    width: calc(100% - 20px);
    left: 10px;

    // No curve at the upper left and upper right corners.
    border-radius: 0 0 ${theme.units.borderRadius} ${theme.units.borderRadius};

    // Shift up to eliminate normal margin between cards, to indicate it's not
    // an independent container.
    margin-top: -${theme.units.paddingLg};

    /* Padding for narrower/mobile screens. */
    @media only screen and (max-width: ${theme.units.gridBreakpointMedium}px),
      only screen and (max-height: ${theme.units.gridBreakpointMedium}px) {
      margin-top: -${theme.units.paddingMd};
    }
    @media only screen and (max-width: ${theme.units.gridBreakpointSmall}px),
      only screen and (max-height: ${theme.units.gridBreakpointSmall}px) {
      margin-top: -${theme.units.paddingSm};
    }

    // Give the content more padding to make up for the upward shift.
    padding-top: calc(${theme.units.paddingLg} + 5px);
    /* Padding for narrower/mobile screens. */
    @media only screen and (max-width: ${theme.units.gridBreakpointMedium}px),
      only screen and (max-height: ${theme.units.gridBreakpointMedium}px) {
      padding-top: calc(${theme.units.paddingMd} + 5px);
    }
    @media only screen and (max-width: ${theme.units.gridBreakpointSmall}px),
      only screen and (max-height: ${theme.units.gridBreakpointSmall}px) {
      padding-top: calc(${theme.units.paddingSm} + 5px);
    }

    button {
      z-index: 1;
    }

    @media print {
      display: ${({ isExpanded }) => (isExpanded ? 'inherit' : 'none')};
    }
  }
`;

type Props = {
  children: React.ReactNode;
  startExpanded?: boolean;
};

/**
 * Used along with regular cards to provide togglable extra detail below.
 *
 * Example:
 *
 * <Card>
 *   <Card.Content>Some content here.</Card.Content>
 * </Card>
 * <CardExpander>
 *   <Card.Content>Extra content here, hidden by default.</Card.Content>
 * </CardExpander>
 */
export const CardExpander = ({ children, startExpanded = false }: Props) => {
  const [isExpanded, setExpanded] = useState(startExpanded);

  return (
    <CardExpanderStyled isExpanded={isExpanded}>
      <ExpandButtonRowStyled>
        <Row>
          <Col hAlign="center">
            <ExpandButtonStyled
              color="text"
              onClick={() => setExpanded(!isExpanded)}
            >
              {isExpanded ? <>Hide Details</> : <>Show Details</>}
            </ExpandButtonStyled>
          </Col>
        </Row>
      </ExpandButtonRowStyled>
      <Card>
        {isExpanded ? (
          children
        ) : (
          <Card.Content>
            <></>
          </Card.Content>
        )}
      </Card>
    </CardExpanderStyled>
  );
};
