import {
  CategoryScale,
  Chart as ChartJS,
  ChartType,
  Legend,
  LineController,
  LineElement,
  LinearScale,
  PointElement,
  Tooltip,
} from 'chart.js';
import { Chart as ReactChart } from 'react-chartjs-2';
import styled from 'styled-components/macro';

import { theme as defaultTheme } from '@perts/ui';

import getAxisStepSize from 'utils/getAxisStepSize';
import { getPercentAxisLimits } from 'utils/getPercentAxisLimits';
import type { ExperienceChildData } from './types';

ChartJS.register(
  CategoryScale,
  Legend,
  LineController,
  LineElement,
  LinearScale,
  PointElement,
  Tooltip,
);

const ChartContainer = styled.div`
  position: relative;
  height: 250px;
  width: 100%;

  @media print {
    canvas {
      width: 100% !important;
    }
  }
`;

// See https://www.chartjs.org/docs/latest/samples/legend/html.html
export type HtmlLegendPlugin = {
  id: string;
  afterUpdate: (chart: any, args: any, options: any) => void;
};

type Props = {
  ariaLabel?: string;
  experienceByChild: (ExperienceChildData & {
    // `true` if the data should be hidden by default
    hidden?: boolean;
  })[];
  theme?: any;
  handleChartRef?: (ref: any) => void;
};

// https://www.chartjs.org/docs/latest/configuration/legend.html#align
type Alignment = 'start' | 'end' | 'center' | undefined;

export const Chart = ({
  ariaLabel,
  experienceByChild,
  theme,
  handleChartRef,
}: Props) => {
  theme = theme || defaultTheme;

  // If the lengths of the data series differ, fill in nulls to match them all
  // to the longest one, otherwise ChartJS will truncate to the shortest.
  const maxSeriesLength = Math.max(
    ...experienceByChild.map((data) => data.ratedPositive.length),
  );

  const experiencePadded = experienceByChild.map((data) => {
    const fillLen = maxSeriesLength - data.ratedPositive.length;
    const filler = Array<null>(fillLen).fill(null);

    return {
      ...data,
      ratedPositive: [...data.ratedPositive, ...filler],
    };
  });

  const allPoints = experiencePadded
    .map((row) => row.ratedPositive)
    .flat()
    .filter((x: number | null): x is number => x !== null);
  const rangeMax = Math.max(...allPoints);
  const rangeMin = Math.min(...allPoints);

  const { max: yMax, min: yMin } = getPercentAxisLimits(rangeMin, rangeMax);

  const primaryLineStyles = {
    borderDash: [], // no dashing
    borderWidth: 3,
  };
  const secondaryLineStyles = {
    // https://developer.mozilla.org/en-US/docs/Web/API/CanvasRenderingContext2D/setLineDash
    borderDash: [10, 5],
    borderWidth: 1,
  };

  const config = {
    type: 'line' as ChartType,
    data: {
      // Survey axis tick labels: ['1', '2', ...]
      labels: experiencePadded[0].ratedPositive.map((_, i) => String(i + 1)),
      datasets: experiencePadded.map((row, i) => ({
        label: row.childName,
        childName: row.childName,
        childLabel: row.childLabel,
        pointBackgroundColor: theme.colorsLineGraph[i],
        borderColor: theme.colorsLineGraph[i],
        data: row.ratedPositive,
        hidden: row.hidden,
        ...(row.styleType === 'secondary'
          ? secondaryLineStyles
          : primaryLineStyles),
      })),
    },
    options: {
      animation: false as any,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          align: 'start' as Alignment,
          // Suppress stock legend (inside canvas) if custom one supplied.
          display: !handleChartRef,
          labels: {
            generateLabels: () =>
              experiencePadded
                .filter((row) => row.displayInLegend !== false)
                .map((row, i) => ({
                  display: false,
                  text: row.childName || '',
                  strokeStyle: theme.colorsLineGraph[i],
                  fillStyle: theme.colorsLineGraph[i],
                })),
          },
          onClick: () => null,
        },
      },
      responsive: true,
      scales: {
        y: {
          max: yMax,
          min: yMin,
          ticks: {
            format: {
              style: 'percent',
              maximumSignificantDigits: 2,
            },
            // scale ticks will be enumerated by multiple of stepSize
            // https://www.chartjs.org/docs/latest/axes/cartesian/linear.html#step-size
            stepSize: getAxisStepSize(rangeMax - rangeMin, 0.05),
          },
          title: {
            text: '% Rated Positively',
            display: true,
          },
        },
        x: {
          // Don't start the first x value, Survey 1, at the extreme left edge.
          // Put offset/padding in.
          offset: true,
          // No vertical gridlines.
          grid: {
            display: false,
          },
          title: {
            text: 'Survey',
            display: true,
          },
        },
      },
    },
  };

  return (
    <ChartContainer>
      <ReactChart
        aria-label={ariaLabel || 'line graph'}
        {...config}
        ref={handleChartRef}
      />
    </ChartContainer>
  );
};
