import { Formik, Form } from 'formik';
import { uniqueId } from 'lodash';

import { Input, TextField } from '@perts/ui';

type Props = {
  by: string;
  setFilter: (value: string) => void;
};

const filterKey = '__filterText__';

export const TableGlobalFilter = ({ by, setFilter }: Props) => {
  const initialValues = { [filterKey]: '' };
  const onSubmit = (values) => values;
  const fieldId = uniqueId('TableFilter-');

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit}>
      {({ setFieldValue, values }) => (
        <Form>
          <Input
            component={TextField}
            displayError={false}
            id={fieldId}
            name={fieldId}
            label={`Filter by ${by}`}
            labelPlacement="start"
            fullWidth={true}
            maxLength={200}
            value={values[filterKey] || ''}
            onChange={(e) => {
              setFieldValue(filterKey, e.target.value || undefined);
              setFilter(e.target.value || undefined);
            }}
          />
        </Form>
      )}
    </Formik>
  );
};
