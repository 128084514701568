import { QueryClient } from 'react-query';
import { union } from 'lodash';
import { createUid } from '@perts/util';
import {
  Network,
  Group,
  queryKeyGroupsByNetwork,
  queryKeyNetworks,
  queryKeyNetwork,
} from 'models';
import {
  get,
  query,
  queryByUser,
  queryPagination,
  update,
  post,
  remove,
} from 'services/triton/networks';

// -----------------------------------------------------------------------------
//   API Functions
// -----------------------------------------------------------------------------

// Get the Network with `networkId`.

type NetworkGetById = (networkId: string) => Promise<Network>;

export const networkGetById: NetworkGetById = get;
export const networksQuery = query;
export const networksQueryByUser = queryByUser;
export const networksQueryPagination = queryPagination;

// Remove Groups from Network.
type GroupsRemoveFromNetwork = (
  groups: Group[],
  network: Network,
) => Promise<Network>;

export const groupsRemoveFromNetwork: GroupsRemoveFromNetwork = (
  groups,
  network,
) => {
  const groupIdsToRemove = groups.map((group) => group.uid);

  // Removed the group IDs from the network
  const updatedNetwork = {
    ...network,
    association_ids: network.association_ids.filter(
      (groupId) => !groupIdsToRemove.includes(groupId),
    ),
  };

  return update(updatedNetwork);
};

// Add Groups to Network.
type GroupsAddToNetwork = (
  groupIDs: string[],
  network: Network,
) => Promise<Network>;

export const groupsAddToNetwork: GroupsAddToNetwork = (
  groupIDsToAdd,
  network,
) => {
  const longGroupIDsToAdd = groupIDsToAdd.map((id) =>
    createUid('Organization', id),
  );
  const updatedGroupIDs = union(longGroupIDsToAdd, network.association_ids);

  // Adding group IDs to the network
  const updatedNetwork = {
    ...network,
    association_ids: updatedGroupIDs,
  };

  return update(updatedNetwork);
};

export const invalidateGroupsAddToNetwork = (
  queryClient: QueryClient,
  networkId: string,
) => queryClient.invalidateQueries(queryKeyGroupsByNetwork(networkId));

// Add network to Program.
type NetworkAddToProgram = (
  name: string,
  program_id: string,
) => Promise<Network>;

export const networksAddToProgram: NetworkAddToProgram = (name, program_id) =>
  post({ name, program_id });

// Remove Network.

type NetworkRemove = (network: Network) => Promise<void>;
export const networkRemove: NetworkRemove = (network) => remove(network.uid);

export const invalidateNetworkRemove = (queryClient: QueryClient) =>
  queryClient.invalidateQueries(queryKeyNetworks());

// Update network
type UpdateNetwork = (network: Network) => Promise<Network>;

export const updateNetwork: UpdateNetwork = update;

export const invalidateUpdateNetwork = (
  queryClient: QueryClient,
  networkId: string,
) => queryClient.invalidateQueries(queryKeyNetwork(networkId));
