import { useFormikContext } from 'formik';
import { useGroupGetByParams } from 'models';
import { Button, Col, Input, Modal, Row, Select } from '@perts/ui';

const options = [
  { label: 'Not at all true', value: '1' },
  { label: 'A little true', value: '2' },
  { label: 'Somewhat true', value: '3' },
  { label: 'Mostly true', value: '4' },
  { label: 'Completely true', value: '5' },
  { label: 'Prefer not to answer', value: '0' },
];

export const initialValues = {
  discuss: '',
};

export const Component = () => {
  const { data: group } = useGroupGetByParams();
  const groupName = group?.name;

  const { isSubmitting, isValid, dirty } = useFormikContext();

  return (
    <>
      <Modal.Title className="center">
        Question about your Elevate experience
      </Modal.Title>

      <Row>
        <Col>
          <p>
            PERTS is doing research to ensure that all Elevate users receive
            adequate support for their work with Elevate. Please help us by
            answering this question. Your response is optional and will be kept
            confidential.
          </p>
          <p>This question is about {groupName}.</p>
          <p></p>
        </Col>
      </Row>

      <Row>
        <Col>
          <Input
            id="discuss"
            name="discuss"
            label={
              <>
                People regularly discuss challenges to learn from one another.
              </>
            }
            labelPlacement="top"
            options={options}
            component={Select}
            placeholder="Select your response"
            keyBy="value"
            keyName="label"
            disabled={isSubmitting}
            fullWidth
            displayErrors={false}
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <Button
            type="submit"
            fullWidth
            disabled={!isValid || isSubmitting || !dirty}
            loading={isSubmitting}
            data-testid="submit-btn"
          >
            Submit Response
          </Button>
        </Col>
      </Row>
    </>
  );
};
