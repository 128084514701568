import { Row, Show } from '@perts/ui';
import { Chart } from './Chart';
import type { ExperienceChildData } from './types';
import { Line } from 'react-chartjs-2';
import { useCustomLegend } from './useCustomLegend';

type Props = {
  ariaLabel?: string;
  experienceByChild: ExperienceChildData[];
  showLegend?: boolean;
  theme?: any;

  /**
   * `true` to display the custom legend. Note: Using the custom legend will
   * also initially limit the display to the top 3 changers (ratedPositive).
   */
  customLegend?: boolean;
};

export const ExperienceBySurveyChart = ({
  ariaLabel,
  experienceByChild,
  showLegend = true,
  customLegend = false,
  theme,
}: Props) => {
  let chartData = experienceByChild;

  if (customLegend) {
    chartData = experienceByChild
      .map((data) => {
        const amendedData = {
          ...data,
          ratedPositiveEnd: Infinity,
          totalChange: 0,
        };

        // Calculate the absolute total change of ratedPositive.
        const ratedPositiveStart: number = data.ratedPositive[0] || 0;
        const ratedPositiveEnd: number =
          data.ratedPositive[data.ratedPositive.length - 1] || 0;

        amendedData.ratedPositiveEnd = ratedPositiveEnd;
        amendedData.totalChange = Math.abs(
          ratedPositiveEnd - ratedPositiveStart,
        );

        return amendedData;
      })

      // Sort by totalChange desc, then ratedPositiveEnd asc, so that we display
      // the lower ratedPositive in the case of ties in change.
      .sort((d1, d2) => d1.ratedPositiveEnd - d2.ratedPositiveEnd)
      .sort((d1, d2) => d2.totalChange - d1.totalChange)

      // so we can mark the top 3 visible and the remainder hidden.
      .map((data, dataIndex) => ({
        ...data,
        hidden: dataIndex + 1 > 3,
      }))
      // Then sort back in alpha order for display.
      .sort((d1, d2) => (d1.childName || '').localeCompare(d2.childName || ''));
  }

  const { handleChartRef, CustomLegend } = useCustomLegend<typeof Line>();

  return (
    <Row noPageBreakInside>
      <Show when={showLegend && customLegend}>
        <CustomLegend />
      </Show>

      <Chart
        ariaLabel={ariaLabel || 'Rated positive by survey'}
        experienceByChild={chartData}
        theme={theme}
        handleChartRef={customLegend ? handleChartRef : undefined}
      />
    </Row>
  );
};
