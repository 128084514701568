import { Card, Text } from '@perts/ui';
import { getMessageFromErrors } from '@perts/util';
import { ErrorMessageBox, Loading, useTerms } from 'components';
import {
  Group,
  queryKeyGroup,
  selectUserIsAdmin,
  updateGroup,
  useCurrentUserQuery,
  useGroupGetByParams,
  useMutationUpdate,
  useParams,
} from 'models';
import {
  ReportSettingsGroupDisableForm,
  ReportSettingsGroupDisableFormValues,
} from './ReportSettingsGroupDisableForm';

export const ReportSettingsGroupDisable = () => {
  const { groupId } = useParams();
  const terms = useTerms();

  const groupQuery = useGroupGetByParams();
  const userQuery = useCurrentUserQuery();
  const queryKey = queryKeyGroup(groupId);

  const mutation = useMutationUpdate<Group, Group>(updateGroup, queryKey, {
    optimisticallyUpdate: false,
    toastSuccessMsg: `Successfully updated ${terms.group.toLowerCase()} reports visibility.`,
    toastErrorMsg: `There was a problem updating ${terms.group.toLowerCase()} reports visibility.`,
  });

  const userHasAccess = selectUserIsAdmin(userQuery.data);

  if (groupQuery.isLoading || userQuery.isLoading) {
    return <Loading />;
  }

  if (!groupQuery.isSuccess || !userQuery.isSuccess) {
    return (
      <ErrorMessageBox>
        {getMessageFromErrors([userQuery.error, groupQuery.error])}
      </ErrorMessageBox>
    );
  }

  if (!userHasAccess) {
    // Render something out for tests to query.
    return <span data-testid="UserNotAccess" />;
  }

  const initialValues = {
    report_hidden: Boolean(groupQuery.data.report_settings?.report_hidden),
  };

  const onSubmit = async (values: ReportSettingsGroupDisableFormValues) => {
    const group = groupQuery.data;

    const updatedReportSettings = {
      ...group.report_settings,
      report_hidden: values.report_hidden,
    };

    const updatedGroup = {
      ...group,
      report_settings: updatedReportSettings,
    };

    await mutation.mutateAsync(updatedGroup);
  };

  return (
    <Card>
      <Card.Title>
        <Text as="h2">Hide {terms.group} Reports</Text>
      </Card.Title>
      <Card.Content>
        <ReportSettingsGroupDisableForm
          initialValues={initialValues}
          onSubmit={onSubmit}
        />
      </Card.Content>
    </Card>
  );
};
