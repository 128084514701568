import { LOCAL_STORAGE_AUTH_TOKEN } from '../config';
import { getJwtFromAuthorization } from './getJwtFromAuthorization';

/**
 * Set locaStorage auth token to received auth token.
 * @param {Object} response fetch response object
 * @return {undefined}
 */
export const setJwtToken = (response) => {
  localStorage.setItem(
    LOCAL_STORAGE_AUTH_TOKEN,
    getJwtFromAuthorization(response.headers.get('authorization')),
  );
};
