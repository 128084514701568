import { Col, FixedActionbarContainer, Row } from '@perts/ui';
import { InputFilter } from './InputFilter';
import { InputSelectProgram } from './InputSelectProgram';
import { useFormikContext } from 'formik';
import { debounce } from 'lodash';
import { useCallback } from 'react';

export const ArchiveActionbar = ({ programs, isLoading }) => {
  const { submitForm } = useFormikContext();

  const debouncedSubmitForm = useCallback(() => {
    // The debounce should be memoized, or it will be recreated during each
    // render, making it ineffective.
    const fn = debounce(() => {
      submitForm();
    }, 500);

    fn();
  }, [submitForm]);

  return (
    <FixedActionbarContainer>
      <Row>
        <Col colsSm={12} cols={6}>
          <InputSelectProgram isLoading={isLoading} programs={programs} />
        </Col>
        <Col colsSm={12} cols={6} vAlign="flex-end">
          <InputFilter isLoading={isLoading} onChange={debouncedSubmitForm} />
        </Col>
      </Row>
    </FixedActionbarContainer>
  );
};
