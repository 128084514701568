import styled from 'styled-components/macro';
import { theme } from '../../';

export type TopbarProps = {
  children: React.ReactNode;
};

const TopbarStyled = styled.div`
  // The content of the top bar never wraps or changes in height, and we never
  // want it to scroll off the screen, so position: fixed.
  position: fixed;
  top: 0;

  margin: 0;
  padding: ${theme.units.paddingLg};

  background: ${theme.colors.white};
  color: ${theme.colors.text};

  height: ${theme.units.appBarHeight};

  // Width adjusts as the sidebar collapses/expands.
  width: calc(100% - ${theme.units.appSidebarWidth});
  @media (max-width: ${theme.units.menuCollapseWidth}px) {
    width: calc(100% - ${theme.units.appSidebarWidthCollapsed});
  }

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  > :first-child {
    margin-bottom: 0;
  }

  @media only screen and (max-width: ${theme.units.gridBreakpointSmall}px) {
    padding: ${theme.units.paddingSm};
  }
  z-index: 100;
  overflow: hidden;

  @media print {
    // Avoid position: fixed, which puts the topbar on every printed page.
    position: static;

    // Sidebar is hidden on print, so use the whole width.
    width: 100%;

    display: none;
  }
`;

export const Topbar = TopbarStyled;
