import { useEffect, useState, useCallback } from 'react';
import { Formik, Form } from 'formik';
import { isEmpty, keyBy, mapValues } from 'lodash';

import { helpArticles } from 'config';

import { Group, groupsQueryByIds } from 'models';
import { toGroup } from 'pages';
import { getLongUid } from '@perts/util';

import {
  Button,
  Col,
  Row,
  useFormError,
  HelpText,
  MultiSelectChip,
  Input,
  Card,
  Text,
} from '@perts/ui';
import { useTerms } from 'components/TermsContext';

const isValidGroupId = (groupId: string) => {
  const regex = /^(Organization_)?[A-Za-z0-9-]+$/;
  return regex.test(groupId);
};

const isInList = (currentValues: string[], valueToValidate: string) => {
  // The currentValues and valueToValidate are updated to long uid
  // to ensure correct validation
  const longCurrentValues = currentValues.map((item: string) =>
    getLongUid('Organization', item),
  );

  return longCurrentValues.includes(
    getLongUid('Organization', valueToValidate),
  );
};

export type ClassesEditParentGroupsFormValues = {
  parent_organizations: string[];
};

export type ClassesEditParentGroupsFormProps = {
  parent_organizations: string[];
  onSubmit: (values: ClassesEditParentGroupsFormValues) => void;
  submitButtonText?: string;
};

export const ClassesEditParentGroupsForm = ({
  submitButtonText,
  parent_organizations = [],
  onSubmit,
}: ClassesEditParentGroupsFormProps) => {
  const [FormError, showFormError] = useFormError();
  const [isValidating, setIsValidating] = useState(false);
  const [currentGroups, setCurrentGroups] = useState<Group[]>([]);
  const terms = useTerms();

  const getInitialCurrentGroups = useCallback(async () => {
    const groups = await groupsQueryByIds(parent_organizations);
    setCurrentGroups(groups);
  }, [parent_organizations]);

  useEffect(() => {
    getInitialCurrentGroups();
  }, [getInitialCurrentGroups]);

  const existGroupId = async (groupId: string) => {
    setIsValidating(true);
    const groups = await groupsQueryByIds([groupId]);

    if (isEmpty(groups)) {
      setIsValidating(false);
      return false;
    }

    setCurrentGroups((currentArray: Group[]) => [...currentArray, ...groups]);
    setIsValidating(false);
    return true;
  };

  const initialValues = {
    parent_organizations,
    currentValue: '',
  };

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      onSubmit={async (values) => {
        try {
          // Clear existing form error.
          showFormError(false);

          const formattedValues = values.parent_organizations.map((item) =>
            getLongUid('Organization', item),
          );

          // Perform form onSubmit.
          await onSubmit({
            parent_organizations: formattedValues,
          });
        } catch (error) {
          // Display form error.
          showFormError(true);
        }
      }}
    >
      {({ isSubmitting, isValid, dirty, values, errors }) => {
        // GroupId validation for MultiSelectChip's `asyncCustomValidation`.
        const multiSelectChipValidation = async (groupId: string) => {
          if (isInList(values.parent_organizations, groupId)) {
            return `${groupId} has already been added.`;
          }

          if (!isValidGroupId(groupId)) {
            return `Group ID "${groupId}" is not valid.`;
          }

          const existGroup = await existGroupId(groupId);

          if (!existGroup) {
            return `Group ID "${groupId}" doesn't exist.`;
          }

          return undefined;
        };

        const valueLabels = {
          ...mapValues(keyBy(currentGroups, 'uid'), 'name'),
          ...mapValues(keyBy(currentGroups, 'short_uid'), 'name'),
        };

        const valueLinks = {
          ...mapValues(keyBy(currentGroups, 'uid'), (item) =>
            toGroup(item.short_uid),
          ),
          ...mapValues(keyBy(currentGroups, 'short_uid'), (item) =>
            toGroup(item.short_uid),
          ),
        };

        return (
          <Form>
            <Card>
              <Card.Title>
                <Row alignItems="center">
                  <Col shrink vAlign="center">
                    <Text as="h2">Parent {terms.groups}</Text>
                  </Col>

                  <Col vAlign="center" hAlign="flex-end">
                    <HelpText articleId={helpArticles.whatAreParentCommunities}>
                      What are parent {terms.groups.toLowerCase()}?
                    </HelpText>
                  </Col>
                </Row>
              </Card.Title>

              <Card.Content>
                <Row>
                  <Col>
                    <Input
                      id="parent_organizations"
                      name="parent_organizations"
                      label={`Add parent ${terms.group} IDs`}
                      placeholder={`${terms.group} IDs`}
                      labelPlacement="top"
                      asyncCustomValidation={multiSelectChipValidation}
                      component={MultiSelectChip}
                      disabled={isSubmitting || isValidating}
                      error={errors.parent_organizations}
                      valueLabels={valueLabels}
                      valueLinks={valueLinks}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <FormError />
                  </Col>
                </Row>

                <Row>
                  <Col cols={12}>
                    <Button
                      type="submit"
                      fullWidth
                      disabled={
                        !isValid ||
                        isSubmitting ||
                        !dirty ||
                        initialValues.parent_organizations ===
                          values.parent_organizations
                      }
                      loading={isSubmitting}
                    >
                      {submitButtonText || 'Save Settings'}
                    </Button>
                  </Col>
                </Row>
              </Card.Content>
            </Card>
          </Form>
        );
      }}
    </Formik>
  );
};
