import { keyBy } from 'lodash';
import { Suspense, useState } from 'react';
import { Tabs } from 'antd';

import { DataOverTime, OrganizationExperienceResults } from '@perts/model';
import { Loading } from '@perts/ui';

import { Group } from 'models';
import { StudentExperienceChildSurveyTable } from '../';
import { NetworkTimeline } from './';
import { getCumulativeChange } from 'utils';

type Props = {
  networkOverallData: DataOverTime;
  organizationData: { [groupId: string]: OrganizationExperienceResults };
  groups: Group[];
  metricLabel: string;
};

export const NetworkChildBySurvey = ({
  networkOverallData,
  organizationData,
  groups,
  metricLabel,
}: Props) => {
  const [tabKey, setTabKey] = useState('graph');
  const handleTabClick = (key: string) => setTabKey(key);

  const groupsById = keyBy(groups, 'uid');

  const graphData = Object.entries(organizationData).map(
    ([orgId, { experience }]) => ({
      childName: groupsById[orgId]?.name || 'Unknown',
      ratedPositive: experience[metricLabel]?.composite.all_participants || [],
    }),
  );

  const tableData = Object.entries(organizationData)
    .map(([orgId, { experience }]) => {
      const cycleValues =
        experience[metricLabel]?.composite.all_participants || [];

      // Calculating cycle-to-cycle changes, or deltas, is awkward b/c values
      // might null. In general, if there aren't two adjacent cycles with data,
      // then the change should be null.
      const changeNum = cycleValues.map((curr, i) => {
        const prev = cycleValues[i - 1];
        if (i === 0 || curr === null || prev === null) {
          return null;
        }
        return Math.round((curr - prev) * 100);
      });

      // May be NaN.
      const cumulative = Math.round(getCumulativeChange(cycleValues) * 100);

      return {
        child_name: groupsById[orgId]?.name || 'Unknown',
        pct_rated_positive: cycleValues,
        rated_positive_change: changeNum.map(String),
        rated_positive_change_num: changeNum,
        cumulative_change: isNaN(cumulative) ? '—' : String(cumulative),
        cumulative_change_num: cumulative,
      };
    })
    .flat();

  return (
    <div>
      <Tabs activeKey={tabKey} centered onChange={handleTabClick} size="large">
        <Tabs.TabPane tab="Graph" key="graph">
          <Suspense fallback={<Loading />}>
            <NetworkTimeline
              experienceByChild={graphData}
              networkRatedPositive={networkOverallData}
            />
          </Suspense>
        </Tabs.TabPane>
        <Tabs.TabPane tab="Table" key="table">
          <StudentExperienceChildSurveyTable data={tableData} />
        </Tabs.TabPane>
      </Tabs>
    </div>
  );
};
