import { Group } from 'models';

import { Show } from '@perts/ui';
import { EmptyList } from 'components/EmptyList';
import { NetworkGroupCard } from './NetworkGroupCard';
import { NetworkGroupsActionbar } from './NetworkGroupsActionbar';
import useToggles from 'utils/useToggles';

export type NetworkGroupsRenderProps = {
  groups: Group[];
};

export const NetworkGroupsRender = ({ groups }: NetworkGroupsRenderProps) => {
  const { ControlToggle, Toggle, checked, someMarked } = useToggles(
    groups,
    'uid',
  );

  return (
    <>
      <NetworkGroupsActionbar
        checked={checked}
        ControlToggle={ControlToggle}
        disabled={!someMarked}
      />
      <Show when={groups.length === 0}>
        <EmptyList />
      </Show>

      {groups.map((group) => {
        const toggle = (
          <Toggle id={group.uid} label="" aria-label={group.name} />
        );

        return (
          <NetworkGroupCard key={group.uid} group={group} toggle={toggle} />
        );
      })}
    </>
  );
};
