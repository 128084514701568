// https://docs.google.com/spreadsheets/d/1ZX4hcUol-_frFLfUYRnZ-UPEvpGsVOsWsJRw1g5tDBM

import keyBy from 'lodash/keyBy';

import { locales } from '../i18n';
import { CompositeMetricConfig, MetricItemConfig } from './types';
import { catalyze24CompletelyTrueOptions } from './answerOptions/catalyze24CompletelyTrueOptions';

export const plc24CoherenceOverTimeItemConfig: MetricItemConfig[] = [
  {
    label: 'plc_action_item_followup',
    likertN: 4,
    minGood: 3,
    options: catalyze24CompletelyTrueOptions,
    prompts: {
      [locales.ENGLISH]:
        'My PLC followed up on action items from our last meeting.',
    },
  },
  {
    label: 'plc_focus_on_topic',
    likertN: 4,
    minGood: 3,
    options: catalyze24CompletelyTrueOptions,
    prompts: {
      [locales.ENGLISH]:
        'My PLC focuses on a topic over an extended period of time.',
    },
  },
];

export const plc24CoherenceOverTimeConfig: CompositeMetricConfig = {
  label: 'plc-coherence-over-time',
  items: plc24CoherenceOverTimeItemConfig,
  itemIndex: keyBy(plc24CoherenceOverTimeItemConfig, 'label'),
  name: 'Coherence Over Time',
  description:
    'Coherence over time is important for maintaining a consistent focus on ' +
    'shared goals, ensuring that collaborative efforts are sustained and ' +
    'that meetings build upon each other to create deeper learning for ' +
    'educators, and in turn, meaningful improvements in classroom practice.',
};
