import styled from 'styled-components/macro';
import { theme } from '../../';

export type SidebarProps = {
  children: React.ReactNode;
};

const SidebarStyled = styled.div`
  background: ${theme.colors.secondary};
  color: ${theme.colors.white};

  width: ${theme.units.appSidebarWidth};
  height: 100%;

  margin: 0;
  padding: ${theme.units.paddingMd};

  position: fixed;
  top: 0;
  left: 0;

  z-index: 100;

  display: flex;
  flex-direction: column;
  // align-items: center;
  justify-content: space-between;

  overflow-y: auto;

  @media (max-width: ${theme.units.menuCollapseWidth}px) {
    width: ${theme.units.appSidebarWidthCollapsed};
    padding: ${theme.units.paddingMd} 0;
  }

  @media print {
    // Aids in removing noise from printed reports.
    display: none;
  }
`;

export const Sidebar = SidebarStyled;

const SidebarMarginStyled = styled.div`
  // Always fits under the top bar, next to the sidebar, and to the edges of
  // the viewport at the right and bottom. Should never scroll.
  margin-left: ${theme.units.appSidebarWidth};
  height: calc(100vh - ${theme.units.appBarHeight});

  @media (max-width: ${theme.units.menuCollapseWidth}px) {
    margin-left: ${theme.units.appSidebarWidthCollapsed};
  }

  // When the Sidebar is hidden, move the content back over.
  @media print {
    margin-left: 0;
  }
`;

export const SidebarMargin = SidebarMarginStyled;
