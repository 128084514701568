import { useParams } from 'react-router-dom';

import { PagesParams } from 'pages';

import { ProgramMenu } from './ProgramMenu';
import { NetworkPagesMenu } from './NetworkPagesMenu';
import { GroupPagesMenu } from './GroupPagesMenu';

export const PagesMenu = () => {
  const { groupId, networkId } = useParams<PagesParams>();

  if (groupId) {
    return <GroupPagesMenu />;
  }

  if (networkId) {
    return <NetworkPagesMenu />;
  }

  return <ProgramMenu />;
};
