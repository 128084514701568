import { useContext } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import {
  Modal,
  Button,
  Row,
  Col,
  HelpText,
  TextField,
  Input,
  useFormError,
} from '@perts/ui';
import TermsContext from 'components/TermsContext';

import { helpArticles } from 'config';

const GroupsAddFormSchema = Yup.object().shape({
  name: Yup.string()
    .max(200, 'Name must be at most 200 characters long.')
    .required('Name is required.'),
});

export type GroupsAddFormValues = {
  name: string;
};

export type GroupsAddFormProps = {
  close: () => void;
  onSubmit: (values: GroupsAddFormValues) => void;
};

export const GroupsAddForm = ({ close, onSubmit }: GroupsAddFormProps) => {
  const [FormError, showFormError] = useFormError();
  const terms = useContext(TermsContext);

  return (
    <Modal close={close}>
      <Modal.Title className="center">Create {terms.group}</Modal.Title>

      <Formik
        enableReinitialize={true}
        initialValues={{
          name: '',
        }}
        validationSchema={GroupsAddFormSchema}
        onSubmit={async (values) => {
          try {
            // Clear existing form error.
            await showFormError(false);

            // Perform form onSubmit.
            await onSubmit(values);
          } catch (error) {
            // Display form error.
            showFormError(true);
          }
        }}
      >
        {({ isSubmitting, isValid, dirty }) => (
          <Form>
            <Input
              id="group-name"
              name="name"
              label="Name"
              labelPlacement="top"
              component={TextField}
              helpText={
                <HelpText articleId={helpArticles.whatIsACommunity}>
                  {`What should I name my ${terms.group}?`}
                </HelpText>
              }
            />

            <Row>
              <Col>
                <FormError />
              </Col>
            </Row>

            <Row justifyContent="space-between">
              <Col cols={6} colsSm={12}>
                <Button
                  type="button"
                  color="secondary"
                  fullWidth
                  onClick={close}
                  disabled={isSubmitting}
                >
                  Cancel
                </Button>
              </Col>

              <Col cols={6} colsSm={12} hAlign="flex-end">
                <Button
                  type="submit"
                  fullWidth
                  disabled={!isValid || isSubmitting || !dirty}
                  loading={isSubmitting}
                  data-testid="submit"
                >
                  Create {terms.group}
                </Button>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
