import { Switch, Route } from 'react-router-dom';
import { Col, Link, Row, Text } from '@perts/ui';
import { toParams } from '@perts/util';
import {
  useClassGetByParams,
  useGroupGetByParams,
  useNetworkGetByParams,
  useProgramId,
  useProgramsQuery,
} from 'models';
import { programNameAndCohort } from 'utils';
import {
  toArchive,
  toArchiveClassReport,
  toArchiveClassReportId,
  toArchiveGroup,
  toArchiveGroupReport,
  toArchiveGroupReportId,
  toArchiveNetworkReport,
  toArchiveNetworkReportId,
  toArchiveProgram,
} from 'pages';
import { IconBreadcrumbChevron } from './IconBreadcrumbChevron';

const NavArchive = ({ programId, programDisplayName }) => {
  const Archive = () => (
    <>Report Archive {programDisplayName && <>({programDisplayName})</>}</>
  );

  return (
    <>
      <IconBreadcrumbChevron />

      <Switch>
        {programId && (
          <Route
            path={[
              toArchiveClassReport(),
              toArchiveClassReportId(),
              toArchiveGroupReport(),
              toArchiveGroupReportId(),
              toArchiveNetworkReport(),
              toArchiveNetworkReportId(),
              toArchiveGroup(),
            ]}
          >
            <Link to={toArchiveProgram(toParams(programId))}>
              <Archive />
            </Link>
          </Route>
        )}

        <Route path={[toArchiveProgram(), toArchive()]}>
          <Archive />
        </Route>
      </Switch>
    </>
  );
};

const NavNetwork = ({ network }) => (
  <>
    <IconBreadcrumbChevron />
    {network.name}
  </>
);

const NavGroup = ({ group, cls }) => (
  <>
    <IconBreadcrumbChevron />
    <Switch>
      <Route path={[toArchiveClassReport(), toArchiveClassReportId()]}>
        <Link to={toArchiveGroup(group.short_uid)}>{group.name}</Link>
      </Route>
      <Route path={[toArchiveGroupReport(), toArchiveGroupReportId()]}>
        <>
          <Link to={toArchiveGroup(group.short_uid)}>{group.name}</Link>
          <IconBreadcrumbChevron />
          Community Report
        </>
      </Route>
      <Route path={toArchiveGroup()}>{group.name}</Route>
    </Switch>
  </>
);

const NavClass = ({ cls }) => (
  <>
    <IconBreadcrumbChevron />
    {cls.name}
  </>
);

const Nav = () => {
  const programId = useProgramId();
  const { data: network } = useNetworkGetByParams();
  const { data: group } = useGroupGetByParams();
  const { data: cls } = useClassGetByParams();
  const { data: programs = [] } = useProgramsQuery();

  const program = programs.find((p) => p.uid === programId);
  const programDisplayName = program && programNameAndCohort(program);

  return (
    <>
      <Link to="/">Home</Link>

      <NavArchive
        programId={programId}
        programDisplayName={programDisplayName}
      />

      {network && <NavNetwork network={network} />}
      {group && <NavGroup group={group} cls={cls} />}
      {cls && <NavClass cls={cls} />}
    </>
  );
};

export const BreadcrumbsArchive = () => (
  <Route
    path={[
      toArchiveClassReport(),
      toArchiveClassReportId(),
      toArchiveGroupReport(),
      toArchiveGroupReportId(),
      toArchiveNetworkReport(),
      toArchiveNetworkReportId(),
      toArchiveGroup(),
      toArchiveProgram(),
      toArchive(),
    ]}
  >
    <Row>
      <Col vAlign="center" flexWrap="wrap">
        <Text as="nav">
          <Nav />
        </Text>
      </Col>
    </Row>
  </Route>
);
