import styled, { css } from 'styled-components';
import theme from 'components/theme';

type ScheduleTableRowContainerProps = {
  showBorder?: boolean;
  backgroundColor?: string;
  borderColor?: string;
  active?: boolean;
  height?: number;
  checked?: boolean;
};

type ScheduleTableRowProps = ScheduleTableRowContainerProps & {
  // children to be displayed.
  children: React.ReactNode;
};

const ScheduleTableRowContainer = styled.div<ScheduleTableRowContainerProps>`
  position: relative;
  display: flex;
  width: fit-content;
  min-width: 100%;
  border-radius: ${theme.units.borderRadius};
  margin-bottom: ${theme.units.fieldPadding};

  > * {
    background-color: ${(props) =>
      props.backgroundColor ? props.backgroundColor : theme.colors.white};
  }

  ${({ height }) => height && ` height: ${height}px; `}

  ${(props) =>
    props.showBorder &&
    css`
      > :first-child {
        border-top-left-radius: ${theme.units.borderRadius};
        border-bottom-left-radius: ${theme.units.borderRadius};
        border: ${props.active ? '5px' : '1px'} solid
          ${props.borderColor ? props.borderColor : theme.colors.grayLight};
        border-right-width: 0px;

        // Margin to prevent initial spacing related to before
        margin-left: -${theme.units.borderRadius};
      }

      // Element located at the beginning of the row to prevent showing
      // borders at corners when scrolling
      :before {
        content: '';
        position: sticky;
        left: 0;
        width: ${theme.units.borderRadius};
        height: inherit;
        background: ${theme.colors.white};
      }

      > :last-child {
        border-top-right-radius: ${theme.units.borderRadius};
        border-bottom-right-radius: ${theme.units.borderRadius};
        border: ${props.active ? '5px' : '1px'} solid
          ${props.borderColor ? props.borderColor : theme.colors.grayLight};
        border-left-width: 0px;
      }

      > :not(:first-child) {
        border-top: ${props.active ? '5px' : '1px'} solid
          ${props.borderColor ? props.borderColor : theme.colors.grayLight};
        border-bottom: ${props.active ? '5px' : '1px'} solid
          ${props.borderColor ? props.borderColor : theme.colors.grayLight};
      }
    `};

  ${({ checked }) =>
    Boolean(checked) &&
    css`
      > :first-child {
        overflow: hidden;
        &:after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 5px;
          background: ${theme.colors.primary};
        }
      }
    `}
`;

export const ScheduleTableRow = ({
  children,
  showBorder = true,
  active,
  ...props
}: ScheduleTableRowProps) => (
  <ScheduleTableRowContainer showBorder={showBorder} active={active} {...props}>
    {children}
  </ScheduleTableRowContainer>
);
