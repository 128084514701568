/**
 * Get appropriate axis limits for rows of data. Makes sure
 * - Axis is "zoomed in" to the data, but never too much.
 * - Avoid placing data points right at the limits, but keeps limits within
 *   [0%, 100%].
 * - Limits are convenient for typical tick intervals, e.g. [10%, 20%] and not
 *   [12%, 19%].
 * - With RANGE_MARGIN at 3 and STEP_SIZE at 10, it adds at most 13% to the max
 *   and min of the data.
 * @param {number} rangeMin decimal, lowest present in data.
 * @param {number} rangeMax decimal, highest present in data.
 * @returns {Object} with { min, max }.
 */
export const getPercentAxisLimits = (rangeMin: number, rangeMax: number) => {
  const MIN_ALLOWED_RANGE = 5;
  const RANGE_MARGIN = 3;
  const STEP_SIZE = 10;

  // Javascript floats are annoying. Change all data to percentage point
  // integers before multiplying or dividing.
  const rangeMax100 = rangeMax * 100;
  const rangeMin100 = rangeMin * 100;

  const range = rangeMax100 - rangeMin100;
  const avg = (rangeMin100 + rangeMax100) / 2;

  let max: number;
  let min: number;

  if (range >= MIN_ALLOWED_RANGE) {
    // Range is big enough, but apply a margin otherwise data points would lie
    // right on the edge.
    max = rangeMax100 + RANGE_MARGIN;
    min = rangeMin100 - RANGE_MARGIN;
  } else {
    // Range is too small. Center the limits around the average of the
    // data. No margin required because we already know the range is smaller.
    max = avg + MIN_ALLOWED_RANGE / 2;
    min = avg - MIN_ALLOWED_RANGE / 2;
  }

  // Make sure limits don't fall outside of [0, 100].
  max = Math.min(max, 100);
  min = Math.max(min, 0);

  // Push out the limits to the nearest step.
  max = Math.ceil(max / STEP_SIZE) * STEP_SIZE;
  min = Math.floor(min / STEP_SIZE) * STEP_SIZE;

  // Convert back to decimals.
  max /= 100;
  min /= 100;

  return { max, min };
};
