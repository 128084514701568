import { useContext } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useQueryClient, useMutation } from 'react-query';
import { message } from 'antd';

import {
  groupsAddToProgram,
  useProgramGetByParams,
  queryKeyGroups,
  Group,
} from 'models';
import { toGroupMembers, toHome, PagesParams } from 'pages';
import { useCloseModal } from 'utils';

import { GroupsAddForm, GroupsAddFormValues } from './GroupsAddForm';
import Loading from 'components/Loading';
import TermsContext from 'components/TermsContext';
import { ErrorMessageBox } from 'components/ErrorMessageBox';
import { getMessageFromErrors } from '@perts/util';

export const GroupsAdd = () => {
  const { programId = '' } = useParams<PagesParams>();
  const history = useHistory();
  const closeModal = useCloseModal({
    pathname: toHome(programId),
    ignoreEmptyState: true,
  });
  const queryClient = useQueryClient();
  const terms = useContext(TermsContext);

  // Query for Programs.
  const {
    isLoading: programIsLoading,
    isError: programIsError,
    data: program,
    error: programError,
  } = useProgramGetByParams();

  // Mutation: Add group to program.
  // https://react-query.tanstack.com/guides/mutations
  const mutation = useMutation(
    ({ name }: GroupsAddFormValues) => {
      if (!program) {
        throw new Error(
          `Program required to add ${terms.group.toLowerCase()}.`,
        );
      }

      return groupsAddToProgram(name, program.uid);
    },
    {
      // Handle successful mutation.
      onSuccess: (data) => {
        message.success(
          `Successfully created the ${terms.group.toLowerCase()}.`,
        );

        const previousValue =
          queryClient.getQueryData<Group[]>(queryKeyGroups()) || [];

        const updatedValue = [data, ...previousValue].sort((a, b) =>
          a.name > b.name ? 1 : -1,
        );

        queryClient.setQueryData(queryKeyGroups(), updatedValue);

        history.push(toGroupMembers(data.short_uid));
      },
    },
  );

  // Display loading when users data is loading.
  if (programIsLoading) {
    return <Loading />;
  }

  // TODO DRY https://github.com/PERTS/perts/issues/61
  // Display any errors.
  if (programIsError) {
    return (
      <ErrorMessageBox>{getMessageFromErrors([programError])}</ErrorMessageBox>
    );
  }

  // https://stackoverflow.com/questions/65760158/react-query-mutation-typescript
  // Formik onSubmit handler
  const onSubmit = async (values: GroupsAddFormValues) => {
    await mutation.mutateAsync(values);
  };

  return <GroupsAddForm close={closeModal} onSubmit={onSubmit} />;
};
