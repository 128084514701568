import { QueryClient } from 'react-query';

import {
  Class,
  Classroom,
  ClassroomForUpdate,
  Group,
  Program,
  Team,
  TeamForUpdate,
  User,
  queryKeyClass,
  queryKeyClassesByGroup,
  SurveyToUpdate,
  Survey,
} from 'models';
import { update } from 'services/triton/classes';
import { post as createClassroom } from 'services/triton/classrooms';
import {
  query as queryTeams,
  queryByOrganization,
  get,
  post as createTeam,
  remove as removeTeam,
} from 'services/triton/teams';
import { update as updateSurvey } from 'services/triton/surveys';
import { invite } from 'services/triton/users';
import { getShortUid } from '@perts/util';
import {
  teamWithDefaults,
  classroomWithDefaults,
  surveyMergeWithDefaults,
} from './helpers';

// -----------------------------------------------------------------------------
//   Utility Functions
// -----------------------------------------------------------------------------

// Transform a Team-Classroom-Survey into a Class.
// This transformation combines the relevant properties from Team, Classroom
// and Survey to create a combined Class object.
// A `team`, `classroom` and `survey` property are
// retained with the original data as provided by the server.

type TransformTeamClassroomSurveyToClass = {
  team: Team;
  classroom?: Classroom;
  survey?: Survey;
};

// eslint-disable-next-line complexity
export const transformTeamClassroomSurveyToClass = ({
  team,
  classroom,
  survey,
}: TransformTeamClassroomSurveyToClass): Class => ({
  // From team table.
  uid: team.uid,
  team_id: team.uid,
  name: team.name,
  num_classrooms: team.num_classrooms,
  num_users: team.num_users,
  organization_ids: team.organization_ids,
  program_id: team.program_id,
  short_uid: team.short_uid,

  // Transform null values to empty string. Empty string and null should be
  // considered equal (both disabled) as the initial value for
  // target_group_name in the database is null but we set the
  // target_group_name to an empty string when a user disables the feature.
  target_group_name: team.target_group_name || '',

  // From classroom table.
  classroom_id: classroom?.uid || team.classroom_id,
  code: classroom?.code || team.code,
  num_students: classroom?.num_students || team.num_students,
  // Standardize falsey values (like null) to empty string.
  participant_pattern:
    classroom?.participant_pattern || team.participant_pattern || '',
  // Standardize falsey values (like null) to empty string.
  participant_ending:
    classroom?.participant_ending || team.participant_ending || '',
  report_settings: classroom?.report_settings || team.report_settings || {},
  roster_locked: classroom?.roster_locked || team.roster_locked,
  subject_area: classroom?.subject_area || team.subject_area,

  // From survey table.
  survey_id: survey?.uid || team.survey_id,
  meta: survey?.meta || team.meta,
  metrics: survey?.metrics || team.metrics,
  metric_labels: survey?.metric_labels || team.metric_labels || [],
  open_responses: survey?.open_responses || team.open_responses,
  portal_type: survey?.portal_type || team.portal_type,
  portal_message: survey?.portal_message || team.portal_message,

  // Client embeds some data here. TODO: remove these and handle separately.
  cycles: undefined,
  cyclesIsLoading: false,

  facilitators: undefined,
  facilitatorsIsLoading: false,

  groups: undefined,
  groupsIsLoading: false,

  participants: undefined,
  participantsIsLoading: false,

  last_updated_results: team.last_updated_results,
});

// Transform a Class into a TeamForUpdate.
// This transformation "reverses" transformTeamClassroomSurveyToClass so that
// the Team entity is in a shape that can be sent to server.

export const transformClassToTeam = (cls: Class): TeamForUpdate => ({
  uid: cls.uid,
  name: cls.name,
  organization_ids: cls.organization_ids,
  target_group_name: cls.target_group_name,
  metrics: cls.metrics,
  open_responses: cls.open_responses,
});

// Transform a Class into a ClassroomForUpdate.
// This transformation "reverses" transformTeamClassroomSurveyToClass so that
// the Classroom entity is in a shape that can be sent to server.

export const transformClassToClassroom = (cls: Class): ClassroomForUpdate => ({
  uid: cls.classroom_id,
  name: cls.name,
  code: cls.code,
  roster_locked: cls.roster_locked,
  participant_pattern: cls.participant_pattern,
  participant_ending: cls.participant_ending,
  report_settings: cls.report_settings,
  subject_area: cls.subject_area,
  team_id: cls.uid,
});

// Transform a Class into a SurveyToUpdate.
// This transformation "reverses" transformTeamClassroomSurveyToClass so that
// the Survey entity is in a shape that can be sent to server.

export const transformClassToSurvey = (cls: Class): SurveyToUpdate => ({
  uid: cls.survey_id,
  short_uid: getShortUid(cls.survey_id),
  team_id: cls.uid,
  portal_type: cls.portal_type,
  portal_message: cls.portal_message,
  meta: cls.meta,
  metrics: cls.metrics,
  open_responses: cls.open_responses,
});

// -----------------------------------------------------------------------------
//   Query Invalidation
// -----------------------------------------------------------------------------

export const invalidateClass = (queryClient: QueryClient, classId: string) =>
  queryClient.invalidateQueries(queryKeyClass(classId));

export const invalidateGroupClasses = (
  queryClient: QueryClient,
  groupId: string,
) => queryClient.invalidateQueries(queryKeyClassesByGroup(groupId));

// -----------------------------------------------------------------------------
//   API Functions
// -----------------------------------------------------------------------------

// Query all of the Classes that belong to the Group with `groupId`. Only
// accessible by group managers and super admins.
// /api/organizations/:groupId/teams

type ClassesQueryByGroupId = (groupId: string) => Promise<Class[]>;

export const classesQueryByGroupId: ClassesQueryByGroupId = async (groupId) => {
  const teams = await queryByOrganization(groupId);
  return teams.map((team) => transformTeamClassroomSurveyToClass({ team }));
};

// Query all of the User's Classes that belong to the Group with `groupId`;
// /api/users/:userId/teams?organization_id=:groupId

type ClassesQueryByUserGroupId = (
  user: User,
  groupId: string,
) => Promise<Class[]>;

export const classesQueryByUserGroupId: ClassesQueryByUserGroupId = async (
  user,
  groupId,
) => {
  const isAdmin = false; // forces legacy function to use user-based endpoint
  const queryOptions = { organization: groupId };
  const { teams } = await queryTeams(user.uid, isAdmin, queryOptions);
  return teams.map((team) => transformTeamClassroomSurveyToClass({ team }));
};

// Get Class with `classId`.

type ClassGetById = (classId: string) => Promise<Class>;

export const classGetById: ClassGetById = async (classId) => {
  const team = (await get(classId)) as unknown as Team;
  return transformTeamClassroomSurveyToClass({ team });
};

// Update Class

type UpdateClass = (cls: Class) => Promise<Class>;

export const updateClass: UpdateClass = async (cls) => {
  const classToUpdate = {
    ...cls,
    meta: {
      ...cls.meta,
      class_name: cls.name,
    },
  };
  const classResponse = await update(classToUpdate);
  return transformTeamClassroomSurveyToClass({ team: classResponse });
};

// Update Group Classes

type UpdateGroupClasses = (classes: Class[]) => Promise<Class[]>;

export const updateGroupClasses: UpdateGroupClasses = (classes) =>
  Promise.all(classes.map((cls) => updateClass(cls)));

// Add class to Group.

type ClassesAddToGroup = (
  name: string,
  subject_area: string,
  facilitator: User,
  program: Program,
  group: Group,
) => Promise<Class>;

export const classesAddToGroup: ClassesAddToGroup = async (
  name,
  subject_area,
  facilitator,
  program,
  group,
) => {
  // TODO Use types and transform functions.
  // - ClassForCreate, TeamForCreate, ClassroomForCreate
  //   transformClassToTeam, transformClassToClassroom, etc.

  const teamToCreate = teamWithDefaults(program, group, facilitator, name);
  const team = await createTeam(teamToCreate);

  const classroomToCreate = classroomWithDefaults(
    program,
    group,
    team,
    facilitator,
    name,
    subject_area,
  );
  const classroom = await createClassroom(classroomToCreate);

  const currentSurvey = transformClassToSurvey(team);
  const surveyToUpdate = surveyMergeWithDefaults(group, currentSurvey, name);
  const survey = await updateSurvey(surveyToUpdate, classroom);

  const invitee = {
    ...facilitator,
    owned_teams: [...facilitator.owned_teams, team.uid],
  };

  await invite(invitee, team, { orgId: group.uid });

  return transformTeamClassroomSurveyToClass({ team, classroom, survey });
};

// Remove Classes from Group.

type ClassesRemoveFromGroup = (classes: Class[]) => void;

export const classesRemoveFromGroup: ClassesRemoveFromGroup = async (
  classes,
) => {
  await Promise.all(
    classes.map((cls) => {
      const team = transformClassToTeam(cls);

      return removeTeam(team);
    }),
  );
};
