const functionBranchPrepend: { [branchName: string]: string } = {
  _default: 'development',
  main: 'production',
  acceptance: 'acceptance',
  dev: 'development',
};

export const gcloudFunctionUrl = (functionName: string) => {
  if (
    !process.env.REACT_APP_GIT_BRANCH ||
    !process.env.REACT_APP_FUNCTIONS_PROTOCOL ||
    !process.env.REACT_APP_FUNCTIONS_DOMAIN
  ) {
    throw Error(
      [
        'The environment variables REACT_APP_GIT_BRANCH, ',
        'REACT_APP_FUNCTIONS_PROTOCOL, and REACT_APP_FUNCTIONS_DOMAIN are ',
        'required.',
      ].join(),
    );
  }

  const baseUrl = `${process.env.REACT_APP_FUNCTIONS_PROTOCOL}://${process.env.REACT_APP_FUNCTIONS_DOMAIN}`;

  const functionPrepend =
    functionBranchPrepend[process.env.REACT_APP_GIT_BRANCH] ??
    functionBranchPrepend._default;

  const url = `${baseUrl}/${functionPrepend}-${functionName}`;

  return url;
};
