import areDateRangesOverlapped from 'utils/areDateRangesOverlapped';

type GetCyclesToAddOrRemoveFromErrors = {
  cyclesToCompare: {
    [key: string]: {
      start_date: string | null;
      end_date: string | null;
    };
  };
  start_date: string | null;
  end_date: string | null;
};

export const getCyclesToAddOrRemoveFromErrors = ({
  cyclesToCompare,
  start_date,
  end_date,
}: GetCyclesToAddOrRemoveFromErrors) => {
  const errorsToAddModifiedCycle: string[] = [];
  const errorsToRemoveModifiedCycle: string[] = [];

  Object.entries(cyclesToCompare).forEach(([cycleId, c]) => {
    if (
      areDateRangesOverlapped(
        [start_date, end_date],
        [c.start_date, c.end_date],
      )
    ) {
      errorsToAddModifiedCycle.push(cycleId);
    } else {
      errorsToRemoveModifiedCycle.push(cycleId);
    }
  });

  return [errorsToAddModifiedCycle, errorsToRemoveModifiedCycle];
};
