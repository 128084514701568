import { Col, Row, useBroadcast } from '@perts/ui';
import DataTable from '../DataTable';
import { channelTablePrinting } from '../../config';
import { useEffect } from 'react';

export type ReactTablePaginationProps = {
  canNextPage: boolean;
  canPreviousPage: boolean;
  gotoPage: (page: number) => void;
  nextPage: () => void;
  pageCount: number;
  pageIndex: number;
  pageSize: number;
  previousPage: () => void;
  setPageSize: (pageSize: number) => void;
  totalRows: number;
};

const DEFAULT_PAGE_SIZE = 10;

export const ReactTablePagination = ({
  canNextPage,
  canPreviousPage,
  gotoPage,
  nextPage,
  pageCount,
  pageIndex,
  pageSize,
  previousPage,
  setPageSize,
  totalRows,
}: ReactTablePaginationProps) => {
  const { listen: listenTablePrinting, broadcast: broadcastTablePrinting } =
    useBroadcast(channelTablePrinting);
  listenTablePrinting((shouldShowAllRows) => {
    if (shouldShowAllRows) {
      setPageSize(totalRows);
    } else {
      setPageSize(
        totalRows <= DEFAULT_PAGE_SIZE ? totalRows : DEFAULT_PAGE_SIZE,
      );
    }
  });
  useEffect(() => {
    const handleBeforePrint = () => {
      broadcastTablePrinting(true);
    };
    const handleAfterPrint = () => {
      broadcastTablePrinting(false);
    };

    window.addEventListener('beforeprint', handleBeforePrint);
    window.addEventListener('afterprint', handleAfterPrint);

    return () => {
      window.removeEventListener('beforeprint', handleBeforePrint);
      window.removeEventListener('afterprint', handleAfterPrint);
    };
  }, [broadcastTablePrinting]);
  return (
    <Row>
      <Col hAlign="flex-start" />
      <Col hAlign="center">
        <DataTable.PageNav
          canNextPage={canNextPage}
          canPreviousPage={canPreviousPage}
          gotoPage={gotoPage}
          nextPage={nextPage}
          pageCount={pageCount}
          pageIndex={pageIndex}
          previousPage={previousPage}
        />
      </Col>
      <Col hAlign="flex-end">
        <DataTable.PageSize
          pageSize={pageSize}
          totalRows={totalRows}
          setPageSize={setPageSize}
        />
      </Col>
    </Row>
  );
};
