import { useRef, useState } from 'react';
import { message } from 'antd';
import { Button, InfoBox } from '@perts/ui';
import AuthWrapper from 'components/AuthWrapper';
import {
  NetworkTestContainer,
  NetworkTestErrors,
} from './NetworkTestContainer';

import { numAttempts } from './config';
import { loadFirebase } from './loadFirebase';
import { loadImage } from './loadImage';

const tests = [
  {
    testFn: loadFirebase,
    domain: 'auth.perts.net',
    url: 'https://auth.perts.net/__/auth/iframe.js?',
  },
  {
    testFn: loadImage,
    domain: 'neptune.perts.net',
    url: 'https://neptune.perts.net/static/images/pertslogo.png?',
  },
  {
    testFn: loadImage,
    domain: 'perts.me',
    url: 'https://perts.me/static/images/pertslogo.png?',
  },
  // PERTS logo hosted in qualtrics graphics library
  {
    testFn: loadImage,
    domain: 'login.qualtrics.com',
    url: 'https://login.qualtrics.com/ControlPanel/Graphic.php?IM=IM_8JiWyM7KJ6kAvyd',
  },
];

type ResultsByDomain = {
  [domain: string]: { isError: boolean; results: string[] };
};

const testNetwork = async () => {
  const resultsByDomain: ResultsByDomain = {};

  await Promise.all(
    tests.map(async (test) => {
      const results = await Promise.all(
        Array.from({ length: numAttempts }).map(() => test.testFn(test.url)),
      );

      const isErrorDomain = results.some((r) => r !== 'load');

      resultsByDomain[test.domain] = {
        results,
        isError: isErrorDomain,
      };
    }),
  );

  const isError = Object.values(resultsByDomain).some((r) => r.isError);

  return { resultsByDomain, isError };
};

export const NetworkTest = () => {
  const [isRunning, setIsRunning] = useState(false);
  const [isError, setIsError] = useState(false);
  const [testResults, setTestResults] = useState({});
  const isResults = 'isError' in testResults;

  const testAndReport = async () => {
    setIsRunning(true);
    const results = await testNetwork();
    setIsError(results.isError);
    setTestResults(results);
    setIsRunning(false);
  };

  const divRef = useRef<HTMLDivElement>(null);

  const handleCopyClick = () => {
    if (divRef.current) {
      const textToCopy: string = divRef.current.innerText;

      // Use the Clipboard API to copy text to the clipboard
      navigator.clipboard
        .writeText(textToCopy)
        .then(() => {
          message.success('Text successfully copied to clipboard');
        })
        .catch(() => {
          // If this doesn't work, the user can copy/paste manually.
        });
    }
  };

  return (
    <AuthWrapper>
      <NetworkTestContainer>
        <h1>Test Your Network</h1>

        <p>
          If you&rsquo;re planning on participating in a PERTS program,
          it&rsquo;s important that your local network can support our tools.
        </p>

        <div>
          <Button onClick={testAndReport} fullWidth>
            Run Test
          </Button>
        </div>

        {isRunning && <p id="busy">Working&hellip;</p>}

        {isResults && !isError && (
          <InfoBox color="success" data-testid="test-success">
            <p>Everything works great!</p>
          </InfoBox>
        )}

        {isResults && isError && (
          <>
            <InfoBox color="warning" data-testid="test-failure">
              <p>
                <strong>There was a problem.</strong> Please copy and paste the
                following into an email and send it to{' '}
                <a href="mailto:support@perts.net">support@perts.net</a>.
              </p>
            </InfoBox>

            <NetworkTestErrors onClick={handleCopyClick}>
              <code ref={divRef}>{JSON.stringify(testResults, null, 2)}</code>
            </NetworkTestErrors>
          </>
        )}
      </NetworkTestContainer>
    </AuthWrapper>
  );
};
