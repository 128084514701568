import React from 'react';
import styled, { css } from 'styled-components/macro';

import { IconSort, IconSortDown, IconSortUp, theme } from '@perts/ui';

import { DataTablePageNav } from './DataTablePageNav';
import { DataTablePageSize } from './DataTablePageSize';

export * from './DataTableExpander';
export * from './DataTablePageNav';
export * from './DataTablePageNavLink';

export const DataTableStyled = styled.table<{ colWidths?: number[] }>`
  margin: ${theme.units.padding} auto;
  width: 100%;
  max-width: 100%;

  table-layout: fixed;

  border-collapse: collapse;

  font-size: 0.75em;

  // In general, header rows are this color:
  thead tr {
    background-color: ${theme.colors.secondary};
    color: ${theme.colors.white};
  }

  thead tr {
    background-color: ${theme.colors.secondary};
    color: ${theme.colors.white};
  }

  thead tr:last-child:not(:first-child) th {
    padding-top: 0;
  }

  thead {
    border-bottom: 1px solid ${theme.colors.grayMedium};
  }

  td,
  th {
    padding: 10px 0;
    border: none;
    text-align: center;
    font-size: 12px;
  }

  th {
    font-weight: normal;
    padding-top: 8px;
    padding-bottom: 8px;
  }

  td {
    word-break: break-word;
  }

  tbody tr:nth-child(2n) {
    background-color: ${theme.colors.grayLight};
  }

  // Won't work with any cell-merging i.e. colspan!
  ${({ colWidths = [] }) => {
    const cssStrings = colWidths.map(
      (percent, i) => `
      thead th:nth-child(${i + 1}),
      tbody td:nth-child(${i + 1}) {
        width: ${percent}%;
      }
    `,
    );
    return css`
      ${cssStrings.join('\n')}
    `;
  }}

  tfoot td {
    font-style: italic;
    border: none;

    &:first-child {
      text-align: left;
    }

    &:last-child {
      text-align: right;
    }
  }

  @media print {
    td,
    th {
      padding: 6px 2px;
      font-size: 10px;
    }
  }
`;

const DataTableHeaderStyled = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  > * {
    text-align: center;
  }
`;

const DataTableSortHeader: React.FC = ({ children, ...rest }) => (
  <th {...rest}>
    <DataTableHeaderStyled>{children}</DataTableHeaderStyled>
  </th>
);

const DataTableSortIconStyled = styled.span<{ isSorted: boolean }>`
  color: ${theme.colors.primary};

  position: relative;
  top: 2px;
`;

type SortIconProps = {
  canSort: boolean;
  isSorted: boolean;
  isSortedDesc: boolean;
};

// https://google.github.io/material-design-icons/
// https://www.w3.org/WAI/WCAG21/Techniques/aria/ARIA24.html
const DataTableSortIcon: React.FC<SortIconProps> = ({
  canSort,
  isSorted,
  isSortedDesc,
}) =>
  canSort ? (
    <DataTableSortIconStyled role="img" isSorted={isSorted}>
      {!isSorted ? (
        <IconSort />
      ) : isSortedDesc ? (
        <IconSortDown />
      ) : (
        <IconSortUp />
      )}
    </DataTableSortIconStyled>
  ) : null;

type Props = {
  colWidths?: number[];
};

type Widths = { [percent: number]: number };

const width: Widths = {
  100: 790,
  25: 198,
  20: 158,
};

// Accounts for cell padding.
const contentWidth: Widths = {
  40: 295,
  33: 240,
  25: 175,
  20: 135,
  15: 100,
};

// Style wrappers for react-table components that display data.
export default class DataTable extends React.Component<Props> {
  static PageNav = DataTablePageNav;
  static PageSize = DataTablePageSize;
  static SortHeader = DataTableSortHeader;
  static SortIcon = DataTableSortIcon;
  static units = { width, contentWidth };

  render() {
    const { children, ...rest } = this.props;
    return <DataTableStyled {...rest}>{children}</DataTableStyled>;
  }
}
