// https://docs.google.com/spreadsheets/d/1ZX4hcUol-_frFLfUYRnZ-UPEvpGsVOsWsJRw1g5tDBM

import keyBy from 'lodash/keyBy';

import { locales } from '../i18n';
import { CompositeMetricConfig, MetricItemConfig } from './types';

export const plc24MeetingRegularityItemConfig: MetricItemConfig[] = [
  {
    label: 'plc_attend_meeting',
    likertN: 2,
    minGood: 2,
    options: {
      [locales.ENGLISH]: [
        { value: 1, label: '0 or 1 times' },
        { value: 2, label: 'More than once' },
      ],
    },
    prompts: {
      [locales.ENGLISH]:
        'In the past month, how many times did you attend a PLC meeting?',
    },
  },
];

export const plc24MeetingRegularityConfig: CompositeMetricConfig = {
  label: 'plc-meeting-regularity',
  items: plc24MeetingRegularityItemConfig,
  itemIndex: keyBy(plc24MeetingRegularityItemConfig, 'label'),
  name: 'Meeting Regularity',
  description:
    "Meeting regularly fosters PLC group members' collaboration, collective " +
    'problem-solving, and continuous learning.',
};
