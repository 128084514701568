/**
 * Must match the behavior of `stripToken()` in the neptune portal.
 * Currently in app_participate/name_or_id/name_or_id.component.js
 */
export const stripToken = (rawToken: any) =>
  // Clean up user input of tokens.
  // Token should be lowercase alpha numeric characters without whitespace.
  typeof rawToken !== 'string'
    ? undefined
    : rawToken.toLowerCase().replace(/[^a-z0-9]/g, '');
