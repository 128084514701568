import { LocationState } from 'history';
import { useHistory as useHistoryReactRouter } from 'react-router-dom';
import { useQueryParams } from '..';

// useHistory is a wrapper around react-router's useHistory that will return
// wrapped versions of push & replace that retain searchParams. This is useful,
// for example, on pages that utilize pagination (`&page=3`).

export const useHistoryRetain = () => {
  const history = useHistoryReactRouter();
  const currentSearchParams = useQueryParams().toString();

  const historyGeneric = (fn) =>
    function (pathname: string, state: LocationState = {}) {
      fn({
        pathname,
        search: currentSearchParams,
        state,
      });
    };

  return {
    push: historyGeneric(history.push),
    replace: historyGeneric(history.replace),
  };
};
