import { useContext } from 'react';
import { Switch, Route } from 'react-router-dom';

import { useNetworkGetByParams } from 'models';
import {
  toNetworkGroups,
  toNetworkMembers,
  toNetworkParticipation,
  toNetworkReport,
  toNetworkReportSettings,
  useParams,
} from 'pages';

import { Text, Row, Col, Link } from '@perts/ui';
import { BreadcrumbsName } from './BreadcrumbsName';
import { IconBreadcrumbChevron } from './IconBreadcrumbChevron';

import TermsContext from 'components/TermsContext';

const BreadcrumbsNetworkMembers = () => {
  const terms = useContext(TermsContext);
  return (
    <>
      <IconBreadcrumbChevron />
      <span>{terms.networkMembers}</span>
    </>
  );
};

const BreadcrumbsNetworkGroups = () => {
  const terms = useContext(TermsContext);
  return (
    <>
      <IconBreadcrumbChevron />
      <span>{terms.groups}</span>
    </>
  );
};

const BreadcrumbsNetworkParticipation = () => (
  <>
    <IconBreadcrumbChevron />
    <span>Participation</span>
  </>
);

const BreadcrumbsNetworkReportSettings = () => {
  const { networkId } = useParams();

  return (
    <>
      <IconBreadcrumbChevron />
      <span>
        <Link to={toNetworkReport(networkId)}>Report</Link>
      </span>
      <IconBreadcrumbChevron />
      <span>Settings</span>
    </>
  );
};

const BreadcrumbsNetworkReport = () => (
  <Switch>
    <Route path={toNetworkReport()}>
      <IconBreadcrumbChevron />
      <span>Report</span>
    </Route>
  </Switch>
);

export const BreadcrumbsNetwork = () => {
  const { data: network } = useNetworkGetByParams();

  return network ? (
    <Row>
      <Col vAlign="center" flexWrap="wrap">
        <Text as="nav">
          <Switch>
            <Route path="/networks">
              <Link to="/">Home</Link>

              <IconBreadcrumbChevron />
              <BreadcrumbsName name={network.name} />
              <Switch>
                <Route path={toNetworkMembers()}>
                  <BreadcrumbsNetworkMembers />
                </Route>

                <Route path={toNetworkGroups()}>
                  <BreadcrumbsNetworkGroups />
                </Route>

                <Route path={toNetworkParticipation()}>
                  <BreadcrumbsNetworkParticipation />
                </Route>

                <Route path={toNetworkReportSettings()}>
                  <BreadcrumbsNetworkReportSettings />
                </Route>

                <Route path={toNetworkReport()}>
                  <BreadcrumbsNetworkReport />
                </Route>
              </Switch>
            </Route>
          </Switch>
        </Text>
      </Col>
    </Row>
  ) : null;
};
