import styled from 'styled-components/macro';
import { ReactTable } from 'components';

export const StudentExperienceChildSurveyTableStyled = styled((props) => (
  <ReactTable {...props} />
))`
  th:first-child,
  td:first-child {
    min-width: 15%;
  }

  th:last-child,
  td:last-child {
    min-width: 10%;
  }
`;
