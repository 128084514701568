// Extracts a list of words that contain colons ("tokens"), and returns that
// along with the remaining text.
export const getTokensFromText = (
  rawText = '',
  definedTokens: string[] = [],
) => {
  const rawWords = rawText.split(' ');
  const tokens = rawWords.filter((w) => definedTokens.includes(w));
  const text = rawWords.filter((w) => !definedTokens.includes(w)).join(' ');
  return { text, tokens };
};
