import { Moment } from 'moment';
import urijs from 'urijs';

import Entity from 'services/Entity';
import { TRITON_URL_PREFIX } from './config';
import {
  fetchApi,
  fetchOptionsDELETE,
  fetchOptionsGET,
  fetchOptionsPOST,
  fetchOptionsPUT,
  fetchOptionsPATCH,
} from './helpers';

export interface CycleEntity extends Entity {
  start_date: Moment;
  end_date: Moment;
  extended_start_date: Moment;
  extended_end_date: Moment;
  ordinal: number;
}

export const queryByTeam = (teamId) => {
  const url = `${TRITON_URL_PREFIX}/api/teams/${teamId}/cycles`;
  const options = fetchOptionsGET();
  return fetchApi(url, options);
};

export const queryForUserByOrg = (userId, orgId) => {
  const options = fetchOptionsGET();

  const url = urijs(`${TRITON_URL_PREFIX}/api/users/${userId}/cycles`)
    .search({ organization: orgId })
    .toString();

  return fetchApi(url, options);
};

export const getByTeamCurrent = (teamId) => {
  const url = `${TRITON_URL_PREFIX}/api/teams/${teamId}/cycles/current`;
  const options = fetchOptionsGET();
  return fetchApi(url, options);
};

export const get = (cycleId) => {
  const url = `${TRITON_URL_PREFIX}/api/cycles/${cycleId}`;
  const options = fetchOptionsGET();
  return fetchApi(url, options);
};

export const post = (cycle) => {
  const url = `${TRITON_URL_PREFIX}/api/cycles?envelope=team_cycles`;
  const options = fetchOptionsPOST(cycle);
  return fetchApi(url, options);
};

export const update = (cycle) => {
  const url = `${TRITON_URL_PREFIX}/api/cycles/${cycle.uid}?envelope=team_cycles`;
  const options = fetchOptionsPUT(cycle);
  return fetchApi(url, options);
};

export const remove = (cycleId) => {
  const url = `${TRITON_URL_PREFIX}/api/cycles/${cycleId}?envelope=team_cycles`;
  const options = fetchOptionsDELETE();
  return fetchApi(url, options);
};

export const updateCycles = (cycles, teamId) => {
  const patchBody = cycles.map(({ team_id, uid, start_date, end_date }) => ({
    method: 'PUT',
    path: `/api/teams/${team_id}/cycles/${uid}`,
    body: { start_date, end_date },
  }));

  const url = `${TRITON_URL_PREFIX}/api/teams/${teamId}/cycles`;
  const options = fetchOptionsPATCH(patchBody);

  return fetchApi(url, options);
};
