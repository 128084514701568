import { SelectFilterSelected } from '../types';
import { SelectFilterTag } from './SelectFilterTag';
import { SelectFilterDivider } from './SelectFilterDivider';

type SelectFilterTagsProps = {
  /**
   * `null` and `[]` indicate nothing to display.
   */
  selected: SelectFilterSelected;

  /**
   * Optional. Maximum tags to display. Default 3.
   */
  max?: number;

  /**
   * Optional. Total number of options. If provided, then a
   * final "N more hidden..." tag will be added.
   */
  total?: number;

  /**
   * `true` if the label is hidden, so also hide the divider.
   */
  labelHide?: boolean;
};

export function SelectFilterTags(props: SelectFilterTagsProps) {
  const { selected, max = 3, total, labelHide } = props;
  const hidden = total && Array.isArray(selected) ? total - selected.length : 0;

  if (!selected || (Array.isArray(selected) && selected.length === 0)) {
    return null;
  }

  if (Array.isArray(selected)) {
    if (selected.length > max) {
      return (
        <>
          {!labelHide && <SelectFilterDivider />}
          <SelectFilterTag label={`${selected.length} selected`} />
        </>
      );
    }

    return (
      <>
        <SelectFilterDivider />
        {selected
          // NOTE: Might not be the optimal place to perform this filter, but
          // it's the quickest to get it done with the current use cases. This
          // is really only meant to be used with the Disaggregate By case. The
          // other cases may result in bugs.
          .filter((s) => s.name !== '(NONE)')
          .map((s) => (
            <SelectFilterTag key={s.value} label={s.name} color={s.color} />
          ))}
        {hidden > 0 && <SelectFilterTag label={`${hidden} others…`} />}
      </>
    );
  }

  return (
    <>
      {!labelHide && <SelectFilterDivider />}
      <SelectFilterTag label={selected.name} color={selected.color} />
    </>
  );
}
