import { AnyDataset, Metric } from 'models';

const normalizeLabel = (label) => label.replace(/_/g, '-');

export const removeDisabledMetricsFromDataset = <T extends AnyDataset>(
  dataset: T,
  enabledMetrics: Metric[],
) => {
  const enabledLabels = enabledMetrics.map((metric) => metric.label);

  return {
    ...dataset,
    student_experience_overview: dataset.student_experience_overview.filter(
      (row) => enabledLabels.includes(normalizeLabel(row.metric_label)),
    ),
    metrics: dataset.metrics.filter((metricData) =>
      enabledLabels.includes(normalizeLabel(metricData.label)),
    ),
  };
};
