import {
  Network,
  queryKeyNetwork,
  updateNetwork,
  useMutationUpdate,
  useNetworkGetByParams,
} from 'models';
import { useParams } from 'pages';

import { NetworkReportLoader } from '../NetworkReportDefault21';
import { ReportSettingsEnabledMetricsForm } from '../components';

export const NetworkReportSettings = () => {
  const { networkId } = useParams();
  const networkQuery = useNetworkGetByParams();

  const queryKey = queryKeyNetwork(networkId);
  const mutation = useMutationUpdate<Network, Network>(
    updateNetwork,
    queryKey,
    {
      optimisticallyUpdate: false,
      transformFormValues: (formValues) => {
        if (!networkQuery.isSuccess) {
          throw new Error('Network data not loaded.');
        }
        const network = networkQuery.data;
        const enabledIds = Object.entries(formValues)
          .filter(([metricId, isChecked]) => isChecked)
          .map(([metricId]) => metricId);
        return {
          ...network,
          report_settings: {
            ...network?.report_settings,
            enabled_metrics: enabledIds,
          },
        };
      },
      toastSuccessMsg: `Successfully updated report settings.`,
      toastErrorMsg: `There was a problem updating report settings.`,
    },
  );

  const onSubmit = async (formValues) => {
    await mutation.mutateAsync(formValues);
  };

  const metricSets = []; // need to load this?

  return (
    <NetworkReportLoader>
      {({
        dataset,
        network,
        program,
        programMetrics,
        reportDate,
        reportMetrics,
      }) => (
        <ReportSettingsEnabledMetricsForm
          onSubmit={onSubmit}
          enabledMetricIds={network.report_settings.enabled_metrics}
          reportMetrics={reportMetrics}
          metricSets={metricSets}
        />
      )}
    </NetworkReportLoader>
  );
};
