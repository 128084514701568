import moment from 'moment';
import { useMemo, useState } from 'react';
import { Parser as CsvParser } from 'json2csv';

import { Card, Row, Col, Button, Link, Show } from '@perts/ui';
import { getMessageFromErrors } from '@perts/util';

import {
  Class,
  Cycle,
  Participant,
  ParticipationData,
  Program,
  useParticipantsGetByClassId,
  useParticipationMultiCycle,
} from 'models';
import { ParticipationProgressTable } from './ParticipationProgressTable';
import Loading from 'components/Loading';
import { DashboardUpdatedFrequency, ErrorMessageBox } from 'components';
import { getProgressDescription } from './getProgressDescription';

const getDownloadURI = (data) => {
  // Transform object row into flat array for CSV format.
  const csvData = data.map((row) => ({
    studentId: row.studentId,
    ...Object.fromEntries(
      row.cycleProgress.map((progress, i) => [`Survey ${i + 1}`, progress]),
    ),
  }));
  return `data:text/csv;charset=utf-8,${encodeURIComponent(
    csvData && csvData.length > 0 ? new CsvParser().parse(csvData) : '',
  )}`;
};

// Table data.
type CycleProgressRow = {
  studentId: string;
  cycleProgress: string[];
};

export const getCycleProgressData = (
  participationDataAllCycles: ParticipationData[],
  participants: Participant[],
): CycleProgressRow[] => {
  if (participationDataAllCycles.length === 0 || participants.length === 0) {
    return [];
  }

  // Each set of participation results, one set for each cycle, should have its
  // own last_run property, and they should all be the same. Pick the first.
  const [{ last_run: lastRun = '' }] = participationDataAllCycles;

  return (participants || []).map((participant) => {
    // When we have no data for a participant, it may be because they haven't
    // done the survey OR it may be because the datawarehouse hasn't processed
    // this participant yet. Fill in appropriate baseline values.
    const pptIsNew = moment(lastRun).isBefore(moment(participant.created));
    const baseProgress = pptIsNew
      ? getProgressDescription(null)
      : getProgressDescription(0);
    const numCycles = participationDataAllCycles.length;
    const cycleProgress = Array<string>(numCycles).fill(baseProgress);

    participationDataAllCycles.forEach((ppnData, i) => {
      // Consider only responses for this participant.
      const pptResponses = ppnData.responses.filter(
        (row) => row.participant_id === participant.uid,
      );

      // Then overwrite cycles where we do have responses.
      for (const response of pptResponses) {
        cycleProgress[i] = getProgressDescription(
          parseInt(response.progress, 10),
        );
      }
    });
    return { studentId: participant.student_id, cycleProgress };
  });
};

type Props = {
  cls: Class;
  cycles: Cycle[];
  program: Program;
};

export const ParticipationMultiCycle = ({ cls, cycles, program }: Props) => {
  const cycleClassIds = new Set(cycles.map((c) => c.team_id));
  if (cycleClassIds.size > 1) {
    throw new Error('ParticipationMultiCycle: must filter cycles to 1 class.');
  }
  const [downloadActive, setDownloadActive] = useState(false);

  // Query for Participants associated with Class.
  const queryParticipants = useParticipantsGetByClassId(cls.uid);

  // Query for Participation by participant associated with all cycles for
  // this class. Returns data similar to useParticipationByParticipant, but with
  // a result set for every cycle.
  const participationQuery = useParticipationMultiCycle(cls.code, cycles);

  const lastRun = participationQuery.data?.[0]?.last_run || '';

  // One student Id column, then a column for each cycle.
  const columns = useMemo(
    () => [
      { Header: 'ID', accessor: 'studentId' },
      {
        Header: 'Survey',
        id: 'survey',
        defaultCanSort: false,
        columns: cycles.map((c) => ({
          Header: c.ordinal,
          id: `cycle-${c.ordinal}`,
          accessor: (row: CycleProgressRow) => row.cycleProgress[c.ordinal - 1],
        })),
      },
    ],
    [cycles],
  );

  const data = useMemo(
    () =>
      getCycleProgressData(
        participationQuery.data || [],
        queryParticipants.data || [],
      ),
    [participationQuery.data, queryParticipants.data],
  );

  // Display loading.
  if (queryParticipants.isLoading || participationQuery.isLoading) {
    return <Loading />;
  }

  // Display any errors.
  if (!queryParticipants.isSuccess || !participationQuery.isSuccess) {
    return (
      <ErrorMessageBox>
        {getMessageFromErrors([
          queryParticipants.error,
          participationQuery.error,
        ])}
      </ErrorMessageBox>
    );
  }

  const downloadName = `${cls.name}_Survey_Progress.csv`;

  return (
    <Card>
      <Card.Content>
        <Row>
          <Col>
            <Button
              onClick={() => setDownloadActive(!downloadActive)}
              disabled={downloadActive}
            >
              Download CSV
            </Button>
          </Col>
          <Col hAlign="flex-end" vAlign="center">
            <DashboardUpdatedFrequency dateString={lastRun} textAlign="right" />
          </Col>
        </Row>
        <Show when={downloadActive}>
          <Row>
            <Col hAlign="center">
              <Link to={getDownloadURI(data)} download={downloadName}>
                {downloadName}
              </Link>
            </Col>
          </Row>
        </Show>
      </Card.Content>
      <Card.Content>
        <ParticipationProgressTable columns={columns} data={data} />
      </Card.Content>
    </Card>
  );
};
