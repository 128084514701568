import { uniqBy } from 'lodash';

import { IconCube, Menu, Show } from '@perts/ui';

import {
  Program,
  selectUserIsAdmin,
  useCurrentUserQuery,
  useProgramsQuery,
  useProgramsQueryByUser,
} from 'models';
import { toHome, useParams } from 'pages';
import { ProgramMenuArchive } from './ProgramMenuArchive';

type ProgramMenuRenderProps = {
  programs: Program[];
};

const ProgramMenuRender = ({ programs }: ProgramMenuRenderProps) => (
  <Menu>
    {programs
      .filter((p) => p.active)
      .map((p) => (
        <Menu.Item
          key={p.uid}
          icon={<IconCube />}
          to={toHome(p.label)}
          iconLetter={p.name.charAt(0)}
        >
          {p.name}
        </Menu.Item>
      ))}
    <Show when={programs.filter((p) => !p.active).length > 0}>
      <ProgramMenuArchive />
    </Show>
  </Menu>
);

export const ProgramMenu = () => {
  const { programId } = useParams();

  const { data: allPrograms = [] } = useProgramsQuery();
  const { data: user } = useCurrentUserQuery();
  const { data: userPrograms = [] } = useProgramsQueryByUser({
    enabled: user && !selectUserIsAdmin(user),
  });

  // Display no menu, if no user.
  if (!user) {
    return null;
  }

  // Make sure to include programs specified in the route, even if they aren't
  // yet associated to the user.
  const routeProgram = allPrograms.find((p) => p.uid === programId);
  const renderPrograms = uniqBy(
    [...userPrograms, ...(routeProgram ? [routeProgram] : [])],
    'uid',
  );

  return (
    <ProgramMenuRender
      programs={selectUserIsAdmin(user) ? allPrograms : renderPrograms}
    />
  );
};
