// https://docs.google.com/spreadsheets/d/1ZX4hcUol-_frFLfUYRnZ-UPEvpGsVOsWsJRw1g5tDBM

import keyBy from 'lodash/keyBy';

import { CompositeMetricConfig, MetricItemConfig } from './types';
import { catalyze23AnswerOptions } from './answerOptions';

export const trustingCommunity23ItemConfig: MetricItemConfig[] = [
  {
    label: 'trusting_community_respect',
    likertN: 5,
    minGood: 4,
    options: catalyze23AnswerOptions,
    prompts: {
      en: 'I feel respected.',
    },
  },
  {
    label: 'trusting_community_connected',
    likertN: 5,
    minGood: 4,
    options: catalyze23AnswerOptions,
    prompts: {
      en: 'I feel connected to my colleagues.',
    },
  },
  {
    label: 'trusting_community_good',
    likertN: 5,
    minGood: 4,
    options: catalyze23AnswerOptions,
    prompts: {
      en: 'I am confident everyone is good at their job. ',
    },
  },
  {
    label: 'trusting_community_collaboration',
    likertN: 5,
    minGood: 4,
    options: catalyze23AnswerOptions,
    prompts: {
      en: 'There is a great deal of collaboration among everyone.',
    },
  },
  {
    label: 'trusting_community_trust',
    likertN: 5,
    minGood: 4,
    options: catalyze23AnswerOptions,
    prompts: {
      en: 'People build trust with one another through actions, not just words.',
    },
  },
  {
    label: 'trusting_community_care',
    likertN: 5,
    minGood: 4,
    options: catalyze23AnswerOptions,
    prompts: {
      en: 'Leaders demonstrate care for everyone’s well-being.',
    },
  },
];

export const trustingCommunity23Config: CompositeMetricConfig = {
  label: 'trusting-community',
  items: trustingCommunity23ItemConfig,
  itemIndex: keyBy(trustingCommunity23ItemConfig, 'label'),
};
