import { useParams } from 'react-router-dom';
import { GuideBox, HelpText, Link } from '@perts/ui';
import { PagesParams, toGroupClasses, toGroupSurveys } from 'pages';
import { useTerms } from 'components/TermsContext';
import { helpArticles } from 'config';

export const SchedulesGuideBox = ({ isClasses = false }) => {
  const { groupId = '' } = useParams<PagesParams>();
  const terms = useTerms();

  return (
    <GuideBox
      action={
        isClasses ? (
          <span>Set your survey schedule above.</span>
        ) : (
          <Link to={toGroupClasses(groupId)}>
            Create {terms.aClass} then return to Schedules to set a survey
            schedule.
          </Link>
        )
      }
      faq={
        isClasses ? (
          <HelpText articleId={helpArticles.guideboxSchedules}>
            How do I create a survey schedule?
          </HelpText>
        ) : null
      }
      next={<Link to={toGroupSurveys(groupId)}>Surveys</Link>}
    />
  );
};
