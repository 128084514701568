import { useState } from 'react';
import styled from 'styled-components/macro';
import { SelectFilter, SelectFilterSelected } from '@perts/ui';
import { theme } from 'components';

const CustomLegendSpacer = styled.div`
  margin-top: ${theme.units.padding};
`;

export const CustomLegend = ({ chartInstance }) => {
  const datasets = chartInstance?.data.datasets || [];

  const options = datasets.map((d, datasetIndex) => ({
    value: d.childLabel,
    name: d.childName,
    color: d.borderColor,

    // Used to query the `hidden` value from chartInstance.
    datasetIndex,
  }));

  const initialState: SelectFilterSelected = options.filter((opt) => {
    // The initial visible/hidden state is sourced from chartInstance, so you'll
    // want to update the data.datasets[].hidden value provided to the Chart
    // component to affect this state.

    const optionMeta = chartInstance?.getDatasetMeta(opt.datasetIndex);
    // optionMeta.hidden is null here, regardless of how it's set in the
    // options, until the value has changed, so using visible here instead.
    return optionMeta.visible;
  });

  const [filters, setFilters] = useState<SelectFilterSelected>(initialState);

  const setDataVisibility = (value: SelectFilterSelected) => {
    if (!Array.isArray(value)) {
      return;
    }

    setFilters(value);

    options.forEach((opt) => {
      const optionMeta = chartInstance?.getDatasetMeta(opt.datasetIndex);
      optionMeta.hidden = !Boolean(value.find((v) => v.value === opt.value));
    });

    chartInstance.update();
  };

  return (
    <>
      <SelectFilter
        multiple
        legend
        label="Show"
        options={options}
        selected={filters}
        setSelected={setDataVisibility}
      />
      <CustomLegendSpacer />
    </>
  );
};
