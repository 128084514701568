import styled from 'styled-components/macro';
import { css } from 'styled-components';
import { theme } from '../../';

const EMBEDDED_ADJUST_LEFT = 3;
const EMBEDDED_ADJUST_RIGHT = 1;

type CardStyledProps = {
  to?: string;
  checked?: boolean;
  archived?: boolean;
  highlight?: boolean;
  highlightWithin?: boolean;
  isExpanded?: boolean;
};

export const CardStyled = styled.div<CardStyledProps>`
  position: relative;

  border: 1px solid ${theme.colors.grayLight};
  border-radius: ${theme.units.borderRadius};

  ${(props) =>
    props.archived &&
    css`
      border: 1px dashed ${theme.colors.grayMedium};
    `}

  background-color: ${theme.colors.white};

  margin-bottom: ${theme.units.paddingLg};
  padding: ${theme.units.paddingLg};

  ${(props) =>
    props.highlight &&
    css`
      border-left: 4px solid ${theme.colors.primary};

      // Adjust for left border so content will align w/ non-highlighted Cards.
      margin-left: -${EMBEDDED_ADJUST_LEFT}px;
    `};

  ${(props) =>
    props.highlight &&
    props.highlightWithin &&
    css`
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;

      // Adjust the placement of the Card > Card (highlighted) so that the left
      // and right borders align with the parent borders.
      margin-left: -${`${parseInt(theme.units.paddingLg.replace(/px/, ''), 10) + EMBEDDED_ADJUST_LEFT}px`};
      margin-right: -${`${parseInt(theme.units.paddingLg.replace(/px/, ''), 10) + EMBEDDED_ADJUST_RIGHT}px`};
    `};

  /* Padding for narrower/mobile screens. */
  @media only screen and (max-width: ${theme.units.gridBreakpointMedium}px),
    only screen and (max-height: ${theme.units.gridBreakpointMedium}px) {
    margin-bottom: ${theme.units.paddingMd};
    padding: ${theme.units.paddingMd};

    ${(props) =>
      props.highlight &&
      props.highlightWithin &&
      css`
        margin-left: -${`${parseInt(theme.units.paddingMd.replace(/px/, ''), 10) + 3}px`};
        margin-right: -${`${parseInt(theme.units.paddingMd.replace(/px/, ''), 10) + 1}px`};
      `};
  }
  @media only screen and (max-width: ${theme.units.gridBreakpointSmall}px),
    only screen and (max-height: ${theme.units.gridBreakpointSmall}px) {
    margin-bottom: ${theme.units.paddingSm};
    padding: ${theme.units.paddingSm};

    ${(props) =>
      props.highlight &&
      props.highlightWithin &&
      css`
        margin-left: -${`${parseInt(theme.units.paddingSm.replace(/px/, ''), 10) + 3}px`};
        margin-right: -${`${parseInt(theme.units.paddingSm.replace(/px/, ''), 10) + 1}px`};
      `};
  }

  /* Margin between Card children */
  & > :not(:first-child) {
    margin-top: ${theme.units.paddingLg};
    @media only screen and (max-width: ${theme.units.gridBreakpointMedium}px),
      only screen and (max-height: ${theme.units.gridBreakpointMedium}px) {
      margin-top: ${theme.units.paddingMd};
    }
    @media only screen and (max-width: ${theme.units.gridBreakpointSmall}px),
      only screen and (max-height: ${theme.units.gridBreakpointSmall}px) {
      margin-top: ${theme.units.paddingSm};
    }
  }

  ${({ to }) =>
    Boolean(to) &&
    css`
      cursor: pointer;
    `}

  ${({ checked }) =>
    Boolean(checked) &&
    css`
      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 5px;
        background: ${theme.colors.primary};

        @media print {
          -webkit-print-color-adjust: exact;
          print-color-adjust: exact;
        }
      }
    `}
`;

// Ensures a column of whitespace (a "gutter") on the left of each card for
// the toggle/checkbox.
export const CardGutterStyled = styled.div`
  flex: none;

  width: ${theme.units.checkboxSize};

  margin-right: ${theme.units.paddingLg};

  /* Padding for narrower/mobile screens. */
  @media only screen and (max-width: ${theme.units.gridBreakpointMedium}px) {
    margin-right: ${theme.units.paddingMd};
  }
  @media only screen and (max-width: ${theme.units.gridBreakpointSmall}px) {
    margin-right: ${theme.units.paddingSm};
  }

  // Make the checkbox container the standard height of the title row for the
  // card. This allows vertical centering across the visual row even though the
  // checkbox and Card.Title are in different containers.
  label {
    height: ${theme.units.lineHeightTitle};
  }
`;
