import React, { forwardRef } from 'react';
import { Menu } from '@headlessui/react';
import { SelectFilterIndicator } from './SelectFilterIndicator';
import {
  SelectFilterOption,
  SelectFilterOptions,
  SelectFilterSelected,
} from '../types';
import { SelectFilterMenuOptionName } from './SelectFilterMenuOptionName';

type SelectFilterMenuOptionsProps = {
  options: SelectFilterOptions;
  selected: SelectFilterSelected;
  setSelected: (val: SelectFilterSelected) => void;
  multiple?: boolean;
};

const SelectFilterMenuOptionsRef = (
  props: SelectFilterMenuOptionsProps,
  ref: any,
) => {
  const { options, selected, setSelected, multiple } = props;

  const isAnySelected =
    (Array.isArray(selected) && selected.length > 0) ||
    (!Array.isArray(selected) && selected !== null);

  const onClickItem = (
    event: React.SyntheticEvent,
    value: SelectFilterSelected,
  ) => {
    // If `multiple`, don't close menu on click.
    if (multiple) {
      event.preventDefault();
    }

    if (!multiple) {
      setSelected(value);
      return;
    }

    if (Array.isArray(selected)) {
      if (value === null || Array.isArray(value)) {
        // These aren't valid in the `multiple` case.
        return;
      }

      // Remove value if already selected.
      const found = selected.find((s) => s.value === value.value);
      if (found) {
        const removed = selected.filter((s) => s.value !== value.value);
        setSelected(removed);
        return;
      }

      // Add value if it is not selected.
      setSelected([...selected, value]);
    }
  };

  const onClickClear = (event: React.SyntheticEvent) => {
    // If `multiple`, don't close menu on click.
    if (multiple) {
      event.preventDefault();
    }

    setSelected(multiple ? [] : null);
  };

  const isSelected = (option: SelectFilterOption) => {
    if (multiple && Array.isArray(selected)) {
      return Boolean(selected.find((s) => s.value === option.value));
    }

    if (selected && !Array.isArray(selected)) {
      return selected.value === option.value;
    }

    return false;
  };

  return (
    <Menu.Items ref={ref} as="ul">
      {options.map((option, optionIndex) => {
        const isNewCategory =
          !options[optionIndex - 1] ||
          options[optionIndex - 1].category !== option.category;

        return (
          <React.Fragment key={optionIndex}>
            {isNewCategory && <div>{option.category}</div>}

            <Menu.Item
              key={optionIndex}
              as="li"
              onClick={(event: React.SyntheticEvent) =>
                onClickItem(event, option)
              }
            >
              <>
                <SelectFilterIndicator
                  multiple={multiple}
                  selected={isSelected(option)}
                  color={option.color}
                />

                <SelectFilterMenuOptionName
                  className={selected === option ? 'active' : ''}
                >
                  {option.name}
                </SelectFilterMenuOptionName>
              </>
            </Menu.Item>
          </React.Fragment>
        );
      })}

      {multiple && isAnySelected && (
        <Menu.Item className="clear" as="li" onClick={onClickClear}>
          <div style={{ height: '20px' }} />
          <span>Clear Filters</span>
        </Menu.Item>
      )}
    </Menu.Items>
  );
};

export const SelectFilterMenuOptions = forwardRef<
  any,
  SelectFilterMenuOptionsProps
>(SelectFilterMenuOptionsRef);
