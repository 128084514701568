import { useHistory, useParams } from 'react-router-dom';

import {
  Actionbar,
  Button,
  Col,
  IconUserVoice,
  IconChevronRight,
  Row,
  Show,
} from '@perts/ui';

import { DashboardUpdatedFrequency } from 'components';
import { toGroupParticipationByParticipant, PagesParams } from 'pages';

import { useTerms } from 'components/TermsContext';

type Props = {
  lastRunDate: string;
  mayViewByParticipant: boolean;
};

export const ParticipationActionbar = ({
  lastRunDate,
  mayViewByParticipant,
}: Props) => {
  const { groupId } = useParams<PagesParams>();
  const history = useHistory();
  const terms = useTerms();

  const openParticipationByParticipant = () => {
    history.push(toGroupParticipationByParticipant(groupId));
  };

  return (
    <Actionbar>
      <Row>
        <Col hAlign="flex-end" vAlign="center">
          <DashboardUpdatedFrequency dateString={lastRunDate} />
        </Col>
        <Show when={mayViewByParticipant}>
          <Col hAlign="flex-end" shrink>
            <Button
              iconRight={<IconChevronRight />}
              iconLeft={<IconUserVoice />}
              color="secondary"
              onClick={openParticipationByParticipant}
            >
              View by {terms.participant}
            </Button>
          </Col>
        </Show>
      </Row>
    </Actionbar>
  );
};
