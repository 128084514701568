import { useCallback, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { useHistoryRetain } from './useHistoryRetain';

type UseCloseModalArgs = {
  /**
   * Optional, path to route to when modal is closed. Default `./`.
   */
  pathname?: string;

  /**
   * Default `false`. `true` if do not want modals to auto close when location
   * state is empty. We auto close because many modals rely on location state
   * and cannot properly function without it.
   */
  ignoreEmptyState?: boolean;
};

const defaultArgs = {
  pathname: undefined,
  ignoreEmptyState: false,
};

const useCloseModal = ({
  pathname,
  ignoreEmptyState = false,
}: UseCloseModalArgs = defaultArgs) => {
  const { state } = useLocation();
  const { replace } = useHistoryRetain();

  const closeModal = useCallback(() => {
    replace(
      // Route to `pathname` or fallback to parent route.
      pathname || './',
      // Retain state (like checked status)
      state,
    );
  }, [replace, pathname, state]);

  // Close the modal if opened with no location state (liked checked items).
  useEffect(() => {
    if (isEmpty(state) && !ignoreEmptyState) {
      closeModal();
    }
  }, [state, ignoreEmptyState, closeModal]);

  return closeModal;
};

export default useCloseModal;
