import { message } from 'antd';
import { IconButton, IconCopy } from '../..';

type CopyButtonProps = {
  /**
   * `true` if the button is disabled.
   */

  disabled?: boolean;

  /**
   * String to copy in clipboard.
   */

  textToCopy: string;

  /**
   * Message to display in toast after copy.
   */

  successMessage?: string;
};

export const CopyButton = ({
  disabled,
  textToCopy,
  successMessage = 'Text copied',
  ...rest
}: CopyButtonProps) => {
  const handleCopyLink = () => {
    navigator.clipboard.writeText(textToCopy);
    message.success(successMessage);
  };

  return (
    <IconButton
      aria-label="Click to copy"
      icon={<IconCopy />}
      onClick={handleCopyLink}
      disabled={disabled}
      {...rest}
    />
  );
};
