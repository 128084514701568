import { ToggleStates } from 'utils/useToggles';

export const validateSurveyMetrics = (values: ToggleStates) => {
  const errors: { [fieldName: string]: string } = {};

  if ('metricsEnabled' in values && !values.metricsEnabled) {
    // Don't enforce selecting metrics if user has disabled default metrics.
    return errors;
  }

  if (
    !Object.values(values).some(
      (metric) => metric.checked || metric.indeterminate,
    )
  ) {
    errors.metrics = 'Please choose at least one condition.';
  }

  return errors;
};
