import { Button } from '@perts/ui';

export const BulkSelectMessage = ({
  totalSelected = 0,
  totalItems = 0,
  toggleAll,
}) => {
  if (totalSelected >= totalItems) {
    return (
      <Button color="secondary" onClick={toggleAll} fullWidth>
        Deselect All
      </Button>
    );
  }

  if (totalSelected > 0) {
    return (
      <Button color="secondary" onClick={toggleAll} fullWidth>
        Select All {totalItems}
      </Button>
    );
  }

  return null;
};
