import { InfoBox } from '@perts/ui';

export type NetworksRemoveFormMessageProps = {
  networkName: string;
};

export const NetworksRemoveFormMessage = ({
  networkName,
}: NetworksRemoveFormMessageProps) => (
  <InfoBox color="warning">
    Are you sure you want to delete Network {networkName}?
  </InfoBox>
);
