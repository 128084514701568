import React from 'react';
import classNames from 'classnames';
import styled from 'styled-components/macro';
import { css } from 'styled-components';
import theme from '../theme';

import { InputField } from '../InputField';
import { InputFieldError } from '../InputFieldError';
import InputFieldLabel from '../InputFieldLabel';

export type TextFieldProps = {
  // To allow styled-components wrapping.
  className?: string;

  // If true, the input is disabled.
  disabled?: boolean;

  // If false, the error field won't be displayed.
  displayError?: boolean;

  // Field error.
  error?: any;

  // Help node.
  helpText?: React.ReactNode;

  // The id of the input element.
  id: string;

  // The label of the input element.
  label: any;

  // Where to position the label. Default 'top'.
  labelPlacement?: 'top' | 'start';

  // If true, the input is a multiline textarea.
  multiline?: boolean;

  // The name of the input element.
  name: string;

  // The placeholder of the input element.
  placeholder?: string;

  // onBlur handler.
  onBlur?: (event: React.FocusEvent<any>) => void;

  // onChange handler.
  onChange: (event: React.FormEvent<HTMLInputElement>) => void;

  // onKeyDown handler.
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;

  // onPaste handler.
  onPaste?: (event: React.ClipboardEvent<HTMLInputElement>) => void;

  // Deprecated. Styles still exist.
  readOnly?: boolean;

  // If true, UI indicates the field is required. Validation isn't performed.
  required?: boolean;

  // Number of rows the multiline/textarea should be.
  rows?: string | number;

  // Optional content to display between label and field.
  sublabel?: React.ReactNode;

  // E.g. "search" for search and filter fields.
  type?: string;

  // The current value of the input.
  value: string;

  // It true, the input was touched.
  touched?: boolean;
};

export const TextField = ({
  sublabel = null,
  className,
  disabled,
  displayError = true,
  error,
  helpText,
  id,
  label,
  labelPlacement = 'top',
  multiline,
  name,
  required,
  touched,
  ...props
}: TextFieldProps) => {
  const cx = classNames(className);
  const isError = error && touched;

  return (
    <InputField className={cx} labelPlacement={labelPlacement}>
      <LabelContainer>
        {label && <InputFieldLabel htmlFor={id}>{label}</InputFieldLabel>}
        {helpText && <HelpText>{helpText}</HelpText>}
      </LabelContainer>

      {sublabel}

      <TextFieldContainer labelPlacement={labelPlacement}>
        <TextFieldStyled
          disabled={disabled}
          error={isError}
          id={id}
          label={label}
          as={multiline ? 'textarea' : 'input'}
          name={name}
          aria-describedby={`${name}-text-field-error`}
          {...props}
        />
        {required && <TextFieldRequired />}
      </TextFieldContainer>

      {displayError && (
        <InputFieldError
          role="alert"
          aria-live="assertive"
          id={`${name}-text-field-error`}
          data-testid={`${name}-text-field-error`}
        >
          {error}
        </InputFieldError>
      )}
    </InputField>
  );
};

const TextFieldStyled = styled.input<TextFieldProps>`
  display: flex;
  flex-direction: row;

  width: 100%;
  padding: ${(props) => props.theme.units.paddingSm};

  border: 1px solid ${(props) => props.theme.colors.grayMedium};
  border-radius: ${(props) => props.theme.units.borderRadius};

  ${(props) =>
    props.rows === undefined &&
    css`
      height: ${props.theme.units.fieldHeight};
    `};

  ${(props) =>
    props.disabled &&
    css`
      background: ${props.theme.colors.fieldBackgroundDisabled};
      color: ${props.theme.colors.text};

      border: 1px dashed ${props.theme.colors.grayMedium};

      cursor: not-allowed;
    `};

  ${(props) =>
    props.readOnly &&
    css`
      color: ${props.theme.colors.textDisabled};

      border: 1px dashed ${props.theme.colors.grayMedium};
    `};

  ${(props) =>
    props.error &&
    css`
      border-color: ${props.theme.colors.warning};
    `};

  &:focus {
    outline: 0;
    box-shadow: 0 0 0 5px ${(props) => props.theme.colors.primaryLight};
  }

  // Restore the cancel button that is removed by our baseline styles.
  // This is a non-standard feature that only exists in Chrome.
  &[type='search']::-webkit-search-cancel-button {
    -webkit-appearance: searchfield-cancel-button;
  }
`;

TextFieldStyled.defaultProps = {
  theme,
};

const TextFieldContainer = styled.div<Pick<TextFieldProps, 'labelPlacement'>>`
  position: relative;

  ${(props) =>
    props.labelPlacement === 'start' &&
    css`
      flex-grow: 1;
    `}
`;

const TextFieldRequired = styled.span`
  position: absolute;
  top: 6px;
  right: 6px;
  height: 8px;
  width: 8px;
  border-radius: 50%;
  background: ${(props) => props.theme.colors.warning};
  content: '';
`;

TextFieldRequired.defaultProps = {
  theme,
};

const LabelContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
`;

const HelpContainer = styled.div`
  position: relative;
  display: inline-flex;
  padding-left: 30px;
  align-items: center;
  font-size: 0.9rem;
  line-height: 1em;
  margin-bottom: ${(props) => props.theme.units.fieldPaddingInternal};
  cursor: pointer;
`;

HelpContainer.defaultProps = {
  theme,
};

const HelpText = styled.div`
  display: flex;
  margin-left: ${theme.units.fieldPaddingInternal};
  margin-bottom: ${theme.units.fieldPaddingInternal};
`;
