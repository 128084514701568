import Loading from 'components/Loading';
import { Card } from '@perts/ui';
import { programCatalyzePattern } from 'config';
import { useClassesQueryByParams, useGroupGetByParams } from 'models';
import { useParams } from 'pages';
import { ErrorMessageBox } from 'components/ErrorMessageBox';
import { useTerms } from 'components/TermsContext';
import { SurveyInstructionsRender } from './SurveyInstructionsRender';
import { SurveyInstructionsRenderCatalyze21 } from './SurveyInstructionsRenderCatalyze21';
import { getMessageFromErrors } from '@perts/util';

export const SurveyInstructions = () => {
  const { classId } = useParams();
  const terms = useTerms();

  // Query for group
  const groupQuery = useGroupGetByParams();

  // Query for classes
  const classesQuery = useClassesQueryByParams();

  if (groupQuery.isLoading || classesQuery.isLoading) {
    return <Loading />;
  }

  // Display any errors.
  if (!groupQuery.isSuccess || !classesQuery.isSuccess) {
    return (
      <ErrorMessageBox>
        {getMessageFromErrors([classesQuery.error])}
      </ErrorMessageBox>
    );
  }

  const group = groupQuery.data;
  const classes = classesQuery.data;

  if (classes && classes.length === 0) {
    return (
      <Card>
        <Card.Content>
          No {terms.classes.toLowerCase()} found for this{' '}
          {terms.group.toLowerCase()}.
        </Card.Content>
      </Card>
    );
  }

  const matchingClasses = classId
    ? classes.filter((cls) => cls.uid === classId)
    : classes;

  if (programCatalyzePattern.test(group.program_id)) {
    return <SurveyInstructionsRenderCatalyze21 classes={matchingClasses} />;
  }
  return <SurveyInstructionsRender classes={matchingClasses} />;
};
