import { useQuery } from 'react-query';
import {
  Prompt,
  promptsQueryByUserAndRoute,
  selectUserIsAdmin,
  useCurrentUserQuery,
} from 'models';

export const queryKeyPrompts = (route: string) => [route];

export const usePromptsQuery = (route: string, queryOptions = {}) => {
  const { data: user } = useCurrentUserQuery();
  const userId = user?.uid || '';

  return useQuery<Prompt[], Error>(
    // queryKey
    queryKeyPrompts(route),
    // queryFn
    () => promptsQueryByUserAndRoute(userId, route),
    // queryOptions
    {
      ...queryOptions,
      // Do not attempt to load prompts until the user is loaded.
      // Do not display prompts for PERTS admins.
      enabled: Boolean(userId) && !selectUserIsAdmin(user),
    },
  );
};
