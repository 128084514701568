import React from 'react';

import { theme, Show } from '@perts/ui';
import { StudentExperienceOverviewRow } from 'models';
import { ChangeText } from '../components';
import colorWhen from 'components/colorWhen';

const EQUITY_GAP_THRESHOLD = 10;

// We want to users to focus on large differences within groups of a subset,
// like if female students report significantly less positive experiences than
// male students.
const SubsetDifferenceWarning = colorWhen([
  {
    ariaLabel: 'Highlighted equity gap',
    color: theme.colors.textColorRedOnRed,
    backgroundColor: theme.colors.textBgRed,
    when: (x) => x >= EQUITY_GAP_THRESHOLD,
  },
]);

// What's in props?
// https://github.com/tannerlinsley/react-table/blob/master/docs/api/useTable.md#user-content-column-object-cell

// What's in a row?
// https://github.com/tannerlinsley/react-table/blob/master/docs/api/useTable.md#user-content-row-properties

type CellProps = {
  row: { original: StudentExperienceOverviewRow };
};

export const cellRatedPositivelyGaps =
  (key, compareKey, showChange) =>
  ({ row }: CellProps) => {
    const rp = 'rated_positive';
    const recentDisplay = row.original[`${rp}_recent_${key}`] || '';
    const recentNum = row.original[`${rp}_recent_${key}_num`] || 0;
    const compareNum = row.original[`${rp}_recent_${compareKey}_num`] || 0;
    const changeDisplay = row.original[`${rp}_change_${key}`] || '';
    const changeNum = row.original[`${rp}_change_${key}_num`] || 0;

    return (
      <>
        <SubsetDifferenceWarning data={compareNum - recentNum}>
          {recentDisplay}
        </SubsetDifferenceWarning>
        <Show when={showChange}>
          <ChangeText data={changeNum}>{changeDisplay}</ChangeText>
        </Show>
      </>
    );
  };

export const EquityGapLegend = () => (
  <p>
    Equity gaps &ge;{' '}
    <SubsetDifferenceWarning data={EQUITY_GAP_THRESHOLD}>
      {EQUITY_GAP_THRESHOLD}
    </SubsetDifferenceWarning>{' '}
    percentage points are highlighted.
  </p>
);
