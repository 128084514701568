import { useContext } from 'react';
import {
  IconBubbles,
  IconReport,
  IconUsergroup,
  IconConversation,
  Menu,
} from '@perts/ui';

import { useParams } from 'models';
import {
  isActiveMenuReports,
  toNetworkGroups,
  toNetworkMembers,
  toNetworkParticipation,
  toNetworkReport,
} from 'pages';

import TermsContext from 'components/TermsContext';

export const NetworkPagesMenu = () => {
  const { networkId } = useParams();

  const terms = useContext(TermsContext);
  const termsLoaded = terms.groupMembers && terms.groups;

  if (!termsLoaded) {
    return null;
  }

  return (
    <Menu>
      <Menu.Item icon={<IconUsergroup />} to={toNetworkMembers(networkId)}>
        {terms.groupMembers}
      </Menu.Item>

      <Menu.Item icon={<IconBubbles />} to={toNetworkGroups(networkId)}>
        {terms.groups}
      </Menu.Item>

      <Menu.Item
        icon={<IconConversation />}
        to={toNetworkParticipation(networkId)}
      >
        Participation
      </Menu.Item>

      <Menu.Item
        icon={<IconReport />}
        to={toNetworkReport(networkId)}
        isActive={isActiveMenuReports}
        exact
      >
        Report
      </Menu.Item>
    </Menu>
  );
};
