import styled from 'styled-components/macro';
import theme from 'components/theme';

import { useCurrentUserQuery } from 'models';
import { useImpersonate } from 'utils/useImpersonate';
import FakeLink from 'components/FakeLink';

const ImpersonateUserBannerStyled = styled.div`
  margin-left: ${theme.units.appSidebarWidth};

  @media (max-width: ${theme.units.menuCollapseWidth}px) {
    margin-left: ${theme.units.appSidebarWidthCollapsed};
  }

  padding: ${theme.units.paddingSm};

  background: ${theme.colors.warning};
  color: ${theme.colors.white};

  text-align: center;
  font-weight: 600;

  > :not(:first-child) {
    margin-left: ${theme.units.paddingSm};
  }

  /* Apply underline to FakeLink link. */
  button {
    text-decoration: underline;
  }
`;

export const ImpersonateUserBanner = () => {
  const { getImpersonateUid, setImpersonateUid } = useImpersonate();
  const { data: user } = useCurrentUserQuery();

  const impersonateUid = getImpersonateUid();

  if (!impersonateUid || !user) {
    return null;
  }

  const stopImitatingUser = () => setImpersonateUid(null);

  return (
    <ImpersonateUserBannerStyled>
      <span>You are impersonating {user.email}</span>
      <FakeLink onClick={stopImitatingUser}>Stop</FakeLink>
    </ImpersonateUserBannerStyled>
  );
};
