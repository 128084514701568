import { useFormikContext } from 'formik';
import * as Yup from 'yup';
import { Modal, Row, Col, Button, Input, Select } from '@perts/ui';
import { optionsRace } from './optionsRace';
import { optionsGender } from './optionsGender';
import { TextFieldOther } from './TextFieldOther';

export const initialValues = {
  race: '',
  gender: '',
  // Note: If you would like to always include these question/answers in the
  // response that is submitted (even when the corresponding race/gender fields
  // have not been selected), then you can uncomment these fields here.
  // race_other: '',
  // gender_other: '',
};

export const validationSchema = Yup.object().shape({
  race: Yup.string().required(),
  race_other: Yup.string().when('race', {
    is: (race) => ['mixed', 'other'].includes(race),
    then: (schema) => schema.required('Required.'),
  }),
  gender: Yup.string().required(),
  gender_other: Yup.string().when('gender', {
    is: (gender) => ['other'].includes(gender),
    then: (schema) => schema.required('Required.'),
  }),
});

export const Component = () => {
  const { isSubmitting, isValid, dirty } = useFormikContext();

  return (
    <>
      <Modal.Title className="center">Ascend Demographics Prompt</Modal.Title>
      <Row>
        <Col>
          PERTS is doing research to ensure that Ascend serves all faculty and
          staff in an unbiased manner. Please help us by answering these two
          questions. Your response is optional and will be kept confidential.
        </Col>
      </Row>

      <Row>
        <Col>
          <Input
            id="race"
            name="race"
            label="With what race or ethnicity do you most strongly identify?"
            labelPlacement="top"
            options={optionsRace}
            component={Select}
            placeholder="Select a race or ethnicity"
            keyBy="value"
            keyName="label"
            disabled={isSubmitting}
            fullWidth
            displayErrors={false}
          />
        </Col>
      </Row>

      <TextFieldOther
        name="race_other"
        label="Please specify"
        disabled={isSubmitting}
        when={(values) => ['mixed', 'other'].includes(values.race)}
      />

      <Row>
        <Col>
          <Input
            id="gender"
            name="gender"
            label="What is your gender identity?"
            labelPlacement="top"
            options={optionsGender}
            component={Select}
            placeholder="Select a gender"
            keyBy="value"
            keyName="label"
            disabled={isSubmitting}
            fullWidth
            displayErrors={false}
          />
        </Col>
      </Row>

      <TextFieldOther
        name="gender_other"
        label="Please specify"
        disabled={isSubmitting}
        when={(values) => ['other'].includes(values.gender)}
      />

      <Row>
        <Col>
          <Button
            type="submit"
            fullWidth
            disabled={!isValid || isSubmitting || !dirty}
            loading={isSubmitting}
            data-testid="submit-btn"
          >
            Submit Response
          </Button>
        </Col>
      </Row>
    </>
  );
};
