import { message } from 'antd';
import { useQueryClient, useMutation } from 'react-query';

import { getAuthUser } from 'services/triton/helpers/getAuthUser';
import {
  transformUsersRemoveNetwork,
  queryKeyCurrentUser,
  queryKeyUsersByNetwork,
  queryKeyNetworksByProgramGeneric,
  updateUsers,
  useCurrentUserQuery,
  User,
  useUsersQueryByNetworkId,
} from 'models';
import { toHomeNoProgram, useParams } from 'pages';
import { useCloseModal, useGetCheckedStates } from 'utils';

import Loading from 'components/Loading';
import { ErrorMessageBox } from 'components/ErrorMessageBox';

import { NetworkMembersRemoveForm } from './NetworkMembersRemoveForm';
import { useTerms } from 'components/TermsContext';
import { getMessageFromErrors } from '@perts/util';

export const NetworkMembersRemove = () => {
  const { networkId } = useParams();
  const queryKeyNetworkUsers = queryKeyUsersByNetwork(networkId);
  const terms = useTerms();
  const checked = useGetCheckedStates();

  // Close the modal.
  const closeModal = useCloseModal();
  const closeModalHome = useCloseModal({ pathname: toHomeNoProgram() });
  const close = ({ redirectToHome }) =>
    redirectToHome ? closeModalHome() : closeModal();

  // Query for Users of Network.
  const usersQuery = useUsersQueryByNetworkId(networkId);
  const currentUserQuery = useCurrentUserQuery();

  const members = usersQuery.data || [];

  // Determine network members to remove.
  const membersToRemove = members.filter((member) => checked[member.uid]);

  const membersToRemoveIncludesAllMembers =
    members.length === membersToRemove.length;

  const membersToRemoveIncludesSelf = membersToRemove.some(
    (member) => member.uid === getAuthUser().uid,
  );

  const toastSuccessMsg = `Successfully removed ${terms.networkMembers.toLowerCase()}(s) from the network.`;
  const toastErrorMsg = `There was a problem removing ${terms.networkMembers.toLowerCase()}(s) from the network.`;

  // Mutation: Remove users from network.
  const queryClient = useQueryClient();
  const mutation = useMutation(updateUsers, {
    onMutate: async (updatedUsers: User[]) => {
      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries(queryKeyNetworkUsers);
      await queryClient.cancelQueries(queryKeyCurrentUser());

      // Snapshot the previous value.
      const previousNetworkUsers =
        queryClient.getQueryData<User[]>(queryKeyNetworkUsers);
      const previousCurrentUser = queryClient.getQueryData<User>(
        queryKeyCurrentUser(),
      );

      // Update: remove deleted items from cache.
      const idsToDelete = updatedUsers.map((u) => u.uid);
      const optimisticNetworkUsers = previousNetworkUsers
        ? previousNetworkUsers.filter((u) => !idsToDelete.includes(u.uid))
        : [];
      const optimisticCurrentUser = updatedUsers.find(
        (u) => u.uid === currentUserQuery.data?.uid,
      );

      queryClient.setQueryData<User[]>(
        queryKeyNetworkUsers,
        optimisticNetworkUsers,
      );
      if (optimisticCurrentUser) {
        // Note: the current user may not be among the network members being
        // removed, e.g. if the network is being viewed by a network lead.

        // Update the current authenticated user with reduced permission.
        queryClient.setQueryData<User>(
          queryKeyCurrentUser(),
          optimisticCurrentUser,
        );

        // Dump any data about networks the home page may be caching, because
        // the current user may not have rights to refresh it anymore.
        queryClient.removeQueries(queryKeyNetworksByProgramGeneric);
      }

      return { previousCurrentUser, previousNetworkUsers };
    },

    onSuccess: () => {
      // Pop a success toast, if a message was provided.
      message.success(toastSuccessMsg);
    },

    onError: (err, formValues, context) => {
      // Pop an error toast, if a message was provided.
      message.error(toastErrorMsg);

      // Rollback to previous if there's an error.
      if (context?.previousCurrentUser) {
        queryClient.setQueryData<User>(
          queryKeyCurrentUser(),
          context.previousCurrentUser,
        );
      }
      if (context?.previousNetworkUsers) {
        queryClient.setQueryData<User[]>(
          queryKeyNetworkUsers,
          context.previousNetworkUsers,
        );
      }
    },
  });

  // https://stackoverflow.com/questions/65760158/react-query-mutation-typescript
  // Formik onSubmit handler
  const onSubmit = async () => {
    await mutation.mutateAsync(
      transformUsersRemoveNetwork(membersToRemove, networkId),
    );
  };

  // Display loading.
  if (usersQuery.isLoading || currentUserQuery.isLoading) {
    return <Loading />;
  }

  // Display errors.
  if (!usersQuery.isSuccess || !currentUserQuery.isSuccess) {
    return (
      <ErrorMessageBox>
        This is my message box.{' '}
        {getMessageFromErrors([usersQuery.error, currentUserQuery.error])}
      </ErrorMessageBox>
    );
  }

  return (
    <NetworkMembersRemoveForm
      close={close}
      onSubmit={onSubmit}
      membersToRemove={membersToRemove}
      membersToRemoveIncludesAllMembers={membersToRemoveIncludesAllMembers}
      membersToRemoveIncludesSelf={membersToRemoveIncludesSelf}
    />
  );
};
