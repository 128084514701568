import { Input, TextField } from '@perts/ui';
import { useFormikContext } from 'formik';

export const InputFilter = ({ isLoading = false, onChange }) => {
  const { setFieldValue } = useFormikContext();
  const onChangeWrapped = (e) => {
    setFieldValue('filter', e.target.value);
    onChange();
  };

  return (
    <Input
      id="filter"
      name="filter"
      label="Filter by name"
      labelPlacement="top"
      displayError={false}
      component={TextField}
      disabled={isLoading}
      onChange={onChangeWrapped}
    />
  );
};
