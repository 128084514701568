import styled from 'styled-components/macro';
import { Col, Row, Text } from '@perts/ui';

const PreviewPromptedMessage = styled(Text)`
  font-style: italic;
  text-align: center;
  font-weight: 600;
  display: flex;
  justify-content: center;
`;

export const SignOnPreview = ({ message }) => (
  <>
    <Row>
      <Col>
        <Text as="h3">Sign-on message preview</Text>
      </Col>
    </Row>
    <Row>
      <Col>
        <PreviewPromptedMessage>{message}</PreviewPromptedMessage>
      </Col>
    </Row>
  </>
);
