/**
 * Cases handled:
 * - ?passwordless logins
 * - ?expired_session=true
 * - ?email
 *
 * - authenticated with ?email mismatched
 *
 * - authenticated with !emailVerified
 * - authenticated with !touAgreed
 * - authenticated with !hasSession
 *
 * - authenticated with ?continue_url
 * - authenticated with ?program
 * - authenticated
 */

import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import uri from 'urijs';

import { InfoBox, Show } from '@perts/ui';
import {
  selectUserAgreedToTermsOfUse,
  selectUserSession,
  useCurrentUserQuery,
} from 'models';
import { toLogin } from 'routes';
import { getJwtPayload } from 'services/triton/helpers';
import { logoutUser } from 'state/auth/actions';
import selectors from 'state/selectors';
import fromSearch from 'utils/fromSearch';

import AuthWrapper from 'components/AuthWrapper';
import Loading from 'components/Loading';
import LoginForm from './LoginForm';
import MessageEmailFor from './MessageEmailFor';
import PasswordlessForm from './PasswordlessForm';
import RedirectAuthenticatedUser from './RedirectAuthenticatedUser';
import VerifyEmail from './VerifyEmail';

const isEmailMismatched = (emailOfInvitation, emailOfCurrentUser) =>
  Boolean(emailOfInvitation) &&
  Boolean(emailOfCurrentUser) &&
  emailOfInvitation.toLowerCase() !== emailOfCurrentUser.toLowerCase();

const MessageExpiredSession = () => (
  <InfoBox color="warning">You were signed out due to inactivity.</InfoBox>
);

/* eslint-disable-next-line complexity */
const Login = (props) => {
  const {
    authenticating,
    // true if the conversion of a google token to triton user fails.
    authIsError,
    // true if the user has verified their email address
    emailVerified,
  } = props;

  const {
    // indicates passwordless login request
    passwordless,
    // indicates user has arrived via an email invite
    email,
    // indicates the user's session has expired
    expired_session,
    // the route to forward the user to after successful authentication
    continue_url,
    // the program to forward the user to after successful authentication
    program,
  } = fromSearch(props);

  const { data: user, isLoading } = useCurrentUserQuery() || {};
  const touAgreed = selectUserAgreedToTermsOfUse(user || {});
  const hasSession = selectUserSession(getJwtPayload() || {});

  // If the user has arrived here because they were invited, then we want to
  // ensure they're attempting to log in with the email they were invited with.
  // https://github.com/perts/perts/issues/1391
  const mismatchedEmail = isEmailMismatched(email, user && user.email);

  // Handle Back to Sign In clicks
  const onClickBack = () => {
    // Redirect back to /login while maintaining any query params.
    const to = uri(toLogin()).search(fromSearch(props));
    props.dispatch(logoutUser(to));
  };

  if (passwordless) {
    return <PasswordlessForm {...props} />;
  }

  if (authenticating || isLoading) {
    // Displayed during two different phases:
    // 1. During login() from googleAuth.ts, where we try to convert a google
    //    token to a triton user.
    // 2. During useCurrentUserQuery() above.

    return (
      <AuthWrapper>
        <Loading />
      </AuthWrapper>
    );
  }

  return (
    <AuthWrapper>
      <Show when={email}>
        <MessageEmailFor
          email={email}
          mismatchedEmail={mismatchedEmail}
          onClickBack={onClickBack}
        />
      </Show>

      <Show when={expired_session === 'true'}>
        <MessageExpiredSession />
      </Show>

      <Show when={authIsError}>
        <InfoBox color="warning">
          <p>
            Error loading your account. Please contact{' '}
            <a href="mailto:support@perts.net">support@perts.net</a>.
          </p>
        </InfoBox>
      </Show>

      <Show when={!user}>
        <LoginForm />
      </Show>

      <Show when={user && !mismatchedEmail && (!emailVerified || !touAgreed)}>
        <VerifyEmail
          authenticated={Boolean(user)}
          emailVerified={emailVerified}
          touAgreed={touAgreed}
          onClickBack={onClickBack}
          {...props}
        />
      </Show>

      <Show
        when={
          user && !mismatchedEmail && hasSession && emailVerified && touAgreed
        }
      >
        <RedirectAuthenticatedUser
          continue_url={continue_url}
          program={program}
        />
      </Show>
    </AuthWrapper>
  );
};

const mapStateToProps = (state, props) => ({
  authenticating: selectors.auth.authenticating(state, props),
  authIsError: selectors.auth.error(state, props),
  emailVerified: selectors.auth.user.emailVerified(state, props),
});

export default withRouter(connect(mapStateToProps)(Login));
