import moment from 'moment';
import { useMemo, useState } from 'react';
import { Parser as CsvParser } from 'json2csv';

import { Card, Row, Col, Button, Link, Show } from '@perts/ui';
import { getMessageFromErrors } from '@perts/util';

import { dateTimeFormat } from 'config';
import {
  Class,
  Cycle,
  Participant,
  ParticipationData,
  Program,
  selectParticipantProgress,
  useParticipantsGetByClassId,
  useParticipationByParticipant,
} from 'models';
import { getEffectiveEndDate, getEffectiveStartDate } from 'utils';
import { ParticipationProgressTable } from './ParticipationProgressTable';
import Loading from 'components/Loading';
import { DashboardUpdatedFrequency } from 'components/UpdatedFrequency';
import { ErrorMessageBox } from 'components/ErrorMessageBox';
import { getProgressDescription } from './getProgressDescription';

const getDownloadURI = (data) =>
  `data:text/csv;charset=utf-8,${encodeURIComponent(
    data && data.length > 0 ? new CsvParser().parse(data) : '',
  )}`;

const getDownloadName = (cls: Class, cycle?: Cycle) =>
  cycle
    ? `${cls.name}_Cycle_${cycle.ordinal}_Survey_Progress.csv`
    : `${cls.name}_Survey_Progress.csv`;

export const getParticipationProgressData = (
  participationData: ParticipationData | undefined,
  participants: Participant[] = [],
) => {
  if (!participationData) {
    return [];
  }

  const { last_run: lastRun, responses } = participationData;

  return participants.map((participant) => {
    const participantProgress = responses.find(
      (row) => row.participant_id === participant.uid,
    );
    const progress = selectParticipantProgress(
      participant,
      participantProgress,
      lastRun,
    );
    const created = participantProgress?.created
      ? moment(participantProgress.created).format(dateTimeFormat)
      : '';

    return {
      student_id: participant.student_id,
      progress: getProgressDescription(progress),
      created,
    };
  });
};

type Props = {
  cls: Class;
  cycle: Cycle;
  program: Program;
};

// eslint-disable-next-line complexity
export const ParticipationSingleCycle = ({ cls, cycle, program }: Props) => {
  const [downloadActive, setDownloadActive] = useState(false);

  // Query for Participants associated with Class.
  const queryParticipants = useParticipantsGetByClassId(cls.uid);

  // Dates needed for participation query.
  const startDateUnsafe = getEffectiveStartDate(cycle);
  const endDateUnsafe = getEffectiveEndDate(cycle);
  // getCycleOptions() in ChooseCycleProgressForm should limit the possible
  // cycle choices to ones where dates are defined. Throw to make TS happy.
  if (startDateUnsafe === null || endDateUnsafe === null) {
    throw new Error(
      `Got cycle w/o dates. Form should prevent this. ${cycle.uid}`,
    );
  }
  const startDate = startDateUnsafe;
  const endDate = endDateUnsafe;

  // Query for Participation by participant associated with Class and cycle
  const participationQuery = useParticipationByParticipant(
    cls.code,
    startDate,
    endDate,
  );

  const { last_run: lastRun = '' } = participationQuery.data || {};

  // https://react-table.tanstack.com/docs/quick-start
  const columns = useMemo(
    () => [
      { Header: 'ID', accessor: 'student_id' },
      { Header: 'Completed', accessor: 'progress' },
      { Header: 'Date', accessor: 'created' },
    ],
    [],
  );

  const data = useMemo(
    () =>
      getParticipationProgressData(
        participationQuery.data,
        queryParticipants.data,
      ),
    [participationQuery.data, queryParticipants.data],
  );

  // Display loading.
  if (queryParticipants.isLoading || participationQuery.isLoading) {
    return <Loading />;
  }

  // Display any errors.
  if (!queryParticipants.isSuccess || !participationQuery.isSuccess) {
    return (
      <ErrorMessageBox>
        {getMessageFromErrors([
          queryParticipants.error,
          participationQuery.error,
        ])}
      </ErrorMessageBox>
    );
  }

  return (
    <Card>
      <Card.Content>
        <Row>
          <Col>
            <Button
              onClick={() => setDownloadActive(!downloadActive)}
              disabled={downloadActive}
            >
              Download CSV
            </Button>
          </Col>
          <Col hAlign="flex-end" vAlign="center">
            <DashboardUpdatedFrequency dateString={lastRun} textAlign="right" />
          </Col>
        </Row>
        <Show when={downloadActive}>
          <Row>
            <Col hAlign="center">
              <Link
                to={getDownloadURI(data)}
                download={getDownloadName(cls, cycle)}
              >
                {getDownloadName(cls, cycle)}
              </Link>
            </Col>
          </Row>
        </Show>
      </Card.Content>
      <Card.Content>
        <ParticipationProgressTable columns={columns} data={data} />
      </Card.Content>
    </Card>
  );
};
