import { useMutation, useQueryClient, useQuery } from 'react-query';
import { message } from 'antd';

import { getEffectiveEndDate, getEffectiveStartDate } from 'utils';
import { useQueriesTyped } from 'utils/useQueriesTyped';
import { update as updateClassroom } from 'services/triton/classrooms';
import {
  addParticipants,
  invalidateClass,
  invalidateUpdateParticipants,
  Class,
  Cycle,
  CycleScheduled,
  Participant,
  ParticipantForBatchAdd,
  participantsQueryByClassId,
  removeParticipants,
  ParticipationData,
  participationByParticipant,
} from 'models';
import { useParams } from 'pages';

// -----------------------------------------------------------------------------
//   queryKey Generators
// -----------------------------------------------------------------------------

export const queryKeyParticipantsByClass = (classId: string) => [
  'participants',
  'byClass',
  classId,
];

export const queryKeyParticipationByParticipant = () => [
  'participation',
  'byParticipant',
];

export const queryKeyParticipationMultiCycle = () => [
  'participation',
  'multiCycle',
];

// -----------------------------------------------------------------------------
//   API Hooks
// -----------------------------------------------------------------------------

export type UseParticipantsQueryByClasses = (
  // Optional, it's a dependent query so the parent may not have resolved yet.
  classes?: Class[],
) => any;

export const useParticipantsQueryByClasses: UseParticipantsQueryByClasses = (
  classes = [],
) => {
  // Query for Participants associated with each Class.
  const uids = classes.flatMap((cls) => cls.uid);
  const uniqueUids = [...new Set(uids)];

  const queries = useQueriesTyped(
    uniqueUids.map((uid) => ({
      queryKey: queryKeyParticipantsByClass(uid),
      queryFn: () => participantsQueryByClassId(uid),
    })),
  );

  const data = queries
    // Filter to arrays only, so we can map over them.
    .filter((query) => Array.isArray(query.data))
    // Flatten data arrays from each query into a single array.
    .flatMap((query) => query.data);

  // If any query is loading, marking isLoading as true.
  const isLoading = queries.some((query) => query.isLoading);

  // Default error state to no error.
  let isError = false;
  let error: Error | null = null;

  // Then override with the first error found, if any.
  const queryWithError = queries.find((query) => query.isError);

  if (queryWithError) {
    ({ isError, error } = queryWithError);
  }

  return {
    data,
    error,
    isLoading,
    isError,
  };
};

export const useParticipantsGetByClassId = (classId: string) =>
  useQuery<Participant[], Error>(
    queryKeyParticipantsByClass(classId),
    // queryFn
    () => participantsQueryByClassId(classId),
  );

export const useParticipantsByParams = () => {
  const { classId } = useParams();
  return useParticipantsGetByClassId(classId);
};

type BatchEditMutationVariables = {
  classroomId: string;
  toAdd: ParticipantForBatchAdd[];
  toRemove: Participant[];
  roster_locked: boolean;
};

export const useParticipantsBatchEdit = (classId: string) => {
  const queryClient = useQueryClient();
  return useMutation<void, Error, BatchEditMutationVariables>(
    async ({ classroomId, toAdd, toRemove, roster_locked }) => {
      // TODO: use optimistic updates, once that pattern is packaged up.
      await Promise.all([
        toAdd.length > 0 ? addParticipants(toAdd) : null,
        toRemove.length > 0 ? removeParticipants(toRemove, classroomId) : null,
      ]);

      await updateClassroom({ uid: classroomId, roster_locked });
    },
    {
      onSuccess: (_data) => {
        message.success(`Saved changes to roster.`);
        invalidateUpdateParticipants(queryClient, classId);
        invalidateClass(queryClient, classId);
      },
      onError: () => {
        message.error('Unable to save changes to roster.');
      },
    },
  );
};

export type ParticipantsRemoveMutationVariables = {
  participantsToRemove: Participant[];
  classroomId: string;
};

export const useParticipantsRemove = (classId: string) => {
  const queryClient = useQueryClient();
  const queryKey = queryKeyParticipantsByClass(classId);

  const mutation = useMutation(
    async ({
      participantsToRemove,
      classroomId,
    }: ParticipantsRemoveMutationVariables) => {
      // Snapshot the previous participants value.
      const previousParticipants =
        queryClient.getQueryData<Participant[]>(queryKey);

      // Optimistically update to the new value
      if (previousParticipants) {
        const updatedValue = previousParticipants.map((participant) => {
          const foundParticipantToRemove = participantsToRemove.find(
            (p) => p.uid === participant.uid,
          );
          if (foundParticipantToRemove) {
            return {
              ...foundParticipantToRemove,
              classroom_ids: foundParticipantToRemove.classroom_ids.filter(
                (id) => id !== classroomId,
              ),
            };
          }
          return participant;
        });

        queryClient.setQueryData(queryKey, updatedValue);
      }

      await removeParticipants(participantsToRemove, classroomId);

      return { previousParticipants };
    },
    {
      onSuccess: (_data) => {
        message.success(`Successfully saved changes to roster.`);
        invalidateUpdateParticipants(queryClient, classId);
      },
      onError: (_err, _data, context: any) => {
        queryClient.setQueryData(queryKey, context.previousClasses);
        message.success(`Unable to save changes to roster.`);
      },
    },
  );

  return mutation.mutateAsync;
};

export const useParticipationByParticipant = (
  code: string,
  start_date: string,
  end_date: string,
) =>
  useQuery<ParticipationData, Error>(
    queryKeyParticipationByParticipant(),
    // queryFn
    () => participationByParticipant(code, start_date, end_date),
  );

export const useParticipationMultiCycle = (code: string, cycles: Cycle[]) =>
  useQuery<ParticipationData[], Error>(queryKeyParticipationMultiCycle(), () =>
    Promise.all(
      cycles
        // Calculate effective dates.
        .map((cycle) => ({
          effectiveStartDate: getEffectiveStartDate(cycle),
          effectiveEndDate: getEffectiveEndDate(cycle),
        }))
        // Filter to scheduled cycles.
        .filter((cycle): cycle is CycleScheduled =>
          Boolean(cycle.effectiveStartDate && cycle.effectiveEndDate),
        )
        // Make a network request for each scheduled cycle.
        .map((cycle) =>
          participationByParticipant(
            code,
            cycle.effectiveStartDate,
            cycle.effectiveEndDate,
          ),
        ),
    ),
  );
