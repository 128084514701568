// Example:
//
//  if (classIsError || participantsIsError || batchEditMutation.isError) {
//    return (
//      <ClassRosterBatchEditError>
//        {getMessageFromErrors([
//          classError,
//          participantsError,
//          batchEditMutation.error,
//        ])}
//      </ClassRosterBatchEditError>
//    );
//  }

type PossibleErrors = { message?: string } | null | undefined;

export const getMessageFromErrors = (errors: PossibleErrors[]): string => {
  const firstValidError = errors.find((err) => Boolean(err));
  return firstValidError?.message ? firstValidError.message : 'Unknown error.';
};
