import { Button, Col, Row, Show } from '@perts/ui';

const MessageEmailFor = ({ email, mismatchedEmail = false, onClickBack }) => (
  <>
    <Row>
      <Col>
        <div>
          <span>This invitation is for</span> <strong>{email}</strong>.{' '}
          {mismatchedEmail && (
            <div>Please go back and sign in with that account.</div>
          )}
        </div>
      </Col>
    </Row>
    <Show when={mismatchedEmail}>
      <Row>
        <Col>
          <Button color="secondary" fullWidth={true} onClick={onClickBack}>
            Back to Sign In
          </Button>
        </Col>
      </Row>
    </Show>
  </>
);

export default MessageEmailFor;
