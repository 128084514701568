import { ReportUpdatedFrequency, ReportFooter } from 'components';
import { NetworkReportLoader } from './NetworkReportLoader';
import { NetworkReport23Render } from './NetworkReport23Render';

export const NetworkReport23 = () => (
  <NetworkReportLoader>
    {({
      groups,
      lastRun,
      lastUpdatedResults,
      network,
      program,
      programMetrics,
      reportMetrics,
      results,
      resultsByGroup,
    }) => (
      <>
        <NetworkReport23Render
          groups={groups}
          lastRun={lastRun}
          network={network}
          program={program}
          programMetrics={programMetrics}
          reportMetrics={reportMetrics}
          results={results}
          resultsByGroup={resultsByGroup}
        />

        <ReportFooter>
          <ReportUpdatedFrequency dateString={lastUpdatedResults} />
        </ReportFooter>
      </>
    )}
  </NetworkReportLoader>
);
