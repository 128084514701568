import { Suspense } from 'react';
import { lazily } from 'react-lazily';
import { UseQueryResult } from 'react-query';

import {
  isOrganizationDataV1_1_0,
  OrganizationData,
  OrganizationUserData,
} from '@perts/model';
import { FixedActionbarContainer, InfoBox, Show } from '@perts/ui';
import { getMessageFromErrors } from '@perts/util';

import {
  useDatasetGroup,
  useDatasetGroupUser,
  useCurrentUserQuery,
  useClassesQueryByParams,
  useGroupGetByParams,
  useUsersQueryByParams,
  User,
} from 'models';
import { ArchivedWarning } from 'components/ArchivedWarning';
import { EmptyList } from 'components/EmptyList';
import { ErrorMessageBox } from 'components/ErrorMessageBox';
import Loading from 'components/Loading';
import { usePermissions } from 'utils';
import { ParticipationGuideBox } from './ParticipationGuideBox';
import { ParticipationActionbar } from './ParticipationActionbar';
import { DashboardClassParticipation } from './DashboardClassParticipation';
import { DashboardMemberParticipation } from './DashboardMemberParticipation';

// Pulls in ChartJS, and we'd like code splitting for that, so lazy-load.
const { DashboardGroup } = lazily(() => import('./DashboardGroup'));

// Note: to simplify conditional loading, which doesn't play nicely with hooks,
// we use nested loading components.

type DashboardProps = {
  query: UseQueryResult<OrganizationData | OrganizationUserData | null, Error>;
  users: User[];
};

type GroupProps = {
  groupId: string;
  mayViewByParticipant: boolean;
  users: User[];
};

type UserGroupProps = {
  currentUserId: string;
  groupId: string;
  mayViewByParticipant: boolean;
  users: User[];
};

const ParticipationDashboard = ({ query, users }: DashboardProps) =>
  query.isLoading ? (
    <Loading />
  ) : !query.isSuccess ? (
    <ErrorMessageBox>{getMessageFromErrors([query.error])}</ErrorMessageBox>
  ) : query.data === null ? (
    <InfoBox color="warning">
      <div>No data available yet</div>
      <div>A dashboard will be available within 24 hours.</div>
    </InfoBox>
  ) : (
    <>
      <DashboardGroup data={query.data} />
      {isOrganizationDataV1_1_0(query.data) && (
        <DashboardMemberParticipation dataset={query.data} users={users} />
      )}
      <DashboardClassParticipation dataset={query.data} users={users} />
    </>
  );

const AllClassParticipation = ({
  groupId,
  users,
  mayViewByParticipant,
}: GroupProps) => {
  const query = useDatasetGroup(groupId);
  return (
    <>
      <ParticipationActionbar
        lastRunDate={query?.data?.last_run || ''}
        mayViewByParticipant={mayViewByParticipant}
      />
      <ParticipationDashboard query={query} users={users} />
    </>
  );
};

const UserClassParticipation = ({
  currentUserId,
  groupId,
  users,
  mayViewByParticipant,
}: UserGroupProps) => {
  const query = useDatasetGroupUser(groupId, currentUserId);
  return (
    <>
      <ParticipationActionbar
        lastRunDate={query?.data?.last_run || ''}
        mayViewByParticipant={mayViewByParticipant}
      />
      <ParticipationDashboard query={query} users={users} />
    </>
  );
};

// End of nested loading components.

// eslint-disable-next-line complexity
export const Participation = () => {
  const classesQuery = useClassesQueryByParams();
  const currentUserQuery = useCurrentUserQuery();
  const groupQuery = useGroupGetByParams();
  const groupUserQuery = useUsersQueryByParams();

  const {
    mayGroupDataView,
    mayGroupUserDataView,
    mayIndividualParticipationView,
  } = usePermissions();

  if (
    classesQuery.isLoading ||
    currentUserQuery.isLoading ||
    groupQuery.isLoading ||
    groupUserQuery.isLoading
  ) {
    return <Loading />;
  }

  if (
    !classesQuery.isSuccess ||
    !currentUserQuery.isSuccess ||
    !groupQuery.isSuccess ||
    !groupUserQuery.isSuccess
  ) {
    return (
      <ErrorMessageBox>
        {getMessageFromErrors([
          classesQuery.error,
          currentUserQuery.error,
          groupQuery.error,
          groupUserQuery.error,
        ])}
      </ErrorMessageBox>
    );
  }

  const classes = classesQuery.data;
  const isClasses = classes.length > 0;
  const currentUser = currentUserQuery.data;
  const group = groupQuery.data;
  const users = groupUserQuery.data;

  const mayViewByParticipant = isClasses && mayIndividualParticipationView;

  // TODO: Determine if schedules have been set for some Classes.
  const isSchedulesSet = true;

  return (
    <>
      <Show when={group.archived}>
        <FixedActionbarContainer>
          <ArchivedWarning />
        </FixedActionbarContainer>
      </Show>

      {/*
        Class-level data isn't shown if there are no classes available to the
        current user. It's more useful to remind the user to go make some
        classes first.
      */}
      <Suspense fallback={<Loading />}>
        <Show when={isClasses}>
          {/* Only group managers may view this. */}
          <Show when={mayGroupDataView}>
            <AllClassParticipation
              groupId={group.uid}
              users={users}
              mayViewByParticipant={mayViewByParticipant}
            />
          </Show>

          {/* Non-managers view this instead. */}
          <Show when={!mayGroupDataView && mayGroupUserDataView}>
            <UserClassParticipation
              currentUserId={currentUser.uid}
              groupId={group.uid}
              users={users}
              mayViewByParticipant={mayViewByParticipant}
            />
          </Show>
        </Show>
        <Show when={!isClasses}>
          <EmptyList />
        </Show>
      </Suspense>

      <ParticipationGuideBox
        isClasses={isClasses}
        isSchedulesSet={isSchedulesSet}
      />
    </>
  );
};
