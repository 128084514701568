const unknownPattern = /^(Unknown|Skipped)/;

const otherPattern = /^(Other|Small Groups)/;

export const attributeValueSort = (titleA: string, titleB: string) => {
  // Always sort 'All' first.
  if (titleA === 'All') {
    return -1;
  }
  if (titleB === 'All') {
    return 1;
  }

  // Always sort 'Unknown' last.
  if (unknownPattern.test(titleA)) {
    return 1;
  }
  if (unknownPattern.test(titleB)) {
    return -1;
  }

  // When 'Unknown' is not present, sort 'Other' last.
  if (otherPattern.test(titleA)) {
    return 1;
  }
  if (otherPattern.test(titleB)) {
    return -1;
  }

  // Otherwise sort alphabetically.
  return titleA < titleB ? -1 : 1;
};
