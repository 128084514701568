import { Participant, ParticipantId } from 'models';
import { idsArrayToString } from './idsArrayToString';

export const participantsToIdsString = (ppts: Participant[]): string => {
  const ids: ParticipantId[] = ppts.map(
    ({ stripped_student_id, student_id }) => ({
      stripped_student_id,
      student_id,
    }),
  );
  return idsArrayToString(ids);
};
