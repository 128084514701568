// https://docs.google.com/spreadsheets/d/1EKKzZjI-eCtekB_S_SsNnBQpGEKVIKueZIkBD6W8QJc

import keyBy from 'lodash/keyBy';

import { locales } from '../i18n';
import { CompositeMetricConfig, MetricItemConfig } from './types';

export const teacherStudentVoiceItemConfig: MetricItemConfig[] = [
  {
    label: 'teacher_student_voice_helpful',
    prompts: {
      [locales.ENGLISH]:
        `How helpful do would you find it for students to give you feedback ` +
        `about your teaching?`,
    },
    minGood: 4,
    options: {
      [locales.ENGLISH]: [
        {
          label: 'Not at all helpful',
          value: 1,
        },
        {
          label: 'A little helpful',
          value: 2,
        },
        {
          label: 'Somewhat helpful',
          value: 3,
        },
        {
          label: 'Very helpful',
          value: 4,
        },
        {
          label: 'Extremely helpful',
          value: 5,
        },
      ],
    },
  },
  {
    label: 'teacher_student_voice_ask',
    prompts: {
      [locales.ENGLISH]: `I ask my students for feedback or suggestions about my teaching.`,
    },
    minGood: 4,
    options: {
      [locales.ENGLISH]: [
        {
          label: 'Never',
          value: 1,
        },
        {
          label: 'Occasionally',
          value: 2,
        },
        {
          label: 'Sometimes',
          value: 3,
        },
        {
          label: 'Often',
          value: 4,
        },
        {
          label: 'Very often',
          value: 5,
        },
      ],
    },
  },
];

export const teacherStudentVoiceConfig: CompositeMetricConfig = {
  label: 'teacher-student-voice',
  items: teacherStudentVoiceItemConfig,
  itemIndex: keyBy(teacherStudentVoiceItemConfig, 'label'),
};
