import { useQuery } from 'react-query';
import {
  Group,
  Network,
  Program,
  Team,
  User,
  groupGetById,
  networkGetById,
  programsQuery,
  programsQueryByUser,
  queryKeyGroup,
  queryKeyNetwork,
  selectProgramById,
  selectUserIsAdmin,
  useCurrentUserQuery,
  useGroupGetByParams,
  useNetworkGetByParams,
} from 'models';
import { useParams } from 'pages';

import { search } from 'services/triton/programs';
import { programNameAndCohort } from 'utils';

type QueryOptions = {
  enabled?: boolean;
};

// -----------------------------------------------------------------------------
//   queryKey Generators
// -----------------------------------------------------------------------------

export const queryKeyProgram = (programId: string) => ['program', programId];

export const queryKeyPrograms = () => ['programs'];

export const queryKeyProgramsByUser = (userId) => [
  'programs',
  'byUser',
  userId,
];

export const queryKeySearch = (searchQuery: string) => ['search', searchQuery];

// -----------------------------------------------------------------------------
//   API Hooks
// -----------------------------------------------------------------------------

export const useProgramId = () => {
  const { programId: programIdFromParams } = useParams();
  const { data: network } = useNetworkGetByParams();
  const { data: group } = useGroupGetByParams();

  // Because programId isn't on the route for all routes, we will also
  // sometimes need to find the programId on the Network or Group. Class is
  // not needed to determine the programId even on Class routes because the
  // Group is still on the route.
  const programId =
    programIdFromParams || network?.program_id || group?.program_id || '';

  return programId;
};

// Query for Program by id

export const useProgramGetById = (programId: string) => {
  const response = useProgramsQuery();
  const { data = [], isLoading } = response;

  if (isLoading) {
    return {
      ...response,
      data: undefined,
    };
  }
  return {
    ...response,
    data: selectProgramById(programId, data),
  };
};

// eslint-disable-next-line complexity
export const useProgramGetByParams = () => {
  const { programId, groupId, networkId } = useParams();
  const {
    isLoading: programsIsLoading,
    isError: programsIsError,
    data: programs = [],
    error: programsError,
  } = useProgramsQuery();

  // This query is only executed if programId doesn't exist, but groupId exist
  const {
    isLoading: groupIsLoading,
    isError: groupIsError,
    data: group,
    error: groupError,
  } = useQuery<Group, Error>(
    // queryKey
    queryKeyGroup(groupId),
    // queryFn
    () => groupGetById(groupId),
    { enabled: Boolean(!programId) && Boolean(groupId) },
  );

  // This query is only executed if programId and groupId doesn't exist
  // but networkId exist
  const {
    isLoading: networkIsLoading,
    isError: networkIsError,
    data: network,
    error: networkError,
  } = useQuery<Network, Error>(
    // queryKey
    queryKeyNetwork(networkId),
    // queryFn
    () => networkGetById(networkId),
    { enabled: Boolean(!programId) && Boolean(!groupId) && Boolean(networkId) },
  );

  const currentProgramId =
    programId ||
    (group ? group.program_id : '') ||
    (network ? network.program_id : '');

  return {
    isLoading: programsIsLoading || groupIsLoading || networkIsLoading,
    isError: programsIsError || groupIsError || networkIsError,
    error: programsError || groupError || networkError,
    data: currentProgramId
      ? selectProgramById(currentProgramId, programs)
      : undefined,
    isSuccess: Boolean(currentProgramId),
  };
};

// Query for Programs.

export const useProgramsQuery = (queryOptions: QueryOptions = {}) =>
  useQuery<Program[], Error>(
    // queryKey
    queryKeyPrograms(),
    // queryFn
    () => programsQuery(),
    // queryOptions
    queryOptions,
  );

// Query for user's programs.

export const useProgramsQueryByUser = (options?: QueryOptions) => {
  const {
    data: user,
    isLoading: userIsLoading,
    isError: userIsError,
  } = useCurrentUserQuery();

  const userId = user?.uid || '';

  const response = useQuery<Program[], Error>(
    queryKeyProgramsByUser(userId),
    () => programsQueryByUser(userId),
    { enabled: Boolean(userId) && options?.enabled !== false },
  );

  // If the query is loading or current user query is loading,
  // marking isLoading as true.
  return {
    ...response,
    isLoading: response.isLoading || userIsLoading,
    isError: response.isError || userIsError,
  };
};

// Query for `searchQuery` the entities associated with Program `programId`.

export const useProgramsSearch = (programLabel: string, searchQuery: string) =>
  useQuery<
    {
      networks: Network[];
      organizations: Group[];
      teams: Team[];
      users: User[];
    },
    Error
  >(queryKeySearch(searchQuery), () => search(programLabel, searchQuery));

// Handles retrieving archived programs for the current user.

export const useProgramsArchived = () => {
  const { data: user } = useCurrentUserQuery();
  const userIsAdmin = selectUserIsAdmin(user);

  const userReady = Boolean(user?.uid);
  const byUserEnabled = userReady && !userIsAdmin;
  const adminEnabled = userReady && userIsAdmin;

  const useByUser = useProgramsQueryByUser({ enabled: byUserEnabled });
  const useAdmin = useProgramsQuery({ enabled: adminEnabled });

  const useActual = adminEnabled && useAdmin.isSuccess ? useAdmin : useByUser;

  return {
    ...useActual,
    data: useActual?.data
      ?.filter((d) => !d.active)
      .map((p) => ({ ...p, displayName: programNameAndCohort(p) }))
      .sort((a, b) => a.displayName.localeCompare(b.displayName)),
  };
};
