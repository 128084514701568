import { useContext } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components/macro';

import {
  Actionbar,
  Button,
  Col,
  Display,
  FixedActionbarContainer,
  IconUserAdd,
  IconUserDelete,
  Row,
  Show,
} from '@perts/ui';

import { toGroupMembersAdd, PagesParams, toGroupMembersLeave } from 'pages';
import { MembersDropdownActions } from './MembersDropdownActions';

import { ArchivedWarning } from 'components/ArchivedWarning';
import TermsContext from 'components/TermsContext';
import { useOpenModal, usePermissions } from 'utils';
import { BulkSelectMessage } from 'components/BulkSelectMessage';

const ActionToggleContainer = styled.div`
  display: flex;
  & > :not(:first-child) {
    margin-left: 10px;
  }
`;

export const MembersActionbar = ({
  archived,
  ControlToggle,
  Pagination,
  checked,
  filterComponent,
  filteredItems,
  members,
  selected,
  toggleAll,
  toggleAllFilter,
  SortByComponent,
}) => {
  const {
    mayGroupLeave,
    mayGroupMemberInvite,
    mayGroupMemberPromote,
    mayGroupMemberRemove,
    mayGroupMemberEditPin,
    mayGroupMemberSendInvite,
  } = usePermissions();
  const { groupId = '' } = useParams<PagesParams>();
  const terms = useContext(TermsContext);

  const openModal = useOpenModal();

  const totalSelected = selected.length;
  const totalMembers = members.length;
  const totalFilteredItems = filteredItems.length;
  const totalItems =
    totalFilteredItems < totalMembers ? totalFilteredItems : totalMembers;

  const handleToggleAll =
    totalFilteredItems < totalMembers ? toggleAllFilter : toggleAll;

  const topBarColsSm = mayGroupLeave && mayGroupMemberInvite ? 6 : 12;

  return (
    <FixedActionbarContainer>
      <Show when={archived}>
        <ArchivedWarning />
      </Show>
      <Actionbar paddingLeft={0}>
        <Row justifyContent="space-between" alignItems="flex-end">
          <Col colsLg={12} orderLg={2}>
            <Row>
              <Col colsSm={12} cols={5}>
                {filterComponent}
              </Col>
              <Col shrink vAlign="flex-end">
                <SortByComponent />
              </Col>
            </Row>
          </Col>
          <Col colsLg={12} orderLg={1} shrink>
            <Row>
              <Show when={mayGroupLeave}>
                <Col colsLg={topBarColsSm} shrink>
                  <Button
                    onClick={() => openModal(toGroupMembersLeave(groupId))}
                    iconLeft={
                      <Display whenMd whenLg whenXl display="contents">
                        <IconUserDelete />
                      </Display>
                    }
                    color="secondary"
                    fullWidth
                    fullHeight
                  >
                    {`Leave ${terms.group}`}
                  </Button>
                </Col>
              </Show>
              <Show when={mayGroupMemberInvite && !archived}>
                <Col colsLg={topBarColsSm} shrink>
                  <Button
                    onClick={() => openModal(toGroupMembersAdd(groupId))}
                    iconLeft={
                      <Display whenMd whenLg whenXl display="contents">
                        <IconUserAdd />
                      </Display>
                    }
                    color="secondary"
                    fullWidth
                    fullHeight
                  >
                    Add {terms.groupMembers}
                  </Button>
                </Col>
              </Show>
            </Row>
          </Col>
        </Row>
      </Actionbar>
      <Actionbar>
        <Row alignItems="center">
          <Col colsSm={12} cols={0}>
            <ActionToggleContainer>
              <Show when={mayGroupMemberRemove || mayGroupMemberPromote}>
                <ControlToggle />
              </Show>

              <Show
                when={
                  mayGroupMemberRemove ||
                  mayGroupMemberPromote ||
                  mayGroupMemberSendInvite ||
                  mayGroupMemberEditPin()
                }
              >
                <MembersDropdownActions
                  totalSelected={totalSelected}
                  checked={checked}
                  mayGroupMemberPromote={mayGroupMemberPromote}
                  mayGroupMemberRemove={mayGroupMemberRemove}
                  mayGroupMemberEditPin={mayGroupMemberEditPin}
                />
              </Show>
            </ActionToggleContainer>
          </Col>

          <Col colsSm={12} cols={0}>
            <BulkSelectMessage
              totalSelected={totalSelected}
              totalItems={totalItems}
              toggleAll={handleToggleAll}
            />
          </Col>

          <Col colsSm={12} vAlign="center" hAlign="flex-end" hAlignSm="center">
            <Pagination />
          </Col>
        </Row>
      </Actionbar>
    </FixedActionbarContainer>
  );
};
