import { useMemo } from 'react';
import { usePermissions } from 'utils';
import { SortByOptions, useSortBy } from './useSortBy';
import { useTerms } from 'components';
import {
  IconFromTo,
  IconUserCircleLead,
  IconUserCircleNotLead,
} from './components';

export const useSortByMembers = () => {
  const { mayGroupMemberPromote, mayGroupMemberSendInvite } = usePermissions();
  const terms = useTerms();

  const sortOptions = useMemo(() => {
    const opts: SortByOptions = [
      {
        value: 'name',
        name: `${terms.groupMember} Name`,
        sortByDirectionOptions: [
          {
            name: (
              <>
                A <IconFromTo /> Z
              </>
            ),
            value: 'name:asc',
          },
          {
            name: (
              <>
                Z <IconFromTo /> A
              </>
            ),
            value: 'name:desc',
          },
        ],
      },
      {
        value: 'email',
        name: `${terms.groupMember} Email`,
        sortByDirectionOptions: [
          {
            name: (
              <>
                A <IconFromTo /> Z
              </>
            ),
            value: 'email:asc',
          },
          {
            name: (
              <>
                Z <IconFromTo /> A
              </>
            ),
            value: 'email:desc',
          },
        ],
      },
      {
        value: 'classesLength',
        name: `# of ${terms.classes}`,
        sortByDirectionOptions: [
          {
            name: (
              <>
                Low <IconFromTo /> High
              </>
            ),
            value: 'classesLength:asc',
          },
          {
            name: (
              <>
                High <IconFromTo /> Low
              </>
            ),
            value: 'classesLength:desc',
          },
        ],
      },
    ];

    // Lead sort should only render if auth user can promote/demote others.
    if (mayGroupMemberPromote) {
      opts.push({
        value: 'isManager',
        name: terms.groupManager,
        sortByDirectionOptions: [
          {
            name: (
              <>
                <IconUserCircleLead /> <IconFromTo /> <IconUserCircleNotLead />
              </>
            ),
            value: 'isManager:desc',
          },
          {
            name: (
              <>
                <IconUserCircleNotLead /> <IconFromTo /> <IconUserCircleLead />
              </>
            ),
            value: 'isManager:asc',
          },
        ],
      });
    }

    // Invitation sort should only render if auth user can send invitations.
    if (mayGroupMemberSendInvite) {
      opts.push({
        value: 'isPendingInvite',
        name: 'Invitation Accepted',
        sortByDirectionOptions: [
          {
            name: (
              <>
                No <IconFromTo /> Yes
              </>
            ),
            value: 'isPendingInvite:desc',
          },
          {
            name: (
              <>
                Yes <IconFromTo /> No
              </>
            ),
            value: 'isPendingInvite:asc',
          },
        ],
      });
    }

    return opts;
  }, [
    mayGroupMemberPromote,
    mayGroupMemberSendInvite,
    terms.classes,
    terms.groupManager,
    terms.groupMember,
  ]);

  return useSortBy(sortOptions);
};
