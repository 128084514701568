import { groupBy, isEqual } from 'lodash';

type DataRow = {
  [key: string]: any;
};

// Pivot data from long form to wide form. Assumes your data is 2-D as an array
// of rows, where each row is a plain object with a consistent set of
// properties. Provide the "column" of the data from which to draw the names of
// the new widened columns as `namesFrom`. Provide the "column" of the data from
// which to draw the values as `valuesFrom`. Designed to have a similar
// interface as the R package tidyr::pivot_wider.
// See https://tidyr.tidyverse.org/articles/pivot.html#wider
// See pivotWider.test.ts for examples.
export const pivotWider = (
  dataRows: DataRow[],
  namesFrom: string,
  valuesFrom: string,
): DataRow[] => {
  // const wideRows: DataRow[] = [];

  if (dataRows.length === 0) {
    return [];
  }

  const expectedKeys = Object.keys(dataRows[0]);

  if (!expectedKeys.includes(namesFrom)) {
    throw new Error(`Property "${namesFrom}" does not appear in first row.`);
  }

  if (!expectedKeys.includes(valuesFrom)) {
    throw new Error(`Property "${valuesFrom}" does not appear in first row.`);
  }

  if (!dataRows.every((row) => isEqual(Object.keys(row), expectedKeys))) {
    throw new Error(`Rows have inconsistent keys.`);
  }

  const indexKeys = expectedKeys.filter(
    (key) => ![namesFrom, valuesFrom].includes(key),
  );

  // suppose row = {"indexA": 1, "indexB": "foo", "nameCol": "x", "valueCol": 5}
  // then the "index value" is '[1,"foo"]'.
  const rowToIndexString = (row: DataRow) =>
    JSON.stringify(indexKeys.map((key) => row[key]));

  // This operation reduces the number of rows. But the values are still nested.
  const rowsByIndex = groupBy(dataRows, rowToIndexString);

  const allWideCols = [...new Set(dataRows.map((row) => row[namesFrom]))];

  return Object.entries(rowsByIndex).map(([indexValue, groupedRows]) => {
    // Unpack the index properties. This will be part of the returned row.
    const parsedIndex = JSON.parse(indexValue);
    const indexRowPartial = Object.fromEntries(
      indexKeys.map((key, i) => [key, parsedIndex[i]]),
    );

    // Start the rest of the wide row with a blank cell for every new column.
    // This operation increases the number of properties in the row.
    const wideRowPartial = Object.fromEntries(
      allWideCols.map((col) => [col, undefined]),
    );

    // Fill in empty cells if we find values in the nested un-widened rows.
    groupedRows.forEach(
      (row) => (wideRowPartial[row[namesFrom]] = row[valuesFrom]),
    );

    // Combine the index propertes with the new wide properties.
    return {
      ...indexRowPartial,
      ...wideRowPartial,
    };
  });
};
