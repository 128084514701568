import { Redirect } from 'react-router-dom';

import Loading from 'components/Loading';
import { GroupReport23, toArchiveGroupReportId, useParams } from 'pages';
import {
  selectMostCurrentReport,
  useCurrentUserQuery,
  useDatasetGroup,
  useDatasetGroupUser,
  useReportsQueryByParams,
} from 'models';
import { usePermissions } from 'utils';

const OldReportRedirect = () => {
  const { groupId } = useParams();

  const reportsQuery = useReportsQueryByParams();

  if (reportsQuery.isLoading) {
    return <Loading />;
  }

  const mostCurrentReport = selectMostCurrentReport(reportsQuery.data || []);

  if (!mostCurrentReport) {
    return <>No report found.</>;
  }

  return (
    <Redirect to={toArchiveGroupReportId(groupId, mostCurrentReport.uid)} />
  );
};

export const ArchiveGroupReport = () => {
  const { mayGroupDataView, mayGroupUserDataView } = usePermissions();
  const { groupId } = useParams();

  const currentUserQuery = useCurrentUserQuery();

  // For super admins, network managers, and group managers.
  const groupDataEnabled = mayGroupDataView;
  // For group members.
  const groupUserDataEnabled =
    currentUserQuery.isSuccess && !mayGroupDataView && mayGroupUserDataView;

  const datasetGroupQuery = useDatasetGroup(groupId, {
    enabled: groupDataEnabled,
  });
  const datasetGroupUserQuery = useDatasetGroupUser(
    groupId,
    currentUserQuery.data?.uid,
    { enabled: groupUserDataEnabled },
  );

  if (datasetGroupQuery.isLoading || datasetGroupUserQuery.isLoading) {
    return <Loading />;
  }

  if (
    !datasetGroupQuery.data?.results &&
    !datasetGroupUserQuery.data?.results
  ) {
    return <OldReportRedirect />;
  }

  return <GroupReport23 />;
};
