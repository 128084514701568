// Returns a mutation for removing (deleting) the given Cycles. Uses optimistic
// updates.

import { useMutation, useQueryClient } from 'react-query';
import { message } from 'antd';

import { Cycle, removeCycle, queryKeyCyclesByGroup } from 'models';
import mutationRemoveFn from 'utils/mutationRemoveFn';

export const useCyclesRemove = () => {
  const queryClient = useQueryClient();

  type MutationArgs = { cycles: Cycle[]; groupId: string };
  return useMutation<Cycle[], Error, MutationArgs, unknown>(
    ({ cycles, groupId }) =>
      mutationRemoveFn<Cycle>(
        queryClient,
        removeCycle,
        cycles,
        queryKeyCyclesByGroup(groupId),
      ),
    {
      onSuccess: () => {
        message.success(`Successfully deleted the cycle.`);
      },
      onError: () => {
        message.error(`Unable to delete the cycle.`);
      },
    },
  );
};
