import { Card } from '@perts/ui';
import { useTerms } from 'components';
import {
  Class,
  Group,
  queryKeyClass,
  updateClass,
  useMutationUpdate,
} from 'models';
import {
  getDefaultValuesSignOn as getDefaultValues,
  getInitialValuesSignOn as getInitialValues,
  getRosterSignOnRuleSettings,
  RosterSignOnRuleForm,
  RosterSignOnRuleFormValues,
} from 'pages/Classes/shared';

export const ClassRosterSettingsSignOnRuleForm = ({
  group,
  cls,
  userIsAdmin = false,
}: {
  group: Group;
  cls: Class;
  userIsAdmin?: boolean;
}) => {
  const terms = useTerms();

  const initialValues = getInitialValues([cls]);
  const defaultValues = getDefaultValues(group);

  const queryKey = queryKeyClass(cls.uid);

  const mutation = useMutationUpdate<Class, Class>(updateClass, queryKey, {
    optimisticallyUpdate: false,
    toastSuccessMsg: `Successfully updated ${terms.class.toLowerCase()} settings.`,
    toastErrorMsg: `There was a problem updating ${terms.class.toLowerCase()} settings.`,
  });

  const onSubmit = async (values: RosterSignOnRuleFormValues) => {
    const rosterSignOnProps = getRosterSignOnRuleSettings({ values });

    const updatedClass = {
      ...cls,
      ...rosterSignOnProps,
    };

    await mutation.mutateAsync(updatedClass);
  };

  return (
    <Card>
      <Card.Content>
        <RosterSignOnRuleForm
          initialValues={initialValues}
          defaultValues={defaultValues}
          submitButtonText="Save Settings"
          onSubmit={onSubmit}
          userIsAdmin={userIsAdmin}
        />
      </Card.Content>
    </Card>
  );
};
