import { useState } from 'react';
import fromSearch from 'utils/fromSearch';
import tritonAuthService from 'services/triton/auth';

import MagicLinkErrors from './MagicLinkErrors';
import MagicLinkRequest from './MagicLinkRequest';
import MagicLinkSent from './MagicLinkSent';
import AuthWrapper from 'components/AuthWrapper';
import { Show } from '@perts/ui';

const { requestMagicLink } = tritonAuthService;

const PasswordlessForm = (props) => {
  // error display
  const [errors, setErrors] = useState(0);
  // loading status
  const [isLoading, setIsLoading] = useState(false);
  // track if the user has been sent their magic link
  const [magicLinkSent, setMagicLinkSet] = useState(false);

  const {
    // where the user was attempting to navigate to
    continue_url,
    // the user's email address
    passwordless: email,
  } = fromSearch(props);

  const handleOnClick = () => {
    setIsLoading(true);

    requestMagicLink(email, continue_url)
      .then(() => setMagicLinkSet(true))
      .catch(() => {
        setIsLoading(false);
        setErrors(errors + 1);
      });
  };

  return (
    <AuthWrapper>
      <Show when={!magicLinkSent && errors === 0}>
        <MagicLinkRequest handleOnClick={handleOnClick} isLoading={isLoading} />
      </Show>

      <Show when={magicLinkSent && errors === 0}>
        <MagicLinkSent email={email} />
      </Show>

      <Show when={errors !== 0}>
        <MagicLinkErrors
          handleOnClick={handleOnClick}
          isLoading={isLoading}
          errors={errors}
        />
      </Show>
    </AuthWrapper>
  );
};

export default PasswordlessForm;
