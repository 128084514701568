// Generates an array of objects with all user, class, and group combinations
// based on the provided `classes` array. Assumption: The classes array has been
// filled with the class' `facitilators` and `groups`.

import { Class } from 'models';
import { UserClassGroupMap } from './types';

export const createUserClassGroupMap = (classesFilled: Class[]) =>
  classesFilled.flatMap((cls) => {
    const facilitators = cls.facilitators || [];

    return facilitators.flatMap((user) => {
      const groups = cls.groups || [];

      const map: UserClassGroupMap = groups.map((group) => ({
        userId: user.uid,
        classId: cls.uid,
        groupId: group.uid,
      }));

      return map;
    });
  });
