import { ParticipantId } from 'models';
import { stripToken, splitAndTrimLines } from '@perts/util';

// idsStringToArray converts the string input we received from redux-form
// into an array of roster IDs. This does not dedupe or remove invalid entries.
export const idsStringToArray = (value: string): ParticipantId[] => {
  const student_ids = splitAndTrimLines(value);
  return student_ids.map((student_id) => {
    const stripped_student_id = stripToken(student_id);

    return {
      student_id,
      stripped_student_id,
    };
  });
};
