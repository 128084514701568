import { useEffect, useState } from 'react';

import { Button, Col, Modal, Row, Switch, useBroadcast } from '@perts/ui';

import { channelOpenResponses } from '../../../config';

const SetupOpenResponsesModal = ({
  isModalVisible,
  closeModal,
}: {
  isModalVisible: boolean;
  closeModal: () => void;
}) => {
  // State for just this switch. Independent of the global, broadcasted state
  // used by `channelOpenResponses`.
  const [areOpenResponsesVisible, setAreOpenResponsesVisible] = useState(true);

  const { listen, broadcast } = useBroadcast(channelOpenResponses);

  // Have our local state follow the global state. This allows the switch to
  // always have the right value when you open the modal. It also triggers the
  // UI toggle to actually move.
  listen((showOpenResponses) => {
    if (showOpenResponses !== areOpenResponsesVisible) {
      setAreOpenResponsesVisible(showOpenResponses);
    }
  });

  useEffect(() => {
    window.addEventListener('afterprint', closeModal);
    return () => {
      window.removeEventListener('afterprint', closeModal);
    };
  }, [closeModal]);

  return (
    <Modal isOpen={isModalVisible} close={closeModal}>
      <Modal.Title className="center">Print Options</Modal.Title>

      <Row>
        <Col>
          <Switch
            id={`OpenResponsesSwitch`}
            label={`Open-ended responses ${
              areOpenResponsesVisible ? 'visible' : 'hidden'
            }`}
            checked={areOpenResponsesVisible}
            onChange={() => {
              broadcast(!areOpenResponsesVisible);
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col shrink={false} cols={6}>
          <Button
            fullWidth
            type="button"
            onClick={closeModal}
            color="secondary"
          >
            Cancel
          </Button>
        </Col>
        <Col shrink={false} cols={6}>
          <Button
            fullWidth
            onClick={() => window.print()}
            type="button"
            color="primary"
          >
            Print
          </Button>
        </Col>
      </Row>
    </Modal>
  );
};

export default SetupOpenResponsesModal;
