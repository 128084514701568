import { ApiCourse } from '@perts/model';

import { post } from 'services/triton/courses';

// -----------------------------------------------------------------------------
//   API Functions
// -----------------------------------------------------------------------------

// Add a canvas course for some group.

export const courseAddToGroup = (groupId: string): Promise<ApiCourse> =>
  post({ organization_id: groupId });
