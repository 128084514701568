import { useMutation, useQueryClient } from 'react-query';
import { message } from 'antd';
import { uniqBy } from 'lodash';
import pluralize from 'pluralize';

import { Modal } from '@perts/ui';
import { getMessageFromErrors } from '@perts/util';
import { ErrorMessageBox, Loading, useTerms } from 'components';
import {
  Class,
  queryKeyClassesByGroup,
  updateGroupClasses,
  useClassesQueryByParams,
  useGroupGetByParams,
} from 'models';
import { useParams } from 'pages';
import {
  RosterSignOnTypeForm,
  RosterSignOnTypeFormValues,
  getDefaultValuesSignOn,
  getInitialValuesSignOn,
  getRosterSignOnTypeSettings,
} from 'pages/Classes/shared';
import { useCloseModal, useGetCheckedStates } from 'utils';

export const ClassesEditRosterSignOnType = () => {
  const checked = useGetCheckedStates();
  const terms = useTerms();
  const queryClient = useQueryClient();
  const { groupId = '' } = useParams();
  const queryKeyClasses = queryKeyClassesByGroup(groupId);
  const closeModal = useCloseModal();

  const groupQuery = useGroupGetByParams();
  const classesQuery = useClassesQueryByParams();

  const mutation = useMutation(updateGroupClasses, {
    onMutate: (classesToUpdate: Class[]) => {
      // Snapshot the previous classes value.
      const previous = queryClient.getQueryData<Class[]>(queryKeyClasses);

      // Optimistically update.
      const optimistic = previous
        ? uniqBy([...classesToUpdate, ...previous], 'uid')
        : classesToUpdate;

      queryClient.setQueryData(queryKeyClasses, optimistic);

      // Return a context object with the snapshot value.
      return { previous };
    },
    onSuccess: () => {
      message.success(
        `Successfully edited ${terms.participant.toLowerCase()} sign-on type for ` +
          `${terms.classes.toLowerCase()}.`,
      );
    },
    onError: (_err, _data, context: any) => {
      if (context?.previous) {
        queryClient.setQueryData(queryKeyClasses, context.previous);
      }
    },
  });

  // Display loading.
  if (groupQuery.isLoading || classesQuery.isLoading || !checked) {
    return <Loading />;
  }

  // Display errors.
  if (!groupQuery.isSuccess || !classesQuery.isSuccess) {
    return (
      <ErrorMessageBox>
        {getMessageFromErrors([groupQuery.error, classesQuery.error])}
      </ErrorMessageBox>
    );
  }

  // Determine classes that have been selected.
  const checkedClasses =
    classesQuery.data?.filter((cls) => checked[cls.uid]) || [];

  const initialValues: RosterSignOnTypeFormValues = (function () {
    const initValues = getInitialValuesSignOn(checkedClasses, [
      'portalType',
      'ruleEnabled',
    ]);

    // If settings are mixed, set portalType to null, so that the form UI won't
    // pre-select any of the options.
    return initValues.ruleEnabled === null
      ? { portalType: null }
      : { portalType: initValues.portalType };
  })();

  const defaultValues = getDefaultValuesSignOn(groupQuery.data);

  const someClassesWithRule = checkedClasses.some((cls) =>
    Boolean(cls.participant_pattern),
  );

  const onSubmit = async (values: RosterSignOnTypeFormValues) => {
    // Form validation should guarantee that this is non-null, but the
    // types allow null for initial form values in case classes have mixed
    // settings. Double check to make TS happy.
    if (values.portalType === null) {
      throw new Error('Form validation failed, portalType is null.');
    }

    const rosterSignOnProps = getRosterSignOnTypeSettings({
      values,
      terms,
      classes: checkedClasses,
    });

    // Update values on selected classes to be sent to the api function.
    const classesToUpdate = checkedClasses.map((cls) => ({
      ...cls,
      ...rosterSignOnProps,
    }));

    await mutation.mutateAsync(classesToUpdate);
  };

  return (
    <Modal close={closeModal}>
      <RosterSignOnTypeForm
        close={closeModal}
        submitButtonText={`Save Changes to ${pluralize(
          terms.class,
          checkedClasses.length,
          true,
        )}`}
        onSubmit={onSubmit}
        classes={checkedClasses}
        initialValues={initialValues}
        defaultValues={defaultValues}
        someClassesWithRule={someClassesWithRule}
      />
    </Modal>
  );
};
