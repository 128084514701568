import styled from 'styled-components/macro';
import theme from 'components/theme';

type ScheduleTableColContainerProps = {
  width?: number;
  alignItems?:
    | 'center'
    | 'flex-start'
    | 'flex-end'
    | 'stretch'
    | 'baseline'
    | 'initial'
    | 'inherit';
  justifyContent?:
    | 'center'
    | 'flex-start'
    | 'flex-end'
    | 'space-between'
    | 'space-around'
    | 'initial'
    | 'inherit';
};

type ScheduleTableRowProps = ScheduleTableColContainerProps & {
  // children to be displayed.
  children?: React.ReactNode;
};

const ScheduleTableColContainer = styled.div<ScheduleTableColContainerProps>`
  display: flex;
  flex-wrap: wrap;
  padding: ${theme.units.fieldPadding};

  width: ${(props) => props.width || 180}px;
  align-items: ${(props) => props.alignItems || 'center'};
  justify-content: ${(props) => props.justifyContent || 'flex-start'};

  :first-child {
    width: 250px;
    position: sticky;
    left: 0px;
    z-index: 10;
  }

  @media only screen and (max-width: ${theme.units.gridBreakpointSmall}px) {
    :first-child {
      position: relative;
      max-width: 50vw;
    }
  }

  > * {
    margin: 0;
  }
`;

export const ScheduleTableCol = ({
  children,
  ...props
}: ScheduleTableRowProps) => (
  <ScheduleTableColContainer {...props}>{children}</ScheduleTableColContainer>
);
