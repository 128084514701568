import { Pagination } from '@perts/ui';

export const PaginationUseQuery = ({ useQueryResults, onChange }: any) => {
  const { isFetching, isLoading, isPreviousData, pagination } = useQueryResults;

  // Only display the pagination loading spinner when we are loading the data
  // needed for the current page for the first time.
  const isLoadingPagination = (isLoading || isPreviousData) && isFetching;

  return (
    <Pagination
      total={pagination.total}
      pageSize={pagination.pageSize}
      pageIndex={pagination.pageIndex}
      onChange={onChange}
      isLoading={isLoadingPagination}
    />
  );
};
