import { Redirect } from 'react-router-dom';

import Loading from 'components/Loading';
import { ClassReport23, toArchiveClassReportId, useParams } from 'pages';
import {
  selectMostCurrentReport,
  useDatasetTeam,
  useReportsQueryByParams,
} from 'models';

const OldReportRedirect = () => {
  const { groupId, classId } = useParams();

  const reportsQuery = useReportsQueryByParams();

  if (reportsQuery.isLoading) {
    return <Loading />;
  }

  const classReports = (reportsQuery.data || []).filter(
    (r) => r.team_id === classId,
  );
  const mostCurrentReport = selectMostCurrentReport(classReports);

  if (!mostCurrentReport) {
    return <>No report found.</>;
  }

  return (
    <Redirect
      to={toArchiveClassReportId(groupId, classId, mostCurrentReport.uid)}
    />
  );
};

export const ArchiveClassReport = () => {
  const { classId } = useParams();

  const datasetQuery = useDatasetTeam(classId);

  if (datasetQuery.isLoading) {
    return <Loading />;
  }

  if (!datasetQuery.data?.results) {
    return <OldReportRedirect />;
  }

  return <ClassReport23 />;
};
