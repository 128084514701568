/**
 * Custom hook that extends useState with state synchronization across
 * instances using a broadcast channel.
 *
 * See: https://github.com/PERTS/perts/pull/2481#discussion_r1684968026
 * We'd like to limit use to Reports. If this tool becomes tempting to use
 * everywhere, please consider using either useContext or Redux to solve the
 * state management needs.
 */

import { useState } from 'react';
import { useBroadcast } from '@perts/ui';

type UseStateSync<T> = [
  state: T,
  setState: React.Dispatch<React.SetStateAction<T>>,

  /**
   * Broadcasts the current state to other instances using the same channel.
   */
  syncState: (customState?: T) => void,
];

export function useStateSync<T>(
  initialValue: T,
  syncChannelName: string,
): UseStateSync<T> {
  const [state, setState] = useState(initialValue);
  const { broadcast, listen } = useBroadcast(syncChannelName);

  const syncState = (customState) => broadcast(customState || state);

  listen((incomingBroadcastedValue) => {
    const hasChanged = incomingBroadcastedValue !== state;

    if (hasChanged) {
      setState(incomingBroadcastedValue);
    }
  });

  return [state, setState, syncState];
}
