import { locales } from '../../i18n';

type SubjectNameStrings = {
  en: string;
  es: string;
};

export const sesStudent23OptionsDisagreeAgree = (
  subjectNameStrings: SubjectNameStrings,
) => ({
  [locales.ENGLISH]: [
    {
      label: 'Strongly Disagree',
      value: 1,
    },
    {
      label: 'Disagree',
      value: 2,
    },
    {
      label: 'Slightly Disagree',
      value: 3,
    },
    {
      label: 'Slightly Agree',
      value: 4,
    },
    {
      label: 'Agree',
      value: 5,
    },
    {
      label: 'Strongly Agree',
      value: 6,
    },
    {
      label: `Not taking a ${subjectNameStrings.en} class right now`,
      value: 0,
    },
  ],
  [locales.SPANISH]: [
    {
      label: 'Totalmente en desacuerdo',
      value: 1,
    },
    {
      label: 'En desacuerdo',
      value: 2,
    },
    {
      label: 'Un poco en desacuerdo',
      value: 3,
    },
    {
      label: 'Un poco de acuerdo',
      value: 4,
    },
    {
      label: 'De acuerdo',
      value: 5,
    },
    {
      label: 'Totalmente de acuerdo',
      value: 6,
    },
    {
      label: `No tomar una clase de ${subjectNameStrings.es} en este momento`,
      value: 0,
    },
  ],
});

export const sesStudent23OptionsMotivated = (
  subjectNameStrings: SubjectNameStrings,
) => ({
  [locales.ENGLISH]: [
    {
      label: 'Not at all motivated',
      value: 1,
    },
    {
      label: 'Only a little motivated',
      value: 2,
    },
    {
      label: 'Somewhat motivated',
      value: 3,
    },
    {
      label: 'Very motivated',
      value: 4,
    },
    {
      label: 'Extremely motivated',
      value: 5,
    },
    {
      label: `Not taking a ${subjectNameStrings.en} class right now`,
      value: 0,
    },
  ],
  [locales.SPANISH]: [
    {
      label: 'No en absoluto motivado',
      value: 1,
    },
    {
      label: 'Solo un poco motivado',
      value: 2,
    },
    {
      label: 'Algo motivado',
      value: 3,
    },
    {
      label: 'Muy motivado',
      value: 4,
    },
    {
      label: 'Extremadamente motivado',
      value: 5,
    },
    {
      label: `No tomar una clase de ${subjectNameStrings.es} en este momento`,
      value: 0,
    },
  ],
});
