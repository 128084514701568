// Input text field that allows for making edits in place.
// Clicking the edit icon enables editing mode.
// "Save" (to figure out) is invoked when confim icon/form submit occurs or the
//   enter key is pressed.
// "Save" cannot be invoked when there are errors.
// Clicking the cancel icon disables editing mode. Resets back to initial value.
// Pressing the escape key disabled editing mode. Resets back to initial value.

import React, { useState } from 'react';
import styled from 'styled-components/macro';
import { Tooltip } from 'antd';

import { theme, IconButton, Show, TextField } from '../..';
import { IconCheckCircle, IconEdit, IconCancel } from '../Icon';

export type EditInPlaceProps = {
  // If true, the input element will be disabled.
  disabled?: boolean;

  // If true, the input will indicate an error.
  error?: boolean;

  // The id of the input element.
  id: string;

  // The initial value of the input element.
  initialValue: any;

  // Name attribute of the input element.
  name: string;

  // Label to use in aria-label
  label: string;

  // onBlur event handler.
  // https://formik.org/docs/api/useField#fieldinputpropsvalue
  onBlur: (event: React.FocusEvent<HTMLInputElement>) => void;

  // onChange event handler.
  // https://formik.org/docs/api/useField#fieldinputpropsvalue
  onChange: (event: React.FormEvent<HTMLInputElement>) => void;

  // A function to change the field's value.
  // https://formik.org/docs/api/useField#fieldhelperprops
  setValue: (value: any) => void;

  // The function called that will submit the form.
  submitForm: () => void;

  // The current value of the input element.
  value: any;

  // The custom message to empty value
  emptyMessage?: React.ReactNode;
};

const EmptyText = styled.span`
  font-style: italic;
`;

const EditInPlaceStyled = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  > :not(:last-child) {
    /* Spacing so that focus outline doesn't overlap other elements. */
    margin-right: 5px;
  }
`;

const EditInPlaceClickToEdit = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  cursor: pointer;
`;

const EditInPlaceStatic = styled.div`
  margin: ${theme.units.paddingSm} ${theme.units.paddingSm}
    ${theme.units.paddingSm} 0;

  // The border is not removed to avoid jump when we display the text field
  border: 1px solid transparent;

  white-space: nowrap;
  overflow: hidden;
`;

export const EditInPlace = ({
  disabled,
  error,
  id,
  initialValue,
  name,
  onBlur,
  onChange,
  setValue,
  submitForm,
  value,
  emptyMessage,
  label,
  ...rest
}: EditInPlaceProps) => {
  const [editing, setEditing] = useState(false);

  const save = () => {
    if (!disabled) {
      // Submit Form.
      submitForm();

      // Turn off editing.
      setEditing(false);
    }
  };

  const cancel = () => {
    // Reset value back to initial value.
    setValue(initialValue);

    // Turn off editing.
    setEditing(false);
  };

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      if (!disabled) {
        save();
      }
    }

    if (e.key === 'Escape') {
      e.preventDefault();
      cancel();
    }
  };

  return (
    <>
      <Show when={editing}>
        <EditInPlaceStyled>
          <TextField
            id={id}
            name={name}
            label=""
            aria-label={label}
            // label="Edit Member Name"
            // labelPlacement="hidden"
            onBlur={onBlur}
            onChange={onChange}
            onKeyDown={onKeyDown}
            value={value}
            displayError={false}
            {...rest}
          />

          <IconButton
            aria-label="Save Changes"
            onClick={save}
            disabled={disabled}
            noBorder
          >
            <IconCheckCircle />
          </IconButton>

          <IconButton aria-label="Cancel Changes" onClick={cancel} noBorder>
            <IconCancel />
          </IconButton>
        </EditInPlaceStyled>
      </Show>

      <Show when={!editing}>
        <EditInPlaceClickToEdit onClick={() => setEditing(true)}>
          {value ? (
            <EditInPlaceStatic aria-label={label} {...rest}>
              <Tooltip
                title={value}
                trigger={['hover', 'click']}
                placement="topLeft"
              >
                {value}
              </Tooltip>
            </EditInPlaceStatic>
          ) : (
            <EditInPlaceStatic aria-label={label} {...rest}>
              <EmptyText data-testid="empty-message">{emptyMessage}</EmptyText>
            </EditInPlaceStatic>
          )}

          <IconButton
            aria-label="Edit"
            onClick={() => setEditing(true)}
            noBorder
          >
            <IconEdit />
          </IconButton>
        </EditInPlaceClickToEdit>
      </Show>
    </>
  );
};

EditInPlace.defaultProps = {
  emptyMessage: 'no value set',
};
