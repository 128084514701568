import { Tooltip } from 'antd';
import styled from 'styled-components';

import { IconWarning } from '@perts/ui';

import { FIDELITY_THRESHOLD } from 'config';

// Note: styling the Icon itself directly, while also nesting it inside
// an antd Tooltip, triggers the error
//   Warning: Function components cannot be given refs. Attempts to access this
//   ref will fail. Did you mean to use React.forwardRef()?
// So we don't do this:
// const IconStyled = styled(IconWarning)`...`;

const IconStyleWrapper = styled.span`
  svg {
    position: relative;
    top: 2px;
    margin: 0 5px;
  }

  h2 svg {
    top: 0;
  }
`;

const fidelityWarningText = `Below ${FIDELITY_THRESHOLD}% threshold.`;

type Props = {
  title?: string;
};

export const FidelityWarningIcon = ({ title }: Props) => (
  <IconStyleWrapper>
    <Tooltip title={title || fidelityWarningText} trigger={['hover', 'click']}>
      <IconWarning aria-label={title || fidelityWarningText} />
    </Tooltip>
  </IconStyleWrapper>
);
