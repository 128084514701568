import styled from 'styled-components/macro';

const TagsDividerOuter = styled.div`
  height: 24px;
  display: flex;
  align-items: center;
`;

const TagsDividerBar = styled.div`
  height: 20px;
  border-left: 1px solid #ccc;
`;

export const SelectFilterDivider = () => (
  <TagsDividerOuter>
    <TagsDividerBar />
  </TagsDividerOuter>
);
