// https://docs.google.com/spreadsheets/d/1ZX4hcUol-_frFLfUYRnZ-UPEvpGsVOsWsJRw1g5tDBM

import keyBy from 'lodash/keyBy';

import { locales } from '../i18n';
import { CompositeMetricConfig, MetricItemConfig, QuestionType } from './types';
import { catalyze24CompletelyTrueOptions } from './answerOptions/catalyze24CompletelyTrueOptions';

export const plc24ImpactOnPracticeItemConfig: MetricItemConfig[] = [
  {
    label: 'plc_have_applied',
    likertN: 4,
    minGood: 3,
    options: catalyze24CompletelyTrueOptions,
    prompts: {
      [locales.ENGLISH]:
        'In the past month, I have applied a strategy or practice from my ' +
        'PLC to my classroom teaching.',
    },
  },
  {
    label: 'plc_applied_practice_example',
    prompts: {
      [locales.ENGLISH]:
        'If true, can you provide an example of a strategy or concept that ' +
        'you applied in your teaching practice?',
    },
    questionType: QuestionType.OPEN_RESPONSE,
  },
  {
    label: 'plc_other_feedback',
    prompts: {
      [locales.ENGLISH]:
        'Is there any other feedback you would like to provide about your PLC?',
    },
    questionType: QuestionType.OPEN_RESPONSE,
  },
];

export const plc24ImpactOnPracticeConfig: CompositeMetricConfig = {
  label: 'plc-impact-on-practice',
  items: plc24ImpactOnPracticeItemConfig,
  itemIndex: keyBy(plc24ImpactOnPracticeItemConfig, 'label'),
  name: 'Impact on Teacher Practice: Applied Practices/Strategies',
  description:
    'Applying new strategies is important for professional learning ' +
    'communities as it allows educators to translate collaborative learning ' +
    'and insights into their own individual classroom actions that can ' +
    'improve teaching effectiveness and student engagement.',
};
