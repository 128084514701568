// https://docs.google.com/spreadsheets/d/1ZX4hcUol-_frFLfUYRnZ-UPEvpGsVOsWsJRw1g5tDBM

import keyBy from 'lodash/keyBy';

import { CompositeMetricConfig, MetricItemConfig } from './types';
import { catalyze23AnswerOptions } from './answerOptions';

export const inclusiveLeadershipItemConfig: MetricItemConfig[] = [
  {
    label: 'inclusive_leadership_ideas',
    likertN: 5,
    minGood: 4,
    options: catalyze23AnswerOptions,
    prompts: {
      en: 'Leaders take my ideas and concerns seriously.',
    },
  },
  {
    label: 'inclusive_leadership_autonomy',
    likertN: 5,
    minGood: 4,
    options: catalyze23AnswerOptions,
    prompts: {
      en: 'I have the autonomy to make decisions that impact my work.',
    },
  },
  {
    label: 'inclusive_leadership_share',
    likertN: 5,
    minGood: 4,
    options: catalyze23AnswerOptions,
    prompts: {
      en: 'Leaders actively work to share power.',
    },
  },
  {
    label: 'inclusive_leadership_reasonable',
    likertN: 5,
    minGood: 4,
    options: catalyze23AnswerOptions,
    prompts: {
      en: 'I trust that leaders will make reasonable and justified decisions.',
    },
  },
];

export const inclusiveLeadershipConfig: CompositeMetricConfig = {
  label: 'inclusive-leadership',
  items: inclusiveLeadershipItemConfig,
  itemIndex: keyBy(inclusiveLeadershipItemConfig, 'label'),
};
