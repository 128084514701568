import { useParams } from 'react-router-dom';
import { GuideBox, HelpText, Link } from '@perts/ui';
import { PagesParams, toGroupClasses, toGroupMembersAdd } from 'pages';
import { useTerms } from 'components/TermsContext';
import { helpArticles } from 'config';

export const MembersGuideBox = () => {
  const { groupId = '' } = useParams<PagesParams>();
  const terms = useTerms();

  return (
    <GuideBox
      action={
        <Link to={toGroupMembersAdd(groupId)}>
          Add {terms.groupMembers.toLowerCase()} to collaborate.
        </Link>
      }
      faq={
        <HelpText articleId={helpArticles.guideboxMembers}>
          Who should I invite to my {terms.group}?
        </HelpText>
      }
      next={<Link to={toGroupClasses(groupId)}>{terms.classes}</Link>}
    />
  );
};
