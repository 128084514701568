import styled from 'styled-components/macro';
import { css } from 'styled-components';
import theme from '../theme';

type FlagTagProps = {
  // Type of flag to define background color
  type: 'hidden' | 'pending' | 'conflict';

  // Text to replace default text to any type
  text?: string;

  // Indicates if the main component has children
  hasChildren?: boolean;

  theme?: any;
};

export type FlagProps = FlagTagProps & {
  children?: React.ReactNode;
};

const FlagContainer = styled.div`
  position: relative;
  display: inline-flex;
`;

const FlagTag = styled.div<FlagTagProps>`
  position: relative;
  display: ${(props) => (props.type === 'hidden' ? 'none' : 'inline-flex')};
  border-radius: ${(props) => props.theme.units.borderRadius};
  color: ${(props) => props.theme.colors.white};

  padding: calc(${(props) => props.theme.units.fieldPaddingInternal} / 2)
    ${(props) => props.theme.units.fieldPaddingInternal};

  ${(props) =>
    props.hasChildren &&
    css`
      position: absolute;
      top: calc(100% - ${props.theme.units.fieldPaddingInternal});
      right: ${props.theme.units.fieldPaddingInternal};
    `};

  background: ${(props) =>
    props.type === 'conflict'
      ? props.theme.colors.redDark
      : props.type === 'pending'
      ? props.theme.colors.primaryDark
      : ''};
`;

const FlagTagText = styled.span`
  font-size: 14px;
  text-transform: capitalize;
  font-weight: bold;
  line-height: 1;
`;

FlagTag.defaultProps = {
  theme,
};

export const Flag = ({ children, text, type = 'hidden' }: FlagProps) => (
  <FlagContainer>
    {children}
    <FlagTag type={type} hasChildren={Boolean(children)}>
      <FlagTagText>{text || type}</FlagTagText>
    </FlagTag>
  </FlagContainer>
);
