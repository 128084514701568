export type MetricItemConfig = {
  label: string;
  likertN?: number;
  likertReverse?: boolean;
  minGood?: number;
  prompts: {
    en?: string;
    es?: string;
  };
  options?: {
    en?: { label: string; value: any }[];
    es?: { label: string; value: any }[];
  };
  questionType?: QuestionType;
};

export type CompositeMetricConfig = {
  label: string;
  items: MetricItemConfig[];
  itemIndex: {
    [itemLabel: string]: MetricItemConfig;
  };

  // Currently would be redundant with the `metrics` table in the db. But I
  // think storing it here where all code can access it is probably better in
  // the long run.
  name?: string;
  description?: string;
};

export type ProgramMetricConfig = {
  metrics: CompositeMetricConfig[];
  metricIndex: {
    [metricLabel: string]: CompositeMetricConfig;
  };
};

export type ProgramMetricIndex = {
  [programLabel: string]: ProgramMetricConfig;
};

export enum QuestionType {
  // Default for Saturn questions. Shows a set of radio options with numeric
  // values.
  LIKERT = 'LIKERT',

  // Shows a textarea. Not related to the open response questions that are
  // automatically added to pages with likert questions.
  OPEN_RESPONSE = 'OPEN_RESPONSE',
}
