import { useQuery, QueryKey, UseQueryOptions } from 'react-query';

type Pagination = {
  pageSize?: number;
  pageIndex?: number;
  totalPages?: number;
  // This isn't going to be the actual total results since the server does not
  // provide this data, but it'll be close enough for the Pagination component
  // to properly render out pages.
  total?: number;
  order?: string;
};

export const useQueryWithPagination = <ResultType>(
  queryKey: QueryKey,
  // DF: Can't seem to figure out how to properly use the QueryFunction type
  // here. All my attempts result in the typing of result.error to be incorrect.
  queryFn: any,
  queryOptions: UseQueryOptions<any, any, any, any> = {},
) => {
  type ResultTypeWithPagination = { data: ResultType; pagination: Pagination };
  type ResultTypeOptionalPagination = ResultType | ResultTypeWithPagination;

  function isResultWithPagination(
    data: ResultTypeOptionalPagination | undefined,
  ): data is ResultTypeWithPagination {
    return data != null && typeof data === 'object' && 'data' in data;
  }

  const result = useQuery<ResultTypeOptionalPagination, Error>(
    queryKey,
    queryFn,
    queryOptions,
  );

  let data: ResultType | undefined;
  let pagination: Pagination = {};

  if (isResultWithPagination(result.data)) {
    ({ data, pagination } = result.data);
  } else {
    ({ data } = result);
  }

  return {
    ...result,
    data,
    pagination,
  };
};
