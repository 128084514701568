// https://docs.google.com/spreadsheets/d/1EKKzZjI-eCtekB_S_SsNnBQpGEKVIKueZIkBD6W8QJc

import keyBy from 'lodash/keyBy';

import { locales } from '../i18n';
import { CompositeMetricConfig, MetricItemConfig } from './types';

const options = {
  [locales.ENGLISH]: [
    {
      label: 'Not at all motivated',
      value: 1,
    },
    {
      label: 'Only a little motivated',
      value: 2,
    },
    {
      label: 'Somewhat motivated',
      value: 3,
    },
    {
      label: 'Very motivated',
      value: 4,
    },
    {
      label: 'Extremely motivated',
      value: 5,
    },
  ],
  [locales.SPANISH]: [
    {
      label: 'No en absoluto motivado',
      value: 1,
    },
    {
      label: 'Solo un poco motivado',
      value: 2,
    },
    {
      label: 'Algo motivado',
      value: 3,
    },
    {
      label: 'Muy motivado',
      value: 4,
    },
    {
      label: 'Extremadamente motivado',
      value: 5,
    },
  ],
};

export const effortItemConfig: MetricItemConfig[] = [
  {
    label: 'effort_motivated',
    prompts: {
      [locales.ENGLISH]: `Overall, how motivated are you to work hard in school?`,
      [locales.SPANISH]:
        `En general, ¿qué tan motivado/a estás para trabajar duro en la ` +
        `escuela?`,
    },
    options,
    minGood: 4,
  },
];

export const effortConfig: CompositeMetricConfig = {
  label: 'effort',
  items: effortItemConfig,
  itemIndex: keyBy(effortItemConfig, 'label'),
};
