// https://docs.google.com/spreadsheets/d/1ZX4hcUol-_frFLfUYRnZ-UPEvpGsVOsWsJRw1g5tDBM

import keyBy from 'lodash/keyBy';

import { locales } from '../i18n';
import { CompositeMetricConfig, MetricItemConfig } from './types';

export const plc24TeachingLearningFocusItemConfig: MetricItemConfig[] = [
  {
    label: 'plc_meeting_is_focused',
    likertN: 4,
    minGood: 3,
    options: {
      [locales.ENGLISH]: [
        { value: 4, label: 'All/nearly all of the time' },
        { value: 3, label: 'Most of the time' },
        { value: 2, label: 'Some of the time' },
        { value: 1, label: 'None/very little of the time' },
      ],
    },
    prompts: {
      [locales.ENGLISH]:
        'About what proportion of your PLC meeting time is typically spent ' +
        'focused on teaching and learning in this subject area?',
    },
  },
];

export const plc24TeachingLearningFocusConfig: CompositeMetricConfig = {
  label: 'plc-teaching-learning-focus',
  items: plc24TeachingLearningFocusItemConfig,
  itemIndex: keyBy(plc24TeachingLearningFocusItemConfig, 'label'),
  name: 'Teaching & Learning Focus',
  description:
    'A focus on particular content and pedagogical skills specific to that ' +
    "content can help develop teachers' knowledge and improve their practice " +
    'teaching that content.',
};
