import { Field, Formik } from 'formik';
import styled from 'styled-components/macro';

import { Button, Checkbox, Col, Row, theme, Show } from '@perts/ui';

import { User } from 'models';
import Loading from 'components/Loading';
import VerifyEmailErrors from './VerifyEmailErrors';
import VerifyEmailSent from './VerifyEmailSent';

const FormStyled = styled.form`
  label {
    text-align: left;
    padding: ${theme.units.paddingMd} 0;
  }
`;

const MessageVerifyEmail = () => (
  <Row>
    <Col>
      For your security, we need to confirm your email address. Click the button
      below to continue.
    </Col>
  </Row>
);

type Props = {
  agreedEducator: boolean;
  agreedLegal: boolean;
  emailVerified: boolean;

  /**
   * If the authenticated user's email address does not match the `email`
   * address invited (based on query params), this will be set to the invited
   * email address.
   */
  mismatchedEmail?: string;

  handleBackToSignIn: (event: any) => void;
  onSubmit: (values: any) => void;
  sendAttempted: boolean;
  sendEmailErrors: number;
  user: User;
  validate: (values: any) => any;
};

export const VerifyEmailRender = ({
  agreedEducator,
  agreedLegal,
  emailVerified,
  handleBackToSignIn,
  onSubmit,
  sendAttempted,
  sendEmailErrors,
  user,
  validate,
}: Props) => {
  const initialValues = {
    educator: agreedEducator,
    legal: agreedLegal,
  };

  const formAlreadyComplete = agreedEducator && agreedLegal && emailVerified;

  if (!user || formAlreadyComplete) {
    return <Loading />;
  }

  return (
    <>
      <Show when={!sendAttempted}>
        <Formik
          initialValues={initialValues}
          validate={validate}
          validateOnMount={true}
          onSubmit={onSubmit}
        >
          {({ handleSubmit, isSubmitting, isValid }) => (
            <FormStyled onSubmit={handleSubmit}>
              <Show when={!agreedEducator || !agreedLegal}>
                <Field name="educator">
                  {({ field, form: { values } }) => (
                    <Checkbox
                      checked={values.educator}
                      label={
                        <>
                          I am an educator or I support educational institutions
                          in an official and authorized capacity.
                        </>
                      }
                      {...field}
                    />
                  )}
                </Field>
                <Field name="legal">
                  {({ field, form: { values } }) => (
                    <Checkbox
                      checked={values.legal}
                      label={
                        <>
                          I will use PERTS services only in a way that complies
                          with all applicable laws, regulations, and contractual
                          obligations, including those governing student privacy
                          or use of student data.
                        </>
                      }
                      {...field}
                    />
                  )}
                </Field>
              </Show>

              {/* Extra message if they need to verify email. */}
              <Show when={!emailVerified}>
                <MessageVerifyEmail />
              </Show>

              <Row>
                <Col>
                  <Button
                    type="submit"
                    data-test="VerifyEmailButton"
                    fullWidth={true}
                    loading={isSubmitting}
                    disabled={isSubmitting || !isValid}
                  >
                    <Show when={emailVerified}>Submit</Show>
                    <Show when={!emailVerified}>Send A Verification Link</Show>
                  </Button>
                </Col>
              </Row>

              <Row>
                <Col>
                  <Button
                    color="secondary"
                    fullWidth={true}
                    onClick={handleBackToSignIn}
                  >
                    Back to Sign In
                  </Button>
                </Col>
              </Row>
            </FormStyled>
          )}
        </Formik>
      </Show>
      <Show when={sendAttempted && sendEmailErrors === 0}>
        <VerifyEmailSent email={user.email} />
      </Show>
      <Show when={sendEmailErrors !== 0}>
        <VerifyEmailErrors />
      </Show>
    </>
  );
};
