import { Redirect, Route, Switch } from 'react-router-dom';

import { Loading } from 'components';
import {
  ClassRosterRouter,
  ClassesRouter,
  GroupsRedirect,
  MembersRouter,
  ParticipationRouter,
  ReportsRouter,
  SchedulesRouter,
  SurveysRouter,
  toClassReport,
  toClassReportSettings,
  toGroupClasses,
  toGroupClassRoster,
  toGroupMembers,
  toGroupParticipation,
  toGroupReport,
  toGroupReports,
  toGroupReportSettings,
  toGroupSchedules,
  toGroupSurveys,
  toHomeNoProgram,
} from 'pages';
import { useCurrentUserQuery } from 'models';
import { usePermissions } from 'utils';

export const PagesRouterGroup = () => {
  const { isLoading } = useCurrentUserQuery();
  const { mayGroupUserDataView, mayGroupDataView } = usePermissions();

  if (isLoading) {
    return <Loading />;
  }

  if (!mayGroupUserDataView && !mayGroupDataView) {
    return <Redirect to={toHomeNoProgram()} />;
  }

  return (
    <Switch>
      <Route path={toGroupMembers()}>
        <MembersRouter />
      </Route>

      <Route
        path={[
          toGroupReport(),
          toGroupReports(),
          toGroupReportSettings(),
          toClassReport(),
          toClassReportSettings(),
        ]}
      >
        <ReportsRouter />
      </Route>

      <Route path={toGroupClassRoster()}>
        <ClassRosterRouter />
      </Route>

      <Route path={toGroupClasses()}>
        <ClassesRouter />
      </Route>

      <Route path={toGroupSchedules()}>
        <SchedulesRouter />
      </Route>

      <Route path={toGroupSurveys()}>
        <SurveysRouter />
      </Route>

      <Route path={toGroupParticipation()}>
        <ParticipationRouter />
      </Route>

      {/* Catchall redirect */}
      <GroupsRedirect />
    </Switch>
  );
};
