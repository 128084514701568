import styled, { css } from 'styled-components/macro';
import { theme } from '@perts/ui';

type Props = {
  bad?: boolean;
  good?: boolean;
};

export const HighlightedText = styled.div`
  display: inline-block;
  font-size: 1.2em;
`;

// To make certain numbers/statistics stand out with size and color.
export const HighlightedStat = styled.span<Props>`
  display: inline-block;
  font-size: 1.2em;
  font-weight: bold;

  ${(props) =>
    props.bad === true &&
    css`
      // WCAG AA as foreground on white
      // darkened version of theme.colors.warning.
      color: ${theme.colors.redDark};
    `};

  ${(props) =>
    props.good === true &&
    css`
      // WCAG AA as foreground on white
      // darkened version of theme.colors.success.
      color: ${theme.colors.greenDark};
    `};
`;
