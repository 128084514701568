import { useContext } from 'react';

import { Group } from 'models';
import {
  toGroupClasses,
  toGroupMembers,
  toGroupParticipation,
  toGroupReports,
} from 'pages';

import {
  Card,
  Col,
  IconUserVoice,
  IconReport,
  IconUsergroup,
  IconConversation,
  Row,
  Text,
} from '@perts/ui';
import TermsContext from 'components/TermsContext';

export type NetworkGroupCardProps = {
  // The Group to display.
  group: Group;

  toggle: React.ReactElement | null;
};

export const NetworkGroupCard = ({ group, toggle }: NetworkGroupCardProps) => {
  const terms = useContext(TermsContext);

  return (
    <Card toggle={toggle}>
      <Card.Title>
        <Row>
          <Col>
            <Text as="h3">{group.name}</Text>
          </Col>
        </Row>
      </Card.Title>
      <Card.Content>
        <Row>
          <Col cols={6} colsMd={12}>
            <Card>
              <Card.Title to={toGroupMembers(group.short_uid)} toText="Edit">
                <Text as="h3" overflowWrap="anywhere">
                  <IconUsergroup />
                  <span>
                    {terms.groupMembers} ({group.num_users})
                  </span>
                </Text>
              </Card.Title>
            </Card>
          </Col>

          <Col cols={6} colsMd={12}>
            <Card>
              <Card.Title to={toGroupClasses(group.short_uid)} toText="Edit">
                <Text as="h3" overflowWrap="anywhere">
                  <IconUserVoice />
                  <span>
                    {terms.classes} ({group.num_teams})
                  </span>
                </Text>
              </Card.Title>
            </Card>
          </Col>

          <Col cols={6} colsMd={12}>
            <Card>
              <Card.Title
                to={toGroupParticipation(group.short_uid)}
                toText="View"
              >
                <Text as="h3" overflowWrap="anywhere">
                  <IconConversation />
                  <span>Participation</span>
                </Text>
              </Card.Title>
            </Card>
          </Col>

          <Col cols={6} colsMd={12}>
            <Card>
              <Card.Title to={toGroupReports(group.short_uid)} toText="View">
                <Text as="h3" overflowWrap="anywhere">
                  <IconReport />
                  <span>Reports</span>
                </Text>
              </Card.Title>
            </Card>
          </Col>
        </Row>
      </Card.Content>
    </Card>
  );
};
