import styled from 'styled-components/macro';

export const ChipsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;

  > * {
    margin: 5px;
  }
`;
