import { ReactElement, useMemo } from 'react';

import { OrganizationData, OrganizationUserData } from '@perts/model';
import { concatNames, formatPercent } from '@perts/util';

import { Class, selectClassFacilitators, User } from 'models';
import { getUserName, sortNumeric } from 'utils';
import { useTerms } from 'components/TermsContext';
import { Table } from './Table';

type RespondentDataRow =
  | OrganizationData['team_respondents_by_cycle'][number]
  | OrganizationUserData['team_respondents_by_cycle'][number];

type DataRow = RespondentDataRow & {
  classLeadNames: string;
};

type TableProps = {
  classRespondentsByCycle: RespondentDataRow[];
  users: User[];
};

// Structure commonly used by react-table.
type TableRow = { original: DataRow };

// Calculate participation rate as a ratio, handling missing data.
const ratio = (row: DataRow, i: number) =>
  row.respondents_by_cycle[i] === undefined ||
  row.participants_by_cycle[i] === undefined
    ? undefined
    : row.respondents_by_cycle[i] / row.participants_by_cycle[i];

// Format ratio as a percent.
const blankValue = '—';
const formatRowAsPercent = (row: DataRow, i: number) =>
  formatPercent(ratio(row, i), blankValue);

// Renders participation rate percents in cells.
const cellPercent =
  (i: number) =>
  // Important to type return value as ReactElement, because accidently
  // returning undefined will break the app with "Nothing was returned from
  // render." Setting this type will help guarantee the code can handle when the
  // data doesn't have a value at index `i`.
  ({ row }: { row: TableRow }): ReactElement =>
    <>{formatRowAsPercent(row.original, i)}</>;

const cellCount =
  (i: number) =>
  // Important to type return value as ReactElement, because accidently
  // returning undefined will break the app with "Nothing was returned from
  // render." Setting this type will help guarantee the code can handle when the
  // data doesn't have a value at index `i`.
  ({ row }: { row: TableRow }): ReactElement =>
    <>{row.original.respondents_by_cycle[i] || 0}</>;

export const ClassParticipationTable = ({
  classRespondentsByCycle,
  users,
}: TableProps) => {
  const terms = useTerms();

  // Find the max number of cycles in the data so we know how many columns to
  // display in the table.
  const numCycles = classRespondentsByCycle.reduce(
    (n, row) =>
      Math.max(
        n,
        row.respondents_by_cycle.length,
        row.participants_by_cycle.length,
      ),
    1,
  );

  const data: DataRow[] = useMemo(
    () =>
      classRespondentsByCycle.map((respondentDataRow) => {
        const facilitators = selectClassFacilitators(
          { uid: respondentDataRow.team_id } as unknown as Class,
          users,
        );
        return {
          ...respondentDataRow,
          classLeadNames: concatNames(facilitators.map(getUserName)),
        };
      }),
    [classRespondentsByCycle, users],
  );

  const columns = useMemo(() => {
    const cols = [
      {
        Header: 'Lead',
        id: 'classLeadNames',
        accessor: 'classLeadNames',
      },
      {
        Header: terms.class,
        id: 'className',
        accessor: 'name',
      },
      ...Array.from({ length: numCycles }).map((_, i) => ({
        Header: String(i + 1),
        id: `survey-${i}`,
        columns: [
          {
            Header: '%',
            id: `percent-${i}`,
            // Accessors allow column sorting. Should provide display values so
            // users understand the sort results.
            accessor: (row: DataRow) => formatRowAsPercent(row, i),
            Cell: cellPercent(i),
            disableGlobalFilter: true,
            sortType: (rowA: TableRow, rowB: TableRow) =>
              sortNumeric(
                ratio(rowA.original, i) || 0,
                ratio(rowB.original, i) || 0,
              ),
          },
          {
            Header: '#',
            id: `count-${i}`,
            // Accessors allow column sorting. Should provide display values so
            // users understand the sort results.
            accessor: (row: DataRow) => row.respondents_by_cycle[i],
            Cell: cellCount(i),
            disableGlobalFilter: true,
            sortType: (rowA: TableRow, rowB: TableRow) =>
              sortNumeric(
                rowA.original.respondents_by_cycle[i] || 0,
                rowB.original.respondents_by_cycle[i] || 0,
              ),
          },
        ],
      })),
    ];

    return cols;
  }, [numCycles, terms]);

  return <Table columns={columns} data={data} numCycles={numCycles} />;
};
