import { Terms } from 'components';
import { Class, Group } from 'models';

import {
  ClassSettingsSignOnProps,
  getPortalMessageFromFormValues as getPortalMessage,
  getRule,
} from 'pages/Classes/shared';
import { RosterSignOnTypeFormValues } from './RosterSignOnTypeForm';

export const getRosterSignOnTypeClassProps = (
  initialValues,
  values,
): ClassSettingsSignOnProps => {
  values.rule = getRule(values.participant_pattern, values.participant_ending);

  // If the changing portal type has anything to do with the student ID option
  // (changing from, or to any classes with that setting), then disable the
  // sign-on rule and reset the portal message to match. We choose this because
  // student ID rules aren't compatible with other portal types.
  const isBatchEditForm = initialValues.classes?.length > 1;
  const someFromStudentId =
    isBatchEditForm &&
    initialValues.classes.some((cls) => cls.portal_type === 'student_id');

  let rosterSignOnProps;

  if (
    initialValues.portal_type === 'student_id' ||
    values.portal_type === 'student_id' ||
    someFromStudentId
  ) {
    rosterSignOnProps = {
      ...values,
      participant_pattern: null,
      participant_ending: null,
      portal_message: getPortalMessage({ ...values, ruleSwitch: false }),
    };
  } else {
    // Otherwise update the portal message based on the form values: whether
    // there's a rule defined, and which portal type is set.
    rosterSignOnProps = {
      ...values,
      portal_message: getPortalMessage({
        ...values,
        ruleSwitch: Boolean(values.rule),
      }),
    };
  }

  return {
    participant_pattern: rosterSignOnProps.participant_pattern,
    participant_ending: rosterSignOnProps.participant_ending,
    portal_message: rosterSignOnProps.portal_message,
    portal_type: rosterSignOnProps.portal_type,
  };
};

/**
 * Re https://github.com/perts/perts/issues/1593
 * Re https://github.com/perts/perts/issues/1595
 * Re https://github.com/PERTS/perts/pull/1600
 *
 * In order to reorganize code to allow for the new "default settings warning"
 * form feature, we needed to pull all the non-form field values out of Formik.
 * Some of the helper functions, like `getRosterSignOnTypeClassProps` were
 * written with the assumption that Formik contained this non-form data. To
 * allow use of these existing helper functions with minimal changes,
 * initialValues and values are recreated with the data they would have had.
 */

export const getRosterSignOnTypeSettings = ({
  values,
  terms,
  group,
  classes,
}: {
  values: RosterSignOnTypeFormValues;
  terms: Terms;
  group?: Group;
  // For a single Class, pass in `[cls]`.
  classes?: Class[];
}) => {
  if ((!group && !classes) || (group && classes)) {
    throw new Error(
      'getRosterSignOnSettings: Must specify _either_ a Group or Class[]',
    );
  }

  let deprecatedInitialValues = {};
  let deprecatedValues = {};

  if (group) {
    const groupDefaultSettings = group.default_settings || {};

    deprecatedInitialValues = {
      participant_pattern: groupDefaultSettings.participant_pattern,
      participant_ending: groupDefaultSettings.participant_ending,
      portal_message: groupDefaultSettings.portal_message,
      portal_type: groupDefaultSettings.portal_type,
    };

    deprecatedValues = {
      ...deprecatedInitialValues,
      // Sourced from Formik form values.
      portal_type: values.portalType,
      // Switching sign on / portal type can also change the portal message,
      // which may require a dynamic term.
      studentIdTerm: terms.studentIdPortalType,
    };
  }

  if (classes) {
    deprecatedInitialValues = {
      classes,
      portal_type: classes[0].portal_type,
    };

    deprecatedValues = {
      // Sourced from Formik form values.
      portal_type: values.portalType,
      // Sourced from first Class found. This works because the forms don't
      // directly alter these values and any updates will align all Classes to
      // the same values.
      portal_message: classes[0].portal_message,
      participant_pattern: classes[0].participant_pattern,
      participant_ending: classes[0].participant_ending,
      // Switching sign on / portal type can also change the portal message,
      // which may require a dynamic term.
      studentIdTerm: terms.studentIdPortalType,
    };
  }

  const rosterSignOnProps = getRosterSignOnTypeClassProps(
    deprecatedInitialValues,
    deprecatedValues,
  );

  return rosterSignOnProps;
};
