import { useState } from 'react';
import { isEmpty, omit, some } from 'lodash';
import styled from 'styled-components/macro';

import { getTokensFromText } from '@perts/util';
import { useQueryParams } from 'utils/useQueryParams';
import { TextField, Row, Col } from '@perts/ui';
import { useDebounce } from './useDebounce';

type FieldOptions = {
  label?: string;
  placeholder?: string;
  matchesText?: boolean;
};

// How you define the behavior of a filter token, e.g. if you want "rule:on" to
// mean "filter to classes which have a sign-on rule". See filterTokenOptions.ts
export type FilterTokenOption = {
  token: string;
  filter: (items: any) => boolean;
};

type UseFilterField = (
  // Complete item list
  items: any[],

  // Attributes array to consider on filter
  attributes: string[],

  // Options to customize filter field
  fieldOptions?: FieldOptions,

  // Filter token definitions. Optional.
  tokenFilterOptions?: FilterTokenOption[],
) => {
  // the value of the filter field
  filterText: string;

  // the value of the filter field, debounced
  filterTextDebounced: string;

  // `true` if the filterText has a length of zero.
  hasFilterText: boolean;

  // Items filtered according to input value
  filteredItems: any[];

  // Rendered component related to input
  FilterField: any;

  // Function to clear filter input
  clearFilter: () => void;
};

type ComponentProps = {
  filterText: string;
  setFilterText: (value: string) => void;
  totalItems: number;
  totalMatches: number;
  fieldOptions: FieldOptions;
};

const Form = styled.div`
  display: flex;
  flex: 1;
  font-size: 1rem;
`;

const FilterFieldComponent = ({
  filterText,
  setFilterText,
  totalItems,
  totalMatches,
  fieldOptions,
}: ComponentProps) => {
  const { set: setQueryParam } = useQueryParams();
  const textFieldOptions = omit(fieldOptions, 'matchesText');
  return (
    <Form>
      <Row alignItems="flex-end">
        <Col colsSm={12}>
          <TextField
            id="filter"
            type="search"
            name="filter"
            label="Filter"
            displayError={false}
            onChange={(e) => {
              setQueryParam('page', undefined);
              setQueryParam('elementId', undefined);
              setFilterText(e.currentTarget.value || '');
            }}
            value={filterText}
            {...textFieldOptions}
          />
        </Col>
      </Row>
    </Form>
  );
};

const useFilterField: UseFilterField = (
  items = [],
  attributes = [],
  fieldOptions = {},
  tokenFilterOptions = [],
) => {
  // The raw text from the field. May include a mixture of specialized filter
  // tokens like "rule:on" and other literal text to match.
  const [filterTextWithTokens, setFilterText] = useState('');
  let filteredItems = items;

  // Here filterText has had any tokens stripped out and can be use to match.
  const definedTokens = tokenFilterOptions.map((opt) => opt.token);
  const { text: filterText, tokens } = getTokensFromText(
    filterTextWithTokens,
    definedTokens,
  );

  const filterTextDebounced = useDebounce(filterText);

  // Successively apply any defined filter functions whose tokens are present.
  // Creates an implicit AND if multiple tokens are used.
  tokens.forEach((token) => {
    const filterFn = tokenFilterOptions.find((o) => o.token === token)?.filter;
    if (filterFn) {
      filteredItems = filteredItems.filter(filterFn);
    }
  });

  // Using the remaining items, compare the filterText literally against the
  // requested attributes.
  if (!isEmpty(filterText)) {
    filteredItems = filteredItems.filter((item) =>
      some(
        attributes,
        (key) =>
          item[key] &&
          item[key].toLowerCase().includes(filterText.trim().toLowerCase()),
      ),
    );
  }

  return {
    filterText,
    filterTextDebounced,
    hasFilterText: !isEmpty(filterTextWithTokens),
    filteredItems,
    FilterField: (
      <FilterFieldComponent
        filterText={filterTextWithTokens}
        setFilterText={setFilterText}
        fieldOptions={fieldOptions}
        totalItems={items.length}
        totalMatches={filteredItems.length}
      />
    ),
    clearFilter: () => {
      setFilterText('');
    },
  };
};

export default useFilterField;
