import { theme as pertsUiTheme } from '@perts/ui';
import merge from 'lodash/merge';

const themeColors = {
  primary: '#42ade8',
  primaryLight: '#64c3f7',

  secondary: '#283043',
  secondaryDark: '#1f2637',
  secondaryLight: '#3d435d',

  grayDark: '#202020',
  grayMedium: '#464646',
  grayLight: '#DDDDDD',

  // Copilot refers grayMedium as mediumGray
  mediumGray: '#464646',

  dark: '#015597',

  green: '#44E790',
  greenDark: '#2B8254',
  red: '#E74444',
  redDark: '#CE4545',
  yellow: '#E7DA44',

  black: '#000000',

  white: '#FFFFFF',
  offWhite: '#F7F8FA',
};

const aliases = {
  success: themeColors.green,
  danger: themeColors.red,
  warning: themeColors.red,
  caution: themeColors.red,
  info: themeColors.primary,
  ribbonShadowIncomplete: themeColors.black,
};

const allColors = { ...themeColors, ...aliases };

const tritonTheme = {
  ...allColors,
  palette: allColors,

  units: {
    // Existing theme config
    boldWeight: '700',
    buttonMargin: '8px',
    paragraphFontSize: '14px',
    paragraphSpacing: '14px', // see components/SectionItem/styles.css
    tableSpacing: '6px',
    sidebarWidth: '270px',
    desktopMinWidth: '(min-width: 800px)',
    formCollapseMinWidth: '(min-width: 1034px)',
    mobileMaxWidth: '(max-width: 799px)',
    // Takes into account the expanding or collapsing of the application side
    // bar. If the side bar is open (at > mobileMaxWidth) the content pane may
    // actually be quite narrow, and need some responsiveness.
    multiColumnMaxWidthCollapsed: '(max-width: 650px)',
    multiColumnMaxWidthExpanded: '(max-width: 950px)',
  },
  boxShadow: '5px 5px 15px 0px rgba(0,0,0,0.2);',
  boxShadowFocus: `0 0 3px 1px ${themeColors.black}`,
  boxShadowFocusInverse: `0 0 3px 2px ${themeColors.white}`,
  fontFamily: "'Open Sans', sans-serif",
  zIndex: {
    // ant-d applies z-index: 1 to some icons, and the app bar dropdown has
    // z-index: 11, so this should be between those.
    applicationBar: 8,
    // CaptainOnly component pieces should be lower than the ApplicationBar, so
    // that it can scroll under it.
    captainOnlyImage: 5,
    captainOnlyTextHover: 4,
    captainOnlyText: 3,
  },
};

export default merge(pertsUiTheme, tritonTheme);
