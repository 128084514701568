import styled from 'styled-components';
import { uniqueId } from 'lodash';

import { Checkbox } from '@perts/ui';

// Vertically center within 40px height so it lines up nicely with a text field.
const CheckboxWrapper = styled.div`
  height: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

type Props = {
  checked: boolean;
  disabled?: boolean;
  label: string;
  onChange: (args: any) => void;
};

export const DisaggregationTableGapCheckbox = ({
  checked,
  disabled = false,
  label,
  onChange,
}: Props) => (
  <CheckboxWrapper>
    <Checkbox
      checked={checked}
      disabled={disabled}
      indeterminate={false}
      label={label}
      name={uniqueId('show-gap-only')}
      onChange={onChange}
      value="show-gap-only"
    />
  </CheckboxWrapper>
);
