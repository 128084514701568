import styled, { css } from 'styled-components/macro';
import { theme } from 'components';

type Props = {
  align?: 'left' | 'right' | 'center';
  whiteSpace?: 'nowrap' | 'break-spaces' | 'normal';
};

export const ReactTableTh = styled.th<Props>`
  padding: ${theme.units.paddingSm};

  @media only screen and (max-width: ${theme.units.gridBreakpointMedium}px) {
    padding: ${theme.units.paddingSm};
  }

  background: ${theme.colors.grayLight};

  font-weight: normal;
  text-transform: uppercase;

  text-align: left;
  ${(props) =>
    props.align &&
    css`
      text-align: ${props.align};
    `}

  ${(props) =>
    props.whiteSpace &&
    css`
      white-space: ${props.whiteSpace};
    `}
`;
