import {
  AppContent,
  Sidebar,
  SidebarMargin,
  Topbar,
  SupportBox,
  Link,
  LogoResponsive,
  Row,
  Col,
  Program,
} from '../../';

export type AppWrapperProps = {
  // The page content rendered.
  children: React.ReactNode;

  // The menu content rendered.
  menu: React.ReactNode;

  // The top menu content rendered.
  menuTop: React.ReactNode;

  // The breadcrumbs navigation content rendered.
  breadcrumbsNavigation: React.ReactNode;

  // Controls appearance of the logo.
  program?: Program;

  // Allows the logo to link to the home page.
  toHome: string;
};

export const AppWrapper = ({
  breadcrumbsNavigation,
  children,
  menu,
  menuTop,
  program,
  toHome,
}: AppWrapperProps) => (
  <>
    <Sidebar>
      <div>
        <Link to={toHome}>
          <LogoResponsive program={program} />
        </Link>
        {menu}
      </div>

      <div>
        <SupportBox />
      </div>
    </Sidebar>

    <SidebarMargin>
      <Topbar>
        <Row alignItems="center">
          <Col vAlign="center">{breadcrumbsNavigation}</Col>

          <Col shrink hAlign="flex-end">
            {menuTop}
          </Col>
        </Row>
      </Topbar>

      <AppContent>{children}</AppContent>
    </SidebarMargin>
  </>
);
