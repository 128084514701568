import { RosterTargetGroupFormValues } from './RosterTargetGroupForm';

export const getRosterTargetGroupSettings = ({
  values,
}: {
  values: RosterTargetGroupFormValues;
}) => {
  const { targetGroupEnabled, targetGroupName } = values;

  if (targetGroupEnabled && targetGroupName) {
    return { target_group_name: targetGroupName };
  }

  return { target_group_name: null };
};
