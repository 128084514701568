import { Formik, Form } from 'formik';
import { lazily } from 'react-lazily';

import type { DataOverTime } from '@perts/model';
import { Col, Row, Select, theme as defaultTheme } from '@perts/ui';

import { useTerms } from 'components/TermsContext';
import { getCumulativeChange } from 'utils';
import type { ExperienceChildData } from 'components/ExperienceBySurveyChart';

// Pulls in ChartJS, and we'd like code splitting for that, so lazy-load.
const { ExperienceBySurveyChart } = lazily(
  () => import('components/ExperienceBySurveyChart'),
);

const getAverage = (data: (number | null)[]) => {
  const filtered = data.filter((x): x is number => x !== null);
  const sum = filtered.reduce((prev, curr) => prev + curr, 0);
  return filtered.length === 0 ? NaN : sum / filtered.length;
};

type Props = {
  experienceByChild: ExperienceChildData[];
  includeSubsetSelect?: boolean;
  networkRatedPositive: DataOverTime;
};

export const NetworkTimeline = ({
  experienceByChild,
  includeSubsetSelect = true,
  networkRatedPositive,
}: Props) => {
  const terms = useTerms();

  const dataByImprovementAsc = [...experienceByChild].sort((a, b) =>
    (getCumulativeChange(a.ratedPositive) || 0) >
    (getCumulativeChange(b.ratedPositive) || 0)
      ? 1
      : -1,
  );
  const dataByAvgAsc = [...experienceByChild].sort((a, b) =>
    (getAverage(a.ratedPositive) || 0) > (getAverage(b.ratedPositive) || 0)
      ? 1
      : -1,
  );

  // For the 'all' subset, we'll add this line to the graph.
  const averageExperience: ExperienceChildData = {
    childName: 'Imputed Average',
    ratedPositive: networkRatedPositive,
  };

  // Other lines will be present, but omitted from the legend.
  const dataWithoutLegend = experienceByChild.map((data) => ({
    ...data,
    displayInLegend: false,
  }));

  const chartDataBySubset = {
    all: [averageExperience, ...dataWithoutLegend],
    bottomFiveImprovers: dataByImprovementAsc.slice(0, 5),
    bottomFiveAverage: dataByAvgAsc.slice(0, 5),
    topFiveImprovers: dataByImprovementAsc.reverse().slice(0, 5),
    topFiveAverage: dataByAvgAsc.reverse().slice(0, 5),
  };

  // When using the 'all' subset, only color the first line, representing the
  // average. When the chart runs out of colors, it will use gray.
  const allTheme = {
    ...defaultTheme,
    colorsLineGraph: defaultTheme.colorsLineGraph.slice(0, 1),
  };

  return (
    <Formik initialValues={{ subset: 'all' }} onSubmit={() => undefined}>
      {({ setFieldValue, values }) => (
        <Form>
          <Row>
            <Col cols={6}>
              <Select
                displayErrors={false}
                fullWidth
                name="subset"
                label={`${terms.groups} to view`}
                options={[
                  { name: 'Top five improvers', value: 'topFiveImprovers' },
                  { name: 'Top five overall', value: 'topFiveAverage' },
                  {
                    name: 'Bottom five improvers',
                    value: 'bottomFiveImprovers',
                  },
                  { name: 'Bottom five overall', value: 'bottomFiveAverage' },
                  { name: 'All', value: 'all' },
                ]}
                keyBy="value"
                keyName="name"
                value={values.subset}
                onChange={(value: string) => setFieldValue('subset', value)}
              />
            </Col>
          </Row>
          <ExperienceBySurveyChart
            ariaLabel={`${terms.groups} rating ${terms.condition} positively by survey`}
            experienceByChild={chartDataBySubset[values.subset]}
            theme={values.subset === 'all' ? allTheme : undefined}
          />
        </Form>
      )}
    </Formik>
  );
};
