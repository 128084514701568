import pluralize from 'pluralize';
import { useMemo } from 'react';

import { RateByMember } from '@perts/model';

import { User } from 'models';
import { useTerms } from 'components/TermsContext';
import {
  getMemberCycleActive,
  getMemberCycleStatus,
  MemberCycleStatus,
} from 'utils';
import { Table } from './Table';

type TableProps = {
  memberRateByCycle: RateByMember[];
  users: User[];
};

type CellData = {
  row: {
    original: RateByMember;
  };
};

const formatCycleStatus = (status: MemberCycleStatus) => {
  if (status === MemberCycleStatus.notStarted) {
    return '—'; // em dash
  }
  if (status === MemberCycleStatus.started) {
    return 'started';
  }
  if (status === MemberCycleStatus.sufficient) {
    return '✓';
  }
  if (status === MemberCycleStatus.excellent) {
    return '✓+';
  }
  if (status === MemberCycleStatus.future) {
    return 'scheduled';
  }
  return ''; // 'dne', user has no cycles here
};

const countActiveCycles = (row: RateByMember) =>
  row.member_cycles.filter(getMemberCycleActive).length;

const countAllCycles = (row: RateByMember) =>
  row.member_cycles
    .map(getMemberCycleStatus)
    .filter((status) => status !== MemberCycleStatus.dne).length;

export const MemberParticipationTable = ({
  memberRateByCycle,
  users,
}: TableProps) => {
  const terms = useTerms();

  // Find the max number of cycles in the data so we know how many columns to
  // display in the table.
  const maxCycles = memberRateByCycle.reduce(
    (n, row) => Math.max(n, row.member_cycles.length),
    1,
  );

  const columns = useMemo(() => {
    const cols = [
      {
        Header: pluralize(terms.classManager),
        id: 'name',
        accessor: (row: RateByMember) => row.name || row.email,
      },
      ...Array.from({ length: maxCycles }).map((_, i) => ({
        Header: String(i + 1),
        id: `survey-${i}`,
        accessor: (row: RateByMember) => {
          // Recall that TS will NOT type-check index access. Explicitly include
          // the fact that element `i` might not be defined. This is expected
          // behavior if other clases in the community have more cycles that
          // this user, and thus more columns that we have data in this row.
          const memberCycle = row.member_cycles[i] || undefined;
          return formatCycleStatus(getMemberCycleStatus(memberCycle));
        },
        disableGlobalFilter: true,
      })),
      {
        Header: 'Completed',
        id: 'completed',
        accessor: countActiveCycles,
        Cell: ({ row: { original } }: CellData) =>
          `${countActiveCycles(original)}/${countAllCycles(original)}`,
      },
    ];

    return cols;
  }, [maxCycles, terms]);

  return (
    <Table columns={columns} data={memberRateByCycle} numCycles={maxCycles} />
  );
};
