import { getParticipantAttributes } from '@perts/config';
import { EquityGapIcon } from '../../components';

export const getOptionsSelectFilterShowBy = () => [
  {
    value: 'metricOnlyAndQuestions',
    name: 'Question',
  },
  {
    value: 'metricOnly',
    name: '',
  },
];

export const getOptionsSelectFilterDisaggregateBy = (
  programLabel: string,
  attrLabelsWithGaps: string[],
  attrLabelsWithGapsMetricOnly: string[],
  annotateWithGapIcon: boolean = false,
) => {
  const participantAttributes = getParticipantAttributes(programLabel);
  const attrOptions = participantAttributes.map((attrConfig) => ({
    name: attrConfig.name,
    value: attrConfig.label,
    gapActive: attrLabelsWithGaps.includes(attrConfig.label),
    gapMetricOnly: attrLabelsWithGapsMetricOnly.includes(attrConfig.label),
  }));

  const options = [
    {
      name: '(None)',
      value: 'all_participants',
      gapActive: false,
      gapMetricOnly: false,
    },
    ...attrOptions,
  ];

  return annotateWithGapIcon
    ? options.map((opt) => ({
        ...opt,
        name: (
          <>
            {opt.name}
            {opt.gapActive && <EquityGapIcon />}
          </>
        ),
      }))
    : options;
};

export const getOptionsSelectFilterMulti = (
  programLabel: string,
  attrLabelsWithGaps: string[],
  attrLabelsWithGapsMetricOnly: string[],
) => {
  const optionsShowBy = getOptionsSelectFilterShowBy();
  const optionsDisaggregateBy = getOptionsSelectFilterDisaggregateBy(
    programLabel,
    attrLabelsWithGaps,
    attrLabelsWithGapsMetricOnly,
  );

  const options = [
    // Question ONLY option
    {
      value: [optionsShowBy[0].value, optionsDisaggregateBy[0].value],
      name: optionsShowBy[0].name,
      category: 'Disaggregate By',
    },

    // Disaggregate ONLY options
    ...optionsDisaggregateBy.slice(1).flatMap((opt) => ({
      value: [optionsShowBy[1].value, opt.value],
      name: (
        <>
          {opt.name}
          {opt.gapMetricOnly && <EquityGapIcon />}
        </>
      ),
      category: 'Disaggregate By',
    })),

    // Question AND Disaggregate options
    ...optionsDisaggregateBy.slice(1).flatMap((opt) => ({
      value: [optionsShowBy[0].value, opt.value],
      name: (
        <>
          {optionsShowBy[0].name} x {opt.name}
          {opt.gapActive && <EquityGapIcon />}
        </>
      ),
      category: 'Advanced',
    })),
  ];

  return options;
};
