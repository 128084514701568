import { Formik, Form } from 'formik';
import { Class, Participant } from 'models';
import pluralize from 'pluralize';
import { Button, Col, InfoBox, Row, Modal, useFormError } from '@perts/ui';
import { useTerms } from 'components/TermsContext';

export type ParticipantsRemoveFormValues = {
  participants: Participant[];
  classroomId: string;
};

export type ParticipantsRemoveFormProps = {
  close: (params?: any) => void;
  onSubmit: (values: ParticipantsRemoveFormValues) => void;
  participants: Participant[];
  cls: Class;
};

export const ParticipantsRemoveForm = ({
  close,
  onSubmit,
  participants,
  cls,
}: ParticipantsRemoveFormProps) => {
  const [FormError, showFormError] = useFormError();
  const terms = useTerms();

  return (
    <Modal close={close}>
      <Modal.Title className="center">Remove {terms.participants}</Modal.Title>

      <Formik
        initialValues={{
          participants,
          classroomId: cls.classroom_id,
        }}
        onSubmit={async (values) => {
          try {
            // Clear existing form error.
            showFormError(false);

            // Perform form onSubmit.
            await onSubmit(values);

            // Close modal on successful form onSubmit.
            close();
          } catch (error) {
            // Display form error.
            showFormError(true);
          }
        }}
      >
        {({ isSubmitting, isValid }) => (
          <Form>
            <Row>
              <Col>
                <InfoBox color="warning">
                  Are you sure you want to remove{' '}
                  {pluralize(
                    terms.participant.toLowerCase(),
                    participants.length,
                    true,
                  )}
                  ?
                </InfoBox>
              </Col>
            </Row>

            <Row>
              <Col>
                <FormError />
              </Col>
            </Row>

            <Row>
              <Col cols={6} colsSm={12}>
                <Button
                  type="button"
                  color="secondary"
                  fullWidth
                  fullHeight
                  onClick={close}
                  disabled={isSubmitting}
                >
                  Cancel
                </Button>
              </Col>

              <Col cols={6} colsSm={12} hAlign="flex-end">
                <Button
                  type="submit"
                  fullWidth
                  disabled={!isValid || isSubmitting}
                  loading={isSubmitting}
                >
                  Remove{' '}
                  {pluralize(terms.participant, participants.length, true)}
                </Button>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
