import { useState } from 'react';
import styled from 'styled-components/macro';
import { useHistory, useParams } from 'react-router-dom';
import { PagesParams, toHomeSearch } from 'pages';
import { Button, TextField, Row, Col } from '@perts/ui';

const Form = styled.form`
  display: 'flex';
  flex: 1;
`;

export const SearchField = () => {
  const { programId = '', searchQuery = '' } = useParams<PagesParams>();
  const [searchField, setSearchField] = useState(searchQuery);
  const history = useHistory();

  return (
    <Form
      onSubmit={(e) => {
        e.preventDefault();
        history.push(toHomeSearch(programId, searchField));
      }}
    >
      <Row>
        <Col colsSm={12}>
          <TextField
            id="search"
            name="search"
            label="Search"
            displayError={false}
            onChange={(e) => setSearchField(e.currentTarget.value)}
            value={searchField}
          />
        </Col>
        <Col colsSm={12} shrink vAlign="flex-end">
          <Button type="submit" color="secondary" fullWidth>
            Search
          </Button>
        </Col>
      </Row>
    </Form>
  );
};
