import moment from 'moment';

import { getMessageFromErrors } from '@perts/util';
import Loading from 'components/Loading';
import {
  isNetworkDefault21Dataset,
  useNetworkGetByParams,
  useProgramsQuery,
  useReportQueryByParams,
  useDatasetGetById,
  useMetricsQuery,
} from 'models';
import { dateFormat } from 'config';
import { ErrorMessageBox } from 'components/ErrorMessageBox';

import { getReportMetrics } from '../components';

// eslint-disable-next-line complexity
export const NetworkReportLoader = ({ children }) => {
  const programsQuery = useProgramsQuery();
  const metricsQuery = useMetricsQuery();
  const networkQuery = useNetworkGetByParams();
  const reportQuery = useReportQueryByParams();
  const datasetQuery = useDatasetGetById(reportQuery.data?.dataset_id, {
    enabled: Boolean(reportQuery.isSuccess),
  });

  if (
    programsQuery.isLoading ||
    metricsQuery.isLoading ||
    networkQuery.isLoading ||
    reportQuery.isLoading ||
    datasetQuery.isLoading
  ) {
    return <Loading />;
  }

  if (
    !programsQuery.isSuccess ||
    !metricsQuery.isSuccess ||
    !networkQuery.isSuccess ||
    !reportQuery.isSuccess ||
    !datasetQuery.isSuccess
  ) {
    return (
      <ErrorMessageBox>
        {getMessageFromErrors([
          programsQuery.error,
          metricsQuery.error,
          networkQuery.error,
          reportQuery.error,
          datasetQuery.error,
        ])}
      </ErrorMessageBox>
    );
  }

  const allMetrics = metricsQuery.data;
  const network = networkQuery.data;
  const report = reportQuery.data;
  const dataset = datasetQuery.data;
  const program = programsQuery.data.find((p) => p.uid === network.program_id);
  if (!program) {
    throw new Error(`Couldn't find program for network: ${network.program_id}`);
  }

  if (!isNetworkDefault21Dataset(dataset)) {
    // TODO: style this
    return <>There's a problem with this report: dataset type error.</>;
  }

  const programMetricIds = program.metrics.map(({ uid }) => uid);
  const programMetrics = allMetrics.filter(({ uid }) =>
    programMetricIds.includes(uid),
  );

  const reportMetrics = getReportMetrics(dataset, allMetrics);

  const reportDate = moment(report.issue_date).format(dateFormat);

  return children({
    programMetrics,
    reportMetrics,
    network,
    dataset,
    program,
    reportDate,
  });
};
