import { toHomeNoProgram } from 'pages';
import { InfoBox, Link, Show } from '@perts/ui';

type ErrorMessageBoxProps = {
  children: React.ReactNode;
  showLinkToHome?: boolean;
};

export const ErrorMessageBox = ({
  children,
  showLinkToHome = true,
}: ErrorMessageBoxProps) => (
  <InfoBox color="warning">
    <span>Sorry, there was an error.</span>
    <span>{children}</span>
    <Show when={showLinkToHome}>
      <Link to={toHomeNoProgram()}>Return to Home page</Link>
    </Show>
  </InfoBox>
);
