import { uniq } from 'lodash';
import type { DemographicRow } from './types';

// Figure out which attributes contain gaps among any of their values. For
// instance, if the attribute value "basic needs insecure" has a gap compared
// to "all", then the attribute "Basic Needs Security" should be listed.
export const getAttrLabelsWithGaps = (data: DemographicRow[]) =>
  uniq(
    data
      .flatMap((demographicRow) => demographicRow.byAttribute)
      .filter((attrSurveyRow) => attrSurveyRow.isEquityGap)
      .map((attrSurveyRow) => attrSurveyRow.attributeLabel),
  );
