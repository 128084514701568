// Wrap an existing fetch function to allow it to immediately return null if
// the response is a 404. Standard behavior is to treat 404 as an error, which
// triggers react-query to retry, and then throw.
//
// Example before wrapping:
//
//   const dataset = await datasetTeam(teamId); // rejects on 404
//   // dataset is type TeamData
//
// Example with wrapping:
//
//   const datasetOrNull = await allowNotFound(datasetTeam, teamId);
//   // dataset is type TeamData | null

export const allowNotFound = async <T>(
  fetchFn: (...args: any) => Promise<T>,
  ...fetchArgs: any
): Promise<T | null> => {
  try {
    // Lack of `response.ok` (non 2xx status) will reject.
    return await fetchFn(...fetchArgs);
  } catch (e: any) {
    // handleApiResponse rejects with Error objects that have a `code` prop.
    if (e.code && e.code === 404) {
      // Just return null instead of throwing so that react-query won't retry.
      return null;
    }
    throw e; // Unexpected errors should be re-thrown.
  }
};
