import styled from 'styled-components/macro';
import { theme } from '../../';

export type CardCollapsedProps = {
  // The content to be rendered.
  children: React.ReactNode;
};

export const CardCollapsedStyled = styled.div<CardCollapsedProps>`
  width: 100%;
  margin: ${theme.units.paddingLg};

  @media only screen and (max-width: ${theme.units.gridBreakpointMedium}px) {
    margin: ${theme.units.paddingMd};
  }

  @media only screen and (max-width: ${theme.units.gridBreakpointSmall}px) {
    margin: ${theme.units.paddingSm};
  }
`;

export const CardCollapsed = ({ children }: CardCollapsedProps) => {
  return <CardCollapsedStyled>{children}</CardCollapsedStyled>;
};

CardCollapsed.displayName = 'CardCollapsed';
