export function validateItems<T>(
  items: T[],
  validatorFn: (items: T[], item: T) => boolean,
) {
  const valid: T[] = [];
  const invalid: T[] = [];

  items.forEach((item) => {
    if (validatorFn(items, item)) {
      valid.push(item);
    } else {
      invalid.push(item);
    }
  });

  return { valid, invalid };
}
