import { useContext } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { isEmpty } from 'lodash';

import { helpArticles } from 'config';

import {
  Button,
  Col,
  InfoBox,
  Input,
  Modal,
  Row,
  MultiSelectChip,
  useFormError,
  HelpText,
} from '@perts/ui';
import validateFromYupSchema from 'utils/validateFromYupSchema';
import TermsContext from 'components/TermsContext';

const EmailValidation = Yup.string().email();
const emailYupValidation = validateFromYupSchema(EmailValidation);

// Email validation for MultiSelectChip's `customValidation`.
const multiSelectChipValidation = (email: string) => {
  if (emailYupValidation(email)) {
    return `Email address "${email}" is not valid.`;
  } else if (email.length > 200) {
    return 'Email should be 200 characters or less.';
  }

  return undefined;
};

const validateMembersAddForm = (values) => {
  // If the email array is not empty OR
  // the current input value is not empty and is a valid email
  if (
    !isEmpty(values.emails) ||
    (!isEmpty(values.currentValue) &&
      isEmpty(emailYupValidation(values.currentValue)))
  ) {
    return {};
  }

  return {
    emails: 'At least one email address is required.',
  };
};

export type MemberAddFormValues = {
  emails: string[];
};

export type MembersAddFormProps = {
  close: () => void;
  onSubmit: (values: MemberAddFormValues) => void;
};

export const MembersAddForm = ({ close, onSubmit }: MembersAddFormProps) => {
  const [FormError, showFormError] = useFormError();
  const terms = useContext(TermsContext);

  return (
    <Modal close={close}>
      <Modal.Title className="center">
        Add {terms.group} {terms.groupMembers}
      </Modal.Title>

      <Formik
        enableReinitialize={true}
        initialValues={{
          emails: [],
          currentValue: '',
        }}
        validate={validateMembersAddForm}
        onSubmit={async ({ emails = [], currentValue }) => {
          try {
            // Clear existing form error.
            await showFormError(false);

            const values = {
              emails: emails.length > 0 ? emails : [currentValue],
            };

            // Perform form onSubmit.
            await onSubmit(values);

            // Close modal on successful form onSubmit.
            close();
          } catch (error) {
            // Display form error.
            showFormError(true);
          }
        }}
      >
        {({ isSubmitting, isValid, dirty, errors, values }) => (
          <Form>
            <Row>
              <Col>
                <Input
                  id="inviteEmail"
                  name="emails"
                  label={terms.memberAddLabel}
                  labelPlacement="top"
                  customValidation={multiSelectChipValidation}
                  helpText={
                    <HelpText articleId={helpArticles.pasteEmailAddresses}>
                      {terms.groupMembers} manage surveys and reports.
                    </HelpText>
                  }
                  component={MultiSelectChip}
                  disabled={isSubmitting}
                  error={values.currentValue === '' && errors.emails}
                />
              </Col>
            </Row>

            <Row>
              <Col>
                <InfoBox>Tip: You can paste in a list of emails.</InfoBox>
              </Col>
            </Row>

            <Row>
              <Col>
                <FormError />
              </Col>
            </Row>

            <Row>
              <Col>
                <Button
                  fullHeight
                  type="button"
                  color="secondary"
                  fullWidth
                  onClick={close}
                  disabled={isSubmitting}
                >
                  Cancel
                </Button>
              </Col>

              <Col>
                <Button
                  type="submit"
                  fullWidth
                  disabled={!isValid || !dirty || isSubmitting}
                  loading={isSubmitting}
                  data-testid="submit-btn"
                >
                  Add {terms.groupMember}(s)
                </Button>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
