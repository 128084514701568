import { useLocation } from 'react-router-dom';
import pluralize from 'pluralize';

import {
  Cycle,
  queryKeyCyclesByGroup,
  removeCycles,
  useCyclesQueryByParams,
  useMutationDelete,
  useParams,
} from 'models';
import { useCloseModal } from 'utils';
import { SchedulesRemoveForm } from './SchedulesRemoveForm';

export const SchedulesRemove = () => {
  const { groupId } = useParams();
  const closeModal = useCloseModal();

  // This page is displayed in a modal, and the Cycle UIDs to be removed are
  // being provided via React Router location.
  const { state } = useLocation<{ cycleUids: string[] }>();
  const { cycleUids = [] } = state;

  // To refresh the Schedules page once removal is complete.
  const queryKey = queryKeyCyclesByGroup(groupId);

  // Cycles of all displayed Classes.
  const { data: cycles } = useCyclesQueryByParams();

  // Filter down to just the Cycles the user is requesting be removed.
  const cyclesToRemove =
    cycles?.filter((cycle) => cycleUids.includes(cycle.uid)) || [];

  const mutation = useMutationDelete<Cycle>(
    removeCycles,
    cyclesToRemove,
    queryKey,
    {
      waitForSuccess: true,
      toastSuccessMsg: `Successfully deleted the ${pluralize(
        'Cycle',
        cycleUids.length,
        false, // don't prepend with number
      )}.`,
      toastErrorMsg:
        `There was a problem deleting the ` +
        `${pluralize(
          'Cycle',
          cycleUids.length,
          false, // don't prepend with number
        )}.`,
    },
  );

  const onSubmit = async (values: string[]) => {
    await mutation.mutateAsync(values);
  };

  return <SchedulesRemoveForm close={closeModal} onSubmit={onSubmit} />;
};
