import styled from 'styled-components/macro';
import { css } from 'styled-components';
import { theme } from '../../';
import { IconChevronRight } from '../Icon';
import { Link } from '../Link';
import { Show } from '../../index';
import { Text } from '../Text';

export type CardTitleProps = {
  children: React.ReactNode;

  to?: string;
  toText?: string;
};

const CardTitleStyled = styled.div<CardTitleProps>`
  flex-grow: 1;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  ${(props) =>
    props.to &&
    css`
      cursor: pointer;
    `};

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 0;
  }
`;

const CardLink = styled(Link)`
  flex-grow: 1;
  &:focus {
    text-decoration: underline;
  }
`;

export const CardTitleText = styled(Text)`
  line-height: ${theme.units.lineHeightTitle};
`;

const CardTitleTo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  line-height: ${theme.units.lineHeightTitle};

  font-weight: 600;

  > * {
    margin-left: ${theme.units.paddingSm};
  }
`;

export const CardTitle = ({
  children,
  to,
  toText,
  ...rest
}: CardTitleProps) => {
  if (!to) {
    return (
      <CardTitleStyled>
        {children}
        <Show when={Boolean(toText)}>
          <CardTitleTo>
            <span>{toText}</span>
            <IconChevronRight />
          </CardTitleTo>
        </Show>
      </CardTitleStyled>
    );
  }

  return (
    <CardLink to={to}>
      <CardTitleStyled {...rest}>
        {children}

        <Show when={Boolean(toText)}>
          <CardTitleTo>
            <span>{toText}</span>
            <IconChevronRight />
          </CardTitleTo>
        </Show>
      </CardTitleStyled>
    </CardLink>
  );
};

CardTitle.displayName = 'CardTitle';
