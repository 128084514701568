import styled from 'styled-components/macro';
import { theme } from 'components';

export const IconButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  & > button,
  & > a {
    margin-right: ${theme.units.bodyPaddingSm};
    min-width: 40px;
    white-space: nowrap; // don't wrap button text
  }
`;
