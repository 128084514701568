import uri from 'urijs';
// https://firebase.google.com/docs/reference/js
// https://firebase.google.com/docs/web/modular-upgrade
import { initializeApp } from 'firebase/app';
import {
  getAuth,
  onAuthStateChanged,
  sendEmailVerification,
  reload as firebaseReload,
  signOut as firebaseSignOut,
  User as FirebaseUser,
} from 'firebase/auth';
import { loginFailure, loginUser, setAuthenticating } from 'state/auth/actions';
import authApi from 'services/auth';
import {
  GOOGLE_AUTH_API_KEY,
  GOOGLE_AUTH_DOMAIN,
  LOCAL_STORAGE_GOOGLE_TOKEN,
  TRITON_URL_PREFIX,
} from 'services/triton/config';
import { saveUserToLocalStorage, getJwtPayload } from 'services/triton/helpers';
import { GoogleUser } from 'models';
import { get as getUser } from 'services/triton/users';
import { createUid } from '@perts/util';

import { getFirebaseJwt } from 'services/auth/getFirebaseJwt';
import { getFirebaseUser } from 'services/auth/getFirebaseUser';

export const getUidFromGoogleJwtSubject = (sub) => createUid('User', sub);

/**
 * Change the shape of the user in the Google token to match a partial of
 * our user object. Always build from the jwt, not the Firebase user, because
 * the identity provider is always known (since the jwt is by definition the
 * result of the user signing in through some provider). For instance see how
 * firebaseUser.providerData can make things ambiguous by listing all providers:
 * https://firebase.google.com/docs/reference/js/auth.user.md#userproviderdata
 * @param  {string} jwt token from firebase auth
 * @return {GoogleUser}     partial user object, in selectors a "googleUser"
 */
export const getGoogleAuthUser = (jwt): GoogleUser => {
  // Can't find the type or documentation for this, but experience shows these
  // propertes can be found in the Google-minted jwt:
  const {
    email,
    email_verified,
    firebase: { sign_in_provider },
    name,
    picture,
    sub,
  } = getJwtPayload(jwt);

  // Convert these properties into a partial PERTS user object.
  const uid = getUidFromGoogleJwtSubject(sub);
  return {
    uid,
    email,
    email_verified, // not normally in a user, but essential here
    sign_in_provider,
    name,
    picture,
  };
};

const storeGoogleAuthUser = async (
  firebaseUser: FirebaseUser,
): Promise<GoogleUser> => {
  const jwt = await getFirebaseJwt(firebaseUser);
  const googleAuthUser = getGoogleAuthUser(jwt);
  saveUserToLocalStorage(googleAuthUser);
  window.localStorage.setItem(LOCAL_STORAGE_GOOGLE_TOKEN, jwt);

  return googleAuthUser;
};

let firebaseInitialized = false;

const initializeFirebase = () => {
  if (!firebaseInitialized) {
    initializeApp({
      apiKey: GOOGLE_AUTH_API_KEY,
      authDomain: GOOGLE_AUTH_DOMAIN,
    });
    firebaseInitialized = true;
  }
};

// If Google's information on the user changes while the page is loaded, e.g.
// if the user confirms their email address, we will need to reload their data.
export const reloadUser = async (): Promise<GoogleUser> => {
  const firebaseUser = await getFirebaseUser();
  await firebaseReload(firebaseUser);
  const googleAuthUser = await storeGoogleAuthUser(firebaseUser);

  // // eslint-disable-next-line no-console
  // console.log(
  //   'reloadUser(): got jwt and auth user. Email verified?',
  //   googleAuthUser.email_verified,
  // );

  // Request PERTS user from triton server, not to retrieve data, but just to
  // grab the PERTS-minted, session-aware token.
  await getUser(googleAuthUser.uid);

  // // eslint-disable-next-line no-console
  // console.log(
  //   'reloadUser(): PERTS user refetched. Session ID in stored token?',
  //   localStorage.getItem('triton:auth:token'),
  //   getJwtPayload(localStorage.getItem('triton:auth:token')),
  //   getJwtPayload(localStorage.getItem('triton:auth:token'))?.session_id,
  // );

  return googleAuthUser;
};

export const signOut = async () => {
  initializeFirebase();
  await firebaseSignOut(getAuth());
};

// https://firebase.google.com/docs/auth/web/manage-users#send_a_user_a_verification_email
export const sendVerificationEmail = async (programLabel?) => {
  const user = getAuth().currentUser;

  if (!user) {
    throw new Error(`Could not send verification email, no user available.`);
  }

  const actionCodeSettings = {
    url: uri(`${TRITON_URL_PREFIX}/login`)
      .search({ program: programLabel })
      .toString(),
  };

  await sendEmailVerification(user, actionCodeSettings);
};

export const login = async (store, firebaseUser) => {
  store.dispatch(setAuthenticating());
  const googleAuthUser = await storeGoogleAuthUser(firebaseUser);

  // Request PERTS user from triton server, not to retrieve data, but just to
  // grab the PERTS-minted, session-aware token.
  try {
    await getUser(googleAuthUser.uid);
  } catch (e: any) {
    console.error(e);
    await signOut(); // firebase
    authApi.removeAuthFromLocalStorage(); // localStorage
    // Allows Login page to detect a failure.
    store.dispatch(loginFailure('Get triton user failed'));
    return;
  }

  store.dispatch(loginUser(googleAuthUser));
};

const googleAuth = (store) => {
  initializeFirebase();

  onAuthStateChanged(getAuth(), async (firebaseUser) => {
    if (firebaseUser) {
      await login(store, firebaseUser);
    }
  });
};

export default googleAuth;
