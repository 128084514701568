import { isEqual } from 'lodash';
import { FormValues } from './FormValues';

export const compareFormFields = (
  values: FormValues,
  defaultValues: FormValues,
) => {
  const missingFromDefaults: string[] = [];
  const diffFromDefaults: { fieldName: string; value: any }[] = [];

  for (const fieldName in values) {
    // Track fields that are missing from defaultValues.
    if (defaultValues[fieldName] === undefined) {
      missingFromDefaults.push(fieldName);
    }

    // Track form values that don't match defaultValues.
    if (!isEqual(values[fieldName], defaultValues[fieldName])) {
      diffFromDefaults.push({
        fieldName,
        value: defaultValues[fieldName],
      });
    }
  }

  return { missingFromDefaults, diffFromDefaults };
};
