import getLinkRel from 'utils/getLinkRel';
import { headersFromResponse } from './headersFromResponse';

// Uses the "links" returned on the HTTP Response Headers to determine the
// data needed for pagination.

// Note: `total` is not precise since the server does not provide it. It's close
// enough to allow us to display the correct number of pages on the client.

export const paginationFromResponse = (response) => {
  const headers = headersFromResponse(response);

  const current = getLinkRel(headers.link, 'self');
  const last = getLinkRel(headers.link, 'last');
  const end = getLinkRel(headers.link, 'end');

  if (!last) {
    return undefined;
  }

  const pageSize = parseInt(current.n, 10);

  // validate bad `n` or trust the server?
  // pageSize = pageSize < 1 ? 10 : pageSize;

  return {
    pageSize,
    pageIndex: Math.floor(current.cursor / pageSize),
    totalPages: Math.floor(last.cursor / pageSize),
    total: end.cursor,
    order: current.order,
  };
};
