import { Formik, Form } from 'formik';
import { Network } from 'models';
import { Button, Col, Row, Modal, useFormError } from '@perts/ui';
import { NetworksRemoveFormMessage } from './NetworksRemoveFormMessage';

export type NetworksRemoveFormValues = {
  network: Network;
};

export type NetworksRemoveFormProps = {
  close: () => void;
  onSubmit: (values: NetworksRemoveFormValues) => void;
  network: Network;
};

export const NetworksRemoveForm = ({
  close,
  onSubmit,
  network,
}: NetworksRemoveFormProps) => {
  const [FormError, showFormError] = useFormError();

  return (
    <Modal close={close}>
      <Modal.Title className="center">Delete Network</Modal.Title>

      <Formik
        enableReinitialize={true}
        initialValues={{
          network,
        }}
        onSubmit={async (values) => {
          try {
            // Clear existing form error.
            showFormError(false);

            // Perform form onSubmit.
            await onSubmit(values);

            // Close modal on successful form onSubmit.
            close();
          } catch (error) {
            // Display form error.
            showFormError(true);
          }
        }}
      >
        {({ isSubmitting, isValid, values }) => (
          <Form>
            <Row>
              <Col>
                <NetworksRemoveFormMessage networkName={network.name} />
              </Col>
            </Row>
            <Row>
              <Col>
                <FormError />
              </Col>
            </Row>
            <Row>
              <Col cols={6} colsSm={12}>
                <Button
                  fullHeight
                  type="button"
                  color="secondary"
                  fullWidth
                  onClick={close}
                  disabled={isSubmitting}
                >
                  Cancel
                </Button>
              </Col>

              <Col cols={6} colsSm={12}>
                <Button
                  type="submit"
                  fullWidth
                  disabled={!isValid || isSubmitting}
                  loading={isSubmitting}
                >
                  Delete Network
                </Button>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
