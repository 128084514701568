import styled from 'styled-components/macro';
import theme from '../theme';

export const IconRightContainer = styled.div`
  margin-left: ${(props) => props.theme.units.fieldPaddingInternal};
  display: flex;
  align-items: center;
  justify-content: center;
`;

IconRightContainer.defaultProps = {
  theme,
};
