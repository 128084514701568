import styled from 'styled-components/macro';
import { Row } from '@perts/ui';

const ReportTitleRow = styled(Row)`
  @media print {
    margin: 0;

    div {
      padding: 0;
      margin: 0;
    }
  }
`;

export default ReportTitleRow;
