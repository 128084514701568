import { InfoBox } from '@perts/ui';

import { usePermissions } from 'utils';
import { ReportUpdatedFrequency, ReportFooter } from 'components';
import { GroupReportLoader } from './GroupReportLoader';
import { GroupReport23Render } from './GroupReport23Render';

export const GroupReport23 = () => {
  const { isAdmin } = usePermissions();

  return (
    <GroupReportLoader>
      {({
        dataset,
        group,
        lastRun,
        lastUpdatedResults,
        program,
        programMetrics,
        reportMetrics,
        results,
        user,
      }) => {
        if (group.report_settings?.report_hidden && !isAdmin) {
          return (
            <InfoBox color="warning">This report has been disabled.</InfoBox>
          );
        }

        // Admins may see the report even if it is disabled for most users.
        return (
          <>
            <GroupReport23Render
              archived={group.archived}
              group={group}
              lastRun={lastRun}
              program={program}
              programMetrics={programMetrics}
              reportMetrics={reportMetrics}
              results={results}
              user={user}
            />

            <ReportFooter>
              <ReportUpdatedFrequency dateString={lastUpdatedResults} />
            </ReportFooter>
          </>
        );
      }}
    </GroupReportLoader>
  );
};
