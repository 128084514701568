import { User } from 'models';
import styled from 'styled-components/macro';

import { Card, Col, Row, Show } from '@perts/ui';

const EmptyText = styled.span`
  font-style: italic;
`;

export type NetworkMemberCardProps = {
  // The User to display.
  member: User;
  toggle: React.ReactElement | null;
};

export const NetworkMemberCard = ({
  member,
  toggle,
}: NetworkMemberCardProps) => (
  <Card toggle={toggle}>
    <Card.Title>
      <Row alignItems="center">
        <Col>
          <Card.TitleText overflowWrap="anywhere">
            {member.email}
          </Card.TitleText>
        </Col>

        <Col>
          <Show when={Boolean(member.name)}>
            <Card.TitleText overflowWrap="anywhere">
              {member.name}{' '}
            </Card.TitleText>
          </Show>
          <Show when={!Boolean(member.name)}>
            <EmptyText>name not set</EmptyText>
          </Show>
        </Col>
      </Row>
    </Card.Title>
  </Card>
);
