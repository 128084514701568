import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useQueryClient } from 'react-query';
import * as authActions from 'state/auth/actions';
import uri from 'urijs';

import { toLogin } from 'routes';
import { isCanvasReachable } from 'services/canvas';

import AuthWrapper from 'components/AuthWrapper';
import Loading from 'components/Loading';
import { canvasLogoutLink } from 'config';

const Logout = (props) => {
  const queryClient = useQueryClient();
  const location = useLocation();

  // Possible query params. See also the AuthenticatedRoute component.
  const {
    // Used if the user attempts to visit a path that requires authentication,
    // but they're not logged in. Then their requested path is stored here and
    // they are redirected back to it after logging in.
    continue_url,

    // ?? Deprecated ??
    email,

    // Triggers an InfoBox on the login screen informing the user they've been
    // logged out due to inactivity.
    expired_session,

    // When this is present, we redirect the user to our canvas instance to
    // make sure they are logged out there also.
    logout_canvas,

    // ?? Deprecated ??
    passwordless,
  } = uri(location.search).search(true);

  const localRedirect = uri(toLogin())
    .setSearch({ continue_url, email, expired_session, passwordless })
    .toString();

  useEffect(() => {
    async function logoutAndRedirect() {
      const redirectToCanvas =
        logout_canvas === 'true' && (await isCanvasReachable());

      const redirect = redirectToCanvas
        ? // No need to carry forward any query params. This is for when the
          // user explicitly clicks the logout button. Their only destination is
          // the Copilot login page.
          canvasLogoutLink
        : // This possibly carries forward the received query params.
          localRedirect;

      queryClient.clear();
      props.actions.logoutUser(redirect);
    }
    logoutAndRedirect();
  }, [logout_canvas, localRedirect, props.actions, queryClient]);

  return (
    <AuthWrapper>
      <Loading>Signing out&hellip;</Loading>
    </AuthWrapper>
  );
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(authActions, dispatch),
});

export default connect(null, mapDispatchToProps)(Logout);
