import { useQueryClient, useMutation } from 'react-query';
import { message } from 'antd';

import {
  invalidateUsersInviteToNetwork,
  useNetworkGetByParams,
  usersInviteToNetwork,
} from 'models';
import { useParams } from 'pages';

import {
  NetworkMembersAddForm,
  NetworkMembersAddFormValues,
} from './NetworkMembersAddForm';
import Loading from 'components/Loading';
import { ErrorMessageBox } from 'components/ErrorMessageBox';

import { getMessageFromErrors } from '@perts/util';
import { useCloseModal } from 'utils';

export const NetworkMembersAdd = () => {
  const queryClient = useQueryClient();
  const { networkId } = useParams();

  // Close the modal.
  const closeModal = useCloseModal({ ignoreEmptyState: true });

  // Query for Network.
  const networkQuery = useNetworkGetByParams();

  const mutation = useMutation(
    async ({ emails: emailsFromForm }: NetworkMembersAddFormValues) => {
      if (!networkQuery.data) {
        throw new Error(`Network required to invite user.`);
      }

      const invitees = emailsFromForm.map((email) => ({
        email,
        name: '',
        receive_email: true,
      }));

      await usersInviteToNetwork(invitees, networkQuery.data);
    },
    {
      onSuccess: () => {
        message.success('Successfully added the email(s).');

        // Invalidate network members to force a refetch.
        invalidateUsersInviteToNetwork(queryClient, networkId);
      },
    },
  );

  if (networkQuery.isLoading) {
    return <Loading />;
  }

  if (!networkQuery.isSuccess) {
    return (
      <ErrorMessageBox>
        {getMessageFromErrors([networkQuery.error])}
      </ErrorMessageBox>
    );
  }

  const onSubmit = async (values: NetworkMembersAddFormValues) => {
    await mutation.mutateAsync(values);
  };

  return <NetworkMembersAddForm close={closeModal} onSubmit={onSubmit} />;
};
