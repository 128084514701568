import { forEach } from 'lodash';
import pluralize from 'pluralize';

// With the given terms object, generate a new object that contains the
// pluralized version of each term. For example, given the following
//   { team: 'Team' }
// this function will return
//   { teams: 'Teams' }

export const pluralizeTerms = (terms) => {
  const pluralizedTerms = {};
  forEach(terms, (term, key) => {
    pluralizedTerms[`${pluralize.plural(key)}`] = pluralize.plural(term);
  });
  return pluralizedTerms;
};
