import { useQuery } from 'react-query';
import { useQueriesTyped } from 'utils/useQueriesTyped';
import {
  Class,
  Group,
  groupGetById,
  groupsQuery,
  groupsQueryByNetwork,
  groupsQueryByUser,
  groupsQueryPagination,
  selectUserIsAdmin,
  useCurrentUserQuery,
  useProgramId,
  useQueryWithPagination,
} from 'models';
import { useParams } from 'pages';
import { QueryParams } from 'services/triton/helpers';
import isPlainObject from 'lodash/isPlainObject';
import { useQueryParams } from 'utils/useQueryParams';

// -----------------------------------------------------------------------------
//   queryKey Generators
// -----------------------------------------------------------------------------

export const queryKeyGroup = (groupId: string) => ['group', groupId];

export const queryKeyGroups = () => ['groups'];

type Paging = {
  [key: string]: any;
};

export const queryKeyGroupsByProgram = (programId: string, paging?: Paging) => {
  const parts: (string | Paging)[] = ['groups', 'byProgram', programId];
  if (paging) {
    parts.push(paging);
  }

  return parts;
};

export const queryKeyGroupsByUser = (userId: string) => [
  'groups',
  'byUser',
  userId,
];

export const queryKeyGroupsByNetwork = (networkId: string) => [
  'groups',
  'byNetwork',
  networkId,
];

// -----------------------------------------------------------------------------
//   API Hooks
// -----------------------------------------------------------------------------

export const useGroupGetById = (groupId: string) =>
  useQuery<Group, Error>(
    // queryKey
    queryKeyGroup(groupId),
    // queryFn
    () => groupGetById(groupId),
    {
      enabled: Boolean(groupId),
    },
  );

export const useGroupGetByParams = () => {
  const { groupId } = useParams();
  return useGroupGetById(groupId);
};

export type UseGroupsQueryByClasses = (
  // Optional, it's a dependent query so the parent may not have resolved yet.
  classes?: Class[],
) => any;

export const useGroupsQueryByClasses: UseGroupsQueryByClasses = (
  classes = [],
) => {
  // Query for Groups associated with each Class (tags).
  const uids = classes.flatMap((cls) => cls.organization_ids);
  const uniqueUids = [...new Set(uids)];

  const queries = useQueriesTyped(
    uniqueUids.map((uid) => ({
      queryKey: queryKeyGroup(uid),
      queryFn: () => groupGetById(uid),
    })),
  );

  const data = queries
    // Filter to plain objects only.
    .filter((query) => isPlainObject(query.data))
    .map((query) => query.data);

  // If any query is loading, marking isLoading as true.
  const isLoading = queries.some((query) => query.isLoading);

  // Default error state to no error.
  let isError = false;
  let error: Error | null = null;

  // Then override with the first error found, if any.
  const queryWithError = queries.find((query) => query.isError);

  if (queryWithError) {
    ({ isError, error } = queryWithError);
  }

  return {
    data,
    error,
    isLoading,
    isError,
  };
};

// Query for Groups, Associated with Network ID

export const useGroupsQueryByNetworkId = (networkId: string) =>
  useQuery<Group[], Error>(queryKeyGroupsByNetwork(networkId), () =>
    groupsQueryByNetwork(networkId),
  );

// Query for Groups, For Home, Associated with Program, By Route

export const useGroupsPagingForHome = () => {
  const { get } = useQueryParams();

  const n = parseInt(get('nGroups') || '10', 10);
  const cursor = parseInt(get('cursorGroups') || '0', 10);
  return { n, cursor };
};

// useGroupQuery
// - will return all groups for admin users
// - will return user associated groups for non-admin users
// - will scope to program if `queryParams.programId` is provided
// - will scope to user if `queryParams.userId` is provided
// - handles pagination

const queryFnGroupsQuery = (user, queryParams: QueryParams) => {
  // Requesting groups of a specific user.
  if (queryParams.userId) {
    return () => groupsQueryByUser(queryParams.userId || '', queryParams);
  }

  // Admin, queries for all groups.
  if (user && selectUserIsAdmin(user)) {
    return () => groupsQuery(queryParams);
  }

  // Non-admin, queries for user's groups.
  return () => groupsQueryByUser(user?.uid || '', queryParams);
};

export const useGroupsQuery = (
  queryParams: QueryParams = {},
  queryOptions = {},
) => {
  const programId = useProgramId();
  const programQueryParams: QueryParams = {
    programId,
    ...queryParams,
  };
  const {
    data: user,
    isError: userIsError,
    error: userError,
    isLoading: userIsLoading,
  } = useCurrentUserQuery();

  const queryFn = queryFnGroupsQuery(user, programQueryParams);
  const queryKey = queryKeyGroupsByProgram(programId, programQueryParams);

  const result = useQueryWithPagination<Group[]>(queryKey, queryFn, {
    // We never want to run this before we know the user since the queryFn used
    // is determined by the user type. NOTE: If you need to pass a custom
    // queryOptions.enabled, this will need to be adjusted.
    enabled: Boolean(user && user.uid),

    // Note: If you override `enabled`, you'll need to manually ensure that
    // `enabled` is not set to true until the user is available.
    ...queryOptions,
  });

  return {
    ...result,
    isLoading: result.isLoading || userIsLoading,
    isError: result.isError || userIsError,
    error: result.error || userError,
  };
};

// useGroupsPagination
// - for use on /archives page

export const useGroupsPagination = (
  queryParams: QueryParams = {},
  queryOptions = {},
) => {
  const { programId } = queryParams;
  const queryFn = () => groupsQueryPagination(queryParams);
  const queryKey = queryKeyGroupsByProgram(programId || '', {
    name: queryParams.name,
    pageIndex: queryParams.pageIndex,
    pinned: queryParams.pinned,
    archived: queryParams.archived,
  });
  return useQueryWithPagination<Group[]>(queryKey, queryFn, queryOptions);
};
