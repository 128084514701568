import { Route, Switch } from 'react-router-dom';
import {
  NetworkGroups,
  NetworkGroupsAdd,
  NetworkGroupsRemove,
  toNetworkGroups,
  toNetworkGroupsAdd,
  toNetworkGroupsRemove,
} from 'pages';

export const NetworkGroupsRouter = () => (
  <>
    <Route path={toNetworkGroups()}>
      <NetworkGroups />
    </Route>

    <Switch>
      <Route path={toNetworkGroupsAdd()}>
        <NetworkGroupsAdd />
      </Route>

      <Route path={toNetworkGroupsRemove()}>
        <NetworkGroupsRemove />
      </Route>
    </Switch>
  </>
);
