import { useQueryClient } from 'react-query';

import { getMessageFromErrors } from '@perts/util';

import { useTerms } from 'components/TermsContext';
import { Loading, ErrorMessageBox } from 'components';
import {
  Group,
  queryKeyGroup,
  queryKeyGroupsByProgram,
  updateGroup,
  useGroupGetByParams,
  useGroupsPagingForHome,
  useMutationUpdate,
} from 'models';
import { useParams } from 'pages';
import { useCloseModal } from 'utils';
import {
  GroupsArchiveForm,
  GroupsArchiveFormValues,
} from './GroupsArchiveForm';

export const GroupsArchive = () => {
  const { groupId, programId } = useParams();
  const closeModal = useCloseModal({
    ignoreEmptyState: true,
  });
  const groupQuery = useGroupGetByParams();
  const groupsPaging = useGroupsPagingForHome();
  const queryClient = useQueryClient();
  const queryKeyById = queryKeyGroup(groupId);
  const queryKeyGroups = queryKeyGroupsByProgram(programId || '', {});
  const terms = useTerms();

  const mutation = useMutationUpdate<Group, Group>(updateGroup, queryKeyById, {
    onMutate: async (group: Group) => {
      // Update the specialized query key used on home page.
      const queryKeyByProgram = queryKeyGroupsByProgram(
        programId,
        groupsPaging,
      );

      // Prevent outgoing refetches from overwriting our optimistic update.
      await queryClient.cancelQueries(queryKeyByProgram);

      // Optimistically update with new value.
      const previous =
        queryClient.getQueryData<Group[]>(queryKeyByProgram) || [];
      const updated = [...previous.filter((g) => g.uid !== group.uid), group];
      queryClient.setQueryData<Group[]>(queryKeyByProgram, updated);

      return {};
    },
    onSettled: () => {
      // useMutationUpdate automatically invalidates the provided queryKey.
      // Here, we need to invalidate both queryKeyById (the one that
      // useMutationUpdate is handling for us) and queryKeyGroups, so we are
      // using onSettled to allow us to invalidate this second cache key.
      queryClient.invalidateQueries(queryKeyGroups);
    },
    optimisticallyUpdate: false,
    transformFormValues: (formValues) => formValues,
    toastSuccessMsg: `Successfully updated ${terms.group.toLowerCase()}.`,
    toastErrorMsg: `There was a problem updating ${terms.group.toLowerCase()}.`,
  });

  // Display loading.
  if (groupQuery.isLoading) {
    return <Loading />;
  }

  // Display errors.
  if (!groupQuery.isSuccess) {
    return (
      <ErrorMessageBox>
        {getMessageFromErrors([groupQuery.error])}
      </ErrorMessageBox>
    );
  }

  const onSubmit = (values: GroupsArchiveFormValues) =>
    mutation.mutateAsync({
      ...values.group,
      archived: !values.group.archived,
    });

  return (
    <GroupsArchiveForm
      close={closeModal}
      onSubmit={onSubmit}
      group={groupQuery.data}
    />
  );
};
