import uri from 'urijs';
import Entity from 'services/Entity';
import { TRITON_URL_PREFIX } from './config';
import {
  QueryParams,
  fetchApi,
  fetchOptionsGET,
  fetchOptionsPOST,
  generateFetchFunctions,
  paginationParamsToServer,
  urlWithQueryParams,
} from './helpers';

export interface OrganizationEntity extends Entity {
  name: string;
  code: string;
  phone_number: string;
  mailing_address: string;
  program_id: string;
  num_teams: number;
  num_users: number;
}

export const getDefaultOrganization = ({
  uid = '',
  code = '',
  name = '',
} = {}) => ({
  uid,
  code,
  name,
});

export const organizationParams = Object.keys(getDefaultOrganization());

export const {
  // boilerplate
  get,
  query,
  queryByUser,
  post,
  postBatch,
  update,
  remove,

  // custom
  queryByIds,
  queryByNetwork,
  queryByTeam,
  queryDashboard,
  queryPagination,
  changeCode,
} = generateFetchFunctions('organizations', {
  queryByIds: (organizationsIds: string[]) => {
    const options = fetchOptionsGET();
    const path = `${TRITON_URL_PREFIX}/api/organizations`;
    const url = uri(path).search({
      uid: organizationsIds,
    });
    return fetchApi(url, options);
  },

  queryByNetwork: (networkId: string) => {
    const options = fetchOptionsGET();
    const url = `${TRITON_URL_PREFIX}/api/networks/${networkId}/organizations`;
    return fetchApi(url, options);
  },

  queryByTeam: (teamId: string) => {
    const options = fetchOptionsGET();
    const url = `${TRITON_URL_PREFIX}/api/teams/${teamId}/organizations`;
    return fetchApi(url, options);
  },

  queryDashboard: (organizationId: string) => {
    const options = fetchOptionsGET();
    const url = `${TRITON_URL_PREFIX}/api/organization_dashboards/${organizationId}`;
    return fetchApi(url, options);
  },

  queryPagination: (queryParams: QueryParams) => {
    const options = fetchOptionsGET();
    const url = `${TRITON_URL_PREFIX}/api/organizations`;
    const queryParamsToServer = paginationParamsToServer(queryParams);
    const urlWithParams = urlWithQueryParams(url, queryParamsToServer);
    return fetchApi(urlWithParams, options);
  },

  changeCode: (orgId) => {
    const options = fetchOptionsPOST();
    const url = `${TRITON_URL_PREFIX}/api/organizations/${orgId}/code`;
    return fetchApi(url, options);
  },
});
