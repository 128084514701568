import React from 'react';
import range from 'lodash/range';

import { PageNavLinkStyled } from 'components/PageNav/PageNavLink';
import {
  Col,
  IconChevronDoubleLeft,
  IconChevronDoubleRight,
  IconChevronLeft,
  IconChevronRight,
  Row,
} from '@perts/ui';

type PageInfo = {
  targetIndex: number; // the page to link to, count-from-zero
  label: string; // text for the link
};

export const getPageRange = (
  pageIndex: number,
  pageCount: number,
  rangeSize: number,
): PageInfo[] => {
  if (pageCount === 0) {
    return [];
  }

  const halfRange = Math.floor(rangeSize / 2);
  const maxIndex = pageCount - 1;

  let begin;
  let end;
  if (pageIndex < halfRange) {
    // chose a range that begins at zero
    begin = 0;
    end = rangeSize;
  } else if (pageIndex > maxIndex - halfRange) {
    // choose a range that ends at the max
    begin = Math.max(pageCount - rangeSize, 0); // avoid negatives
    end = pageCount;
  } else {
    // choose a relative range
    begin = Math.max(pageIndex - halfRange, 0); // avoid negatives
    end = Math.min(pageIndex + halfRange + 1, maxIndex); // avoid > max;
  }
  const targetIndices = range(0, pageCount).slice(begin, end);

  // Array of tuples, where each is a count-from-zero page index and a
  // count-from-one display string.
  const pageRange = targetIndices.map((i) => ({
    targetIndex: i,
    label: String(i + 1),
  }));

  // The first and last display strings may need an ellipsis added.

  // eslint-disable-next-line prefer-destructuring
  const first = pageRange[0];
  const last = pageRange[pageRange.length - 1];

  if (first.targetIndex > 0) {
    // Then pages exist to the left, but we're choosing not to show them.
    // Put an ellipsis on the first label.
    first.label = `…${first.label}`;
  }
  if (last.targetIndex < pageCount - 1) {
    // Then pages exist to the right, but we're choosing not to show them.
    // Put an ellipsis on the last label.
    last.label = `${last.label}…`;
  }

  return pageRange;
};

type Props = {
  canNextPage: boolean;
  canPreviousPage: boolean;
  currentPageIndex: number;
  displayRangeSize: number;
  goToNextPage: () => void;
  goToPageIndex: (page: number) => void;
  goToPreviousPage: () => void;
  pageCount: number;
};

const Paginator: React.FC<Props> = ({
  canNextPage,
  canPreviousPage,
  currentPageIndex,
  displayRangeSize,
  goToNextPage,
  goToPageIndex,
  goToPreviousPage,
  pageCount,
}) => (
  <Row>
    <Col hAlign="center">
      <PageNavLinkStyled
        onClick={() => goToPageIndex(0)}
        disabled={!canPreviousPage}
      >
        <IconChevronDoubleLeft />
      </PageNavLinkStyled>
      <PageNavLinkStyled onClick={goToPreviousPage} disabled={!canPreviousPage}>
        <IconChevronLeft />
      </PageNavLinkStyled>
      {getPageRange(currentPageIndex, pageCount, displayRangeSize).map(
        ({ targetIndex, label }) => (
          <PageNavLinkStyled
            key={targetIndex}
            onClick={() => goToPageIndex(targetIndex)}
            active={currentPageIndex === targetIndex}
          >
            {label}
          </PageNavLinkStyled>
        ),
      )}
      <PageNavLinkStyled onClick={goToNextPage} disabled={!canNextPage}>
        <IconChevronRight />
      </PageNavLinkStyled>
      <PageNavLinkStyled
        onClick={() => goToPageIndex(pageCount - 1)}
        disabled={!canNextPage}
      >
        <IconChevronDoubleRight />
      </PageNavLinkStyled>
    </Col>
  </Row>
);

export default Paginator;
