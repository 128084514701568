import { Dataset } from './';
import {
  get,
  getNetwork,
  getOrganization,
  getOrganizationUser,
  getTeam,
} from 'services/triton/datasets';

// -----------------------------------------------------------------------------
//   API Functions
// -----------------------------------------------------------------------------

// Get a dataset by id.

type DatasetGetById = (datasetId: string) => Promise<Dataset>;

export const datasetGetById: DatasetGetById = get;

export const datasetNetwork = getNetwork;
export const datasetGroup = getOrganization;
export const datasetGroupUser = getOrganizationUser;
export const datasetTeam = getTeam;
