import { useContext } from 'react';
import {
  intersection,
  uniq,
  flatMap,
  difference,
  keyBy,
  isEqual,
  uniqWith,
} from 'lodash';
import { QueryClient, useMutation, useQueryClient } from 'react-query';
import { message } from 'antd';

import {
  Class,
  Group,
  queryKeyClassesByGroup,
  selectClassFacilitators,
  selectClassGroups,
  updateGroupClasses,
  useClassesQueryByParams,
  useGroupsQuery,
  useGroupsQueryByClasses,
  User,
  // usersInviteToGroup,
  useUsersQueryByParams,
} from 'models';
import { useParams } from 'pages';
import { useCloseModal, useGetCheckedStates } from 'utils';

import Loading from 'components/Loading';
import { ErrorMessageBox } from 'components/ErrorMessageBox';

import {
  ClassesEditGroupsForm,
  ClassesEditGroupsValuesProps,
} from './ClassesEditGroupsForm';
import TermsContext from 'components/TermsContext';
import { getMessageFromErrors } from '@perts/util';

export const classesEditGroupsMutationFn =
  (
    queryClient: QueryClient,
    queryKeyClasses: string[],
    classes: Class[],
    userGroups: Group[],
    checked: {
      [uid: string]: boolean;
    },
    users: User[],
  ) =>
  async ({ organizations = {} }: ClassesEditGroupsValuesProps) => {
    // Cancel any outgoing refetches
    // (so they don't overwrite our optimistic update)
    await queryClient.cancelQueries(queryKeyClasses);

    const classesById = keyBy(classes, 'uid');

    // Determine classes that have been selected.
    const checkedClasses: Class[] = classes.filter((cls) => checked[cls.uid]);

    // Add groups and leads in every checked class
    const classesFilled: Class[] = checkedClasses.map((cls) => ({
      ...cls,
      groups: selectClassGroups(cls, userGroups),
      facilitators: selectClassFacilitators(cls, users),
    }));

    // Init classGroupsToUpdate var to save organization_ids by classId
    const classGroupsToUpdate = {};
    classesFilled.forEach((cls) => {
      classGroupsToUpdate[cls.uid] = [];
    });

    for (const organizationId of Object.keys(organizations)) {
      const classIds = organizations[organizationId];
      classIds.forEach((classId) => {
        classGroupsToUpdate[classId].push(organizationId);
      });
    }

    const classesToUpdate: Class[] = [];

    classesFilled.forEach((cls) => {
      // Check if class organization_ids is not equal to
      // new value to organization_ids
      if (
        !isEqual(
          classesById[cls.uid].organization_ids.sort(),
          classGroupsToUpdate[cls.uid].sort(),
        )
      ) {
        const initialClsOrgIds = classesById[cls.uid].organization_ids;
        const updatedClsOrgIds = classGroupsToUpdate[cls.uid];

        // Determine organization ids to add in each class
        const clsOrgIdsToAdd = difference(updatedClsOrgIds, initialClsOrgIds);
        const classLeads = cls.facilitators || [];

        // Add class leads to invite
        let invitations: { organization_id: string; user_id: string }[] = [];
        for (const orgId of clsOrgIdsToAdd) {
          invitations = [
            ...invitations,
            ...classLeads.map((user) => ({
              organization_id: orgId,
              user_id: user.uid,
            })),
          ];
        }

        classesToUpdate.push({
          ...cls,
          organization_ids: classGroupsToUpdate[cls.uid],
          invitations: uniqWith(invitations, isEqual),
        });
      }
    });

    // Snapshot the previous classes value.
    const previousClasses = queryClient.getQueryData<Class[]>(queryKeyClasses);

    // Optimistically update to the new value
    if (previousClasses) {
      const updatedValue = classes.map((cls) => {
        const foundClassToUpdate = classesToUpdate.find(
          (c) => c.uid === cls.uid,
        );
        if (foundClassToUpdate) {
          return foundClassToUpdate;
        }
        return cls;
      });

      queryClient.setQueryData(queryKeyClasses, updatedValue);
    }

    await updateGroupClasses(classesToUpdate);

    return { previousClasses };
  };

export const ClassesEditGroups = () => {
  const checked = useGetCheckedStates();
  const terms = useContext(TermsContext);
  const queryClient = useQueryClient();
  const { groupId } = useParams();
  const queryKeyClasses = queryKeyClassesByGroup(groupId);
  const closeModal = useCloseModal();

  // Query for Classes of Group.
  const {
    isLoading: classesIsLoading,
    data: classes = [],
    isError: classesIsError,
    error: classesError,
  } = useClassesQueryByParams();

  // Query for Users of Group.
  const {
    isLoading: usersIsLoading,
    data: users = [],
    isError: usersIsError,
    error: usersError,
  } = useUsersQueryByParams();

  // Query for Groups associated with the user, so the user may, if they choose,
  // newly associate some of those groups to the selected classes.
  // Note: These are filtered to the current program by the hook.
  const {
    isLoading: userGroupsIsLoading,
    isError: userGroupsIsError,
    data: userGroups = [],
    error: userGroupsError,
  } = useGroupsQuery();

  // Query for Groups associated with all Classes. This is the same query used
  // by the classes page. This allows all groups currently associated with the
  // selected classes to correctly appear in the form, even if the current user
  // isn't a member of them.
  const {
    isLoading: classGroupsIsLoading,
    isError: classGroupsIsError,
    data: classGroups = [],
    error: classGroupsError,
  } = useGroupsQueryByClasses(classes);

  // Mutation: Edit groups from classes.
  // https://react-query.tanstack.com/guides/mutations
  const mutation = useMutation(
    classesEditGroupsMutationFn(
      queryClient,
      queryKeyClasses,
      classes,
      userGroups,
      checked,
      users,
    ),
    {
      // Handle successful mutation.
      onSuccess: (data) => {
        message.success(
          `Successfully edited ${terms.groups.toLowerCase()} for ` +
            `${terms.classes.toLowerCase()}.`,
        );
      },
      // If the mutation fails,
      // use the context returned from onMutate to roll back
      onError: (err, data, context: any) => {
        message.error(
          `Unable to edit ${terms.groups.toLowerCase()} for ` +
            `${terms.classes.toLowerCase()}.`,
        );
        queryClient.setQueryData(queryKeyClasses, context.previousClasses);
      },
    },
  );

  // Display loading.
  const isLoading =
    classesIsLoading ||
    classGroupsIsLoading ||
    userGroupsIsLoading ||
    usersIsLoading;

  if (isLoading) {
    return <Loading />;
  }

  // Display errors.
  if (
    classesIsError ||
    classGroupsIsError ||
    userGroupsIsError ||
    usersIsError
  ) {
    return (
      <ErrorMessageBox>
        {getMessageFromErrors([
          classesError,
          classGroupsError,
          userGroupsError,
          usersError,
        ])}
      </ErrorMessageBox>
    );
  }

  // Determine classes that have been selected.
  const checkedClasses: Class[] = classes.filter((cls) => checked[cls.uid]);

  // Add groups in every checked class
  const classesFilled: Class[] = checkedClasses.map((cls) => ({
    ...cls,
    groups: selectClassGroups(cls, classGroups),
  }));

  // Determine arrays of groups ids from classes
  // that have been selected.
  const classGroupIds = classesFilled.map(({ groups: grps = [] }) =>
    grps.map(({ uid }) => uid),
  );

  const uniqueClassGroupIds = uniq(flatMap(classGroupIds));

  const checkedGroupIds = intersection(...classGroupIds);

  const indeterminateGroupIds = difference(
    uniqueClassGroupIds,
    checkedGroupIds,
  );

  // https://stackoverflow.com/questions/65760158/react-query-mutation-typescript
  // Formik onSubmit handler
  const onSubmit = async (values: ClassesEditGroupsValuesProps) => {
    await mutation.mutateAsync(values);
  };

  return (
    <ClassesEditGroupsForm
      close={closeModal}
      onSubmit={onSubmit}
      classes={classesFilled}
      classGroups={classGroups}
      checkedGroupIds={checkedGroupIds}
      indeterminateGroupIds={indeterminateGroupIds}
      userGroups={userGroups}
    />
  );
};
