import {
  DatasetType,
  NetworkData,
  OrganizationData,
  OrganizationUserData,
  TeamData,
} from '@perts/model';

import { useQuery } from 'react-query';
import {
  Dataset,
  datasetGetById,
  datasetNetwork,
  datasetGroup,
  datasetGroupUser,
  datasetTeam,
} from 'models';

import { allowNotFound } from 'services/triton/helpers';

// -----------------------------------------------------------------------------
//   queryKey Generators
// -----------------------------------------------------------------------------

export const queryKeyDatasetById = (datasetId: string) => [
  'dataset',
  datasetId,
];
export const queryKeyDatasetByType = (
  type: DatasetType,
  parentId: string,
  userId?: string,
) => ['dataset-type', type, parentId, userId];

// -----------------------------------------------------------------------------
//   API Hooks
// -----------------------------------------------------------------------------

export const useDatasetGetById = (datasetId = '', options = {}) =>
  useQuery<Dataset, Error>(
    // queryKey
    queryKeyDatasetById(datasetId),
    // queryFn
    () => datasetGetById(datasetId),
    options,
  );

export const useDatasetNetwork = (networkId = '', options = {}) =>
  useQuery<NetworkData | null, Error>(
    queryKeyDatasetByType(DatasetType.network, networkId),
    () => allowNotFound(datasetNetwork, networkId),
    options,
  );

export const useDatasetGroup = (orgId = '', options = {}) =>
  useQuery<OrganizationData | null, Error>(
    queryKeyDatasetByType(DatasetType.organization, orgId),
    () => allowNotFound(datasetGroup, orgId),
    options,
  );

export const useDatasetGroupUser = (orgId = '', userId = '', options = {}) =>
  useQuery<OrganizationUserData | null, Error>(
    queryKeyDatasetByType(DatasetType.organizationUser, orgId, userId),
    () => allowNotFound(datasetGroupUser, orgId, userId),
    {
      enabled: Boolean(userId && orgId),
      ...options,
    },
  );

export const useDatasetTeam = (teamId = '', options = {}) =>
  useQuery<TeamData | null, Error>(
    queryKeyDatasetByType(DatasetType.team, teamId),
    () => allowNotFound(datasetTeam, teamId),
    {
      enabled: Boolean(teamId),
      ...options,
    },
  );
