// https://docs.google.com/spreadsheets/d/1ZX4hcUol-_frFLfUYRnZ-UPEvpGsVOsWsJRw1g5tDBM

import keyBy from 'lodash/keyBy';

import { locales } from '../i18n';
import { CompositeMetricConfig, MetricItemConfig } from './types';
import { catalyze24CompletelyTrueOptions } from './answerOptions/catalyze24CompletelyTrueOptions';

export const plc24WellOrganizedMeetingsItemConfig: MetricItemConfig[] = [
  {
    label: 'plc_too_many_topics',
    likertN: 2,
    minGood: 2,
    options: {
      [locales.ENGLISH]: [
        { value: 1, label: 'Yes' },
        { value: 2, label: 'No' },
      ],
    },
    prompts: {
      [locales.ENGLISH]: 'My PLC tries to address too many topics.',
    },
  },
  {
    label: 'plc_time_protocols',
    likertN: 4,
    minGood: 3,
    options: catalyze24CompletelyTrueOptions,
    prompts: {
      [locales.ENGLISH]:
        'My PLC has strong protocols or processes that maximize our ' +
        'professional learning time.',
    },
  },
  {
    label: 'plc_clear_takeaways',
    likertN: 4,
    minGood: 3,
    options: catalyze24CompletelyTrueOptions,
    prompts: {
      [locales.ENGLISH]:
        'I leave my PLC meetings with a clear understanding of the next ' +
        'steps I should take.',
    },
  },
];

export const plc24WellOrganizedMeetingsConfig: CompositeMetricConfig = {
  label: 'plc-well-organized-meetings',
  items: plc24WellOrganizedMeetingsItemConfig,
  itemIndex: keyBy(plc24WellOrganizedMeetingsItemConfig, 'label'),
  name: 'Facets of a Well-Organized Meeting',
  description:
    'A well-organized meeting ensures efficient use of time, clear ' +
    'communication, and focused collaboration, leading to productive ' +
    'outcomes and the advancement of collective goals centered on improving ' +
    'instructional practice.',
};
