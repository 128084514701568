import {
  Group,
  queryKeyGroup,
  updateGroup,
  useMutationUpdate,
  useGroupGetByParams,
  useProgramGetByParams,
} from 'models';
import { useParams } from 'pages';

import { GroupReportLoader } from '../GroupReport23';
import { ReportSettingsEnabledMetricsForm } from '../components';

export const ReportSettingsGroupEnabledMetrics = () => {
  const { groupId } = useParams();
  const groupQuery = useGroupGetByParams();
  const { data: program } = useProgramGetByParams();

  const queryKey = queryKeyGroup(groupId);
  const mutation = useMutationUpdate<Group, Group>(updateGroup, queryKey, {
    optimisticallyUpdate: false,
    transformFormValues: (formValues) => {
      if (!groupQuery.isSuccess) {
        throw new Error('Group data not loaded.');
      }
      const group = groupQuery.data;
      const enabledIds = Object.entries(formValues)
        .filter(([metricId, isChecked]) => isChecked)
        .map(([metricId]) => metricId);
      return {
        ...group,
        report_settings: {
          ...group?.report_settings,
          enabled_metrics: enabledIds,
        },
      };
    },
    toastSuccessMsg: `Successfully updated report settings.`,
    toastErrorMsg: `There was a problem updating report settings.`,
  });

  const onSubmit = async (formValues) => {
    await mutation.mutateAsync(formValues);
  };

  return (
    <GroupReportLoader>
      {({
        group,
        reportMetrics,
        // // unused:
        // dataset,
        // program,
        // programMetrics,
        // lastRun: dataset.last_run,
        // results,
      }) => (
        <ReportSettingsEnabledMetricsForm
          onSubmit={onSubmit}
          enabledMetricIds={group.report_settings.enabled_metrics}
          reportMetrics={reportMetrics}
          metricSets={program?.metric_sets || []}
        />
      )}
    </GroupReportLoader>
  );
};
