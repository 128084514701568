import { useContext } from 'react';
import { message } from 'antd';
import { useQueryClient } from 'react-query';

import {
  Group,
  updateGroup,
  queryKeyGroupsByProgram,
  invalidateUpdateGroup,
} from 'models';
import { useParams } from 'pages';
import { useQueryParams } from 'utils/useQueryParams';

import { Show, Text, EditInPlaceForm, Row, Col } from '@perts/ui';
import TermsContext from 'components/TermsContext';

type HomeEditGroupNameProps = {
  group: Group;
  mayEditNameInPlace: boolean;
};

const useQueryKeyGroupsByProgram = () => {
  const { programId } = useParams();
  const { get } = useQueryParams();
  const n = parseInt(get('nGroups') || '10', 10);
  const cursor = parseInt(get('cursorGroups') || '0', 10);
  const paging = { n, cursor };

  return queryKeyGroupsByProgram(programId, paging);
};

export const HomeEditGroupName = ({
  group,
  mayEditNameInPlace,
}: HomeEditGroupNameProps) => {
  const terms = useContext(TermsContext);
  const queryClient = useQueryClient();
  const queryKey = useQueryKeyGroupsByProgram();
  const queryInvalidateFn = () => invalidateUpdateGroup(queryClient, group.uid);
  const uniqComparator = (previous, current) => previous.uid === current.uid;

  return (
    <Text as="h2" overflowWrap="anywhere">
      <Show when={mayEditNameInPlace}>
        <Row>
          <Col>
            <EditInPlaceForm<Group>
              name="name"
              label={`Edit in place ${group.name}`}
              value={group}
              emptyValueMessage="No name set"
              queryKey={queryKey}
              queryUpdateFn={updateGroup}
              queryInvalidateFn={queryInvalidateFn}
              uniqComparator={uniqComparator}
              successFn={() =>
                message.success(
                  `Successfully updated the ${terms.group.toLowerCase()}'s name.`,
                )
              }
              errorFn={() =>
                message.error(
                  `There was an error updating that ${terms.group.toLowerCase()}'s name.`,
                )
              }
            />
          </Col>
        </Row>
      </Show>

      <Show when={!mayEditNameInPlace}>
        <span data-testid="static-group-name">{group.name}</span>
      </Show>
    </Text>
  );
};
