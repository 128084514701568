import { useMutation, useQueryClient } from 'react-query';
import { message } from 'antd';
import { uniqBy } from 'lodash';
import pluralize from 'pluralize';

import { Modal } from '@perts/ui';
import { getMessageFromErrors } from '@perts/util';
import { ErrorMessageBox, Loading, useTerms } from 'components';
import {
  Class,
  queryKeyClassesByGroup,
  updateGroupClasses,
  useClassesQueryByParams,
  useGroupGetByParams,
} from 'models';
import { useParams } from 'pages';
import {
  RosterSignOnLockForm,
  RosterSignOnLockFormValues,
  getDefaultValuesSignOn,
  getInitialValuesSignOn,
} from 'pages/Classes/shared';
import { useCloseModal, useGetCheckedStates } from 'utils';

export const ClassesEditRosterSignOnLock = () => {
  const checked = useGetCheckedStates();
  const terms = useTerms();
  const queryClient = useQueryClient();
  const { groupId = '' } = useParams();
  const queryKeyClasses = queryKeyClassesByGroup(groupId);
  const closeModal = useCloseModal();

  const groupQuery = useGroupGetByParams();
  const classesQuery = useClassesQueryByParams();

  const mutation = useMutation(updateGroupClasses, {
    onMutate: (classesToUpdate: Class[]) => {
      // Snapshot the previous classes value.
      const previous = queryClient.getQueryData<Class[]>(queryKeyClasses);

      // Optimistically update.
      const optimistic = previous
        ? uniqBy([...classesToUpdate, ...previous], 'uid')
        : classesToUpdate;

      queryClient.setQueryData(queryKeyClasses, optimistic);

      // Return a context object with the snapshot value.
      return { previous };
    },
    onSuccess: () => {
      message.success(
        `Successfully edited roster locking for ` +
          `${terms.classes.toLowerCase()}.`,
      );
    },
    onError: (_err, _data, context: any) => {
      if (context?.previous) {
        queryClient.setQueryData(queryKeyClasses, context.previous);
      }
    },
  });

  // Display loading.
  if (groupQuery.isLoading || classesQuery.isLoading || !checked) {
    return <Loading />;
  }

  // Display errors.
  if (!groupQuery.isSuccess || !classesQuery.isSuccess) {
    return (
      <ErrorMessageBox>
        {getMessageFromErrors([groupQuery.error, classesQuery.error])}
      </ErrorMessageBox>
    );
  }

  // Determine classes that have been selected.
  const checkedClasses =
    classesQuery.data?.filter((cls) => checked[cls.uid]) || [];

  const initialValues = getInitialValuesSignOn<RosterSignOnLockFormValues>(
    checkedClasses,
    ['rosterLocked', 'ruleEnabled'],
  );
  const defaultValues = getDefaultValuesSignOn(groupQuery.data);

  const onSubmit = async (values: RosterSignOnLockFormValues) => {
    // Update values on selected classes to be sent to the api function.
    const classesToUpdate = checkedClasses.map((cls) => ({
      ...cls,
      roster_locked: Boolean(values.rosterLocked),
    }));

    await mutation.mutateAsync(classesToUpdate);
  };

  return (
    <Modal close={closeModal}>
      <RosterSignOnLockForm
        close={closeModal}
        submitButtonText={`Save Changes to ${pluralize(
          terms.class,
          checkedClasses.length,
          true,
        )}`}
        onSubmit={onSubmit}
        initialValues={initialValues}
        defaultValues={defaultValues}
      />
    </Modal>
  );
};
