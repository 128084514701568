// Standardizes use of colorWhen and icons for all student experience tables.
//
// * When changes are > 5 %, color in green.
// * When changes are < -5 %, color in red.
// * Any value > 0 gets an up-arrow icon
// * Any value < 0 gets a down-arrow icon
//
// Example:
//
// return (
//   <ChangeText data={row.original.rated_positive_change_all_num}>
//     {row.original.rated_positive_change_all}
//   </ChangeText>
// );
import React, { ReactNode } from 'react';

import colorWhen from 'components/colorWhen';

import { theme, Show } from '@perts/ui';

const CHANGE_THRESHOLD = 6;

export const ColorChangeText = colorWhen([
  {
    ariaLabel: 'Highlighted positive change',
    color: theme.colors.textColorGreenOnGreen,
    backgroundColor: theme.colors.textBgGreen,
    when: (x) => x >= CHANGE_THRESHOLD,
  },
  {
    ariaLabel: 'Highlighted negative change',
    color: theme.colors.textColorRedOnRed,
    backgroundColor: theme.colors.textBgRed,
    when: (x) => x <= -1 * CHANGE_THRESHOLD,
  },
]);

type Props = { children: ReactNode; data: number | null };

export const ChangeText = ({ children, data }: Props) =>
  data === null ? (
    <span>—</span>
  ) : (
    <ColorChangeText data={data}>
      <Show when={data > 0}>+</Show>
      {children}
    </ColorChangeText>
  );

export const ChangeLegend = () => (
  <p>
    Changes &ge;{' '}
    <ColorChangeText data={CHANGE_THRESHOLD}>
      +{CHANGE_THRESHOLD}
    </ColorChangeText>{' '}
    or &le;{' '}
    <ColorChangeText data={-1 * CHANGE_THRESHOLD}>
      -{CHANGE_THRESHOLD}
    </ColorChangeText>{' '}
    percentage points are highlighted.
  </p>
);
