import { Route, Switch } from 'react-router-dom';
import {
  NetworkMembers,
  NetworkMembersAdd,
  NetworkMembersRemove,
  toNetworkMembers,
  toNetworkMembersAdd,
  toNetworkMembersRemove,
} from 'pages';

export const NetworkMembersRouter = () => (
  <>
    <Route path={toNetworkMembers()}>
      <NetworkMembers />
    </Route>

    <Switch>
      <Route path={toNetworkMembersAdd()}>
        <NetworkMembersAdd />
      </Route>
      <Route path={toNetworkMembersRemove()}>
        <NetworkMembersRemove />
      </Route>
    </Switch>
  </>
);
