import styled, { keyframes } from 'styled-components';

import { IconSpinner } from '@perts/ui';

const LoadingContainer = styled.div`
  padding: 14px 10px;
`;

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const Spinner = styled(IconSpinner)`
  display: inline-flex;
  animation: ${rotate} infinite 1s linear;
  margin-right: 5px;
`;

const Loading = () => (
  <LoadingContainer>
    <Spinner />
    <span>Loading...</span>
  </LoadingContainer>
);

export default Loading;
