import { Network, User, selectUserIsAdmin } from 'models';
import { Col, Row, Show } from '@perts/ui';
import { CardNetwork } from './CardNetwork';

type HomeNetworksProps = {
  user: User;
  networks: Network[];
  emptyMessage?: JSX.Element;
  pagination: JSX.Element;
};

export const HomeNetworks = ({
  user,
  networks,
  emptyMessage,
  pagination,
}: HomeNetworksProps) => {
  const isAdmin = selectUserIsAdmin(user);

  return (
    <>
      <Show when={networks.length === 0 && Boolean(emptyMessage)}>
        {emptyMessage}
      </Show>

      <Show when={networks.length > 0}>
        {pagination}

        {networks.map((network) => (
          <Row key={network.uid}>
            <Col>
              <CardNetwork
                key={network.uid}
                network={network}
                mayEditNameInPlace={isAdmin}
                mayRemoveNetworks={isAdmin}
              />
            </Col>
          </Row>
        ))}

        {pagination}
      </Show>
    </>
  );
};
