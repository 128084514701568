import { useContext } from 'react';
import { Show, InfoBox } from '@perts/ui';
import TermsContext from 'components/TermsContext';

export type MemberLeaveFormMessageProps = {
  mayLeaveGroup: boolean;
  memberToLeaveIsUniqueLead: boolean;
};

export const MemberLeaveFormMessage = ({
  mayLeaveGroup,
  memberToLeaveIsUniqueLead,
}: MemberLeaveFormMessageProps) => {
  const terms = useContext(TermsContext);

  return (
    <>
      <Show when={memberToLeaveIsUniqueLead}>
        <InfoBox color="warning" data-testid="unique-lead-leave-group-message">
          You are the only {terms.groupManager.toLowerCase()} in this{' '}
          {terms.class.toLowerCase()}. Assign someone else as the{' '}
          {terms.groupManager.toLowerCase()}.
        </InfoBox>
      </Show>

      <Show when={mayLeaveGroup && !memberToLeaveIsUniqueLead}>
        <InfoBox color="warning" data-testid="allow-leave-group-message">
          Are you sure you want to leave this {terms.group.toLowerCase()}?
        </InfoBox>
      </Show>
      <Show when={!mayLeaveGroup && !memberToLeaveIsUniqueLead}>
        <InfoBox color="warning" data-testid="dont-allow-leave-group-message">
          You are {terms.aGroupMember.toLowerCase()} of one or more{' '}
          {terms.classes.toLowerCase()} in this {terms.group.toLowerCase()}.
          Either remove those {terms.classes.toLowerCase()} from this{' '}
          {terms.group.toLowerCase()} or assign someone else as the{' '}
          {terms.classManager.toLowerCase()}.
        </InfoBox>
      </Show>
    </>
  );
};
