import moment from 'moment';
import styled from 'styled-components';

type StyledProps = {
  textAlign?: string;
};

const DashboardUpdatedFrequencyContainer = styled.div<StyledProps>`
  font-size: 12px;

  text-align: ${(props) => props.textAlign || 'left'};
`;

type Props = StyledProps & {
  dateString?: string;
};

export const DashboardUpdatedFrequency = ({ dateString, textAlign }: Props) => {
  const ago = moment(dateString).fromNow();
  const time = moment(dateString).format('LT');
  const updated = dateString ? `${time} (${ago})` : 'daily';

  return (
    <DashboardUpdatedFrequencyContainer textAlign={textAlign}>
      Participation through {updated}. This data updates daily.
    </DashboardUpdatedFrequencyContainer>
  );
};
