// https://docs.google.com/spreadsheets/d/1EKKzZjI-eCtekB_S_SsNnBQpGEKVIKueZIkBD6W8QJc

import keyBy from 'lodash/keyBy';

import { locales } from '../i18n';
import { CompositeMetricConfig, MetricItemConfig } from './types';

export const growthMindsetItemConfig: MetricItemConfig[] = [
  {
    label: 'growth_mindset_certain',
    likertN: 6,
    likertReverse: true,
    minGood: 5,
    prompts: {
      [locales.ENGLISH]:
        `You have a certain amount of intelligence, and you ` +
        `can’t really do much to change it.`,
      [locales.SPANISH]:
        `Tienes una cierta cantidad de inteligencia, y ` +
        `realmente no puedes hacer mucho para cambiarla.`,
    },
  },
  {
    label: 'growth_mindset_learn',
    likertN: 6,
    likertReverse: true,
    minGood: 5,
    prompts: {
      [locales.ENGLISH]:
        `You can learn new things, but you can’t really change your basic ` +
        `intelligence.`,
      [locales.SPANISH]:
        `Puedes aprender cosas nuevas, pero realmente no puedes cambiar tu ` +
        `inteligencia básica.`,
    },
  },
];

export const growthMindsetConfig: CompositeMetricConfig = {
  label: 'growth-mindset',
  items: growthMindsetItemConfig,
  itemIndex: keyBy(growthMindsetItemConfig, 'label'),
};
