// This file isn't in typescript because react-table doesn't make its types
// easy to use. The following is an example from the author(s), where you have
// to wrangle many of their types for them to make it work, and I'm not going
// to bother:
// https://github.com/tannerlinsley/react-table/pull/1585/files/d80305deb5d4653c49222f750941d78a9b9da9a9
import React from 'react';
import styled from 'styled-components/macro';
import { DataTablePageNavLink } from './DataTablePageNavLink';

const NavContainer = styled.div`
  text-align: center;
  font-style: normal;

  @media print {
    display: none;
  }
`;

export const DataTablePageNav = ({
  canNextPage,
  canPreviousPage,
  gotoPage,
  nextPage,
  pageCount,
  pageIndex,
  previousPage,
}) => (
  <NavContainer>
    <DataTablePageNavLink
      onClick={() => gotoPage(0)}
      disabled={!canPreviousPage}
    >
      &lt;&lt;
    </DataTablePageNavLink>
    <DataTablePageNavLink onClick={previousPage} disabled={!canPreviousPage}>
      &lt;
    </DataTablePageNavLink>
    {Object.keys(Array(pageCount).fill()).map((pageIndexLink, i) => (
      <DataTablePageNavLink
        key={i}
        onClick={() => gotoPage(pageIndexLink)}
        active={Number(pageIndex) === i}
      >
        {i + 1}
      </DataTablePageNavLink>
    ))}
    <DataTablePageNavLink onClick={nextPage} disabled={!canNextPage}>
      &gt;
    </DataTablePageNavLink>
    <DataTablePageNavLink
      onClick={() => gotoPage(pageCount - 1)}
      disabled={!canNextPage}
    >
      &gt;&gt;
    </DataTablePageNavLink>
  </NavContainer>
);
