import { useQuery } from 'react-query';

import {
  Cycle,
  cyclesQueryByUserIdAndGroupId,
  useCurrentUserQuery,
} from 'models';
import { useParams } from 'pages';

export * from './useCyclesCreateByClasses';
export * from './useCyclesRemove';
export * from './useCyclesUpdate';

// -----------------------------------------------------------------------------
//   queryKey Generators
// -----------------------------------------------------------------------------

export const queryKeyCyclesByGroup = (groupId: string) => [
  'cycles',
  'byGroup',
  groupId,
];

// -----------------------------------------------------------------------------
//   API Hooks
// -----------------------------------------------------------------------------

// Query Cycles of provided Group.

export const useCyclesQueryByGroupId = (userId = '', groupId = '') =>
  useQuery<Cycle[], Error>(
    queryKeyCyclesByGroup(groupId),
    () => cyclesQueryByUserIdAndGroupId(userId, groupId),
    { enabled: Boolean(userId && groupId) },
  );

export const useCyclesQueryByParams = () => {
  const { groupId } = useParams();
  const { data: user } = useCurrentUserQuery();

  return useCyclesQueryByGroupId(user?.uid, groupId);
};
