import { useParams } from 'react-router-dom';
import {
  PagesParams,
  toGroupClasses,
  toGroupParticipation,
  toGroupSchedules,
} from 'pages';
import { useTerms } from 'components/TermsContext';
import { GuideBox, HelpText, Link } from '@perts/ui';
import { toGroupSurveysInstructions } from 'pages/routes';
import { helpArticles } from 'config';

export const SurveysGuideBox = ({
  // Are there classes?
  isClasses = false,

  // Have schedules been set for some of those classes?
  isSchedulesSet = false,
}) => {
  const { groupId = '' } = useParams<PagesParams>();
  const terms = useTerms();

  if (!isClasses) {
    return (
      <GuideBox
        action={
          <Link to={toGroupClasses(groupId)}>
            Create {terms.aClass} to survey {terms.participants.toLowerCase()}.
          </Link>
        }
        faq={null}
        next={<Link to={toGroupParticipation(groupId)}>Participation</Link>}
      />
    );
  }

  if (isClasses && !isSchedulesSet) {
    return (
      <GuideBox
        action={
          <Link to={toGroupSchedules(groupId)}>
            Set a survey schedule then return to Surveys.
          </Link>
        }
        faq={null}
        next={<Link to={toGroupParticipation(groupId)}>Participation</Link>}
      />
    );
  }

  if (isClasses && isSchedulesSet) {
    return (
      <GuideBox
        action={
          <Link to={toGroupSurveysInstructions(groupId)}>
            View survey instructions and ask {terms.participants.toLowerCase()}{' '}
            to complete the survey.
          </Link>
        }
        faq={
          <HelpText articleId={helpArticles.guideboxSurveys}>
            How do I administer the survey to my{' '}
            {terms.participants.toLowerCase()}?
          </HelpText>
        }
        next={<Link to={toGroupParticipation(groupId)}>Participation</Link>}
      />
    );
  }

  return null;
};
