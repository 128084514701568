import { Button, Card, Col, IconReport, Row } from '@perts/ui';
import { FiChevronRight } from 'react-icons/fi';
import { getKind } from '@perts/util';
import { Network, Group } from 'models';
import { toArchiveGroup, toArchiveNetworkReport } from 'pages';

export const ArchiveResultsCard = ({ entity }: { entity: Network | Group }) => {
  const isNetwork = getKind(entity.uid) === 'Network';
  const to = isNetwork
    ? toArchiveNetworkReport(entity.uid)
    : toArchiveGroup(entity.uid);

  return (
    <Card>
      <Card.Content>
        <Row alignItems="center">
          <Col colsSm={12}>
            {getKind(entity.uid) === 'Network' && 'Network: '}
            {entity.name}
          </Col>
          <Col colsSm={12} shrink>
            <Button
              to={to}
              color="text"
              iconLeft={<IconReport />}
              iconRight={<FiChevronRight />}
              fullWidth
            >
              Report
            </Button>
          </Col>
        </Row>
      </Card.Content>
    </Card>
  );
};
