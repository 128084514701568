import { ReactNode } from 'react';

import { Visible } from '@perts/ui';
import {
  BarChartHorizontalContainer,
  BarChartHorizontalHiddenLabel,
  BarChartHorizontalLabelValue,
  BarChart,
  BarChartBar,
  BarChartThreshold,
  BarChartIconWrapper,
} from './BarChartRatedPositivelyStyled';

export type BarChartHorizontalProps = {
  /**
   * The aria-label of the chart. Not normally visible.
   */
  label: string;

  /**
   * Between 0 and 1.
   */
  value: number | null;

  /**
   * Optional. Between 0 and 1. If `value` is below `threshold`, then
   * a warning icon will be displayed.
   */
  threshold?: number;

  /**
   * Color of the bar.
   */
  color: string;

  /**
   * Height of the component, in pixels.
   */
  height?: number;

  /**
   * Component. If defined, will leave room for this icon on the right.
   */
  Icon?: Function;

  /**
   * If this is true, and an icon is defined, the icon will be displayed.
   */
  iconActive?: boolean;

  /**
   * The aria-label for the icon. Must provide if icon is defined.
   */
  iconLabel?: string;

  /**
   * How to display the numeric value to the left of the bar. If not provided,
   * this will be calculated from the `value` prop.
   */
  valueDisplay?: ReactNode;
};

export const BarChartRatedPositively = ({
  color,
  height = 32,
  Icon,
  iconActive = false,
  iconLabel,
  label,
  threshold,
  value,
  valueDisplay,
}: BarChartHorizontalProps) => {
  if (value === null) {
    return <div>No data</div>;
  }

  if (Icon !== undefined && iconLabel === undefined) {
    throw new Error('Must define a label for provided icon.');
  }

  const barHeight = height - 12;
  const percent = Math.round(value * 100);

  return (
    <BarChartHorizontalContainer height={height}>
      <BarChartHorizontalHiddenLabel>{label}</BarChartHorizontalHiddenLabel>
      <BarChartHorizontalLabelValue>
        {valueDisplay ? valueDisplay : `${percent}%`}
      </BarChartHorizontalLabelValue>
      <BarChart height={barHeight}>
        {threshold && (
          <BarChartThreshold
            height={barHeight}
            threshold={threshold}
            value={value}
          />
        )}
        <BarChartBar height={barHeight} color={color} percent={percent} />
      </BarChart>
      {Icon && (
        <Visible when={iconActive}>
          <BarChartIconWrapper>
            <Icon aria-label={iconLabel} />
          </BarChartIconWrapper>
        </Visible>
      )}
    </BarChartHorizontalContainer>
  );
};
