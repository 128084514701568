// Apply color to text based on set rules and dynamic data. Color defaults to
// black.
//
// Example:
//
// const ColorWithData = colorWhen([
//   { color: theme.colors.redDark, when: (data) => data.value < 5 },
//   { color: theme.colors.greenDark, when: (data) => data.value > 10 }
// ])
// return <ColorWithData data={data}>Display Text</ColorWithData>

import React from 'react';
import styled, { css } from 'styled-components/macro';

import { theme } from '@perts/ui';

const ColorText = styled.span<{ color: string; backgroundColor?: string }>`
  color: ${({ color }) => color};

  ${({ backgroundColor }) =>
    backgroundColor &&
    css`
      background-color: ${backgroundColor};
      padding: 3px 5px;
      border-radius: ${theme.units.borderRadius};
      word-break: keep-all;

      @media only screen and (max-width: ${theme.units.gridBreakpointSmall}px) {
        padding: 2px 2px;
      }
    `}
`;

type WhenFunction = (args: any) => boolean;
type ColorRule = {
  ariaLabel?: string;
  color: string;
  backgroundColor?: string;
  when: WhenFunction;
};

type RenderProps = {
  children: React.ReactNode;
  data: any;
};

const colorWhen =
  (rules: ColorRule[]) =>
  ({ children, data }: RenderProps) => {
    // First rule that returns true.
    const defaultRule: ColorRule = { color: 'black', when: () => true };
    const rule = rules.find(({ when }) => when(data)) || defaultRule;
    return (
      <ColorText
        aria-label={rule.ariaLabel || ''}
        color={rule.color}
        backgroundColor={rule.backgroundColor}
      >
        {children}
      </ColorText>
    );
  };

export default colorWhen;
