import { Route, Switch } from 'react-router-dom';
import {
  toGroupClasses,
  toGroupClassesAdd,
  toGroupClassesDefaultSettings,
  toGroupClassesEditGroups,
  toGroupClassesEditTargetGroup,
  toGroupClassesEditUsers,
  toGroupClassesRemove,
  toGroupClassesRosterIDRule,
  toGroupClassesRosterLocking,
  toGroupClassesRosterSignOnType,
  Classes,
  ClassesAdd,
  ClassesDefaultSettings,
  ClassesEditTargetGroup,
  ClassesEditRosterSignOnLock,
  ClassesEditRosterSignOnRule,
  ClassesEditRosterSignOnType,
  ClassesEditGroups,
  ClassesEditUsers,
  ClassesRemove,
} from '../';

export const ClassesRouter = () => (
  <>
    <Switch>
      <Route path={toGroupClassesDefaultSettings()}>
        <ClassesDefaultSettings />
      </Route>
      <Route path={toGroupClasses()}>
        <Classes />
      </Route>
    </Switch>
    <Switch>
      <Route path={toGroupClassesAdd()}>
        <ClassesAdd />
      </Route>

      <Route path={toGroupClassesRemove()}>
        <ClassesRemove />
      </Route>

      <Route path={toGroupClassesEditUsers()}>
        <ClassesEditUsers />
      </Route>

      <Route path={toGroupClassesEditGroups()}>
        <ClassesEditGroups />
      </Route>

      <Route path={toGroupClassesEditTargetGroup()}>
        <ClassesEditTargetGroup />
      </Route>

      <Route path={toGroupClassesRosterIDRule()}>
        <ClassesEditRosterSignOnRule />
      </Route>

      <Route path={toGroupClassesRosterSignOnType()}>
        <ClassesEditRosterSignOnType />
      </Route>

      <Route path={toGroupClassesRosterLocking()}>
        <ClassesEditRosterSignOnLock />
      </Route>
    </Switch>
  </>
);
