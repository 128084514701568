import { Formik, Form } from 'formik';

import { Col, HelpText, Row, Show, Text, useFormError } from '@perts/ui';
import { DefaultSettingsWarning, FormButtons, useTerms } from 'components';
import { helpArticles } from 'config';
import { SurveysEditAdvancedFields } from './SurveysEditAdvancedFields';

const DisplayHelpText = () => (
  <Col shrink vAlign="center" hAlign="flex-end">
    <HelpText articleId={helpArticles.genderIdentity}>
      How is gender identity collected and reported?
    </HelpText>
  </Col>
);

export type SurveysEditAdvancedFormValues = {
  genderQuestionEnabled: boolean | null;
};

type SurveysEditAdvancedFormProps = {
  close?: () => void;
  submitButtonText: React.ReactNode;
  onSubmit: (values: SurveysEditAdvancedFormValues) => void;
  validate?: (values: SurveysEditAdvancedFormValues) => void | object;
  initialValues: SurveysEditAdvancedFormValues;
  defaultValues: SurveysEditAdvancedFormValues | null;
};

export const SurveysEditAdvancedForm = ({
  close,
  submitButtonText,
  onSubmit,
  validate,
  initialValues,
  defaultValues,
}: SurveysEditAdvancedFormProps) => {
  const terms = useTerms();
  const [FormError, showFormError] = useFormError();
  const isDefaultSettingsView = false;

  return (
    <>
      <Row alignItems="center">
        <Col shrink vAlign="center" hAlign="center">
          <Text as="h2">
            <span>Advanced Settings</span>
          </Text>
        </Col>

        <Show when={!Boolean(close)}>
          <Col></Col>
          <DisplayHelpText />
        </Show>
      </Row>

      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validate={validate}
        onSubmit={async (values) => {
          try {
            showFormError(false);
            await onSubmit(values);
          } catch (error) {
            showFormError(true);
          }
        }}
      >
        {({ isSubmitting, values }) => (
          <Form>
            <SurveysEditAdvancedFields
              isSubmitting={isSubmitting}
              values={values}
            />

            <Row>
              <Col>
                <FormError />
              </Col>
            </Row>

            <Show when={Boolean(close)}>
              <Row>
                <DisplayHelpText />
              </Row>
            </Show>

            <Row>
              <Col>
                <FormError />
                <Show when={!isDefaultSettingsView}>
                  <DefaultSettingsWarning
                    hideWhenErrors={true}
                    message={`This change doesn't match the ${terms.group}'s default settings.`}
                    defaultValues={defaultValues}
                  />
                </Show>
              </Col>
            </Row>

            <FormButtons close={close} submitButtonText={submitButtonText} />
          </Form>
        )}
      </Formik>
    </>
  );
};
