// https://docs.google.com/spreadsheets/d/1ZX4hcUol-_frFLfUYRnZ-UPEvpGsVOsWsJRw1g5tDBM

import keyBy from 'lodash/keyBy';

import { locales } from '../i18n';
import { CompositeMetricConfig, MetricItemConfig } from './types';
import { catalyze24CompletelyTrueOptions } from './answerOptions/catalyze24CompletelyTrueOptions';

export const plc24CurriculumUseItemConfig: MetricItemConfig[] = [
  {
    label: 'plc_understand_curriculum',
    likertN: 4,
    minGood: 3,
    options: catalyze24CompletelyTrueOptions,
    prompts: {
      [locales.ENGLISH]:
        'My professional learning activities are advancing my understanding ' +
        'of how to use my curriculum in my classroom.',
    },
  },
  {
    label: 'plc_have_training',
    likertN: 4,
    minGood: 3,
    options: catalyze24CompletelyTrueOptions,
    prompts: {
      [locales.ENGLISH]:
        'I have the training I need to implement my curriculum.',
    },
  },
  {
    label: 'plc_curriculum_confidence',
    likertN: 4,
    minGood: 3,
    options: {
      [locales.ENGLISH]: [
        { value: 4, label: 'Very confident' },
        { value: 3, label: 'Confident' },
        { value: 2, label: 'Somewhat confident' },
        { value: 1, label: 'Not at all confident' },
      ],
    },
    prompts: {
      [locales.ENGLISH]:
        'How confident are you in using your school’s curriculum in your ' +
        'classroom?',
    },
  },
];

export const plc24CurriculumUseConfig: CompositeMetricConfig = {
  label: 'plc-curriculum-use',
  items: plc24CurriculumUseItemConfig,
  itemIndex: keyBy(plc24CurriculumUseItemConfig, 'label'),
  name: 'Curriculum Use',
  description:
    'Discussing curriculum to build comfort with teacher use is important ' +
    'for professional learning communities because it ensures that teaching ' +
    'practices are aligned with educational standards and are continuously ' +
    'refined to meet the evolving needs of students.',
};
