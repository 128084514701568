import { Route, Switch } from 'react-router-dom';

import { Loading } from 'components';
import { useCurrentUserQuery } from 'models';
import {
  NetworkGroupsRouter,
  NetworkMembersRouter,
  NetworkParticipation,
  NetworksRedirect,
  ReportsRouter,
  toNetworkGroups,
  toNetworkMembers,
  toNetworkParticipation,
  toNetworkReport,
  toNetworkReportSettings,
  toNetworkReports,
} from 'pages';

export const PagesRouterNetwork = () => {
  const { isLoading } = useCurrentUserQuery();

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Switch>
      <Route
        path={[
          toNetworkReport(),
          toNetworkReports(),
          toNetworkReportSettings(),
        ]}
      >
        <ReportsRouter />
      </Route>

      <Route path={toNetworkGroups()}>
        <NetworkGroupsRouter />
      </Route>

      <Route path={toNetworkMembers()}>
        <NetworkMembersRouter />
      </Route>

      <Route path={toNetworkParticipation()}>
        <NetworkParticipation />
      </Route>

      {/* Catchall redirect */}
      <NetworksRedirect />
    </Switch>
  );
};
