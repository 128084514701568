import Entity from 'services/Entity';
import { TRITON_URL_PREFIX } from './config';
import {
  QueryParams,
  fetchApi,
  fetchOptionsGET,
  fetchOptionsPOST,
  generateFetchFunctions,
  paginationParamsToServer,
  urlWithQueryParams,
} from './helpers';

export interface NetworkEntity extends Entity {
  name: string;
  code: string;
  program_id: string;
  association_ids: string[];
  num_networks?: number;
  num_organizations?: number;
  num_users?: number;
}

export const {
  // boilerplate
  get,
  query,
  queryByUser,
  post,
  postBatch,
  update,
  remove,

  // custom
  queryByNetwork,
  queryPagination,
  changeCode,
} = generateFetchFunctions('networks', {
  queryByNetwork: (networkId: string) => {
    const url = `${TRITON_URL_PREFIX}/api/networks/${networkId}/networks`;
    const options = fetchOptionsGET();
    return fetchApi(url, options);
  },

  queryPagination: (queryParams: QueryParams) => {
    const options = fetchOptionsGET();
    const url = `${TRITON_URL_PREFIX}/api/networks`;
    const queryParamsToServer = paginationParamsToServer(queryParams);
    const urlWithParams = urlWithQueryParams(url, queryParamsToServer);
    return fetchApi(urlWithParams, options);
  },

  changeCode: (networkId: string) => {
    const url = `${TRITON_URL_PREFIX}/api/networks/${networkId}/code`;
    const options = fetchOptionsPOST();
    return fetchApi(url, options);
  },
});
