import styled from 'styled-components/macro';
import { useTable, useSortBy, usePagination } from 'react-table';

import Paginator from 'components/Paginator';
import theme from 'components/theme';
import {
  Col,
  IconSort,
  IconSortUp,
  IconSortDown,
  Row,
  TextField,
  Text,
} from '@perts/ui';

const SortHeader = styled.th`
  svg {
    margin-left: 10px;
  }
`;

const TableStyled = styled.table`
  table-layout: fixed;
  width: 100%;
  margin-bottom: ${theme.units.paragraphSpacing};

  thead {
    border-bottom: 1px solid black;

    tr:last-child {
      th {
        padding-bottom: 10px;
      }
    }
  }

  tbody {
    tr:first-child {
      td {
        padding-top: 10px;
      }
    }

    tr:nth-child(even) {
      background-color: ${theme.palette.lightGray};
    }
  }

  th,
  td {
    text-align: center;

    padding: 5px;

    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const PagitationInput = styled(TextField)`
  display: flex;
  width: 70px;
  margin-left: ${theme.units.fieldPadding};
`;

export const ParticipationProgressTable = ({ columns, data }) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,

    // from usePagination
    // https://codesandbox.io/embed/github/tannerlinsley/react-table/tree/master/examples/pagination
    page,
    canPreviousPage,
    canNextPage,
    // pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      // https://react-table.tanstack.com/docs/api/useSortBy
      initialState: {
        sortBy: [{ id: 'progress', desc: true }],
        pageSize: 20,
      },
    },
    useSortBy,
    usePagination,
  );

  const colStyles = [
    {}, // Roster ID
    {}, // Completed
  ];

  return (
    <>
      <TableStyled {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, i) =>
                column.canSort ? (
                  // Add the sorting props to control sorting. For this example
                  // we can add them into the header props
                  <SortHeader
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    style={colStyles[i]}
                  >
                    {column.render('Header')}
                    {/* Add a sort direction indicator */}
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <IconSortDown />
                      ) : (
                        <IconSortUp />
                      )
                    ) : (
                      <IconSort />
                    )}
                  </SortHeader>
                ) : (
                  <th {...column.getHeaderProps()} style={colStyles[i]}>
                    {column.render('Header')}
                  </th>
                ),
              )}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell, i) => (
                  <td {...cell.getCellProps()} style={colStyles[i]}>
                    {cell.render('Cell')}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </TableStyled>

      <Paginator
        canNextPage={canNextPage}
        canPreviousPage={canPreviousPage}
        currentPageIndex={pageIndex}
        displayRangeSize={15}
        goToNextPage={nextPage}
        goToPageIndex={gotoPage}
        goToPreviousPage={previousPage}
        pageCount={pageCount}
      />

      <Row>
        <Col hAlign="center" vAlign="center">
          <Text>Rows per page:</Text>
          <PagitationInput
            type="number"
            value={pageSize}
            onChange={(e) => setPageSize(Math.max(Number(e.target.value), 1))}
            min={1}
            displayError={false}
          />
        </Col>
      </Row>
    </>
  );
};
