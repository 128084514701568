import { Class, Cycle } from 'models';
import { createCyclesLookupFromFormValues } from './createCyclesLookupFromFormValues';
import { DEFAULT_PAGE_SIZE } from 'config';

type CyclesMap = {
  uid: Cycle['uid'];
  ordinal: number;
  classUid: Class['uid'];
  classPageIndex: number;
  page: number;
};

export const createUnscheduledCyclesMap = (
  classes: Class[],
  formValues: any,
) => {
  const unscheduledCyclesMap: CyclesMap[] = [];

  const draftUnscheduledLookup = createCyclesLookupFromFormValues(formValues);

  classes.forEach((cls, classIndex) => {
    const page = Math.ceil((classIndex + 1) / DEFAULT_PAGE_SIZE);

    (cls.cycles || []).forEach((cycle) => {
      if (draftUnscheduledLookup[cycle.uid]) {
        unscheduledCyclesMap.push({
          uid: cycle.uid,
          ordinal: cycle.ordinal,
          classUid: cls.uid,
          classPageIndex: (classIndex % DEFAULT_PAGE_SIZE) + 1,
          page,
        });
      }
    });
  });

  return unscheduledCyclesMap;
};
