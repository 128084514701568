import { useContext } from 'react';
import { useQueryClient } from 'react-query';
import { message } from 'antd';

import {
  Participant,
  invalidateUpdateParticipants,
  queryKeyParticipantsByClass,
  updateParticipants,
} from 'models';
import { useParams } from 'pages';
import { SwitchForm } from '@perts/ui';
import TermsContext from 'components/TermsContext';

export const ParticipantsEditTargetForm = ({ all, selected }) => {
  const { classId } = useParams();
  const queryClient = useQueryClient();
  const terms = useContext(TermsContext);

  const switchName = 'switchTargetGroup';
  const switchLabel = 'Focal group';

  const isOn = (participant: Participant) =>
    Boolean(participant.in_target_group);

  const switchOn = (participant: Participant): Participant => ({
    ...participant,
    in_target_group: true,
  });

  const switchOff = (participant: Participant): Participant => ({
    ...participant,
    in_target_group: false,
  });

  const queryUpdateFn = async (participantsToUpdate) => {
    await updateParticipants(participantsToUpdate);
  };

  const queryInvalidateFn = () =>
    invalidateUpdateParticipants(queryClient, classId);

  const uniqComparator = (participant1, participant2) =>
    participant1.uid === participant2.uid;

  return (
    <SwitchForm<Participant>
      name={switchName}
      label={switchLabel}
      all={all}
      selected={selected}
      isOn={isOn}
      switchOn={switchOn}
      switchOff={switchOff}
      queryKey={queryKeyParticipantsByClass(classId)}
      queryUpdateFn={queryUpdateFn}
      queryInvalidateFn={queryInvalidateFn}
      uniqComparator={uniqComparator}
      successFn={() =>
        message.success(
          `Successfully updated ${terms.participants.toLowerCase()}.`,
        )
      }
      errorFn={() =>
        message.error(
          `There was an error updating the ${terms.participants.toLowerCase()}.`,
        )
      }
    />
  );
};
