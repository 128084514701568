// Returns a mutation for creating a single Cycle associated with each of the
// provided Class[] `classes`. Does NOT use optimisitc update because we don't
// know the exact details of the cycles the server will create.

import { useMutation, useQueryClient } from 'react-query';
import { message } from 'antd';
import { Class, Cycle, cycleCreateNew, queryKeyCyclesByGroup } from 'models';

export const useCyclesCreateByClasses = () => {
  const queryClient = useQueryClient();

  type MutationArgs = { classes: Class[]; groupId: string };

  return useMutation<Cycle[], Error, MutationArgs, unknown>(
    async ({ classes, groupId }) => {
      const newCycles = await Promise.all(
        classes.map((cls) => cycleCreateNew(cls.uid)),
      );

      queryClient.invalidateQueries(queryKeyCyclesByGroup(groupId));

      return newCycles;
    },
    {
      onSuccess: () => {
        message.success(`Successfully created a new cycle.`);
      },
      onError: () => {
        message.error(`Unable to create new cycle.`);
      },
    },
  );
};
