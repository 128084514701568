// https://docs.google.com/spreadsheets/d/1ZX4hcUol-_frFLfUYRnZ-UPEvpGsVOsWsJRw1g5tDBM

import keyBy from 'lodash/keyBy';

import { CompositeMetricConfig, MetricItemConfig } from './types';
import { catalyze23AnswerOptions } from './answerOptions';

export const transformativeEquity23ItemConfig: MetricItemConfig[] = [
  {
    label: 'transformative_equity_conversations',
    likertN: 5,
    minGood: 4,
    options: catalyze23AnswerOptions,
    prompts: {
      en: 'People have conversations about equity (e.g. race, gender identity, SES), even when they are difficult.',
    },
  },
  {
    label: 'transformative_equity_disrupt',
    likertN: 5,
    minGood: 4,
    options: catalyze23AnswerOptions,
    prompts: {
      en: 'People actively work to disrupt inequitable systems and structures.',
    },
  },
  {
    label: 'transformative_equity_biases',
    likertN: 5,
    minGood: 4,
    options: catalyze23AnswerOptions,
    prompts: {
      en: 'People encourage one another to acknowledge and challenge their biases.',
    },
  },
  {
    label: 'transformative_equity_included',
    likertN: 5,
    minGood: 4,
    options: catalyze23AnswerOptions,
    prompts: {
      en: 'All the communities we serve are included in decision-making.',
    },
  },
  {
    label: 'transformative_equity_pl',
    likertN: 5,
    minGood: 4,
    options: catalyze23AnswerOptions,
    prompts: {
      en: 'Professional learning promotes a shared language and understanding around the concept of equity.',
    },
  },
];

export const transformativeEquity23Config: CompositeMetricConfig = {
  label: 'transformative-equity',
  items: transformativeEquity23ItemConfig,
  itemIndex: keyBy(transformativeEquity23ItemConfig, 'label'),
};
