import { useContext } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useQueryClient, useMutation } from 'react-query';
import { message } from 'antd';

import {
  groupsAddToProgram,
  useProgramGetByParams,
  queryKeyGroups,
  Group,
  useGroupGetById,
} from 'models';
import { toGroupMembers, toHome, PagesParams } from 'pages';
import { useCloseModal } from 'utils';

import { GroupsCopyForm, GroupCopyParams } from './GroupsCopyForm';
import Loading from 'components/Loading';
import TermsContext from 'components/TermsContext';
import { ErrorMessageBox } from 'components/ErrorMessageBox';
import { getMessageFromErrors } from '@perts/util';

type LocationState = {
  state: {
    groupId?: string;
  };
};

export const GroupsCopy = () => {
  const { programId = '' } = useParams<PagesParams>();
  const history = useHistory();
  const closeModal = useCloseModal({
    pathname: toHome(programId),
    ignoreEmptyState: false,
  });
  const location: LocationState = useLocation();
  const groupId = location?.state?.groupId || '';

  const queryClient = useQueryClient();
  const terms = useContext(TermsContext);
  const {
    isLoading: groupIsLoading,
    isSuccess: groupIsSuccess,
    data: group,
    error: groupError,
  } = useGroupGetById(groupId);

  // Query for Programs.
  const {
    isLoading: programIsLoading,
    isSuccess: programIsSuccess,
    data: program,
    error: programError,
  } = useProgramGetByParams();

  // Mutation: Add group to program.
  // https://react-query.tanstack.com/guides/mutations
  const mutation = useMutation(
    ({ name, default_settings }: GroupCopyParams) => {
      if (!program) {
        throw new Error(
          `Program required to copy ${terms.group.toLowerCase()}.`,
        );
      }

      return groupsAddToProgram(name, program.uid, default_settings);
    },
    {
      // Handle successful mutation.
      onSuccess: (data) => {
        message.success(
          `Successfully copied the ${terms.group.toLowerCase()}.`,
        );

        const previousValue =
          queryClient.getQueryData<Group[]>(queryKeyGroups()) || [];

        const updatedValue = [data, ...previousValue].sort((a, b) =>
          a.name > b.name ? 1 : -1,
        );

        queryClient.setQueryData(queryKeyGroups(), updatedValue);

        history.push(toGroupMembers(data.short_uid));
      },
    },
  );

  // Display loading when users data is loading.
  if (programIsLoading || groupIsLoading) {
    return <Loading />;
  }

  // TODO DRY https://github.com/PERTS/perts/issues/61
  // Display any errors.
  if (!programIsSuccess || !groupIsSuccess || !program || !group) {
    return (
      <ErrorMessageBox>
        {getMessageFromErrors([programError, groupError])}
      </ErrorMessageBox>
    );
  }

  // https://stackoverflow.com/questions/65760158/react-query-mutation-typescript
  // Formik onSubmit handler
  const onSubmit = async (values: GroupCopyParams) => {
    await mutation.mutateAsync(values);
  };

  return (
    <GroupsCopyForm close={closeModal} onSubmit={onSubmit} group={group} />
  );
};
