import React from 'react';
import styled from 'styled-components/macro';
import { css } from 'styled-components';

import theme from '../theme';
import { Menu } from '@headlessui/react';

type Display =
  | 'block'
  | 'inline'
  | 'inline-block'
  | 'flex'
  | 'inline-flex'
  | 'none'
  | 'inherit';

type DropdownItemProps = {
  children: React.ReactNode;
  onClick?: (e: any) => void;
  disabled?: boolean;
  display?: Display;
  displayLg?: Display;
  displayMd?: Display;
  displaySm?: Display;
};

type MenuOptionProps = {
  disabled: boolean;
  active: boolean;
  theme: any;
  display?: Display;
  displayLg?: Display;
  displayMd?: Display;
  displaySm?: Display;
};

const MenuOption = styled.button<MenuOptionProps>`
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  text-align: left;
  border: 0;
  width: 100%;
  cursor: pointer;
  background-color: ${(props) => props.theme.colors.white};
  color: ${(props) => props.theme.colors.text};
  padding: ${(props) => props.theme.units.paddingSm}
    ${(props) => props.theme.units.paddingMd};
  svg path {
    stroke: ${(props) => props.theme.colors.text};
  }

  a {
    color: ${(props) => props.theme.colors.text};
    text-decoration: none;
    font-weight: inherit;
  }

  ${(props) =>
    props.disabled &&
    css`
      background-color: ${props.theme.colors.white};
      color: ${props.theme.colors.grayLight};
      cursor: not-allowed;
    `};

  ${(props) =>
    props.active &&
    css`
      background-color: ${props.theme.colors.primary};
      color: ${props.theme.colors.white};
      svg path {
        stroke: ${props.theme.colors.white};
      }
      a {
        color: ${props.theme.colors.white};
        text-decoration: none;
        font-weight: inherit;

        :focus,
        :hover {
          color: ${props.theme.colors.white};
          font-weight: inherit;
          text-decoration: none;
        }
      }
    `};

  & > *:first-child {
    margin-right: 5px;
  }

  ${({ display }) =>
    display &&
    css`
      display: ${display};
    `};

  @media only screen and (max-width: ${theme.units.gridBreakpointLarge}px) {
    ${({ displayLg }) =>
      displayLg &&
      css`
        display: ${displayLg};
      `};
  }

  @media only screen and (max-width: ${theme.units.gridBreakpointMedium}px) {
    ${({ displayMd }) =>
      displayMd &&
      css`
        display: ${displayMd};
      `};
  }

  @media only screen and (max-width: ${theme.units.gridBreakpointSmall}px) {
    ${({ displaySm }) =>
      displaySm &&
      css`
        display: ${displaySm};
      `};
  }
`;

MenuOption.defaultProps = {
  theme,
};

export const DropdownItem = ({ children, ...rest }: DropdownItemProps) => (
  <Menu.Item {...rest}>
    {(menuItemsProps) => (
      <MenuOption {...menuItemsProps}>{children}</MenuOption>
    )}
  </Menu.Item>
);
