import { oneAndOnlyOne } from '@perts/util';
import { Show } from '../..';
import { linkProps } from '../shared';
import { StyledButton } from './StyledButton';
import { ButtonContent } from './ButtonContent';
import ButtonLoadingIndicator from './ButtonLoadingIndicator';
import { IconLeftContainer } from './IconLeftContainer';
import { IconRightContainer } from './IconRightContainer';

export type ButtonProps = {
  /**
   * The aria-label attribute is used to define a string that labels the current
   * element. Use it in cases where a text label is not visible on the screen.
   * https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Attributes/aria-label
   */
  'aria-label'?: string;

  /**
   * The content of the Button.
   */
  children: React.ReactNode;

  /**
   * To allow styled-component wrapping.
   */
  className?: string;

  /**
   * The color of the button. Default 'primary'.
   */
  color?:
    | 'primary'
    | 'secondary'
    | 'success'
    | 'warning'
    | 'text'
    | 'warning-secondary';

  /**
   * For test querying, when no better way to access the element exists.
   * https://testing-library.com/docs/queries/about/#priority
   */
  'data-testid'?: string;

  /**
   * If true, the button is disabled.
   */
  disabled?: boolean;

  /**
   * If present, indicates that the author intends the hyperlink to be used for
   * downloading a resource vs displaying in the browser.
   * https://html.spec.whatwg.org/multipage/links.html#attr-hyperlink-download
   */
  download?: string;

  /**
   * If true, the button will take up the full width of its container;
   */
  fullWidth?: boolean;

  /**
   * If true, the button will take up the full width of its container;
   */
  fullHeight?: boolean;

  /**
   * Optional, button icon which appears left of the label text.
   * Example: <Button iconLeft={<TiChevronLeft />}>
   */
  iconLeft?: React.ReactNode;

  /**
   * Optional, button icon which appears right of the label text.
   * Example: <Button iconRight={<TiChevronRight />}>
   */
  iconRight?: React.ReactNode;

  /**
   * If true, the button is in the loading state.
   */
  loading?: boolean;

  /**
   * onClick event handler.
   */
  onClick?: (event: React.FormEvent<HTMLButtonElement>) => void;

  /**
   * The HTML anchor/React Router's `target`.
   */
  target?: string;

  /**
   * https://developer.mozilla.org/en-US/docs/Web/HTML/Global_attributes/tabindex
   */
  tabIndex?: string;

  /**
   * React Router Link's `to` or <a> `href`.
   * https://v5.reactrouter.com/web/api/Link
   * https://developer.mozilla.org/en-US/docs/Web/HTML/Element/a#attr-href
   */
  to?: string;

  /**
   * The ID of the Freshworks article to link to.
   */
  articleId?: string;

  /**
   * The button type. Default 'button'.
   */
  type?: 'submit' | 'button';
};

export const Button = (props: ButtonProps) => {
  const {
    children,
    color = 'primary',
    disabled,
    fullWidth = false,
    fullHeight = false,
    iconLeft,
    iconRight,
    loading,
    onClick = undefined,
    to,
    articleId,
    type = 'button',
    ...rest
  } = props;
  const articleOnClick = () => {
    if (articleId) {
      const fw = (window as any).FreshworksWidget;
      fw('open', 'article', {
        id: articleId,
      });
    }
  };

  const handleOnClick = onClick || (articleId && articleOnClick) || undefined;

  const isLink = Boolean(to);
  const isButton = Boolean(onClick || articleId);
  const isFormSubmit = type === 'submit';

  if (!oneAndOnlyOne([isLink, isButton || isFormSubmit])) {
    throw new Error(
      'You must provide either `to`, `onClick`/`type="submit"`, or `articleId` to Button.',
    );
  }

  const isDisabled = disabled || loading;
  const showProvidedIconLeft = Boolean(iconLeft) && !loading;
  const showProvidedIconRight = Boolean(iconRight) && !loading;
  const showLoading = loading;

  return (
    <StyledButton
      disabled={isDisabled}
      fullWidth={fullWidth}
      fullHeight={fullHeight}
      type={type}
      color={color}
      {...rest}
      {...linkProps({ to, onClick: handleOnClick, type })}
      // Disable tab index if disabled.
      tabIndex={props.disabled ? '-1' : props.tabIndex}
    >
      <Show when={showProvidedIconLeft}>
        <IconLeftContainer>{iconLeft}</IconLeftContainer>
      </Show>

      <ButtonContent>{children}</ButtonContent>

      <Show when={showLoading}>
        <ButtonLoadingIndicator />
      </Show>

      <Show when={showProvidedIconRight}>
        <IconRightContainer>{iconRight}</IconRightContainer>
      </Show>
    </StyledButton>
  );
};
