// https://docs.google.com/spreadsheets/d/1ZX4hcUol-_frFLfUYRnZ-UPEvpGsVOsWsJRw1g5tDBM

import keyBy from 'lodash/keyBy';

import { locales } from '../i18n';
import { CompositeMetricConfig, MetricItemConfig } from './types';
import { catalyze24CompletelyTrueOptions } from './answerOptions/catalyze24CompletelyTrueOptions';

export const plc24TeacherTeacherTrustItemConfig: MetricItemConfig[] = [
  {
    label: 'plc_group_trusts',
    likertN: 4,
    minGood: 3,
    options: catalyze24CompletelyTrueOptions,
    prompts: {
      [locales.ENGLISH]: 'Teachers in this group trust each other.',
    },
  },
  {
    label: 'plc_discuss_feelings',
    likertN: 4,
    minGood: 3,
    options: catalyze24CompletelyTrueOptions,
    prompts: {
      [locales.ENGLISH]:
        'It’s OK in this group to discuss feelings, worries, and ' +
        'frustrations with other teachers.',
    },
  },
  {
    label: 'plc_feel_respected',
    likertN: 4,
    minGood: 3,
    options: catalyze24CompletelyTrueOptions,
    prompts: {
      [locales.ENGLISH]:
        'I feel respected by the other teachers in this group.',
    },
  },
  {
    label: 'plc_group_cares',
    likertN: 4,
    minGood: 3,
    options: catalyze24CompletelyTrueOptions,
    prompts: {
      [locales.ENGLISH]: 'Teachers in this group really care about each other.',
    },
  },
];

export const plc24TeacherTeacherTrustConfig: CompositeMetricConfig = {
  label: 'plc-teacher-teacher-trust',
  items: plc24TeacherTeacherTrustItemConfig,
  itemIndex: keyBy(plc24TeacherTeacherTrustItemConfig, 'label'),
  name: 'Group Norms: Teacher-Teacher Trust',
  description:
    'Trust between teachers creates a safe and supportive environment for ' +
    'open dialogue, risk-taking, and shared vulnerability, which are ' +
    'essential for genuine collaboration and collective professional growth.',
};
