import { Col, Row, SelectFilter, SelectFilterSelected } from '@perts/ui';
import { EquityGapIcon } from 'pages/Reports/components';
import { ApplyToAllStyledButton } from '../ApplyToAllStyledButton';
import { getOptionsSelectFilterMulti } from './getOptions';

type ShowBy = 'metricOnly' | 'metricOnlyAndQuestions';

type SelectFilterMultiProps = {
  programLabel: string;

  /**
   * "Show by..." either composite metric only `metricOnly`, or include
   * questions/items `metricOnlyAndQuestions`.
   */
  showBy: string;
  setShowBy: (value: ShowBy) => void;

  /**
   * "Disaggregate by..."
   */
  disaggregateBy: string;
  setDisaggregateBy: (value: string) => void;
  applyToAll?: () => void;

  questionsHaveGaps?: boolean;
  attrLabelsWithGapsCombined?: string[];
  attrLabelsWithGapsMetricOnly?: string[];
};

const isOptionSelected = (
  option: any,
  showBy: string,
  disaggregateBy: string,
) => {
  const [optionShowBy, optionDisaggregateBy] = option.value;
  return showBy === optionShowBy && disaggregateBy === optionDisaggregateBy;
};

export const SelectFilterMulti = (props: SelectFilterMultiProps) => {
  const {
    programLabel,
    showBy,
    setShowBy,
    disaggregateBy,
    setDisaggregateBy,
    applyToAll,
    questionsHaveGaps = false,
    attrLabelsWithGapsCombined = [],
    attrLabelsWithGapsMetricOnly = [],
  } = props;
  const options = getOptionsSelectFilterMulti(
    programLabel,
    attrLabelsWithGapsCombined,
    attrLabelsWithGapsMetricOnly,
  );

  const setSelected = (selected: SelectFilterSelected) => {
    if (!selected || Array.isArray(selected)) {
      // SelectFilter supports types that aren't used here.
      return;
    }

    const [selectedShowBy, selectedDisaggregateBy] = selected.value;
    setShowBy(selectedShowBy);
    setDisaggregateBy(selectedDisaggregateBy);
  };

  const filterSelected =
    options.find((option) =>
      isOptionSelected(option, showBy, disaggregateBy),
    ) || options[0];

  return (
    <Row>
      <Col shrink>
        <SelectFilter
          label={
            <>
              Disaggregate By
              {questionsHaveGaps && <EquityGapIcon />}
            </>
          }
          options={options}
          selected={filterSelected}
          setSelected={setSelected}
        />
      </Col>
      {applyToAll && (
        <Col>
          <ApplyToAllStyledButton noPaddingOnContainer onClick={applyToAll} />
        </Col>
      )}
    </Row>
  );
};
