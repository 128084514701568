import { useMemo } from 'react';
import { useTerms } from 'components';
import { useSortBy } from './useSortBy';
import {
  IconCleverEnabled,
  IconEnvelopeEnabled,
  IconFromTo,
  IconGoogleEnabled,
  IconIdBadgeEnabled,
} from './components';

export const useSortByClasses = () => {
  const terms = useTerms();

  const sortByOptions = useMemo(
    () => [
      {
        value: 'name',
        name: `${terms.class} Name`,
        sortByDirectionOptions: [
          {
            name: (
              <>
                A <IconFromTo /> Z
              </>
            ),
            value: 'name:asc',
          },
          {
            name: (
              <>
                Z <IconFromTo /> A
              </>
            ),
            value: 'name:desc',
          },
        ],
      },
      {
        value: 'portal_type',
        name: 'Sign-On Type',
        sortByDirectionOptions: [
          {
            name: (
              <>
                <IconEnvelopeEnabled /> <div /> <div>First</div>
              </>
            ),
            value: 'portal_type_email:desc',
          },
          {
            name: (
              <>
                <IconGoogleEnabled /> <div /> <div>First</div>
              </>
            ),
            value: 'portal_type_google:desc',
          },
          {
            name: (
              <>
                <IconIdBadgeEnabled /> <div /> <div>First</div>
              </>
            ),
            value: 'portal_type_studentid:desc',
          },
          {
            name: (
              <>
                <IconCleverEnabled /> <div /> <div>First</div>
              </>
            ),
            value: 'portal_type_clever:desc',
          },
        ],
      },
      {
        value: 'participant_ending',
        name: 'Sign-On Rule',
        sortByDirectionOptions: [
          {
            name: (
              <>
                Yes <IconFromTo /> No
              </>
            ),
            value: 'isSignOnRule:desc',
          },
          {
            name: (
              <>
                No <IconFromTo /> Yes
              </>
            ),
            value: 'isSignOnRule:no',
          },
        ],
      },
      {
        value: 'roster_locked',
        name: 'Roster Locked',
        sortByDirectionOptions: [
          {
            name: (
              <>
                Yes <IconFromTo /> No
              </>
            ),
            value: 'roster_locked:desc',
          },
          {
            name: (
              <>
                No <IconFromTo /> Yes
              </>
            ),
            value: 'roster_locked:no',
          },
        ],
      },

      {
        value: 'target_group_name',
        name: 'Focal Group',
        sortByDirectionOptions: [
          {
            name: (
              <>
                Yes <IconFromTo /> No
              </>
            ),
            value: 'isTargetGroup:desc',
          },
          {
            name: (
              <>
                No <IconFromTo /> Yes
              </>
            ),
            value: 'isTargetGroup:no',
          },
        ],
      },
      {
        value: 'num_students',
        name: `# of ${terms.participants}`,
        sortByDirectionOptions: [
          {
            name: (
              <>
                Low <IconFromTo /> High
              </>
            ),
            value: 'num_students:asc',
          },
          {
            name: (
              <>
                High <IconFromTo /> Low
              </>
            ),
            value: 'num_students:desc',
          },
        ],
      },
    ],
    // Note: It's important to specify the terms.participants over simply terms
    // because the terms object changes often.
    [terms.class, terms.participants],
  );

  return useSortBy(sortByOptions);
};
