import { omit } from 'lodash';

type PaginationParamsClient = {
  pageSize?: number;
  pageIndex?: number;
  order?: string;
};

// Transforms the pagination related parameters from client to server.

export const paginationParamsToServer = (
  paramsClient: PaginationParamsClient = {},
) => ({
  ...omit(paramsClient, ['pageSize', 'pageIndex']),
  n: paramsClient.pageSize || undefined,
  cursor:
    paramsClient.pageSize !== undefined && paramsClient.pageIndex !== undefined
      ? paramsClient.pageSize * paramsClient.pageIndex
      : undefined,
});
