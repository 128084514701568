import { Class } from 'models';
import { getRule } from './getRule';
import { splitMinMax } from './splitMinMax';

export function getRulesFromClasses(classes: Class[]) {
  const [{ participant_ending, participant_pattern }] = classes;

  const allEqualParticipantPattern = classes.every(
    (cls) => cls.participant_pattern === participant_pattern,
  );

  const _studentEmailEnding = getRule(participant_pattern, participant_ending);
  const [_studentIdMin, _studentIdMax] = splitMinMax(participant_ending);

  if (!allEqualParticipantPattern) {
    return {
      studentEmailEnding: undefined,
      studentIdMin: undefined,
      studentIdMax: undefined,
    };
  }

  return {
    studentEmailEnding: _studentEmailEnding,
    studentIdMin: _studentIdMin,
    studentIdMax: _studentIdMax,
  };
}
