import { Redirect, Route, Switch } from 'react-router-dom';

import AuthenticatedRoute from 'components/AuthenticatedRoute';

// import Login, { LoginMaintenance } from 'scenes/Login'; // for maintenance
import Login from 'scenes/Login';
import Logout from 'scenes/Logout';

import {
  HomeNoProgram,
  HomeRouter,
  PagesTemplate,
  Search,
  UserAccount,
  toArchive,
  toGroup,
  toHome,
  toHomeNoProgram,
  toHomeSearch,
  toNetwork,
  toUser,
} from 'pages';
import * as routes from 'routes';
import { PagesRouterGroup } from './PagesRouterGroup';
import { PagesRouterNetwork } from './PagesRouterNetwork';
import { PagesRouterArchive } from './PagesRouterArchive';
import { NetworkTest } from './NetworkTest';
import { CanvasRedirect } from './CanvasRedirect';

export const PagesRouter = ({ userIsLoggedIn }) => (
  <Switch>
    {/* Groups */}

    <AuthenticatedRoute authenticated={userIsLoggedIn} path={toGroup()}>
      <PagesTemplate>
        <PagesRouterGroup />
      </PagesTemplate>
    </AuthenticatedRoute>

    {/* Networks */}

    <AuthenticatedRoute authenticated={userIsLoggedIn} path={toNetwork()}>
      <PagesTemplate>
        <PagesRouterNetwork />
      </PagesTemplate>
    </AuthenticatedRoute>

    {/* Home */}

    <AuthenticatedRoute authenticated={userIsLoggedIn} path={toHomeSearch()}>
      <PagesTemplate>
        <Search />
      </PagesTemplate>
    </AuthenticatedRoute>

    <AuthenticatedRoute authenticated={userIsLoggedIn} path={toHome()}>
      <PagesTemplate>
        <HomeRouter />
      </PagesTemplate>
    </AuthenticatedRoute>

    <AuthenticatedRoute authenticated={userIsLoggedIn} path={toHomeNoProgram()}>
      <PagesTemplate>
        <HomeNoProgram />
      </PagesTemplate>
    </AuthenticatedRoute>

    {/* Archive */}

    <AuthenticatedRoute authenticated={userIsLoggedIn} path={toArchive()}>
      <PagesTemplate>
        <PagesRouterArchive />
      </PagesTemplate>
    </AuthenticatedRoute>

    {/* User */}

    <AuthenticatedRoute authenticated={userIsLoggedIn} path={toUser()}>
      <PagesTemplate>
        <UserAccount />
      </PagesTemplate>
    </AuthenticatedRoute>

    {/* Network Test */}
    <Route path={routes.toNetworkTest()}>
      <NetworkTest />
    </Route>

    {/* Auth */}

    <Route path={routes.toLogout()} component={Logout} />

    {/* Maintenance Mode */}

    {/*
    <Route path={routes.toLogin()}>
      <LoginMaintenance />
    </Route>

    <Route path={routes.toSecretLogin()}>
      <Login />
    </Route>
    */}

    {/* Normal Mode */}

    <Route path={routes.toLogin()}>
      <Login />
    </Route>

    <Route path={routes.toCanvas()}>
      <CanvasRedirect />
    </Route>

    {/* Catch all route that redirects to /login */}

    <Route render={() => <Redirect to={routes.toLogin()} />} />
  </Switch>
);
