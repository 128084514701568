import { useContext } from 'react';

import TermsContext from 'components/TermsContext';
import Table from 'components/Table';
import { Card, InfoBox, Link, Text, Show } from '@perts/ui';
import { Class } from 'models';

export const SurveyInstructionsRender = ({ classes }: { classes: Class[] }) => {
  const terms = useContext(TermsContext);

  return (
    <>
      <Card>
        <Card.Title>
          <Text as="h2">Before the Survey</Text>
        </Card.Title>
        <Card.Content>
          <p>Consider strategies that maximize participation:</p>
          <ul>
            <li>
              Ask {terms.participants.toLowerCase()} to complete the survey{' '}
              <strong>during class time</strong>.
            </li>
            <li>
              Add the survey invitation to your{' '}
              <strong>learning management system (LMS)</strong>.
            </li>
            <li>
              Offer <strong>extra credit</strong> to individuals who complete
              the survey or to the whole class if they reach 80% completion.
            </li>
          </ul>
        </Card.Content>
      </Card>

      <Card>
        <Card.Title>
          <Text as="h2">Survey Instructions</Text>
        </Card.Title>
        <Card.Content>
          <p>
            You can use the template below to invite{' '}
            {terms.participants.toLowerCase()} to the survey. Feel free to adapt
            it to your voice, but keep in mind these{' '}
            <Link to="https://perts.net/communication">recommendations</Link> to
            maximize participation and honesty in responses.
          </p>
          <InfoBox textAlign="left">
            <div>
              <p>Hi Everyone,</p>
              <p>
                I&rsquo;m inviting you to complete a short,{' '}
                <strong>confidential survey</strong> because I need your
                feedback. My goal is to improve our learning environment and
                make this class a better place for you to learn. Your honest
                feedback is very important to me because I can&rsquo;t improve
                without it, and I won&rsquo;t see your name in connection to
                your answers. I&rsquo;ll ask for feedback multiple times so that
                I can keep track of what&rsquo;s working for you, and what
                isn&rsquo;t.
              </p>
              <p>
                To start the 5-minute, confidential survey, click the link next
                to your {terms.class.toLowerCase()} and follow the prompts.
              </p>
              <p>
                If you have trouble with the link, go to{' '}
                <Link to="https://perts.me">perts.me</Link> and enter your
                participation code manually.
              </p>

              <Table>
                <thead>
                  <tr>
                    <th>Classes</th>
                    <th>Participation Code</th>
                  </tr>
                </thead>
                <tbody>
                  <Show when={classes.length === 0}>
                    <tr>
                      <td colSpan={2}>
                        <em>
                          You don&rsquo;t have any {terms.classes.toLowerCase()}{' '}
                          yet.
                        </em>
                      </td>
                    </tr>
                  </Show>
                  <Show when={classes.length > 0}>
                    {classes.map((cls) => (
                      <tr key={cls.uid}>
                        <td>{cls.name}</td>
                        <td>
                          <Link to={`https://perts.me/${cls.code}`}>
                            {cls.code}
                          </Link>
                        </td>
                      </tr>
                    ))}
                  </Show>
                </tbody>
              </Table>
            </div>
          </InfoBox>
        </Card.Content>
      </Card>

      <Card>
        <Card.Title>
          <Text as="h2">After the Survey</Text>
        </Card.Title>
        <Card.Content>
          <ol>
            <li>
              Remind {terms.participants.toLowerCase()} who missed taking the
              survey that they can complete a makeup at any time during the
              survey window.
              {/*
                We used to display this link to help people find the detailed
                progress view. Skipping it for now to save time.
                TODO: restore this.
              */}
              {/*
              <Show when={toCycleProgress}>
                {' '}
                View <Link to={toCycleProgress}>Detailed Survey Progress</Link>.
              </Show>
            */}
            </li>
            <li>
              A new report is generated every Monday if new data were collected
              during the previous week. Only the{' '}
              {terms.classManager.toLowerCase()}(s) can see the report for that{' '}
              {terms.class.toLowerCase()}.
            </li>
            <li>
              Debrief the results: Be transparent about what you are doing well
              and what you are trying to improve.
            </li>
          </ol>
        </Card.Content>
      </Card>
    </>
  );
};
