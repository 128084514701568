import { Route, matchPath } from 'react-router-dom';
import { IconArchive, Menu } from '@perts/ui';
import { toParams } from '@perts/util';
import { useProgramId, useProgramsArchived } from 'models';
import {
  toArchive,
  toArchiveClassReport,
  toArchiveGroup,
  toArchiveGroupReport,
  toArchiveNetworkReport,
  toArchiveProgram,
  toHome,
  toHomeNoProgram,
} from 'pages';

const isActive = (currentPath: string, routes: string[]) =>
  routes.some((route) => matchPath(currentPath, { path: route }));

const ProgramMenuArchiveItem = () => {
  const programId = useProgramId();
  const { data: archivedPrograms } = useProgramsArchived();

  // Only link to archived programs.
  const isArchivedProgram =
    programId && archivedPrograms?.find((p) => p.uid === programId);

  const archiveRoutes = [
    toArchiveClassReport(),
    toArchiveGroupReport(),
    toArchiveNetworkReport(),
    toArchiveGroup(),
    toArchiveProgram(),
  ];

  return isArchivedProgram ? (
    <Menu.Item
      icon={<IconArchive />}
      to={toArchiveProgram(toParams(programId))}
      isActive={(_, location) =>
        // Provide a custom isActive since routes don't match `to` route.
        isActive(location.pathname, archiveRoutes)
      }
    >
      Archive
    </Menu.Item>
  ) : (
    <Menu.Item icon={<IconArchive />} to={toArchive()}>
      Archive
    </Menu.Item>
  );
};

export const ProgramMenuArchive = () => (
  <Route
    path={[
      toArchiveClassReport(),
      toArchiveGroupReport(),
      toArchiveNetworkReport(),
      toArchiveGroup(),
      toArchiveProgram(),
      toArchive(),
      toHome(),
      toHomeNoProgram(),
    ]}
  >
    <ProgramMenuArchiveItem />
  </Route>
);
