import styled, { css } from 'styled-components/macro';
import { theme } from '../..';

type StyledButtonProps = {
  /**
   * The text of the link.
   */
  children: React.ReactNode;

  /**
   * The color of the button. Default `primary`.
   */
  color?:
    | 'primary'
    | 'secondary'
    | 'success'
    | 'warning'
    | 'text'
    | 'warning-secondary';

  /**
   * If `true`, the button is disabled.
   */
  disabled?: boolean;

  /**
   * If `true`, the button will take up the full width of its container;
   */
  fullWidth?: boolean;

  /**
   * If `true`, the button will take up the full width of its container;
   */
  fullHeight?: boolean;

  /**
   * Theme config object.
   */
  theme: { [key: string]: any };
};

const shouldForwardProp = (prop: string, defaultValidatorFn: Function) =>
  !['fullWidth', 'fullHeight'].includes(prop) && defaultValidatorFn(prop);

export const StyledButton = styled('button').withConfig({
  shouldForwardProp,
})<StyledButtonProps>`
  box-sizing: border-box;
  position: relative;
  overflow: hidden;

  display: inline-flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;
  font-family: 'Open Sans', sans-serif;
  text-decoration: none;

  max-width: 100%;

  ${(props: StyledButtonProps) =>
    props.fullWidth &&
    css`
      width: 100%;
    `};

  ${(props: StyledButtonProps) =>
    props.fullHeight &&
    css`
      height: 100%;
    `};

  min-height: ${(props: StyledButtonProps) => props.theme.units.buttonHeight};

  padding: 0 ${(props: StyledButtonProps) => props.theme.units.buttonPadding};

  font-size: 1.125rem;
  font-weight: 600;

  @media only screen and (max-width: ${(props: StyledButtonProps) =>
      props.theme.units.gridBreakpointMedium}px),
    only screen and (max-height: ${(props: StyledButtonProps) =>
      props.theme.units.gridBreakpointMedium}px) {
    padding: 0
      ${(props: StyledButtonProps) => props.theme.units.buttonPaddingMd};
  }

  @media only screen and (max-width: ${(props: StyledButtonProps) =>
      props.theme.units.gridBreakpointSmall}px),
    only screen and (max-height: ${(props: StyledButtonProps) =>
      props.theme.units.gridBreakpointSmall}px) {
    font-size: 1rem;
    padding: 0
      ${(props: StyledButtonProps) => props.theme.units.buttonPaddingSm};
  }

  cursor: pointer;

  color: ${(props: StyledButtonProps) => props.theme.colors.white};
  background-color: ${(props: StyledButtonProps) => props.theme.colors.primary};
  border: 0;

  border-radius: ${(props: StyledButtonProps) =>
    props.theme.units.borderRadius};

  svg path {
    stroke: ${(props: StyledButtonProps) => props.theme.colors.white};
  }

  &:focus {
    outline: 0;
    box-shadow: 0 0 0 4px
      ${(props: StyledButtonProps) => props.theme.colors.primaryLight};
  }

  &:hover {
    color: ${(props: StyledButtonProps) => props.theme.colors.white};
    background-color: ${(props: StyledButtonProps) =>
      props.theme.colors.buttonBgDarkBlueOnHover};
  }

  &:active {
    color: ${(props: StyledButtonProps) => props.theme.colors.white};
    background-color: ${(props: StyledButtonProps) =>
      props.theme.colors.buttonBgDarkBlueOnActive};
  }

  ${(props: StyledButtonProps) =>
    props.disabled &&
    css`
      cursor: not-allowed;

      > * {
        /* additional specifity to override antd's a:disabled styles */
        color: ${props.theme.colors.white};
      }
      background-color: ${props.theme.colors.disabled};

      /* icons */
      svg path {
        stroke: ${props.theme.colors.white};
      }

      &:focus {
        outline: 0;
        box-shadow: none;
      }

      &:hover {
        color: ${props.theme.colors.white};
        background-color: ${props.theme.colors.disabled};
      }

      &:active {
        color: ${props.theme.colors.white};
        background-color: ${props.theme.colors.disabled};
      }
    `};

  ${(props: StyledButtonProps) =>
    props.color === 'secondary' &&
    css`
      color: ${props.theme.colors.primary};
      background-color: ${props.theme.colors.white};
      border: 1px solid ${props.theme.colors.primary};

      /* icons */
      svg path {
        stroke: ${props.theme.colors.primary};
      }

      &:focus {
        outline: 0;
        box-shadow: 0 0 0 4px ${props.theme.colors.primaryLight};
      }

      &:hover {
        color: ${props.theme.colors.buttonBgDarkBlueOnHover};
        background-color: ${props.theme.colors.white};
        border: 1px solid ${props.theme.colors.buttonBgDarkBlueOnHover};
      }

      &:active {
        color: ${props.theme.colors.buttonBgDarkBlueOnActive};
        background-color: ${props.theme.colors.white};
        border: 1px solid ${props.theme.colors.buttonBgDarkBlueOnActive};
      }
    `};

  ${(props: StyledButtonProps) =>
    props.color === 'secondary' &&
    props.disabled &&
    css`
      > * {
        /* additional specifity to override antd's a:disabled styles */
        color: ${props.theme.colors.textColorLightGrayOnWhite};
      }
      background-color: ${props.theme.colors.white};
      border: 1px solid ${props.theme.colors.textColorLightGrayOnWhite};

      /* icons */
      svg path {
        stroke: ${props.theme.colors.textColorLightGrayOnWhite};
      }

      &:focus {
        outline: 0;
        box-shadow: none;
      }

      &:hover {
        color: ${props.theme.colors.textColorLightGrayOnWhite};
        background-color: ${props.theme.colors.white};
        border: 1px solid ${props.theme.colors.textColorLightGrayOnWhite};
      }

      &:active {
        color: ${props.theme.colors.textColorLightGrayOnWhite};
        background-color: ${props.theme.colors.white};
        border: 1px solid ${props.theme.colors.textColorLightGrayOnWhite};
      }
    `};

  ${(props: StyledButtonProps) =>
    props.color === 'warning' &&
    css`
      color: ${props.theme.colors.white};
      background-color: ${props.theme.colors.warning};

      /* icons */
      svg path {
        stroke: ${props.theme.colors.white};
      }

      &:focus {
        outline: 0;
        box-shadow: 0 0 0 4px ${props.theme.colors.boxShadowLightRedOnFocus};
      }

      &:hover {
        background-color: ${props.theme.colors.buttonBgDarkRedOnHover};
      }

      &:active {
        background-color: ${props.theme.colors.buttonBgDarkRedOnActive};
      }
    `};

  ${(props: StyledButtonProps) =>
    props.color === 'text' &&
    css`
      color: ${props.theme.colors.text};
      svg path {
        stroke: ${props.theme.colors.text};
      }
      background-color: ${props.theme.colors.white};
      border: 1px solid ${props.theme.colors.text};

      &:hover {
        color: ${props.theme.colors.text};
        border: 1px solid ${props.theme.colors.text};
        background-color: ${props.theme.colors.white};
      }

      &:active {
        color: ${props.theme.colors.text};
        border: 1px solid ${props.theme.colors.text};
        background-color: ${props.theme.colors.white};
      }
    `};

  ${(props: StyledButtonProps) =>
    props.color === 'text' &&
    props.disabled &&
    css`
      > * {
        /* additional specifity to override antd's a:disabled styles */
        color: ${props.theme.colors.text};
      }
      background-color: ${props.theme.colors.white};
      border: 1px solid ${props.theme.colors.text};

      /* icons */
      svg path {
        stroke: ${props.theme.colors.text};
      }

      &:focus {
        outline: 0;
        box-shadow: none;
      }

      &:hover {
        color: ${props.theme.colors.text};
        background-color: ${props.theme.colors.white};
        border: 1px solid ${props.theme.colors.text};
      }

      &:active {
        color: ${props.theme.colors.text};
        background-color: ${props.theme.colors.white};
        border: 1px solid ${props.theme.colors.text};
      }
    `};

  ${(props: StyledButtonProps) =>
    props.color === 'warning-secondary' &&
    css`
      color: ${props.disabled
        ? props.theme.colors.textColorLightGrayOnWhite
        : props.theme.colors.warning};
      svg path {
        stroke: ${props.disabled
          ? props.theme.colors.textColorLightGrayOnWhite
          : props.theme.colors.warning};
      }
      background-color: ${props.theme.colors.white};
      border: ${props.disabled
        ? `1px solid ${props.theme.colors.textColorLightGrayOnWhite}`
        : `1px solid ${props.theme.colors.warning}`};

      &:hover {
        color: ${props.theme.colors.buttonBgDarkRedOnHover};
        border: 1px solid ${props.theme.colors.buttonBgDarkRedOnHover};
        background-color: ${props.theme.colors.white};
      }

      &:active {
        color: ${props.theme.colors.buttonBgDarkRedOnActive};
        border: 1px solid ${props.theme.colors.buttonBgDarkRedOnActive};
        background-color: ${props.theme.colors.white};
      }

      &:focus {
        outline: 0;
        box-shadow: 0 0 0 4px ${props.theme.colors.boxShadowLightRedOnFocus};
      }
    `};
`;

StyledButton.displayName = 'StyledButton';

StyledButton.defaultProps = {
  theme,
};
