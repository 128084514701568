import * as Yup from 'yup';
import { Formik, Form } from 'formik';

import {
  Col,
  HelpText,
  InfoBox,
  Row,
  Show,
  Text,
  useFormError,
} from '@perts/ui';
import { helpArticles } from 'config';
import { DefaultSettingsWarning, FormButtons, useTerms } from 'components';
import { ClassSettingsFormValues } from 'pages/Classes/shared';
import { RosterSignOnTargetGroupFields } from './RosterTargetGroupFields';

const RosterTargetGroupHelpText = () => (
  <Col shrink vAlign="center" hAlign="flex-end">
    <HelpText articleId={helpArticles.whatIsTargetGroup}>
      What is a focal group?
    </HelpText>
  </Col>
);

export type RosterTargetGroupFormValues = Pick<
  ClassSettingsFormValues,
  'targetGroupEnabled' | 'targetGroupName'
>;

type Props = {
  close?: () => void;
  submitButtonText: string;
  onSubmit: (values: RosterTargetGroupFormValues) => void;
  initialValues: RosterTargetGroupFormValues;
  defaultValues: RosterTargetGroupFormValues | null;
  someEmptyTargetGroups?: boolean;
};

const ValidationSchema = Yup.object().shape({
  targetGroupEnabled: Yup.boolean(),
  targetGroupName: Yup.string().when('targetGroupEnabled', {
    is: true,
    then: (schema) => schema.required('Required'),
  }),
});

export const RosterTargetGroupForm = ({
  close,
  submitButtonText,
  onSubmit,
  initialValues,
  defaultValues,
  someEmptyTargetGroups = false,
}: Props) => {
  const terms = useTerms();
  const [FormError, showFormError] = useFormError();

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={ValidationSchema}
      enableReinitialize={true}
      onSubmit={async (values) => {
        try {
          // Clear existing form error.
          showFormError(false);

          // Perform form onSubmit.
          await onSubmit(values);

          // Close modal on successful form onSubmit.
          close && close();
        } catch (error) {
          // Display form error.
          showFormError(true);
        }
      }}
    >
      {({ isSubmitting, setFieldTouched, setValues, values }) => (
        <Form id="focal-group" data-testid="focal-group">
          <Row alignItems="center">
            <Col shrink vAlign="center" hAlign="center">
              <Text as="h2">
                <span>Focal Group</span>
              </Text>
            </Col>

            <Show when={!Boolean(close)}>
              <Col></Col>
              <RosterTargetGroupHelpText />
            </Show>
          </Row>

          <Row>
            <Col>
              <RosterSignOnTargetGroupFields
                isSubmitting={isSubmitting}
                setFieldTouched={setFieldTouched}
                setValues={setValues}
                values={values}
              />
            </Col>
          </Row>

          <Row>
            <Col>
              <FormError />
              <DefaultSettingsWarning
                hideWhenErrors={true}
                message={`This change doesn't match the ${terms.group}'s default settings.`}
                defaultValues={defaultValues}
              />
            </Col>
          </Row>

          <Show
            when={
              initialValues.targetGroupEnabled === null &&
              !someEmptyTargetGroups
            }
          >
            <Row>
              <Col>
                <InfoBox
                  color="warning"
                  data-testid="warning-different-focal-group"
                >
                  The selected classes have different focal group names
                </InfoBox>
              </Col>
            </Row>
          </Show>

          <Show
            when={
              initialValues.targetGroupEnabled === null && someEmptyTargetGroups
            }
          >
            <Row>
              <Col>
                <InfoBox
                  color="warning"
                  data-testid="warning-different-empty-focal-group"
                >
                  The selected classes have different or empty focal group names
                </InfoBox>
              </Col>
            </Row>
          </Show>

          <Show when={Boolean(close)}>
            <Row>
              <RosterTargetGroupHelpText />
            </Row>
          </Show>

          <FormButtons close={close} submitButtonText={submitButtonText} />
        </Form>
      )}
    </Formik>
  );
};
