import { Formik, Form } from 'formik';
import { EditInPlace, Input, useFormError } from '../..';
import React, { useState } from 'react';

export type EditInPlaceFormRenderProps<EntityType> = {
  onSubmit: (values: EntityType) => void;

  name: string;
  label: string;
  value: any;
  emptyValueMessage?: string;
  minLength?: number;
  maxLength?: number;
};

export function EditInPlaceFormRender<EntityType>({
  onSubmit,
  name,
  label,
  value,
  emptyValueMessage,
  minLength,
  maxLength,
  ...rest
}: EditInPlaceFormRenderProps<EntityType>) {
  // TODO decide how we want to display errors
  const [, /* FormError, */ showFormError] = useFormError();
  const [disabled, setDisabled] = useState<boolean>(false);

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        ...value,
        [name]: value[name] || '',
      }}
      onSubmit={async (values) => {
        try {
          // Clear existing form error.
          showFormError(false);

          // Perform form onSubmit.
          await onSubmit(values);
        } catch (error) {
          // Display form error.
          showFormError(true);
        }
      }}
    >
      {({ submitForm, setFieldValue }) => (
        <Form>
          <Input
            id={`${name}EditInPlaceForm`}
            name={name}
            label={label}
            labelPlacement="end"
            component={EditInPlace}
            emptyMessage={emptyValueMessage}
            submitForm={submitForm}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              const newValueLength = e.target.value.length;

              // If maxLength is not defined or
              // If maxLength exists and new value is smaller than it
              if (!maxLength || (maxLength && newValueLength <= maxLength)) {
                setFieldValue(name, e.target.value);
              }

              if (minLength && newValueLength < minLength) {
                setDisabled(true);
              } else {
                setDisabled(false);
              }
            }}
            setValue={(newValue: string) => {
              const newValueLength = newValue.length;
              if (minLength && newValueLength < minLength) {
                setDisabled(true);
              } else {
                setDisabled(false);
              }
              setFieldValue(name, newValue);
            }}
            disabled={disabled}
            {...rest}
          />
        </Form>
      )}
    </Formik>
  );
}
