import { useMemo } from 'react';

import { TargetGroupTableRow } from '@perts/model';
import { Col, Link, Row, Text } from '@perts/ui';
import { createUid } from '@perts/util';
import { PagesParams, toClassReport } from 'pages';

import { Table } from './Table';
import { useTerms } from 'components/TermsContext';
import { useParams } from 'react-router-dom';
import { User, mayClassReportView } from 'models';

// // Retained so we can use consistent styles if subheadings are re-introduced.
// const SubHeader = styled.span`
//   display: block;
//   font-weight: normal;
//   color: ${theme.colors.grayLight};
// `;

type Props = {
  data: TargetGroupTableRow[];
  /**
   * The current user.
   */
  user: User;
};

export const TargetGroupTable = ({ data, user }: Props) => {
  const { groupId } = useParams<PagesParams>();
  const terms = useTerms();

  // https://react-table.tanstack.com/docs/quick-start for details on useMemo
  const columns = useMemo(
    () => [
      {
        Header: <>{terms.class}</>,
        id: 'team_name',
        accessor: 'team_name',
        Cell: (cell) => {
          const { short_uid } = cell.row.original;
          const { value } = cell;

          return mayClassReportView(user, createUid('Class', short_uid)) ? (
            <Link to={toClassReport(groupId, short_uid)}>{value}</Link>
          ) : (
            value
          );
        },
      },
      {
        Header: <>Focal Group Name</>,
        id: 'target_group_name',
        accessor: 'target_group_name',
      },
      {
        Header: <># Enrolled</>,
        id: 'participants_n',
        accessor: 'participants_n',
      },
    ],
    [groupId, terms, user],
  );

  // Ignore teams which don't have target groups active.
  const filteredData = useMemo(
    () => data.filter((row) => Boolean(row.target_group_name)),
    [data],
  );

  if (filteredData.length === 0) {
    return (
      <Row>
        <Col hAlign="center">
          <Text>
            <em>No focal groups to display.</em>
          </Text>
        </Col>
      </Row>
    );
  }

  return <Table columns={columns} data={filteredData} />;
};
