import {
  Group,
  queryKeyGroup,
  selectUserIsAdmin,
  selectUserIsGroupManager,
  updateGroup,
  useCurrentUserQuery,
  useGroupGetByParams,
  useMetricsQuery,
  useMutationUpdate,
  useProgramGetByParams,
} from 'models';
import { useParams } from 'pages';

import { getMessageFromErrors } from '@perts/util';

import { Card } from '@perts/ui';
import { ErrorMessageBox, Loading, useTerms } from 'components';
import {
  SurveysEditAdvancedForm,
  SurveysEditAdvancedFormValues,
  SurveysEditMetricsForm,
  SurveysEditMetricsFormValues,
  toggleStatesMetrics,
  validateSurveyMetrics,
} from 'pages/Surveys/shared';
import defaultSettingsMetrics from './defaultSettingsMetrics';
import { SurveysDefaultSettingsGuideBox } from './SurveysDefaultSettingsGuideBox';
import { getDefaultValues } from '../shared/SurveysEditAdvancedForm/getDefaultValues';

// eslint-disable-next-line complexity
export const SurveysDefaultSettings = () => {
  const { groupId } = useParams();
  const terms = useTerms();
  const queryKey = queryKeyGroup(groupId);

  const userQuery = useCurrentUserQuery();

  const programQuery = useProgramGetByParams();

  const groupQuery = useGroupGetByParams();

  const metricsQuery = useMetricsQuery();

  // Mutation: Update Group surveys default settings
  const mutation = useMutationUpdate<Group, Group>(updateGroup, queryKey, {
    optimisticallyUpdate: true,
    transformFormValues: (formValues) => formValues,
    toastSuccessMsg: `Successfully updated survey default settings.`,
    toastErrorMsg: `There was a problem updating survey default settings.`,
  });

  // Display loading.
  if (
    userQuery.isLoading ||
    programQuery.isLoading ||
    groupQuery.isLoading ||
    metricsQuery.isLoading
  ) {
    return <Loading />;
  }

  // Display any errors.
  if (
    !groupQuery.isSuccess ||
    !programQuery.data ||
    !userQuery.isSuccess ||
    !metricsQuery.isSuccess
  ) {
    return (
      <ErrorMessageBox>
        {getMessageFromErrors([
          groupQuery.error,
          programQuery.error,
          userQuery.error,
        ])}
      </ErrorMessageBox>
    );
  }

  const currentGroup = groupQuery.data;
  const currentDefaultSettings = currentGroup.default_settings || {};

  const onSubmitMetrics = async (values: SurveysEditMetricsFormValues) => {
    const { metricsEnabled, ...metricsToggles } = values;

    // Create an array of Metric UIDs from the checked fields.
    const metrics = metricsEnabled
      ? Object.keys(metricsToggles).filter(
          (metricUid) => metricsToggles[metricUid].checked,
        )
      : // By setting `undefined` the metrics prop will be removed from the
        // group's default_settings altogether. Non-existance is different than
        // an empty array. Non-existance will cause classes created to fall back
        // to initializing with the program default metrics selected.
        undefined;

    // Merge the updated metrics with the existing default settings.
    const updatedDefaultSettings = {
      ...currentDefaultSettings,
      metrics,
    };

    for (const key in updatedDefaultSettings) {
      if (!updatedDefaultSettings[key]) {
        delete updatedDefaultSettings[key];
      }
    }

    const updatedGroup = {
      ...currentGroup,
      default_settings: updatedDefaultSettings,
    };

    await mutation.mutateAsync(updatedGroup);
  };

  const onSubmitAdvanced = async (values: SurveysEditAdvancedFormValues) => {
    const updatedGroup = {
      ...currentGroup,
      default_settings: {
        ...currentGroup.default_settings,
        gender_question_disabled: !values.genderQuestionEnabled,
      },
    };

    await mutation.mutateAsync(updatedGroup);
  };

  const program = programQuery.data;

  const userIsManager = selectUserIsGroupManager(userQuery.data, groupId);
  const userIsAdmin = selectUserIsAdmin(userQuery.data);

  const mayEditDefaultSettings =
    Boolean(program.survey_config_enabled) && (userIsManager || userIsAdmin);

  if (!mayEditDefaultSettings) {
    return (
      <ErrorMessageBox>
        You don&rsquo;t have access to this page.
      </ErrorMessageBox>
    );
  }

  const submitButtonText = `Save Settings for New ${terms.classes}`;

  const availableMetrics = metricsQuery.data.filter(({ uid }) =>
    program.metrics.find((m) => m.uid === uid),
  );

  const initialMetrics = [
    { metrics: defaultSettingsMetrics(currentGroup, program) },
  ];

  const initialValuesMetrics = {
    ...toggleStatesMetrics(availableMetrics, initialMetrics),
    metricsEnabled: Array.isArray(groupQuery.data.default_settings.metrics),
  };

  const initialValuesAdvanced = getDefaultValues(currentGroup);

  // Used by SurveysEditSwitch to set form checkboxes to program defaults when
  // the switch is disabled.
  const programMetricsValues = {};
  program.metrics.forEach((m) => {
    programMetricsValues[m.uid] = {
      checked: m.default_active,
      indeterminate: false,
    };
  });

  return (
    <>
      <SurveysDefaultSettingsGuideBox group={currentGroup} />

      <Card>
        <Card.Content>
          <SurveysEditMetricsForm
            submitButtonText={submitButtonText}
            onSubmit={onSubmitMetrics}
            validate={validateSurveyMetrics}
            initialValues={initialValuesMetrics}
            // For default settings form, don't use the default match feature.
            defaultValues={null}
            programValues={programMetricsValues}
            // MetricSet related props
            availableMetrics={availableMetrics}
            initialMetrics={initialMetrics}
            metricSets={program.metric_sets}
          />
        </Card.Content>
      </Card>

      <Card>
        <Card.Content>
          <SurveysEditAdvancedForm
            submitButtonText={submitButtonText}
            onSubmit={onSubmitAdvanced}
            initialValues={initialValuesAdvanced}
            defaultValues={null}
          />
        </Card.Content>
      </Card>
    </>
  );
};
