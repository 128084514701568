import styled from 'styled-components/macro';
import { IconLock, IconUnlock } from '@perts/ui';

const IconButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    height: 20px;
    width: 20px;
  }
`;

type IconRosterLockedProps = {
  locked: boolean;
};

export const IconRosterLocked = ({ locked }: IconRosterLockedProps) => (
  <IconButton>{locked ? <IconLock /> : <IconUnlock />}</IconButton>
);
