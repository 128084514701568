import { Route, Switch } from 'react-router-dom';
import { toGroupClassRoster, toGroupClassRosterRemove, ClassRoster } from '../';
import { ParticipantsRemove } from './ClassRosterParticipants/ParticipantsRemove';

export const ClassRosterRouter = () => (
  <>
    <Route path={toGroupClassRoster()}>
      <ClassRoster />
    </Route>
    <Switch>
      <Route path={toGroupClassRosterRemove()}>
        <ParticipantsRemove />
      </Route>
    </Switch>
  </>
);
