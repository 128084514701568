// https://docs.google.com/spreadsheets/d/1ZX4hcUol-_frFLfUYRnZ-UPEvpGsVOsWsJRw1g5tDBM

import keyBy from 'lodash/keyBy';

import { locales } from '../i18n';
import { CompositeMetricConfig, MetricItemConfig } from './types';
import { catalyze24CompletelyTrueOptions } from './answerOptions/catalyze24CompletelyTrueOptions';

export const plc24SupportiveLeadershipItemConfig: MetricItemConfig[] = [
  {
    label: 'plc_feel_supported',
    likertN: 4,
    minGood: 3,
    options: catalyze24CompletelyTrueOptions,
    prompts: {
      [locales.ENGLISH]:
        'I feel supported by my school leadership in implementing what I ' +
        'learned into my classroom practice.',
    },
  },
];

export const plc24SupportiveLeadershipConfig: CompositeMetricConfig = {
  label: 'plc-supportive-leadership',
  items: plc24SupportiveLeadershipItemConfig,
  itemIndex: keyBy(plc24SupportiveLeadershipItemConfig, 'label'),
  name: 'Supportive Leadership',
  description:
    'Supportive leadership empowers educators by providing guidance, ' +
    'resources, and a culture that values collaboration and continuous ' +
    "improvement, which are essential for the community's success and the " +
    'enhancement of student learning.',
};
