import { useHistory, useParams } from 'react-router-dom';
import { Tabs } from 'antd';
import styled from 'styled-components/macro';

import { Class } from 'models';
import { PagesParams, toGroupClassRoster } from 'pages';

import { NonScrollingBodyStyles } from '@perts/ui';
import { ClassRosterBatchEdit } from './ClassRosterBatchEdit';
import { ClassRosterParticipants } from './ClassRosterParticipants';
import { ClassRosterSettings } from './ClassRosterSettings';
import theme from 'components/theme';

const { TabPane } = Tabs;

type ClassRosterRenderProps = {
  cls: Class;
};

const TabsContainer = styled.div`
  margin: -${theme.units.bodyPadding} -${theme.units.bodyPadding} 0;

  /* Padding for narrower/mobile screens. */
  @media (max-width: ${theme.units.gridBreakpointSmall}px) {
    margin: -${theme.units.bodyPadding} -${theme.units.bodyPaddingSm} 0;
  }
`;

const TabPaneStyled = styled(TabPane)`
  // To calculate the available max-height to content we need to take the all size
  // and substract:
  // * TopBar Height: theme.units.appBarHeight
  // * Body padding x 2 (top and botton): theme.units.bodyPadding
  // * Tabs height
  // * Button actions height: It is a manual calculation considering
  // different values to different media queries
  // this is the last value in every calc(...);

  max-height: calc(
    100vh - ${theme.units.appBarHeight} - ${theme.units.bodyPadding} -
      ${theme.units.bodyPadding} - 75px
  );

  @media only screen and (max-width: ${theme.units.gridBreakpointMedium}px) {
    max-height: calc(
      100vh - ${theme.units.appBarHeight} - ${theme.units.bodyPadding} -
        ${theme.units.bodyPadding} - 90px
    );
  }

  @media only screen and (max-width: ${theme.units.gridBreakpointSmall}px) {
    max-height: calc(
      100vh - ${theme.units.appBarHeight} - ${theme.units.bodyPaddingSm} -
        ${theme.units.bodyPaddingSm} - 100px
    );
  }

  overflow-y: scroll;

  padding: 0 ${theme.units.bodyPadding};

  /* Padding for narrower/mobile screens. */
  @media (max-width: ${theme.units.gridBreakpointSmall}px) {
    padding: 0 ${theme.units.bodyPaddingSm};
  }
`;

export const ClassRosterRender = ({ cls }: ClassRosterRenderProps) => {
  const { groupId, tabKey = 'roster' } = useParams<PagesParams>();
  const history = useHistory();

  const handleClick = (key: string) => {
    history.push(toGroupClassRoster(groupId, cls.short_uid, key));
  };

  return (
    <TabsContainer>
      <NonScrollingBodyStyles />
      <Tabs activeKey={tabKey} centered onChange={handleClick} size="large">
        <TabPaneStyled tab="Roster" key="roster">
          <ClassRosterParticipants />
        </TabPaneStyled>
        <TabPaneStyled tab="Settings" key="settings">
          <ClassRosterSettings />
        </TabPaneStyled>
        <TabPaneStyled tab="Batch Edit" key="batch-edit">
          <ClassRosterBatchEdit />
        </TabPaneStyled>
      </Tabs>
    </TabsContainer>
  );
};
