// Definitions for what attributes we may assign to participants in the Data
// Warehouse as a result of their responses to various identity questions.
// This should align with Datateer's logic but does not actually control it.

export type AttributeValueConfig = {
  label: string;
  name: string;
  shortName: string;
};

export type AttributeConfig = {
  label: string;
  name: string;
  values: AttributeValueConfig[];
};

// Re-used attribute value configs.

export const race7ValueConfig: AttributeValueConfig[] = [
  {
    label: 'american-indian-alaskan-native',
    name: 'American Indian/Alaskan Native',
    shortName: 'AIAN',
  },
  {
    label: 'asian-asian-american',
    name: 'Asian/Asian American',
    shortName: 'Asian',
  },
  {
    label: 'black-african-american',
    name: 'Black/African American',
    shortName: 'Black',
  },
  {
    label: 'latinx',
    name: 'Hispanic/Latino(a)/Latinx',
    shortName: 'Latino/a/x',
  },
  {
    label: 'middle-eastern-north-african',
    name: 'Middle Eastern/North African',
    shortName: 'MENA',
  },
  {
    label: 'native-hawaiian-pacific-islander',
    name: 'Native Hawaiian/Pacific Islander',
    shortName: 'NHPI',
  },
  {
    label: 'white-european-american',
    name: 'White/European American',
    shortName: 'White',
  },
  { label: 'combined-groups', name: 'Small Groups', shortName: 'Small Groups' },
  { label: 'other', name: 'Other Groups', shortName: 'Other Groups' },
  {
    label: 'unknown',
    name: 'Skipped Race/Ethnicity Question',
    shortName: 'Skipped Race/Ethnicity Question',
  },
];

// Attribute configs.
// Ties together attributes, their values, and their display names.

export const Race7PreferenceConfig: AttributeConfig = {
  label: 'race-7-preference',
  name: 'Race/Ethnicity',
  values: [
    ...race7ValueConfig,
    { label: 'multi', name: 'Multiracial', shortName: 'Multiracial' },
  ],
};

export const Race7MultiConfig: AttributeConfig = {
  label: 'race-7-multi',
  name: 'Multiracial',
  values: [
    ...race7ValueConfig,
    { label: 'multi', name: 'Multiracial', shortName: 'Multiracial' },
  ],
};

export const Race7LeastServedConfig: AttributeConfig = {
  label: 'race-7-least-served',
  name: 'Least-well-served Racial',
  values: [...race7ValueConfig],
};

export const Gender3Config: AttributeConfig = {
  label: 'gender-3',
  name: 'Gender',
  values: [
    { label: 'man', name: 'Boy/Man', shortName: 'Boy/Man' },
    { label: 'woman', name: 'Girl/Woman', shortName: 'Girl/Woman' },
    { label: 'other', name: 'Non-binary/Other', shortName: 'Non-binary' },
    {
      label: 'combined-groups',
      name: 'Small Groups',
      shortName: 'Small Groups',
    },
    {
      label: 'unknown',
      name: 'Skipped Gender Question',
      shortName: 'Skipped Gender Question',
    },
  ],
};

export const NeedsSecurityConfig: AttributeConfig = {
  label: 'needs-security',
  name: 'Basic Needs Security',
  values: [
    { label: 'high', name: 'Secure', shortName: 'Secure' },
    { label: 'low', name: 'Insecure', shortName: 'Insecure' },
    {
      label: 'combined-groups',
      name: 'Small Groups',
      shortName: 'Small Groups',
    },
    {
      label: 'unknown',
      name: 'Skipped Basic Needs Security Question',
      shortName: 'Skipped Basic Needs Security Question',
    },
  ],
};

export const TargetGroupConfig: AttributeConfig = {
  label: 'target-group',
  name: 'Focal Group',
  values: [
    {
      label: 'target-group-true',
      name: 'Focal Group',
      shortName: 'Focal Group',
    },
    {
      label: 'target-group-false',
      name: 'Not Focal Group',
      shortName: 'Not Focal Group',
    },
    {
      label: 'combined-groups',
      name: 'Small Groups',
      shortName: 'Small Groups',
    },
    {
      label: 'unknown',
      name: 'Sometimes in Focal Group',
      shortName: 'Sometimes in Focal Group',
    },
  ],
};

// Top-level config for all attributes.

export type ParticipantAttributeConfig = {
  [attributeConfigLabel: string]: AttributeConfig;
};

export const participantAttributeConfig: ParticipantAttributeConfig = {
  // [Race7MultiConfig.label]: Race7MultiConfig,
  // [Race7LeastServedConfig.label]: Race7LeastServedConfig,
  [Race7PreferenceConfig.label]: Race7PreferenceConfig,
  [Gender3Config.label]: Gender3Config,
  [NeedsSecurityConfig.label]: NeedsSecurityConfig,
  [TargetGroupConfig.label]: TargetGroupConfig,
};
