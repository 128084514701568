// InputField Component
// Common form field styles/functionality.
// Note: This is intended for reuse by other perts-ui components.

import styled, { css } from 'styled-components/macro';
import theme from '../theme';

type InputFieldProps = {
  labelPlacement?: 'top' | 'start';

  // theme to use
  theme: any;

  // `true` to activate warning styles.
  warning?: boolean;
};

const InputFieldComponent = styled.div<InputFieldProps>`
  position: relative;

  /*
    InputField takes up full width and allow layout components should control
    the layout.
  */
  width: 100%;

  ${(props) =>
    props.labelPlacement === 'start' &&
    css`
      display: flex;
      align-items: center;

      label {
        margin-right: ${props.theme.units.gridDefaultGutter}px;
      }
    `}

  ${(props) =>
    props.warning &&
    css`
      outline: 2px solid ${props.theme.colors.warning};
      outline-offset: 2px;
      border-radius: ${props.theme.units.borderRadius};
    `};
`;

InputFieldComponent.defaultProps = {
  theme,
};

export const InputField = Object.assign(InputFieldComponent, {
  displayName: 'InputField',
});
