import { QueryKey, useQueryClient, useMutation } from 'react-query';
import { Comparator, isEmpty } from 'lodash';
import { SwitchFormRender } from './SwitchFormRender';

export type SwitchFormValues<EntityType> = {
  all: EntityType[];
  selected: EntityType[];
};

export type SwitchFormProps<EntityType> = {
  // The name of the Switch.
  name: string;
  // The label of the Switch.
  label: string;

  // An array of all of the items.
  all: EntityType[];
  // An array of the selected items. (A subset of all.)
  selected: EntityType[];

  // Predicate function run against selected items to determine the Switch's
  // toggle state. This is NOT the same as all.length === selected.length since
  // the Switch input is performing an update on a particular property of each
  // of the selected items (example, changing managed_organizations). Therefore,
  // the Switch toggle state should match the predicate test for selected items.
  isOn: (item: EntityType) => boolean;

  // Function called when Switch is toggled on.
  switchOn: (item: EntityType) => EntityType;
  // Function called when Switch is toggled off.
  switchOff: (item: EntityType) => EntityType;

  // react-query queryKey.
  queryKey: QueryKey;
  // Function called on the selected (updated) items. This is called AFTER the
  // items have been modified with the desired new values.
  queryUpdateFn: (itemsToUpdate: EntityType[]) => Promise<any>;
  // react-query invalidate function. Called onSettled to force a refetch.
  queryInvalidateFn: () => Promise<void>;

  // Comparator function passed to lodash's uniqWith. The all and selected item
  // arrays will be merged using this function to uniqueness.
  uniqComparator: Comparator<any>;

  // Function called on successful toggle.
  successFn?: () => void;

  // Function called on failed toggle.
  errorFn?: (err?: Error) => void;

  // Function to handle custom validation.
  validate?: (values: SwitchFormValues<EntityType>) => any;
};

export function SwitchForm<EntityType>({
  name,
  label,
  all,
  selected,
  isOn,
  switchOn,
  switchOff,
  queryKey,
  queryUpdateFn,
  queryInvalidateFn,
  uniqComparator,
  successFn = () => undefined,
  errorFn = () => undefined,
  validate = () => undefined,
  ...rest
}: SwitchFormProps<EntityType>) {
  const queryClient = useQueryClient();

  const mutation = useMutation(
    async (values: SwitchFormValues<EntityType>) => {
      await queryUpdateFn(values.selected);
    },
    {
      onMutate: async (values: SwitchFormValues<EntityType>) => {
        // Cancel any outgoing refetches.
        //   (So they don't overwrite our optimistic update.)
        await queryClient.cancelQueries(queryKey);

        // Snapshot the previous value.
        const previous = queryClient.getQueryData<EntityType[]>(queryKey);

        // Optimistically update to the new value.
        queryClient.setQueryData<EntityType[]>(queryKey, values.all);

        // Return a context object with the snapshot value.
        return { previous };
      },
      // Handle successful mutation.
      onSuccess: (_data) => {
        successFn();
      },
      // If the mutation fails, use the context returned to roll back.
      onError: (err: Error, _values, context) => {
        if (context?.previous) {
          queryClient.setQueryData<EntityType[]>(queryKey, context.previous);
        }

        errorFn(err);
      },
      // Always refetch after an error or success.
      onSettled: () => {
        queryInvalidateFn();
      },
    },
  );

  // https://stackoverflow.com/questions/65760158/react-query-mutation-typescript
  // Formik onSubmit handler
  const onSubmit = async (values: SwitchFormValues<EntityType>) => {
    const errors = validate(values);
    if (!isEmpty(errors)) {
      errorFn(errors);
      return;
    }
    await mutation.mutateAsync(values);
  };

  return (
    <SwitchFormRender
      onSubmit={onSubmit}
      name={name}
      label={label}
      all={all}
      selected={selected}
      isOn={isOn}
      switchOn={switchOn}
      switchOff={switchOff}
      uniqComparator={uniqComparator}
      {...rest}
    />
  );
}
