import { useQueryClient } from 'react-query';
import { useHistory } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { impersonateUser, impersonateUserReset } from 'state/auth/actions';
import selectors from 'state/selectors';

export const useImpersonate = () => {
  const queryClient = useQueryClient();
  const history = useHistory();

  const dispatch = useDispatch();
  const impersonateUid = useSelector(selectors.auth.impersonateUid);

  const getImpersonateUid = () => impersonateUid;

  const setImpersonateUid = (uid) => {
    if (!uid) {
      dispatch(impersonateUserReset());
    } else {
      dispatch(impersonateUser(uid));
    }

    // Clear out react-query cache and redirect back to root to force a data
    // refresh so the app will display the correct user's data.
    queryClient.clear();
    history.push('/');
  };

  return {
    setImpersonateUid,
    getImpersonateUid,
  };
};
