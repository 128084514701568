import { TRITON_URL_PREFIX } from 'services/triton/config';
import { fetchApi, fetchOptionsGET } from 'services/triton/helpers';

export async function isCanvasReachable() {
  try {
    const url = `${TRITON_URL_PREFIX}/api/canvas_health_check`;
    const options = fetchOptionsGET();
    const response = await fetchApi(url, options);
    return response.status === 200;
  } catch (error) {
    console.error('Canvas instance unreachable:', error);
    return false;
  }
}
