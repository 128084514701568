import { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useQueryClient, useMutation } from 'react-query';
import { message } from 'antd';

import {
  useGroupGetById,
  groupRemove,
  invalidateGroupRemove,
  queryKeyGroupsByProgram,
  Group,
} from 'models';
import { toHome, PagesParams } from 'pages';

import Loading from 'components/Loading';
import { ErrorMessageBox } from 'components/ErrorMessageBox';
import { GroupsRemoveForm, GroupsRemoveFormValues } from './GroupsRemoveForm';
import TermsContext from 'components/TermsContext';

import { createUid, getMessageFromErrors } from '@perts/util';
import { useCloseModal, useQueryParams } from 'utils';

export const GroupsRemove = () => {
  const { groupId, programId = '' } = useParams<PagesParams>();
  const organizationId = createUid('Organization', groupId);
  const terms = useContext(TermsContext);
  const queryClient = useQueryClient();

  const { get } = useQueryParams();
  const n = get('n');
  const cursor = get('cursor');
  const queryKey = queryKeyGroupsByProgram(programId, { n, cursor });

  const closeModal = useCloseModal({
    pathname: toHome(programId),
    ignoreEmptyState: true,
  });

  // Close the modal if modal is opened with no groupId provided.
  useEffect(() => {
    if (!groupId) {
      closeModal();
    }
  }, [groupId, closeModal]);

  // Query for Group.
  const groupQuery = useGroupGetById(organizationId);

  const mutation = useMutation(
    (values: GroupsRemoveFormValues) => groupRemove(values.group),
    {
      onMutate: (values: GroupsRemoveFormValues) => {
        // Snapshot the previous value.
        const previousGroups =
          queryClient.getQueryData<Group[]>(queryKey) || [];

        // Optimistically update to the new values.
        queryClient.setQueryData(
          queryKey,
          previousGroups.filter((g) => g.uid !== values.group.uid),
        );

        return { previousGroups };
      },
      // Handle successful mutation.
      onSuccess: () => {
        message.success(`Successfully deleted the ${terms.group}.`);
        invalidateGroupRemove(queryClient);
      },
      // Handle successful mutation.
      onError: (_err, _data, context: any) => {
        message.error(`Unable to delete ${terms.group}.`);

        // Rollback to previous if there's an error.
        if (context?.previousGroups) {
          queryClient.setQueryData(queryKey, context.previousGroups);
        }
      },
    },
  );

  // Display loading.
  if (groupQuery.isLoading) {
    return <Loading />;
  }

  // Display errors.
  if (!groupQuery.isSuccess) {
    return (
      <ErrorMessageBox>
        {getMessageFromErrors([groupQuery.error])}
      </ErrorMessageBox>
    );
  }

  return (
    <GroupsRemoveForm
      close={closeModal}
      onSubmit={mutation.mutateAsync}
      group={groupQuery.data}
    />
  );
};
