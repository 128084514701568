import { useFormikContext } from 'formik';
import { Col, Input, Row, TextField } from '@perts/ui';
import { ROSTER_ID_MIN_LENGTH, ROSTER_ID_MAX_LENGTH } from 'config';
import { RosterSignOnRuleFormValues } from './RosterSignOnRuleForm';

export const FieldsStudentId = ({ onChange }) => {
  const { values, touched, errors, isSubmitting } =
    useFormikContext<RosterSignOnRuleFormValues>();

  return (
    <Row>
      <Col>
        <Input
          component={TextField}
          disabled={!values.ruleEnabled || isSubmitting}
          error={touched.studentIdMin ? errors.studentIdMin : ''}
          fullWidth
          id="studentIdMin"
          label="Minimum"
          labelPlacement="top"
          max={ROSTER_ID_MAX_LENGTH}
          min={ROSTER_ID_MIN_LENGTH}
          name="studentIdMin"
          type="number"
          onChange={onChange('studentIdMin')}
        />
      </Col>
      <Col>
        <Input
          component={TextField}
          disabled={!values.ruleEnabled || isSubmitting}
          error={touched.studentIdMax ? errors.studentIdMax : ''}
          fullWidth
          id="studentIdMax"
          label="Maximum"
          labelPlacement="top"
          max={ROSTER_ID_MAX_LENGTH}
          min={ROSTER_ID_MIN_LENGTH}
          name="studentIdMax"
          type="number"
          onChange={onChange('studentIdMax')}
        />
      </Col>
    </Row>
  );
};
