import { useContext } from 'react';
import { useParams } from 'react-router-dom';
import pluralize from 'pluralize';

import TermsContext from 'components/TermsContext';
import { Actionbar, IconUserDelete, Row, Col, Show, Button } from '@perts/ui';
import { ParticipantsEditTargetForm } from './ParticipantsEditTargetForm';
import { PagesParams, toGroupClassRosterRemove } from 'pages';
import { useOpenModal } from 'utils';

export const ClassRosterParticipantsActionbar = ({
  ControlToggle,
  disabled,
  participants,
  selected,
  checked,
  mayEditTargetGroup,
}) => {
  const { groupId = '', classId = '' } = useParams<PagesParams>();
  const terms = useContext(TermsContext);
  const openModal = useOpenModal();

  const handleAction = (route: string) => {
    openModal(route, { checked });
  };

  return (
    <>
      <Actionbar>
        <Row alignItems="center">
          <Col colsSm={2} cols={0}>
            <ControlToggle aria-label="Bulk participant selector" />
          </Col>

          <Show when={mayEditTargetGroup}>
            <Col shrink colsSm={10} cols={0}>
              <ParticipantsEditTargetForm
                all={participants}
                selected={selected}
              />
            </Col>
          </Show>

          <Col colsSm={mayEditTargetGroup ? 12 : 10} cols={0}>
            <Button
              onClick={() => {
                handleAction(
                  toGroupClassRosterRemove(groupId, classId, 'roster'),
                );
              }}
              disabled={disabled}
              iconLeft={<IconUserDelete />}
              fullWidth
            >
              Remove {pluralize(terms.participant, selected.length)}
            </Button>
          </Col>
        </Row>
      </Actionbar>
    </>
  );
};
