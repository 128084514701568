import { combineReducers } from 'redux';

import auth from './auth/reducer';
import ui from './ui/reducer';

const reducers = {
  auth,
  ui,
};

if (window.Cypress) {
  /* eslint no-console: off */
  console.log(
    'Enabling Redux debugging output. See state/reducers to turn off.',
  );
  reducers.debugRedux = (state = {}, action) => {
    console.log('>>', action.type, action);
    if (action.payload instanceof Error) {
      console.log(action.payload.toString());
      console.error(action.payload);
    }
    return state;
  };
}

const rootReducer = combineReducers(reducers);
export default rootReducer;
