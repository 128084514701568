import { maxBy } from 'lodash';
import {
  Class,
  Group,
  Program,
  Report,
  selectClassesReportsLatest,
  selectMostCurrentReport,
  selectMostCurrentClassReport,
} from 'models';

export const selectGroupProgram = (
  group: Group,
  programs: Program[] = [],
): Program => {
  const program = programs.find((p) => p.uid === group.program_id);
  if (program) {
    return program;
  }
  throw new Error(`Group's program not found`);
};

export const selectGroupClasses = (
  group: Group,
  classes: Class[] = [],
): Class[] => classes.filter((cls) => cls.organization_ids.includes(group.uid));

export const selectMostCurrentGroupReport = (
  group: Group,
  reports: Report[] = [],
) => selectMostCurrentReport(selectGroupReports(group, reports));

export const selectGroupClassesWithReports = (
  group: Group,
  classes: Class[] = [],
  reports: Report[] = [],
): Class[] =>
  selectGroupClasses(group, classes).filter(
    (cls) =>
      reports.filter((report) => report.parent_id === cls.classroom_id).length >
      0,
  );

export const selectAllCurrentGroupReports = (
  group: Group,
  classes: Class[] = [],
  reports: Report[] = [],
): Report[] => {
  const mostCurrentReports: Report[] = [];

  // Determine most current group report related to group
  // If the groupReport is found, it is added to report list
  const groupReport = selectMostCurrentGroupReport(group, reports);
  if (groupReport) {
    mostCurrentReports.push(groupReport);
  }
  // Determine most current report by class
  const classReports = classes.map((cls) =>
    selectMostCurrentClassReport(cls, reports),
  ) as Report[];

  return [...mostCurrentReports, ...classReports];
};

// Returns the Reports found in `reports` that are associated with `group`.

export const selectGroupReports = (group: Group, reports: Report[]): Report[] =>
  reports.filter((report) => report.parent_id === group.uid);

// Returns the latest Report found in `reports` that is associated with `group`.

export const selectGroupReportLatest = (
  group: Group,
  reports: Report[],
): Report => {
  const reportsByGroup = selectGroupReports(group, reports);
  return maxBy(reportsByGroup, 'issue_date') as Report;
};

// Sort `reports`, first by issue_date descending (most recent reports first),
// then by reports associated with a group first. Assumption, the `reports`
// provided all belong to the same group.

const sortIssueDateDesc = (a, b) => (a.issue_date < b.issue_date ? 1 : -1);
const sortGroupReportsDesc = (a) => (a.organization_id ? -1 : 1);

export const sortGroupReports = (reports: Report[]): Report[] =>
  reports.sort(sortIssueDateDesc).sort(sortGroupReportsDesc);

// Select all of `reports` that belong to the `group` or the `classes` provided.
// The results are sorted, with reports associated with the group first, then by
// issue_date descending.

export const selectGroupAndClassesReportsLatest = (
  group: Group,
  classes: Class[],
  reports: Report[],
): Report[] => {
  const reportByGroup = selectGroupReportLatest(group, reports);
  const reportsByClasses = selectClassesReportsLatest(classes, reports);

  const unsortedReports = [reportByGroup, ...reportsByClasses].filter(
    (r) => r !== undefined,
  );

  const sortedReports = sortGroupReports(unsortedReports);

  return sortedReports;
};
