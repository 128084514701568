// https://docs.google.com/spreadsheets/d/1ZX4hcUol-_frFLfUYRnZ-UPEvpGsVOsWsJRw1g5tDBM

import keyBy from 'lodash/keyBy';

import { CompositeMetricConfig, MetricItemConfig } from './types';
import { catalyze23AnswerOptions } from './answerOptions';

export const learningCulture23ItemConfig: MetricItemConfig[] = [
  {
    label: 'learning_culture_time',
    likertN: 5,
    minGood: 4,
    options: catalyze23AnswerOptions,
    prompts: {
      en: 'There is consistent, protected time for collaborative learning.',
    },
  },
  {
    label: 'learning_culture_data',
    likertN: 5,
    minGood: 4,
    options: catalyze23AnswerOptions,
    prompts: {
      en: 'There is a culture of using data to grow as professionals. ',
    },
  },
  {
    label: 'learning_culture_support',
    likertN: 5,
    minGood: 4,
    options: catalyze23AnswerOptions,
    prompts: {
      en: 'People regularly discuss challenges to learn from one another.',
    },
  },
  {
    label: 'learning_culture_innovate',
    likertN: 5,
    minGood: 4,
    options: catalyze23AnswerOptions,
    prompts: {
      en: 'People are encouraged to innovate and experiment, even when success is not guaranteed.',
    },
  },
  {
    label: 'learning_culture_pl',
    likertN: 5,
    minGood: 4,
    options: catalyze23AnswerOptions,
    prompts: {
      en: 'Professional learning experiences actually help me improve my work. ',
    },
  },
];

export const learningCulture23Config: CompositeMetricConfig = {
  label: 'learning-culture',
  items: learningCulture23ItemConfig,
  itemIndex: keyBy(learningCulture23ItemConfig, 'label'),
};
