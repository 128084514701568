import { DatePicker } from 'antd';
import { isEqual } from 'lodash';
import { RangeValue } from 'rc-picker/lib/interface';
import classNames from 'classnames';
import moment from 'moment';
import styled from 'styled-components/macro';

import { Flag } from '../../.';
import { InputField } from '../InputField';
import InputFieldLabel from '../InputFieldLabel';
import theme from '../theme';

const { RangePicker: RangePickerAntd } = DatePicker;

export type RangePickerProps = {
  // Allows some customization of RangePicker from antd.
  // See apps/triton-client/src/configAntd.js
  antdLocale?: any;

  // To allow styled-components wrapping.
  className?: string;

  // If true, the input is disabled.
  disabled?: boolean;

  // Field error.
  error?: any;

  // The id of the input element.
  id: string;

  // The label of the input element.
  label?: any;

  // The name of the input element.
  name: string;

  // If true, UI indicates the field is required
  required?: boolean;

  // The current value of the input.
  value: any;

  // The initial value of the input.
  initialValue: any;

  // It true, the input was touched.
  touched?: boolean;

  // Format date to set new Value
  formatDate?: string;

  // Format date to handle dates in RangePicker
  formatDateToShow?: string;

  // Formik function to set field values
  setFieldValue?: (field: string, value: any, shouldValidate?: boolean) => void;

  // Formik function to set touch field
  setFieldTouched?: (
    field: string,
    value: any,
    shouldValidate?: boolean,
  ) => void;

  // Function to invoke after setFieldValue
  onChangeCallback?: (
    start_date: string | null,
    end_date: string | null,
  ) => boolean;

  disabledDate?: (date: moment.Moment) => boolean;

  customHandleChange?: (dates: RangeValue<moment.Moment>) => void;

  renderExtraFooter?: (() => React.ReactNode) | undefined;

  // `true` to activate warning styles.
  warning?: boolean;
};

export const RangePicker = ({
  antdLocale,
  className,
  disabled,
  id,
  label,
  name,
  formatDate,
  formatDateToShow,
  setFieldValue,
  setFieldTouched,
  value = {},
  initialValue,
  onChangeCallback,
  error,
  disabledDate,
  customHandleChange,
  renderExtraFooter,
  warning,
}: RangePickerProps) => {
  const antdProps = antdLocale ? { locale: antdLocale } : {};

  const cx = classNames(className);

  // Determine current value to RangePickerAntd
  const { start_date, end_date } = value;
  let formattedValue: RangeValue<moment.Moment> = null;
  if (start_date && end_date) {
    formattedValue = [moment(start_date), moment(end_date)];
  }

  const handleOnChange = (dates: RangeValue<moment.Moment>): void => {
    if (customHandleChange) {
      customHandleChange(dates);
    } else {
      // If formik form is used, setFieldValue must be invoked
      if (setFieldValue && setFieldTouched) {
        if (Array.isArray(dates)) {
          const [momentStartDate, momentEndDate] = dates;
          const newStartDate = moment(momentStartDate).format(formatDate);
          const newEndDate = moment(momentEndDate).format(formatDate);
          setFieldValue(`${name}.start_date`, newStartDate, false);
          setFieldValue(`${name}.end_date`, newEndDate, false);
          if (onChangeCallback) {
            onChangeCallback(newStartDate, newEndDate);
          }
        } else {
          setFieldValue(`${name}.start_date`, null, false);
          setFieldValue(`${name}.end_date`, null, false);
          if (onChangeCallback) {
            onChangeCallback(null, null);
          }
        }
        setFieldTouched(`${name}`, true, false);
      }
    }
  };

  const flagType = error
    ? 'conflict'
    : isEqual(value, initialValue)
    ? 'hidden'
    : 'pending';

  return (
    <InputField className={cx} warning={warning}>
      <LabelContainer>
        {label && <InputFieldLabel htmlFor={id}>{label}</InputFieldLabel>}
      </LabelContainer>

      <Flag type={flagType}>
        <RangePickerContainer>
          <RangePickerStyled
            disabled={disabled}
            id={id}
            name={name}
            value={formattedValue}
            format={formatDateToShow}
            onChange={handleOnChange}
            data-testid={id}
            disabledDate={disabledDate}
            renderExtraFooter={renderExtraFooter}
            {...antdProps}
          />
        </RangePickerContainer>
      </Flag>
    </InputField>
  );
};

type RangePickerStyledProps = {
  theme?: any;
};

const RangePickerStyled = styled(RangePickerAntd)<RangePickerStyledProps>`
  &.ant-picker-disabled {
    cursor: not-allowed;
    color: ${(props) => props.theme.colors.textDisabled};
    background: ${(props) => props.theme.colors.white};
  }
`;

RangePickerStyled.defaultProps = {
  theme,
};

const RangePickerContainer = styled.div`
  position: relative;
`;

const LabelContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;
