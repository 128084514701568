// https://docs.google.com/spreadsheets/d/1ZX4hcUol-_frFLfUYRnZ-UPEvpGsVOsWsJRw1g5tDBM

import keyBy from 'lodash/keyBy';

import { CompositeMetricConfig, MetricItemConfig } from './types';
import { catalyze23AnswerOptions } from './answerOptions';

export const collectiveVision23ItemConfig: MetricItemConfig[] = [
  {
    label: 'collective_vision_clear',
    likertN: 5,
    minGood: 4,
    options: catalyze23AnswerOptions,
    prompts: {
      en: 'The organizational vision is communicated in a way that is clear and understandable to me.',
    },
  },
  {
    label: 'collective_vision_community',
    likertN: 5,
    minGood: 4,
    options: catalyze23AnswerOptions,
    prompts: {
      en: 'The organizational vision reflects input from the communities we serve.',
    },
  },
  {
    label: 'collective_vision_responsibility',
    likertN: 5,
    minGood: 4,
    options: catalyze23AnswerOptions,
    prompts: {
      en: 'There is a shared sense of responsibility for advancing the organizational vision.',
    },
  },
  {
    label: 'collective_vision_guide',
    likertN: 5,
    minGood: 4,
    options: catalyze23AnswerOptions,
    prompts: {
      en: 'The organizational vision is used to guide choices and actions.',
    },
  },
  {
    label: 'collective_vision_pl',
    likertN: 5,
    minGood: 4,
    options: catalyze23AnswerOptions,
    prompts: {
      en: 'Professional learning is aligned to the organizational vision.',
    },
  },
];

export const collectiveVision23Config: CompositeMetricConfig = {
  label: 'collective-vision',
  items: collectiveVision23ItemConfig,
  itemIndex: keyBy(collectiveVision23ItemConfig, 'label'),
};
