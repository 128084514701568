import { useParams } from 'react-router-dom';
import { GuideBox, HelpText, Link } from '@perts/ui';
import { PagesParams, toGroupSchedules, toGroupClassesAdd } from 'pages';
import { useTerms } from 'components/TermsContext';
import { helpArticles } from 'config';

export const ClassesGuideBox = () => {
  const { groupId = '' } = useParams<PagesParams>();
  const terms = useTerms();

  return (
    <GuideBox
      action={
        <Link to={toGroupClassesAdd(groupId)}>
          Create {terms.aClass} to survey {terms.participants.toLowerCase()}.
        </Link>
      }
      faq={
        <HelpText articleId={helpArticles.guideboxClasses}>
          What is {terms.aClass}?
        </HelpText>
      }
      next={<Link to={toGroupSchedules(groupId)}>Schedules</Link>}
    />
  );
};
