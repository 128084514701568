import { Suspense } from 'react';
import { lazily } from 'react-lazily';
import { isEmpty } from 'lodash';

import { GuideBox, HelpText, InfoBox, Link } from '@perts/ui';
import { getMessageFromErrors } from '@perts/util';

import { helpArticles } from 'config';
import { useParams, toNetworkGroupsAdd } from 'pages';
import { useNetworkGetByParams, useDatasetNetwork } from 'models';
import { EmptyList } from 'components/EmptyList';
import { ErrorMessageBox } from 'components/ErrorMessageBox';
import Loading from 'components/Loading';
import { useTerms } from 'components/TermsContext';

// Pulls in ChartJS, and we'd like code splitting for that, so lazy-load.
const { DashboardNetworkParticipation } = lazily(
  () => import('./DashboardNetworkParticipation'),
);

export const NetworkParticipation = () => {
  const { networkId } = useParams();
  const networkQuery = useNetworkGetByParams();
  const dataQuery = useDatasetNetwork(networkId);

  const terms = useTerms();

  if (networkQuery.isLoading || dataQuery.isLoading) {
    return <Loading />;
  }

  // Display any errors.
  if (!networkQuery.isSuccess || !dataQuery.isSuccess) {
    return (
      <ErrorMessageBox>
        {getMessageFromErrors([networkQuery.error, dataQuery.error])}
      </ErrorMessageBox>
    );
  }

  const { data: network } = networkQuery;
  const { data } = dataQuery;

  if (isEmpty(network.association_ids)) {
    return (
      <>
        <EmptyList />
        <GuideBox
          action={
            <Link to={toNetworkGroupsAdd(networkId)}>
              Add {terms.aGroup.toLowerCase()} to see participation data.
            </Link>
          }
          faq={
            <HelpText articleId={helpArticles.participationHowToUseNetwork}>
              How does the participation dashboard work?
            </HelpText>
          }
          next={null}
        />
      </>
    );
  }

  if (data === null) {
    return (
      <InfoBox color="warning">
        <div>No data available yet</div>
        <div>A dashboard will be available within 24 hours.</div>
      </InfoBox>
    );
  }

  return (
    <Suspense fallback={<Loading />}>
      <DashboardNetworkParticipation network={network} data={data} />
    </Suspense>
  );
};
