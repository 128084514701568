import { capitalize } from './capitalize';
import { randomAlphaNumeric } from './random';

export const ID_LENGTH: number = 12;

export const createUid = (kind: string, id?: string) => {
  kind = capitalize(kind);

  // If `id` not provided, generate a random id.
  if (!id) {
    return `${kind}_${randomAlphaNumeric(ID_LENGTH)}`;
  }

  // Don't convert to long uid if the ID provided is already long.
  if (id.indexOf(`${kind}_`) > -1) {
    return id;
  }

  return `${kind}_${id}`;
};
