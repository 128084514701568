import { FiChevronRight } from 'react-icons/fi';
import styled from 'styled-components/macro';

import { Button, ButtonProps, theme } from '@perts/ui';

const HomeCardButtonStyled = styled(Button)`
  // Keeps button icon/text from wrapping when it doesn't need to.
  min-width: 190px;
  white-space: nowrap;

  // Allow more flexibility for smaller screens.
  @media only screen and (max-width: ${theme.units.gridBreakpointSmall}px) {
    padding: ${theme.units.paddingSm};
    min-width: auto;
    white-space: normal;
  }
`;

type Props = Required<Pick<ButtonProps, 'to' | 'iconLeft'>> &
  Pick<ButtonProps, 'children' | 'disabled' | 'iconRight'>;

export const HomeCardButton = ({ iconRight, ...rest }: Props) => (
  <HomeCardButtonStyled
    color="text"
    {...rest}
    iconRight={iconRight === undefined ? <FiChevronRight /> : iconRight}
    fullWidth
    fullHeight
  />
);
