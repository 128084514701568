import { Form, Formik } from 'formik';

import {
  Col,
  HelpText,
  InfoBox,
  Row,
  Show,
  Text,
  Visible,
  useFormError,
} from '@perts/ui';
import { DefaultSettingsWarning, FormButtons, useTerms } from 'components';
import { helpArticles } from 'config';
import { ClassSettingsFormValues } from 'pages/Classes/shared';
import { RosterSignOnRuleFormFields } from './RosterSignOnRuleFormFields';
import { validationSchema } from './validate';

const SignOnRuleHelpText = () => (
  <Col hAlign="flex-end" shrink>
    <HelpText articleId={helpArticles.whatIsRosterIDRule}>
      What is a sign-on rule?
    </HelpText>
  </Col>
);

export type RosterSignOnRuleFormValues = Pick<
  ClassSettingsFormValues,
  | 'ruleEnabled'
  | 'portalType'
  | 'portalMessage'
  | 'studentEmailEnding'
  | 'studentIdMin'
  | 'studentIdMax'
>;

type Props = {
  close?: () => void;
  submitButtonText: string;
  onSubmit: (values: RosterSignOnRuleFormValues) => void;
  userIsAdmin?: boolean;
  initialValues: RosterSignOnRuleFormValues;
  defaultValues: RosterSignOnRuleFormValues | null;
};

export const RosterSignOnRuleForm = ({
  close,
  submitButtonText,
  onSubmit,
  userIsAdmin = false,
  initialValues,
  defaultValues,
}: Props) => {
  const terms = useTerms();
  const [FormError, showFormError] = useFormError();

  return (
    <>
      <Row alignItems="center">
        <Col shrink vAlign="center" hAlign="center">
          <Text as="h2">
            <span>{terms.participant} Sign-On Rule</span>
          </Text>
        </Col>

        <Show when={!Boolean(close)}>
          <Col></Col>
          <SignOnRuleHelpText />
        </Show>
      </Row>

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize={true}
        onSubmit={async (values) => {
          try {
            // Clear existing form error.
            showFormError(false);

            // Perform form onSubmit.
            await onSubmit(values);

            // Close modal on successful form onSubmit.
            close && close();
          } catch (error) {
            // Display form error.
            showFormError(true);
          }
        }}
      >
        {({ dirty, isSubmitting, isValid }) => (
          <Form>
            <RosterSignOnRuleFormFields
              userIsAdmin={userIsAdmin}
              initialValues={initialValues}
            />

            <Row>
              <Col>
                <FormError />
                <DefaultSettingsWarning
                  hideWhenErrors={true}
                  message={`This change doesn't match the ${terms.group}'s default settings.`}
                  defaultValues={defaultValues}
                />
              </Col>
            </Row>

            <Row>
              <Col>
                <Visible when={dirty && initialValues.ruleEnabled === null}>
                  <InfoBox
                    color="warning"
                    aria-label="Saving will overwrite mixed settings."
                  >
                    The selected {terms.classes.toLowerCase()} have mixed
                    settings. Saving will overwrite them.
                  </InfoBox>
                </Visible>
              </Col>
            </Row>

            <Show when={Boolean(close)}>
              <Row>
                <SignOnRuleHelpText />
              </Row>
            </Show>

            <FormButtons close={close} submitButtonText={submitButtonText} />
          </Form>
        )}
      </Formik>
    </>
  );
};
