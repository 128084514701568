import { isEmpty, pickBy, cloneDeep } from 'lodash';

// Function to remove nested empty attributes.
// Formik return that the form isValid only if the
// errors var is an empty object
export const cleanCycleErrors = (currentErrors: any, classId: string) => {
  let updatedErrors = cloneDeep(currentErrors);
  const { classes = {} } = updatedErrors;
  const { [classId]: currentClassId = {} } = classes;
  const { cycles = {} } = currentClassId;

  // Remove empty cycle conflicts
  const cleanedCycles = pickBy(cycles, (c) => !isEmpty(c));
  const updatedCycles = {
    cycles: cleanedCycles,
  };

  // Remove cycles attribute if it is empty
  const cleanedClassId = pickBy(updatedCycles, (c) => !isEmpty(c));
  const updatedClasses = {
    ...classes,
    [classId]: cleanedClassId,
  };

  // Remove classId attribute if it is empty
  const cleanedClasses = pickBy(updatedClasses, (c) => !isEmpty(c));
  updatedErrors = {
    classes: cleanedClasses,
  };

  // Remove classes attribute if it is empty
  const cleanedErrors = pickBy(updatedErrors, (c) => !isEmpty(c));

  return cleanedErrors;
};
