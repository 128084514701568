import { Group, Program } from 'models';

export const selectProgramGroups = (
  // Allowing for undefined because this sometimes relies on a query that may
  // not have resolved yet.
  program: Program | undefined,
  groups: Group[] = [],
) => {
  if (!program) {
    return [];
  }

  return groups.filter((group) => group.program_id === program.uid);
};

export const selectProgramById = (
  programId: string,
  programs: Program[] = [],
) => programs.find((p) => p.uid === programId);
