// For display on pages with an empty list.

import { Card, Col, Row, Text } from '@perts/ui';

export const EmptyList = ({ text = "There's nothing here yet." }) => (
  <Card>
    <Card.Title>
      <Row>
        <Col hAlign="center">
          <Text as="h3">{text}</Text>
        </Col>
      </Row>
    </Card.Title>
  </Card>
);
