import { EXPERIENCE_GAP_THRESHOLD } from 'config';

/**
 * Check if the subgroup is signficantly below the comparison "all" score.
 * @param {number | null} all      a "rated positively" score from 0 to 1
 *   representing the comparison population.
 * @param {number | null} subgroup a "rated positively" score from 0 to 1
 *   representing a subgroup of interest.
 * @returns {boolean} whether or not there's an equity gap.
 */
export const getIsEquityGap = (all: number | null, subgroup: number | null) => {
  const gap = all && subgroup ? Math.round((all - subgroup) * 100) : null;
  return gap === null ? false : gap >= EXPERIENCE_GAP_THRESHOLD;
};
