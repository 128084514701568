import { Switch, Route } from 'react-router-dom';

import { useClassGetByParams, useGroupGetByParams } from 'models';
import {
  toClassReport,
  toClassReportSettings,
  toGroupClasses,
  toGroupClass,
  toGroupClassesAdd,
  toGroupClassesDefaultSettings,
  toGroupClassesRemove,
  toGroupClassesEditUsers,
  toGroupClassesEditGroups,
  toGroupClassesEditTargetGroup,
  toGroupClassesRosterIDRule,
  toGroupClassesRosterLocking,
  toGroupClassesRosterSignOnType,
  toGroupClassRoster,
  toGroupMembers,
  toGroupParticipation,
  toGroupParticipationByParticipant,
  toGroupReports,
  toGroupReport,
  toGroupReportSettings,
  toGroupSchedules,
  toGroupSurveys,
  toGroupSchedulesDefaultSettings,
  toGroupSurveysDefaultSettings,
  toGroupClassSurveyInstructions,
  toGroupSurveysInstructions,
  useParams,
} from 'pages';

import { Text, Row, Col, Link } from '@perts/ui';
import { useTerms } from 'components';
import { BreadcrumbsName } from './BreadcrumbsName';
import { IconBreadcrumbChevron } from './IconBreadcrumbChevron';

const BreadcrumbsGroupMembers = () => {
  const terms = useTerms();
  return (
    <>
      <IconBreadcrumbChevron />
      <span>{terms.groupMembers}</span>
    </>
  );
};

const BreadcrumbsGroupClassesClass = () => {
  const { groupId, classId } = useParams();
  const { data: cls, isLoading: classIsLoading } = useClassGetByParams();
  const terms = useTerms();

  if (classIsLoading || !cls) {
    return null;
  }

  return (
    <>
      <IconBreadcrumbChevron />
      <Link to={toGroupClasses(groupId)}>
        <span>{terms.classes}</span>
      </Link>

      <IconBreadcrumbChevron />
      <BreadcrumbsName name={cls.name} />

      <Switch>
        <Route path={toGroupClassRoster(groupId, classId, 'roster')}>
          <IconBreadcrumbChevron /> <span>Roster</span>
        </Route>
        <Route path={toGroupClassRoster(groupId, classId, 'settings')}>
          <IconBreadcrumbChevron /> <span>Settings</span>
        </Route>
        <Route path={toGroupClassRoster(groupId, classId, 'batch-edit')}>
          <IconBreadcrumbChevron /> <span>Batch Edit</span>
        </Route>
      </Switch>
    </>
  );
};

const BreadcrumbsGroupClassesDefaultSettings = () => {
  const terms = useTerms();
  const { groupId } = useParams();

  return (
    <>
      <IconBreadcrumbChevron />
      <Link to={toGroupClasses(groupId)}>{terms.classes}</Link>
      <IconBreadcrumbChevron />
      <span>Default {terms.class} Settings</span>
    </>
  );
};

const BreadcrumbsGroupClasses = () => {
  const terms = useTerms();

  const classPagePaths = [
    toGroupClasses(),
    toGroupClassesAdd(),
    toGroupClassesRemove(),
    toGroupClassesEditUsers(),
    toGroupClassesEditGroups(),
    toGroupClassesEditTargetGroup(),
    toGroupClassesRosterIDRule(),
    toGroupClassesRosterLocking(),
    toGroupClassesRosterSignOnType(),
  ];

  return (
    <>
      <Switch>
        <Route path={classPagePaths} exact>
          {/* Here Classes is NOT being linked. */}
          <IconBreadcrumbChevron />
          <span>{terms.classes}</span>
        </Route>

        <Route path={toGroupClassesDefaultSettings()}>
          {/* Here Classes will be linked. */}
          <BreadcrumbsGroupClassesDefaultSettings />
        </Route>

        <Route path={toGroupClass()}>
          {/* Here Classes WILL be linked. */}
          <BreadcrumbsGroupClassesClass />
        </Route>
      </Switch>
    </>
  );
};

const BreadcrumbsGroupSurveysDefaultSettings = () => {
  const { groupId } = useParams();

  return (
    <>
      <IconBreadcrumbChevron />
      <Link to={toGroupSurveys(groupId)}>Surveys</Link>
      <IconBreadcrumbChevron />
      <span>Default Settings</span>
    </>
  );
};

const BreadcrumbsGroupSurveysInstructionsClass = () => {
  const { groupId } = useParams();
  const { data: cls, isLoading: classIsLoading } = useClassGetByParams();

  if (classIsLoading || !cls) {
    return null;
  }

  return (
    <>
      <IconBreadcrumbChevron />
      <Link to={toGroupSurveys(groupId)}>Surveys</Link>
      <IconBreadcrumbChevron />
      <BreadcrumbsName name={cls.name} />
      <IconBreadcrumbChevron />
      <span>Instructions</span>
    </>
  );
};

const BreadcrumbsGroupSurveysInstructions = () => {
  const { groupId } = useParams();

  return (
    <>
      <IconBreadcrumbChevron />
      <Link to={toGroupSurveys(groupId)}>Surveys</Link>
      <IconBreadcrumbChevron />
      <span>Instructions</span>
    </>
  );
};

const BreadcrumbsGroupSurveys = () => (
  <>
    <Switch>
      <Route path={toGroupSurveysDefaultSettings()}>
        {/* Here Survey WILL be linked. */}
        <BreadcrumbsGroupSurveysDefaultSettings />
      </Route>
      <Route path={toGroupClassSurveyInstructions()}>
        {/* Here Survey WILL be linked. */}
        <BreadcrumbsGroupSurveysInstructionsClass />
      </Route>

      <Route path={toGroupSurveysInstructions()}>
        {/* Here Survey WILL be linked. */}
        <BreadcrumbsGroupSurveysInstructions />
      </Route>

      <Route path={toGroupSurveys()}>
        {/* Here Survey is NOT being linked. */}
        <IconBreadcrumbChevron />
        <span>Surveys</span>
      </Route>
    </Switch>
  </>
);

const BreadcrumbsGroupSchedulesDefaultSettings = () => {
  const { groupId } = useParams();

  return (
    <>
      <IconBreadcrumbChevron />
      <Link to={toGroupSchedules(groupId)}>Schedules</Link>
      <IconBreadcrumbChevron />
      <span>Default Schedule</span>
    </>
  );
};

const BreadcrumbsGroupSchedules = () => (
  <>
    <Switch>
      <Route path={toGroupSchedules()} exact>
        {/* Here Schedules is NOT being linked. */}
        <IconBreadcrumbChevron />
        <span>Schedules</span>
      </Route>

      <Route path={toGroupSchedulesDefaultSettings()}>
        {/* Here Schedules will be linked. */}
        <BreadcrumbsGroupSchedulesDefaultSettings />
      </Route>
    </Switch>
  </>
);

const BreadcrumbsGroupParticipationByParticipant = () => {
  const { groupId } = useParams();
  const terms = useTerms();
  return (
    <>
      <IconBreadcrumbChevron />
      <Link to={toGroupParticipation(groupId)}>Participation</Link>
      <IconBreadcrumbChevron />
      <span>Participation By {terms.participant}</span>
    </>
  );
};

const BreadcrumbsGroupParticipation = () => (
  <Switch>
    <Route path={toGroupParticipationByParticipant()}>
      <BreadcrumbsGroupParticipationByParticipant />
    </Route>

    <Route path={toGroupParticipation()}>
      <IconBreadcrumbChevron />
      <span>Participation</span>
    </Route>
  </Switch>
);

const BreadcrumbReportGroup = () => {
  const { groupId } = useParams();
  const terms = useTerms();

  return (
    <>
      <IconBreadcrumbChevron />
      <Link to={toGroupReports(groupId)}>Reports</Link>
      <IconBreadcrumbChevron />
      <span>{terms.group} Report</span>
    </>
  );
};

const BreadcrumbReportClass = () => {
  const { groupId } = useParams();
  const { data: cls, isLoading: classIsLoading } = useClassGetByParams();

  if (classIsLoading || !cls) {
    return null;
  }

  return (
    <>
      <IconBreadcrumbChevron />
      <Link to={toGroupReports(groupId)}>Reports</Link>
      <IconBreadcrumbChevron />
      <span>{cls.name}</span>
    </>
  );
};

const BreadcrumbsClassReportSettings = () => {
  const { classId, groupId } = useParams();
  const { data: cls, isLoading: classIsLoading } = useClassGetByParams();

  if (classIsLoading || !cls) {
    return null;
  }

  return (
    <>
      <IconBreadcrumbChevron />
      <Link to={toGroupReports(groupId)}>Reports</Link>
      <IconBreadcrumbChevron />
      <span>
        <Link to={toClassReport(groupId, classId)}>{cls.name}</Link>
      </span>
      <IconBreadcrumbChevron />
      <span>Settings</span>
    </>
  );
};

const BreadcrumbsGroupReportSettings = () => {
  const { groupId } = useParams();
  const terms = useTerms();

  return (
    <>
      <IconBreadcrumbChevron />
      <Link to={toGroupReports(groupId)}>Reports</Link>
      <IconBreadcrumbChevron />
      <span>
        <Link to={toGroupReport(groupId)}>{terms.group} Report</Link>
      </span>
      <IconBreadcrumbChevron />
      <span>Settings</span>
    </>
  );
};

const BreadcrumbsGroupReports = () => (
  <Switch>
    <Route path={toGroupReports()} exact>
      <IconBreadcrumbChevron />
      <span>Reports</span>
    </Route>

    <Route path={toClassReport()}>
      <BreadcrumbReportClass />
    </Route>
    <Route path={toGroupReport()}>
      <BreadcrumbReportGroup />
    </Route>
  </Switch>
);

export const BreadcrumbsGroup = () => {
  const { data: group } = useGroupGetByParams();

  return group ? (
    <Row>
      <Col vAlign="center" flexWrap="wrap">
        <Text as="nav">
          <Switch>
            <Route path="/groups">
              <Link to="/">Home</Link>

              <IconBreadcrumbChevron />
              <BreadcrumbsName name={group.name} />

              <Switch>
                <Route
                  path={[toGroupReports(), toGroupReport(), toClassReport()]}
                >
                  <BreadcrumbsGroupReports />
                </Route>

                <Route path={toClassReportSettings()}>
                  <BreadcrumbsClassReportSettings />
                </Route>

                <Route path={toGroupReportSettings()}>
                  <BreadcrumbsGroupReportSettings />
                </Route>

                <Route path={toGroupMembers()}>
                  <BreadcrumbsGroupMembers />
                </Route>

                <Route path={toGroupClasses()}>
                  <BreadcrumbsGroupClasses />
                </Route>

                <Route path={toGroupSurveys()}>
                  <BreadcrumbsGroupSurveys />
                </Route>

                <Route path={toGroupSchedules()}>
                  <BreadcrumbsGroupSchedules />
                </Route>

                <Route path={toGroupParticipation()}>
                  <BreadcrumbsGroupParticipation />
                </Route>
              </Switch>
            </Route>
          </Switch>
        </Text>
      </Col>
    </Row>
  ) : null;
};
