import { all, put, takeLatest } from 'redux-saga/effects';

import authSaga from './auth/sagas';
import uiSaga from './ui/sagas';

function* redirectRequest() {
  yield put({ type: 'REDIRECT_RESET' });
}

export default function* rootSaga() {
  yield all([
    // Base sagas
    authSaga(),
    uiSaga(),
    // Additional sagas
    takeLatest('REDIRECT_REQUEST', redirectRequest()),
  ]);
}
