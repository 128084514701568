import { message } from 'antd';
import { Switch, Route } from 'react-router-dom';
import { useMutation, useQueryClient } from 'react-query';
import { toGroup, toGroupReports, toHome, useParams } from 'pages';
import {
  Prompt,
  promptResponsesPost,
  queryKeyPrompts,
  useCurrentUserQuery,
  useProgramId,
  usePromptsQuery,
} from 'models';
import { RenderPrompt } from './RenderPrompt';
import { promptToDisplay } from './promptToDisplay';

type PromptResponseFormValues = Record<string, string>;

const PromptQuery = ({ path }: { path: string }) => {
  const queryClient = useQueryClient();
  const queryKey = queryKeyPrompts(path);
  const { groupId } = useParams();
  const programId = useProgramId();
  const { data: prompts } = usePromptsQuery(path);
  const { data: user } = useCurrentUserQuery();

  const prompt = promptToDisplay(prompts, {
    programId,
    groupId,
    // we will have this user here, satisfy ts
    userEmail: user?.email || '',
  });

  const mutation = useMutation(
    async (values: PromptResponseFormValues) => {
      // Optimistically update, so the user can move onto the next prompt or the
      // prompt dialog can close before waiting for server response.
      await queryClient.cancelQueries(queryKey);
      const previous = queryClient.getQueryData<Prompt[]>(queryKey);
      const updatedPrompts =
        prompts?.filter((p) => p.uid !== prompt?.uid) || [];
      queryClient.setQueryData<Prompt[]>(queryKey, updatedPrompts);

      if (!user?.uid || !prompt?.uid) {
        return { previous };
      }

      const responses = Object.entries<string>(values).map(
        ([question, answer]) => ({
          user_id: user?.uid,
          prompt_id: prompt?.uid,
          question,
          answer,
        }),
      );

      await promptResponsesPost(responses);

      return { previous };
    },
    {
      onSuccess: () => {
        message.success('Successfully submitted response.');
      },
      onError: () => {
        // TODO. Is there a good way to recover? If something goes wrong, then
        // users will probably be completely locked out of Copilot.
        message.error('Unable to submit response.');
      },
      onSettled: () => {
        queryClient.invalidateQueries(queryKey);
      },
    },
  );

  if (!prompt) {
    return null;
  }

  return <RenderPrompt label={prompt.label} onSubmit={mutation.mutateAsync} />;
};

const PromptRoute = ({ path }: { path: string }) => (
  // A parent Route is needed so useParams has access to matched route params.
  <Route path={path}>
    <PromptQuery path={path} />
  </Route>
);

export const Prompts = () => (
  <Switch>
    {/* Each route that we want to allow prompts on needs to be added here. */}
    <PromptRoute path={toHome()} />
    <PromptRoute path={toGroupReports()} />
    <PromptRoute path={toGroup()} />
  </Switch>
);
