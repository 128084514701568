import styled from 'styled-components/macro';
import { theme } from '../../';

export type AppContentProps = {
  children: React.ReactNode;
};

const AppContentStyled = styled.div`
  margin: ${theme.units.appBarHeight} 0 0 0;
  padding: ${theme.units.bodyPadding};

  // All dynamic content should live in here. It fills the allowed height
  // (see SidebarMarginStyled) so child content can set height 100% if they
  // want to, or scroll if they want to.
  height: 100%;
  overflow: auto;

  /* Padding for medium screens. */
  @media (max-width: ${theme.units.gridBreakpointMedium}px),
    (max-height: ${theme.units.gridBreakpointMedium}px) {
    padding: ${theme.units.bodyPaddingMd};
  }

  /* Padding for narrower/mobile screens. */
  @media (max-width: ${theme.units.gridBreakpointSmall}px),
    (max-height: ${theme.units.gridBreakpointSmall}px) {
    padding: ${theme.units.bodyPaddingSm};
  }

  @media print {
    // Without this override, the printable content would end wherever
    // the viewport does, which is silly in that context.
    height: initial;

    // In print mode, the top bar is NOT fixed, but occupies height in the flow,
    // so we don't need to adjust the margin.
    margin-top: 0;
  }
`;

export const AppContent = AppContentStyled;
