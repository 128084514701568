import { getGroupDefaultSettings } from 'models';

type FormValues = {
  [fieldName: string]: any;
};

export const getDefaultValues = (program, group) => {
  const groupDefaultSettings = getGroupDefaultSettings(group);

  // undefined indicates the group does not have any default metrics saved.
  // In this case, we will not display any form warnings.
  if (groupDefaultSettings.metrics === undefined) {
    return null;
  }

  // Generate a defaultValues object.
  const defaultValues: FormValues = {};

  for (const metric of program.metrics) {
    // Include a key:value pair for every Metric in the Program.
    defaultValues[metric.uid] = {
      checked: groupDefaultSettings.metrics.includes(metric.uid),
      indeterminate: false,
    };
  }

  return defaultValues;
};
