import { Class } from 'models';

import { Card, Col, IconChevronRight, Row, Show, Text } from '@perts/ui';
import { ReportCardDate } from './ReportCardDate';

export type ReportCardProps = {
  cls?: Class;
  groupId: string;
  statusIcon?: React.ReactNode;
  title: string;
  last_updated_results?: string | null;
  to: string;
};

export const ReportCard = ({
  cls,
  groupId,
  statusIcon,
  title,
  last_updated_results,
  to,
  ...rest
}: ReportCardProps) => (
  <Card>
    <Card.Title to={to} {...rest}>
      <Row>
        <Show when={Boolean(statusIcon)}>
          <Col shrink>{statusIcon}</Col>
        </Show>
        <Col hAlign="flex-start" vAlign="center">
          <Text>{title}</Text>
        </Col>

        <Col shrink>
          <ReportCardDate date={last_updated_results} />
        </Col>

        <Col shrink vAlign="center" hAlign="flex-end">
          <IconChevronRight size={18} />
        </Col>
      </Row>
    </Card.Title>
  </Card>
);
