import styled from 'styled-components/macro';
import { IconArchive } from '@perts/ui';
import { theme } from 'components';

const GroupArchiveIconStyled = styled.div`
  border: 1px dashed ${theme.colors.grayMedium};
  border-radius: ${theme.units.borderRadius};

  height: 30px;
  width: 30px;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const GroupArchiveIcon = () => (
  <GroupArchiveIconStyled>
    <IconArchive />
  </GroupArchiveIconStyled>
);
