import styled from 'styled-components/macro';
import { Row } from '@perts/ui';

type FidelityChildTableRowProps = {
  hideOnPrint?: boolean;
};

const FidelityChildTableRow = styled(Row)<FidelityChildTableRowProps>`
  @media print {
    ${({ hideOnPrint = false }) =>
      hideOnPrint &&
      `
        display: none;
      `}
  }
`;

export default FidelityChildTableRow;
