import { useEffect } from 'react';

type Callback = (...args: any[]) => void;

type Broadcast = {
  listen: (callback: Callback) => void;
  broadcast: (...args: any[]) => void;
};

const registry: { [index: string]: Callback[] } = {};

// Broadcast hook to communicate _events_ across multiple components, regardless
// of their location. Note that if you want to manage _state_ then you should
// use the Context API.
//
// Example usage for broadcasting an event:
//
// const MyBroadcastComponent = () => {
//   const { broadcast } = useBroadcast('/example/channel');
//
//   return (
//     <button onClick={() => broadcast('some data')}>Send It</button>
//   );
// };
//
// Example usage for listening to an event:
//
// const MyListeningComponent = () => {
//   const { listen } = useBroadcast('/example/channel');
//   const listen((data) => console.log('MyListeningComponent heard', data));
//
//   return <>Foo.</>;
// };
//
// Extra credit: make this generic so the callbacks have typed args.
export const useBroadcast = (channel: string): Broadcast => ({
  listen(callback) {
    // Wrap everything in an effect hook so that multiple renders of a
    // consuming component don't register multiple callbacks.
    // Also tell eslint to chill about the function name "listen" (it wants
    // all custom hooks to have names like "useListen").
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      // Initialize the list of callbacks for this channel.
      if (!(channel in registry)) {
        registry[channel] = [];
      }

      // Add the callback to the channel.
      registry[channel].push(callback);

      // When the component unmounts, unregister the callback.
      return function cleanup() {
        const i = registry[channel].indexOf(callback);
        registry[channel].splice(i, 1);
      };
    }, [callback]);
  },
  broadcast(...args) {
    if (registry[channel] === undefined) {
      // eslint-disable-next-line no-console
      console.error(`useBroadcast: No listeners on channel "${channel}"`);
    }

    // Call all of the callbacks registered by listeners on this channel.
    (registry[channel] || []).forEach((callback) => callback(...args));
  },
});
