import { useEffect, useRef } from 'react';
import {
  User,
  queryKeyCurrentUser,
  updateCurrentUser,
  useCurrentUserQuery,
  useMutationUpdate,
  useProgramGetByParams,
} from 'models';

export const useUpdateRecentProgram = () => {
  const { data: user } = useCurrentUserQuery();
  const { data: program } = useProgramGetByParams();

  // To save current program to db as recent viewed.
  const currentUserMutation = useMutationUpdate<User, User>(
    updateCurrentUser,
    queryKeyCurrentUser(),
  );

  // Make sure the user is updated only once, when the program changes.
  const recentProgramIdRef = useRef<string>('');

  useEffect(() => {
    // Keep track of which program was viewed recently, updating if necessary.
    if (
      user &&
      program &&
      program.active &&
      user.recent_program_id !== program.uid &&
      recentProgramIdRef.current !== program.uid
    ) {
      currentUserMutation.mutate({ ...user, recent_program_id: program.uid });

      // Record an "instance variable" that this update has been sent, and no
      // further calls of this effect for this program should send again.
      recentProgramIdRef.current = program.uid;
    }
  }, [user, program, currentUserMutation]);
};
