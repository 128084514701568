import { Card } from '@perts/ui';
import { useTerms } from 'components';
import {
  Class,
  Group,
  queryKeyClass,
  updateClass,
  useMutationUpdate,
} from 'models';
import {
  getDefaultValuesSignOn as getDefaultValues,
  getInitialValuesSignOn as getInitialValues,
  getRosterTargetGroupSettings,
  RosterTargetGroupForm,
  RosterTargetGroupFormValues,
} from 'pages/Classes/shared';
import { useScrollToTargetGroup } from './useScrollToTargetGroup';

export const ClassRosterSettingsTargetGroupForm = ({
  group,
  cls,
}: {
  group: Group;
  cls: Class;
  userIsAdmin?: boolean;
}) => {
  useScrollToTargetGroup();
  const terms = useTerms();

  const initialValues = getInitialValues(
    [cls],
    ['targetGroupEnabled', 'targetGroupName'],
  );
  const defaultValues = getDefaultValues(group);

  const queryKey = queryKeyClass(cls.uid);

  const mutation = useMutationUpdate<Class, Class>(updateClass, queryKey, {
    optimisticallyUpdate: false,
    toastSuccessMsg: `Successfully updated ${terms.class.toLowerCase()} settings.`,
    toastErrorMsg: `There was a problem updating ${terms.class.toLowerCase()} settings.`,
  });

  const onSubmit = async (values: RosterTargetGroupFormValues) => {
    const rosterSignOnProps = getRosterTargetGroupSettings({ values });

    const updatedClass = {
      ...cls,
      ...rosterSignOnProps,
    };

    await mutation.mutateAsync(updatedClass);
  };

  return (
    <Card>
      <Card.Content>
        <RosterTargetGroupForm
          initialValues={initialValues}
          defaultValues={defaultValues}
          submitButtonText="Save Settings"
          onSubmit={onSubmit}
        />
      </Card.Content>
    </Card>
  );
};
