import { ROSTER_ID_PATTERN } from 'config';
import { ParticipantId } from 'models';

export const isParticipantIdMinLength = (
  ids: ParticipantId[],
  id: ParticipantId,
) => ROSTER_ID_PATTERN.test(id.student_id);

export const isParticipantIdUnique = (
  ids: ParticipantId[],
  id: ParticipantId,
) =>
  ids.filter((i) => i.stripped_student_id === id.stripped_student_id).length <=
  1;

export const isRosterRuleFollowed =
  (regexString: string | null) => (ids: ParticipantId[], id: ParticipantId) => {
    if (!regexString) {
      return true;
    }

    const regex = new RegExp(regexString);

    return regex.test(id.student_id);
  };
