import React from 'react';
import { useTable, useSortBy, usePagination } from 'react-table';
import styled from 'styled-components/macro';

import DataTable from 'components/DataTable';
import { DEFAULT_PAGE_SIZE } from 'config';
import { Col, Row, theme } from '@perts/ui';
import { ToggleChange } from '../components';

const SubHeader = styled.span`
  font-weight: normal;
  color: ${theme.colors.grayLight};
`;

export const Table = ({
  columns,
  data,
  reportDate,
  showChange,
  setShowChange,
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,

    // from usePagination
    // https://codesandbox.io/embed/github/tannerlinsley/react-table/tree/master/examples/pagination
    page,
    canPreviousPage,
    canNextPage,
    // pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      // https://react-table.js.org/api/useSortBy#table-options
      initialState: {
        sortBy: [{ id: 'child_name' }],
        pageSize: DEFAULT_PAGE_SIZE,
      },
    },
    useSortBy,
    usePagination,
  );

  const colWidths = [15]; // first column (org names) gets more width

  return (
    <>
      <DataTable {...getTableProps()} colWidths={colWidths}>
        <thead>
          <tr>
            <th>{/* empty cell above org names */}</th>
            <th colSpan={showChange ? 14 : 7}>
              {showChange ? (
                <>
                  Rated Positively on Last Survey
                  <br />
                  <SubHeader>± change since survey 1</SubHeader>
                </>
              ) : (
                <>Rated Positively on Last Survey</>
              )}
            </th>
          </tr>
          {headerGroups.map((headerGroup) => (
            <tr
              {...headerGroup.getHeaderGroupProps([
                {
                  className: headerGroup.className,
                  style: headerGroup.style,
                },
              ])}
            >
              {headerGroup.headers.map((column) => (
                <DataTable.SortHeader
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                >
                  {column.render('Header')}
                  <DataTable.SortIcon {...column} />
                </DataTable.SortHeader>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps}>
          {page.map((row) => {
            prepareRow(row);

            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => (
                  <td
                    {...cell.getCellProps([
                      {
                        className: cell.column.className,
                        style: cell.column.style,
                      },
                    ])}
                  >
                    {cell.render('Cell')}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </DataTable>
      <Row>
        <Col hAlign="flex-start" />
        <Col hAlign="center">
          <DataTable.PageNav
            canNextPage={canNextPage}
            canPreviousPage={canPreviousPage}
            gotoPage={gotoPage}
            nextPage={nextPage}
            pageCount={pageCount}
            pageIndex={pageIndex}
            previousPage={previousPage}
          />
        </Col>
        <Col hAlign="flex-end">
          <DataTable.PageSize
            pageSize={pageSize}
            totalRows={data.length}
            setPageSize={setPageSize}
          />
        </Col>
      </Row>
      <Row>
        <Col hAlign="flex-start" vAlign="center">
          <p>
            <em>Data refreshed {reportDate}</em>
          </p>
        </Col>
        <Col hAlign="center" vAlign="center">
          <ToggleChange showChange={showChange} setShowChange={setShowChange} />
        </Col>
        <Col hAlign="flex-end"></Col>
      </Row>
    </>
  );
};
