import { useState, useEffect, useRef } from 'react';
import { Formik, Form } from 'formik';
import { Input, Switch, useFormError, Row, Col } from '../../';
import { Comparator, every, some, uniqWith } from 'lodash';

export type SwitchFormRenderValues<EntityType> = {
  all: EntityType[];
  selected: EntityType[];
};

export type SwitchFormRenderProps<EntityType> = {
  onSubmit: (values: SwitchFormRenderValues<EntityType>) => void;

  name: string;
  label: string;

  all: EntityType[];
  selected: EntityType[];

  isOn: (item: EntityType) => boolean;

  switchOn: (item: EntityType) => EntityType;
  switchOff: (item: EntityType) => EntityType;

  uniqComparator: Comparator<any>;
};

export function SwitchFormRender<EntityType>({
  onSubmit,
  name,
  label,
  all,
  selected,
  isOn,
  switchOn,
  switchOff,
  uniqComparator,
  ...rest
}: SwitchFormRenderProps<EntityType>) {
  // TODO decide how we want to display errors
  const [, /* FormError, */ showFormError] = useFormError();
  const someSelected = selected.length > 0;
  const allCheckedMatchIsOn = someSelected && every(selected, isOn);
  const someCheckedMatchIsOn = someSelected && some(selected, isOn);
  const formikRef = useRef<any>(null);
  const [toggleState, setToggleState] = useState({
    checked: allCheckedMatchIsOn,
    indeterminate: someCheckedMatchIsOn && !allCheckedMatchIsOn,
  });

  const initialValues = {
    [name]: toggleState.checked,
    all,
    selected,
  };

  useEffect(() => {
    //Determine new values to update form values
    const updatedSomeSelected = selected.length > 0;
    const updatedAllCheckedMatchIsOn =
      updatedSomeSelected && every(selected, isOn);
    const updatedSomeCheckedMatchIsOn =
      updatedSomeSelected && some(selected, isOn);
    const newToggleState = {
      checked: updatedAllCheckedMatchIsOn,
      indeterminate: updatedSomeCheckedMatchIsOn && !updatedAllCheckedMatchIsOn,
    };

    //Check ref to Formik FOrm and set new values
    if (formikRef.current) {
      formikRef.current.setValues({
        [name]: toggleState.checked,
        all,
        selected,
      });
    }
    setToggleState(newToggleState);
  }, [all, isOn, name, selected, toggleState.checked]);

  return (
    <Formik
      innerRef={formikRef}
      initialValues={initialValues}
      onSubmit={async (values) => {
        try {
          // Clear existing form error.
          showFormError(false);

          // The value of the form switch is based on the checked states of all
          // toggle items that are provided to this form. When a user clicks on
          // the form switch to update all checked items, it's current value
          // will be opposite of what we want to end up saving. The act of
          // saving (or rather, the optimistic update that follows) is what will
          // actually get the form switch to change state.
          const updatedItems = values[name]
            ? selected.map(switchOff)
            : selected.map(switchOn);

          // Combine all items with selected (updated) items and remove
          // duplicate items, giving preference to the selected (updated) items.
          const allItemsOptimistic = uniqWith(
            [...updatedItems, ...values.all],
            uniqComparator,
          );

          // Perform form onSubmit.
          await onSubmit({
            ...values,
            all: allItemsOptimistic,
            selected: updatedItems,
          });
        } catch (error) {
          // Display form error.
          showFormError(true);
        }
      }}
    >
      {({ handleSubmit, isSubmitting }) => (
        <Row>
          <Col>
            <Form>
              <Input
                id={`${name}SwitchForm`}
                name={name}
                label={label}
                labelPlacement="end"
                {...toggleState}
                disabled={!someSelected || isSubmitting}
                component={Switch}
                onChange={() => handleSubmit()}
                {...rest}
              />
            </Form>
          </Col>
        </Row>
      )}
    </Formik>
  );
}
