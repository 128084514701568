import * as DefaultComponent from './DefaultComponent';
import * as PromptAscend23Demographics from './PromptAscend23Demographics';
import * as PromptElevate23Demographics from './PromptElevate23Demographics';
import * as PromptElevate23GroupReports from './PromptElevate23GroupReports';
import * as PromptDemoByOrganization from './PromptDemoByOrganization';
import * as PromptTou from './PromptTou';

const promptComponents = {
  default: DefaultComponent,
  PromptAscend23Demographics,
  PromptElevate23Demographics,
  PromptElevate23GroupReports,

  PromptDemoByOrganization,
  PromptTou,
};

const capitalizeFirstLetter = (str: string) =>
  str.charAt(0).toUpperCase() + str.slice(1);

export const promptComponent = (promptLabel: string) => {
  const componentKey = `Prompt${capitalizeFirstLetter(promptLabel)}`;
  return promptComponents[componentKey] || promptComponents.default;
};
