import { keyBy } from 'lodash';

import { Class, Cycle } from 'models';

type DiffClassesCycles = (
  currentClasses: Class[],
  updatedClasses: Class[],
) => Cycle[];

export const diffClassesCycles: DiffClassesCycles = (
  currentClasses,
  updatedClasses,
) => {
  // Extract cycles from current classes, indexed for order-indepdendent lookup.
  const currentCyclesIndex = keyBy(
    currentClasses.flatMap((cls) => cls.cycles),
    'uid',
  );

  // Extract cycles from all updated classes.
  const updatedCyclesArr = updatedClasses.flatMap((cls) => cls.cycles || []);

  // Find the cycles that have been changed.
  // This step must be performant because it compares properties
  // between two potentially large (1000's) sets of cycles with every render.
  const differentCycles = updatedCyclesArr.filter((updated) => {
    const current = currentCyclesIndex[updated.uid];
    if (!current) {
      // Updated cycle is somehow new. Not expected, but treat as "different."
      return true;
    }
    return (
      current.start_date !== updated.start_date ||
      current.end_date !== updated.end_date
    );
  });

  return differentCycles;
};
