import styled from 'styled-components/macro';
import { IoMdFunnel } from 'react-icons/io';

const SelectFilterIconDiv = styled.div`
  display: flex;
  align-items: center;

  svg {
    height: 20px;
    width: 20px;
  }
`;

export const SelectFilterIcon = () => {
  return (
    <SelectFilterIconDiv>
      <IoMdFunnel aria-hidden="true" />
    </SelectFilterIconDiv>
  );
};
