import { isNetworkData } from '@perts/model';
import { getMessageFromErrors } from '@perts/util';

import Loading from 'components/Loading';
import {
  useGroupsQueryByNetworkId,
  useNetworkGetByParams,
  useProgramsQuery,
  useDatasetNetwork,
  useMetricsQuery,
} from 'models';
import { useParams } from 'pages';
import { ErrorMessageBox } from 'components/ErrorMessageBox';

import { getAvailableMetrics, NoReportMessage } from '../components';

// eslint-disable-next-line complexity
export const NetworkReportLoader = ({ children }) => {
  const { networkId } = useParams();

  const groupsQuery = useGroupsQueryByNetworkId(networkId);
  const metricsQuery = useMetricsQuery();
  const networkQuery = useNetworkGetByParams();
  const programsQuery = useProgramsQuery();
  const datasetQuery = useDatasetNetwork(networkQuery.data?.uid, {
    enabled: Boolean(networkQuery.isSuccess),
  });

  if (
    datasetQuery.isLoading ||
    groupsQuery.isLoading ||
    metricsQuery.isLoading ||
    networkQuery.isLoading ||
    programsQuery.isLoading
  ) {
    return <Loading />;
  }

  if (!datasetQuery.isSuccess || datasetQuery.data === null) {
    return <NoReportMessage />;
  }

  if (
    !groupsQuery.isSuccess ||
    !metricsQuery.isSuccess ||
    !networkQuery.isSuccess ||
    !programsQuery.isSuccess
  ) {
    return (
      <ErrorMessageBox>
        {getMessageFromErrors([
          groupsQuery.error,
          metricsQuery.error,
          networkQuery.error,
          programsQuery.error,
        ])}
      </ErrorMessageBox>
    );
  }

  const allMetrics = metricsQuery.data;
  const groups = groupsQuery.data;
  const network = networkQuery.data;
  const dataset = datasetQuery.data;
  const program = programsQuery.data.find((p) => p.uid === network.program_id);
  if (!program) {
    throw new Error(`Couldn't find program for network: ${network.program_id}`);
  }

  if (!isNetworkData(dataset)) {
    return (
      <ErrorMessageBox>
        Unexpected dataset type: "{(dataset as any).dataset_type}"
      </ErrorMessageBox>
    );
  }

  const { results, results_by_organization: resultsByGroup } = dataset;
  if (!results || !resultsByGroup) {
    return <NoReportMessage />;
  }

  const programMetricIds = program.metrics.map(({ uid }) => uid);
  const programMetrics = allMetrics.filter(({ uid }) =>
    programMetricIds.includes(uid),
  );

  const reportMetrics = getAvailableMetrics(results, allMetrics);

  return children({
    groups,
    lastRun: dataset.last_run,
    lastUpdatedResults: dataset.last_updated_results,
    network,
    program,
    programMetrics,
    reportMetrics,
    results,
    resultsByGroup,
  });
};
