import { Switch, Route } from 'react-router-dom';
import {
  ClassReportDefault21,
  ClassReportSettings,
  GroupReportDefault21,
  GroupReportSettings,
  NetworkReportDefault21,
  NetworkReportSettings,
  ReportsExport,
} from './';
import {
  toArchiveClassReportId,
  toArchiveClassReportIdSettings,
  toArchiveGroupReportId,
  toArchiveGroupReportIdSettings,
  toArchiveNetworkReportId,
  toArchiveNetworkReportIdExport,
  toArchiveNetworkReportIdSettings,
} from 'pages';

export const ReportsRouter = () => (
  <>
    <Switch>
      <Route path={toArchiveNetworkReportIdSettings()}>
        <NetworkReportSettings />
      </Route>
      <Route path={toArchiveNetworkReportId()}>
        <NetworkReportDefault21 />
      </Route>
      <Route path={toArchiveClassReportIdSettings()}>
        <ClassReportSettings />
      </Route>
      <Route path={toArchiveClassReportId()}>
        <ClassReportDefault21 />
      </Route>
      <Route path={toArchiveGroupReportIdSettings()}>
        <GroupReportSettings />
      </Route>
      <Route path={toArchiveGroupReportId()}>
        <GroupReportDefault21 />
      </Route>
    </Switch>

    <Switch>
      <Route path={toArchiveNetworkReportIdExport()}>
        <ReportsExport />
      </Route>
    </Switch>
  </>
);
