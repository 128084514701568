import styled from 'styled-components';

import { Link } from '@perts/ui';
import { formatPercentagePoints } from '@perts/util';

import { FIDELITY_THRESHOLD } from 'config';
import { theme, ReactTableTd } from 'components';
import {
  BarChartRatedPositively,
  ChangeText,
  EquityGapIcon,
  FidelityWarningIcon,
} from '../../components';

export const MetricName = styled.div<{ isComposite: boolean }>`
  font-weight: ${(props) => (props.isComposite ? 'bold' : 'normal')};
`;

export const MetricParticipation = styled.div``;

export const TdMetric = ({ cell, isComposite }) => {
  const { link, name, sampleSize, sampleSizeTotal } = cell.row.original;
  const { isExpanded, subRows } = cell.row;
  const rowSpan = subRows?.length > 0 ? subRows?.length + 1 : 1;

  if (!isExpanded) {
    // Rows that are not marked with isExpanded are subrows.
    return null;
  }

  return (
    <ReactTableTd cell={cell} rowSpan={rowSpan} vAlign="top">
      {link ? (
        <Link to={link}>
          <MetricName isComposite={isComposite}>{name}</MetricName>
        </Link>
      ) : (
        <MetricName isComposite={isComposite}>{name}</MetricName>
      )}
      <MetricParticipation>
        {sampleSize} (of {sampleSizeTotal}) responses
      </MetricParticipation>
    </ReactTableTd>
  );
};

export const TdRatedPositively = ({ cell }) => {
  const {
    isExpanded,
    original: { isEquityGap },
  } = cell.row;

  return (
    <ReactTableTd cell={cell}>
      <BarChartRatedPositively
        label="Rated Positively on Last Survey"
        Icon={EquityGapIcon}
        iconActive={isEquityGap}
        iconLabel="Equity gap compared to 'all' group."
        value={cell.value}
        color={isExpanded ? theme.colors.secondary : theme.colors.primary}
      />
    </ReactTableTd>
  );
};

export const TdFidelityRatedPositively = ({ cell }) => {
  const threshold = FIDELITY_THRESHOLD / 100;
  const isBelowThreshold = cell.value < threshold;

  return (
    <ReactTableTd cell={cell}>
      <BarChartRatedPositively
        label="Rated Positively on Last Survey"
        threshold={threshold}
        Icon={FidelityWarningIcon}
        iconActive={isBelowThreshold}
        iconLabel="Below threshold"
        value={cell.value}
        color={isBelowThreshold ? theme.colors.yellow : theme.colors.greenDark}
      />
    </ReactTableTd>
  );
};

export const TdChangeFromStart = ({ cell }) => (
  <ReactTableTd cell={cell}>
    <ChangeText data={cell.value * 100}>
      {formatPercentagePoints(cell.value)}
    </ChangeText>
  </ReactTableTd>
);
