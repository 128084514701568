import { useContext } from 'react';
import { Formik, Form } from 'formik';
import { Group } from 'models';
import { Button, Col, Row, Modal, useFormError } from '@perts/ui';

import TermsContext from 'components/TermsContext';

export type GroupsArchiveFormValues = {
  group: Group;
};

export type GroupsArchiveFormProps = {
  close: () => void;
  onSubmit: (values: GroupsArchiveFormValues) => void;
  group: Group;
};

export const GroupsArchiveForm = ({
  close,
  onSubmit,
  group,
}: GroupsArchiveFormProps) => {
  const [FormError, showFormError] = useFormError();
  const terms = useContext(TermsContext);
  const termArchive = group.archived ? 'Unarchive' : 'Archive';

  return (
    <Modal close={close}>
      <Modal.Title className="center">
        {termArchive} {terms.group}
      </Modal.Title>

      <Formik
        enableReinitialize={true}
        initialValues={{
          group,
        }}
        onSubmit={async (values) => {
          try {
            // Clear existing form error.
            showFormError(false);

            // Perform form onSubmit.
            await onSubmit(values);

            // Close modal on successful form onSubmit.
            close();
          } catch (error) {
            // Display form error.
            showFormError(true);
          }
        }}
      >
        {({ isSubmitting, isValid, values }) => (
          <Form>
            <Row>
              <Col>
                Are you sure you'd like to {termArchive.toLowerCase()} this{' '}
                {terms.group}?
              </Col>
            </Row>

            <Row>
              <Col>
                <FormError />
              </Col>
            </Row>

            <Row>
              <Col cols={6} colsSm={12}>
                <Button
                  fullHeight
                  type="button"
                  color="secondary"
                  fullWidth
                  onClick={close}
                  disabled={isSubmitting}
                >
                  Cancel
                </Button>
              </Col>

              <Col cols={6} colsSm={12}>
                <Button
                  type="submit"
                  fullWidth
                  disabled={!isValid || isSubmitting}
                  loading={isSubmitting}
                >
                  {termArchive} {terms.group}
                </Button>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
