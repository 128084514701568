import { ROSTER_ID_MAX_LENGTH, ROSTER_ID_MIN_LENGTH } from 'config';

// Parses participant_ending into min and max settings for student ID sign-in
// options. See SettingsRosterIdRuleFields.
export const splitMinMax = (participant_ending: string | null) => {
  const [min, max] = (participant_ending || '').split(',');
  return [
    parseInt(min, 10) || ROSTER_ID_MIN_LENGTH,
    parseInt(max, 10) || ROSTER_ID_MAX_LENGTH,
  ];
};
