import { getMessageFromErrors } from '@perts/util';
import {
  Col,
  Link,
  Modal,
  QRCodeImage,
  QRCodeDownloadButton,
  Row,
  Text,
} from '@perts/ui';

import { ErrorMessageBox, Loading } from 'components';
import { useClassesQueryByParams } from 'models';
import { useCloseModal, useGetCheckedStates } from 'utils';

type ModalWrapperProps = {
  close: () => void;
  children: React.ReactNode;
};

export const ModalWrapper = ({ children, close }: ModalWrapperProps) => (
  <Modal close={close}>
    <Modal.Title className="center">Survey QR Code</Modal.Title>
    {children}
  </Modal>
);

export const SurveyQRCode = () => {
  const checked = useGetCheckedStates();
  const closeModal = useCloseModal();

  const {
    data: classes = [],
    error: classesError,
    isLoading: classesIsLoading,
    isSuccess: classesIsSuccess,
  } = useClassesQueryByParams();

  if (classesIsLoading) {
    return (
      <ModalWrapper close={closeModal}>
        <Loading />
      </ModalWrapper>
    );
  }

  const cls = classes.find((c) => c.uid in checked);

  if (!classesIsSuccess || !cls) {
    return (
      <ModalWrapper close={closeModal}>
        <ErrorMessageBox>
          {getMessageFromErrors([classesError])}
        </ErrorMessageBox>
      </ModalWrapper>
    );
  }

  const participantLink = `https://perts.me/${cls.code}`;
  const participantLinkText = `perts.me/${cls.code}`;
  const fileName = `PERTS survey link - ${cls.name} - code ${cls.code}`;

  return (
    <ModalWrapper close={closeModal}>
      <Row>
        <Col hAlign="center">
          <Text as="h3">{cls.name}</Text>
        </Col>
      </Row>
      <Row>
        <Col hAlign="center">
          <div>
            <QRCodeImage url={participantLink} />
            <br />
            <Link to={participantLink}>{participantLinkText}</Link>
          </div>
        </Col>
      </Row>
      <Row>
        <Col hAlign="center">
          <QRCodeDownloadButton url={participantLink} fileName={fileName}>
            Download
          </QRCodeDownloadButton>
        </Col>
      </Row>
    </ModalWrapper>
  );
};
