import { useHistory, useParams } from 'react-router-dom';
import { useQueryClient, useMutation } from 'react-query';
import { message } from 'antd';

import {
  networksAddToProgram,
  Network,
  queryKeyNetworks,
  useProgramGetByParams,
} from 'models';
import { toHome, PagesParams, toNetworkMembers } from 'pages';
import { useCloseModal } from 'utils';

import { NetworksAddForm, NetworksAddFormValues } from './NetworksAddForm';
import Loading from 'components/Loading';
import { ErrorMessageBox } from 'components/ErrorMessageBox';
import { getMessageFromErrors } from '@perts/util';

export const NetworksAdd = () => {
  const history = useHistory();
  const { programId } = useParams<PagesParams>();
  const queryClient = useQueryClient();

  // Query for Programs.
  const {
    isLoading: programIsLoading,
    isError: programIsError,
    data: program,
    error: programError,
  } = useProgramGetByParams();

  // Close the modal.
  const closeModal = useCloseModal({
    pathname: toHome(programId),
    ignoreEmptyState: true,
  });

  // Mutation: Add network to program.
  const mutation = useMutation(
    ({ name }: NetworksAddFormValues) => {
      if (!program) {
        throw new Error('Program required to add network.');
      }

      return networksAddToProgram(name, program.uid);
    },
    {
      // Handle successful mutation.
      onSuccess: (data) => {
        message.success('Successfully created the network.');

        const previousValue =
          queryClient.getQueryData<Network[]>(queryKeyNetworks()) || [];

        const updatedValue = [data, ...previousValue].sort((a, b) =>
          a.name > b.name ? 1 : -1,
        );

        queryClient.setQueryData(queryKeyNetworks(), updatedValue);

        history.push(toNetworkMembers(data.short_uid));
      },
    },
  );

  if (programIsLoading) {
    return <Loading />;
  }

  if (programIsError) {
    return (
      <ErrorMessageBox>{getMessageFromErrors([programError])}</ErrorMessageBox>
    );
  }

  const onSubmit = async (values: NetworksAddFormValues) => {
    await mutation.mutateAsync(values);
  };

  return <NetworksAddForm close={closeModal} onSubmit={onSubmit} />;
};
