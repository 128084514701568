// https://docs.google.com/spreadsheets/d/1EKKzZjI-eCtekB_S_SsNnBQpGEKVIKueZIkBD6W8QJc

import keyBy from 'lodash/keyBy';

import { locales } from '../i18n';
import { CompositeMetricConfig, MetricItemConfig } from './types';

export const attitudesTowardsStudentsItemConfig: MetricItemConfig[] = [
  {
    label: 'attitudes_towards_students_bad',
    likertN: 6,
    likertReverse: true,
    minGood: 5,
    prompts: {
      [locales.ENGLISH]: `Students come to school with bad attitudes.`,
    },
  },
  {
    label: 'attitudes_towards_students_ready',
    likertN: 6,
    minGood: 5,
    prompts: {
      [locales.ENGLISH]: `Most students come to school ready to learn.`,
    },
  },
];

export const attitudesTowardsStudentsConfig: CompositeMetricConfig = {
  label: 'attitudes-towards-students',
  items: attitudesTowardsStudentsItemConfig,
  itemIndex: keyBy(attitudesTowardsStudentsItemConfig, 'label'),
};
