import { pick } from 'lodash';

import { Results, isTeamResults } from '@perts/model';

import { Metric } from 'models';

export const removeDisabledMetricsFromResults = <T extends Results>(
  results: T,
  enabledMetrics: Metric[],
): T => {
  const enabledLabels = enabledMetrics.map((metric) => metric.label);

  return {
    ...results,
    experience: pick(results.experience, enabledLabels),
    open_responses: isTeamResults(results)
      ? pick(results.open_responses, enabledLabels)
      : undefined,
    sample: {
      ...results.sample,
      by_metric: pick(results.sample.by_metric, enabledLabels),
    },
  };
};
