import { Col, Row } from '@perts/ui';

const VerifyEmailSent = ({ email }) => (
  <div data-test="VerifyEmailSent">
    <Row>
      <Col>
        <h2>Verification Link Sent!</h2>
        <p>
          We just sent an email to you at <strong>{email}</strong>. It contains
          a link that will verify your email address.
        </p>
      </Col>
    </Row>
    <Row>
      <Col>
        <p>You may close this screen.</p>
      </Col>
    </Row>
  </div>
);

export default VerifyEmailSent;
