// https://docs.google.com/spreadsheets/d/1EKKzZjI-eCtekB_S_SsNnBQpGEKVIKueZIkBD6W8QJc

import keyBy from 'lodash/keyBy';

import { locales } from '../i18n';
import { CompositeMetricConfig, MetricItemConfig } from './types';

const options = {
  [locales.ENGLISH]: [
    // NOTE: values are reversed.
    {
      label: 'Never',
      value: 5,
    },
    {
      label: 'Rarely',
      value: 4,
    },
    {
      label: 'Sometimes',
      value: 3,
    },
    {
      label: 'Often',
      value: 2,
    },
    {
      label: 'Always',
      value: 1,
    },
  ],
};

export const teacherBurnoutItemConfig: MetricItemConfig[] = [
  {
    label: 'teacher_burnout_ed',
    prompts: {
      [locales.ENGLISH]: `I feel emotionally drained from my work.`,
    },
    options,
    minGood: 4,
  },
  {
    label: 'teacher_burnout_bo',
    prompts: {
      [locales.ENGLISH]: `I feel burned out from my work.`,
    },
    options,
    minGood: 4,
  },
  {
    label: 'teacher_burnout_frustrated',
    prompts: {
      [locales.ENGLISH]: `I feel frustrated with my job.`,
    },
    options,
    minGood: 4,
  },
];

export const teacherBurnoutConfig: CompositeMetricConfig = {
  label: 'teacher-burnout',
  items: teacherBurnoutItemConfig,
  itemIndex: keyBy(teacherBurnoutItemConfig, 'label'),
};
