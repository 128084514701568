import React from 'react';

import { Show } from '../../';
import { CardContent } from './CardContent';
import { CardStyled, CardGutterStyled } from './CardStyled';
import { CardTitle } from './CardTitle';
import { CardTitleWrapStyled } from './CardTitleWrapStyled';
import { CardContentWrapStyled } from './CardContentWrapStyled';
import { CardStatusIcon } from './CardStatusIcon';

export type CardProps = {
  // The content to be rendered.
  children: React.ReactNode;

  // JSX of toggle component. This is compatible with the Toggle component that
  // is supplied by our useToggles hook.
  toggle?: React.ReactNode;

  checked?: boolean;

  archived?: boolean;

  /**
   * `true` if the Card should be highlighted.
   */
  highlight?: boolean;

  /**
   * `true` if the highlight Card is a child of another Card.
   */
  highlightWithin?: boolean;
};

export const Card = ({
  archived,
  children,
  toggle = null,
  checked = false,
  highlight = false,
  highlightWithin = false,
}: CardProps) => {
  return (
    <CardStyled
      data-testid="Card"
      checked={checked}
      archived={archived}
      highlight={highlight}
      highlightWithin={highlightWithin}
    >
      {React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
          // Card.StatusIcon

          if (child.type === CardStatusIcon) {
            return React.cloneElement(child);
          }

          // Card.Title

          if (child.type === CardTitle) {
            return (
              <CardTitleWrapStyled>
                <Show when={Boolean(toggle)}>
                  <CardGutterStyled>{toggle}</CardGutterStyled>
                </Show>
                {React.cloneElement(child)}
              </CardTitleWrapStyled>
            );
          }

          // Card.Content

          if (child.type === CardContent) {
            return (
              <CardContentWrapStyled>
                <Show when={Boolean(toggle)}>
                  <CardGutterStyled>&nbsp;</CardGutterStyled>
                </Show>
                {React.cloneElement(child)}
              </CardContentWrapStyled>
            );
          }
        }

        return null;
      })}
    </CardStyled>
  );
};
