import {
  NetworkData,
  OrganizationData,
  OrganizationUserData,
  TeamData,
} from '@perts/model';

import { TRITON_URL_PREFIX } from './config';
import { fetchOptionsGET, fetchApi } from './helpers';

export const get = (datasetId: string): Promise<any> => {
  const options = fetchOptionsGET();
  const url = `${TRITON_URL_PREFIX}/api/datasets/${datasetId}`;
  return fetchApi(url, options);
};

export const getNetwork = (networkId: string): Promise<NetworkData> => {
  const options = fetchOptionsGET();
  const url = `${TRITON_URL_PREFIX}/api/networks/${networkId}/datasets/network`;
  return fetchApi(url, options);
};

export const getOrganization = (orgId: string): Promise<OrganizationData> => {
  const options = fetchOptionsGET();
  const url =
    `${TRITON_URL_PREFIX}/api/organizations/${orgId}/` +
    `datasets/organization`;
  return fetchApi(url, options);
};

export const getOrganizationUser = (
  orgId: string,
  userId: string,
): Promise<OrganizationUserData> => {
  const options = fetchOptionsGET();
  const url =
    `${TRITON_URL_PREFIX}/api/users/${userId}/organizations/${orgId}/` +
    `datasets/organizationUser`;
  return fetchApi(url, options);
};

export const getTeam = (teamId: string): Promise<TeamData> => {
  const options = fetchOptionsGET();
  const url = `${TRITON_URL_PREFIX}/api/teams/${teamId}/datasets/team`;
  return fetchApi(url, options);
};
