import {
  Group,
  queryKeyGroup,
  updateGroup,
  useMutationUpdate,
  useGroupGetByParams,
} from 'models';
import { useParams } from 'pages';

import { GroupReportLoader } from '../GroupReportDefault21';
import { ReportSettingsEnabledMetricsForm } from '../components';

export const GroupReportSettings = () => {
  const { groupId } = useParams();
  const groupQuery = useGroupGetByParams();

  const queryKey = queryKeyGroup(groupId);
  const mutation = useMutationUpdate<Group, Group>(updateGroup, queryKey, {
    optimisticallyUpdate: false,
    transformFormValues: (formValues) => {
      if (!groupQuery.isSuccess) {
        throw new Error('Group data not loaded.');
      }
      const group = groupQuery.data;
      const enabledIds = Object.entries(formValues)
        .filter(([metricId, isChecked]) => isChecked)
        .map(([metricId]) => metricId);
      return {
        ...group,
        report_settings: {
          ...group?.report_settings,
          enabled_metrics: enabledIds,
        },
      };
    },
    toastSuccessMsg: `Successfully updated report settings.`,
    toastErrorMsg: `There was a problem updating report settings.`,
  });

  const onSubmit = async (formValues) => {
    await mutation.mutateAsync(formValues);
  };

  const metricSets = []; // need to load this?

  return (
    <GroupReportLoader>
      {({
        dataset,
        group,
        program,
        programMetrics,
        reportDate,
        reportMetrics,
      }) => (
        <ReportSettingsEnabledMetricsForm
          onSubmit={onSubmit}
          enabledMetricIds={group.report_settings.enabled_metrics}
          reportMetrics={reportMetrics}
          metricSets={metricSets}
        />
      )}
    </GroupReportLoader>
  );
};
