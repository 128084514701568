import React, { useMemo, useState } from 'react';
import { Column } from 'react-table';

import { channelExpandAll } from 'config';
import { Table } from './Table';
import { StudentExperienceChildRow } from 'models';
import { sortTableRowNumeric } from 'utils';
import {
  accessorRatedPositively,
  cellChange,
  cellRatedPositively,
  cellRatedPositivelyGaps,
} from '../components';
import { useTerms } from 'components/TermsContext';
import { useBroadcast } from '@perts/ui';

type Props = {
  data: StudentExperienceChildRow[];
  reportDate: string;
};

export const StudentExperienceChildTable = ({ data, reportDate }: Props) => {
  const terms = useTerms();
  const [showChange, setShowChange] = useState(false);
  const { listen } = useBroadcast(channelExpandAll);
  listen((shouldExpand) => setShowChange(shouldExpand));

  let useTargetGroup =
    'rated_positive_recent_target_group' in data[0] &&
    'rated_positive_change_target_group' in data[0];
  let useFinStress =
    'rated_positive_recent_high_financial_stress' in data[0] &&
    'rated_positive_change_high_financial_stress' in data[0];

  if (useTargetGroup === useFinStress) {
    console.error(
      'StudentExperienceOverviewTable: data corrupt, target group and ' +
        'financial stress columns not as expected.',
    );
    useTargetGroup = true;
    useFinStress = false;
  }

  const accessor = (key) => `rated_positive_${key}_num`;

  const columns = useMemo(() => {
    const cols = [
      {
        Header: <>{terms.group}</>,
        id: 'child_name',
        accessor: 'child_name',
      },
      // These columns render the first arg, color based on a comparison
      // to the second arg, and toggle additional change data.
      {
        Header: (
          <>
            Girl/
            <wbr />
            Woman
          </>
        ),
        id: 'female',
        Cell: cellRatedPositivelyGaps('female', 'male', showChange),
        accessor: accessorRatedPositively('female'),
      },
      {
        Header: (
          <>
            Boy/
            <wbr />
            Man
          </>
        ),
        id: 'male',
        Cell: cellRatedPositivelyGaps('male', 'female', showChange),
        accessor: accessorRatedPositively('male'),
      },
      {
        Header: 'Struct. Disadv. Race',
        id: 'disadv_race',
        Cell: cellRatedPositivelyGaps('disadv_race', 'adv_race', showChange),
        accessor: accessorRatedPositively('disadv_race'),
      },
      {
        Header: 'Struct. Adv. Race',
        id: 'adv_race',
        Cell: cellRatedPositivelyGaps('adv_race', 'disadv_race', showChange),
        accessor: accessorRatedPositively('adv_race'),
      },
    ];

    if (useTargetGroup) {
      cols.push({
        Header: 'Focal Group',
        id: 'focal_group',
        Cell: cellRatedPositivelyGaps(
          'target_group',
          'not_target_group',
          showChange,
        ),
        accessor: accessorRatedPositively('target_group'),
      });
      cols.push({
        Header: 'Not Focal Group',
        id: 'not_focal_group',
        Cell: cellRatedPositivelyGaps(
          'not_target_group',
          'target_group',
          showChange,
        ),
        accessor: accessorRatedPositively('not_target_group'),
      });
    }
    if (useFinStress) {
      cols.push({
        Header: 'High Fin. Stress',
        id: 'high_financial_stress',
        Cell: cellRatedPositivelyGaps(
          'high_financial_stress',
          'low_financial_stress',
          showChange,
        ),
        accessor: accessorRatedPositively('high_financial_stress'),
      });
      cols.push({
        Header: 'Low Fin. Stress',
        id: 'low_financial_stress',
        Cell: cellRatedPositivelyGaps(
          'low_financial_stress',
          'high_financial_stress',
          showChange,
        ),
        accessor: accessorRatedPositively('low_financial_stress'),
      });
    }

    cols.push({
      Header: 'All',
      id: 'all',
      Cell: cellRatedPositively('all', showChange),
      accessor: accessorRatedPositively('all'),
    });

    return cols;
  }, [terms.group, showChange, useTargetGroup, useFinStress]);

  const columnsWithChange = useMemo(() => {
    const cols: Column[] = [
      {
        Header: <>{terms.group}</>,
        id: 'child_name',
        accessor: 'child_name',
      },
      {
        Header: (
          <>
            Girl/
            <wbr />
            Woman
          </>
        ),
        id: 'female',
        columns: [
          {
            Header: '%',
            id: 'recent_female',
            Cell: cellRatedPositivelyGaps('female', 'male', false),
            accessor: accessor('recent_female'),
          },
          {
            Header: '±',
            id: 'change_female',
            Cell: cellChange('female'),
            accessor: accessor('change_female'),
            sortType: sortTableRowNumeric(accessor('change_female')),
          },
        ],
      },
      {
        Header: (
          <>
            Boy/
            <wbr />
            Man
          </>
        ),
        id: 'male',
        columns: [
          {
            Header: '%',
            id: 'recent_male',
            Cell: cellRatedPositivelyGaps('male', 'female', false),
            accessor: accessor('recent_male'),
          },
          {
            Header: '±',
            id: 'change_male',
            Cell: cellChange('male'),
            accessor: accessor('change_male'),
            sortType: sortTableRowNumeric(accessor('change_male')),
          },
        ],
      },
      {
        Header: 'Struct. Disadv. Race',
        id: 'disadv_race',
        columns: [
          {
            Header: '%',
            id: 'recent_disadv_race',
            Cell: cellRatedPositivelyGaps('disadv_race', 'adv_race', false),
            accessor: accessor('recent_disadv_race'),
          },
          {
            Header: '±',
            id: 'change_disadv_race',
            Cell: cellChange('disadv_race'),
            accessor: accessor('change_disadv_race'),
            sortType: sortTableRowNumeric(accessor('change_disadv_race')),
          },
        ],
      },
      {
        Header: 'Struct. Adv. Race',
        id: 'adv_race',
        columns: [
          {
            Header: '%',
            id: 'recent_adv_race',
            Cell: cellRatedPositivelyGaps('adv_race', 'disadv_race', false),
            accessor: accessor('recent_adv_race'),
          },
          {
            Header: '±',
            id: 'change_adv_race',
            Cell: cellChange('adv_race'),
            accessor: accessor('change_adv_race'),
            sortType: sortTableRowNumeric(accessor('change_adv_race')),
          },
        ],
      },
    ];

    if (useTargetGroup) {
      cols.push({
        Header: 'Focal Group',
        id: 'focal_group',
        columns: [
          {
            Header: '%',
            id: 'recent_target_group',
            Cell: cellRatedPositivelyGaps(
              'target_group',
              'not_target_group',
              false,
            ),
            accessor: accessor('recent_target_group'),
          },
          {
            Header: '±',
            id: 'change_target_group',
            Cell: cellChange('target_group'),
            accessor: accessor('change_target_group'),
            sortType: sortTableRowNumeric(accessor('change_target_group')),
          },
        ],
      });
      cols.push({
        Header: 'Not Focal Group',
        id: 'not_focal_group',
        columns: [
          {
            Header: '%',
            id: 'recent_not_target_group',
            Cell: cellRatedPositivelyGaps(
              'not_target_group',
              'target_group',
              false,
            ),
            accessor: accessor('recent_not_target_group'),
          },
          {
            Header: '±',
            id: 'change_not_target_group',
            Cell: cellChange('not_target_group'),
            accessor: accessor('change_not_target_group'),
            sortType: sortTableRowNumeric(accessor('change_not_target_group')),
          },
        ],
      });
    }
    if (useFinStress) {
      cols.push({
        Header: 'High Fin. Stress',
        id: 'high_financial_stress',
        columns: [
          {
            Header: '%',
            id: 'recent_high_financial_stress',
            Cell: cellRatedPositivelyGaps(
              'high_financial_stress',
              'low_financial_stress',
              false,
            ),
            accessor: accessor('recent_high_financial_stress'),
          },
          {
            Header: '±',
            id: 'change_high_financial_stress',
            Cell: cellChange('high_financial_stress'),
            accessor: accessor('change_high_financial_stress'),
            sortType: sortTableRowNumeric(
              accessor('change_high_financial_stress'),
            ),
          },
        ],
      });
      cols.push({
        Header: 'Low Fin. Stress',
        id: 'low_financial_stress',
        columns: [
          {
            Header: '%',
            id: 'recent_low_financial_stress',
            Cell: cellRatedPositivelyGaps(
              'low_financial_stress',
              'high_financial_stress',
              false,
            ),
            accessor: accessor('recent_low_financial_stress'),
          },
          {
            Header: '±',
            id: 'change_low_financial_stress',
            Cell: cellChange('low_financial_stress'),
            accessor: accessor('change_low_financial_stress'),
            sortType: sortTableRowNumeric(
              accessor('change_low_financial_stress'),
            ),
          },
        ],
      });
    }

    cols.push({
      Header: 'All',
      id: 'all',
      columns: [
        {
          Header: '%',
          id: 'recent_all',
          Cell: ({ row }) => row.original.rated_positive_recent_all,
          accessor: accessor('recent_all'),
        },
        {
          Header: '±',
          id: 'change_all',
          Cell: cellChange('all'),
          accessor: accessor('change_all'),
          sortType: sortTableRowNumeric(accessor('change_all')),
        },
      ],
    });

    return cols;
  }, [terms.group, useTargetGroup, useFinStress]);

  return (
    <Table
      columns={showChange ? columnsWithChange : columns}
      data={data}
      reportDate={reportDate}
      showChange={showChange}
      setShowChange={setShowChange}
    />
  );
};
