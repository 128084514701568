// fn to turn names into one string, e.g. ['Foo Bar', 'Baz Qux'] into
// 'Bar, Foo; Qux, Baz'
export const concatNames = (names: string[]) =>
  names
    .map((fullName) => {
      if (fullName.includes(' ')) {
        const [lastName, ...restReversed] = fullName.split(' ').reverse();
        return `${lastName}, ${restReversed.reverse().join(' ')}`;
      }
      return fullName;
    })
    .sort()
    .join('; ');
