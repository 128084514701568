import React, { useCallback, useMemo, useState } from 'react';

import { channelExpandAll } from 'config';
import Table from './Table';
import { useTerms } from 'components/TermsContext';
import { Metric, StudentExperienceOverviewRow } from 'models';
import { sortTableRowNumeric } from 'utils';
import { cellRatedPositivelyGaps, cellChange } from '../components';
import { useBroadcast } from '@perts/ui';

const standardizeLabel = (label: string) => label.replace(/_/g, '-');

const findMetric = (metrics: Metric[], label: string): Metric | undefined =>
  metrics.find((m) => standardizeLabel(m.label) === standardizeLabel(label));

type Props = {
  data: StudentExperienceOverviewRow[];
  metrics: Metric[];
  nResponded: string;
  pctResponded: string;
  reportDate: string;
};

export const StudentExperienceTable = ({
  data,
  metrics,
  nResponded,
  pctResponded,
  reportDate,
}: Props) => {
  const [showChange, setShowChange] = useState(false);
  const { listen } = useBroadcast(channelExpandAll);
  listen((shouldExpand) => setShowChange(shouldExpand));

  const terms = useTerms();

  let useTargetGroup =
    'rated_positive_recent_target_group' in (data[0] || []) &&
    'rated_positive_change_target_group' in (data[0] || []);
  let useFinStress =
    'rated_positive_recent_high_financial_stress' in (data[0] || []) &&
    'rated_positive_change_high_financial_stress' in (data[0] || []);

  if (useTargetGroup === useFinStress) {
    console.error(
      'StudentExperienceOverviewTable: data corrupt, target group and ' +
        'financial stress columns not as expected.',
    );
    useTargetGroup = true;
    useFinStress = false;
  }

  // react-table Cell function to display the readable metric name/link.
  const metricName = useCallback(
    (props: any) => {
      const { metric_label } = props.row.original;
      return (
        <a href={`#${standardizeLabel(metric_label)}-data`}>
          {(findMetric(metrics, metric_label) || {}).name}
        </a>
      );
    },
    [metrics],
  );

  const accessor = (key) => `rated_positive_${key}_num`;

  const columns = useMemo(() => {
    const cols = [
      {
        Header: terms.condition,
        id: 'metric_label',
        accessor: 'metric_label',
        Cell: metricName,
      },
      // These columns render the first arg, color based on a comparison
      // to the second arg, and toggle additional change data.
      {
        Header: (
          <>
            Girl/
            <wbr />
            Woman
          </>
        ),
        id: 'female',
        Cell: cellRatedPositivelyGaps('female', 'male', showChange),
        accessor: accessor('recent_female'),
      },
      {
        Header: (
          <>
            Boy/
            <wbr />
            Man
          </>
        ),
        id: 'male',
        Cell: cellRatedPositivelyGaps('male', 'female', showChange),
        accessor: accessor('recent_male'),
      },
      {
        Header: 'Struct. Disadv. Race',
        id: 'disadv_race',
        Cell: cellRatedPositivelyGaps('disadv_race', 'adv_race', showChange),
        accessor: accessor('recent_disadv_race'),
      },
      {
        Header: 'Struct. Adv. Race',
        id: 'adv_race',
        Cell: cellRatedPositivelyGaps('adv_race', 'disadv_race', showChange),
        accessor: accessor('recent_adv_race'),
      },
    ];

    if (useTargetGroup) {
      cols.push({
        Header: 'Focal Group',
        id: 'focal_group',
        Cell: cellRatedPositivelyGaps(
          'target_group',
          'not_target_group',
          showChange,
        ),
        accessor: accessor('recent_target_group'),
      });
      cols.push({
        Header: 'Not Focal Group',
        id: 'not_focal_group',
        Cell: cellRatedPositivelyGaps(
          'not_target_group',
          'target_group',
          showChange,
        ),
        accessor: accessor('recent_not_target_group'),
      });
    }
    if (useFinStress) {
      cols.push({
        Header: 'High Fin. Stress',
        id: 'high_financial_stress',
        Cell: cellRatedPositivelyGaps(
          'high_financial_stress',
          'low_financial_stress',
          showChange,
        ),
        accessor: accessor('recent_high_financial_stress'),
      });
      cols.push({
        Header: 'Low Fin. Stress',
        id: 'low_financial_stress',
        Cell: cellRatedPositivelyGaps(
          'low_financial_stress',
          'high_financial_stress',
          showChange,
        ),
        accessor: accessor('recent_low_financial_stress'),
      });
    }
    return cols;
  }, [metricName, showChange, terms.condition, useTargetGroup, useFinStress]);

  const columnsWithChange = useMemo(() => {
    const cols = [
      {
        Header: terms.condition,
        id: 'metric_label',
        accessor: 'metric_label',
        Cell: metricName,
      },
      {
        Header: (
          <>
            Girl/
            <wbr />
            Woman
          </>
        ),
        id: 'female',
        columns: [
          {
            Header: '%',
            id: 'recent_female',
            Cell: cellRatedPositivelyGaps('female', 'male', false),
            accessor: accessor('recent_female'),
          },
          {
            Header: '±',
            id: 'change_female',
            Cell: cellChange('female'),
            accessor: accessor('change_female'),
            sortType: sortTableRowNumeric(accessor('change_female')),
          },
        ],
      },
      {
        Header: (
          <>
            Boy/
            <wbr />
            Man
          </>
        ),
        id: 'male',
        columns: [
          {
            Header: '%',
            id: 'recent_male',
            Cell: cellRatedPositivelyGaps('male', 'female', false),
            accessor: accessor('recent_male'),
          },
          {
            Header: '±',
            id: 'change_male',
            Cell: cellChange('male'),
            accessor: accessor('change_male'),
            sortType: sortTableRowNumeric(accessor('change_male')),
          },
        ],
      },
      {
        Header: 'Struct. Disadv. Race',
        id: 'disadv',
        columns: [
          {
            Header: '%',
            id: 'recent_disadv_race',
            Cell: cellRatedPositivelyGaps('disadv_race', 'adv_race', false),
            accessor: accessor('recent_disadv_race'),
          },
          {
            Header: '±',
            id: 'change_disadv_race',
            Cell: cellChange('disadv_race'),
            accessor: accessor('change_disadv_race'),
            sortType: sortTableRowNumeric(accessor('change_disadv_race')),
          },
        ],
      },
      {
        Header: 'Struct. Adv. Race',
        id: 'adv',
        columns: [
          {
            Header: '%',
            id: 'recent_adv_race',
            Cell: cellRatedPositivelyGaps('adv_race', 'disadv_race', false),
            accessor: accessor('recent_adv_race'),
          },
          {
            Header: '±',
            id: 'change_adv_race',
            Cell: cellChange('adv_race'),
            accessor: accessor('change_adv_race'),
            sortType: sortTableRowNumeric(accessor('change_adv_race')),
          },
        ],
      },
    ];

    if (useTargetGroup) {
      // Focal Group
      cols.push({
        Header: 'Focal Group',
        id: 'focal_group',
        columns: [
          {
            Header: '%',
            id: 'recent_target_group',
            Cell: cellRatedPositivelyGaps(
              'target_group',
              'not_target_group',
              false,
            ),
            accessor: accessor('recent_target_group'),
          },
          {
            Header: '±',
            id: 'change_target_group',
            Cell: cellChange('target_group'),
            accessor: accessor('change_target_group'),
            sortType: sortTableRowNumeric(accessor('change_target_group')),
          },
        ],
      });
      cols.push({
        Header: 'Not Focal Group',
        id: 'not_focal_group',
        columns: [
          {
            Header: '%',
            id: 'recent_not_target_group',
            Cell: cellRatedPositivelyGaps(
              'not_target_group',
              'target_group',
              false,
            ),
            accessor: accessor('recent_not_target_group'),
          },
          {
            Header: '±',
            id: 'change_not_target_group',
            Cell: cellChange('not_target_group'),
            accessor: accessor('change_not_target_group'),
            sortType: sortTableRowNumeric(accessor('change_not_target_group')),
          },
        ],
      });
    }
    if (useFinStress) {
      cols.push({
        Header: 'High Fin. Stress',
        id: 'high_financial_stress',
        columns: [
          {
            Header: '%',
            id: 'recent_high_financial_stress',
            Cell: cellRatedPositivelyGaps(
              'high_financial_stress',
              'low_financial_stress',
              false,
            ),
            accessor: accessor('recent_high_financial_stress'),
          },
          {
            Header: '±',
            id: 'change_high_financial_stress',
            Cell: cellChange('high_financial_stress'),
            accessor: accessor('change_high_financial_stress'),
            sortType: sortTableRowNumeric(
              accessor('change_high_financial_stress'),
            ),
          },
        ],
      });
      cols.push({
        Header: 'Low Fin. Stress',
        id: 'low_financial_stress',
        columns: [
          {
            Header: '%',
            id: 'recent_low_financial_stress',
            Cell: cellRatedPositivelyGaps(
              'low_financial_stress',
              'high_financial_stress',
              false,
            ),
            accessor: accessor('recent_low_financial_stress'),
          },
          {
            Header: '±',
            id: 'change_low_financial_stress',
            Cell: cellChange('low_financial_stress'),
            accessor: accessor('change_low_financial_stress'),
            sortType: sortTableRowNumeric(
              accessor('change_low_financial_stress'),
            ),
          },
        ],
      });
    }
    return cols;
  }, [metricName, terms.condition, useTargetGroup, useFinStress]);

  return (
    <Table
      columns={showChange ? columnsWithChange : columns}
      data={data}
      reportDate={reportDate}
      nResponded={nResponded}
      pctResponded={pctResponded}
      showChange={showChange}
      setShowChange={setShowChange}
    />
  );
};
