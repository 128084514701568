import * as Yup from 'yup';

import { PortalType, portalTypesWithEndings } from '@perts/model';

import { ROSTER_ID_MAX_LENGTH, ROSTER_ID_MIN_LENGTH } from 'config';

export const validationSchema = Yup.object().shape({
  studentEmailEnding: Yup.string().when(['ruleEnabled', 'portalType'], {
    is: (ruleEnabled, portalType) =>
      ruleEnabled && portalTypesWithEndings.includes(portalType),
    then: (schema) => schema.required('Required'),
  }),

  studentIdMin: Yup.number()
    // Asserts that studentIdMin has a min value of ROSTER_ID_MIN_LENGTH,
    // otherwise display "Too small".
    .min(ROSTER_ID_MIN_LENGTH, 'Too small')
    // Asserts that studentIdMin has a max value of studentIdMax (the min must
    // be less than or equal to the max), otherwise display "Too large".
    .max(Yup.ref('studentIdMax'), 'Too large')
    .when(['ruleEnabled', 'portalType'], {
      is: (ruleEnabled, portalType) =>
        ruleEnabled && [PortalType.STUDENT_ID].includes(portalType),
      then: (schema) => schema.required('Required'),
    }),

  studentIdMax: Yup.number()
    .min(Yup.ref('studentIdMin'), 'Too small')
    .max(ROSTER_ID_MAX_LENGTH, 'Too large')
    .when(['ruleEnabled', 'portalType'], {
      is: (ruleEnabled, portalType) =>
        ruleEnabled && [PortalType.STUDENT_ID].includes(portalType),
      then: (schema) => schema.required('Required'),
    }),

  portalMessage: Yup.string().when('ruleEnabled', {
    is: true,
    then: (schema) => schema.required('Required'),
  }),
});
