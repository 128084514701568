// Input provides the Formik useField values down the the `component`.
// https://formik.org/docs/api/useField#fieldinputpropsvalue
//
// Properties that are defined in InputProps also provide a way for us to
// document input properties that all input components must handle.
//
// Example usage:
//   <Input
//     id="email"
//     name="email"
//     label="Email Addresses"
//     labelPlacement="top"
//     component={TextField}
//   />

import React from 'react';
import {
  useField,
  FieldInputProps,
  FieldMetaProps,
  FieldHelperProps,
  useFormikContext,
} from 'formik';

// All input components will extend from BaseInputProps.
export type BaseInputProps = {
  // If true, the input will be displayed in a disabled state.
  disabled?: boolean;

  // The help text to be displayed.
  helpText?: React.ReactNode;

  // The id of the input element.
  id: string;

  // The text to be used in an enclosing label element.
  label: React.ReactNode;

  // The position of the label. When 'hidden', the label will still be screen
  // reader accessible.
  labelPlacement: 'start' | 'end' | 'top' | 'bottom' | 'hidden';

  // The name of the input element.
  name: string;

  // If true, the input will be displayed in a submitting state.
  submitting?: boolean;
};

export type UseFormikProps = FieldInputProps<any> &
  FieldMetaProps<any> &
  FieldHelperProps<any>;

export type BaseInputPropsWithFormik = BaseInputProps & UseFormikProps;

export type BaseInputPropsWithPartialFormik = BaseInputProps &
  Partial<UseFormikProps>;

export type InputProps = BaseInputProps & {
  // The input component to render.
  component: any;

  // Allow for specialized properties to be passed down to component.
  [prop: string]: any;
};

export const Input = ({ component: Component, name, ...rest }: InputProps) => {
  const { setFieldTouched, setFieldValue } = useFormikContext();
  const [field, meta, helpers] = useField(name);

  // Note that the `rest` props appear at the end so that custom
  // props like onChange override the defaults from useField

  return (
    <Component
      setFieldValue={setFieldValue}
      setFieldTouched={setFieldTouched}
      {...field}
      {...meta}
      {...helpers}
      {...rest}
    />
  );
};
