import React, { useState } from 'react';
import { FieldHelperProps } from 'formik';
import { BaseInputPropsWithPartialFormik, Chip, TextField } from '../../';
import { ChipsContainer } from './ChipsContainer';
import uniq from 'lodash/uniq';
import get from 'lodash/get';

export interface MultiSelectChipProps extends BaseInputPropsWithPartialFormik {
  // Function that is used to validate individual entries entered into the input
  // TextField. This occurs outside of the normal Formik validation flow because
  // we want to disallow invalid entries from ever becoming "chips" and so need
  // to disallow them from ever being added to this Field's value array.
  //
  // Similiar to Formik/Redux-Form's validate function, if invalid, return a
  // string containing the error message or return undefined.
  // https://formik.org/docs/api/field#validate
  // To handle custom validation we can to use
  // `customValidation` function to sync validations
  // or `asyncCustomValidation` function to async validations
  customValidation?: (item: string) => undefined | string;

  asyncCustomValidation?: (item: string) => Promise<undefined | string>;

  // The field's value.
  value: string[];

  // https://formik.org/docs/api/useField#fieldhelperprops
  // A function to change the field's value. This is the actual form value that
  // represent the array of Chips that will be displayed, not the temporary
  // TextField value.
  setValue: FieldHelperProps<any>['setValue'];

  // setFieldValue from formik form
  setFieldValue?: (field: string, value: any, shouldValidate?: boolean) => void;

  // If true the field is disabled.
  disabled?: boolean;

  // The id of the field.
  id: string;

  // Object to handle custom labels for every chip
  valueLabels?: { [key: string]: any };

  // Object to handle custom links for every chip
  valueLinks?: { [key: string]: any };
}

export const MultiSelectChip = ({
  id,
  customValidation,
  asyncCustomValidation,
  error,
  helpText,
  label,
  name,
  setValue,
  value: currentChips,
  setFieldValue,
  disabled,
  valueLabels = {},
  valueLinks = {},
  ...rest
}: MultiSelectChipProps) => {
  const [textFieldValue, setTextFieldValue] = useState<string>('');
  const [textFieldError, setTextFieldError] = useState<string>('');

  const splitValues = (value: string) => {
    const trimmedValue = value.replace(/\s{2,}/g, ' ').trim();
    // Separator pattern separates by comma, space or newline character (\n)
    const separatorPattern = /[, \n]/;
    const splittedValues = trimmedValue.split(separatorPattern);
    const notEmptyValues = splittedValues.filter((sv) => sv);
    return notEmptyValues;
  };

  const isInList = (valueToValidate: string) => {
    return currentChips.includes(valueToValidate);
  };

  const validateChips = (chips: string[]) => {
    let validationError;

    // Use .some to short-circuit as soon as we find a validation error.
    chips.some((chip) => {
      const customError =
        typeof customValidation === 'function' && customValidation(chip);

      if (customError) {
        validationError = customError;
        return true;
      }

      if (isInList(chip)) {
        validationError = `${chip} has already been added.`;
        return true;
      }

      return false;
    });

    return validationError;
  };

  const asyncValidateChips = async (chips: string[]) => {
    let validationError;

    for (let chipIndex = 0; chipIndex < chips.length; chipIndex++) {
      const chip = chips[chipIndex];
      const customError =
        asyncCustomValidation && (await asyncCustomValidation(chip));

      if (customError) {
        validationError = customError;
        break;
      }

      if (isInList(chip)) {
        validationError = `${chip} has already been added.`;
        break;
      }
    }

    return validationError;
  };

  const addChips = async (newChipsString = textFieldValue) => {
    const newChips = splitValues(newChipsString);
    let validationError;

    if (asyncCustomValidation) {
      validationError = await asyncValidateChips(newChips);
    } else {
      validationError = validateChips(newChips);
    }

    if (validationError) {
      setTextFieldError(validationError);
      return;
    }

    setValue([...currentChips, ...uniq(newChips)]);

    setTextFieldError('');
    setTextFieldValue('');
    if (setFieldValue) {
      const shouldValidate = false;
      setFieldValue('currentValue', '', shouldValidate);
    }
  };

  // Remove chip at `index` in `currentChips` array.
  // Assumption, the `index` exists in the `currentChips` array.
  const removeChip = (index: number) => {
    setValue([
      ...currentChips.slice(0, index),
      ...currentChips.slice(index + 1),
    ]);
  };

  const onChangeTextField = (event: React.FormEvent<HTMLInputElement>) => {
    setTextFieldValue(event.currentTarget.value);
    setTextFieldError('');
    if (setFieldValue) {
      setFieldValue('currentValue', event.currentTarget.value);
    }
  };

  const onKeyDownTextField = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (['Enter', ' ', ','].includes(event.key)) {
      event.preventDefault();
      addChips();
    }
  };

  const onPasteTextField = (event: React.ClipboardEvent<HTMLInputElement>) => {
    event.preventDefault();
    const clipboardData = event.clipboardData.getData('text');
    addChips(clipboardData);
  };

  return (
    <>
      <TextField
        {...rest}
        id={id}
        name={name}
        label={label}
        helpText={helpText}
        value={textFieldValue}
        error={textFieldError || error}
        onBlur={() => addChips()}
        onChange={onChangeTextField}
        onKeyDown={onKeyDownTextField}
        onPaste={onPasteTextField}
        disabled={disabled}
      />

      <ChipsContainer>
        {currentChips.map((chipLabel, index) => (
          <Chip
            key={chipLabel}
            label={get(valueLabels, chipLabel) || chipLabel}
            onDelete={() => removeChip(index)}
            to={get(valueLinks, chipLabel)}
          />
        ))}
      </ChipsContainer>
    </>
  );
};
