import React, { useState } from 'react';

import {
  Card,
  Col,
  Display,
  HelpText,
  Link,
  PageBreak,
  Row,
  Show,
  Text,
  getMetricLogo,
  useBroadcast,
} from '@perts/ui';
import { channelExpandAll, helpArticles } from 'config';
import {
  Metric,
  MetricData,
  MetricDataOpenResponses,
  MetricDataChildTable,
  // MetricDataTimelineTable, // Changed with Callisto, see import below.
  Network,
  selectMetricReadingLink,
} from 'models';

// old version of MetricDataTimelineTable
import type { MetricDataTimelineTable } from '../types';

import { HighlightedStat } from './HighlightedStat';
import { GraphWrapper } from './GraphWrapper';
import { StudentExperienceChildTable } from '../';
import { EquityGapLegend, NetworkTimeline } from '../components';
import { useTerms } from 'components/TermsContext';
import FakeLink from 'components/FakeLink';

const isMetricDataOpenResponses = (
  metricData: MetricData | MetricDataOpenResponses,
): metricData is MetricDataOpenResponses => 'open_responses' in metricData;

const isMetricDataChildTable = (
  metricData: MetricData | MetricDataChildTable,
): metricData is MetricDataChildTable =>
  'student_experience_child_table' in metricData;

const isMetricTimelineTable = (
  metricData: MetricData | MetricDataTimelineTable,
): metricData is MetricDataTimelineTable =>
  'timeline_charts_data' in metricData;

type Props = {
  metric: Metric;
  metricData: MetricData;
  network?: Network;
  reportDate?: string;
};

// eslint-disable-next-line complexity
export const MetricSection = ({
  metric,
  metricData,
  network,
  reportDate = '',
}: Props) => {
  const terms = useTerms();

  const [barGraphCollapsed, setBarGraphCollapsed] = useState(true);
  const [timelineCollapsed, setTimelineCollapsed] = useState(true);

  const { listen } = useBroadcast(channelExpandAll);
  listen((shouldExpand) => {
    setBarGraphCollapsed(!shouldExpand);
    setTimelineCollapsed(!shouldExpand);
  });

  const metricName = metric.name;
  const metricLabel = metric.label;
  const MetricLogo = getMetricLogo(metric.label);

  const showExpandableTimeline =
    !network &&
    metricData.timeline_chart_64 &&
    metricData.timeline_chart_by_question_64;
  const showNetworkTimeline =
    Boolean(network) && isMetricTimelineTable(metricData);
  const showTimeline = showExpandableTimeline || showNetworkTimeline;

  return (
    <section id={`${metricLabel}-data`}>
      <PageBreak />
      <Card>
        <Card.Title>
          <Row>
            <Col cols={8} hAlign="flex-start">
              <h2>
                <MetricLogo />
                {metricName} Detailed Results
              </h2>
            </Col>
            <Col cols={4} hAlign="flex-end" vAlign="center">
              <Link to={selectMetricReadingLink(metric)}>Learn strategies</Link>
            </Col>
          </Row>
        </Card.Title>
        <Card.Content>
          <p>{metric.description}</p>

          {/* <GROUP and CLASS reports> */}

          {metricData.rated_positive_change_num !== undefined && (
            <Show when={metricData.rated_positive_change_num > 0}>
              <Card>
                <Card.Content>
                  <HighlightedStat good>
                    <Show when={metricData.rated_positive_change_num > 0}>
                      +
                    </Show>
                    <Show when={metricData.rated_positive_change_num < 0}>
                      -
                    </Show>
                    {metricData.rated_positive_change_str} %
                  </HighlightedStat>
                  on average since starting
                  <Show when={metricData.rated_positive_change_num >= 15}>
                    : <strong>Amazing progress!</strong>
                    <br />
                    More of your {terms.participants.toLowerCase()} are
                    experiencing {metricName.toLowerCase()}.
                  </Show>
                  <Show
                    when={
                      metricData.rated_positive_change_num >= 5 &&
                      metricData.rated_positive_change_num < 15
                    }
                  >
                    : <strong>Great progress!</strong>
                    <br />
                    More of your {terms.participants.toLowerCase()} are
                    experiencing {metricName.toLowerCase()}.
                  </Show>
                  <Show when={metricData.rated_positive_change_num < 5}>
                    : <strong>Keep it up!</strong>
                    <br />
                    More of your {terms.participants.toLowerCase()} are
                    experiencing {metricName.toLowerCase()}.
                  </Show>
                </Card.Content>
              </Card>
            </Show>
          )}

          {/* </GROUP and CLASS reports> */}

          <Show when={Boolean(showTimeline)}>
            <h3>
              {terms.participant} Experience by Survey: {metricName}
            </h3>
            <Row>
              <Col>
                <HelpText
                  articleId={helpArticles.studentExperienceBySurveyGraph}
                >
                  How Can I Use the {terms.participant} Experience by Survey?
                </HelpText>
              </Col>
            </Row>

            {/* Learning condition timeline graph */}
            <Row>
              <Col>
                {/* <GROUP and CLASS reports> */}
                <Show when={Boolean(showExpandableTimeline)}>
                  <Show when={timelineCollapsed}>
                    <GraphWrapper>
                      <img
                        src={`data:image/png;base64,${metricData.timeline_chart_64}`}
                        alt={`${metricName} timeline chart`}
                      />
                    </GraphWrapper>
                    <Display when={true} whenPrint={false}>
                      <p>
                        <FakeLink onClick={() => setTimelineCollapsed(false)}>
                          Show individual questions
                        </FakeLink>
                      </p>
                    </Display>
                  </Show>
                  <Show when={!timelineCollapsed}>
                    <GraphWrapper>
                      <img
                        src={`data:image/png;base64,${metricData.timeline_chart_by_question_64}`}
                        alt={`${metricName} timeline chart`}
                      />
                    </GraphWrapper>
                    <Display when={true} whenPrint={false}>
                      <p>
                        <FakeLink onClick={() => setTimelineCollapsed(true)}>
                          Hide individual questions
                        </FakeLink>
                      </p>
                    </Display>
                  </Show>
                </Show>
                {/* </GROUP and CLASS reports> */}

                {/*
                  For NETWORKS: different timeline graph property, no
                  expand/collapse.
                */}

                {showNetworkTimeline && isMetricTimelineTable(metricData) && (
                  <NetworkTimeline
                    data={metricData.timeline_charts_data}
                    metricLabel={metricLabel}
                  />
                )}
              </Col>
            </Row>
          </Show>

          <h3>Recent Results by Subpopulation Graph: {metricName}</h3>
          <Row>
            <Col>
              <HelpText articleId={helpArticles.resultsBySubpopulationGraph}>
                How Can I Use the Results by Subpopulation Graph?
              </HelpText>
            </Col>
          </Row>

          {/* Learning condition bar graph (subpopulation) */}
          <Row>
            <Col>
              <Show when={barGraphCollapsed}>
                <GraphWrapper>
                  <img
                    src={`data:image/png;base64,${metricData.bar_chart_64}`}
                    alt={`${metricName} timeline chart`}
                  />
                </GraphWrapper>
                <Display when={true} whenPrint={false}>
                  <p>
                    <FakeLink onClick={() => setBarGraphCollapsed(false)}>
                      Show individual questions
                    </FakeLink>
                  </p>
                </Display>
              </Show>
              <Show when={!barGraphCollapsed}>
                <GraphWrapper>
                  <img
                    src={`data:image/png;base64,${metricData.bar_chart_by_question_64}`}
                    alt={`${metricName} timeline chart`}
                  />
                </GraphWrapper>
                <Display when={true} whenPrint={false}>
                  <p>
                    <FakeLink onClick={() => setBarGraphCollapsed(true)}>
                      Hide individual questions
                    </FakeLink>
                  </p>
                </Display>
              </Show>
            </Col>
          </Row>

          {/* <CLASS reports> */}

          {isMetricDataOpenResponses(metricData) && (
            <>
              <h3>Recent Responses</h3>

              <Show when={metricData.open_responses.length === 0}>
                <p>
                  <em>No responses yet.</em>
                </p>
              </Show>
              <Show when={metricData.open_responses.length > 0}>
                {metricData.open_responses.map((or, iOr) => (
                  <React.Fragment key={iOr}>
                    <p>
                      <em>{or.question_text}</em>
                    </p>
                    <ul>
                      {or.responses.map((r, iR) => (
                        <li key={iR}>{r}</li>
                      ))}
                    </ul>
                  </React.Fragment>
                ))}
              </Show>
            </>
          )}

          {/* </CLASS reports> */}

          {/* <NETWORK reports> */}

          {network && (
            <>
              <Text as="h3">
                Equity Summary by {terms.group}: {metricName}
              </Text>
              {isMetricDataChildTable(metricData) ? (
                <>
                  <Row>
                    <Col cols={6}>
                      <HelpText
                        articleId={helpArticles.equityOverviewByChildTable}
                      >
                        How can I Use the Equity Summary by {terms.group} Table?
                      </HelpText>
                    </Col>
                    <Col cols={6} hAlign="flex-end">
                      <EquityGapLegend />
                    </Col>
                  </Row>
                  <StudentExperienceChildTable
                    data={metricData.student_experience_child_table}
                    reportDate={reportDate}
                  />
                </>
              ) : (
                <Row>
                  <Col cols={12}>
                    <p>
                      <em>Not enough data to display.</em>
                    </p>
                  </Col>
                </Row>
              )}
            </>
          )}

          {/* </NETWORK reports> */}
        </Card.Content>
      </Card>
    </section>
  );
};
