import styled from 'styled-components/macro';

import { PortalType } from '@perts/model';
import {
  IconClever,
  IconEnvelope,
  IconGoogle,
  IconIdBadge,
  RadioWithLabel,
  Show,
} from '@perts/ui';

import { theme, useTerms } from 'components';
import { usePermissions } from 'utils';
import { RosterSignOnTypeFormValues } from './RosterSignOnTypeForm';

const LabelStyled = styled.span`
  display: inline-flex;
  justify-content: center;
  align-items: center;
`;

const Spacer = styled.span`
  margin-right: ${theme.units.fieldPaddingInternal};
`;

type Props = {
  defaultValues: RosterSignOnTypeFormValues | null;
  setFieldValue: (field: string, value: any) => void;
  values: RosterSignOnTypeFormValues;
};

export const RosterSignOnTypeFields = ({
  defaultValues,
  setFieldValue,
  values,
}: Props) => {
  const terms = useTerms();
  const { isAdmin } = usePermissions();

  // Recall that this component is use for both the edit class modal and the
  // community defaults form.
  const mayUseClever =
    // * Admins may set classes or defaults to clever
    isAdmin ||
    // * Any user may set classes to clever if the community default is clever
    defaultValues?.portalType === PortalType.CLEVER ||
    // * Any user may see that a class or default is set to clever, even if
    //   switching it away from clever may be irreversible for them.
    values.portalType === PortalType.CLEVER;

  return (
    <>
      <RadioWithLabel
        name="portalType"
        label={
          <LabelStyled>
            <IconEnvelope />
            <Spacer />
            Email Address
          </LabelStyled>
        }
        value={PortalType.EMAIL}
        checked={values.portalType === PortalType.EMAIL}
        onChange={() => setFieldValue('portalType', PortalType.EMAIL)}
      />
      <RadioWithLabel
        name="portalType"
        label={
          <LabelStyled>
            <IconGoogle />
            <Spacer />
            Google (recommended)
          </LabelStyled>
        }
        value={PortalType.GOOGLE}
        checked={values.portalType === PortalType.GOOGLE}
        onChange={() => setFieldValue('portalType', PortalType.GOOGLE)}
      />
      <Show when={mayUseClever}>
        <RadioWithLabel
          name="portalType"
          label={
            <LabelStyled>
              <IconClever />
              <Spacer />
              Clever
            </LabelStyled>
          }
          value={PortalType.CLEVER}
          checked={values.portalType === PortalType.CLEVER}
          onChange={() => setFieldValue('portalType', PortalType.CLEVER)}
        />
      </Show>
      <RadioWithLabel
        name="portalType"
        label={
          <LabelStyled>
            <IconIdBadge />
            <Spacer />
            {terms.studentIdPortalType}
          </LabelStyled>
        }
        value={PortalType.STUDENT_ID}
        checked={values.portalType === PortalType.STUDENT_ID}
        onChange={() => setFieldValue('portalType', PortalType.STUDENT_ID)}
      />
    </>
  );
};
