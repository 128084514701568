import { useContext } from 'react';
import { Formik, Form } from 'formik';
import { Group, GroupMember } from 'models';
import pluralize from 'pluralize';
import {
  Button,
  Col,
  InfoBox,
  Row,
  Modal,
  Show,
  useFormError,
} from '@perts/ui';
import { ListMembersToRemove } from './ListMembersToRemove';
import { ListMembersWithClasses } from './ListMembersWithClasses';
import TermsContext from 'components/TermsContext';

export type MembersRemoveFormValues = {};

export type MembersRemoveFormProps = {
  close: (params?: any) => void;
  onSubmit: (values: MembersRemoveFormValues) => void;
  group: Group;
  membersToRemove: GroupMember[];
  membersToRemoveIncludesAllManagers: boolean;
  membersToRemoveIncludesSelf: boolean;
  membersWithClasses: GroupMember[];
};

export const MembersRemoveForm = ({
  close,
  onSubmit,
  group,
  membersToRemove,
  membersToRemoveIncludesAllManagers,
  membersToRemoveIncludesSelf,
  membersWithClasses,
}: MembersRemoveFormProps) => {
  const [FormError, showFormError] = useFormError();
  const terms = useContext(TermsContext);

  const validate = (values) => ({
    ...(membersToRemove.length === 0 || membersToRemoveIncludesAllManagers
      ? { emails: 'invalid' }
      : {}),
  });

  return (
    <Modal close={close}>
      <Modal.Title className="center">
        Remove {terms.group} {terms.groupMembers}
      </Modal.Title>

      <Formik
        enableReinitialize={true}
        initialValues={{
          emails: [],
        }}
        validate={validate}
        validateOnMount={true}
        onSubmit={async (values) => {
          try {
            // Clear existing form error.
            showFormError(false);

            // Perform form onSubmit.
            await onSubmit(values);

            // Close modal on successful form onSubmit.
            close({ redirectToHome: membersToRemoveIncludesSelf });
          } catch (error) {
            // Display form error.
            showFormError(true);
          }
        }}
      >
        {({ isSubmitting, isValid, values, errors }) => (
          <Form>
            <Show when={membersToRemoveIncludesAllManagers}>
              <Row>
                <Col>
                  <InfoBox color="warning" data-testid="members-list-self">
                    This action would remove all{' '}
                    {terms.groupManagers.toLowerCase()} from the{' '}
                    {terms.group.toLowerCase()}.
                  </InfoBox>
                </Col>
              </Row>
            </Show>
            <Show when={!membersToRemoveIncludesAllManagers}>
              <ListMembersToRemove
                group={group}
                members={membersToRemove}
                includesSelf={membersToRemoveIncludesSelf}
              />
              <ListMembersWithClasses members={membersWithClasses} />
            </Show>

            <Row>
              <Col>
                <FormError />
              </Col>
            </Row>

            <Row>
              <Col cols={6} colsSm={12}>
                <Button
                  type="button"
                  color="secondary"
                  fullWidth
                  onClick={close}
                  disabled={isSubmitting}
                >
                  Cancel
                </Button>
              </Col>

              <Col cols={6} colsSm={12} hAlign="flex-end">
                <Button
                  type="submit"
                  fullWidth
                  data-testid="submit-button"
                  disabled={!isValid || isSubmitting}
                  loading={isSubmitting}
                >
                  Remove{' '}
                  {pluralize(terms.groupMember, membersToRemove.length, true)}
                </Button>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
