import styled from 'styled-components/macro';

export const ItemLinkStyled = styled.div`
  button {
    padding-left: 30px;
  }

  svg {
    position: relative;
    top: 2px;
    margin-right: 5px;
  }
`;
