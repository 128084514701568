import { useCloseModal, useGetCheckedStates } from 'utils';
import pluralize from 'pluralize';
import { SurveysEditAdvancedFormWrapper } from './SurveysEditAdvancedFormWrapper';
import { useMutation, useQueryClient } from 'react-query';
import {
  Class,
  queryKeyClassesByGroup,
  updateGroupClasses,
  useClassesQueryByParams,
  useGroupGetByParams,
  useParams,
} from 'models';
import { uniqBy } from 'lodash';
import { message } from 'antd';
import { ErrorMessageBox, Loading, useTerms } from 'components';
import { getMessageFromErrors } from '@perts/util';
import { SurveysEditAdvancedForm } from '../shared/SurveysEditAdvancedForm';
import { SurveysEditAdvancedFormValues } from 'pages/Surveys/shared';
import { getInitialValues } from '../shared/SurveysEditAdvancedForm/getInitialValues';
import { getDefaultValues } from '../shared/SurveysEditAdvancedForm/getDefaultValues';

export const SurveysEditAdvanced = () => {
  const { groupId } = useParams();
  const checked = useGetCheckedStates();
  const closeModal = useCloseModal();

  const terms = useTerms();

  const queryClient = useQueryClient();
  const queryUpdateFn = updateGroupClasses;
  const queryKey = queryKeyClassesByGroup(groupId);

  const groupQuery = useGroupGetByParams();

  const {
    data: classes = [],
    error: classesError,
    isLoading: classesIsLoading,
    isSuccess: classesIsSuccess,
  } = useClassesQueryByParams();

  const checkedClasses = classes.filter((c) => checked[c.uid]);

  const mutation = useMutation(queryUpdateFn, {
    onMutate: (values: Class[]) => {
      // Snapshot previous.
      const previous = queryClient.getQueryData<Class[]>(queryKey);

      // Optimistic update.
      const optimisticCacheValue = previous
        ? // if existing cache, combine updated with existing cache value
          uniqBy([...values, ...classes], 'uid')
        : // else, just add update to the cache
          values;

      queryClient.setQueryData<Class[]>(queryKey, optimisticCacheValue);

      // Return previous snapshot for rollbacks.
      return { previous };
    },

    onSuccess: () => {
      message.success(
        `Successfully edited settings for ${terms.classes.toLowerCase()}.`,
      );
    },

    onError: (_err: Error, _data, context) => {
      message.error(
        `Unable to edit settings for ${terms.classes.toLowerCase()}.`,
      );

      // Rollback any optimistic updates performed.
      if (context?.previous) {
        queryClient.setQueryData(queryKey, context.previous);
      }
    },

    onSettled: () => {
      // Always refetch after an error or success.
      queryClient.invalidateQueries(queryKey);
    },
  });

  const onSubmit = async (values: SurveysEditAdvancedFormValues) => {
    const updatedClasses = checkedClasses.map((cls) => ({
      ...cls,
      meta: {
        ...cls.meta,
        gender_question_disabled: !values.genderQuestionEnabled,
      },
    }));

    await mutation.mutateAsync(updatedClasses);

    closeModal();
  };

  // Handle Loading
  const isLoading = groupQuery.isLoading || classesIsLoading;

  if (isLoading) {
    return (
      <SurveysEditAdvancedFormWrapper close={closeModal}>
        <Loading />
      </SurveysEditAdvancedFormWrapper>
    );
  }

  // Handle Errors
  const displayError = !groupQuery.isSuccess || !classesIsSuccess;

  if (displayError) {
    return (
      <SurveysEditAdvancedFormWrapper close={closeModal}>
        <ErrorMessageBox>
          {getMessageFromErrors([groupQuery.error, classesError])}
        </ErrorMessageBox>
      </SurveysEditAdvancedFormWrapper>
    );
  }

  // Render Form
  const submitButtonText = `Save Changes to ${pluralize(
    terms.class,
    checkedClasses.length,
    true,
  )}`;

  const initialValues = getInitialValues(checkedClasses);
  const defaultValues = getDefaultValues(groupQuery.data);

  return (
    <SurveysEditAdvancedFormWrapper close={closeModal}>
      <SurveysEditAdvancedForm
        close={closeModal}
        submitButtonText={submitButtonText}
        onSubmit={onSubmit}
        initialValues={initialValues}
        defaultValues={defaultValues}
      />
    </SurveysEditAdvancedFormWrapper>
  );
};
