import FakeLink from 'components/FakeLink';
import { useImpersonate } from 'utils/useImpersonate';

export const ImpersonateUser = ({ uid }) => {
  const { setImpersonateUid } = useImpersonate();

  const impersonate = () => {
    setImpersonateUid(uid);
  };

  return <FakeLink onClick={() => impersonate()}>Impersonate</FakeLink>;
};
