import { useContext } from 'react';
import { useMutation } from 'react-query';
import { message } from 'antd';
import { difference } from 'lodash';

import {
  transformUsersRemoveGroup,
  selectUserIsGroupManager,
  updateUsers,
  useClassesQueryByParams,
  useCurrentUserQuery,
  useUsersQueryByParams,
} from 'models';
import { useParams, toHomeNoProgram } from 'pages';
import { useCloseModal } from 'utils';

import Loading from 'components/Loading';
import { ErrorMessageBox } from 'components/ErrorMessageBox';
import { MemberLeaveForm, MemberLeaveFormValues } from './MemberLeaveForm';
import TermsContext from 'components/TermsContext';

import { getMessageFromErrors } from '@perts/util';

export const MemberLeave = () => {
  const { groupId } = useParams();
  const terms = useContext(TermsContext);

  // Close the modal.
  const closeModal = useCloseModal({ ignoreEmptyState: true });
  const closeModalHome = useCloseModal({
    pathname: toHomeNoProgram(),
    ignoreEmptyState: true,
  });
  const close = ({ redirectToHome }) =>
    redirectToHome ? closeModalHome() : closeModal();

  const userQuery = useCurrentUserQuery();

  const usersQuery = useUsersQueryByParams();

  const classesQuery = useClassesQueryByParams();

  const mutation = useMutation(updateUsers, {
    // Handle successful mutation.
    onSuccess: () => {
      message.success(`Successfully left the ${terms.group}.`);
    },
    // Handle error mutation.
    onError: () => {
      message.error(`Unable to leave ${terms.group}.`);
    },
  });
  // https://stackoverflow.com/questions/65760158/react-query-mutation-typescript
  // Formik onSubmit handler
  const onSubmit = async (values: MemberLeaveFormValues) => {
    const updatedUsers = transformUsersRemoveGroup([values.user], groupId);
    await mutation.mutateAsync(updatedUsers);
  };

  // Display loading.
  const isLoading = userQuery.isLoading || usersQuery.isLoading;

  if (isLoading) {
    return <Loading />;
  }

  // Display errors.
  if (
    !userQuery.isSuccess ||
    !usersQuery.isSuccess ||
    !classesQuery.isSuccess
  ) {
    return (
      <ErrorMessageBox>
        {getMessageFromErrors([
          userQuery.error,
          usersQuery.error,
          classesQuery.error,
        ])}
      </ErrorMessageBox>
    );
  }
  const currentUser = userQuery.data;
  const users = usersQuery.data;
  const classes = classesQuery.data;

  const managerIds = users
    .filter((u) => selectUserIsGroupManager(u, groupId))
    .map((u) => u.uid);

  const memberToLeaveIsUniqueLead =
    difference(managerIds, [currentUser.uid]).length === 0;

  return (
    <MemberLeaveForm
      close={close}
      onSubmit={onSubmit}
      user={currentUser}
      memberToLeaveIsUniqueLead={memberToLeaveIsUniqueLead}
      classes={classes}
    />
  );
};
