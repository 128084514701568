import { LOCAL_STORAGE_AUTH_USER } from 'services/triton/config';

export const saveUserToLocalStorage = (user) => {
  try {
    localStorage.setItem(LOCAL_STORAGE_AUTH_USER, JSON.stringify(user));
  } catch (e) {
    console.error(e);
    localStorage.setItem(LOCAL_STORAGE_AUTH_USER, null);
  }
};
