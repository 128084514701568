import {
  cycleCompletionThreshold,
  cycleExcellentThreshold,
} from '@perts/config';
import { formatPercent } from '@perts/util';

import React from 'react';
import {
  useTable,
  useGlobalFilter,
  useSortBy,
  usePagination,
} from 'react-table';

import { Col, Row } from '@perts/ui';

import { DEFAULT_PAGE_SIZE } from 'config';
import {
  TableGlobalFilter,
  ReactTable,
  ReactTableFooter,
  ReactTablePagination,
  ReactTableTd,
  ReactTableTh,
  ReactTableTr,
  ReactTableWrap,
} from 'components';
import DataTable from 'components/DataTable';
import { useTerms } from 'components/TermsContext';

export const Table = ({ columns, data, numCycles }) => {
  const terms = useTerms();

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    // from useGlobalFilter
    rows: rowsFiltered,
    // from usePagination
    // https://codesandbox.io/embed/github/tannerlinsley/react-table/tree/master/examples/pagination
    page,
    canPreviousPage,
    canNextPage,
    // pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    setGlobalFilter,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      // https://react-table.js.org/api/useSortBy#table-options
      initialState: {
        sortBy: [{ id: 'name' }],
        pageSize: DEFAULT_PAGE_SIZE,
      },

      // Prevent react-query stale prevention updates from reseting the filter.
      autoResetGlobalFilter: false,
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
  );

  return (
    <>
      <Row>
        <Col cols={8}>
          <TableGlobalFilter
            by={`${terms.class.toLowerCase()} or lead`}
            setFilter={setGlobalFilter}
          />
        </Col>
      </Row>

      <ReactTableWrap>
        <ReactTable {...getTableProps()}>
          <thead>
            <tr>
              <ReactTableTh>{/* Class Leads */}</ReactTableTh>
              <ReactTableTh align="center" colSpan={numCycles}>
                Survey
              </ReactTableTh>
              <ReactTableTh>{/* Completed */}</ReactTableTh>
            </tr>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <ReactTableTh
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    align="center"
                    whiteSpace="nowrap"
                  >
                    {column.render('Header')}
                    <DataTable.SortIcon {...column} />
                  </ReactTableTh>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps}>
            {page.map((row) => {
              prepareRow(row);

              return (
                <ReactTableTr {...row.getRowProps()}>
                  {row.cells.map((cell, i) => (
                    <ReactTableTd
                      align={i === 0 ? 'left' : 'center'}
                      cell={cell}
                      key={`${row.id}-${i}`}
                    />
                  ))}
                </ReactTableTr>
              );
            })}
          </tbody>
        </ReactTable>

        <ReactTableFooter>
          <ReactTablePagination
            canNextPage={canNextPage}
            canPreviousPage={canPreviousPage}
            gotoPage={gotoPage}
            nextPage={nextPage}
            pageCount={pageCount}
            pageIndex={pageIndex}
            pageSize={pageSize}
            previousPage={previousPage}
            setPageSize={setPageSize}
            totalRows={rowsFiltered.length}
          />
        </ReactTableFooter>
        <ReactTableFooter>
          <Row>
            <Col>
              <em>
                ✓: at least {formatPercent(cycleCompletionThreshold)}{' '}
                participation | ✓+: at least{' '}
                {formatPercent(cycleExcellentThreshold)}
              </em>
            </Col>
          </Row>
        </ReactTableFooter>
      </ReactTableWrap>
    </>
  );
};
