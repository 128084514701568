// Intentionally independent of the race options used in Saturn
// (in @perts/config)

export const optionsRace = [
  {
    label: 'American Indian or Native America',
    value: 'native_american',
  },
  {
    label: 'Asian or Asian American',
    value: 'asian_american',
  },
  {
    label: 'Black or African American',
    value: 'black',
  },
  {
    label: 'Latino/a/x',
    value: 'latino',
  },
  {
    label: 'Middle Eastern/North African',
    value: 'middle_eastern',
  },
  {
    label: 'Native Hawaiian or Other Pacific Islander',
    value: 'pacific_islander',
  },
  {
    label: 'White',
    value: 'white',
  },
  {
    label: 'Interracial or mixed race (please specify)',
    value: 'mixed',
  },
  {
    label: 'Another group (please specify)',
    value: 'other',
  },
  {
    label: 'Prefer not to say',
    value: 'prefer_not_to_answer',
  },
];
