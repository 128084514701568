import {
  NEPTUNE_URL_PREFIX,
  TRITON_URL_PREFIX,
  TRITON_PLATFORM_NAME,
  CONTACT_EMAIL_ADDRESS,
} from './config';
import {
  fetchApi,
  getAuthorization,
  getJwtPayload,
  handleApiResponse,
} from './helpers';

const tokenLogin = (token) => {
  const { user_id } = getJwtPayload(token);
  const options = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  };

  return fetch(`${TRITON_URL_PREFIX}/api/users/${user_id}`, options)
    .then(handleApiResponse)
    .catch((error) =>
      // eslint-disable-next-line
      Promise.reject({
        code: 'error',
        message: 'Token is invalid.',
        error,
      }),
    );
};

const login = (email, password) => {
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ auth_type: 'email', email, password }),
  };

  return fetch(`${NEPTUNE_URL_PREFIX}/api/login`, options)
    .then(handleApiResponse)
    .catch((error) =>
      // eslint-disable-next-line
      Promise.reject({
        code: 'error',
        error,
        message: 'User and/or password is incorrect.',
      }),
    );
};

// Response will have Set-Cookie header that forces all our cookies to expire.
const logout = () =>
  fetch(`${TRITON_URL_PREFIX}/api/logout`, {
    headers: { Authorization: getAuthorization() },
  }).catch((error) =>
    // eslint-disable-next-line
    Promise.reject({
      code: 'error',
      message: 'Unexpected response from /logout',
      error,
    }),
  );

const isEmailNew = (email) => {
  const options = {
    method: 'GET',
  };

  const url = `${NEPTUNE_URL_PREFIX}/api/accounts/${email}`;

  return fetch(url, options).then((response) => response.status === 404);
};

const register = (email) =>
  isEmailNew(email).then((isUnique) => {
    if (isUnique) {
      // email is unique (on neptune), so we will attempt to register on triton
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email,
          domain: TRITON_URL_PREFIX,
          platform: TRITON_PLATFORM_NAME,
          template_content: {
            contact_email_address: CONTACT_EMAIL_ADDRESS,
          },
        }),
      };

      const url = `${NEPTUNE_URL_PREFIX}/api/register`;

      return fetch(url, options).catch((error) =>
        // eslint-disable-next-line
        Promise.reject({
          code: 'register/error',
          error,
          message: 'Trouble registering. Please try again.',
        }),
      );
    }

    // email is not unique (on neptune), so we won't register
    // eslint-disable-next-line
    return Promise.reject({
      code: 'register/email',
      message: 'Email address not available.',
    });
  });

const requestMagicLink = (email, continuePath) => {
  const continueUrl = `${TRITON_URL_PREFIX}${continuePath}`;
  const url = `${NEPTUNE_URL_PREFIX}/api/temp_token_emails`;
  const options = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ email, url: continueUrl }),
  };

  // The UI only really cares if this request was successful or not.
  return fetch(url, options).then((res) => {
    if (!res.ok) {
      throw new Error('Could not create temp token email.');
    }
  });
};

const setPassword = (token, password) => {
  const options = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ password }),
  };

  const url = `${NEPTUNE_URL_PREFIX}/api/set_password`;

  return fetchApi(url, options);
};

const resetPassword = (email, captchaResponse) => {
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      email,
      captcha_response: captchaResponse,
      domain: TRITON_URL_PREFIX,
      platform: TRITON_PLATFORM_NAME,
      template_content: {
        contact_email_address: CONTACT_EMAIL_ADDRESS,
      },
    }),
  };

  const url = `${NEPTUNE_URL_PREFIX}/api/reset_password`;

  return fetchApi(url, options);
};

const checkToken = (token) => {
  const options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const url = `${NEPTUNE_URL_PREFIX}/api/auth_tokens/${token}/user`;

  return fetchApi(url, options);
};

const imitateUser = (email) => {
  const options = {
    method: 'GET',
    headers: {
      Authorization: getAuthorization(),
    },
  };

  const url = `${NEPTUNE_URL_PREFIX}/api/login/${email}`;

  return fetchApi(url, options);
};

/**
 * Old: Ask Neptune if a user has been "verified" i.e. set a password.
 * 2021: All users are authenticated with Google Identity Platform and there's
 * no point at which passwords are not set.
 *
 * @param {string} _email address
 * @returns {Promise<boolean>} whether the user is verified.
 */
const verify = (_email) => Promise.resolve(true);

export default {
  tokenLogin,
  login,
  logout,
  register,
  requestMagicLink,
  setPassword,
  resetPassword,
  checkToken,
  imitateUser,
  verify,
};
