import { useContext } from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';

import { Group, GroupDefaultSettings } from 'models';
import {
  Button,
  Col,
  Input,
  Modal,
  Row,
  TextField,
  useFormError,
} from '@perts/ui';
import TermsContext from 'components/TermsContext';

const GroupsAddFormSchema = Yup.object().shape({
  name: Yup.string()
    .max(200, 'Name must be at most 200 characters long.')
    .required('Name is required.'),
});

export type GroupCopyFormValues = {
  name: string;
};

export type GroupCopyParams = GroupCopyFormValues & {
  default_settings: GroupDefaultSettings;
};

export type GroupCopyFormProps = {
  close: () => void;
  group: Group;
  onSubmit: (values: GroupCopyParams) => void;
};

export const GroupsCopyForm = ({
  close,
  onSubmit,
  group,
}: GroupCopyFormProps) => {
  const [FormError, showFormError] = useFormError();
  const terms = useContext(TermsContext);

  return (
    <Modal close={close}>
      <Modal.Title className="center">
        Copy {terms.group}: {group.name}
      </Modal.Title>

      <Formik
        enableReinitialize={true}
        initialValues={{
          name: `${group.name} (copy)`,
        }}
        validationSchema={GroupsAddFormSchema}
        onSubmit={async (values) => {
          try {
            // Clear existing form error.
            await showFormError(false);

            // Copy settings from the original group.
            const copyParams: GroupCopyParams = {
              ...values,
              default_settings: group.default_settings,
            };

            // Perform form onSubmit.
            await onSubmit(copyParams);
          } catch (error) {
            // Display form error.
            showFormError(true);
          }
        }}
      >
        {({ isSubmitting, isValid, dirty }) => (
          <Form>
            <Input
              id="group-name"
              name="name"
              label="Name of Copy"
              labelPlacement="top"
              component={TextField}
            />

            <Row>
              <Col>
                <FormError />
              </Col>
            </Row>

            <Row>
              <Col>
                <span>
                  <i>
                    New {terms.group.toLowerCase()} will have the same default
                    settings as the original. {terms.groupMembers},{' '}
                    {terms.classes.toLowerCase()}, and survey schedules are not
                    copied.
                  </i>
                </span>
              </Col>
            </Row>

            <Row justifyContent="space-between">
              <Col cols={6} colsSm={12}>
                <Button
                  type="button"
                  color="secondary"
                  fullWidth
                  onClick={close}
                  disabled={isSubmitting}
                >
                  Cancel
                </Button>
              </Col>

              <Col cols={6} colsSm={12} hAlign="flex-end">
                <Button
                  type="submit"
                  fullWidth
                  disabled={!isValid || isSubmitting}
                  loading={isSubmitting}
                  data-testid="submit"
                >
                  Create Copy
                </Button>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
