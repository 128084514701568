import {
  Group,
  User,
  selectUserIsAdmin,
  selectUserIsGroupManager,
} from 'models';

import { Col, Row, Show } from '@perts/ui';
import { CardGroup } from './CardGroup';

type HomeGroupsProps = {
  user: User;
  groups: Group[];
  queryKeyParams?: {
    name: string;
    pageIndex: number;
  };
  emptyMessage?: JSX.Element;
  pagination?: JSX.Element;
};

export const HomeGroups = ({
  user,
  groups,
  queryKeyParams,
  emptyMessage,
  pagination,
}: HomeGroupsProps) => {
  const isAdmin = selectUserIsAdmin(user);
  const managerPermission = (group) =>
    selectUserIsGroupManager(user, group.uid) || isAdmin;

  return (
    <>
      <Show when={groups.length === 0 && Boolean(emptyMessage)}>
        {emptyMessage}
      </Show>

      <Show when={groups.length > 0}>
        {pagination}

        {groups.map((group) => (
          <Row key={group.uid}>
            <Col>
              <CardGroup
                key={group.uid}
                group={group}
                user={user}
                mayPin={!isAdmin}
                queryKeyParams={queryKeyParams}
                mayEditNameInPlace={managerPermission(group)}
                mayRemoveGroup={managerPermission(group)}
                maySeeTotalClasses={managerPermission(group)}
              />
            </Col>
          </Row>
        ))}

        {pagination}
      </Show>
    </>
  );
};
