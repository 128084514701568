import { message } from 'antd';
import { useQueryClient } from 'react-query';
import styled from 'styled-components/macro';

import {
  Network,
  updateNetwork,
  queryKeyNetworksByProgram,
  invalidateUpdateNetwork,
} from 'models';
import { useParams } from 'pages';

import { Show, Text, EditInPlaceForm, Row, Col } from '@perts/ui';

type HomeEditNetworkNameProps = {
  network: Network;
  mayEditNameInPlace: boolean;
};

const NameContainer = styled.div`
  display: flex;
  align-items: center;
  & > span {
    margin-right: 5px;
  }
`;

export const HomeEditNetworkName = ({
  network,
  mayEditNameInPlace,
}: HomeEditNetworkNameProps) => {
  const { programId } = useParams();
  const queryClient = useQueryClient();

  const uniqComparator = (previous, current) => previous.uid === current.uid;

  return (
    <Text as="h2" overflowWrap="anywhere">
      <Show when={mayEditNameInPlace}>
        <Row>
          <Col>
            <NameContainer>
              <span>Network:</span>
              <EditInPlaceForm<Network>
                name="name"
                label={`Edit in place ${network.name}`}
                value={network}
                emptyValueMessage="No name set"
                queryKey={queryKeyNetworksByProgram(programId)}
                queryUpdateFn={updateNetwork}
                queryInvalidateFn={() =>
                  invalidateUpdateNetwork(queryClient, network.uid)
                }
                uniqComparator={uniqComparator}
                successFn={() =>
                  message.success(`Successfully updated the networks's name.`)
                }
                errorFn={() =>
                  message.error(
                    `There was an error updating that networks's name.`,
                  )
                }
              />
            </NameContainer>
          </Col>
        </Row>
      </Show>
      <Show when={!mayEditNameInPlace}>
        <span>Network: {network.name}</span>
      </Show>
    </Text>
  );
};
