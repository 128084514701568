// https://docs.google.com/spreadsheets/d/1EKKzZjI-eCtekB_S_SsNnBQpGEKVIKueZIkBD6W8QJc

import keyBy from 'lodash/keyBy';

import { locales } from '../i18n';
import { CompositeMetricConfig, MetricItemConfig } from './types';
import {
  sesStudent23OptionsDisagreeAgree,
  sesStudent23OptionsMotivated,
} from './answerOptions';

export const sesStudent23MathItemConfig: MetricItemConfig[] = [
  {
    label: 'math_student_voice',
    prompts: {
      [locales.ENGLISH]: `My math teacher responds to student suggestions to make our class better.`,
      [locales.SPANISH]: `Mi maestro de matemáticas responde a las sugerencias de los estudiantes para mejorar nuestra clase.`,
    },
    options: sesStudent23OptionsDisagreeAgree({
      en: 'math',
      es: 'matemáticas',
    }),
    minGood: 5,
  },
  {
    label: 'math_teacher_caring',
    prompts: {
      [locales.ENGLISH]: `My math teacher cares about my life outside of school.`,
      [locales.SPANISH]: `Mi maestro de matemáticas se preocupa por mí vida fuera de la escuela.`,
    },
    options: sesStudent23OptionsDisagreeAgree({
      en: 'math',
      es: 'matemáticas',
    }),
    minGood: 5,
  },
  {
    label: 'math_effort',
    prompts: {
      [locales.ENGLISH]: `Overall, how motivated are you to work hard in your math class?`,
      [locales.SPANISH]: `En general, ¿qué tan motivado/a estás para trabajar duro en tu clase de matemáticas?`,
    },
    options: sesStudent23OptionsMotivated({
      en: 'math',
      es: 'matemáticas',
    }),
    minGood: 4,
  },
];

export const sesStudent23MathConfig: CompositeMetricConfig = {
  label: 'ses-student23-math',
  items: sesStudent23MathItemConfig,
  itemIndex: keyBy(sesStudent23MathItemConfig, 'label'),
};
