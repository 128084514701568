import {
  useCurrentUserQuery,
  selectUserIsAdmin,
  selectUserIsMember,
  useClassGetByParams,
} from 'models';
import { useParams } from 'pages';

import { ClassRosterRender } from './ClassRosterRender';
import Loading from 'components/Loading';
import { ErrorMessageBox } from 'components/ErrorMessageBox';

import { getMessageFromErrors } from '@perts/util';

export const ClassRosterTabKeys = {
  roster: 'Roster',
  settings: 'Settings',
  'batch-edit': 'Batch Edit',
};

export const ClassRoster = () => {
  const { groupId } = useParams();

  // Query for current user.
  const currentUserQuery = useCurrentUserQuery();

  // Query for Class.
  const classQuery = useClassGetByParams();

  // Display loading.
  if (currentUserQuery.isLoading || classQuery.isLoading) {
    return <Loading />;
  }

  // Display any errors.
  if (!currentUserQuery.isSuccess || !classQuery.isSuccess) {
    return (
      <ErrorMessageBox>
        {getMessageFromErrors([currentUserQuery.error, classQuery.error])}
      </ErrorMessageBox>
    );
  }

  const currentUser = currentUserQuery.data;

  const userIsMember = selectUserIsMember(currentUser, groupId);
  const userIsAdmin = selectUserIsAdmin(currentUser);

  const mayViewPage = userIsMember || userIsAdmin;

  // Show the error access if the user is not a member, group manager or admin.
  if (!mayViewPage) {
    return (
      <ErrorMessageBox>
        You don&rsquo;t have access to this page.
      </ErrorMessageBox>
    );
  }

  return <ClassRosterRender cls={classQuery.data} />;
};
