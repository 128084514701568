import { useContext } from 'react';
import { Show, InfoBox } from '@perts/ui';
import TermsContext from 'components/TermsContext';

export type GroupsRemoveFormMessageProps = {
  groupHasClasses: boolean;
  groupName: string;
};

export const GroupsRemoveFormMessage = ({
  groupHasClasses,
  groupName,
}: GroupsRemoveFormMessageProps) => {
  const terms = useContext(TermsContext);

  return (
    <>
      <Show when={groupHasClasses}>
        <InfoBox color="warning" data-testid="allow-group-remove-message">
          There are one or more {terms.classes.toLowerCase()} in this{' '}
          {terms.group.toLowerCase()}. Remove them before deleting this{' '}
          {terms.group.toLowerCase()}.
        </InfoBox>
      </Show>
      <Show when={!groupHasClasses}>
        <InfoBox color="warning" data-testid="dont-allow-group-remove-message">
          Are you sure you want to delete {terms.group.toLowerCase()} &ldquo;
          {groupName}&rdquo;?
        </InfoBox>
      </Show>
    </>
  );
};
