import { Col, InfoBox, Input, Row, Show, Switch, Text } from '@perts/ui';
import { useTerms } from 'components';
import { helpArticles } from 'config';
import { RosterSignOnLockFormValues } from './RosterSignOnLockForm';

const switchLabel = (value, terms) => {
  const checked = Boolean(value);
  const indeterminate = value == null;

  const mixedSwitchLabel = (
    <span>{terms.classes} have mixed sign-on rules.</span>
  );
  const enabledSwitchLabel = (
    <span>
      <strong>Locked</strong>: {terms.participants.toLowerCase()} can only
      access the survey after they are added to the class roster.
    </span>
  );
  const disabledSwitchLabel = (
    <span>
      <strong>Unlocked</strong>: {terms.participants.toLowerCase()} can add
      themselves to the roster.
    </span>
  );

  const label = indeterminate
    ? mixedSwitchLabel
    : checked
    ? enabledSwitchLabel
    : disabledSwitchLabel;

  return label;
};

const handleOnClick = () => {
  const fw = (window as any).FreshworksWidget;
  fw('open', 'article', {
    id: helpArticles.whatIsRosterIDRule,
  });
};

export const RosterSignOnLockFields = ({
  isSubmitting,
  values,
}: {
  isSubmitting: boolean;
  values: RosterSignOnLockFormValues;
}) => {
  const terms = useTerms();

  return (
    <>
      <Row>
        <Col>
          <Input
            component={Switch}
            id="rosterLocked"
            name="rosterLocked"
            label={switchLabel(values.rosterLocked, terms)}
            labelPlacement="end"
            disabled={isSubmitting}
            checked={values.rosterLocked}
            indeterminate={values.rosterLocked === null}
          />
        </Col>
      </Row>

      <Show when={!values.rosterLocked && !values.ruleEnabled}>
        <Row>
          <Col>
            <InfoBox data-testid="set-a-sign-on-rule">
              <span>
                Set a{' '}
                <Text as="a" onClick={handleOnClick}>
                  sign-on rule
                </Text>{' '}
                to ensure {terms.participants.toLowerCase()} use the right
                identifier.
              </span>
            </InfoBox>
          </Col>
        </Row>
      </Show>
    </>
  );
};
