import React, { useMemo } from 'react';

import { Table } from './Table';
import colorWhen from 'components/colorWhen';
import type { FidelityChildRow } from '../types';
import { theme } from '@perts/ui';

const ColorWhenData = colorWhen([
  {
    color: theme.colors.greenDark,
    when: (x) => x >= 80,
  },
]);

type Props = {
  childTerm: string;
  data: FidelityChildRow[];
  reportDate: string;
};

export const FidelityChildTable = ({ childTerm, data, reportDate }: Props) => {
  // https://react-table.tanstack.com/docs/quick-start for details on useMemo
  const columns = useMemo(
    () => [
      {
        Header: <>{childTerm}</>,
        id: 'child_name',
        accessor: 'child_name',
      },
      {
        Header: <>Can Be Honest</>,
        id: 'fidelity_honest',
        accessor: 'fidelity_honest_num', // for sorting
        Cell: ({ row }: { row: { original: FidelityChildRow } }) => (
          <>
            <ColorWhenData data={row.original.fidelity_honest_num}>
              {row.original.fidelity_honest_str}
            </ColorWhenData>
          </>
        ),
      },
      {
        Header: <>Used for Improvement</>,
        id: 'fidelity_class_better',
        accessor: 'fidelity_class_better_num', // for sorting
        Cell: ({ row }: { row: { original: FidelityChildRow } }) => (
          <>
            <ColorWhenData data={row.original.fidelity_class_better_num}>
              {row.original.fidelity_class_better_str}
            </ColorWhenData>
          </>
        ),
      },
    ],
    [childTerm],
  );

  // Don't memoize data b/c it's hard-coded by the server

  return <Table columns={columns} data={data} />;
};
