import React, { useMemo } from 'react';
import { groupBy, isEqual, range } from 'lodash';

import { sortNumeric, sortTableRowNumeric } from 'utils';
import { ChangeText, mutateToChildSurveyTable } from '../components';
import { Table } from './Table';
import { ChildSurveyRow, TimelineChartRow } from '../types';
import { formatPercent } from '@perts/util';
import { useTerms } from 'components/TermsContext';

type CellProps = {
  row: { original: ChildSurveyRow };
};

const checkDataIsNotSparse = (data: TimelineChartRow[], numCycles: number) => {
  // SG asserts that RServe won't trigger this error, but this formalizes
  // expectations that we won't have a sparse array of cycle ordinals for any
  // given child.
  const expectedRange = range(1, numCycles + 1);
  const dataById = groupBy(data, 'reporting_unit_id');
  const ordinalRanges = Object.values(dataById).map((rows) =>
    rows.map((row) => row.cycle_ordinal),
  );
  const badRange = ordinalRanges.find((r) => !isEqual(r, expectedRange));
  if (badRange) {
    // Example: suppose most reporting units have 3 cycles of data, but one only
    // has 2. Then the range of cycles for that one, [1, 2] doesn't
    // match the expectation of [1, 2, 3].
    throw new Error(
      `Unexpected range of cycle ordinals for child. Expected ` +
        `${expectedRange} but got ${badRange}`,
    );
  }
};

// Cell rendering for cycle data.
const cellRatedPositive =
  (index) =>
  ({ row }: CellProps) =>
    formatPercent(row.original.pct_rated_positive[index]);

const cellChange =
  (index) =>
  ({ row }: CellProps) =>
    (
      <ChangeText data={row.original.rated_positive_change_num[index]}>
        {row.original.rated_positive_change[index]}
      </ChangeText>
    );

// Cell rendering for cumulative change.
const CellChildCumulativeChange = ({ row }: CellProps) => (
  <ChangeText data={row.original.cumulative_change_num}>
    {row.original.cumulative_change}
  </ChangeText>
);

type Props = {
  data: TimelineChartRow[];
};

export const StudentExperienceChildSurveyTable = ({ data }: Props) => {
  const terms = useTerms();

  const numCycles = data.reduce((n, r) => Math.max(n, r.cycle_ordinal), 1);

  checkDataIsNotSparse(data, numCycles);

  const childSurveyData = mutateToChildSurveyTable(data);

  const columns = useMemo(() => {
    const cols = [
      {
        Header: terms.group,
        id: 'child_name',
        accessor: 'child_name',
      },
      ...Array.from({ length: numCycles }).map((_, i) => ({
        Header: `Survey ${i + 1}`, // index -> ordinal
        id: `survey_${i}`,
        columns: [
          {
            Header: '%',
            id: `pct_rated_positive_${i}`,
            // Accessors allow column sorting. Should provide display values so
            // users understand the sort results.
            accessor: (row: ChildSurveyRow) =>
              formatPercent(row.pct_rated_positive[i]),
            Cell: cellRatedPositive(i),
          },
          {
            Header: '±',
            id: `rated_positive_change_${i}`,
            accessor: (row: ChildSurveyRow) => row.rated_positive_change_num[i],
            Cell: cellChange(i),
            sortType: (
              rowA: { original: ChildSurveyRow },
              rowB: { original: ChildSurveyRow },
            ) => {
              const a = rowA.original.rated_positive_change_num[i];
              const b = rowB.original.rated_positive_change_num[i];
              return sortNumeric(a, b);
            },
          },
        ],
      })),
      {
        Header: '± Since Survey 1',
        id: 'cumulative_change',
        // Accessors allow column sorting. See `sortType` below.
        accessor: (row: ChildSurveyRow) => row.cumulative_change_num,
        Cell: CellChildCumulativeChange,
        sortType: sortTableRowNumeric('cumulative_change_num'),
      },
    ];

    return cols;
  }, [numCycles, terms.group]);

  return (
    <Table columns={columns} data={childSurveyData} numCycles={numCycles} />
  );
};
