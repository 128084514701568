import React, { useCallback, useMemo } from 'react';

import Table from './Table';
import { useTerms } from 'components/TermsContext';
import { Metric, selectMetricReadingLink } from 'models';
import { CellRatedPositivelyAll, cellChange } from '../components';
import { Link } from '@perts/ui';

const standardizeLabel = (label: string) => label.replace(/_/g, '-');

const findMetric = (metrics: Metric[], label: string): Metric | undefined =>
  metrics.find((m) => standardizeLabel(m.label) === standardizeLabel(label));

type Props = {
  data: any;
  metrics: Metric[];
  nResponded: string;
  pctResponded: string;
  reportDate: string;
};

export const StudentExperienceOverviewTable = ({
  data,
  metrics,
  nResponded,
  pctResponded,
  reportDate,
}: Props) => {
  const terms = useTerms();

  // react-table Cell function to display the readable metric name/link.
  const metricName = useCallback(
    (props: any) => {
      const { metric_label } = props.row.original;
      return (
        <a href={`#${standardizeLabel(metric_label)}-data`}>
          {(findMetric(metrics, metric_label) || {}).name}
        </a>
      );
    },
    [metrics],
  );

  const metricLink = useCallback(
    (props: any) => {
      const { metric_label } = props.row.original;
      const metric = findMetric(metrics, metric_label);
      return metric ? (
        <Link to={selectMetricReadingLink(metric)}>Strategies</Link>
      ) : null;
    },
    [metrics],
  );

  const columns = useMemo(
    () => [
      {
        Header: terms.condition,
        id: 'metric_label',
        accessor: 'metric_label',
        Cell: metricName,
      },
      {
        Header: '%',
        id: 'rated_positive_recent_all_num',
        accessor: 'rated_positive_recent_all_num',
        Cell: CellRatedPositivelyAll,
      },
      {
        Header: '±',
        id: 'rated_positive_change_all_num',
        accessor: 'rated_positive_change_all_num',
        Cell: cellChange('all'),
      },
      {
        Header: <>Resources</>,
        id: 'resources',
        Cell: metricLink,
        disableSortBy: true,
      },
    ],
    [metricLink, metricName, terms.condition],
  );

  return (
    <Table
      columns={columns}
      data={data}
      reportDate={reportDate}
      nResponded={nResponded}
      pctResponded={pctResponded}
    />
  );
};
