import { useState } from 'react';
import sortBy from 'lodash/sortBy';

import { Card, Col, HelpText, Row, Text, useBroadcast } from '@perts/ui';

import { channelFullwindow, helpArticles } from 'config';
import {
  ChangeLegend,
  EquityGapLegend,
  getEnabledMetrics,
  MetricSection,
  removeDisabledMetricsFromDataset,
  ReportSettingsLink,
  ReportStyled,
} from '../components';
import { useTerms } from 'components/TermsContext';
import { Network, NetworkDefault21Dataset, Metric, Program } from 'models';
import { toArchiveNetworkReportIdSettings, useParams } from 'pages';
import {
  FidelityChildTable,
  ReportNavigation,
  StudentExperienceTable,
  StudentExperienceOverviewTable,
  SurveySampleOverviewTable,
  TargetGroupTable,
} from '../';

type Props = {
  programMetrics: Metric[];
  reportMetrics: Metric[];
  network: Network;
  dataset: NetworkDefault21Dataset;
  program: Program;
  reportDate: string;
};

export const NetworkReportDefault21Render = ({
  programMetrics,
  reportMetrics,
  network,
  dataset: unfilteredDataset,
  program,
  reportDate,
}: Props) => {
  const { reportId } = useParams();
  const terms = useTerms();

  const [isFullwindow, setFullwindow] = useState(false);
  const { listen } = useBroadcast(channelFullwindow);
  listen((shouldExpand) => setFullwindow(shouldExpand));

  const enabledMetrics = getEnabledMetrics(network, reportMetrics);

  const dataset = removeDisabledMetricsFromDataset<NetworkDefault21Dataset>(
    unfilteredDataset,
    enabledMetrics,
  );

  const showExport = dataset.csv_download_cols && dataset.csv_download_df;

  return (
    <ReportStyled fullscreen={isFullwindow}>
      <ReportNavigation
        metrics={enabledMetrics}
        program={program}
        showExport={Boolean(showExport)}
      />
      <Row>
        <Col cols={1}></Col>
        <Col cols={10}>
          <h1>
            {program.name} {terms.network} Report for {network.name}
          </h1>
        </Col>
        <Col cols={1} colsSm={12} vAlign="center" hAlign="center">
          <ReportSettingsLink
            enabledMetrics={enabledMetrics}
            reportMetrics={reportMetrics}
            to={toArchiveNetworkReportIdSettings(network.uid, reportId)}
          />
        </Col>
      </Row>
      <Row>
        <Col cols={1}></Col>
        <Col cols={10}>
          <h2 id="student-experience-overview">
            {terms.participant} Experience Overview
          </h2>
        </Col>
        <Col cols={1}></Col>
      </Row>
      <Card>
        <Card.Content>
          <Row>
            <Col cols={6}>
              <HelpText articleId={helpArticles.studentExperienceOverviewTable}>
                How Can I Use the {terms.participant} Experience Overview?
              </HelpText>
            </Col>
            <Col cols={6} hAlign="flex-end">
              <ChangeLegend />
            </Col>
          </Row>
          <StudentExperienceOverviewTable
            data={dataset.student_experience_overview}
            metrics={programMetrics}
            nResponded={dataset.n_responded_participants}
            pctResponded={dataset.pct_responded}
            reportDate={reportDate}
          />
        </Card.Content>
      </Card>

      <h2 id="equity-overview">Equity Overview</h2>

      <Card>
        <Card.Content>
          <Row>
            <Col cols={6}>
              <HelpText articleId={helpArticles.equityOverviewTable}>
                How Can I Use the Equity Overview?
              </HelpText>
            </Col>
            <Col cols={6} hAlign="flex-end">
              <EquityGapLegend />
            </Col>
          </Row>
          <StudentExperienceTable
            data={dataset.student_experience_overview}
            metrics={programMetrics}
            nResponded={dataset.n_responded_participants}
            pctResponded={dataset.pct_responded}
            reportDate={reportDate}
          />
        </Card.Content>
      </Card>

      <h2 id="survey-response-rates">Survey Response Rates</h2>

      <Card>
        <Card.Content>
          <Row>
            <Col>
              <Text as="h3">Participation by Subpopulation</Text>
            </Col>
          </Row>
          <Row>
            <Col>
              <HelpText
                articleId={helpArticles.participationBySubpopulationTable}
              >
                How Can I Use the Participation by Subpopulation Table?
              </HelpText>
            </Col>
          </Row>
          <SurveySampleOverviewTable
            data={dataset.survey_sample_overview}
            reportDate={reportDate}
            networkId={network.short_uid}
          />
        </Card.Content>
      </Card>

      {/* applies to GROUP reports */}
      {/* applies to NETWORK reports */}
      {/* Commented out per https://github.com/PERTS/perts/issues/529 */}
      {/*
    <Card>
      <Card.Content>
        <Row>
          <Col>
            <Text as="h3">Participation by {childTerm}</Text>
          </Col>
        </Row>
        <Row>
          <Col>
            <HelpText articleId={helpArticles.participationByChildTable}>
              How Can I Use the Participation Table?
            </HelpText>
          </Col>
        </Row>
        <ChildParticipationTable
          childTerm={childTerm}
          data={dataset.child_participation_table}
          reportDate={reportDate}
        />
      </Card.Content>
    </Card>
    */}

      {/* applies to NETWORK reports */}
      <h2 id="survey-fidelity">Survey Fidelity</h2>

      <Card>
        <Card.Content>
          <Row>
            <Col>
              <HelpText articleId={helpArticles.surveyFidelityTable}>
                How Can I Use the Survey Fidelity Table?
              </HelpText>
            </Col>
          </Row>
          <FidelityChildTable
            childTerm={terms.group}
            data={dataset.fidelity_child_table}
            reportDate={reportDate}
          />
        </Card.Content>
      </Card>

      {sortBy(enabledMetrics, 'name').map((metric) => {
        const metricData = dataset.metrics.find(
          (md) => metric.label === md.label.replace(/_/g, '-'),
        );

        return metricData ? (
          <MetricSection
            key={metric.label}
            metric={metric}
            metricData={metricData}
            network={network}
            reportDate={reportDate}
          />
        ) : null;
      })}

      {dataset.target_group_table && (
        <>
          {/* applies to GROUP reports */}
          {/* applies to NETWORK reports */}
          <h2 id="focal-group-summary">Focal Group Summary</h2>

          <Card>
            <Card.Content>
              <Row>
                <Col>
                  <HelpText articleId={helpArticles.targetGroupSummary}>
                    How Can I Use the Focal Group Summary?
                  </HelpText>
                </Col>
              </Row>
              <TargetGroupTable data={dataset.target_group_table} />
            </Card.Content>
          </Card>
        </>
      )}
    </ReportStyled>
  );
};
