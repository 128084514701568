import { BrowserRouter as Router } from 'react-router-dom';

import { PagesRouter } from 'pages';
import Redirector from 'scenes/Redirector';
import {
  ImpersonateUserBanner,
  Prompts,
  RenderWithTermsContext,
} from 'components';

// Exportable so that we can wrap this router inside a MemoryRouter in tests.
// See src/scenes/TaskModule/index.test.js
export const AppRouterRouting = PagesRouter;

const AppRouter = ({ userIsLoggedIn }) => (
  <Router>
    <Redirector />
    <Prompts />
    <ImpersonateUserBanner />
    <RenderWithTermsContext>
      <AppRouterRouting userIsLoggedIn={userIsLoggedIn} />
    </RenderWithTermsContext>
  </Router>
);

export default AppRouter;
