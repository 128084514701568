import { Route, Switch } from 'react-router-dom';
import {
  Archive,
  ArchiveClassReport,
  ArchiveGroupReport,
  ArchiveNetworkReport,
  Reports,
  toArchive,
  toArchiveClassReport,
  toArchiveClassReportId,
  toArchiveGroup,
  toArchiveGroupReport,
  toArchiveGroupReportId,
  toArchiveNetworkReport,
  toArchiveNetworkReportId,
  toArchiveProgram,
} from 'pages';
import { ReportsRouter as OldReportsRouter } from './Archive/OldReports';

export const PagesRouterArchive = () => (
  <Switch>
    <Route
      path={[
        toArchiveNetworkReportId(),
        toArchiveGroupReportId(),
        toArchiveClassReportId(),
      ]}
    >
      <OldReportsRouter />
    </Route>

    <Route path={toArchiveClassReport()}>
      <ArchiveClassReport />
    </Route>

    <Route path={toArchiveGroupReport()}>
      <ArchiveGroupReport />
    </Route>

    <Route path={toArchiveNetworkReport()}>
      <ArchiveNetworkReport />
    </Route>

    <Route path={toArchiveGroup()}>
      <Reports
        toGroupReport={toArchiveGroupReport}
        toClassReport={toArchiveClassReport}
      />
    </Route>

    <Route path={toArchiveProgram()}>
      <Archive />
    </Route>

    <Route path={toArchive()}>
      <Archive />
    </Route>
  </Switch>
);
