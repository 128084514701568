import React, { Fragment } from 'react';
import classNames from 'classnames';
import styled from 'styled-components/macro';
import { css } from 'styled-components';
import { InputField } from '../InputField';
import { InputFieldError } from '../InputFieldError';
import { Listbox } from '@headlessui/react';
import { IconCheck, IconSelector } from '../Icon';
import { Show } from '../../.';
import theme from '../theme';

// Reference: https://material-ui.com/api/select/
// Reference: https://headlessui.dev/react/listbox
// Might be fine styling up Headless if it has the functionality we need.

type ListboxContainerProps = {
  theme: any;
  fullWidth?: boolean;
  height?: number;
  labelPlacement: 'top' | 'start';
};

type SelectButtonContainerProps = React.HTMLProps<HTMLDivElement> & {
  error: any;
  fullWidth?: boolean;
  theme: any;
};

type OptionContainerProps = {
  theme: any;
  selected?: boolean;
  active?: boolean;
};

export type SelectProps = {
  // The content.
  children?: React.ReactNode;

  // To allow styled-components wrapping.
  className?: string;

  // Whether the radio is disabled.
  disabled?: boolean;

  // Whether or not to reserve height for and display errors; default true.
  displayErrors?: boolean;

  // Field error.
  error?: any;

  // Help node.
  helpText?: React.ReactNode;

  // Field label.
  label: any;

  // Field name.
  name: string;

  // Callback function fired when a menu item is selected.
  onChange?: (value: string) => void;

  // Callback function fired when a menu item is selected using Formik.
  setValue?: (value: string) => void;

  // Callback function fired when a menu item is selected using Formik.
  setTouched?: (value: boolean) => void;

  // No readOnly.
  // > The attribute is not supported or relevant to <select> or input types
  // > that are already not mutable, such as checkbox and radio...
  // https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/readonly

  // Indicate the field is required. This component does not handle validation.
  required?: boolean;

  // Field value.
  value: any;

  // If true, the input will take up the full width of its container.
  fullWidth?: boolean;

  // Max height the input will take up.
  height?: number;

  options: any[];

  // Key name to value element
  keyBy: string;

  // Key name to name element
  keyName: string;

  // Field placeholder name.
  placeholder?: string;

  // Position of the label. Defaults to 'top'.
  labelPlacement?: 'top' | 'start';

  // It is true if the input was touched.
  touched?: boolean;
};

export const Select = ({
  className,
  disabled,
  displayErrors = true,
  error,
  fullWidth,
  height = 440,
  helpText,
  keyBy = 'uid',
  keyName = 'name',
  label,
  labelPlacement = 'top',
  name,
  onChange,
  options = [],
  placeholder = 'Select',
  setTouched,
  setValue,
  touched,
  value,
}: SelectProps) => {
  const cx = classNames(className);

  const selectedOption = options.find((option) => option[keyBy] === value);

  const isError = error && touched;

  const handleOnChange = (newValue: any) => {
    if (setValue && setTouched) {
      setValue(newValue);
      setTouched(true);
    } else if (onChange) {
      onChange(newValue);
    }
  };

  return (
    <InputField className={cx}>
      <LabelContainer>
        {helpText && <HelpText>{helpText}</HelpText>}
      </LabelContainer>
      <ListboxContainer
        fullWidth={fullWidth}
        height={height}
        labelPlacement={labelPlacement}
      >
        <Listbox
          value={selectedOption ? selectedOption[keyBy] : undefined}
          onChange={handleOnChange}
          disabled={disabled}
        >
          {label && <Listbox.Label>{label}</Listbox.Label>}
          <ListboxDropdownContainer>
            <SelectButtonContainer error={isError} disabled={disabled}>
              <Listbox.Button>
                <span>
                  {selectedOption ? selectedOption[keyName] : placeholder}{' '}
                </span>
                <IconSelector />
              </Listbox.Button>
            </SelectButtonContainer>
            <Listbox.Options>
              {options.map((option) => (
                <Listbox.Option
                  key={option[keyBy]}
                  value={option[keyBy]}
                  as={Fragment}
                >
                  {({ selected, ...optionProps }) => (
                    <OptionContainer selected={selected} {...optionProps}>
                      <Show when={selected}>
                        <IconCheckStyled />
                      </Show>
                      {option[keyName]}
                    </OptionContainer>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </ListboxDropdownContainer>
        </Listbox>
      </ListboxContainer>
      <Show when={displayErrors}>
        <InputFieldError
          role="alert"
          aria-live="assertive"
          id={`${name}-text-field-error`}
          data-testid={`${name}-text-field-error`}
        >
          {error && touched ? error : ''}
        </InputFieldError>
      </Show>
    </InputField>
  );
};

const HelpText = styled.span`
  margin-left: 6px;
`;

const LabelContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const ListboxDropdownContainer = styled.div`
  position: relative;
`;

const ListboxContainer = styled.div<ListboxContainerProps>`
  // Allows the position: absolute below to be aware of this parent container
  // in terms of width. Without this, the dropdown won't adjust its width to
  // 100% of this parent.
  position: relative;

  label {
    font-weight: 700;
    display: inline-block;
    line-height: 1em;
    margin-bottom: 5px;
  }

  ${(props) =>
    props.labelPlacement === 'start' &&
    css`
      display: flex;
      align-items: center;

      label {
        margin-right: ${props.theme.units.gridDefaultGutter}px;
      }

      // should apply only to SelectButtonContainer
      > div {
        flex-grow: 1;
      }
    `}

  ul {
    padding: 0;
    border: 1px solid ${(props) => props.theme.colors.grayLight};
    border-radius: ${(props) => props.theme.units.borderRadius};
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
      0 4px 6px -2px rgba(0, 0, 0, 0.05);
    position: absolute;
    width: 100%;
    z-index: 5;

    // Limit the height of the drop down.
    max-height: ${(props) => props.height}px;
    overflow-y: scroll;

    // Always display scrollbar so knows when list is longer than height.
    // Source:
    //   https://stackoverflow.com/a/10100209
    // Note, this probably won't work in all browsers:
    //   https://developer.apple.com/forums/thread/670065
    ::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 7px;
      background: rgba(255, 255, 255, 1);
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background-color: rgba(0, 0, 0, 0.5);
      box-shadow: 0 0 1px rgba(255, 255, 255, 1);
    }
  }

  button {
    width: ${(props) => (props.fullWidth ? '100%' : '')};
  }
`;

ListboxContainer.defaultProps = {
  theme,
};

const SelectButtonContainer = styled.div<SelectButtonContainerProps>`
  > button {
    background: red;
    position: relative;
    display: flex;
    align-items: center;
    padding: ${(props) => props.theme.units.paddingSm};
    padding-right: 30px;
    background: ${(props) => props.theme.colors.white};
    border: 1px solid ${(props) => props.theme.colors.grayMedium};
    border-radius: ${(props) => props.theme.units.borderRadius};

    ${(props) =>
      props.error &&
      css`
        border-color: ${props.theme.colors.warning};
      `};

    ${(props) =>
      props.disabled &&
      css`
        background: ${props.theme.colors.fieldBackgroundDisabled};
        color: ${props.theme.colors.text};

        border: 1px dashed ${props.theme.colors.grayMedium};

        cursor: not-allowed;
      `};
  }

  //Check icon
  > button > :last-child {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    font-size: 24px;
    display: flex;
  }
`;

SelectButtonContainer.defaultProps = {
  theme,
};

const OptionContainer = styled.li<OptionContainerProps>`
  position: relative;
  background: ${(props) =>
    props.active ? props.theme.colors.primaryLight : props.theme.colors.white};
  list-style: none;
  padding: ${(props) => props.theme.units.fieldPadding};
  padding-left: 35px;
`;

OptionContainer.defaultProps = {
  theme,
};

const IconCheckStyled = styled(IconCheck)`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 5px;
  font-size: 28px;
  display: flex;
  color: ${(props) => props.theme.colors.primary};
`;
