import { getAuthUser } from 'services/triton/helpers/getAuthUser';
import { useQuery } from 'react-query';
import {
  User,
  useQueryWithPagination,
  userGetById,
  usersQueryPagination,
  usersQueryByGroupId,
  usersQueryByNetworkId,
} from 'models';
import { useParams } from 'pages';

import { useImpersonate } from 'utils/useImpersonate';
import { QueryParams } from 'services/triton/helpers';

export * from './useCurrentUserUpdate';

// -----------------------------------------------------------------------------
//   queryKey Generators
// -----------------------------------------------------------------------------

export const queryKeyCurrentUser = () => 'currentUser';

export const queryKeyUser = (userId: string) => ['user', userId];

export const queryKeyUsersByGroup = (groupId: string) => [
  'users',
  'byGroup',
  groupId,
];

export const queryKeyUsersByNetwork = (networkId: string) => [
  'users',
  'byNetwork',
  networkId,
];

// -----------------------------------------------------------------------------
//   API Hooks
// -----------------------------------------------------------------------------

// Returns the User object associated with the imitated User's uid (if set) or
// the authenticated User's uid.

export const useCurrentUserQuery = () => {
  const { getImpersonateUid } = useImpersonate();
  const uid = getImpersonateUid() || getAuthUser()?.uid;

  return useQuery<User, Error>(
    // queryKey
    queryKeyCurrentUser(),
    // queryFn
    () => userGetById(uid),
    {
      enabled: Boolean(uid),
    },
  );
};

export const useUsersQueryByGroupId = (groupId: string) =>
  useQuery<User[], Error>(
    // queryKey
    queryKeyUsersByGroup(groupId),
    // queryFn
    () => usersQueryByGroupId(groupId),
  );

export const useUsersQueryByParams = () => {
  const { groupId } = useParams();
  return useUsersQueryByGroupId(groupId);
};

export const useUserGetByParams = () => {
  const { userId } = useParams();
  return useQuery<User, Error>(
    // queryKey
    queryKeyUser(userId),
    // queryFn
    () => userGetById(userId),
    // options
    {
      enabled: Boolean(userId),
    },
  );
};

export const useUsersQueryByNetworkId = (networkId: string) =>
  useQuery<User[], Error>(
    // queryKey
    queryKeyUsersByNetwork(networkId),
    // queryFn
    () => usersQueryByNetworkId(networkId),
  );

const queryKeyUsersQuery = (name: string, paging) => [name, paging];

export const useUsersPagination = (
  queryParams: QueryParams = {},
  queryOptions = {},
) => {
  const queryFn = () => usersQueryPagination(queryParams);
  const queryKey = queryKeyUsersQuery(queryParams.name || '', {
    pageIndex: queryParams.pageIndex,
  });
  return useQueryWithPagination<User[]>(queryKey, queryFn, queryOptions);
};
