import { Results } from '@perts/model';

import { AnyDataset, Metric } from 'models';

export const getReportMetricsRserve = (
  dataset: AnyDataset,
  allMetrics: Metric[],
) => {
  const reportMetricLabels = dataset.metrics
    // Although a "metric" object may be defined in the dataset, sometimes
    // there's no real data behind it, and the charts are empty/undefined.
    // In that case we want to ignore it completely: no link in the nav menu,
    // no section in the content.
    .filter((metricData) => Boolean(metricData.bar_chart_64))
    .map((metricData) => metricData.label.replace(/_/g, '-'));
  return allMetrics.filter(({ label }) => reportMetricLabels.includes(label));
};

export const getAvailableMetrics = (results: Results, allMetrics: Metric[]) => {
  const resultsMetricLabels = Object.keys(results.experience);
  return allMetrics.filter(({ label }) => resultsMetricLabels.includes(label));
};
