import { useTable, useSortBy, usePagination } from 'react-table';

import { DEFAULT_PAGE_SIZE } from 'config';
import {
  ReactTableFooter,
  ReactTableHead,
  ReactTablePagination,
  ReactTableSortIcon,
  ReactTableTd,
  ReactTableTh,
  ReactTableTr,
  ReactTableWrap,
} from 'components';
import { StudentExperienceChildSurveyTableStyled } from './StudentExperienceChildSurveyTableStyled';

export const Table = ({ columns, data, numCycles }) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,

    // from usePagination
    // https://codesandbox.io/embed/github/tannerlinsley/react-table/tree/master/examples/pagination
    page,
    canPreviousPage,
    canNextPage,
    // pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      // https://react-table.js.org/api/useSortBy#table-options
      initialState: {
        sortBy: [{ id: 'child_name' }],
        pageSize: DEFAULT_PAGE_SIZE,
      },
    },
    useSortBy,
    usePagination,
  );

  return (
    <ReactTableWrap>
      <StudentExperienceChildSurveyTableStyled {...getTableProps()}>
        <ReactTableHead>
          <tr>
            <ReactTableTh>{/* above child name column header */}</ReactTableTh>
            <ReactTableTh colSpan={numCycles * 2} align="center">
              <div>
                Rated Positively on Last Survey
                <br />± change since previous survey
              </div>
            </ReactTableTh>
            <ReactTableTh>
              {/* above cumulative change column header*/}
            </ReactTableTh>
          </tr>

          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <ReactTableTh
                  {...column.getHeaderProps(
                    column.getSortByToggleProps([{ align: column.align }]),
                  )}
                >
                  {column.render('Header')}
                  <ReactTableSortIcon {...column} />
                </ReactTableTh>
              ))}
            </tr>
          ))}
        </ReactTableHead>

        <tbody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);

            return (
              <ReactTableTr {...row.getRowProps()}>
                {row.cells.map((cell) => (
                  <ReactTableTd cell={cell} {...cell.getCellProps()} />
                ))}
              </ReactTableTr>
            );
          })}
        </tbody>
      </StudentExperienceChildSurveyTableStyled>

      <ReactTableFooter>
        <ReactTablePagination
          canNextPage={canNextPage}
          canPreviousPage={canPreviousPage}
          gotoPage={gotoPage}
          nextPage={nextPage}
          pageCount={pageCount}
          pageIndex={pageIndex}
          pageSize={pageSize}
          previousPage={previousPage}
          setPageSize={setPageSize}
          totalRows={data.length}
        />
      </ReactTableFooter>
    </ReactTableWrap>
  );
};
