import * as Yup from 'yup';
import { useContext } from 'react';
import { Formik, Form } from 'formik';
import moment from 'moment';
import { message } from 'antd';

import {
  Button,
  Card,
  Col,
  IconChevronRight,
  Input,
  Row,
  Select,
  Text,
} from '@perts/ui';

import { Class, Cycle, selectClassCycles } from 'models';
import { dateFormatShort } from 'config';
import TermsContext from 'components/TermsContext';
import { usePermissions } from 'utils';

type NamedCycle = Cycle & { keyName: string };

const getNamedCycle = (cycle) => {
  const prefix = `Survey ${cycle.ordinal}`;
  if (cycle.start_date && cycle.end_date) {
    const startFormatted = moment(cycle.start_date).format(dateFormatShort);
    const endFormatted = moment(cycle.end_date).format(dateFormatShort);
    const name = `${prefix}: ${startFormatted} - ${endFormatted}`;
    return { ...cycle, name };
  }

  const name = `${prefix}: unscheduled`;
  return { ...cycle, name };
};

const getCycleOptions = (
  classes: Class[],
  classId: string,
  cycles: Cycle[],
) => {
  const classSelected = classes.find(({ uid }) => uid === classId);

  if (!classSelected) {
    return [];
  }

  const classCycles = selectClassCycles(classSelected, cycles);
  const scheduledCycles = classCycles.filter((c) => c.start_date && c.end_date);
  const namedCycles: NamedCycle[] = scheduledCycles.map(getNamedCycle);

  return namedCycles;
};

export type ChooseCycleProgressFormValues = {
  cycle?: Cycle;
  cls: Class;
};

type ChooseCycleProgressFormProps = {
  classes: Class[];
  cycles: Cycle[];
  onSubmit: (values: ChooseCycleProgressFormValues) => void;
};

export const ChooseCycleProgressForm = ({
  classes,
  cycles,
  onSubmit,
}: ChooseCycleProgressFormProps) => {
  const terms = useContext(TermsContext);
  const { isAdmin } = usePermissions();

  const normalSchema = Yup.object().shape({
    classId: Yup.string().required(`Required`),
    cycleId: Yup.string().required(`Required`),
  });
  const adminSchema = Yup.object().shape({
    classId: Yup.string().required(`Required`),
    cycleId: Yup.string().notRequired(),
  });

  // Add a display name to cycles for the select field.
  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        classId: '',
        cycleId: 'all',
      }}
      validationSchema={isAdmin ? adminSchema : normalSchema}
      onSubmit={async ({ classId, cycleId }) => {
        try {
          const cls = classes.find(({ uid }) => uid === classId);
          const cycle = cycles.find(({ uid }) => uid === cycleId);

          if (!cls) {
            throw new Error('Submit required class.');
          }

          // cycleId may be 'all' in which case cycle will be undefined, that's
          // expected, and will trigger data to load for all cycles.

          await onSubmit({ cls, cycle });
        } catch (error) {
          message.error(`There was an error getting the progress information.`);
        }
      }}
      validateOnBlur={false}
    >
      {({ isSubmitting, isValid, dirty, values }) => (
        <Form>
          <Card>
            <Card.Title>
              <Text as="h3">View Participation By {terms.participant}</Text>
            </Card.Title>
            <Card.Content>
              <Row alignItems="flex-end">
                <Col cols={6} colsSm={12}>
                  <Input
                    component={Select}
                    displayErrors={false}
                    fullWidth
                    id="class"
                    keyBy="uid"
                    keyName="name"
                    label={terms.class}
                    labelPlacement="top"
                    name="classId"
                    options={classes}
                    placeholder={`Select ${terms.aClass}`}
                  />
                </Col>
                <Col cols={6} colsSm={12}>
                  <Input
                    component={Select}
                    displayErrors={false}
                    fullWidth
                    id="cycle"
                    keyBy="uid"
                    keyName="name"
                    label="Survey"
                    labelPlacement="top"
                    name="cycleId"
                    options={[
                      { uid: 'all', name: 'All' },
                      ...getCycleOptions(classes, values.classId, cycles),
                    ]}
                    disabled={!Boolean(values.classId)}
                  />
                </Col>
              </Row>
              <Button
                type="submit"
                fullWidth
                disabled={!isValid || isSubmitting || !dirty}
                loading={isSubmitting}
                data-testid="submit"
                iconRight={<IconChevronRight />}
              >
                View
              </Button>
            </Card.Content>
          </Card>
        </Form>
      )}
    </Formik>
  );
};
