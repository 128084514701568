import { NetworkReportDefault21Render, NetworkReportLoader } from './';

export const NetworkReportDefault21 = () => (
  <NetworkReportLoader>
    {({
      dataset,
      network,
      program,
      programMetrics,
      reportDate,
      reportMetrics,
    }) => (
      <NetworkReportDefault21Render
        dataset={dataset}
        network={network}
        program={program}
        programMetrics={programMetrics}
        reportDate={reportDate}
        reportMetrics={reportMetrics}
      />
    )}
  </NetworkReportLoader>
);
