import { useFormikContext } from 'formik';
import * as Yup from 'yup';
import { Modal, Row, Col, Button, Input, TextField } from '@perts/ui';

export const initialValues = {
  questionPromptByOrg: '',
};

export const validationSchema = Yup.object().shape({});

export const Component = () => {
  const { isSubmitting, isValid, dirty } = useFormikContext();

  return (
    <>
      <Modal.Title className="center">Demo Prompt By Organization</Modal.Title>
      <Row>
        <Col>
          This prompt displays based on an allowed rule on organization_id.
        </Col>
      </Row>

      <Row>
        <Col>
          <Input
            id="questionPromptByOrg"
            name="questionPromptByOrg"
            label="Please respond to the question"
            labelPlacement="top"
            component={TextField}
            disabled={isSubmitting}
            fullWidth
            displayErrors={false}
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <Button
            type="submit"
            fullWidth
            disabled={!isValid || isSubmitting || !dirty}
            loading={isSubmitting}
            data-testid="submit-btn"
          >
            Submit Response
          </Button>
        </Col>
      </Row>
    </>
  );
};
