import { portalTypesWithEndings } from '@perts/model';

import { getRosterIdRuleClassProps } from 'pages/Classes/shared';
import { RosterSignOnRuleFormValues } from './RosterSignOnRuleForm';

export const getRosterSignOnRuleSettings = ({
  values,
}: {
  values: RosterSignOnRuleFormValues;
}) => {
  const studentEmailEnding = portalTypesWithEndings.includes(values.portalType)
    ? values.studentEmailEnding
    : '';

  const rosterSignOnRuleProps = getRosterIdRuleClassProps({
    ruleSwitch: values.ruleEnabled,
    portal_type: values.portalType,
    portal_message: values.portalMessage,
    rule: studentEmailEnding,
    studentIdMin: values.studentIdMin,
    studentIdMax: values.studentIdMax,
  });

  return rosterSignOnRuleProps;
};
