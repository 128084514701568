import { Button, Col, IconChevronLeft, Row, Text } from '@perts/ui';
import { useTerms } from 'components';
import { toGroupReport, useParams } from 'pages';
import { ReportSettingsGroupEnabledMetrics } from './ReportSettingsGroupEnabledMetrics';
import { ReportSettingsGroupDisable } from './ReportSettingsGroupDisable';
import { ReportNavigationStyled } from '../ReportNavigation/ReportNavigationStyled';
import { ReportNavigationCard } from '../ReportNavigation/ReportNavigationCard';

export const ReportSettingsGroup = () => {
  const { groupId } = useParams();
  const terms = useTerms();

  return (
    <>
      <ReportNavigationStyled role="navigation">
        <ReportNavigationCard>
          <ReportNavigationCard.Content>
            <Row>
              <Col>
                <Button
                  color="secondary"
                  iconLeft={<IconChevronLeft />}
                  to={toGroupReport(groupId)}
                >
                  Back to report
                </Button>
              </Col>
            </Row>
          </ReportNavigationCard.Content>
        </ReportNavigationCard>
      </ReportNavigationStyled>
      <Text as="h1">{terms.group} Report Settings</Text>
      <ReportSettingsGroupDisable />
      <ReportSettingsGroupEnabledMetrics />
    </>
  );
};
