import { Col, Row } from '@perts/ui';

const VerifyLinkErrors = () => (
  <div data-test="VerifyLinkError">
    <Row>
      <Col>
        <h2>We&rsquo;re sorry!</h2>
        <p>
          There seems to have been a problem sending your email. Please refresh
          the page and try again.
        </p>
      </Col>
    </Row>
  </div>
);

export default VerifyLinkErrors;
