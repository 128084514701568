// Custom hook to track the state of multiple toggles/checks.
//
// Example usage:
//
// const exampleItems = [
//   {
//     uid: 'User_001',
//     isManager: true,
//   },
//   {
//     uid: 'User_002',
//     isManager: false,
//   },
//   {
//     uid: 'User_003',
//     isManager: true,
//   },
// ];
//
// const exampleKeyBy = 'uid';
//
// const { toggleStates, toggleChecked, isChecked } = useToggles(
//   exampleItems, 'uid',
// );
//
// toggleStates === {
//   User_001: { checked: false, indeterminate: false },
//   User_002: { checked: false, indeterminate: false },
//   User_003: { checked: false, indeterminate: false },
// };
//
// toggleChecked('User_001');
// isChecked('User_001') === true;

import { useEffect, useState } from 'react';
import fpFlow from 'lodash/fp/flow';
import fpKeyBy from 'lodash/fp/keyBy';
import fpMapValues from 'lodash/fp/mapValues';
import { every, isEmpty, some, mapValues } from 'lodash';
import { Checkbox } from '@perts/ui';

export type CheckedState = boolean;
export type CheckedStates = { [uid: string]: CheckedState };

export type ToggleState = { checked: boolean; indeterminate: boolean };
export type ToggleStates = { [uid: string]: ToggleState };

export type ControlToggleProps = { label: string };
export type ControlToggleType = ({ label }: ControlToggleProps) => any;

export type ToggleProps = { id: string; label?: string | React.ReactNode };
export type ToggleType = ({ id, label }: ToggleProps) => any;

type UseToggles = (
  items: any[],
  keyBy: string,
  options?: {
    // The input component to be rendered as ControlToggle.
    // Default @perts/ui Switch.
    controlComponent?: any;

    // The input component to be rendered as the Toggle.
    // Default @perts/ui Switch.
    toggleComponent?: any;

    // An initial checked/indeterminate state object of the toggles. If no
    // initialState is provided, then all checked and indeterminate states will
    // start out false.
    initialState?: ToggleStates;

    // When we use paged items, the current page items will be on this param
    pageItems?: any[];

    // When we use filtered items, the filtered items will be on this param
    filteredItems?: any[];
  },
) => {
  // Leaving for for backwards compatibility.
  checked: { [key: string]: boolean };

  // Items filtered by those that have been checked.
  // TODO? change name to `checked`. Replacing the above.
  selected: any[];

  // An array of the { checked, indeterminate } state of all items.
  toggleStates: ToggleStates;
  // Returns the { checked, indeterminate } state of the item with provided key.
  toggleState: (key: string) => ToggleState;

  // Is true if any item is either checked or indeterminate.
  someMarked: boolean;

  // Is true all current page is selected
  allPageIsSelected: boolean;

  // Total items selected of the current page
  totalPageItemsSelected: number;

  // Returns true if the item with provided key is checked.
  isChecked: (key: string) => boolean;
  // Returns true if the item with provided key is indeterminate.
  isIndeterminate: (key: string) => boolean;

  // Toggles the { checked, indeterminate } state of an individual item.
  toggleChecked: (key: string) => void;
  // Toggle the { checked, indeterminate } states of all items.
  toggleAll: () => void;

  // Toggle the { checked, indeterminate } states of all items on current page.
  toggleAllPage: () => void;

  // Toggle the { checked, indeterminate } states of all filtered items.
  toggleAllFilter: () => void;

  // The ControlToggle renders out the select/deselect all items UI component.
  ControlToggle: ControlToggleType;
  // Toggle renders out the individual item toggle.
  Toggle: ToggleType;
};

const keyByToggleState = (
  keyBy: string,
  toggleStates = { checked: false, indeterminate: false },
) =>
  fpFlow(
    fpKeyBy(keyBy),
    fpMapValues(() => toggleStates),
  );

const keyByToggleStatePage = (
  keyBy: string,
  toggleStatesTo = { checked: false, indeterminate: false },
  pageItems: any[],
  currentToggleStates: ToggleStates,
) =>
  fpFlow(
    fpKeyBy(keyBy),
    fpMapValues((item: any) => {
      if (pageItems.find((p) => p.uid === item.uid)) {
        return toggleStatesTo;
      }
      return currentToggleStates[item.uid];
    }),
  );

const keyByToggleStateFilter = (
  keyBy: string,
  toggleStatesTo = { checked: false, indeterminate: false },
  filteredItems: any[],
  currentToggleStates: ToggleStates,
) =>
  fpFlow(
    fpKeyBy(keyBy),
    fpMapValues((item: any) => {
      if (filteredItems.find((p) => p.uid === item.uid)) {
        return toggleStatesTo;
      }
      return currentToggleStates[item.uid];
    }),
  );

// eslint-disable-next-line complexity
const useToggles: UseToggles = (items, keyBy, options = {}) => {
  const {
    controlComponent: ControlComponent = Checkbox,
    toggleComponent: ToggleComponent = Checkbox,
    initialState,
    pageItems = [],
    filteredItems = [],
  } = options;

  const isPaged = pageItems.length > 0;

  // Create inputRefs like object that can be provided as checkbox refs.
  const toggleStateInitial = initialState || keyByToggleState(keyBy)(items);

  const [toggleStates, setToggleStates] = useState(toggleStateInitial);

  // Update `toggleStates` when there are changes to the provided `items` array.
  useEffect(
    () => {
      setToggleStates(toggleStateInitial);
    },
    // Using stringify to determine when the object actually changes instead of
    // when the object is changing due to parent rerendering. Else we end up
    // with infinite rerenders since the state change here bubbles up to parent
    // and the back down.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(toggleStateInitial)],
  );

  const toggleState = (key) => toggleStates[key];

  const isChecked = (key) => toggleStates[key]?.checked;
  const isIndeterminate = (key) => toggleStates[key]?.indeterminate;

  const checked = mapValues(toggleStates, (i) => i.checked);
  const selected = items.filter((i) => isChecked(i[keyBy]));

  const allChecked =
    !isEmpty(toggleStates) && every(toggleStates, (i) => i.checked);
  const someChecked =
    !isEmpty(toggleStates) && some(toggleStates, (i) => i.checked);

  const allCheckedPage =
    !isEmpty(pageItems) && every(pageItems, (item) => isChecked(item.uid));
  const someCheckedPage =
    !isEmpty(pageItems) && some(pageItems, (item) => isChecked(item.uid));

  const allCheckedFiltered =
    !isEmpty(filteredItems) &&
    every(filteredItems, (item) => isChecked(item.uid));

  // someMarked is provided for forms/buttons/etc. that need to know if any of
  // the toggles are checked OR indeterminate. This allows those UI elements to
  // be disabled/enabled based on this.
  const itemIsCheckedOrIndeterminate = (i) => i.checked || i.indeterminate;
  const someMarked = some(toggleStates, itemIsCheckedOrIndeterminate);
  const someMarkedPage = some(pageItems, (item) => isChecked(item.uid));

  const totalPageItemsSelected = pageItems.filter((i) =>
    isChecked(i.uid),
  ).length;
  const allPageIsSelected = totalPageItemsSelected === pageItems.length;

  // Toggle the { checked, indeterminate } state of an individual toggle.
  const toggleChecked = (key) => {
    setToggleStates((currentState) => ({
      ...currentState,
      [key]: {
        // Set true when currently !checked or indeterminate. Else false.
        checked: !currentState[key].checked || currentState[key].indeterminate,
        // Any user interaction will remove the indeterminate state.
        indeterminate: false,
      },
    }));
  };

  // Toggle the { checked, indeterminate } states of all toggles.
  const toggleAll = () => {
    const setToggleStatesTo = {
      // Set true when any currently !checked. Else false.
      checked: !allChecked,
      // Any user interaction will remove the indeterminate state.
      indeterminate: false,
    };

    const newToggleStates = keyByToggleState(keyBy, setToggleStatesTo)(items);
    setToggleStates(newToggleStates);
  };

  const toggleAllPage = () => {
    const setToggleStatesTo = {
      // Set true when any currently on page !checked. Else false.
      checked: !allCheckedPage,
      // Any user interaction will remove the indeterminate state.
      indeterminate: false,
    };

    const newToggleStates = keyByToggleStatePage(
      keyBy,
      setToggleStatesTo,
      pageItems,
      toggleStates,
    )(items);
    setToggleStates(newToggleStates);
  };

  const toggleAllFilter = () => {
    const setToggleStatesTo = {
      // Set true when any filter item is !checked. Else false.
      checked: !allCheckedFiltered,
      // Any user interaction will remove the indeterminate state.
      indeterminate: false,
    };

    const newToggleStates = keyByToggleStateFilter(
      keyBy,
      setToggleStatesTo,
      filteredItems,
      toggleStates,
    )(items);
    setToggleStates(newToggleStates);
  };

  let controlToggleState;

  if (isPaged) {
    controlToggleState = {
      checked: allCheckedPage,
      indeterminate: someCheckedPage && !allCheckedPage,
    };
  } else {
    controlToggleState = {
      checked: allChecked,
      indeterminate: someChecked && !allChecked,
    };
  }

  const ControlToggle: ControlToggleType = ({ label, ...rest }) => (
    <ControlComponent
      {...controlToggleState}
      label={label}
      onChange={isPaged ? toggleAllPage : toggleAll}
      {...rest}
    />
  );

  const Toggle: ToggleType = ({ id, label, ...props }) => (
    <ToggleComponent
      id={id}
      {...toggleStates[id]}
      label={label}
      onChange={() => toggleChecked(id)}
      className=""
      disabled={false}
      {...props}
    />
  );

  return {
    checked,
    selected,

    toggleStates,
    toggleState,

    someMarked: someMarked || someMarkedPage,

    totalPageItemsSelected,
    allPageIsSelected,

    isChecked,
    isIndeterminate,

    toggleChecked,
    toggleAll,
    toggleAllPage,
    toggleAllFilter,

    ControlToggle,
    Toggle,
  };
};

export default useToggles;
