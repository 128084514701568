import { Formik, Form } from 'formik';

import { PortalType } from '@perts/model';
import { Col, HelpText, Row, Show, Text, useFormError } from '@perts/ui';

import { DefaultSettingsWarning, FormButtons, useTerms } from 'components';
import { helpArticles } from 'config';
import { Class } from 'models';
import { RosterSignOnTypeFields } from './RosterSignOnTypeFields';
import { RosterSignOnTypeMixed } from './RosterSignOnTypeMixed';
import { validationSchema } from './validationSchema';

const SignOnTypeHelpText = () => (
  <Col shrink vAlign="center" hAlign="flex-end">
    <HelpText articleId={helpArticles.whatAreSignOnTypes}>
      What are sign-on types?
    </HelpText>
  </Col>
);

type Props = {
  close?: () => void;
  submitButtonText: string;
  onSubmit: (values: RosterSignOnTypeFormValues) => void;
  classes?: Class[];
  initialValues: RosterSignOnTypeFormValues;
  defaultValues: RosterSignOnTypeFormValues | null;
  someClassesWithRule?: boolean;
};

export type RosterSignOnTypeFormValues = {
  /**
   * `null` inidicates mixed settings.
   */
  portalType: PortalType | null;
};

export const RosterSignOnTypeForm = ({
  close,
  submitButtonText,
  onSubmit,
  classes = [],
  initialValues,
  defaultValues,
  someClassesWithRule = false,
}: Props) => {
  const [FormError, showFormError] = useFormError();
  const terms = useTerms();

  return (
    <>
      <Row alignItems="center">
        <Col shrink vAlign="center" hAlign="center">
          <Text as="h2">
            <span>{terms.participant} Sign-On Type</span>
          </Text>
        </Col>

        <Show when={!Boolean(close)}>
          <Col></Col>
          <SignOnTypeHelpText />
        </Show>
      </Row>

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize={true}
        onSubmit={async (values) => {
          try {
            // Clear existing form error.
            showFormError(false);

            // Perform form onSubmit.
            await onSubmit(values);

            // Close modal on successful form onSubmit.
            close && close();
          } catch (error) {
            // Display form error.
            showFormError(true);
          }
        }}
      >
        {({ isSubmitting, isValid, dirty, values, setFieldValue }) => (
          <Form>
            <Row>
              <Col>
                <RosterSignOnTypeFields
                  setFieldValue={setFieldValue}
                  defaultValues={defaultValues}
                  values={values}
                />
              </Col>
            </Row>

            <Row>
              <Col>
                <RosterSignOnTypeMixed
                  allEqualPortalTypes={initialValues.portalType !== null}
                  isBatchEditForm={classes.length > 1}
                  someClassesWithRule={someClassesWithRule}
                  dirty={dirty}
                  initialValues={initialValues}
                  values={values}
                />
              </Col>
            </Row>

            <Row>
              <Col>
                <FormError />
                <DefaultSettingsWarning
                  hideWhenErrors={true}
                  message={`This change doesn't match the ${terms.group}'s default settings.`}
                  defaultValues={defaultValues}
                />
              </Col>
            </Row>

            <Show when={Boolean(close)}>
              <Row>
                <SignOnTypeHelpText />
              </Row>
            </Show>

            <FormButtons close={close} submitButtonText={submitButtonText} />
          </Form>
        )}
      </Formik>
    </>
  );
};
