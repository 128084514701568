import pluralize from 'pluralize';

import {
  selectUserIsGroupManager,
  selectUserIsNetworkManager,
  Group,
  Network,
  User,
} from 'models';

import { Show, Text, Row, Col } from '@perts/ui';
import { useTerms } from 'components/TermsContext';
import { SearchField } from './SearchField';
import { SearchUserCard } from './SearchUserCard';
import { SearchCardGroup } from './SearchCardGroup';
import { SearchCardNetwork } from './SearchCardNetwork';

const ResultLink = ({ term, number }: { term: string; number: number }) => (
  <a href={`#${term.toLowerCase()}`}>
    {pluralize(term.toLowerCase(), number, true)}
  </a>
);

const ResultTitle = ({ term }) => (
  <>
    <span id={term.toLowerCase()}></span>
    <Text as="h2">{term}</Text>
  </>
);

type SearchRenderProps = {
  networks: Network[];
  networksTotal: number | undefined;
  networksPagination: JSX.Element;
  organizations: Group[];
  organizationsTotal: number | undefined;
  organizationsPagination: JSX.Element;
  users: User[];
  usersTotal: number | undefined;
  usersPagination: JSX.Element;
  currentUser: User;
  currentUserIsAdmin: boolean;
};

export const SearchRender = ({
  networks = [],
  networksTotal = 0,
  networksPagination,
  organizations = [],
  organizationsTotal = 0,
  organizationsPagination,
  users = [],
  usersTotal = 0,
  usersPagination,
  currentUser,
  currentUserIsAdmin,
}: SearchRenderProps) => {
  const terms = useTerms();

  return (
    <div>
      <Row>
        <Col>
          <SearchField />
        </Col>
      </Row>
      <Row>
        <Col>
          Found: <ResultLink term={terms.networks} number={networksTotal} /> |{' '}
          <ResultLink term={terms.groups} number={organizationsTotal} /> |{' '}
          <ResultLink term="users" number={usersTotal} />
        </Col>
      </Row>

      <ResultTitle term={terms.networks} />

      <Show when={networks.length === 0}>
        <Text as="p">No matching results.</Text>
      </Show>

      {networks.map((network) => {
        // Determine user permissions.
        const mayRemoveNetworks =
          selectUserIsNetworkManager(currentUser, network.uid) ||
          currentUserIsAdmin;
        const mayEditNameInPlace =
          selectUserIsNetworkManager(currentUser, network.uid) ||
          currentUserIsAdmin;

        return (
          <SearchCardNetwork
            key={network.uid}
            network={network}
            mayEditNameInPlace={mayEditNameInPlace}
            mayRemoveNetworks={mayRemoveNetworks}
            mayExpandCard={currentUserIsAdmin}
          />
        );
      })}

      <Row>
        <Col vAlign="center" hAlign="flex-end" hAlignSm="center" cols={12}>
          {networksPagination}
        </Col>
      </Row>

      <ResultTitle term={terms.groups} />

      <Show when={organizations.length === 0}>
        <Text as="p">No matching results.</Text>
      </Show>

      {organizations.map((group) => {
        // Determine user permissions.
        const mayRemoveGroup =
          selectUserIsGroupManager(currentUser, group.uid) ||
          currentUserIsAdmin;
        const mayEditNameInPlace =
          selectUserIsGroupManager(currentUser, group.uid) ||
          currentUserIsAdmin;

        return (
          <SearchCardGroup
            key={group.uid}
            currentUser={currentUser}
            group={group}
            mayEditNameInPlace={mayEditNameInPlace}
            mayRemoveGroup={mayRemoveGroup}
            mayExpandCard={currentUserIsAdmin}
          />
        );
      })}

      <Row>
        <Col vAlign="center" hAlign="flex-end" hAlignSm="center" cols={12}>
          {organizationsPagination}
        </Col>
      </Row>

      <ResultTitle term="Users" />

      <Show when={users.length === 0}>
        <Text as="p">No matching results.</Text>
      </Show>

      {users.map((user) => (
        <SearchUserCard key={user.uid} user={user} />
      ))}

      <Row>
        <Col vAlign="center" hAlign="flex-end" hAlignSm="center" cols={12}>
          {usersPagination}
        </Col>
      </Row>
    </div>
  );
};
