import { Menu } from '@headlessui/react';
import { ScreenReaderOnly } from '../ScreenReaderOnly';
import { SelectFilterOptions, SelectFilterSelected } from './types';
import { SelectFilterStyles } from './SelectFilterStyles';
import { SelectFilterIcon } from './SelectFilterIcon';
import { SelectFilterTags } from './SelectFilterTags';
import { SelectFilterMenuOptions } from './SelectFilterMenuOptions';

type SelectFilterProps = {
  label: string | JSX.Element;

  /**
   * `true` to hide the label.
   */
  labelHide?: boolean;
  options: SelectFilterOptions;
  multiple?: boolean;
  legend?: boolean;
  selected: SelectFilterSelected;
  setSelected: (val: SelectFilterSelected) => void;
  resetSelected?: () => void;
  disabled?: boolean;
  icon?: JSX.Element;

  /**
   * Optional. Default `left`. If the drop down will anchor to the left or right
   * side of the SelectFilter button.
   */
  anchor?: 'left' | 'right';
};

export const SelectFilter = (props: SelectFilterProps) => {
  const {
    label,
    labelHide = false,
    options,
    selected,
    legend,
    setSelected,
    multiple,
    disabled,
    icon = <SelectFilterIcon />,
    anchor = 'left',
  } = props;
  const horizontal = legend;

  return (
    <SelectFilterStyles
      horizontal={horizontal}
      disabled={disabled}
      anchor={anchor}
    >
      <Menu>
        <Menu.Button disabled={disabled}>
          {icon}

          {labelHide ? (
            <ScreenReaderOnly>{label}</ScreenReaderOnly>
          ) : (
            <div>{label}</div>
          )}

          <SelectFilterTags
            labelHide={labelHide}
            selected={selected}
            max={legend ? Infinity : undefined}
            total={legend ? options.length : 0}
          />
        </Menu.Button>

        <SelectFilterMenuOptions
          options={options}
          selected={selected}
          setSelected={setSelected}
          multiple={multiple}
        />
      </Menu>
    </SelectFilterStyles>
  );
};
