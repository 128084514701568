import { Formik, Form } from 'formik';

import {
  Button,
  Card,
  CheckboxWithLabel,
  Col,
  IconWarning,
  InfoBox,
  Row,
  Visible,
  Text,
  useFormError,
} from '@perts/ui';

import { useTerms } from 'components';
import { Metric, MetricSet } from 'models';

type Props = {
  onSubmit: Function;
  enabledMetricIds?: string[];
  reportMetrics: Metric[];
  metricSets: MetricSet[];
};

type FormValues = {
  [metricId: string]: boolean;
};

export const ReportSettingsEnabledMetricsForm = ({
  onSubmit,
  enabledMetricIds,
  reportMetrics,
  metricSets,
}: Props) => {
  const terms = useTerms();

  const [FormError, showFormError] = useFormError();

  // If enabled metrics is not set, that means the user has made no explicit
  // choice on the matter, and we treat ALL metrics as enabled so as not to hide
  // data in surprising ways.
  const selectedMetricIds =
    enabledMetricIds === undefined
      ? reportMetrics.map((m) => m.uid)
      : enabledMetricIds;

  const initialState: FormValues = Object.fromEntries(
    reportMetrics.map((metric) => [
      metric.uid,
      selectedMetricIds.includes(metric.uid),
    ]),
  );

  const allChecked = (formValues: FormValues) =>
    Object.values(formValues).every((isChecked) => isChecked);
  const noneChecked = (formValues: FormValues) =>
    Object.values(formValues).every((isChecked) => !isChecked);

  const validate = (values: FormValues) => {
    const errors: { [key: string]: string | undefined } = {};

    if (noneChecked(values)) {
      errors.noneChecked = 'noneChecked';
    }

    return errors;
  };

  return (
    <>
      <Text as="h1">Report Settings</Text>
      <Card>
        <Card.Title>
          <Text as="h2">{terms.metrics} in Report</Text>
        </Card.Title>
        <Card.Content>
          <Formik
            initialValues={initialState}
            enableReinitialize={true}
            onSubmit={async (values) => {
              try {
                // Clear existing form error.
                await showFormError(false);

                // Perform form onSubmit.
                await onSubmit(values);
              } catch (error: any) {
                // Display form error.
                showFormError(true);
              }
            }}
            validate={validate}
          >
            {({
              dirty,
              errors,
              isSubmitting,
              isValid,
              setFieldValue,
              values,
            }) => (
              <Form>
                <Row>
                  <Col>
                    <Visible when={!allChecked(values)}>
                      <InfoBox>
                        <div>
                          <IconWarning /> Unselected{' '}
                          {terms.metrics.toLowerCase()} will be hidden in this
                          report.
                        </div>
                      </InfoBox>
                    </Visible>
                  </Col>
                </Row>
                {reportMetrics.map((metric) => (
                  <CheckboxWithLabel
                    key={metric.uid}
                    name={metric.uid}
                    value={metric.uid}
                    label={metric.name}
                    checked={values[metric.uid]}
                    onChange={() =>
                      setFieldValue(metric.uid, !values[metric.uid])
                    }
                    disabled={isSubmitting}
                    className=""
                  />
                ))}
                <Row>
                  <Col>
                    <Visible when={Boolean(errors.noneChecked)}>
                      <InfoBox color="error">
                        Please select at least one {terms.metric}.
                      </InfoBox>
                    </Visible>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormError />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Button
                      type="submit"
                      fullWidth
                      disabled={!isValid || isSubmitting || !dirty}
                      loading={isSubmitting}
                      data-testid="submit-btn"
                    >
                      Save {terms.metrics} In Report
                    </Button>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </Card.Content>
      </Card>
    </>
  );
};
