import styled, { css } from 'styled-components/macro';
import { IconButtonProps } from './IconButton';
import { Button } from '../Button';
import { theme } from '../..';

// Filtering using the old method since withConfig is erroring:
//   Property 'withConfig' does not exist on type
//   'ThemedStyledFunction<"button", any, {}, never>'.ts(2339)

export const StyledIconButton = styled(({ icon, ...props }) => (
  <Button {...props} />
))<IconButtonProps>`
  position: relative;
  overflow: hidden;

  width: 30px;
  height: 30px;
  min-width: 30px;
  min-height: 30px;

  padding: 0;

  cursor: pointer;

  &:hover,
  &:active {
    color: ${(props) => props.theme.colors.text};
    background-color: ${(props) => props.theme.colors.white};
    border: 1px solid ${(props) => props.theme.colors.text};
  }

  ${(props) =>
    props.state === 'stateless' &&
    css`
      color: ${props.theme.colors.text};
      background-color: ${props.theme.colors.white};
      border: 1px solid ${props.theme.colors.text};
    `};

  ${(props) =>
    props.state === 'active' &&
    css`
      color: ${props.theme.colors.white};
      background-color: ${props.theme.colors.text};
      border: 1px solid ${props.theme.colors.text};

      &:hover,
      &:active {
        color: ${props.theme.colors.white};
        background-color: ${props.theme.colors.text};
        border: 1px solid ${props.theme.colors.text};
      }
    `};

  ${(props) =>
    props.state === 'inactive' &&
    css`
      color: ${props.theme.colors.text};
      background-color: ${props.theme.colors.white};
      border: 1px dashed ${props.theme.colors.text};

      &:hover,
      &:active {
        color: ${props.theme.colors.text};
        background-color: ${props.theme.colors.white};
        border: 1px dashed ${props.theme.colors.text};
      }
    `};

  ${(props) =>
    props.disabled &&
    css`
      cursor: not-allowed;

      color: ${props.theme.colors.white};
      background-color: ${props.theme.colors.grayLighten3};
      border: 1px solid ${props.theme.colors.grayLighten3};

      &:hover,
      &:active {
        color: ${props.theme.colors.white};
        background-color: ${props.theme.colors.grayLighten3};
        border: 1px solid ${props.theme.colors.grayLighten3};
      }
    `};

  ${(props) =>
    props.noBorder &&
    css`
      border: 0;

      &:hover,
      &:active {
        border: 0;
      }
    `};

  svg {
    width: 20px;
    height: 20px;
  }
`;

StyledIconButton.defaultProps = {
  theme,
};

StyledIconButton.displayName = 'StyledIconButton';
