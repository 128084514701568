import { Network } from 'models';
import { toHomeNetworksRemove } from 'pages';
import { useOpenModal } from 'utils';

import { Dropdown, IconTrash } from '@perts/ui';

type HomeNetworkDropdownProps = {
  network: Network;
};

export const HomeNetworkDropdown = ({ network }: HomeNetworkDropdownProps) => {
  const openModal = useOpenModal();

  return (
    <Dropdown aria-label={`Card network ${network.name} dropdown`}>
      <Dropdown.Item
        onClick={() => {
          openModal(toHomeNetworksRemove(network.program_id, network.uid));
        }}
      >
        <IconTrash />
        <span>Delete Network</span>
      </Dropdown.Item>
    </Dropdown>
  );
};
