import {
  Group,
  Program,
  SurveyToUpdate,
  Team,
  User,
  getGroupDefaultSettings,
} from 'models';

export function teamWithDefaults(
  program: Program,
  group: Group,
  facilitator: User,
  name: string,
) {
  const {
    parent_organizations,
    schedules: cycles,
    target_group_name,
  } = getGroupDefaultSettings(group);

  return {
    name,
    organization_ids: [group.uid, ...parent_organizations],
    program_id: program.uid,
    captain_id: facilitator.uid,
    ...(target_group_name && { target_group_name }),
    ...(cycles.length > 0 && { cycles }),
  };
}

export function classroomWithDefaults(
  program: Program,
  group: Group,
  team: Team,
  facilitator: User,
  name: string,
  subject_area: string,
) {
  const { roster_locked, participant_ending, participant_pattern } =
    getGroupDefaultSettings(group);

  return {
    name,
    team_id: team.uid,
    program_label: program.label,
    contact_id: facilitator.uid,
    subject_area,
    ...(roster_locked && { roster_locked }),
    ...(participant_ending && { participant_ending }),
    ...(participant_pattern && { participant_pattern }),
  };
}

export function surveyMergeWithDefaults(
  group: Group,
  survey: SurveyToUpdate,
  name: string,
) {
  const {
    metrics,
    open_responses,
    portal_message,
    portal_type,
    gender_question_disabled,
  } = getGroupDefaultSettings(group);

  return {
    ...survey,
    meta: {
      ...survey.meta,
      class_name: name,
      gender_question_disabled,
    },
    ...(metrics && metrics.length > 0 && { metrics }),
    ...(open_responses && open_responses.length > 0 && { open_responses }),
    ...(portal_message && { portal_message }),
    ...(portal_type && { portal_type }),
  };
}
