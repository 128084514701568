import { useCallback, useRef } from 'react';
import ReactCanvasConfetti from 'react-canvas-confetti';
import styled from 'styled-components/macro';

import { theme, Button } from '../../';
import supportSrc from '../../assets/images/support.png';
import { IconHelp } from '../Icon';

export type SupportBoxProps = {
  onClick: () => void;
};

const SupportBoxExpandedStyled = styled.div`
  padding: 10px;
  background: ${theme.colors.offWhite};
  border-radius: ${theme.units.borderRadius};

  text-align: center;

  img {
    max-height: 120px;
    max-width: 100%;
  }

  @media (max-width: ${theme.units.menuCollapseWidth}px) {
    display: none;
  }
`;

const canvasStyles: any = {
  position: 'fixed',
  pointerEvents: 'none',
  width: '100%',
  height: '100%',
  top: 0,
  left: 0,
};

const SupportBoxExpanded = ({ onClick }: SupportBoxProps) => {
  // //// Project Enchanting Needle: easter egg w/ confetti ////

  // Code borrowed from https://codesandbox.io/s/realistic-fn-react-canvas-confetti-2o3pe?file=/src/App.js:110-236
  const refAnimationInstance = useRef<null | Function>(null);

  const getInstance = useCallback((instance) => {
    refAnimationInstance.current = instance;
  }, []);

  const makeShot = useCallback((opts = {}) => {
    refAnimationInstance.current &&
      refAnimationInstance.current({
        ...opts,
        angle: Math.random() * 40 + 30, // 30-70 degrees
        origin: { x: 0.1, y: 0.9 },
        particleCount: Math.random() * 80 + 20, // 20 - 100 particles
        scalar: Math.random() * 0.75 + 0.75, // 0.75-1.5 scalar
        spread: Math.random() * 100 + 20, // 20-120 spread
        startVelocity: Math.random() * 30 + 30, // 30-60 velocity
      });
  }, []);

  const numShots = 4;
  const fire = useCallback(() => {
    makeShot();
    for (let x = 0; x < numShots - 1; x += 1) {
      setTimeout(makeShot, Math.random() * 400);
    }
  }, [makeShot]);

  // //// End Project Enchanting Needle ////

  return (
    <SupportBoxExpandedStyled>
      <ReactCanvasConfetti
        refConfetti={getInstance}
        style={canvasStyles}
        useWorker={false}
      />
      <img src={supportSrc} alt="Person using PERTS" onClick={fire} />
      <Button fullWidth onClick={onClick}>
        <IconHelp />
        &nbsp;<span>Help</span>
      </Button>
    </SupportBoxExpandedStyled>
  );
};

const SupportBoxCollapsedStyled = styled.div`
  display: none;

  @media (max-width: ${theme.units.menuCollapseWidth}px) {
    display: block;
  }
`;

const SupportBoxCollapsed = ({ onClick }: SupportBoxProps) => {
  return (
    <SupportBoxCollapsedStyled>
      <Button fullWidth onClick={onClick}>
        <IconHelp />
      </Button>
    </SupportBoxCollapsedStyled>
  );
};

export const SupportBox = () => {
  const fw = (window as any).FreshworksWidget;

  // See widget API: https://developers.freshdesk.com/widget-api/

  // The launcher often obscures important parts of the UI in either of its
  // supported placements: lower left or lower right. So we hide it immediately
  // (see public/index.html) and just make our own buttons that trigger opening
  // the content.

  // The widget API doesn't have any method to detect if it's open or not. So
  // this hack might break by refusing to close at some point.
  const widgetIsOpen = () =>
    Boolean(window.document.getElementById('freshworks-frame-wrapper'));

  const toggleWidget = () => {
    if (widgetIsOpen()) {
      fw('close');
    } else {
      fw('open');
    }
  };
  return (
    <>
      <SupportBoxExpanded onClick={toggleWidget} />
      <SupportBoxCollapsed onClick={toggleWidget} />
    </>
  );
};
