import { GroupReportDefault21Render, GroupReportLoader } from './';

export const GroupReportDefault21 = () => (
  <GroupReportLoader>
    {({
      dataset,
      group,
      program,
      programMetrics,
      reportDate,
      reportMetrics,
    }) => (
      <GroupReportDefault21Render
        archived={group.archived}
        dataset={dataset}
        group={group}
        program={program}
        programMetrics={programMetrics}
        reportDate={reportDate}
        reportMetrics={reportMetrics}
      />
    )}
  </GroupReportLoader>
);
