import * as Yup from 'yup';
import pluralize from 'pluralize';
import { Formik, Form } from 'formik';
import { isEmpty, uniqBy } from 'lodash';
import { useState, useEffect, useContext } from 'react';

import useToggles from 'utils/useToggles';
import { Group, Class } from 'models';
import {
  Modal,
  Button,
  Row,
  Col,
  CheckboxGroup,
  useFormError,
} from '@perts/ui';
import { useParams } from '../../.';
import TermsContext, { Terms } from 'components/TermsContext';

export type ClassesEditGroupsValuesProps = {
  organizations?: { [uid: string]: string[] };
};

export type ClassesEditGroupsFormProps = {
  classes: Class[];
  classGroups: Group[];
  onSubmit: (values: ClassesEditGroupsValuesProps) => void;
  close: () => void;
  checkedGroupIds: string[];
  indeterminateGroupIds: string[];
  userGroups: Group[];
};

const createSchemaShapeFromGroups = (groups) =>
  Object.fromEntries(groups.map(({ uid }) => [uid, Yup.array()]));

const getFormSchema = (name: string, groups: Group[], terms: Terms) =>
  Yup.object().shape({
    [name]: Yup.object()
      .shape(createSchemaShapeFromGroups(groups))
      .test(
        'at-least-one',
        `There must be at least one ${terms.group.toLowerCase()} in each ${terms.class.toLowerCase()}.`,
        (values) => Object.values(values).some((value) => !isEmpty(value)),
      ),
  });

export const ClassesEditGroupsForm = ({
  close,
  onSubmit,
  classes: rawClasses = [],
  classGroups = [],
  checkedGroupIds = [],
  indeterminateGroupIds = [],
  userGroups = [],
}: ClassesEditGroupsFormProps) => {
  const [FormError, showFormError] = useFormError();
  const { groupId } = useParams();
  const terms = useContext(TermsContext);

  const initialState = {};

  // Determine the full set of options to display, which may include groups not
  // curently related to the classes, but which are available to the user.
  const groups = uniqBy([...classGroups, ...userGroups], (g) => g.uid);
  const userGroupIds = userGroups.map((g) => g.uid);

  groups.forEach(({ uid }) => {
    initialState[uid] = {
      checked: Boolean(checkedGroupIds.find((cf) => cf === uid)),
      indeterminate: Boolean(indeterminateGroupIds.find((cf) => cf === uid)),
    };
  });

  const { isChecked, toggleChecked, isIndeterminate } = useToggles(
    groups,
    'uid',
    {
      initialState,
    },
  );

  const isDisabled = (id) => !userGroupIds.includes(id);

  const [initialValues, setInitialValues] =
    useState<ClassesEditGroupsValuesProps>({});

  // Ensure groups are defined for each class. The Class type has this
  // property as optional.
  const classes = rawClasses.map((cls) => ({
    ...cls,
    groups: cls.groups || [],
  }));

  useEffect(() => {
    if (classes.length && isEmpty(initialValues)) {
      // Create initial values to form
      const initialGroups = {};
      groups.forEach((group) => {
        initialGroups[group.uid] = classes
          .filter((cls) => cls.organization_ids.includes(group.uid))
          .map(({ uid }) => uid);
      });

      setInitialValues({
        organizations: initialGroups,
      });
    }
  }, [classes, groups, initialValues]);

  const currentGroupName = groups.find(({ uid }) => uid === groupId)?.name;

  const currentGroupError =
    currentGroupName && !isChecked(groupId)
      ? `Selected classes will be removed from the current group.`
      : '';

  const allClassIds = classes.map(({ uid }) => uid);

  const schemaShape = {};
  groups.forEach(({ uid }) => {
    schemaShape[uid] = Yup.array();
  });

  const ClassesEditGroupsFormSchema = getFormSchema(
    'organizations',
    groups,
    terms,
  );

  return (
    <Modal close={close}>
      <Modal.Title className="center">{`Edit ${terms.groups}`}</Modal.Title>

      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={ClassesEditGroupsFormSchema}
        validateOnBlur={false}
        onSubmit={async (values) => {
          try {
            // Clear existing form error.
            showFormError(false);

            // Perform form onSubmit.
            await onSubmit(values);

            // Close modal on successful form onSubmit.
            close();
          } catch (error) {
            // Display form error.
            showFormError(true);
          }
        }}
      >
        {({
          isSubmitting,
          isValid,
          setFieldValue,
          values = {},
          errors = {},
          dirty,
        }) => (
          <Form>
            <CheckboxGroup
              type="entityList"
              id="organizations"
              name="organizations"
              items={groups}
              keyId="uid"
              keyName="name"
              value={values.organizations}
              isChecked={isChecked}
              isDisabled={isDisabled}
              isIndeterminate={isIndeterminate}
              toggleChecked={toggleChecked}
              setFieldValue={setFieldValue}
              error={errors.organizations || currentGroupError}
              listToAdd={allClassIds}
              disabled={isSubmitting}
            />

            <Row>
              <Col>
                <FormError />
              </Col>
            </Row>

            <Row>
              <Col cols={6} colsSm={12}>
                <Button
                  type="button"
                  color="secondary"
                  fullWidth
                  onClick={close}
                  disabled={isSubmitting}
                  fullHeight
                >
                  Cancel
                </Button>
              </Col>

              <Col cols={6} colsSm={12} hAlign="flex-end">
                <Button
                  type="submit"
                  fullWidth
                  disabled={!isValid || isSubmitting || !dirty}
                  loading={isSubmitting}
                  data-testid="submit-btn"
                >
                  {`Save Changes to ${pluralize(
                    terms.class,
                    classes.length,
                    true,
                  )}`}
                </Button>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
