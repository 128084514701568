import PropTypes from 'prop-types';
import React from 'react';
import uri from 'urijs';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Route, Redirect } from 'react-router-dom';

import fromSearch from 'utils/fromSearch';
import Loading from 'components/Loading';
import selectors from 'state/selectors';
import Unauthorized from 'scenes/Unauthorized';
import { tokenLoginUser } from 'state/auth/actions';
import { toLogout } from 'routes';

/**
 * Handles redirections necessary to ensure that users visiting this route are
 * authenticated. There are two ways of authenticating:
 *
 * 1. Have a query string parameter `token` set to a valid jwt. If this
 *    parameter is set but invalid, this displays the <Unauthorized> scene to
 *    help with next steps. If it's valid, we dispatch an action to log the
 *    user in and remove the token from the query string.
 * 2. If the token parameter is not set, the user must already be logged in,
 *    which means having a jwt in localStorage. If not, the user is redirected
 *    to /login with this route as a parameter so they can return afterward.
 *    If they are logged in, the passed in component is rendered.
 *
 * @param {Object}          props               props
 * @param {Object}          props.actions       float representing width to
 *     height ratio
 * @param {boolean}         props.authenticated whether logged in
 * @param {React.Component} props.component     to render if logged in
 * @param {Object}          props.authError     null or {code: 'error',
 *      message: 'foo'}
 * @param {Object}          props.props         for the component to render
 * @param {string}          props.redirectTo    where unauthenticated users
 *     should go to sign in, defaults to '/login'
 * @returns {string} html to render
 */
export const AuthenticatedRoute = (props) => {
  const {
    actions,
    authenticated,
    children,
    component: Component,
    authError,
    location,
    props: cProps,
    redirectTo,
    ...rest
  } = props;

  const { token } = fromSearch(props);
  if (authError) {
    return (
      <Unauthorized message={authError.message} path={location.pathname} />
    );
  } else if (token) {
    actions.tokenLoginUser(token, location.pathname);
    return <Loading>Signing you in&hellip;</Loading>;
  }

  // Allows us to send the user back to route they were attempting to access.
  const continue_url = location.pathname;

  const {
    email, // Supports prefilling invited email address in login form.
    passwordless, // Supports passwordless accounts.
  } = uri(location.search).search(true);

  // The expired_session is searched in "window.location.search"
  // because it was added manually in the handleApiResponse
  // when server response 401 and location prop does not find
  // this query string param
  const { expired_session } = uri(window.location.search).search(true);

  const pathname = uri(redirectTo)
    .setSearch({ continue_url })
    .setSearch({ passwordless })
    .setSearch({ email })
    .setSearch({ expired_session })
    .toString();

  // It's important to make it clear to react that,
  // when rendering `children` directly,
  // a change in `renderProps` should not necessarily trigger a re-render.
  // See https://github.com/PERTS/perts/pull/555

  return (
    <Route
      {...rest}
      render={(renderProps) =>
        authenticated ? (
          Component ? (
            <Component renderProps={renderProps} {...cProps} />
          ) : (
            children
          )
        ) : (
          <Redirect to={pathname} />
        )
      }
    />
  );
};

AuthenticatedRoute.propTypes = {
  authenticated: PropTypes.bool,
  // expecting React.Component or Stateless Functional Component
  component: PropTypes.elementType,
  props: PropTypes.object,
  redirectTo: PropTypes.string,
};

AuthenticatedRoute.defaultProps = {
  authenticated: false,
  redirectTo: toLogout(),
};

const mapStateToProps = (state, props) => ({
  authError: selectors.auth.error(state, props),
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ tokenLoginUser }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(AuthenticatedRoute);
