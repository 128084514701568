import uri from 'urijs';
import { mapKeys, snakeCase } from 'lodash';

export const urlWithQueryParams = (
  path: string,
  queryParams: { [key: string]: any },
) => {
  const snakeCaseQueryParams = mapKeys(queryParams, (_, key) => snakeCase(key));
  return uri(path).search(snakeCaseQueryParams).toString();
};
