import styled from 'styled-components/macro';
import { theme } from '../../';

export type MenuProps = {
  children: React.ReactNode;
};

const MenuStyled = styled.div`
  margin-top: ${theme.units.appSidebarMenuMarginTop};
`;

export const Menu = MenuStyled;
