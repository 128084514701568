import { Metric } from 'models';
import { ToggleStates } from 'utils/useToggles';

// This type allows for flexibility. For example,
// - when used in SurveysEditForm, we provide Class[],
// - when used in SurveysDefaultSettingsForm, we provide DefaultMetricsIds[]
export type ObjectWithMetrics = {
  [key: string]: any;
  metrics: string[];
};

// Returns `true` if the `metric` is included in ALL of the provided `classes`.
const isMetricCheckedForAll = (metric: Metric, objects: ObjectWithMetrics[]) =>
  objects.every((o) => o.metrics.includes(metric.uid));

// Returns `true` if the `metric` is included in SOME of the provided `classes`.
const isMetricCheckedForSome = (metric: Metric, objects: ObjectWithMetrics[]) =>
  objects.some((o) => o.metrics.includes(metric.uid));

// Creates a ToggleStates object of `checked` and `indeterminate` states based
// on the `metrics` and `classes` provided.
export const toggleStatesMetrics = (
  metrics: Metric[],
  objects: ObjectWithMetrics[],
) => {
  const state: ToggleStates = {};

  metrics.forEach((m) => {
    const isAll = isMetricCheckedForAll(m, objects);
    const isSome = isMetricCheckedForSome(m, objects);

    state[m.uid] = {
      checked: isAll,
      indeterminate: isSome && !isAll,
    };
  });

  return state;
};
