// InputFieldError Component
// Common form field error styles/functionality.
// Note: This is intended for reuse by other perts-ui components.

import styled from 'styled-components/macro';
import theme from '../theme';

type InputFieldErrorContainerProps = {
  // theme to use
  theme: any;

  // id to identify error
  id?: string;

  // The error is visible If it is true
  visible?: boolean;

  // The role to error
  role?: string;
};

type InputFieldErrorProps = InputFieldErrorContainerProps & {
  children: React.ReactNode;
};

const InputFieldErrorContainer = styled.div<InputFieldErrorContainerProps>`
  margin-top: ${(props) => props.theme.units.fieldPaddingInternal};
  padding: ${(props) => props.theme.units.fieldPadding};

  border-radius: ${(props) => props.theme.units.borderRadius};

  background: ${(props) => props.theme.colors.warning};
  color: ${(props) => props.theme.colors.white};

  text-align: center;

  // Calculation of min height to prevent change height when the error appears
  min-height: ${(props) =>
    `calc(${props.theme.units.fieldPadding} + ${props.theme.units.lineHeight} + ${props.theme.units.fieldPadding})`};

  line-height: ${(props) => props.theme.units.lineHeight};

  visibility: ${(props) => (props.visible ? 'visible' : 'hidden')};

  a,
  a:hover {
    color: ${(props) => props.theme.colors.white};
  }
`;

const InputFieldErrorComponent = ({
  children,
  ...props
}: InputFieldErrorProps) => (
  <InputFieldErrorContainer visible={Boolean(children)} {...props}>
    {children}
  </InputFieldErrorContainer>
);

InputFieldErrorComponent.defaultProps = {
  theme,
  children: null, // there is no error
};

export const InputFieldError = Object.assign(InputFieldErrorComponent, {
  displayName: 'InputFieldError',
});
