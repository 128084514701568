import { ClassReportDefault21Render, ClassReportLoader } from './';

export const ClassReportDefault21 = () => (
  <ClassReportLoader>
    {({
      cls,
      dataset,
      group,
      program,
      programMetrics,
      reportDate,
      reportMetrics,
    }) => (
      <ClassReportDefault21Render
        archived={Boolean(group.archived)}
        cls={cls}
        dataset={dataset}
        program={program}
        programMetrics={programMetrics}
        reportDate={reportDate}
        reportMetrics={reportMetrics}
      />
    )}
  </ClassReportLoader>
);
