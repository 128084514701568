import { useCallback, useEffect } from 'react';
import { useQueryParams } from 'utils';

export const useScrollToTargetGroup = () => {
  const { get } = useQueryParams();
  const optionId = get('optionId');

  const scrollToTargetGroup = useCallback(() => {
    // If optionId is equal to 'focal-group',
    // The screen scrolls to target group form
    if (optionId === 'focal-group') {
      document
        .getElementById(optionId)
        ?.scrollIntoView({ block: 'center', inline: 'nearest' });
    }
  }, [optionId]);

  // This useEffect triggers scrolling if it is necessary when the page load
  // by first time
  useEffect(() => {
    scrollToTargetGroup();
  }, [scrollToTargetGroup]);
};
