import { Text } from '@perts/ui';

type Props = {
  pctResponded: string;
  nResponded: string;
};

export const ResponseStatFooter = ({ pctResponded, nResponded }: Props) => (
  <em>
    {pctResponded} ({nResponded}) responded (see{' '}
    <Text as="a" href="#survey-response-rates">
      Participation by Subpopulation
    </Text>
    )
  </em>
);
