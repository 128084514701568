import { locales } from '../../i18n';

export const options = {
  [locales.ENGLISH]: [
    {
      label: 'Never',
      value: 1,
    },
    {
      label: 'Hardly ever (A few times per year)',
      value: 2,
    },
    {
      label: 'Sometimes (A few times per month)',
      value: 3,
    },
    {
      label: 'Often (every week)',
      value: 4,
    },
    {
      label: 'Very often (many times per week)',
      value: 5,
    },
  ],
  [locales.SPANISH]: [
    {
      label: 'Nunca',
      value: 1,
    },
    {
      label: 'Casi nunca (Algunas veces al año)',
      value: 2,
    },
    {
      label: 'Algunas veces (Algunas veces al mes)',
      value: 3,
    },
    {
      label: 'Con frequencia (Cada semana)',
      value: 4,
    },
    {
      label: 'Muy a menudo (Muchas veces por semana)',
      value: 5,
    },
  ],
};
