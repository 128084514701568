import {
  Class,
  queryKeyClass,
  updateClass,
  useMutationUpdate,
  useClassGetByParams,
} from 'models';
import { useParams } from 'pages';

import { ClassReportLoader } from '../ClassReportDefault21';
import { ReportSettingsEnabledMetricsForm } from '../components';

export const ClassReportSettings = () => {
  const { classId } = useParams();
  const classQuery = useClassGetByParams();

  const queryKey = queryKeyClass(classId);
  const mutation = useMutationUpdate<Class, Class>(updateClass, queryKey, {
    optimisticallyUpdate: false,
    transformFormValues: (formValues) => {
      if (!classQuery.isSuccess) {
        throw new Error('Class data not loaded.');
      }
      const cls = classQuery.data;
      const enabledIds = Object.entries(formValues)
        .filter(([metricId, isChecked]) => isChecked)
        .map(([metricId]) => metricId);
      return {
        ...cls,
        report_settings: {
          ...cls?.report_settings,
          enabled_metrics: enabledIds,
        },
      };
    },
    toastSuccessMsg: `Successfully updated report settings.`,
    toastErrorMsg: `There was a problem updating report settings.`,
  });

  const onSubmit = async (formValues) => {
    await mutation.mutateAsync(formValues);
  };

  const metricSets = []; // need to load this?

  return (
    <ClassReportLoader>
      {({
        cls,
        dataset,
        group,
        program,
        programMetrics,
        reportDate,
        reportMetrics,
      }) => (
        <ReportSettingsEnabledMetricsForm
          onSubmit={onSubmit}
          enabledMetricIds={cls.report_settings.enabled_metrics}
          reportMetrics={reportMetrics}
          metricSets={metricSets}
        />
      )}
    </ClassReportLoader>
  );
};
