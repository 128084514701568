import styled from 'styled-components/macro';
import { theme } from 'components';

const TableFullWidth = styled.div`
  /* This is required to make the table full-width */
  display: block;
  max-width: 100%;

  /* Similar margin-bottom rules as Row from @perts/ui */
  :not(:last-child) {
    margin-bottom: ${theme.units.gridDefaultGutter}px;
  }
  @media only screen and (max-width: ${theme.units.gridBreakpointSmall}px) {
    :not(:last-child) {
      margin-bottom: ${theme.units.gridDefaultGutter / 2}px;
    }
  }
`;

const TableWrap = styled.div`
  /* This will make the table scrollable when it gets too small */
  display: block;
  max-width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  border-bottom: 1px solid black;
  /* This will make the table scrollable when it gets too small */

  border: 1px solid ${theme.colors.grayLight};
  border-radius: ${theme.units.borderRadius};
`;

export const ReactTable = styled.table`
  /* Make sure the inner table is always as wide as needed */
  width: 100%;
  border-spacing: 0;
  /* Make sure the inner table is always as wide as needed */

  background: ${theme.colors.white};
`;

export const ReactTableWrap = ({ children }: { children: React.ReactNode }) => (
  <TableFullWidth>
    <TableWrap>{children}</TableWrap>
  </TableFullWidth>
);
