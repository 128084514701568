import { createUid } from '@perts/util';
import { Class, GoogleUser, Group } from 'models';
import { User, UserType } from './types';

export const sortUserByEmail = (a: User, b: User) =>
  a.email.toLowerCase().localeCompare(b.email.toLowerCase());

export const selectUserClasses = (user: User, classes: Class[] = []) =>
  classes.filter((c) => user.owned_teams.includes(c.uid));

export const selectUserIsAdmin = (user?: User) =>
  user?.user_type === UserType.SUPER_ADMIN;

export const selectUserIsGroupManager = (user: User, groupId: string) =>
  user.managed_organizations.includes(createUid('Organization', groupId));

export const selectUserGroups = (user: User, groups: Group[] = []) =>
  groups.filter((group) => user.owned_organizations.includes(group.uid));

export const selectUserAgreedToTermsOfUse = (user: User) =>
  Boolean(user.tou_agreed);

export const selectGoogleUserEmailVerified = (user: GoogleUser) =>
  Boolean(user.email_verified);

export const selectUserSession = (pertsTokenPayload: any) =>
  pertsTokenPayload?.session_id;

export const selectUserIsMember = (user: User, groupId: string) =>
  user.owned_organizations.includes(groupId);

export const selectUserIsNetworkManager = (user: User, networkId: string) =>
  user.owned_networks.includes(networkId);

export const selectUserIsNetworkManagerOfGroup = (
  user: User,
  groupId: string,
) => user.networked_organizations.includes(groupId);

export const selectUserMayGroupClassView = (user: User, groupId: string) =>
  selectUserIsAdmin(user) ||
  selectUserIsGroupManager(user, groupId) ||
  selectUserIsNetworkManagerOfGroup(user, groupId);

export const selectUserAccountIsPending = (user) =>
  !selectUserAccountIsAccepted(user);

export const selectUserAccountIsAccepted = (user) => Boolean(user.tou_agreed);

export const selectUserGroupIsPinned = (user: User, groupId: string) =>
  user?.pinned_organizations.includes(groupId);

export const mayClassReportView = (user: User, classUid: string) =>
  selectUserIsAdmin(user) || user.owned_teams.includes(classUid);
