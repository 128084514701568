import {
  Class,
  queryKeyClass,
  updateClass,
  useMutationUpdate,
  useClassGetByParams,
  useProgramGetByParams,
} from 'models';
import { useParams } from 'pages';

import { ClassReportLoader } from '../ClassReport23';
import { ReportSettingsEnabledMetricsForm } from '../components';

export const ReportSettingsClassEnabledMetrics = () => {
  const { classId } = useParams();
  const classQuery = useClassGetByParams();
  const { data: program } = useProgramGetByParams();

  const queryKey = queryKeyClass(classId);
  const mutation = useMutationUpdate<Class, Class>(updateClass, queryKey, {
    optimisticallyUpdate: false,
    transformFormValues: (formValues) => {
      if (!classQuery.isSuccess) {
        throw new Error('Class data not loaded.');
      }
      const cls = classQuery.data;
      const enabledIds = Object.entries(formValues)
        .filter(([metricId, isChecked]) => isChecked)
        .map(([metricId]) => metricId);
      return {
        ...cls,
        report_settings: {
          ...cls?.report_settings,
          enabled_metrics: enabledIds,
        },
      };
    },
    toastSuccessMsg: `Successfully updated report settings.`,
    toastErrorMsg: `There was a problem updating report settings.`,
  });

  const onSubmit = async (formValues) => {
    await mutation.mutateAsync(formValues);
  };

  return (
    <ClassReportLoader>
      {({
        cls,
        reportMetrics,
        // // unused:
        // group,
        // program,
        // programMetrics,
        // lastRun: dataset.last_run,
        // results,
      }) => (
        <ReportSettingsEnabledMetricsForm
          onSubmit={onSubmit}
          enabledMetricIds={cls.report_settings.enabled_metrics}
          reportMetrics={reportMetrics}
          metricSets={program?.metric_sets || []}
        />
      )}
    </ClassReportLoader>
  );
};
