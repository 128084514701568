import { useEffect } from 'react';
import { useQueryClient, useMutation } from 'react-query';
import { message } from 'antd';

import {
  useNetworkGetById,
  networkRemove,
  queryKeyNetworksByProgram,
  Network,
  invalidateNetworkRemove,
} from 'models';
import { toHome, useParams } from 'pages';
import { useCloseModal, useQueryParams } from 'utils';

import Loading from 'components/Loading';
import { ErrorMessageBox } from 'components/ErrorMessageBox';
import {
  NetworksRemoveForm,
  NetworksRemoveFormValues,
} from './NetworksRemoveForm';

import { getMessageFromErrors } from '@perts/util';

export const NetworksRemove = () => {
  const { networkId, programId } = useParams();
  const queryClient = useQueryClient();

  const { get } = useQueryParams();
  const n = get('n');
  const cursor = get('cursor');
  const queryKey = queryKeyNetworksByProgram(programId, { n, cursor });

  // Close the modal.
  const closeModal = useCloseModal({
    pathname: toHome(programId),
    ignoreEmptyState: true,
  });

  // Close the modal if modal is opened with no networkId provided.
  useEffect(() => {
    if (!networkId) {
      closeModal();
    }
  }, [networkId, closeModal]);

  // Query for Network.
  const networkQuery = useNetworkGetById(networkId);

  const mutation = useMutation(
    (values: NetworksRemoveFormValues) => networkRemove(values.network),
    {
      onMutate: (values: NetworksRemoveFormValues) => {
        // Snapshot the previous value.
        const previousNetworks =
          queryClient.getQueryData<Network[]>(queryKey) || [];

        // Optimistically update to the new values.
        queryClient.setQueryData(
          queryKey,
          previousNetworks.filter((g) => g.uid !== values.network.uid),
        );

        return { previousNetworks };
      },
      // Handle successful mutation.
      onSuccess: () => {
        message.success(`Successfully deleted the Network.`);
        invalidateNetworkRemove(queryClient);
      },
      // Handle successful mutation.
      onError: (_err, _data, context: any) => {
        message.error(`Unable to delete Network.`);

        // Rollback to previous if there's an error.
        if (context?.previousNetworks) {
          queryClient.setQueryData(queryKey, context.previousNetworks);
        }
      },
    },
  );

  // Display loading.
  if (networkQuery.isLoading) {
    return <Loading />;
  }

  // Display errors.
  if (!networkQuery.isSuccess) {
    return (
      <ErrorMessageBox>
        {getMessageFromErrors([networkQuery.error])}
      </ErrorMessageBox>
    );
  }

  return (
    <NetworksRemoveForm
      close={closeModal}
      onSubmit={mutation.mutateAsync}
      network={networkQuery.data}
    />
  );
};
