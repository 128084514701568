import { useContext } from 'react';
import { Network, Group } from 'models';
import { Row, Col, Show } from '@perts/ui';
import pluralize from 'pluralize';
import TermsContext from 'components/TermsContext';

type ListGroupsToRemoveProps = {
  network: Network;
  groups: Group[];
};

export const ListGroupsToRemove = ({
  network,
  groups,
}: ListGroupsToRemoveProps) => {
  const terms = useContext(TermsContext);

  return (
    <Show when={groups.length > 0}>
      <Row>
        <Col>
          Are you sure you want to remove{' '}
          {pluralize(terms.groups.toLowerCase(), groups.length, true)} from
          Network {network.name}?
        </Col>
      </Row>

      <Row data-testid="group-list-to-remove">
        <Col>
          <ul>
            {groups.map((group) => (
              <li key={group.uid} data-testid="group-list-to-remove-item">
                {group.name}
              </li>
            ))}
          </ul>
        </Col>
      </Row>
    </Show>
  );
};
