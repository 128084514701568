import { getSurveyParams } from './surveys';
import { NEPTUNE_URL_PREFIX, TRITON_URL_PREFIX } from './config';
import { fetchApi, getAuthorization } from './helpers';

export const updateClass = (cls) => {
  const options = {
    method: 'PUT',
    headers: {
      Authorization: getAuthorization(),
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(cls),
  };

  const url = `${TRITON_URL_PREFIX}/api/classes/${cls.uid}`;

  return fetchApi(url, options);
};

const updateParticipationCode = (cls, jwt) => {
  // These spaces have been cropping up mysteriously, see #1090.
  if (cls?.participant_pattern?.includes(' ')) {
    throw new Error(
      `participant_pattern should not contain spaces. Pattern: ` +
        `"${cls.participant_pattern}", Classroom: ${cls.uid}.`,
    );
  }

  const options = {
    method: 'PUT',
    headers: {
      Authorization: getAuthorization(jwt),
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      organization_id: cls.team_id,
      portal_message: cls.portal_message,
      portal_type: cls.portal_type,
      participant_pattern: cls.participant_pattern,
      survey_params: getSurveyParams(cls),
    }),
  };

  const url = `${NEPTUNE_URL_PREFIX}/api/codes/${cls.code.replace(/ /g, '-')}`;

  return fetchApi(url, options);
};

export const update = (cls) =>
  updateClass(cls).then((updatedClass) =>
    updateParticipationCode(updatedClass, updatedClass.jwt)
      // Throw away Neptune's participation code response; we want to store
      // the triton entity.
      .then((_neptuneResponse) => updatedClass),
  );
