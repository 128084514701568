import { Tooltip } from 'antd';
import styled from 'styled-components';

import { IconWarning } from '@perts/ui';

import { equityGapText } from './EquityGapLegend';

// Note: styling the Icon itself directly, while also nesting it inside
// an antd Tooltip, triggers the error
//   Warning: Function components cannot be given refs. Attempts to access this
//   ref will fail. Did you mean to use React.forwardRef()?
// So we don't do this:
// const IconStyled = styled(IconWarning)`...`;

const IconStyleWrapper = styled.span`
  svg {
    position: relative;
    top: 2px;
    margin: 0 5px;
  }
`;

export const EquityGapIcon = () => (
  <IconStyleWrapper>
    <Tooltip title={equityGapText} trigger={['hover', 'click']}>
      <IconWarning aria-label={equityGapText} />
    </Tooltip>
  </IconStyleWrapper>
);
