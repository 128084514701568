import { Button, Col, Row } from '@perts/ui';

const MagicLinkRequest = ({ isLoading, handleOnClick }) => (
  <div data-test="MagicLinkRequest">
    <Row>
      <Col>
        <h2>Hello!</h2>
        <p>
          In order to view the resource you requested, we&rsquo;ll need to email
          you a magic link to sign you into Copilot.
        </p>
      </Col>
    </Row>
    <Row>
      <Col>
        <Button
          data-test="MagicLinkButton"
          loading={isLoading}
          onClick={handleOnClick}
        >
          Send Me The Magic Link
        </Button>
      </Col>
    </Row>
  </div>
);

export default MagicLinkRequest;
