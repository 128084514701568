import { Cycle, CycleForCreate, templateCycleNew } from 'models';
import {
  queryForUserByOrg,
  post,
  update,
  updateCycles,
  remove,
} from 'services/triton/cycles';

// -----------------------------------------------------------------------------
//   API Functions
// -----------------------------------------------------------------------------

// Create Cycle

type CycleCreate = (cycle: CycleForCreate) => Promise<Cycle>;

export const cycleCreate: CycleCreate = post;

// Create Cycle, with no dates set, associated with provided `classId`.

type CycleCreateNew = (classId: string) => Promise<Cycle>;

export const cycleCreateNew: CycleCreateNew = (classId) =>
  cycleCreate(templateCycleNew(classId));

// Query all Cycles that belong to the user's classes within a group.

type CyclesQueryByUserIdAndGroupId = (
  userId: string,
  groupId: string,
) => Promise<Cycle[]>;

export const cyclesQueryByUserIdAndGroupId: CyclesQueryByUserIdAndGroupId =
  queryForUserByOrg;

// Update Cycle

type UpdateCycle = (cycle: Cycle) => Promise<Cycle>;

export const updateCycle: UpdateCycle = update;

// Update Cycles by Team, Batch

export { updateCycles };

// Remove Cycle

type RemoveCycle = (cycleId: string) => Promise<void>;

export const removeCycle: RemoveCycle = remove;

// Remove Cycles

export const removeCycles = (cycles: Cycle[]) =>
  Promise.all(cycles.map((cycle) => removeCycle(cycle.uid)));
