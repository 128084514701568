import { stringIsRegex, stringToEndingRegex, clearRegex } from '@perts/util';
import { Class } from 'models';

export type ClassSettingsSignOnProps = Pick<
  Class,
  | 'participant_pattern'
  | 'participant_ending'
  | 'portal_message'
  | 'portal_type'
>;

export const getRosterIdRuleClassProps = (values): ClassSettingsSignOnProps => {
  const portalSettings = {
    portal_type: values.portal_type,
    portal_message: values.portal_message,
  };

  if (!values.ruleSwitch) {
    // If the roster ID rule is disabled, then set null patterns.
    return {
      participant_pattern: null,
      participant_ending: null,
      ...portalSettings,
    };
  }

  const patternIsRegex = stringIsRegex(values.rule || '');

  // Converted string to regex format
  const clearedRegex = values.rule ? clearRegex(values.rule) : '';

  // Converted string to regex format
  const stringRegex = values.rule ? stringToEndingRegex(values.rule) : '';

  const minMaxRegex = `^\\d{${values.studentIdMin},${values.studentIdMax}}$`;
  const minMaxEnding = `${values.studentIdMin},${values.studentIdMax}`;

  const participant_pattern = patternIsRegex
    ? clearedRegex
    : values.portal_type === 'student_id'
    ? minMaxRegex
    : stringRegex;

  const participant_ending = patternIsRegex
    ? null
    : values.portal_type === 'student_id'
    ? minMaxEnding
    : values.rule;

  return {
    participant_pattern,
    participant_ending,
    ...portalSettings,
  };
};
