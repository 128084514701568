import { Button, Card, Col, HelpText, Row, Show, Text, theme } from '@perts/ui';
import { formatPercent } from '@perts/util';

import { FIDELITY_THRESHOLD, helpArticles } from 'config';
import { getCumulativeChange, getRecentCycleValue } from 'utils';
import { useTerms } from 'components/TermsContext';
import { BarChartRatedPositively, FidelityWarningIcon } from '../components';

type Props = {
  fidelityMakeBetter: (number | null)[];
};

const NotEnoughData = () => {
  const terms = useTerms();
  return (
    <Card>
      <Card.Title>
        <Row>
          <Col cols={6}>
            <Text as="h2">Communication Fidelity</Text>
          </Col>
          <Col vAlign="center" hAlign="center" cols={6}>
            Not enough data
          </Col>
        </Row>
      </Card.Title>
      <Card.Content>
        <Row>
          <Col>
            Fewer than five {terms.participants.toLowerCase()} answered the
            question, &ldquo;{terms.fidelityMakeBetter}&rdquo;
          </Col>
        </Row>
      </Card.Content>
    </Card>
  );
};

export const CommunicationFidelity = ({ fidelityMakeBetter }: Props) => {
  const terms = useTerms();

  // The calculations are easier if we use the decimal form.
  const threshold = FIDELITY_THRESHOLD / 100;

  // The data provides fidelity scores by cycle. We want to display (1) the most
  // recent value and (2) the overall change.
  const recentMakeBetter = getRecentCycleValue(fidelityMakeBetter);
  const change = Math.round(getCumulativeChange(fidelityMakeBetter) * 100);

  // Whether the recent value is below desired threshold.
  const lowMakeBetter =
    recentMakeBetter !== null && recentMakeBetter < threshold;

  if (recentMakeBetter === null) {
    return <NotEnoughData />;
  }

  return (
    <Card checked={lowMakeBetter}>
      <Card.Title>
        <Row>
          <Col shrink vAlign="center">
            <Text as="h2">
              <Show when={lowMakeBetter}>
                <FidelityWarningIcon title="Needs improvement" />
              </Show>
              Communication Fidelity
            </Text>
          </Col>

          <Col vAlign="center" hAlign="flex-end">
            <HelpText articleId={helpArticles.surveyFidelityTable}>
              Why fidelity matters
            </HelpText>
          </Col>
        </Row>
      </Card.Title>
      <Card.Content>
        <Row>
          <Col>
            <span>
              {lowMakeBetter ? '' : <strong>Great work!</strong>}{' '}
              {lowMakeBetter ? 'Less' : 'More'} than {FIDELITY_THRESHOLD}% of{' '}
              {terms.participants.toLowerCase()} agreed with: &ldquo;
              {terms.fidelityMakeBetter}&rdquo;{' '}
            </span>
            <Show when={!isNaN(change)}>
              <span>
                You&rsquo;ve seen a{' '}
                <strong>
                  {change >= 0 ? '+' : ''}
                  {change} point {change >= 0 ? 'increase' : 'decrease'}
                </strong>{' '}
                from the first survey.{' '}
              </span>
            </Show>
            <Show when={lowMakeBetter}>
              High communication fidelity is essential for improvement.{' '}
            </Show>
          </Col>
        </Row>

        <Row>
          <Col cols={6}>
            <BarChartRatedPositively
              color={
                lowMakeBetter ? theme.colors.yellow : theme.colors.greenDark
              }
              height={40}
              label="Rated positively on last survey"
              threshold={threshold}
              value={recentMakeBetter}
              valueDisplay={<strong>{formatPercent(recentMakeBetter)}</strong>}
            />
          </Col>

          <Show when={lowMakeBetter}>
            <Col vAlign="center" hAlign="flex-end">
              <Button articleId={helpArticles.surveyFidelityTable}>
                How to Improve Fidelity
              </Button>
            </Col>
          </Show>
        </Row>
      </Card.Content>
    </Card>
  );
};
