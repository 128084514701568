import type { IconType } from '@react-icons';

import { AiFillEyeInvisible } from '@react-icons/all-files/ai/AiFillEyeInvisible';
import { BiBell } from '@react-icons/all-files/bi/BiBell';
import { BiEditAlt } from '@react-icons/all-files/bi/BiEditAlt';
import { BiLogIn } from '@react-icons/all-files/bi/BiLogIn';
import { BsFillCalendarFill } from '@react-icons/all-files/bs/BsFillCalendarFill';
import { FaAngleDoubleLeft } from '@react-icons/all-files/fa/FaAngleDoubleLeft';
import { FaAngleDoubleRight } from '@react-icons/all-files/fa/FaAngleDoubleRight';
import { FaArchive } from '@react-icons/all-files/fa/FaArchive';
import { FaAward } from '@react-icons/all-files/fa/FaAward';
import { FaBars } from '@react-icons/all-files/fa/FaBars';
import { FaCaretDown } from '@react-icons/all-files/fa/FaCaretDown';
import { FaCaretRight } from '@react-icons/all-files/fa/FaCaretRight';
import { FaCaretUp } from '@react-icons/all-files/fa/FaCaretUp';
import { FaCheck } from '@react-icons/all-files/fa/FaCheck';
import { FaChevronCircleDown } from '@react-icons/all-files/fa/FaChevronCircleDown';
import { FaChevronCircleLeft } from '@react-icons/all-files/fa/FaChevronCircleLeft';
import { FaChevronCircleRight } from '@react-icons/all-files/fa/FaChevronCircleRight';
import { FaChevronCircleUp } from '@react-icons/all-files/fa/FaChevronCircleUp';
import { FaChevronDown } from '@react-icons/all-files/fa/FaChevronDown';
import { FaChevronLeft } from '@react-icons/all-files/fa/FaChevronLeft';
import { FaChevronRight } from '@react-icons/all-files/fa/FaChevronRight';
import { FaClipboardList } from '@react-icons/all-files/fa/FaClipboardList';
import { FaCog } from '@react-icons/all-files/fa/FaCog';
import { FaEllipsisV } from '@react-icons/all-files/fa/FaEllipsisV';
import { FaEnvelope } from '@react-icons/all-files/fa/FaEnvelope';
import { FaExclamationTriangle } from '@react-icons/all-files/fa/FaExclamationTriangle';
import { FaExternalLinkAlt } from '@react-icons/all-files/fa/FaExternalLinkAlt';
import { FaIdBadge } from '@react-icons/all-files/fa/FaIdBadge';
import { FaFileExport } from '@react-icons/all-files/fa/FaFileExport';
import { FaLock } from '@react-icons/all-files/fa/FaLock';
import { FaGoogle } from '@react-icons/all-files/fa/FaGoogle';
import { FaPrint } from '@react-icons/all-files/fa/FaPrint';
import { FaRegCheckCircle } from '@react-icons/all-files/fa/FaRegCheckCircle';
import { FaRegCopy } from '@react-icons/all-files/fa/FaRegCopy';
import { FaRegQuestionCircle } from '@react-icons/all-files/fa/FaRegQuestionCircle';
import { FaSort } from '@react-icons/all-files/fa/FaSort';
import { FaSortDown } from '@react-icons/all-files/fa/FaSortDown';
import { FaSortUp } from '@react-icons/all-files/fa/FaSortUp';
import { FaSpinner } from '@react-icons/all-files/fa/FaSpinner';
import { FaTimesCircle } from '@react-icons/all-files/fa/FaTimesCircle';
import { FaTrash } from '@react-icons/all-files/fa/FaTrash';
import { FaUnlock } from '@react-icons/all-files/fa/FaUnlock';
import { FaUser } from '@react-icons/all-files/fa/FaUser';
import { FaUserCircle } from '@react-icons/all-files/fa/FaUserCircle';
import { FaUserCog } from '@react-icons/all-files/fa/FaUserCog';
import { FaUserMinus } from '@react-icons/all-files/fa/FaUserMinus';
import { FiUserX } from '@react-icons/all-files/fi/FiUserX';
import { FaUserPlus } from '@react-icons/all-files/fa/FaUserPlus';
import { FaUsers } from '@react-icons/all-files/fa/FaUsers';
import { FiLogOut } from '@react-icons/all-files/fi/FiLogOut';
import { FiTarget } from '@react-icons/all-files/fi/FiTarget';
import { GiCube } from '@react-icons/all-files/gi/GiCube';
import { GoPrimitiveDot } from '@react-icons/all-files/go/GoPrimitiveDot';
import { GrTableAdd } from '@react-icons/all-files/gr/GrTableAdd';
import { HiSelector } from '@react-icons/all-files/hi/HiSelector';
import { IoIosRemoveCircleOutline } from '@react-icons/all-files/io/IoIosRemoveCircleOutline';
import { IoMdAddCircleOutline } from '@react-icons/all-files/io/IoMdAddCircleOutline';
import { MdFullscreen } from '@react-icons/all-files/md/MdFullscreen';
import { MdFullscreenExit } from '@react-icons/all-files/md/MdFullscreenExit';
import { RiQrCodeLine } from '@react-icons/all-files/ri/RiQrCodeLine';
import { MdRecordVoiceOver } from '@react-icons/all-files/md/MdRecordVoiceOver';
import { RiBubbleChartFill } from '@react-icons/all-files/ri/RiBubbleChartFill';
import { RiCheckFill } from '@react-icons/all-files/ri/RiCheckFill';
import { RiCloseFill } from '@react-icons/all-files/ri/RiCloseFill';
import { RiFileTextFill } from '@react-icons/all-files/ri/RiFileTextFill';
import { RiFilterFill } from '@react-icons/all-files/ri/RiFilterFill';
import { RiFilterOffFill } from '@react-icons/all-files/ri/RiFilterOffFill';
import { RiPushpin2Fill } from '@react-icons/all-files/ri/RiPushpin2Fill';
import { RiQuestionAnswerFill } from '@react-icons/all-files/ri/RiQuestionAnswerFill';
import { RiRulerLine } from '@react-icons/all-files/ri/RiRulerLine';
import { RiSubtractFill } from '@react-icons/all-files/ri/RiSubtractFill';

import { ReactComponent as CleverLogo } from '../../assets/images/cleverLogo.svg';

const iconHoc = (Component: IconType) => (props: any) =>
  <Component {...props} role="img" />;

export const IconAdd = iconHoc(IoMdAddCircleOutline);
export const IconArchive = iconHoc(FaArchive);
export const IconAward = iconHoc(FaAward);
export const IconBell = iconHoc(BiBell);
export const IconBubbles = iconHoc(RiBubbleChartFill);
export const IconCalendar = iconHoc(BsFillCalendarFill);
export const IconCancel = iconHoc(FaTimesCircle);
export const IconCaretDown = iconHoc(FaCaretDown);
export const IconCaretRight = iconHoc(FaCaretRight);
export const IconCaretUp = iconHoc(FaCaretUp);
export const IconCheck = iconHoc(FaCheck);
export const IconCheckCircle = iconHoc(FaRegCheckCircle);
export const IconCheckboxCheck = iconHoc(RiCheckFill);
export const IconCheckboxIndeterminate = iconHoc(RiSubtractFill);
export const IconChevronCircleDown = iconHoc(FaChevronCircleDown);
export const IconChevronCircleLeft = iconHoc(FaChevronCircleLeft);
export const IconChevronCircleRight = iconHoc(FaChevronCircleRight);
export const IconChevronCircleUp = iconHoc(FaChevronCircleUp);
export const IconChevronDoubleLeft = iconHoc(FaAngleDoubleLeft);
export const IconChevronDoubleRight = iconHoc(FaAngleDoubleRight);
export const IconChevronDown = iconHoc(FaChevronDown);
export const IconChevronLeft = iconHoc(FaChevronLeft);
export const IconChevronRight = iconHoc(FaChevronRight);
export const IconClever = iconHoc(CleverLogo);
export const IconClipboardList = iconHoc(FaClipboardList);
export const IconClose = iconHoc(RiCloseFill);
export const IconConversation = iconHoc(RiQuestionAnswerFill);
export const IconCopy = iconHoc(FaRegCopy);
export const IconCube = iconHoc(GiCube);
export const IconDot = iconHoc(GoPrimitiveDot);
export const IconEdit = iconHoc(BiEditAlt);
export const IconEnvelope = iconHoc(FaEnvelope);
export const IconExport = iconHoc(FaFileExport);
export const IconExternalLink = iconHoc(FaExternalLinkAlt);
export const IconFilter = iconHoc(RiFilterFill);
export const IconFilterOff = iconHoc(RiFilterOffFill);
export const IconFullwindow = iconHoc(MdFullscreen);
export const IconFullwindowExit = iconHoc(MdFullscreenExit);
export const IconGoogle = iconHoc(FaGoogle);
export const IconHelp = iconHoc(FaRegQuestionCircle);
export const IconIdBadge = iconHoc(FaIdBadge);
export const IconLock = iconHoc(FaLock);
export const IconLogin = iconHoc(BiLogIn);
export const IconLogout = iconHoc(FiLogOut);
export const IconMenu = iconHoc(FaBars);
export const IconMenuEllipsis = iconHoc(FaEllipsisV);
export const IconNotVisible = iconHoc(AiFillEyeInvisible);
export const IconPin = iconHoc(RiPushpin2Fill);
export const IconPrint = iconHoc(FaPrint);
export const IconQRCode = iconHoc(RiQrCodeLine);
export const IconRemove = iconHoc(IoIosRemoveCircleOutline);
export const IconReport = iconHoc(RiFileTextFill);
export const IconSelector = iconHoc(HiSelector);
export const IconSettings = iconHoc(FaCog);
export const IconSort = iconHoc(FaSort);
export const IconSortDown = iconHoc(FaSortDown);
export const IconSortUp = iconHoc(FaSortUp);
export const IconSpinner = iconHoc(FaSpinner);
export const IconTableExpand = GrTableAdd;
export const IconTarget = iconHoc(FiTarget);
export const IconTrash = iconHoc(FaTrash);
export const IconUnlock = iconHoc(FaUnlock);
export const IconUser = iconHoc(FaUser);
export const IconUserCircle = iconHoc(FaUserCircle);
export const IconUserAccount = iconHoc(FaUserCog);
export const IconUserAdd = iconHoc(FaUserPlus);
export const IconUserDelete = iconHoc(FaUserMinus);
export const IconUserVoice = iconHoc(MdRecordVoiceOver);
export const IconUserX = iconHoc(FiUserX);
export const IconUsergroup = iconHoc(FaUsers);
export const IconWarning = iconHoc(FaExclamationTriangle);
export const IconRule = iconHoc(RiRulerLine);
