import urijs from 'urijs';

import store from 'state/store';
import { redirectTo } from 'state/ui/actions';
import { toLogout, toNetworkTest } from 'routes';
import { reportApiError } from 'services';
import { NEPTUNE_DOMAIN } from '../config';
import { setJwtToken } from './setJwtToken';
import { paginationFromResponse } from './paginationFromResponse';

/**
 * @param  {Object} response fetch response object
 * @return {[type]}          [description]
 */
export const handleApiResponse = async (response) => {
  if (response.ok) {
    // NOTE: msw always sets response.url to an empty string as of 2022-05-31,
    // so this logic may be impossible to test without jest.mock().
    const isNeptuneResponse = urijs(response.url).host() === NEPTUNE_DOMAIN;
    if (!isNeptuneResponse && response.headers.get('authorization')) {
      // Use the most recent token from the server for future calls. Important
      // to keep the token fresh. Authorization header may not be present if the
      // user (1) is not authenticated or (2) only has a google token with
      // email_verified false.
      setJwtToken(response);
    }
    // But _don't_ store tokens from Neptune, which has different user accounts.
    // We don't want to use the uid Neptune may have returned if the current
    // user also has a Neptune account with the same email address but different
    // uid.

    if (response.status === 204) {
      // HTTP 204 No Content
      return null;
    }

    // Parse the response body.
    const data = await response.json();

    // Retrieve pagination from headers, if available.
    const pagination = paginationFromResponse(response);

    if (pagination) {
      // When returning data with pagination, the receiving useQuery hook must
      // handle result data on result.data.data. See useQueryWithPagination.
      return { data, pagination };
    }

    return data;
  }

  if (response.status === 401) {
    if (window.location.pathname === toNetworkTest()) {
      // If the user is already on network test, we don't want to redirect the
      // user to the Login page because then they'll attempt to authenticate and
      // part of the network test is verifying that they can connect to our
      // auth.perts.net authentication service.
      return undefined;
    }

    store.dispatch(redirectTo(toLogout({ expiredSession: true })));
    return undefined;
  }

  // https://github.com/perts/perts/issues/1366
  if (response.status === 403) {
    store.dispatch(redirectTo(toLogout()));
    return undefined;
  }

  // else, get any useful information from the response and reject
  reportApiError(response);

  return response.text().then((body) => {
    const error = new Error(body);
    error.code = response.status;
    try {
      error.message = body ? JSON.parse(body) : '';
    } catch (e) {
      if (e instanceof SyntaxError) {
        console.error('Triton returned non-JSON error message:', body);
        error.message = body;
      } else {
        throw e;
      }
    }
    return Promise.reject(error);
  });
};
