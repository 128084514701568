import { InfoBox } from '@perts/ui';

// Reasons why there might not be a report
//
// 1. The reporting unit was created recently (all dataset types)
// 2. The user was recently associated with an org (organizationUser only)
// 3. There are no survey responses (all dataset types)
//
// In all cases, they can expect their report on Monday, assuming there are
// survey responses. This is because we only run dwDatasetTaskWorker with
// `"process_results": true` on Mondays.

export const NoReportMessage = () => (
  <InfoBox color="warning">
    <div>No data available.</div>
    <div>
      This may occur if you joined this community or signed in for the first
      time recently.
    </div>
    <div>If data is collected, a report will be available next Monday.</div>
  </InfoBox>
);
