import { useContext } from 'react';
import { useHistory } from 'react-router-dom';

import TermsContext from 'components/TermsContext';
import {
  Actionbar,
  Button,
  Col,
  IconUserAdd,
  IconTarget,
  Row,
  Show,
} from '@perts/ui';
import { toGroupClassRoster, useParams } from 'pages';

export const ClassRosterParticipantsActionbarAdd = ({ targetGroupEnabled }) => {
  const terms = useContext(TermsContext);
  const { groupId, classId } = useParams();
  const history = useHistory();

  return (
    <Actionbar>
      <Row alignItems="center" justifyContent="flex-end">
        <Show when={targetGroupEnabled}>
          <Col cols={0} colsSm={12}>
            <Button
              iconLeft={<IconTarget />}
              color="secondary"
              onClick={() => {
                history.push(
                  `${toGroupClassRoster(
                    groupId,
                    classId,
                    'settings',
                  )}?optionId=focal-group`,
                );
              }}
              fullWidth
            >
              Edit Focal Group
            </Button>
          </Col>
        </Show>

        <Col cols={0} colsSm={12}>
          <Button
            iconLeft={<IconUserAdd />}
            color="secondary"
            onClick={() => {
              history.push(toGroupClassRoster(groupId, classId, 'batch-edit'));
            }}
            fullWidth
          >
            {`Add ${terms.participants}`}
          </Button>
        </Col>
      </Row>
    </Actionbar>
  );
};
