import { Formik, Form } from 'formik';
import { Metric, MetricSet } from 'models';

import {
  Col,
  InputFieldError,
  Row,
  Select,
  Show,
  Text,
  useFormError,
} from '@perts/ui';
import { DefaultSettingsWarning, FormButtons, useTerms } from 'components';
import useToggles from 'utils/useToggles';

import { ObjectWithMetrics, useMetricsDataForm } from '..';
import { SurveysEditMetricsFormContent } from './SurveysEditMetricsFormContent';
import { SurveysEditMetricsFormSwitch } from './SurveysEditMetricsFormSwitch';

// Note, I'd like to type SurveysEditMetricsFormValues something like one of the
// following...
// export type SurveysEditMetricsFormValues = {
//   ...ToggleStates;
//   // Used by default settings form.
//   metricsEnabled?: boolean;
// };
// -- or --
// export type SurveysEditMetricsFormValues = ToggleStates && {
//   // Used by default settings form.
//   metricsEnabled?: boolean;
// };
// ...but I'm not sure how in a way TypeScript will accept. So using any.
export type SurveysEditMetricsFormValues = any;

type SurveysEditMetricsFormProps = {
  close?: () => void;
  submitButtonText: React.ReactNode;
  onSubmit: (values: SurveysEditMetricsFormValues) => void;
  validate?: (values: SurveysEditMetricsFormValues) => void | object;
  initialValues: SurveysEditMetricsFormValues;
  defaultValues: SurveysEditMetricsFormValues | null;
  programValues?: SurveysEditMetricsFormValues;
  // MetricSet related props
  availableMetrics: Metric[];
  initialMetrics: ObjectWithMetrics[];
  metricSets: MetricSet[];
};

export const SurveysEditMetricsForm = ({
  close,
  submitButtonText,
  onSubmit,
  validate,
  initialValues,
  defaultValues,
  programValues,
  availableMetrics,
  metricSets,
}: SurveysEditMetricsFormProps) => {
  const terms = useTerms();
  const [FormError, showFormError] = useFormError();
  const isDefaultSettingsView = initialValues.hasOwnProperty('metricsEnabled');

  const { isChecked, isIndeterminate, toggleChecked } = useToggles(
    availableMetrics,
    'uid',
    {
      initialState: initialValues,
    },
  );

  const {
    isMetricSetsFeature,
    selectMetricSetOptions,
    view,
    setView,
    isViewingActive,
    selectedMetricSets,
  } = useMetricsDataForm({
    isChecked,
    isIndeterminate,
    metrics: availableMetrics,
    metricSets,
  });

  return (
    <>
      <Row alignItems="center">
        <Col shrink vAlign="center" hAlign="center">
          <Text as="h2">
            <span>Survey {terms.metrics}</span>
          </Text>
        </Col>

        <Show when={!Boolean(close)}>
          <Col></Col>
          {/* Slot for KB Article, default setting view. */}
        </Show>
      </Row>

      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validate={validate}
        onSubmit={async (values) => {
          try {
            // Clear existing form error.
            showFormError(false);

            // Perform form onSubmit.
            await onSubmit(values);
          } catch (error) {
            // Display form error.
            showFormError(true);
          }
        }}
      >
        {({ errors = {}, values = {}, isSubmitting, setFieldValue }) => (
          <Form>
            {programValues && (
              <SurveysEditMetricsFormSwitch
                programValues={programValues}
                toggleChecked={toggleChecked}
              />
            )}

            <Show when={isMetricSetsFeature}>
              <Row>
                <Col>
                  <Select
                    name="selectMetricSets"
                    label="View"
                    placeholder="Active Sets"
                    options={selectMetricSetOptions}
                    keyBy="uid"
                    keyName="name"
                    value={view}
                    onChange={setView}
                    fullWidth
                    displayErrors={false}
                  />
                </Col>
              </Row>
            </Show>

            <SurveysEditMetricsFormContent
              selectedMetricSets={selectedMetricSets}
              isChecked={isChecked}
              isIndeterminate={isIndeterminate}
              toggleChecked={toggleChecked}
              setFieldValue={setFieldValue}
              isSubmitting={isSubmitting}
              values={values}
              isViewingActive={isViewingActive}
              errors={errors}
              isDisabled={'metricsEnabled' in values && !values.metricsEnabled}
            />

            {/*
              If no validate function is provided, don't render the error field
              so that we can save some vertical space.
            */}
            <Show when={!isViewingActive && Boolean(validate)}>
              <InputFieldError
                role="alert"
                aria-live="assertive"
                id="metrics-text-field-error"
                data-testid="metrics-text-field-error"
              >
                {errors.metrics}
              </InputFieldError>
            </Show>

            <Row>
              <Col>
                <FormError />
                <Show when={!isDefaultSettingsView}>
                  <DefaultSettingsWarning
                    hideWhenErrors={true}
                    message={`This change doesn't match the ${terms.group}'s default settings.`}
                    defaultValues={defaultValues}
                    toggleChecked={toggleChecked}
                  />
                </Show>
              </Col>
            </Row>

            <Show when={Boolean(close)}>
              <Row>{/* Slot for KB Article, modal view */}</Row>
            </Show>

            <FormButtons close={close} submitButtonText={submitButtonText} />
          </Form>
        )}
      </Formik>
    </>
  );
};
