import { useEffect, useState } from 'react';
import { Program, useCurrentUserQuery } from 'models';
import { ArchiveActionbar } from './ArchiveActionbar';
import { ArchiveResultsGroups } from './ArchiveResultsGroups';
import { ArchiveResultsNetworks } from './ArchiveResultsNetworks';

type Props = {
  programs: Program[];
  programId?: string;
  filter: string;
  programIsLoading: boolean;
};

export const ArchiveResults = ({
  programs,
  programId,
  filter,
  programIsLoading,
}: Props) => {
  const { data: user, isLoading: userIsLoading } = useCurrentUserQuery();
  const [activeIndexGroups, setActiveIndexGroups] = useState(0);
  const [activeIndexNetworks, setActiveIndexNetworks] = useState(0);

  useEffect(() => {
    // Return to first page when program or filter changes.
    setActiveIndexGroups(0);
    setActiveIndexNetworks(0);
  }, [programId, filter]);

  return (
    <div>
      <ArchiveActionbar
        programs={programs}
        isLoading={userIsLoading || programIsLoading}
      />

      {user && programId && (
        <ArchiveResultsNetworks
          programId={programId}
          filter={filter}
          activePageIndex={activeIndexNetworks}
          setActivePageIndex={setActiveIndexNetworks}
        />
      )}

      {user && programId && (
        <ArchiveResultsGroups
          programId={programId}
          filter={filter}
          activePageIndex={activeIndexGroups}
          setActivePageIndex={setActiveIndexGroups}
        />
      )}
    </div>
  );
};
