import { useFormikContext } from 'formik';
import pluralize from 'pluralize';

import { Col, Input, Row, Switch } from '@perts/ui';
import { useTerms } from 'components/TermsContext';

export const MembersPinFormSwitch = ({ group, members }) => {
  const { values, setFieldValue } = useFormikContext<any>();
  const terms = useTerms();
  const termMembers = pluralize(terms.groupMember, members.length, false);

  const onChangeSwitch = (e) => {
    // Toggle switch value.
    setFieldValue('isPinned', e.target.checked);

    // Any interaction with the form means it's no longer indeterminate.
    if (values.isPinnedIndeterminate) {
      setFieldValue('isPinnedIndeterminate', false);
    }
  };

  const labelText = values.isPinnedIndeterminate ? (
    <strong>{termMembers} have mixed settings.</strong>
  ) : values.isPinned ? (
    <span>
      <strong>Pinned</strong>: Selected {termMembers.toLowerCase()} will have
      &ldquo;{group.name}&rdquo; pinned on their home page.
    </span>
  ) : (
    <span>
      <strong>Unpinned</strong>: Selected {termMembers.toLowerCase()}{' '}
      won&rsquo;t have &ldquo;{group.name}&rdquo; pinned on their home page.
    </span>
  );

  return (
    <Row>
      <Col>
        <Input
          id="isPinned"
          name="isPinned"
          label={labelText}
          aria-label={labelText}
          labelPlacement="end"
          component={Switch}
          checked={values.isPinned}
          indeterminate={values.isPinnedIndeterminate}
          onChange={onChangeSwitch}
        />
      </Col>
    </Row>
  );
};
