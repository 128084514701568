import moment from 'moment';

import { dateFormatShort } from 'config';
import {
  toNetworkGroups,
  toNetworkMembers,
  toNetworkParticipation,
  toNetworkReport,
} from 'pages';
import { useTerms } from 'components';

import {
  Card,
  Col,
  IconBubbles,
  IconConversation,
  IconReport,
  IconUsergroup,
  Row,
  Show,
} from '@perts/ui';
import { HomeCardButton } from './HomeCardButton';
import { HomeEditNetworkName } from './HomeEditNetworkName';
import { HomeNetworkDropdown } from './HomeNetworkDropdown';
import { Network } from 'models';

type CardNetworkProps = {
  network: Network;
  mayRemoveNetworks: boolean;
  mayEditNameInPlace: boolean;
};

export const CardNetwork = ({
  network,
  mayRemoveNetworks,
  mayEditNameInPlace,
}: CardNetworkProps) => {
  const terms = useTerms();
  const reportDate = network.last_updated_results
    ? moment(network.last_updated_results).format(dateFormatShort)
    : null;

  return (
    <Card>
      <Card.Title>
        <Row>
          <Col>
            <HomeEditNetworkName
              network={network}
              mayEditNameInPlace={mayEditNameInPlace}
            />
          </Col>
          <Show when={mayRemoveNetworks}>
            <Col shrink hAlign="flex-end" vAlign="center">
              <HomeNetworkDropdown network={network} />
            </Col>
          </Show>
        </Row>
      </Card.Title>

      <Card.Content>
        <Row>
          <Col colsSm={6}>
            <HomeCardButton
              to={toNetworkMembers(network.short_uid)}
              iconLeft={<IconUsergroup />}
            >
              {terms.groupMembers}
            </HomeCardButton>
          </Col>

          <Col colsSm={6}>
            <HomeCardButton
              to={toNetworkGroups(network.short_uid)}
              iconLeft={<IconBubbles />}
            >
              {terms.groups} ({network.association_ids.length})
            </HomeCardButton>
          </Col>
          <Col colsSm={6}>
            <HomeCardButton
              to={toNetworkParticipation(network.short_uid)}
              iconLeft={<IconConversation />}
            >
              Participation
            </HomeCardButton>
          </Col>

          <Col colsSm={6}>
            <HomeCardButton
              to={toNetworkReport(network.uid)}
              iconLeft={<IconReport />}
            >
              Report {reportDate && <>({reportDate})</>}
            </HomeCardButton>
          </Col>
        </Row>
      </Card.Content>
    </Card>
  );
};
