import uri from 'urijs';
import { TRITON_URL_PREFIX } from './config';
import { fetchApi, fetchOptionsGET, fetchOptionsPOST } from './helpers';
import { PromptResponse } from 'models';

export const promptsQueryByUserAndRoute = (userId: string, route: string) => {
  const path = `${TRITON_URL_PREFIX}/api/users/${userId}/prompts`;
  const url = uri(path).search({ route }).toString();
  const options = fetchOptionsGET();
  return fetchApi(url, options);
};

export const promptResponsePost = (prompt: PromptResponse) => {
  const url = `${TRITON_URL_PREFIX}/api/prompt_responses`;
  const options = fetchOptionsPOST(prompt);
  return fetchApi(url, options);
};

export const promptResponsesPost = (prompts: PromptResponse[]) =>
  Promise.all(prompts.map((prompt) => promptResponsePost(prompt)));
