import { useContext } from 'react';
import { Group, GroupMember } from 'models';
import { Row, Col, InfoBox, Show } from '@perts/ui';
import pluralize from 'pluralize';
import TermsContext from 'components/TermsContext';

type ListMembersToRemoveProps = {
  group: Group;
  members: GroupMember[];
  includesSelf: boolean;
};

export const ListMembersToRemove = ({
  group,
  members,
  includesSelf,
}: ListMembersToRemoveProps) => {
  const terms = useContext(TermsContext);

  return (
    <Show when={members.length > 0}>
      <Row>
        <Col>
          Are you sure you want to remove{' '}
          {pluralize(terms.groupMember.toLowerCase(), members.length, true)}?
        </Col>
      </Row>

      <Row data-testid="members-list-to-remove">
        <Col>
          <ul>
            {members.map((member) => (
              <li key={member.uid} data-testid="members-list-to-remove-item">
                {member.email}
              </li>
            ))}
          </ul>
        </Col>
      </Row>

      <Show when={includesSelf}>
        <Row>
          <Col>
            <InfoBox color="warning" data-testid="members-list-self">
              This action will remove you from {group.name}.
            </InfoBox>
          </Col>
        </Row>
      </Show>
    </Show>
  );
};
