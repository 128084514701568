// This file isn't in typescript because react-table doesn't make its types
// easy to use. The following is an example from the author(s), where you have
// to wrangle many of their types for them to make it work, and I'm not going
// to bother:
// https://github.com/tannerlinsley/react-table/pull/1585/files/d80305deb5d4653c49222f750941d78a9b9da9a9

import React from 'react';
import { useTable, useSortBy, usePagination } from 'react-table';

import DataTable from 'components/DataTable';
import { DEFAULT_PAGE_SIZE } from 'config';
import { Col, Row } from '@perts/ui';

export const Table = ({ columns, data }) => {
  const {
    getTableBodyProps,
    headers,
    prepareRow,

    // from usePagination
    // https://codesandbox.io/embed/github/tannerlinsley/react-table/tree/master/examples/pagination
    page,
    canPreviousPage,
    canNextPage,
    // pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      // https://react-table.js.org/api/useSortBy#table-options
      initialState: {
        sortBy: [{ id: 'team_name' }],
        pageSize: DEFAULT_PAGE_SIZE,
      },
    },
    useSortBy,
    usePagination,
  );

  return (
    <>
      <DataTable>
        <thead>
          <tr>
            {headers.map((column) => (
              <DataTable.SortHeader
                {...column.getHeaderProps(column.getSortByToggleProps())}
              >
                {column.render('Header')}
                <DataTable.SortIcon {...column} />
              </DataTable.SortHeader>
            ))}
          </tr>
        </thead>
        <tbody {...getTableBodyProps}>
          {page.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => (
                  <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </DataTable>
      <Row>
        <Col hAlign="flex-start" />
        <Col hAlign="center">
          <DataTable.PageNav
            canNextPage={canNextPage}
            canPreviousPage={canPreviousPage}
            gotoPage={gotoPage}
            nextPage={nextPage}
            pageCount={pageCount}
            pageIndex={pageIndex}
            previousPage={previousPage}
          />
        </Col>
        <Col hAlign="flex-end">
          <DataTable.PageSize
            pageSize={pageSize}
            setPageSize={setPageSize}
            totalRows={data.length}
          />
        </Col>
      </Row>
    </>
  );
};
