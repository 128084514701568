import { Redirect } from 'react-router-dom';
import * as routes from 'routes';

const RedirectAuthenticatedUser = ({ program, continue_url }) => {
  let to = routes.toHomeNoProgram();

  if (continue_url) {
    to = continue_url;
  } else if (program) {
    // Wait, you say, why pass `program` to a "no program" scene? This version
    // of the home scene differentiates between a program in the search, which
    // may trigger fancy redirects that are intended only to affect people
    // logging in. If the program is in the path (toHome), these redirects don't
    // happen
    to = `${routes.toHomeNoProgram()}?program=${program}`;
  }

  return <Redirect to={to} />;
};

export default RedirectAuthenticatedUser;
