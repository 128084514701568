import styled, { css } from 'styled-components/macro';

import { MetricLogoStyled, theme } from '@perts/ui';

type Props = {
  fullscreen?: boolean;
};

export const ReportStyled = styled.div<Props>`
  ${({ fullscreen = false }) =>
    fullscreen &&
    css`
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      z-index: 100;
      padding: ${theme.units.bodyPadding};
      background-color: ${theme.colors.appBackground};
      overflow: auto;

      @media (max-width: ${theme.units.gridBreakpointMedium}px),
        (max-height: ${theme.units.gridBreakpointMedium}px) {
        padding: ${theme.units.bodyPaddingMd};
      }

      @media (max-width: ${theme.units.gridBreakpointSmall}px),
        (max-height: ${theme.units.gridBreakpointSmall}px) {
        padding: ${theme.units.bodyPaddingSm};
      }
    `}

  h1,
  h2 {
    text-align: center;
  }

  h1 {
    font-size: 24px;
  }

  h2 {
    font-size: 20px;
  }

  h3 {
    font-size: 16px;
  }

  section h3:not(:first-child) {
    margin-top: ${theme.units.bodyPadding};
  }

  // Add a pseudo-element before bookmarks so that the sticky navbar doesn't
  // overlap them.
  h2[id]:before,
  section[id]:before {
    display: block;
    content: ' ';
    margin: -125px 0 0;
    height: 125px;
    visibility: hidden;
    pointer-events: none;
  }

  @media (max-width: ${theme.units.gridBreakpointMedium}px) {
    h2[id]:before,
    section[id]:before {
      margin: -120px 0 0;
      height: 120px;
    }
  }

  @media (max-width: ${theme.units.gridBreakpointSmall}px) {
    h2[id]:before,
    section[id]:before {
      margin: -100px 0 0;
      height: 100px;
    }
  }

  // Size metric logos nicely. See MetricSection.
  ${MetricLogoStyled} {
    max-width: 100px;
    margin-right: ${theme.units.fieldPadding};
  }

  @media print {
    // ** Scaling down for printing ** //

    h1 {
      font-size: 20px;
    }

    h2 {
      font-size: 16px;
    }

    th,
    td,
    p,
    em,
    a {
      font-size: 10px;
    }

    // ** Page Breaks ** //
    figure,
    table {
      break-inside: avoid;
    }
  }
`;
