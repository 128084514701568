import { useMemo } from 'react';
import { useTerms } from 'components';
import { useSortBy } from './useSortBy';
import { IconFromTo } from './components';

export const useSortBySurveys = () => {
  const terms = useTerms();

  const sortByOptions = useMemo(
    () => [
      {
        value: 'name',
        name: `${terms.class} Name`,
        sortByDirectionOptions: [
          {
            name: (
              <>
                A <IconFromTo /> Z
              </>
            ),
            value: 'name:asc',
          },
          {
            name: (
              <>
                Z <IconFromTo /> A
              </>
            ),
            value: 'name:desc',
          },
        ],
      },
      {
        value: 'metricsLength',
        name: '# of Conditions',
        sortByDirectionOptions: [
          {
            name: (
              <>
                Low <IconFromTo /> High
              </>
            ),
            value: 'metricsLength:asc',
          },
          {
            name: (
              <>
                High <IconFromTo /> Low
              </>
            ),
            value: 'metricsLength:desc',
          },
        ],
      },
    ],
    [terms.class],
  );

  return useSortBy(sortByOptions);
};
