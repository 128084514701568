import { Button, Col, Row, Show } from '@perts/ui';

const MagicLinkErrors = ({ errors, isLoading, handleOnClick }) => (
  <>
    <Show when={errors === 1}>
      <div data-test="MagicLinkError">
        <Row>
          <Col>
            <h2>We&rsquo;re sorry!</h2>
            <p>
              There seems to have been a problem sending your email. You can try
              again.
            </p>
          </Col>
        </Row>
        <Row>
          <Col>
            <Button
              data-test="MagicLinkButton"
              loading={isLoading}
              onClick={handleOnClick}
            >
              Send Me The Magic Link
            </Button>
          </Col>
        </Row>
      </div>
    </Show>

    <Show when={errors > 1}>
      <div data-test="MagicLinkError">
        <Row>
          <Col>
            <h2>We&rsquo;re really sorry!</h2>
            <p>
              It seems we&rsquo;re still having trouble sending your email.
              Please contact{' '}
              <a href="mailto:support@perts.net">support@perts.net</a> for help.
            </p>
          </Col>
        </Row>
      </div>
    </Show>
  </>
);

export default MagicLinkErrors;
