import { keyBy, groupBy, omit, orderBy, padStart } from 'lodash';

import {
  getItemLabels,
  participantAttributeConfig,
  programMetricIndex,
} from '@perts/config';
import { OrganizationExperienceResults } from '@perts/model';
import { getShortUid, pivotWider } from '@perts/util';

import { Group, Metric, Network } from 'models';

const getMetricPrefix = (metricName: string) =>
  metricName.trim().replace(/\s+/g, '_').toLowerCase();

// Defines some column names. See linked codebooks below.
export const metricColName = (
  metrics: Metric[],
  metricLabel: string,
  cycleIndex: number,
) => {
  const metric = metrics.find((m) => m.label === metricLabel);
  const metricName = metric?.name || metricLabel;

  // Ensure metric name is in lower_snake_case.
  const prefix = getMetricPrefix(metricName);
  const cycleLabel = padStart(String(cycleIndex + 1), 2, '0');
  return `${prefix}_survey_${cycleLabel}`.replace(/-/g, '_');
};

// Defines some column names. See linked codebooks below.
export const itemColName = (
  metrics: Metric[],
  metricLabel: string,
  itemIndex: number,
  cycleIndex: number,
) => {
  const metric = metrics.find((m) => m.label === metricLabel);
  const metricName = metric?.name || metricLabel;

  const prefix = getMetricPrefix(metricName);
  const itemLabel = padStart(String(itemIndex + 1), 2, '0');
  const cycleLabel = padStart(String(cycleIndex + 1), 2, '0');
  return `${prefix}_${itemLabel}_survey_${cycleLabel}`.replace(/-/g, '_');
};

// Don't output the raw labels we use for participant attributes. Use the same
// names we display in reports.
const attrValueName = (attrPath) => {
  if (attrPath === 'all_participants') {
    return 'All';
  }
  const [attr, value] = attrPath.split('.');
  const attrConf = participantAttributeConfig[attr];
  const valueConf = (attrConf?.values || []).find((c) => c.label === value);
  return `${attrConf?.name}: ${valueConf?.name}`;
};

// Tool for counting sample sizes by org and demographic/attribute.
const nMaxKey = (groupId: string, attrPath: string) => `${groupId}:${attrPath}`;

type ResultsByGroup = {
  [organizationId: string]: OrganizationExperienceResults;
};

type LongRow = {
  wideColName: string;
  community_name: string;
  groupId: string;
  subset_value: string;
  ratedPositivelyCycle: number | null;
};

// Take survey results from a dataset and wrangle into the expected format for
// CSV export in a Network report. The codebooks to match are here:
// * http://perts.net/ascend/codebook22
// * http://perts.net/elevate/codebook22
// * http://perts.net/catalyze/codebook22
export const buildNetworkReportExport = (
  network: Network,
  groups: Group[],
  metrics: Metric[],
  resultsByGroup: ResultsByGroup,
) => {
  // In what position does a given question appear in its metric? Counts from 0.
  const getItemIndex = (metricLabel, itemLabel) => {
    const programLabel = getShortUid(network.program_id);
    const programConf = programMetricIndex[programLabel];
    const itemLabels = getItemLabels(programConf, metricLabel);
    return itemLabels.indexOf(itemLabel);
  };

  const groupIndex = keyBy(groups, 'uid');

  // The codebooks call for new columns both at the composite/metric level and
  // the item level. We'll calculate both in long form and then pivot once.

  // ** metric level data ** //

  const metricRows: LongRow[] = Object.entries(resultsByGroup).flatMap(
    ([groupId, groupData]) =>
      Object.entries(groupData.experience).flatMap(([metricLabel, expr]) =>
        Object.entries(expr.composite).flatMap(([attrPath, dataOverTime]) =>
          dataOverTime.map((ratedPositivelyCycle, cycleIndex) => ({
            // will be removed later
            groupId,
            attrPath,
            // will re-appear in wide data
            community_name: groupIndex[groupId].name,
            subset_value: attrValueName(attrPath),
            // used to pivot, won't appear in wide data
            wideColName: metricColName(metrics, metricLabel, cycleIndex),
            ratedPositivelyCycle,
          })),
        ),
      ),
  );

  // ** item level data ** //

  const itemRows: LongRow[] = Object.entries(resultsByGroup).flatMap(
    ([groupId, groupData]) =>
      Object.entries(groupData.experience).flatMap(([metricLabel, expr]) =>
        Object.entries(expr.by_item).flatMap(([itemLabel, byAttr]) =>
          Object.entries(byAttr).flatMap(([attrPath, dataOverTime]) =>
            dataOverTime.map((ratedPositivelyCycle, cycleIndex) => ({
              // will be removed later
              groupId,
              attrPath,
              // will re-appear in wide data
              community_name: groupIndex[groupId].name,
              subset_value: attrValueName(attrPath),
              // used to pivot, won't appear in wide data
              wideColName: itemColName(
                metrics,
                metricLabel,
                getItemIndex(metricLabel, itemLabel),
                cycleIndex,
              ),
              ratedPositivelyCycle,
            })),
          ),
        ),
      ),
  );

  // ** fidelity data ** //

  // Although these are not really "items", SG says they most natural way to
  // report on them is to make them like items. So we're going to pretend that
  // `make_better` is the first and only "item" of the `fidelity` "metric" and
  // the only subset value is "All".

  const fidelityRows: LongRow[] = Object.entries(resultsByGroup).flatMap(
    ([groupId, groupData]) =>
      Object.entries(groupData.fidelity).flatMap(([itemLabel, dataOverTime]) =>
        dataOverTime.map((ratedPositivelyCycle, cycleIndex) => ({
          // will be removed later
          groupId,
          attrPath: 'all_participants',
          // will re-appear in wide data
          community_name: groupIndex[groupId].name,
          subset_value: attrValueName('all_participants'),
          // used to pivot, won't appear in wide data
          wideColName: itemColName(metrics, 'fidelity', 0, cycleIndex),
          ratedPositivelyCycle,
        })),
      ),
  );

  // ** concat ** //

  const rowsLong = [...fidelityRows, ...itemRows, ...metricRows];

  // ** pivot ** //

  const rowsWide = pivotWider(rowsLong, 'wideColName', 'ratedPositivelyCycle');

  // ** nmax ** //

  // Codebook calls for each wide row to have a count of the responses
  // appearing. Since these can vary by question, we take a maximum.
  // Collect all the sample metric/attr numbers into flat rows for easy lookup.
  // * group by org and attribute (keys are like
  //   "Organization_XYZ:gender-3.woman")
  // * take the max sample size across all metrics
  const sampleSizeFlat = Object.entries(resultsByGroup).flatMap(
    ([groupId, groupData]) =>
      Object.entries(groupData.sample).flatMap(([metricLabel, sample]) =>
        Object.entries(sample).map(([attrPath, sampleSize]) => ({
          groupId,
          metricLabel,
          attrPath,
          sampleSize,
        })),
      ),
  );
  const sampleByAttr = groupBy(sampleSizeFlat, (row) =>
    nMaxKey(row.groupId, row.attrPath),
  );
  const nMaxByAttr = Object.fromEntries(
    Object.entries(sampleByAttr).map(([key, rows]) => [
      key,
      Math.max(...rows.map((row) => row.sampleSize)),
    ]),
  );

  // Look up the n_max value and assign to the original set of rows.
  // Omit props we don't need.
  const rowsNMax = rowsWide.map((row) => ({
    ...omit(row, 'groupId', 'attrPath'),
    n_max: nMaxByAttr[nMaxKey(row.groupId, row.attrPath)],
  }));

  // ** column order ** //

  // When viewing the CSV in a spreadsheet, column order matters. Put the
  // important columns on the left and alphabetize the remaining.
  const firstCols = ['community_name', 'subset_value', 'n_max'];
  const columnsUnsorted = rowsNMax.length > 0 ? Object.keys(rowsNMax[0]) : [];
  const experienceRows = columnsUnsorted.filter((c) => !firstCols.includes(c));
  experienceRows.sort();

  // ** row order ** //

  const rowsSorted = orderBy(rowsNMax, ['community_name', 'subset_value']);

  return {
    data: rowsSorted,
    columns: [...firstCols, ...experienceRows],
  };
};
