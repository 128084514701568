export enum PortalType {
  CLEVER = 'clever',
  GOOGLE = 'google',
  EMAIL = 'email',
  STUDENT_ID = 'student_id',
}

// These portal types are all similar in that we offer a "sign-on rule" feature
// that enforces matches of incoming user IDs to some ending, e.g. "@cps.edu".
// See for example getInitialValuesSignOn.ts.
export const portalTypesWithEndings = [
  PortalType.CLEVER,
  PortalType.GOOGLE,
  PortalType.EMAIL,
];

export enum SignInProvider {
  GOOGLE = 'google.com',
  PASSWORD = 'password',
}

export enum UserType {
  USER = 'user',
  SUPER_ADMIN = 'super_admin',
}

export enum MetricLinkType {
  READING = 'reading',
  IMAGE = 'img',
}

// Values allowed in the db field user_association.type
// See `AsscType` in legacy/triton/app/model/user_association.py
export enum UserAssociationType {
  MANAGED_ORGANIZATION = 'managed_organization',
  MANAGED_TEAM = 'managed_team',
  OWNED_NETWORK = 'owned_network',
  OWNED_ORGANIZATION = 'owned_organization',
  OWNED_TEAM = 'owned_team',
  PINNED_ORGANIZATION = 'pinned_organization',
}

// Attributes/properties of user objects that hold lists of associated IDs.
export enum UserAssociationAttribute {
  MANAGED_ORGANIZATIONS = 'managed_organizations',
  MANAGED_TEAMS = 'managed_teams',
  OWNED_NETWORKS = 'owned_networks',
  OWNED_ORGANIZATIONS = 'owned_organizations',
  OWNED_TEAMS = 'owned_teams',
  PINNED_ORGANIZATIONS = 'pinned_organizations',
}
