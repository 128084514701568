import { isTeamData } from '@perts/model';
import { getMessageFromErrors } from '@perts/util';

import Loading from 'components/Loading';
import {
  useClassGetByParams,
  useDatasetTeam,
  useGroupGetByParams,
  useMetricsQuery,
  useProgramsQuery,
} from 'models';
import { ErrorMessageBox } from 'components/ErrorMessageBox';

import { getAvailableMetrics, NoReportMessage } from '../components';

// eslint-disable-next-line complexity
export const ClassReportLoader = ({ children }) => {
  const classQuery = useClassGetByParams();
  const groupQuery = useGroupGetByParams();
  const metricsQuery = useMetricsQuery();
  const programsQuery = useProgramsQuery();

  // This has an `enabled` check that will trigger once the group and user ID
  // are loaded and available.
  const datasetQuery = useDatasetTeam(classQuery.data?.uid);

  if (
    classQuery.isLoading ||
    datasetQuery.isLoading ||
    metricsQuery.isLoading ||
    programsQuery.isLoading
  ) {
    return <Loading />;
  }

  if (!datasetQuery.isSuccess) {
    return <NoReportMessage />;
  }

  if (
    !classQuery.isSuccess ||
    !metricsQuery.isSuccess ||
    !programsQuery.isSuccess
  ) {
    return (
      <ErrorMessageBox>
        {getMessageFromErrors([
          classQuery.error,
          metricsQuery.error,
          programsQuery.error,
        ])}
      </ErrorMessageBox>
    );
  }

  const allMetrics = metricsQuery.data;
  const cls = classQuery.data;
  const dataset = datasetQuery.data;
  const group = groupQuery.data;
  const program = programsQuery.data.find((p) => p.uid === cls.program_id);
  if (!program) {
    throw new Error(`Couldn't find program for team: ${cls.program_id}`);
  }

  if (!dataset || !dataset.results) {
    return <NoReportMessage />;
  }

  if (!isTeamData(dataset)) {
    return (
      <ErrorMessageBox>
        Unexpected dataset type: "{(dataset as any).dataset_type}"
      </ErrorMessageBox>
    );
  }

  const programMetricIds = program.metrics.map(({ uid }) => uid);
  const programMetrics = allMetrics.filter(({ uid }) =>
    programMetricIds.includes(uid),
  );

  const reportMetrics = getAvailableMetrics(dataset.results, allMetrics);

  return children({
    cls,
    group,
    lastRun: dataset.last_run,
    lastUpdatedResults: dataset.last_updated_results,
    program,
    programMetrics,
    reportMetrics,
    results: dataset.results,
  });
};
