// Define a numeric sort for some row property. Expects integers.

// This is necessary because JavaScript sorts "alphabetically" by default, and
// react-table sorts "alphanumerically" by default, neither of which orders
// numbers correctly.
// https://react-table-v7.tanstack.com/docs/api/useSortBy
export const sortNumeric = (a: number | null, b: number | null) =>
  a === null || b === null ? -1 : a - b;

export const sortTableRowNumeric =
  (key: string) => (rowA: { original: object }, rowB: { original: object }) =>
    sortNumeric(rowA.original[key], rowB.original[key]);
