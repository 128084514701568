import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';

import './index.css';
import App from './App';
import { ThemeProvider } from 'styled-components';
import theme from 'components/theme';

import { Provider } from 'react-redux';
import store from 'state/store';
import googleAuth from 'services/auth/googleAuth';

import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

// Save parts of Redux store to localStorage on unload. Unload occurs when
// navigating away from the page (reloads count).

import saveToLocalStorage from 'services/saveToLocalStorage';
saveToLocalStorage(store);

const queryClient = new QueryClient();

googleAuth(store);

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <App />
      </Provider>

      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  </ThemeProvider>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
