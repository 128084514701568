// Create a `validate` function from a Yup validationSchema object.
//
// The return signature attempts to approximate Formik/React-Redux's validate.
// If invalid, return a string containing the error message or return undefined.
// https://formik.org/docs/api/field#validate
// https://formik.org/docs/guides/validation#field-level-validation
//
// Example usage:
//   const EmailValidation = Yup.string()
//     .email('Should be a valid email address.')
//     .max(200, 'Emails should be 200 characters or less.');
//   const validateEmail = validateFromYupSchema(EmailValidation);
//   ...
//   {/* Standard Input usage */}
//   <Input
//     name="email"
//     component={TextField}
//     validate={validateEmail}
//    />
//
//   {/* MultiSelectChip customValidation usage */}
//   <Input
//     name="inviteEmails"
//     component={MultiSelectChip}
//     customValidation={validateEmail}
//   />
//   ...

import { Schema } from 'yup';

type ValidateFromYupSchema = (
  ValidationSchema: Schema<any>,
) => (objectToValidate: any) => undefined | string;

const validateFromYupSchema: ValidateFromYupSchema =
  (ValidationSchema) => (objToValidate: any) => {
    let validationError = '';

    try {
      // https://github.com/jquense/yup#mixedvalidatesyncvalue-any-options-object-any
      ValidationSchema.validateSync(objToValidate);
    } catch (err: any) {
      validationError = err?.message ? String(err.message) : '';
    }

    return validationError;
  };

export default validateFromYupSchema;
