import styled from 'styled-components/macro';

import { CardStyled } from './CardStyled';

const iconSize = '14px';

const CardStatusIconStyled = styled.div`
  position: absolute;

  height: ${iconSize};
  width: ${iconSize};

  // Override margin rules for children of Card.
  ${CardStyled} & {
    margin: 0;
  }

  top: calc(-${iconSize} / 2);
  right: calc(-${iconSize} / 2);
`;

type Props = {
  children: React.ReactNode;
};

export const CardStatusIcon = ({ children }: Props) => (
  <CardStatusIconStyled>{children}</CardStatusIconStyled>
);

CardStatusIcon.displayName = 'CardStatusIcon';
