type HttpHeaders = Record<string, string>;

export const headersFromResponse = (response) => {
  const headers: HttpHeaders = {};

  response.headers.forEach((value, name) => {
    headers[name] = value;
  });

  return headers;
};
