import styled, { keyframes } from 'styled-components';
import { IconSpinner } from '@perts/ui';

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const Spinner = styled(IconSpinner)`
  display: inline-flex;
  animation: ${rotate} infinite 1s linear;
  margin-right: 5px;
`;
