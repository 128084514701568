import styled from 'styled-components/macro';

export const PageBreak = styled.div`
  @media print {
    // This is the standard rule.
    break-before: always;

    // In CM's testing, only this combination of deprecated rule and
    // "!important" causes the desired effect in Chrome.
    page-break-before: always !important;
  }
`;
