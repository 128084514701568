import { Suspense, useState } from 'react';
import { Tabs } from 'antd';
import { lazily } from 'react-lazily';

import { Loading } from '@perts/ui';

import { mutateToChildSurveyTable } from './mutateToChildSurveyTable';
import { TimelineChartRow } from '../types';
import { StudentExperienceChildSurveyTable } from '../';

// Pulls in ChartJS, and we'd like code splitting for that, so lazy-load.
const { ExperienceBySurveyChart } = lazily(
  () => import('./ExperienceBySurveyChart'),
);

type Props = {
  data: TimelineChartRow[];
  metricLabel: string;
};

export const NetworkTimeline = ({ data, metricLabel }: Props) => {
  const [tabKey, setTabKey] = useState('graph');
  const handleTabClick = (key: string) => setTabKey(key);
  const ratedPositiveByGroup = mutateToChildSurveyTable(data);

  return (
    <div>
      <Tabs activeKey={tabKey} centered onChange={handleTabClick} size="large">
        <Tabs.TabPane tab="Graph" key="graph">
          <Suspense fallback={<Loading />}>
            <ExperienceBySurveyChart
              metricLabel={metricLabel}
              ratedPositiveByGroup={ratedPositiveByGroup}
            />
          </Suspense>
        </Tabs.TabPane>
        <Tabs.TabPane tab="Table" key="table">
          <StudentExperienceChildSurveyTable data={data} />
        </Tabs.TabPane>
      </Tabs>
    </div>
  );
};
