import React from 'react';
import styled from 'styled-components/macro';
import Truncate from 'react-truncate';

import theme from '../theme';
import { IconClose } from '../Icon';
import { Link, Show } from '../..';

// Reference: https://material-ui.com/components/chips/

export type ChipProps = {
  // The content of the label.
  label: string;

  // If true, the chip should be displayed in a disabled state.
  disabled?: boolean;

  // Callback function fired when the delete icon is clicked.
  // If set, the delete icon will be shown.
  onDelete?: React.EventHandler<any>;

  // Callback function fired when the chip is clicked. If both onDelete and
  // onClick are both provided, use clicks outside of the delete icon will
  // trigger this callback.
  onClick?: React.EventHandler<any>;

  // If provided, renders a link inside the Chip with this href.
  to?: string;

  // If true, the chip should be displayed with ellipsis If its text is longer than the truncateWidth
  truncate?: boolean;

  // With to truncate text, when the trucate props is true
  truncateWidth?: number;
};

type ChipContainerProps = {
  theme: any;
};

const ChipContainer = styled.div<ChipContainerProps>`
  display: inline-flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 0 5px;
  border: 1px solid ${(props) => props.theme.colors.grayMedium};
  background-color: ${(props) => props.theme.colors.grayLight};
  border-radius: ${(props) => props.theme.units.borderRadius};
`;

const ChipLinkStyled = styled.div<ChipContainerProps>`
  a,
  a:hover {
    color: ${(props) => props.theme.colors.text};
    font-weight: normal;
    text-decoration: underline;
    font-size: ${(props) => props.theme.units.fontBase};
    margin: 0 ${(props) => props.theme.units.paddingSm};
  }
`;

ChipContainer.defaultProps = {
  theme,
};

const ChipLabelStyled = styled.label`
  font-size: 16px;
  margin: 0 ${(props) => props.theme.units.paddingSm};
  word-break: break-word;
  flex: 1;
`;

ChipLabelStyled.defaultProps = {
  theme,
};

const ChipDeleleteIcon = styled(IconClose)`
  cursor: pointer;
`;

const ChipTextHOC =
  (label: string, truncate?: boolean, truncateWidth?: number) => () =>
    (
      <>
        <Show when={truncate}>
          <Truncate lines={1} trimWhitespace width={truncateWidth}>
            {label}
          </Truncate>
        </Show>
        <Show when={!truncate}>{label}</Show>
      </>
    );

export const Chip = ({
  label,
  onClick: _onClick,
  onDelete,
  disabled,
  to,
  truncate,
  truncateWidth,
}: ChipProps) => {
  const handleOnDelete = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (!disabled && onDelete) {
      onDelete(e);
    }
  };

  const ChipText = ChipTextHOC(label, truncate, truncateWidth);

  return (
    <ChipContainer>
      <Show when={Boolean(to)}>
        <ChipLinkStyled theme={theme}>
          <Link to={to}>
            <ChipText />
          </Link>
        </ChipLinkStyled>
      </Show>
      <Show when={!to}>
        <ChipLabelStyled aria-label={label} theme={theme}>
          <ChipText />
        </ChipLabelStyled>
      </Show>
      <Show when={Boolean(onDelete)}>
        <ChipDeleleteIcon size={26} onClick={handleOnDelete} />
      </Show>
    </ChipContainer>
  );
};

Chip.defaultProps = {
  truncate: false,
  truncateWidth: 180,
};
