import styled, { css } from 'styled-components';

import { theme } from '@perts/ui';

type Props = {
  active?: boolean;
  disabled?: boolean;
};

// Page controls at the bottom of a react-table.
export const DataTablePageNavLink = styled.span<Props>`
  padding: 3px 5px;

  color: ${theme.colors.grayMedium};
  text-decoration: none;
  cursor: pointer;

  ${(props) =>
    props.active &&
    css`
      text-decoration: underline;
    `};

  ${(props) =>
    props.disabled &&
    css`
      color: ${theme.colors.grayMedium};
      cursor: default;
      pointer-events: none;
    `};
`;
