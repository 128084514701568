import { useParams } from 'react-router-dom';
import pluralize from 'pluralize';

import {
  Dropdown,
  IconUserCircle,
  IconUserDelete,
  IconChevronDown,
  IconPin,
  IconEnvelope,
  Show,
  Text,
} from '@perts/ui';
import {
  PagesParams,
  toGroupMembersManager,
  toGroupMembersRemove,
  toGroupMembersSendInvitations,
  toGroupMembersPin,
} from 'pages';
import { useTerms } from 'components/TermsContext';
import { useOpenModal } from 'utils';

export const MembersDropdownActions = ({
  totalSelected,
  checked,
  mayGroupMemberPromote,
  mayGroupMemberRemove,
  mayGroupMemberEditPin,
}) => {
  const { groupId } = useParams<PagesParams>();
  const openModal = useOpenModal();
  const terms = useTerms();

  const handleAction = (route: string) => {
    openModal(route, { checked });
  };

  // checked has structure { [uid]: boolean, ... }
  const anySelected = Object.values(checked).filter(Boolean).length > 0;

  return (
    <Dropdown
      menuButton={
        <>
          <span>
            Edit
            {` ${pluralize(
              terms.groupMember,
              totalSelected,
              Boolean(totalSelected),
            )}`}
          </span>

          <IconChevronDown />
        </>
      }
      color="primary"
      aria-label={`Members actions dropdown`}
      openDirection="left"
      fullWidth
    >
      <Show when={anySelected && mayGroupMemberPromote}>
        <Dropdown.Item
          onClick={() => handleAction(toGroupMembersManager(groupId))}
        >
          <IconUserCircle />
          <span>Edit {terms.groupManagers}</span>
        </Dropdown.Item>
      </Show>

      <Show when={anySelected}>
        <Dropdown.Item
          onClick={() => handleAction(toGroupMembersSendInvitations(groupId))}
        >
          <IconEnvelope />
          <span>Send Invitations</span>
        </Dropdown.Item>
      </Show>

      <Show when={anySelected && mayGroupMemberEditPin}>
        <Dropdown.Item onClick={() => handleAction(toGroupMembersPin(groupId))}>
          <IconPin />
          <span>Edit Pins</span>
        </Dropdown.Item>
      </Show>

      <Show when={anySelected && mayGroupMemberRemove}>
        <Dropdown.Item
          onClick={() => handleAction(toGroupMembersRemove(groupId))}
        >
          <IconUserDelete />
          <span>Remove {terms.groupMembers}</span>
        </Dropdown.Item>
      </Show>

      <Show when={!anySelected}>
        <Dropdown.Item disabled={true}>
          <Text as="p">
            <em>
              Select one or more {terms.groupMembers.toLowerCase()} to edit.
            </em>
          </Text>
        </Dropdown.Item>
      </Show>
    </Dropdown>
  );
};
