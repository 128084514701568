import {
  Participant,
  queryKeyParticipantsByClass,
  removeParticipants,
  useClassGetByParams,
  useParticipantsByParams,
  useMutationDelete,
} from 'models';
import { useParams } from 'pages';
import { useCloseModal, useGetCheckedStates } from 'utils';

import Loading from 'components/Loading';
import { ErrorMessageBox } from 'components/ErrorMessageBox';
import { getMessageFromErrors } from '@perts/util';
import { ParticipantsRemoveForm } from './ParticipantsRemoveForm';

export const ParticipantsRemove = () => {
  const { classId } = useParams();
  const checked = useGetCheckedStates();
  const queryKey = queryKeyParticipantsByClass(classId);

  // Close the modal.
  const closeModal = useCloseModal();

  // Query for Class.
  const classQuery = useClassGetByParams();

  // Query for Participants associated with Class.
  const participantsQuery = useParticipantsByParams();

  // Determine participants selected to remove.
  const participantsData = participantsQuery.data || [];
  const participantsSelected = participantsData.filter(
    (participant) => checked[participant.uid],
  );

  // Determine classroom id to remove.
  const clsData = classQuery.data;
  const classroomId = clsData?.classroom_id || '';

  const mutation = useMutationDelete<Participant>(
    () => removeParticipants(participantsSelected, classroomId),
    participantsSelected,
    queryKey,
    {
      waitForSuccess: true,
      toastSuccessMsg: `Successfully saved changes to roster.`,
      toastErrorMsg: `Unable to save changes to roster.`,
    },
  );

  // Display loading.
  const isLoading = classQuery.isLoading || participantsQuery.isLoading;

  if (isLoading) {
    return <Loading />;
  }

  // Display errors.
  if (!classQuery.isSuccess || !participantsQuery.isSuccess) {
    return (
      <ErrorMessageBox>
        {getMessageFromErrors([classQuery.error, participantsQuery.error])}
      </ErrorMessageBox>
    );
  }

  return (
    <ParticipantsRemoveForm
      close={closeModal}
      onSubmit={mutation.mutateAsync}
      participants={participantsSelected}
      cls={classQuery.data}
    />
  );
};
