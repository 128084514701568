import styled from 'styled-components/macro';

import { Link, TruncateText } from '@perts/ui';
import { theme } from 'components';

export const ClassCardGroupContainer = styled.div`
  display: inline-block;
  margin-left: 5px;

  > * {
    display: inline;
  }
`;

const GroupLabel = styled.label`
  font-size: ${theme.units.fontBase};
  word-break: break-word;
`;

const GroupLink = styled(Link)`
    color: ${theme.colors.text};
    font-weight: normal;
    text-decoration: underline;
    font-size: ${theme.units.fontBase};

    &:hover {
      font-weight: normal;
    }
  }
`;

const GroupName = ({ to, children }) => (
  <>
    {/* Using <Show> here would cause Link to throw a propType warning. */}
    {Boolean(to) ? (
      <GroupLink to={to}>{children}</GroupLink>
    ) : (
      <GroupLabel>{children}</GroupLabel>
    )}
  </>
);

export const ClassCardGroup = ({ group, truncateWidth = 0, to }) => (
  <GroupName to={to}>
    <TruncateText truncateWidth={truncateWidth}>{group.name}</TruncateText>
  </GroupName>
);
