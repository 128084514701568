import { useParams } from 'react-router-dom';
import pluralize from 'pluralize';

import {
  Dropdown,
  IconChevronDown,
  IconClipboardList,
  IconSettings,
  Show,
  Text,
} from '@perts/ui';
import { useTerms } from 'components';
import { useProgramId } from 'models';
import {
  PagesParams,
  toGroupSurveysEditAdvanced,
  toGroupSurveysEditMetrics,
} from 'pages';
import { useOpenModal } from 'utils';

export const SurveysDropdownActions = ({ checked, totalSelected }) => {
  const { groupId } = useParams<PagesParams>();
  const programId = useProgramId();
  const openModal = useOpenModal();
  const terms = useTerms();

  const anySelected = Object.values(checked).filter(Boolean).length > 0;

  const handleAction = (route: string) => {
    openModal(route, { checked });
  };

  return (
    <Dropdown
      menuButton={
        <>
          <span>
            Edit
            {` ${pluralize(
              terms.class,
              totalSelected,
              Boolean(totalSelected),
            )}`}
          </span>

          <IconChevronDown />
        </>
      }
      color="primary"
      aria-label={`Classes actions dropdown`}
      openDirection="left"
      fullWidth
    >
      <Show when={!anySelected}>
        <Dropdown.Item disabled={true}>
          <Text as="p">
            <em>Select one or more {terms.classes.toLowerCase()} to edit.</em>
          </Text>
        </Dropdown.Item>
      </Show>

      <Show when={anySelected}>
        <Dropdown.Item
          onClick={() => handleAction(toGroupSurveysEditMetrics(groupId))}
        >
          <IconClipboardList />
          <span>Edit Conditions</span>
        </Dropdown.Item>

        <Show when={programId.includes('elevate')}>
          <Dropdown.Item
            onClick={() => handleAction(toGroupSurveysEditAdvanced(groupId))}
          >
            <IconSettings />
            <span>Edit Advanced Settings</span>
          </Dropdown.Item>
        </Show>
      </Show>
    </Dropdown>
  );
};
