// Intentionally independent of the race options used in Saturn
// (in @perts/config)

export const optionsGender = [
  {
    label: 'Woman',
    value: 'woman',
  },
  {
    label: 'Man',
    value: 'man',
  },
  {
    label: 'Non-binary',
    value: 'nonbinary',
  },
  {
    label: 'Another group (please specify)',
    value: 'other',
  },
  {
    label: 'Prefer not to say',
    value: 'prefer_not_to_answer',
  },
];
