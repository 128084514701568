import { message } from 'antd';
import {
  channelReportsSetAllDisaggregation,
  channelReportsSetAllShowBy,
} from 'config';
import { DISAGGREGATE_BY_ALL, SHOW_BY_METRIC } from './constants';
import { useStateSync } from './useStateSync';

const SHOW_BY_DEFAULT = SHOW_BY_METRIC;
const MESSAGES_SELECTION_APPLIED = 'Selection applied.';

const useStateDisaggregateBy = (initialState) =>
  useStateSync(
    initialState ?? DISAGGREGATE_BY_ALL,
    channelReportsSetAllDisaggregation,
  );

const useStateShowBy = (initialState) =>
  useStateSync(initialState ?? SHOW_BY_DEFAULT, channelReportsSetAllShowBy);

export const useStateFilterDisaggregateBy = (
  /**
   * `true` if the "Disaggregate By" is a multi/advanced style filter.
   * Default `false`.
   */
  isMultiSelect = false,

  initialState: { disaggregateBy?: string; showBy?: string } = {},
) => {
  const { disaggregateBy: initialDisaggregateBy, showBy: initialShowBy } =
    initialState;

  const [disaggregateBy, setDisaggregateBy, applyToAllDisaggregateBy] =
    useStateDisaggregateBy(initialDisaggregateBy);

  const [showBy, setShowBy, applyToAllShowBy] = useStateShowBy(initialShowBy);

  // Used for "Disaggregate By" filters with only a simple/single select. The
  // "Apply to All" will cause multi/advanced filters to reset their "Show By"
  // state back to default.
  const applyToApplySingle = () => {
    applyToAllDisaggregateBy();
    applyToAllShowBy(SHOW_BY_DEFAULT);
    message.success(MESSAGES_SELECTION_APPLIED);
  };

  // Used for "Disaggregate By" filters with only a multi/advanced select. The
  // "Apply to All" will sync both the "Disaggregate By" and "Show By" state.
  const applyToAllMulti = () => {
    applyToAllDisaggregateBy();
    applyToAllShowBy();
    message.success(MESSAGES_SELECTION_APPLIED);
  };

  return {
    disaggregateBy,
    setDisaggregateBy,
    showBy,
    setShowBy,
    applyToAll: isMultiSelect ? applyToAllMulti : applyToApplySingle,
  };
};
