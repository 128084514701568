import styled from 'styled-components/macro';

import pertsLogoColor from '../../assets/images/pertsLogoColor.svg';
import pertsLogoWhite from '../../assets/images/pertsLogoWhite.svg';
import pertsTitleColor from '../../assets/images/pertsTitleColor.png';
import pertsTitleWhite from '../../assets/images/pertsTitleWhite.png';
import ascend21TitleWhite from '../../assets/images/ascend21TitleWhite.png';
import catalyze21TitleWhite from '../../assets/images/catalyze21TitleWhite.png';
import elevate21TitleWhite from '../../assets/images/elevate21TitleWhite.png';
import sesTeacher23TitleWhite from '../../assets/images/sesTeacher23TitleWhite.png';
import sesStudent23TitleWhite from '../../assets/images/sesStudent23TitleWhite.png';

export enum LogoVariant {
  white = 'white',
  color = 'color',
}

export type Program = {
  label: string;
  name: string;
};

export type LogoProps = {
  variant?: LogoVariant;
  program?: Program;
  title?: boolean;
};

const LogoStyled = styled.img`
  height: 42px;
  width: 42px;
`;

const ProgramTitle = styled.img`
  position: relative;
  top: 2px;

  padding-top: 10px;
  padding-left: 8px;
  height: 42px;
`;

const getProgramTitle = (programLabel: string) => {
  if (/^ascend\d{2}$/.test(programLabel)) {
    return ascend21TitleWhite;
  }
  if (/^catalyze\d{2}$/.test(programLabel)) {
    return catalyze21TitleWhite;
  }
  if (/^elevate\d{2}$/.test(programLabel)) {
    return elevate21TitleWhite;
  }
  if (/^sesTeacher\d{2}$/.test(programLabel)) {
    return sesTeacher23TitleWhite;
  }
  if (/^sesStudent\d{2}$/.test(programLabel)) {
    return sesStudent23TitleWhite;
  }
  return null;
};

export const Logo = ({
  variant = LogoVariant.white,
  program,
  title = true,
}: LogoProps) => {
  const programTitleSrc = program ? getProgramTitle(program.label) : '';

  let logoSrc;
  if (variant === LogoVariant.white) {
    logoSrc = pertsLogoWhite;
  } else if (variant === LogoVariant.color) {
    logoSrc = pertsLogoColor;
  }

  const titleAlt = program ? program.name : 'PERTS';

  let titleSrc;
  if (!title) {
    titleSrc = null;
  } else if (programTitleSrc && variant === LogoVariant.white) {
    titleSrc = programTitleSrc;
  } else if (programTitleSrc && variant === LogoVariant.color) {
    throw new Error('Color program titles not implemented.');
  } else if (!programTitleSrc && variant === LogoVariant.white) {
    titleSrc = pertsTitleWhite;
  } else if (!programTitleSrc && variant === LogoVariant.color) {
    titleSrc = pertsTitleColor;
  }
  return (
    <>
      <LogoStyled alt="PERTS" src={logoSrc} />
      {titleSrc && <ProgramTitle src={titleSrc} alt={titleAlt} />}
    </>
  );
};

export default Logo;
