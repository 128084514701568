import { Col, Row, SelectFilter, SelectFilterSelected } from '@perts/ui';
import { EquityGapIcon } from '../../components';
import { getOptionsSelectFilterDisaggregateBy } from '../SelectFilterMulti/getOptions';
import { ApplyToAllStyledButton } from '../ApplyToAllStyledButton';

type SelectFilterDisaggregateProps = {
  programLabel: string;
  attrLabelsWithGaps: string[];
  disaggregateBy: string;
  setDisaggregateBy: (selected: string) => void;
  applyToAll?: () => void;
  disabled?: boolean;
  icon?: JSX.Element;
};

export const SelectFilterDisaggregate = ({
  programLabel,
  attrLabelsWithGaps,
  disaggregateBy,
  setDisaggregateBy,
  applyToAll,
  disabled,
  icon,
}: SelectFilterDisaggregateProps) => {
  const options = getOptionsSelectFilterDisaggregateBy(
    programLabel,
    attrLabelsWithGaps,
    [],
    true,
  );

  const selected =
    // @ts-ignore
    options.find((option) => option.value === disaggregateBy) || options[0];

  const setSelected = (s: SelectFilterSelected) => {
    if (!s || Array.isArray(s)) {
      // SelectFilter supports types that aren't used here.
      return;
    }

    setDisaggregateBy(s.value);
  };

  return (
    <Row>
      <Col shrink>
        <SelectFilter
          label={
            <>
              Disaggregate By{' '}
              {attrLabelsWithGaps.length > 0 && <EquityGapIcon />}
            </>
          }
          options={options}
          selected={selected}
          setSelected={setSelected}
          disabled={disabled}
          icon={icon}
        />
      </Col>
      {applyToAll && (
        <Col>
          <ApplyToAllStyledButton noPaddingOnContainer onClick={applyToAll} />
        </Col>
      )}
    </Row>
  );
};
