import { pick } from 'lodash';
import { Class } from 'models';

import { PortalType, portalTypesWithEndings } from '@perts/model';

import { getPortalMessageFromClasses } from '../getPortalMessage';
import { getRulesFromClasses } from './getRulesFromClasses';
import { preferredPortalType } from './preferredPortalType';
import { ClassSettingsFormValues } from './types';

type FieldsToInclude = string[];

export function getInitialValuesSignOn<
  FormValuesType = ClassSettingsFormValues,
>(classes: Class[], fieldsToInclude: FieldsToInclude = []) {
  const portalType = preferredPortalType(classes);
  const [
    { portal_type, participant_pattern, roster_locked, target_group_name },
  ] = classes;

  const ruleEnabled = (function () {
    const allEqualPortalType = classes.every(
      (cls) => cls.portal_type === portal_type,
    );

    const someEqualPortalType = classes.some(
      (cls) => cls.portal_type === portal_type,
    );

    const allEqualParticipantPattern = classes.every(
      (cls) => cls.participant_pattern === participant_pattern,
    );

    const someEqualParticipantPattern = classes.some(
      (cls) => cls.participant_pattern === participant_pattern,
    );

    const allEqual = allEqualPortalType && allEqualParticipantPattern;
    const someEqual = someEqualPortalType && someEqualParticipantPattern;

    // Rule is enabled.
    if (allEqual && Boolean(participant_pattern)) {
      return true;
    }

    // Rule is mixed.
    if (!allEqual && someEqual) {
      return null;
    }

    // Rule is disabled.
    return false;
  })();

  const rosterLocked = (function () {
    const allEqualRosterLocked = classes.every(
      (cls) => cls.roster_locked === roster_locked,
    );

    const someEqualRosterLocked = classes.some(
      (cls) => cls.roster_locked === roster_locked,
    );

    if (allEqualRosterLocked && Boolean(roster_locked)) {
      return true;
    }

    if (!allEqualRosterLocked && someEqualRosterLocked) {
      return null;
    }

    return false;
  })();

  const { targetGroupEnabled, targetGroupName } = (function () {
    const allEqual = classes.every(
      (cls) => cls.target_group_name === target_group_name,
    );

    const someEqual = classes.some(
      (cls) => cls.target_group_name === target_group_name,
    );

    if (
      allEqual &&
      typeof target_group_name === 'string' &&
      target_group_name.length > 0
    ) {
      return { targetGroupEnabled: true, targetGroupName: target_group_name };
    }

    if (!allEqual && someEqual) {
      return { targetGroupEnabled: null, targetGroupName: '' };
    }

    return { targetGroupEnabled: false, targetGroupName: '' };
  })();

  const portalMessage = getPortalMessageFromClasses(classes);

  const { studentEmailEnding, studentIdMin, studentIdMax } =
    getRulesFromClasses(classes);

  const useEndingPattern = portalTypesWithEndings.includes(portalType);
  const usingStudentId = [PortalType.STUDENT_ID].includes(portalType);

  const initialValues: ClassSettingsFormValues = {
    ruleEnabled,

    portalType,
    portalMessage,

    ...(useEndingPattern ? { studentEmailEnding } : {}),

    ...(usingStudentId
      ? {
          studentIdMax,
          studentIdMin,
        }
      : {}),

    rosterLocked,

    targetGroupEnabled,
    targetGroupName,
  };

  if (fieldsToInclude.length > 0) {
    return pick<ClassSettingsFormValues>(
      initialValues,
      fieldsToInclude,
    ) as FormValuesType;
  }

  return initialValues;

  // TODO Receiving the following error and am not sure how to wrangle the
  // TypeScript to make it happy.

  // Conversion of type 'ClassSettingsFormValues' to type 'FormValuesType' may
  // be a mistake because neither type sufficiently overlaps with the other. If
  // this was intentional, convert the expression to 'unknown' first.
  // 'FormValuesType' could be instantiated with an arbitrary type which could
  // be unrelated to 'ClassSettingsFormValues'.  TS2352
}
