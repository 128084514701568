import { useContext } from 'react';
import { useParams } from 'react-router-dom';

import {
  Group,
  Network,
  groupsRemoveFromNetwork,
  queryKeyGroupsByNetwork,
  useGroupsQueryByNetworkId,
  useMutationDelete,
  useNetworkGetByParams,
} from 'models';
import { PagesParams } from 'pages';
import { useCloseModal, useGetCheckedStates } from 'utils';

import {
  NetworkGroupsRemoveForm,
  NetworkGroupsRemoveFormValues,
} from './NetworkGroupsRemoveForm';
import { ErrorMessageBox } from 'components/ErrorMessageBox';
import Loading from 'components/Loading';
import TermsContext from 'components/TermsContext';
import { createUid, getMessageFromErrors } from '@perts/util';

export const NetworkGroupsRemove = () => {
  const { networkId: shortNetworkId = '' } = useParams<PagesParams>();
  const networkId = createUid('Network', shortNetworkId);
  const queryKey = queryKeyGroupsByNetwork(networkId);
  const terms = useContext(TermsContext);

  const checked = useGetCheckedStates();

  // Close the modal.
  const closeModal = useCloseModal();

  // Query for Network.
  const networkQuery = useNetworkGetByParams();

  // Query for Users of Network.
  const groupsQuery = useGroupsQueryByNetworkId(networkId);

  const groups: Group[] = groupsQuery.data || [];

  // Determine groups that have been selected.
  const groupsToRemove = groups.filter((member) => checked[member.uid]);

  const groupsToRemoveIncludesAllGroups =
    groups.length === groupsToRemove.length;

  // Mutation: Remove groups from network.
  const mutation = useMutationDelete<Group>(
    () => groupsRemoveFromNetwork(groupsToRemove, networkQuery.data as Network),
    groupsToRemove,
    queryKey,
    {
      waitForSuccess: true,
      toastSuccessMsg:
        `Successfully removed ${terms.groups.toLowerCase()} ` +
        `from the network.`,
      toastErrorMsg:
        `There was a problem removing ` +
        `${terms.groups.toLowerCase()} from the network.`,
    },
  );

  // https://stackoverflow.com/questions/65760158/react-query-mutation-typescript
  // Formik onSubmit handler
  const onSubmit = async (values: NetworkGroupsRemoveFormValues) => {
    await mutation.mutateAsync(values);
  };

  // Display loading.
  const isLoading = networkQuery.isLoading || groupsQuery.isLoading;

  if (isLoading) {
    return <Loading />;
  }

  // Display errors.
  if (!networkQuery.isSuccess || !groupsQuery.isSuccess) {
    return (
      <ErrorMessageBox>
        {getMessageFromErrors([networkQuery.error, groupsQuery.error])}
      </ErrorMessageBox>
    );
  }

  return (
    <NetworkGroupsRemoveForm
      close={closeModal}
      onSubmit={onSubmit}
      network={networkQuery.data}
      groupsToRemove={groupsToRemove}
      groupsToRemoveIncludesAllGroups={groupsToRemoveIncludesAllGroups}
    />
  );
};
