import React, { useMemo } from 'react';

import { sortNumeric, sortTableRowNumeric } from 'utils';
import { ChangeText } from '../components';
import { Table } from './Table';
import { formatPercent } from '@perts/util';
import { useTerms } from 'components/TermsContext';

type ChildSurveyRow = {
  child_name: string;
  pct_rated_positive: (number | null)[]; // decimals
  rated_positive_change: string[]; // "integers"
  // null means change not applicable, as for survey 1
  rated_positive_change_num: (number | null)[]; // integers
  cumulative_change: string; // "integer"
  // null means change not applicable, as for survey 1
  cumulative_change_num: number | null; // integer
};

type CellProps = {
  row: { original: ChildSurveyRow };
};

// Cell rendering for cycle data.
const cellRatedPositive =
  (index) =>
  ({ row }: CellProps) =>
    formatPercent(row.original.pct_rated_positive[index]);

const cellChange =
  (index) =>
  ({ row }: CellProps) =>
    (
      <ChangeText data={row.original.rated_positive_change_num[index]}>
        {row.original.rated_positive_change[index]}
      </ChangeText>
    );

// Cell rendering for cumulative change.
const CellChildCumulativeChange = ({ row }: CellProps) => (
  <ChangeText data={row.original.cumulative_change_num}>
    {row.original.cumulative_change}
  </ChangeText>
);

type Props = {
  data: ChildSurveyRow[];
};

export const StudentExperienceChildSurveyTable = ({ data }: Props) => {
  const terms = useTerms();

  const cycleLengths = data.map((row) => row.rated_positive_change.length);
  const numCycles = Math.max(0, ...cycleLengths);

  const columns = useMemo(() => {
    const cols = [
      {
        Header: terms.group,
        id: 'child_name',
        accessor: 'child_name',
      },
      ...Array.from({ length: numCycles }).map((_, i) => ({
        Header: `Survey ${i + 1}`, // index -> ordinal
        id: `survey_${i}`,
        align: 'center',
        columns: [
          {
            Header: '%',
            id: `pct_rated_positive_${i}`,
            // Accessors allow column sorting. Should provide display values so
            // users understand the sort results.
            accessor: (row: ChildSurveyRow) =>
              formatPercent(row.pct_rated_positive[i]),
            Cell: cellRatedPositive(i),
          },
          {
            Header: '±',
            id: `rated_positive_change_${i}`,
            accessor: (row: ChildSurveyRow) => row.rated_positive_change_num[i],
            Cell: cellChange(i),
            sortType: (
              rowA: { original: ChildSurveyRow },
              rowB: { original: ChildSurveyRow },
            ) => {
              const a = rowA.original.rated_positive_change_num[i];
              const b = rowB.original.rated_positive_change_num[i];
              return sortNumeric(a, b);
            },
          },
        ],
      })),
      {
        Header: '± Since Survey 1',
        id: 'cumulative_change',
        // Accessors allow column sorting. See `sortType` below.
        accessor: (row: ChildSurveyRow) => row.cumulative_change_num,
        Cell: CellChildCumulativeChange,
        sortType: sortTableRowNumeric('cumulative_change_num'),
        align: 'center',
      },
    ];

    return cols;
  }, [numCycles, terms.group]);

  // <Table columns={columns} data={childSurveyData} numCycles={numCycles} />
  return <Table columns={columns} data={data} numCycles={numCycles} />;
};
