import { Redirect, useLocation } from 'react-router-dom';

import {
  dataIsEmpty,
  Program,
  useCurrentUserQuery,
  useProgramsQuery,
  useProgramsQueryByUser,
} from 'models';
import { toArchiveProgram, toHome } from 'pages';
import { signupLink } from 'config';

import Loading from 'components/Loading';
import { getMessageFromErrors } from '@perts/util';
import { ErrorMessageBox } from 'components/ErrorMessageBox';
import { Link, InfoBox } from '@perts/ui';
import { orderBy } from 'lodash';

// Attempt to match a yearless program label, e.g. "ascend", with a program. For
// instance, in 2024 we have "ascend20" through "ascend23". The param "ascend"
// should match "ascend23" because it's the newest.
const matchProgramByPattern = (programs: Program[], param: string) => {
  // <Security Note>

  // Including user-supplied data in the RegExp constructor, if intentionally
  // mis-used, could lead to a regular expression that does
  // [catastrophic backtracking][1]. However, this will only lock up the user's
  // browser. A true security vulnerability would involve passing this
  // constructed RegExp to other users or to the server.

  // Nevertheless, we'll limit the param to certain allowed characters, just
  // to be sure.

  const safeParamPattern = /^[A-Za-z0-9-]*$/;
  if (!safeParamPattern.test(param)) {
    // eslint-disable-next-line no-console
    console.warn('Invalid `program` param, ignoring.');
    return undefined;
  }

  // [1]: https://www.regular-expressions.info/catastrophic.html

  const labelPattern = new RegExp(`^${param}\\d{2}$`);

  // </Security Note>

  const matchingPrograms = programs.filter((p) => labelPattern.test(p.label));

  if (matchingPrograms.length > 0) {
    // Assumes that similar programs order from oldest to newest
    // (e.g. ['elevate21', 'elevate22']) so we can take the last as the newest.
    return orderBy(matchingPrograms, ['label'], ['desc'])[0];
  }

  return undefined;
};

// eslint-disable-next-line complexity
export const HomeNoProgram = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const requestedProgramLabel = searchParams.get('program');

  // Query for authenticated user.
  const {
    isLoading: userIsLoading,
    isError: userIsError,
    data: user,
    error: userError,
  } = useCurrentUserQuery();

  // Query for the _user's_ programs.
  const {
    isLoading: userProgramsIsLoading,
    isError: userProgramsIsError,
    data: userPrograms = [],
    error: userProgramsError,
  } = useProgramsQueryByUser();

  // Query for all programs. Often when users first set up their account, they
  // have a program in the URL that they aren't associated with yet.
  const {
    isLoading: programsIsLoading,
    isError: programsIsError,
    data: programs = [],
    error: programsError,
  } = useProgramsQuery();

  const isLoading = userIsLoading || userProgramsIsLoading || programsIsLoading;

  if (isLoading) {
    return <Loading />;
  }

  const isError = userIsError || userProgramsIsError || programsIsError;

  // Display any errors.
  if (isError) {
    return (
      <ErrorMessageBox>
        {getMessageFromErrors([userError, userProgramsError, programsError])}
      </ErrorMessageBox>
    );
  }

  // If there is a user and there are programs available
  if (user && !dataIsEmpty(programs)) {
    // Look for a requested program among all that exist because user might not
    // be associated with it yet.
    const requestedProgramExact = programs.find(
      (p) => p.label === requestedProgramLabel,
    );

    const requestedProgramMatch = matchProgramByPattern(
      programs,
      requestedProgramLabel || '',
    );

    // Look for a recent program among all that exist. If they've viewed it
    // before they may just have deleted their last community, but want to make
    // a new one.
    const recentProgram = programs.find(
      (p) => p.uid === user.recent_program_id,
    );

    // If neither of those work, default to the first program _associated with_
    // the user, of which there may be none. Select from the user's programs
    // here is important so we don't accidentally send someone to a home page
    // for a program they never signed up for.

    // Sort active programs first so that we direct a user to an active
    // program before directing them to an inactive program.
    const [defaultProgram] = orderBy(
      userPrograms,
      ['active', 'name'],
      ['desc', 'asc'],
    );

    // Check which program the user might want to see.
    // Note: this defines priority for redirection
    const destinationProgram =
      requestedProgramExact ||
      requestedProgramMatch ||
      recentProgram ||
      defaultProgram;

    // If program is found then redirect.
    if (destinationProgram) {
      // Once programs are inactive/archived, we don't view them on the normal
      // home page anymore. Send the user to the archive instead.
      const routeFn = destinationProgram.active ? toHome : toArchiveProgram;
      return <Redirect to={routeFn(destinationProgram.label)} />;
    }

    // If none of the methods above can establish which program the user should
    // view, then likely they just haven't used Copilot before AND they haven't
    // used one of our sign-up links from perts.net. Direct them to our home
    // page to browse programs.
  }

  const NoProgramWarning = () => (
    <InfoBox>
      You haven’t signed up for a program yet. Please register at
      <Link to={signupLink}>perts.net/resources</Link>
    </InfoBox>
  );

  return <NoProgramWarning />;
};
