import {
  Col,
  FixedActionbarContainer,
  IconNotVisible,
  Row,
  Show,
} from '@perts/ui';

import { Class, Group } from 'models';
import {
  toGroupReport as toGroupReportDefault,
  toClassReport as toClassReportDefault,
} from 'pages';

import { ReportCard } from './ReportCard';
import { ReportsGuideBox } from './ReportsGuideBox';
import { ArchivedWarning } from 'components/ArchivedWarning';
import { useTerms } from 'components/TermsContext';
import { usePermissions } from 'utils';
import useFilterField from 'utils/useFilterField';
import { useClientPagination } from 'utils/useClientPagination';

type ReportsRenderProps = {
  group: Group;
  classes: Class[];
  toGroupReport?: (groupId?: string) => string;
  toClassReport?: (groupId?: string, classId?: string) => string;
};

export const ReportsRender = ({
  group,
  classes,
  toGroupReport = toGroupReportDefault,
  toClassReport = toClassReportDefault,
}: ReportsRenderProps) => {
  const terms = useTerms();
  const { isAdmin } = usePermissions();

  const { filteredItems, FilterField } = useFilterField(classes, ['name'], {
    label: `Filter by ${terms.class.toLowerCase()} name`,
  });

  const reportHidden = Boolean(group.report_settings?.report_hidden);
  const showGroupReport = !reportHidden || isAdmin;

  const { currentPageItems, Pagination } = useClientPagination({
    data: filteredItems,
  });

  return (
    <>
      <FixedActionbarContainer>
        <Show when={group.archived}>
          <ArchivedWarning isReport={true} />
        </Show>
        <Row>
          <Col>{FilterField}</Col>
        </Row>

        <Row>
          <Col vAlign="center" hAlign="flex-end" hAlignSm="center" cols={12}>
            <Pagination />
          </Col>
        </Row>
      </FixedActionbarContainer>

      <div data-testid="container-report-cards">
        <Show when={showGroupReport}>
          <ReportCard
            data-testid="group-report-card"
            groupId={group.short_uid}
            key={group.uid}
            statusIcon={reportHidden ? <IconNotVisible /> : null}
            title={group.name}
            last_updated_results={group.last_updated_results}
            to={toGroupReport(group.uid)}
          />
        </Show>
        {currentPageItems.map((cls) => (
          <ReportCard
            data-testid="group-report-card"
            groupId={group.short_uid}
            key={cls.uid}
            title={cls.name}
            cls={cls}
            last_updated_results={cls.last_updated_results}
            to={toClassReport(group.uid, cls.uid)}
          />
        ))}
      </div>

      <ReportsGuideBox />
    </>
  );
};
