import { Formik, Form } from 'formik';
import { User } from 'models';
import pluralize from 'pluralize';
import {
  Button,
  Col,
  Row,
  Modal,
  Show,
  InfoBox,
  useFormError,
} from '@perts/ui';
import { ListNetworkMembersToRemove } from './ListNetworkMembersToRemove';
import { useTerms } from 'components/TermsContext';

export type NetworkMembersRemoveFormValues = {};

export type NetworkMembersRemoveFormProps = {
  close: (params?: any) => void;
  onSubmit: (values: NetworkMembersRemoveFormValues) => void;
  membersToRemove: User[];
  membersToRemoveIncludesAllMembers: boolean;
  membersToRemoveIncludesSelf: boolean;
};

export const NetworkMembersRemoveForm = ({
  close,
  onSubmit,
  membersToRemove,
  membersToRemoveIncludesAllMembers,
  membersToRemoveIncludesSelf,
}: NetworkMembersRemoveFormProps) => {
  const [FormError, showFormError] = useFormError();
  const terms = useTerms();

  const validate = ({ members }) => ({
    ...(members.length === 0 ? { members: 'invalid' } : {}),
  });

  return (
    <Modal close={close}>
      <Modal.Title className="center">{`Remove Network ${terms.networkMembers}`}</Modal.Title>

      <Formik
        enableReinitialize={true}
        initialValues={{
          members: membersToRemove,
        }}
        validate={validate}
        validateOnMount={true}
        onSubmit={async (values) => {
          try {
            // Clear existing form error.
            showFormError(false);

            // Perform form onSubmit.
            await onSubmit(values);

            // Close modal on successful form onSubmit.
            close({ redirectToHome: membersToRemoveIncludesSelf });
          } catch (error) {
            // Display form error.
            showFormError(true);
          }
        }}
      >
        {({ isSubmitting, isValid }) => (
          <Form>
            <Show when={membersToRemoveIncludesAllMembers}>
              <Row>
                <Col>
                  <InfoBox color="warning">
                    This action would remove all{' '}
                    {terms.networkMembers.toLowerCase()} from the network.
                  </InfoBox>
                </Col>
              </Row>
            </Show>

            <Show when={!membersToRemoveIncludesAllMembers}>
              <ListNetworkMembersToRemove
                members={membersToRemove}
                includesSelf={membersToRemoveIncludesSelf}
              />
            </Show>

            <Row>
              <Col>
                <FormError />
              </Col>
            </Row>

            <Row>
              <Col cols={6} colsSm={12}>
                <Button
                  type="button"
                  color="secondary"
                  fullWidth
                  onClick={close}
                  disabled={isSubmitting}
                >
                  Cancel
                </Button>
              </Col>

              <Col cols={6} colsSm={12} hAlign="flex-end">
                <Show when={!membersToRemoveIncludesAllMembers}>
                  <Button
                    type="submit"
                    fullWidth
                    disabled={!isValid || isSubmitting}
                    loading={isSubmitting}
                  >
                    Remove{' '}
                    {pluralize(
                      terms.networkMember,
                      membersToRemove.length,
                      true,
                    )}
                  </Button>
                </Show>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
