import * as Yup from 'yup';
import { Modal, Row, Col } from '@perts/ui';

export const initialValues = {
  agreed: true,
};

export const validationSchema = Yup.object().shape({});

export const Component = () => (
  <Modal>
    <Modal.Title className="center">Terms of Use</Modal.Title>
    <Row>
      <Col>Agree to the agreement.</Col>
    </Row>
  </Modal>
);
