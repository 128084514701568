import { useMutation, useQueryClient } from 'react-query';
import { message } from 'antd';
import { uniqBy } from 'lodash';
import pluralize from 'pluralize';

import { Modal } from '@perts/ui';
import { getMessageFromErrors } from '@perts/util';
import { ErrorMessageBox, Loading, useTerms } from 'components';
import {
  Class,
  queryKeyClassesByGroup,
  selectUserIsAdmin,
  updateGroupClasses,
  useClassesQueryByParams,
  useCurrentUserQuery,
  useGroupGetByParams,
} from 'models';
import { useParams } from 'pages';
import {
  RosterSignOnRuleForm,
  RosterSignOnRuleFormValues,
  getDefaultValuesSignOn,
  getInitialValuesSignOn,
  getRosterSignOnRuleSettings,
} from 'pages/Classes/shared';
import { useCloseModal, useGetCheckedStates } from 'utils';

export const ClassesEditRosterSignOnRule = () => {
  const checked = useGetCheckedStates();
  const terms = useTerms();
  const queryClient = useQueryClient();
  const { groupId = '' } = useParams();
  const queryKeyClasses = queryKeyClassesByGroup(groupId);
  const closeModal = useCloseModal();

  const userQuery = useCurrentUserQuery();
  const groupQuery = useGroupGetByParams();
  const classesQuery = useClassesQueryByParams();

  // Mutation: Edit target group name from classes.
  const mutation = useMutation(updateGroupClasses, {
    onMutate: (classesToUpdate) => {
      // Snapshot the previous classes value.
      const previous = queryClient.getQueryData<Class[]>(queryKeyClasses);

      // Optimistically update.
      const optimistic = previous
        ? uniqBy([...classesToUpdate, ...previous], 'uid')
        : classesToUpdate;

      queryClient.setQueryData(queryKeyClasses, optimistic);

      // Return a context object with the snapshot value.
      return { previous };
    },
    onSuccess: () => {
      message.success(
        `Successfully edited ${terms.participant.toLowerCase()} sign-on rule for ` +
          `${terms.classes.toLowerCase()}.`,
      );
    },
    onError: (_err, _data, context: any) => {
      if (context?.previous) {
        queryClient.setQueryData(queryKeyClasses, context.previous);
      }
    },
  });

  // Display loading.
  if (
    userQuery.isLoading ||
    groupQuery.isLoading ||
    classesQuery.isLoading ||
    !checked
  ) {
    return <Loading />;
  }

  // Display errors.
  if (
    !userQuery.isSuccess ||
    !groupQuery.isSuccess ||
    !classesQuery.isSuccess
  ) {
    return (
      <ErrorMessageBox>
        {getMessageFromErrors([
          userQuery.error,
          groupQuery.error,
          classesQuery.error,
        ])}
      </ErrorMessageBox>
    );
  }

  const userIsAdmin = selectUserIsAdmin(userQuery.data);

  // Determine classes that have been selected.
  const checkedClasses: Class[] = classesQuery.data.filter(
    (cls) => checked[cls.uid],
  );

  const initialValues = getInitialValuesSignOn(checkedClasses);
  const defaultValues = getDefaultValuesSignOn(groupQuery.data);

  const onSubmit = async (values: RosterSignOnRuleFormValues) => {
    // Form validation should guarantee that this is always set, but the
    // types allow null for initial form values in case classes have mixed
    // settings. Note that this field is NOT nullable in the db, so we won't
    // corrupt any data. Check for the case, then tell TS to chill;
    if (values.portalType === null) {
      throw new Error('Form validation failed, portalType is null.');
    }

    const rosterSignOnProps = getRosterSignOnRuleSettings({ values });

    const classesToUpdate = checkedClasses.map((cls) => ({
      ...cls,
      ...rosterSignOnProps,
    }));

    await mutation.mutateAsync(classesToUpdate);
  };

  return (
    <Modal close={closeModal}>
      <RosterSignOnRuleForm
        close={closeModal}
        submitButtonText={`Save Changes to ${pluralize(
          terms.class,
          checkedClasses.length,
          true,
        )}`}
        onSubmit={onSubmit}
        userIsAdmin={userIsAdmin}
        initialValues={initialValues}
        defaultValues={defaultValues}
      />
    </Modal>
  );
};
