import { sample } from 'lodash';

const letters = 'abcdefghijklmnopqrstuvwxyz';

const digits = '0123456789';

const hexDigits = digits + 'ABCDEF';

const alphaLower = letters.split('');

const alphaUpper = letters.toUpperCase().split('');

const alpha = alphaLower.concat(alphaUpper);

const numeric = digits.split('');

const alphaNumeric = alpha.concat(numeric);

const hex = hexDigits.split('');

export const randomAlphaNumeric = (length: number) =>
  Array.from({ length })
    .map(() => sample(alphaNumeric))
    .join('');

export const randomAlpha = (length: number) =>
  Array.from({ length })
    .map(() => sample(alpha))
    .join('');

export const randomNumeric = (length: number) =>
  Array.from({ length })
    .map(() => sample(numeric))
    .join('');

export const randomHexadecimal = (length: number) =>
  Array.from({ length })
    .map(() => sample(hex))
    .join('');

export const randomNumber = (arg1: number, arg2?: number) => {
  let min: number;
  let max: number;
  let range: number;

  if (arg2 === undefined) {
    min = 0;
    max = arg1;
    range = arg1;
  } else {
    min = arg1;
    max = arg2;
    range = max - min;
  }

  return Math.floor(Math.random() * range) + min;
};
