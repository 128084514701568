import { get } from 'lodash';
import { useMemo } from 'react';
import {
  getAttributeValueName,
  getAttributeValueShortName,
} from '@perts/config';
import { Results } from '@perts/model';
import { Card, Col, HelpText, Row, Show, Text } from '@perts/ui';
import { helpArticles } from 'config';
import { useTerms } from 'components/TermsContext';
import { Metric } from 'models';
import {
  getCumulativeChange,
  getIsEquityGap,
  getRecentCycleValue,
} from 'utils';

import { DISAGGREGATE_BY_ALL } from './constants';
import type { DemographicRow } from './types';
import { getAttrLabelsWithGaps } from './getAttrLabelsWithGaps';
import { useStateFilterDisaggregateBy } from './useStateFilterDisaggregateBy';
import { DisaggregationTable } from './DisaggregationTable';
import { SelectFilterDisaggregate } from './SelectFilterDisaggregate';

type Props = {
  experienceData: Results['experience'];
  metrics: Metric[];
  programLabel: string;
  sampleData: Results['sample'];
};

export const ExperienceOverview = ({
  experienceData,
  metrics,
  programLabel,
  sampleData,
}: Props) => {
  const terms = useTerms();
  const {
    disaggregateBy: selectedAttribute,
    setDisaggregateBy: setSelectedAttribute,
    applyToAll,
  } = useStateFilterDisaggregateBy();

  const data: DemographicRow[] = metrics.map((metric) => {
    const compositeData = experienceData[metric.label]?.composite || {};
    const cycleValuesAll = compositeData?.all_participants || [];
    const recentAll = getRecentCycleValue(cycleValuesAll);

    return {
      cumulativeChange: getCumulativeChange(cycleValuesAll),
      isComposite: true, // will cause title of metric to be bold
      label: metric.label,
      link: `#${metric.label}-data`,
      name: metric.name,
      ratedPositively: recentAll,
      sampleSize:
        (sampleData.by_metric[metric.label] || {}).composite.all_participants ||
        0,
      sampleSizeTotal: sampleData.all_responses.all_participants,

      byAttribute: Object.entries(compositeData).map(
        ([attributePath, cycleValues]) => {
          const [attributeLabel, attributeValue] = attributePath.split('.');
          const recent = getRecentCycleValue(cycleValues);

          return {
            attributeLabel,
            attributePath,
            attributeValue,
            attributeValueName: getAttributeValueName(attributePath),
            attributeValueShortName: getAttributeValueShortName(attributePath),
            cumulativeChange: getCumulativeChange(cycleValues),
            isEquityGap: getIsEquityGap(recentAll, recent),
            ratedPositively: recent,
            sampleSize: get(
              sampleData,
              ['by_metric', metric.label, 'composite', attributePath],
              0,
            ),
          };
        },
      ),
    };
  });

  // Apply current filter state. Note that the "All" subgroup should
  // always be visible.
  const dataFiltered = useMemo(
    () =>
      data.map((row) => ({
        ...row,
        byAttribute: row.byAttribute.filter(({ attributeLabel }) =>
          [DISAGGREGATE_BY_ALL, selectedAttribute].includes(attributeLabel),
        ),
      })),
    [data, selectedAttribute],
  );

  const attrLabelsWithGaps = getAttrLabelsWithGaps(data);

  return (
    <Card>
      <Card.Title>
        <Row>
          <Col cols={6}>
            <Text as="h2" includeMargin={false}>
              {terms.participant} Experience Overview
            </Text>
          </Col>
          <Col cols={6} vAlign="center" hAlign="flex-end">
            <HelpText articleId={helpArticles.studentExperienceOverviewTable}>
              Tips for using this
            </HelpText>
          </Col>
        </Row>
      </Card.Title>
      <Card.Content>
        <Show when={metrics.length === 0}>
          <Row>
            <Col>
              <Text as="p">
                <em>Not enough data collected yet.</em>
              </Text>
            </Col>
          </Row>
        </Show>
        <Show when={metrics.length > 0}>
          <SelectFilterDisaggregate
            programLabel={programLabel}
            attrLabelsWithGaps={attrLabelsWithGaps}
            disaggregateBy={selectedAttribute}
            setDisaggregateBy={setSelectedAttribute}
            applyToAll={applyToAll}
          />

          <DisaggregationTable
            data={dataFiltered}
            primaryHeader={terms.metric}
          />
        </Show>
      </Card.Content>
    </Card>
  );
};
