import styled from 'styled-components/macro';

import { IconSort, IconSortDown, IconSortUp, theme } from '@perts/ui';

import { ScheduleSort, SetSort } from '../../';
import { ScheduleTableRow } from '../ScheduleTableRow';
import { ScheduleTableCol } from '../ScheduleTableCol';

type ScheduleTableHeaderProps = {
  // Max cycles from all classes.
  currentMaxCycles: number;

  // Rendered component related to input
  FilterField?: any;

  // Rendered component related to input
  height?: number;

  // Change how the table is sorted. Undefined if the table isn't sortable.
  setSort?: SetSort;

  // Defines how the table is currently sorted. Undefined if the table isn't
  // sortable. Null if the table is sortable but no sort is active.
  sort?: ScheduleSort;
};

const InactiveSort = styled(IconSort)`
  color: ${theme.colors.textColorLightGrayOnWhite};
`;

type SortIndicatorProps = { sort?: ScheduleSort; ordinal: number };
const SortIndicator = ({ sort, ordinal }: SortIndicatorProps) => {
  if (sort === undefined) {
    // This table isn't sortable. Display nothing;
    return null;
  }
  if (sort === null || sort.ordinal !== ordinal) {
    // No columns are sorted, or some other column is.
    return <InactiveSort />;
  }
  return sort.ascending ? <IconSortUp /> : <IconSortDown />;
};

type UpdateSort = (
  ordinal: number,
  sort?: ScheduleSort,
  setSort?: SetSort,
) => (event: any) => void;
const updateSort: UpdateSort = (ordinal, sort, setSort) => (_event) => {
  if (sort === undefined || setSort === undefined) {
    // This table doesn't support sorting. Do nothing.
    return;
  }

  if (sort === null || sort.ordinal !== ordinal) {
    // This column currently isn't sorted. Switch to ascending.
    setSort({ ordinal, ascending: true });
  } else if (sort.ascending) {
    // Currently ascending; switch to descending.
    setSort({ ordinal, ascending: false });
  } else {
    // Currently descending; switch to not sorted.
    setSort(null);
  }
};

const HeaderLabel = styled.span`
  font-weight: bold;
  cursor: pointer;
`;

export const ScheduleTableHeader = ({
  currentMaxCycles,
  FilterField,
  height,
  setSort,
  sort,
}: ScheduleTableHeaderProps) => (
  <ScheduleTableRow height={height}>
    {/* Row's first column */}
    <ScheduleTableCol>{FilterField}</ScheduleTableCol>

    {/* Columns for each cycle that exists anywhere in the group  */}
    {Array.from({ length: currentMaxCycles }, (_, i) => (
      <ScheduleTableCol key={i} justifyContent="center">
        <HeaderLabel onClick={updateSort(i + 1, sort, setSort)}>
          Survey {i + 1} <SortIndicator sort={sort} ordinal={i + 1} />
        </HeaderLabel>
      </ScheduleTableCol>
    ))}

    {/* Extra column for the add cycle button */}
    <ScheduleTableCol justifyContent="center">
      <HeaderLabel />
    </ScheduleTableCol>
  </ScheduleTableRow>
);
