import { Redirect } from 'react-router-dom';

import { AppWrapper, Col, Row } from '@perts/ui';

import { Loading } from 'components';
import { canvasLoginLink } from 'config';
import { toLogin } from 'routes';
import { getAuthUser } from 'services/triton/helpers/getAuthUser';
import { getJwtToken } from 'services/triton/helpers/getJwtToken';

export const CanvasRedirect = () => {
  const isLoggedIn = Boolean(getAuthUser()?.uid);

  const token = getJwtToken();

  if (!isLoggedIn) {
    return <Redirect to={toLogin()} push={false} />;
  }

  window.location.replace(`${canvasLoginLink}?perts_token=${token}`);

  return (
    <AppWrapper
      breadcrumbsNavigation={null}
      menu={null}
      menuTop={null}
      toHome="/home"
    >
      <Row>
        <Col hAlign="center">
          <Loading />
        </Col>
      </Row>
    </AppWrapper>
  );
};
