// https://docs.google.com/spreadsheets/d/1ZX4hcUol-_frFLfUYRnZ-UPEvpGsVOsWsJRw1g5tDBM

import keyBy from 'lodash/keyBy';

import { locales } from '../i18n';
import { CompositeMetricConfig, MetricItemConfig, QuestionType } from './types';
import { catalyze24CompletelyTrueOptions } from './answerOptions/catalyze24CompletelyTrueOptions';

export const plc24AdequateResourcesItemConfig: MetricItemConfig[] = [
  {
    label: 'plc_access_resources',
    likertN: 4,
    minGood: 3,
    options: catalyze24CompletelyTrueOptions,
    prompts: {
      [locales.ENGLISH]:
        'I have access to the resources (time, information, and materials) I ' +
        'need to continue to grow as a teacher.',
    },
  },
  {
    label: 'plc_needed_resources',
    prompts: {
      [locales.ENGLISH]:
        'If needed, what resources do you need to continue to grow as a ' +
        'teacher?',
    },
    questionType: QuestionType.OPEN_RESPONSE,
  },
];

export const plc24AdequateResourcesConfig: CompositeMetricConfig = {
  label: 'plc-adequate-resources',
  items: plc24AdequateResourcesItemConfig,
  itemIndex: keyBy(plc24AdequateResourcesItemConfig, 'label'),
  name: 'Adequate Resources',
  description:
    'Adequate resources are critical to provide the necessary tools, time, ' +
    'and support for educators to effectively collaborate, implement ' +
    'innovative strategies, and engage in continuous professional ' +
    'development that improves teaching and learning.',
};
