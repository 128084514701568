import pluralize from 'pluralize';
import styled from 'styled-components/macro';

import { Class, Program, useCyclesCreateByClasses } from 'models';
import { useParams, toGroupSchedulesDefaultSettings } from 'pages';
import {
  Actionbar,
  Button,
  Col,
  IconAdd,
  IconChevronRight,
  IconWarning,
  Row,
  Show,
  Display,
} from '@perts/ui';

import { BulkSelectMessage } from 'components/BulkSelectMessage';
import { useTerms } from 'components/TermsContext';
import { ControlToggleType } from 'utils/useToggles';
import { useQueryParams } from 'utils';
import { getNextConflict, getNextUnscheduledCycle } from './shared';

const ActionToggleContainer = styled.div`
  display: flex;
  & > :not(:first-child) {
    margin-left: 10px;
  }
`;

export type SchedulesActionbarProps = {
  program: Program;
  classes: Class[];
  mayAddCycles: boolean;
  mayUpdateCycles: boolean;
  someMarked: boolean;
  dirty: boolean;
  isValid: boolean;
  isSubmitting: boolean;
  numberOfPendingChanges: number;
  handleReset: (e?: React.SyntheticEvent<any>) => void;
  selected: Class[];
  Pagination: any;
  ControlToggle: ControlToggleType;
  toggleAll: any;
  toggleAllFilter: any;
  errors: any;
  setCurrentPage: (page, elementId?) => void;
  clearFilter: () => void;
  mayEditDefaultSettings: boolean;
  filteredItems: Class[];
  formValues: any;
};

// eslint-disable-next-line complexity
export const SchedulesActionbar = ({
  program,
  classes,
  mayAddCycles,
  mayUpdateCycles,
  someMarked,
  dirty,
  selected,
  isValid,
  isSubmitting,
  numberOfPendingChanges,
  handleReset,
  Pagination,
  toggleAll,
  toggleAllFilter,
  errors,
  setCurrentPage,
  ControlToggle,
  clearFilter,
  mayEditDefaultSettings,
  filteredItems,
  formValues,
}: SchedulesActionbarProps) => {
  const { groupId } = useParams();
  const { get } = useQueryParams();
  const terms = useTerms();
  const cyclesCreateForClassesMutation = useCyclesCreateByClasses();
  const allClassesHaveMaxCycles = selected.every(
    ({ cycles = [] }) => cycles.length >= program.max_cycles,
  );

  // The current elementId highlighted.
  const paramElementId = get('elementId') || '';

  // Retrieve the { uid, page } of the next unscheduled cycle. Use the current
  // form values to determine which cycles are unscheduled so that the button
  // reacts to the current form state and we only highlight the cycles that
  // still need attention.
  const nextUnscheduledCycle = getNextUnscheduledCycle(
    paramElementId,
    classes,
    formValues,
  );

  // Only render the Find Unscheduled button when there are unscheduled cycles.
  const showNextUnscheduledButton = Boolean(nextUnscheduledCycle.uid);

  const goToNextConflict = () => {
    clearFilter();
    const conflict = getNextConflict(classes, errors);
    if (conflict) {
      setCurrentPage(conflict.page, conflict.classId);
    }
  };

  const goToNextUnscheduledCycle = () => {
    if (nextUnscheduledCycle.uid) {
      setCurrentPage(nextUnscheduledCycle.page, nextUnscheduledCycle.uid);

      // Horizontal scroll to bring cycle input field into view.
      const tableEl = document.getElementById('ScheduleTable');
      const inputWidth = 180;
      const left = (nextUnscheduledCycle.ordinal - 1) * inputWidth;

      tableEl?.scrollTo({
        behavior: 'smooth',
        left,
      });
    }
  };

  const onClickAddCycles = () => {
    cyclesCreateForClassesMutation.mutateAsync({
      classes: selected.filter(
        ({ cycles = [] }) => cycles.length < program.max_cycles,
      ),
      groupId,
    });
  };

  const optionsShowConfig = {
    addCycle: mayAddCycles && !dirty,
    saveChanges: mayUpdateCycles && dirty && isValid,
    nextConflict: mayUpdateCycles && dirty && !isValid,
    discard: dirty,
  };

  const totalSelected = selected.length;
  const totalClasses = classes.length;
  const totalFilteredItems = filteredItems.length;
  const totalItems =
    totalFilteredItems < totalClasses ? totalFilteredItems : totalClasses;

  const handleToggleAll =
    totalFilteredItems < totalClasses ? toggleAllFilter : toggleAll;

  pluralize.addIrregularRule('doesn’t', 'don’t');

  return (
    <Show when={mayAddCycles || mayUpdateCycles}>
      <Actionbar paddingLeft={0}>
        <Row justifyContent="flex-end">
          <Show when={showNextUnscheduledButton}>
            <Col colsSm={6} cols={0}>
              <Button
                iconLeft={
                  <Display whenMd whenLg whenXl display="contents">
                    <IconWarning />
                  </Display>
                }
                color="warning-secondary"
                onClick={goToNextUnscheduledCycle}
                fullWidth
                fullHeight
              >
                Find Unscheduled
              </Button>
            </Col>
          </Show>
          <Show when={mayEditDefaultSettings}>
            <Col colsSm={6} cols={0}>
              <Button
                to={toGroupSchedulesDefaultSettings(groupId)}
                iconRight={
                  <Display whenMd whenLg whenXl display="contents">
                    <IconChevronRight />
                  </Display>
                }
                color="secondary"
                fullWidth
                fullHeight
              >
                Default Schedule
              </Button>
            </Col>
          </Show>
        </Row>
        <Row justifyContent="space-between" alignItems="center">
          <Col colsSm={12} cols={0} shrink>
            <ActionToggleContainer>
              <ControlToggle label="" aria-label="Bulk class selector" />
              <Show when={optionsShowConfig.addCycle}>
                <Button
                  fullWidth
                  fullHeight
                  color="primary"
                  disabled={!someMarked || dirty || allClassesHaveMaxCycles}
                  onClick={onClickAddCycles}
                  data-testid="btn-bulk-add-cycle"
                  iconLeft={<IconAdd />}
                >
                  Add Cycle to
                  {` ${pluralize(
                    terms.class,
                    totalSelected,
                    Boolean(totalSelected),
                  )}`}
                </Button>
              </Show>
            </ActionToggleContainer>
          </Col>
          <Show when={optionsShowConfig.saveChanges}>
            <Col cols={0}>
              <Button
                fullWidth
                fullHeight
                type="submit"
                loading={isSubmitting}
                disabled={!dirty || !isValid || isSubmitting}
                data-testid="btn-submit-update-cycles"
              >{`Save ${pluralize(
                'Change',
                numberOfPendingChanges,
                true,
              )}`}</Button>
            </Col>
          </Show>
          <Show when={optionsShowConfig.nextConflict}>
            <Col cols={0}>
              <Button
                fullWidth
                fullHeight
                color="warning"
                onClick={goToNextConflict}
                disabled={isValid}
              >
                Go To Next Conflict
              </Button>
            </Col>
          </Show>
          <Show when={optionsShowConfig.discard}>
            <Col cols={0} displaySm="none">
              <Button
                fullWidth
                fullHeight
                color="warning"
                onClick={handleReset}
              >
                Discard
              </Button>
            </Col>
          </Show>
          <Col colsSm={12} cols={0}>
            <BulkSelectMessage
              totalSelected={totalSelected}
              totalItems={totalItems}
              toggleAll={handleToggleAll}
            />
          </Col>
          <Col vAlign="center" hAlign="flex-end" hAlignSm="center" colsSm={12}>
            <Pagination />
          </Col>
        </Row>
      </Actionbar>
    </Show>
  );
};
