import { useParams } from 'react-router-dom';

import { useGroupsQueryByNetworkId } from 'models';
import { PagesParams } from 'pages';

import { NetworkGroupsRender } from './NetworkGroupsRender';
import { ErrorMessageBox } from 'components/ErrorMessageBox';
import Loading from 'components/Loading';
import { createUid, getMessageFromErrors } from '@perts/util';

export const NetworkGroups = () => {
  const { networkId: shortNetworkId } = useParams<PagesParams>();
  const networkId = createUid('Network', shortNetworkId);

  // Query for Groups of Network.
  const groupsQuery = useGroupsQueryByNetworkId(networkId);

  if (groupsQuery.isLoading) {
    return <Loading />;
  }

  // Display any errors.
  if (!groupsQuery.isSuccess) {
    return (
      <ErrorMessageBox>
        {getMessageFromErrors([groupsQuery.error])}
      </ErrorMessageBox>
    );
  }

  return <NetworkGroupsRender groups={groupsQuery.data} />;
};
