// https://docs.google.com/spreadsheets/d/1EKKzZjI-eCtekB_S_SsNnBQpGEKVIKueZIkBD6W8QJc

import keyBy from 'lodash/keyBy';

import { locales } from '../i18n';
import { CompositeMetricConfig, MetricItemConfig } from './types';
import {
  sesStudent23OptionsDisagreeAgree,
  sesStudent23OptionsMotivated,
} from './answerOptions';

export const sesStudent23ScienceItemConfig: MetricItemConfig[] = [
  {
    label: 'science_student_voice',
    prompts: {
      [locales.ENGLISH]: `My science teacher responds to student suggestions to make our class better.`,
      [locales.SPANISH]: `Mi maestro de ciencias responde a las sugerencias de los estudiantes para mejorar nuestra clase.`,
    },
    options: sesStudent23OptionsDisagreeAgree({
      en: 'science',
      es: 'ciencias',
    }),
    minGood: 5,
  },
  {
    label: 'science_teacher_caring',
    prompts: {
      [locales.ENGLISH]: `My science teacher cares about my life outside of school.`,
      [locales.SPANISH]: `Mi maestro de ciencias se preocupa por mí vida fuera de la escuela.`,
    },
    options: sesStudent23OptionsDisagreeAgree({
      en: 'science',
      es: 'ciencias',
    }),
    minGood: 5,
  },
  {
    label: 'science_effort',
    prompts: {
      [locales.ENGLISH]: `Overall, how motivated are you to work hard in your science class?`,
      [locales.SPANISH]: `En general, ¿qué tan motivado/a estás para trabajar duro en tu clase de ciencias?`,
    },
    options: sesStudent23OptionsMotivated({
      en: 'science',
      es: 'ciencias',
    }),
    minGood: 4,
  },
];

export const sesStudent23ScienceConfig: CompositeMetricConfig = {
  label: 'ses-student23-science',
  items: sesStudent23ScienceItemConfig,
  itemIndex: keyBy(sesStudent23ScienceItemConfig, 'label'),
};
