import styled from 'styled-components/macro';
import { IconButton, IconSettings } from '@perts/ui';
import { theme } from 'components';

const Spacer = styled.div`
  margin-right: ${theme.units.gridDefaultGutter}px;

  @media only screen and (max-width: ${theme.units.gridBreakpointSmall}px) {
    margin-right: ${(props) => props.theme.units.gridDefaultGutter / 2}px;
  }
`;

export const AdvancedSettingsButton = ({ onClick }) => (
  <>
    <IconButton
      aria-label="Advanced Settings"
      icon={<IconSettings />}
      onClick={onClick}
    />
    <Spacer />
  </>
);
