import { useMutation } from 'react-query';
import { message } from 'antd';
import {
  User,
  useUsersQueryByParams,
  usersInviteToGroup,
  useGroupGetByParams,
  selectUserAccountIsPending,
} from 'models';

import {
  MembersSendInvitationForm,
  MembersSendInvitationFormValues,
} from './MembersSendInvitationForm';
import Loading from 'components/Loading';
import { useTerms } from 'components/TermsContext';
import { ErrorMessageBox } from 'components/ErrorMessageBox';

import { getMessageFromErrors } from '@perts/util';
import { useCloseModal, useGetCheckedStates } from 'utils';

export const MembersSendInvitation = () => {
  const checked = useGetCheckedStates();
  const terms = useTerms();
  const closeModal = useCloseModal();

  // Query for Group.
  const {
    isLoading: groupIsLoading,
    isError: groupIsError,
    data: group,
    error: groupError,
  } = useGroupGetByParams();

  // Query for Users of Group.
  const {
    isLoading: usersIsLoading,
    data: users = [],
    isError: usersIsError,
    error: usersError,
  } = useUsersQueryByParams();

  // Determine members to send invitation
  const membersToSendInvitation: User[] = users.filter(
    (user) => checked[user.uid] && selectUserAccountIsPending(user),
  );

  const checkedMembers: User[] = users.filter((user) => checked[user.uid]);

  const mutation = useMutation(
    async ({ members }: MembersSendInvitationFormValues) => {
      if (!group) {
        throw new Error('Group required to invite user.');
      }
      await usersInviteToGroup(members, group, { forceSendInvitation: true });
    },
    {
      // Handle successful mutation.
      onSuccess: () => {
        message.success(
          `Successfully sent invitations to ${terms.groupMembers.toLowerCase()}.`,
        );
      },
      onError: () => {
        message.error('There was an error sending invitations.');
      },
    },
  );

  const onSubmit = async (values: MembersSendInvitationFormValues) => {
    await mutation.mutateAsync(values);
  };

  const isLoading = groupIsLoading || usersIsLoading;

  if (isLoading) {
    return <Loading />;
  }

  if (usersIsError || groupIsError) {
    return (
      <ErrorMessageBox>
        {getMessageFromErrors([groupError, usersError])}
      </ErrorMessageBox>
    );
  }

  return (
    <MembersSendInvitationForm
      close={closeModal}
      onSubmit={onSubmit}
      checkedMembers={checkedMembers}
      membersToSendInvitation={membersToSendInvitation}
    />
  );
};
