import { ReactNode, useEffect, useState } from 'react';
import QRCode from 'qrcode';

import { Button } from '../..';

export const getPngDataUrl = (url: string): Promise<string> => {
  // Simplest alphanumeric encoding available for QR codes only has uppercase
  // letters, not lowercase, so this reduces the number of bits and thus the
  // size of the QR code.
  const urlUpper = url.toUpperCase();

  // 'L' for "low". Reduces size of the code. Error correction is important when
  // the physical printed code can get dirty or damaged. Not as useful when
  // displayed on a projector or screen.
  const errorCorrectionLevel = 'L';

  // Image type defaults to PNG: https://www.npmjs.com/package/qrcode#options
  return QRCode.toDataURL(urlUpper, { errorCorrectionLevel });
};

export const QRCodeImage = ({ url }: { url: string }) => {
  const [dataUrl, setDataUrl] = useState<string | undefined>(undefined);

  useEffect(() => {
    (async () => setDataUrl(await getPngDataUrl(url)))();
  }, [setDataUrl, url]);

  return dataUrl ? <img src={dataUrl} alt={`QR code for ${url}`} /> : null;
};

type DownloadButtonProps = {
  children: ReactNode;
  fileName: string;
  url: string;
};

export const QRCodeDownloadButton = ({
  children,
  fileName,
  url,
}: DownloadButtonProps) => {
  const [dataUrl, setDataUrl] = useState<string | undefined>(undefined);

  useEffect(() => {
    (async () => setDataUrl(await getPngDataUrl(url)))();
  });

  return dataUrl ? (
    <Button to={dataUrl} download={`${fileName}.png`} color="primary">
      {children}
    </Button>
  ) : null;
};
