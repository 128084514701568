import { useParams as useParamsReactRouter } from 'react-router-dom';
import { PagesParams } from 'pages';
import { getLongUid } from '@perts/util';

export const useParams = () => {
  const { classId, groupId, programId, reportId, userId, networkId, ...rest } =
    useParamsReactRouter<PagesParams>();

  return {
    classId: getLongUid('Team', classId),
    groupId: getLongUid('Organization', groupId),
    programId: getLongUid('Program', programId),
    reportId: getLongUid('Report', reportId),
    userId: getLongUid('User', userId),
    networkId: getLongUid('Network', networkId),
    ...rest,
  };
};
