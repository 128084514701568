// https://docs.google.com/spreadsheets/d/1EKKzZjI-eCtekB_S_SsNnBQpGEKVIKueZIkBD6W8QJc

import keyBy from 'lodash/keyBy';

import { locales } from '../i18n';
import { CompositeMetricConfig, MetricItemConfig } from './types';
import {
  sesStudent23OptionsDisagreeAgree,
  sesStudent23OptionsMotivated,
} from './answerOptions';

export const sesStudent23EnglishItemConfig: MetricItemConfig[] = [
  {
    label: 'english_student_voice',
    prompts: {
      [locales.ENGLISH]: `My English teacher responds to student suggestions to make our class better.`,
      [locales.SPANISH]: `Mi maestro de inglés responde a las sugerencias de los estudiantes para mejorar nuestra clase. `,
    },
    options: sesStudent23OptionsDisagreeAgree({
      en: 'English',
      es: 'inglés',
    }),
    minGood: 5,
  },
  {
    label: 'english_teacher_caring',
    prompts: {
      [locales.ENGLISH]: `My English teacher cares about my life outside of school.`,
      [locales.SPANISH]: `Mi maestro de inglés se preocupa por mí vida fuera de la escuela.`,
    },
    options: sesStudent23OptionsDisagreeAgree({
      en: 'English',
      es: 'inglés',
    }),
    minGood: 5,
  },
  {
    label: 'english_effort',
    prompts: {
      [locales.ENGLISH]: `Overall, how motivated are you to work hard in your English class?`,
      [locales.SPANISH]: `En general, ¿qué tan motivado/a estás para trabajar duro en tu clase de inglés?`,
    },
    options: sesStudent23OptionsMotivated({
      en: 'English',
      es: 'inglés',
    }),
    minGood: 4,
  },
];

export const sesStudent23EnglishConfig: CompositeMetricConfig = {
  label: 'ses-student23-english',
  items: sesStudent23EnglishItemConfig,
  itemIndex: keyBy(sesStudent23EnglishItemConfig, 'label'),
};
