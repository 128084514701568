import { useQueryClient, useMutation } from 'react-query';
import { message } from 'antd';

import { getMessageFromErrors } from '@perts/util';

import { Loading, ErrorMessageBox } from 'components';
import {
  courseAddToGroup,
  queryKeyGroup,
  queryKeyGroupsByProgram,
  useGroupGetByParams,
} from 'models';
import { useParams } from 'pages';
import { useCloseModal } from 'utils';
import { GroupsCreateCourseForm } from './GroupsCreateCourseForm';

export const GroupsCreateCourse = () => {
  const { groupId, programId } = useParams();
  const closeModal = useCloseModal({
    ignoreEmptyState: true,
  });
  const groupQuery = useGroupGetByParams();
  const queryClient = useQueryClient();
  const queryKeyById = queryKeyGroup(groupId);
  const queryKeyByProgram = queryKeyGroupsByProgram(programId);

  const mutation = useMutation(() => courseAddToGroup(groupId), {
    onSuccess: (/* course is available here */) => {
      queryClient.invalidateQueries(queryKeyById);
      queryClient.invalidateQueries(queryKeyByProgram);

      closeModal();
      message.success(`Successfully created course.`);
    },
    onError: () => {
      queryClient.invalidateQueries(queryKeyById);
      queryClient.invalidateQueries(queryKeyByProgram);

      message.error(`There was a problem creating the course.`);
    },
  });

  // Display loading.
  if (groupQuery.isLoading) {
    return <Loading />;
  }

  // Display errors.
  if (!groupQuery.isSuccess) {
    return (
      <ErrorMessageBox>
        {getMessageFromErrors([groupQuery.error])}
      </ErrorMessageBox>
    );
  }

  return (
    <GroupsCreateCourseForm
      close={closeModal}
      onSubmit={() => mutation.mutateAsync()}
      group={groupQuery.data}
    />
  );
};
