import {
  Group,
  queryKeyGroup,
  selectUserIsAdmin,
  selectUserIsGroupManager,
  updateGroup,
  useCurrentUserQuery,
  useGroupGetByParams,
  useMutationUpdate,
  useProgramGetByParams,
} from 'models';
import { useParams } from 'pages';

import { getMessageFromErrors } from '@perts/util';

import Loading from 'components/Loading';
import { useTerms } from 'components/TermsContext';
import { ErrorMessageBox } from 'components/ErrorMessageBox';
import { ClassesDefaultSettingsRender } from './ClassesDefaultSettingsRender';
import {
  RosterSignOnLockFormValues,
  RosterSignOnRuleFormValues,
  RosterSignOnTypeFormValues,
  RosterTargetGroupFormValues,
  getRosterSignOnRuleSettings,
  getRosterSignOnTypeSettings,
  getRosterTargetGroupSettings,
} from 'pages/Classes/shared';
import { pickBy } from 'lodash';

const filterFalsySettings = (settings): Group['default_settings'] =>
  pickBy<Group['default_settings']>(settings, Boolean);

export const ClassesDefaultSettings = () => {
  const { groupId } = useParams();
  const queryKey = queryKeyGroup(groupId);
  const terms = useTerms();

  const userQuery = useCurrentUserQuery();
  const programQuery = useProgramGetByParams();
  const groupQuery = useGroupGetByParams();

  // Mutation: Update Group classes default settings
  const mutation = useMutationUpdate<Group, Group>(updateGroup, queryKey, {
    optimisticallyUpdate: false,
    transformFormValues: (formValues) => formValues,
    toastSuccessMsg: `Successfully updated ${terms.classes.toLowerCase()} default settings.`,
    toastErrorMsg: `There was a problem updating ${terms.classes.toLowerCase()} default settings.`,
  });

  // Display loading.
  if (userQuery.isLoading || programQuery.isLoading || groupQuery.isLoading) {
    return <Loading />;
  }

  // Display any errors.
  if (!groupQuery.isSuccess || !programQuery.data || !userQuery.isSuccess) {
    return (
      <ErrorMessageBox>
        {getMessageFromErrors([
          userQuery.error,
          programQuery.error,
          groupQuery.error,
        ])}
      </ErrorMessageBox>
    );
  }

  const onSubmitType = async (values: RosterSignOnTypeFormValues) => {
    const group = groupQuery.data;

    const updatedDefaultSettings = {
      ...group.default_settings,
      ...getRosterSignOnTypeSettings({ values, terms, group }),
    };

    const filteredUpdatedDefaultSettings = filterFalsySettings(
      updatedDefaultSettings,
    );

    const updatedGroup = {
      ...group,
      default_settings: filteredUpdatedDefaultSettings,
    };

    await mutation.mutateAsync(updatedGroup);
  };

  const onSubmitRule = async (values: RosterSignOnRuleFormValues) => {
    const group = groupQuery.data;

    const updatedDefaultSettings = {
      ...group.default_settings,
      ...getRosterSignOnRuleSettings({ values }),
    };

    const filteredUpdatedDefaultSettings = filterFalsySettings(
      updatedDefaultSettings,
    );

    const updatedGroup = {
      ...group,
      default_settings: filteredUpdatedDefaultSettings,
    };

    await mutation.mutateAsync(updatedGroup);
  };

  const onSubmitLock = async (values: RosterSignOnLockFormValues) => {
    const group = groupQuery.data;

    const updatedDefaultSettings = {
      ...group.default_settings,
      roster_locked: values.rosterLocked,
    };

    const filteredUpdatedDefaultSettings = filterFalsySettings(
      updatedDefaultSettings,
    );

    const updatedGroup = {
      ...group,
      default_settings: filteredUpdatedDefaultSettings,
    };

    await mutation.mutateAsync(updatedGroup);
  };

  const onSubmitTargetGroup = async (values: RosterTargetGroupFormValues) => {
    const group = groupQuery.data;

    const updatedDefaultSettings = {
      ...group.default_settings,
      ...getRosterTargetGroupSettings({ values }),
    };

    const filteredUpdatedDefaultSettings = filterFalsySettings(
      updatedDefaultSettings,
    );

    const updatedGroup = {
      ...group,
      default_settings: filteredUpdatedDefaultSettings,
    };

    await mutation.mutateAsync(updatedGroup);
  };

  const onSubmitParentGroups = async (values: any) => {
    const group = groupQuery.data;

    const updatedDefaultSettings = {
      ...group.default_settings,
      parent_organizations: values.parent_organizations,
    };

    const updatedGroup = {
      ...group,
      default_settings: updatedDefaultSettings,
    };

    await mutation.mutateAsync(updatedGroup);
  };

  const userIsManager = selectUserIsGroupManager(userQuery.data, groupId);
  const userIsAdmin = selectUserIsAdmin(userQuery.data);

  const mayEditDefaultSettings = userIsManager || userIsAdmin;

  if (!mayEditDefaultSettings) {
    return (
      <ErrorMessageBox>
        You don&rsquo;t have access to this page.
      </ErrorMessageBox>
    );
  }

  return (
    <ClassesDefaultSettingsRender
      program={programQuery.data}
      group={groupQuery.data}
      userIsAdmin={userIsAdmin}
      onSubmitType={onSubmitType}
      onSubmitRule={onSubmitRule}
      onSubmitLock={onSubmitLock}
      onSubmitTargetGroup={onSubmitTargetGroup}
      onSubmitParentGroups={onSubmitParentGroups}
    />
  );
};
