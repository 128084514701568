/**
 * One of several show/hide components: Show (hide children by not rendering),
 * Visible (hide children with `visibility: hidden`), and Display (hide children
 * with `display: none`)
 */

import styled, { css } from 'styled-components/macro';

export type VisibleProps = {
  children: React.ReactNode | any | undefined;
  when?: boolean | number;
};

const VisibleWrapper = styled.div<{ visible: boolean }>`
  ${({ visible }) =>
    css`
      visibility: ${visible ? 'visible' : 'hidden'};
    `}
`;

// Allows content to be shown or not shown without changing the layout of the
// page. Hidden content gets the CSS rule `visibility: hidden` so it (1) still
// occupies exactly the same height and width, (2) is not visible, and (3) is
// removed from the accessibility tree (hidden from screen readers). Useful for
// status indicators that show or hide as the user interacts without causing the
// layout to shift. Note: always _renders_ its content, which is different than
// <Show />.
export const Visible = ({ children, when }: VisibleProps) => (
  <VisibleWrapper visible={Boolean(when)}>{children}</VisibleWrapper>
);
