import { Route, Switch } from 'react-router-dom';
import { toGroupParticipation, toGroupParticipationByParticipant } from 'pages';
import { Participation } from './Participation';
import { ParticipationByParticipant } from './ParticipationByParticipant';

export const ParticipationRouter = () => (
  <Switch>
    <Route path={toGroupParticipation()} exact>
      <Participation />
    </Route>
    <Route path={toGroupParticipationByParticipant()}>
      <ParticipationByParticipant />
    </Route>
  </Switch>
);
