import styled from 'styled-components/macro';

import { theme } from '../..';
import { LinkProps } from '.';

export const StyledLink = styled('a')<LinkProps>`
  padding: 0;
  border: 0;

  cursor: pointer;

  color: ${(props) => props.theme.colors.primary};
  background: none;

  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  font-weight: 600;
  text-decoration: none;

  &:active {
    color: ${(props) => props.theme.colors.primary};
  }

  &:hover {
    text-decoration: underline;
  }

  &:focus {
    outline: 2px solid ${(props) => props.theme.colors.primary};
    outline-offset: 2px;
    border-radius: 3px;
  }
`;

StyledLink.defaultProps = {
  theme,
};
