import { orderBy } from 'lodash';

import { PortalType } from '@perts/model';
import { getMessageFromErrors } from '@perts/util';

import {
  Class,
  dataIsEmpty,
  selectClassFacilitators,
  selectClassGroups,
  useClassesQueryByParams,
  useGroupGetByParams,
  useGroupsQueryByClasses,
  useProgramGetByParams,
  useUsersQueryByGroupId,
  useCurrentUserQuery,
  selectUserIsAdmin,
  selectUserIsGroupManager,
  selectUserIsMember,
} from 'models';
import { useParams } from 'pages';

import { useSortByClasses, ErrorMessageBox, Loading } from 'components';
import { ClassesRender } from './ClassesRender';

// eslint-disable-next-line complexity
export const Classes = () => {
  const { groupId } = useParams();

  // Sorting
  const { SortBy, sortByField, sortByDirection } = useSortByClasses();

  // Queries necessary to render.
  const classesQuery = useClassesQueryByParams();
  const currentUserQuery = useCurrentUserQuery();
  const groupQuery = useGroupGetByParams();
  const programQuery = useProgramGetByParams();

  // Slower queries NOT required to render. They can load in lazily.
  // Query for Groups associated with all Classes (for Group tags).
  const classesGroupsQuery = useGroupsQueryByClasses(classesQuery.data);
  const groupUsersQuery = useUsersQueryByGroupId(groupId);

  // Display loading.
  if (
    classesQuery.isLoading ||
    currentUserQuery.isLoading ||
    groupQuery.isLoading ||
    programQuery.isLoading
  ) {
    return <Loading />;
  }

  // Display any errors.
  if (
    !classesQuery.isSuccess ||
    !currentUserQuery.isSuccess ||
    !groupQuery.isSuccess ||
    dataIsEmpty(programQuery.data)
  ) {
    return (
      <ErrorMessageBox>
        {getMessageFromErrors([
          classesQuery.error,
          currentUserQuery.error,
          groupQuery.error,
          programQuery.error,
        ])}
      </ErrorMessageBox>
    );
  }

  // Fill facilitators, groups, participants
  const classesSorted: Class[] = orderBy(
    classesQuery.data.map((cls) => ({
      ...cls,

      facilitators: selectClassFacilitators(cls, groupUsersQuery.data),
      facilitatorsIsLoading: groupUsersQuery.isLoading,

      groups: selectClassGroups(cls, classesGroupsQuery.data),
      groupsIsLoading: classesGroupsQuery.isLoading,

      // Properties added for sorting
      portal_type_email: cls.portal_type === PortalType.EMAIL,
      portal_type_google: cls.portal_type === PortalType.GOOGLE,
      portal_type_studentid: cls.portal_type === PortalType.STUDENT_ID,
      portal_type_clever: cls.portal_type === PortalType.CLEVER,
      isSignOnRule: cls.participant_ending && cls.participant_ending.length > 0,
      isTargetGroup: cls.target_group_name && cls.target_group_name.length > 0,
    })),
    // Always secondary sort by name even when sorting by metrics.
    [sortByField, 'name'],
    [sortByDirection, 'asc'],
  );

  const currentUser = currentUserQuery.data;

  const userIsMember = selectUserIsMember(currentUser, groupId);
  const userIsAdmin = selectUserIsAdmin(currentUser);
  const userIsManager = selectUserIsGroupManager(currentUser, groupId);

  return (
    <ClassesRender
      archived={groupQuery.data.archived}
      mayAddClasses={userIsMember || userIsAdmin}
      mayEditMembers={userIsMember || userIsAdmin}
      mayEditGroups={userIsMember || userIsAdmin}
      mayDeleteClasses={userIsMember || userIsAdmin}
      mayEditTargetGroup={
        programQuery.data.target_group_enabled && (userIsMember || userIsAdmin)
      }
      mayLockRoster={userIsMember || userIsAdmin}
      mayNavigateToRoster={userIsMember || userIsAdmin}
      mayEditNameInPlace={userIsMember || userIsAdmin}
      mayEditRosterIDRule={userIsMember || userIsAdmin}
      mayEditDefaultSettings={userIsManager || userIsAdmin}
      classes={classesSorted}
      SortByComponent={SortBy}
    />
  );
};
