/**
 * Returns `currentValue + 1` when that value would not go over `maxValue`,
 * else returns `resetValue`.
 * @param currentValue number
 * @param maxValue number
 * @param resetValue number
 * @returns number
 */

export const incrementUpTo = (
  currentValue: number,
  maxValue: number,
  resetValue: number,
) => {
  const nextValue = currentValue + 1;
  return nextValue > maxValue ? resetValue : nextValue;
};
