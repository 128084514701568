import styled, { css } from 'styled-components/macro';

import { MetricLogoStyled, theme } from '@perts/ui';

type Props = {
  fullscreen?: boolean;
};

export const ReportStyled = styled.div<Props>`
  ${({ fullscreen = false }) =>
    fullscreen &&
    css`
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      z-index: 100;
      padding: ${theme.units.bodyPadding};
      background-color: ${theme.colors.appBackground};
      overflow: auto;

      @media (max-width: ${theme.units.gridBreakpointMedium}px),
        (max-height: ${theme.units.gridBreakpointMedium}px) {
        padding: ${theme.units.bodyPaddingMd};
      }

      @media (max-width: ${theme.units.gridBreakpointSmall}px),
        (max-height: ${theme.units.gridBreakpointSmall}px) {
        padding: ${theme.units.bodyPaddingSm};
      }
    `}

  h1 {
    font-size: 24px;
    text-align: center;
  }

  h2 {
    font-size: 20px;
  }

  h3 {
    font-size: 16px;
  }

  section h3:not(:first-child) {
    margin-top: ${theme.units.bodyPadding};
  }

  // Add a pseudo-element before bookmarks so that the sticky navbar doesn't
  // overlap them.
  section[id]:before {
    display: block;
    content: ' ';
    margin: -100px 0 0;
    height: 100px;
    visibility: hidden;
    pointer-events: none;
  }

  // Usually we draw attention to field labels, but in this UI the various
  // filtering controls are not the priority.
  label {
    font-weight: normal;
  }

  @media (max-width: ${theme.units.gridBreakpointMedium}px) {
    section[id]:before {
      margin: -95px 0 0;
      height: 95px;
    }
  }

  @media (max-width: ${theme.units.gridBreakpointSmall}px) {
    section[id]:before {
      margin: -75px 0 0;
      height: 75px;
    }
  }

  // Size metric logos nicely. See MetricSection.
  ${MetricLogoStyled} {
    max-width: 100px;
    margin-right: ${theme.units.fieldPadding};
  }

  // Print adjustments
  @media print {
    // ** Spacing adjustments ** //
    h1 {
      font-size: 20px;
    }

    h2 {
      font-size: 16px;
    }

    label {
      font-size: 14px;
    }

    a,
    div {
      font-size: 12px;
    }

    h2 {
      font-size: 16px;
    }

    th,
    p,
    em,
    span,
    td {
      font-size: 10px;
    }

    td {
      padding-top: 2px;
      padding-bottom: 2px;
    }

    #metric-trends > div,
    section {
      margin-top: 20px;
    }

    // ** Hidden Elements ** //
    div[role='navigation'] {
      display: none;
    }

    // ** Page Breaks ** //

    h1 {
      break-after: avoid;
      page-break-after: avoid;
    }

    section {
      break-before: auto;
      page-break-before: auto;
      break-inside: auto;
      page-break-inside: auto;
    }

    p {
      break-inside: avoid;
      page-break-inside: avoid;
    }

    figure,
    table,
    div {
      break-before: avoid;
      page-break-before: avoid;
      break-inside: avoid;
      page-break-inside: avoid;
      break-after: avoid;
      page-break-after: avoid;
    }
  }
`;
