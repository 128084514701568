import { Participant } from 'models';
import useToggles from 'utils/useToggles';

import { useTerms } from 'components/TermsContext';
import { ClassRosterParticipantsActionbar } from './ClassRosterParticipantsActionbar';
import { ClassRosterParticipantsActionbarAdd } from './ClassRosterParticipantsActionbarAdd';
import { ParticipantCard } from './ParticipantCard';
import { EmptyList } from 'components/EmptyList';
import { Card, Col, Row, Show, FixedActionbarContainer } from '@perts/ui';

type ClassRosterParticipantsFormProps = {
  participants: Participant[];
  mayEditTargetGroup: boolean;
  maySeeTargetGroup: boolean;
  targetGroupEnabled: boolean;
};

export const ClassRosterParticipantsForm = ({
  participants,
  mayEditTargetGroup,
  maySeeTargetGroup,
  targetGroupEnabled,
}: ClassRosterParticipantsFormProps) => {
  const terms = useTerms();
  const { ControlToggle, Toggle, someMarked, selected, checked } = useToggles(
    participants,
    'uid',
  );

  return (
    <>
      {/*
        TODO Reorganize the ClassRoster components/pages so it's like Classes.
        Clean up tabs, ActionBar, etc., and move out of the containing Card, so
        that EmptyList won't be stuck "inside" the containing Card and can be
        displayed like other pages. Something like,

        <ClassRosterTabs />
        
        <Show when={participants.length === 0}>
          <EmptyList />
        </Show>

        <Show when={participants.length !== 0}>
          <ClassActionBar />
          {participants.map(p => <Card>{p}</Card>)}
        </Show>
      */}

      <FixedActionbarContainer isFixedUnderTopBar={false}>
        <ClassRosterParticipantsActionbarAdd
          targetGroupEnabled={targetGroupEnabled}
        />

        <Show when={participants.length > 0}>
          <ClassRosterParticipantsActionbar
            ControlToggle={ControlToggle}
            disabled={!someMarked}
            participants={participants}
            selected={selected}
            checked={checked}
            mayEditTargetGroup={mayEditTargetGroup}
          />
        </Show>
      </FixedActionbarContainer>

      <Card>
        <Card.Title>
          <Row>
            <Col cols={7} offset={1}>
              <strong>{terms.participant}</strong>
            </Col>
            <Col cols={2} hAlign="center">
              <strong>Added by {terms.participant.toLowerCase()}</strong>
            </Col>
            <Col cols={2} hAlign="center">
              <Show when={targetGroupEnabled}>
                <strong>In focal group</strong>
              </Show>
            </Col>
          </Row>
        </Card.Title>
        <Card.Content>
          <Show when={participants.length === 0}>
            <EmptyList />
          </Show>

          <Show when={participants.length > 0}>
            <div>
              {participants.map((participant) => (
                <ParticipantCard
                  maySeeTargetGroup={maySeeTargetGroup}
                  key={participant.uid}
                  toggle={
                    <Toggle
                      id={participant.uid}
                      aria-label={`Selector ${participant.student_id}`}
                    />
                  }
                  participant={participant}
                />
              ))}
            </div>
          </Show>
        </Card.Content>
      </Card>
    </>
  );
};
