import { Link as ReactRouterLink } from 'react-router-dom';
import { isExternal } from './isExternal';

type LinkPropsParams = {
  to?: string;
  onClick?: (event: React.FormEvent<HTMLButtonElement>) => void;
  type?: 'submit' | 'button';
};

export const linkProps = (props: LinkPropsParams) => {
  // <button>
  if (props.onClick) {
    return {
      as: 'button',
      to: undefined,
      href: undefined,
      onClick: props.onClick,
    };
  }

  // <button type="submit">
  // Won't have it's own onClick, will submit the parent form.
  if (props.type === 'submit') {
    return {
      as: 'button',
      to: undefined,
      href: undefined,
      onClick: undefined,
    };
  }

  // <a>
  if (props.to && isExternal(props.to)) {
    return {
      as: 'a',
      to: undefined,
      href: props.to,
      onClick: undefined,
      target: '_blank',

      // This protects us against scripts on pages we may link to.
      // https://mathiasbynens.github.io/rel-noopener/
      rel: 'noopener noreferrer',
    };
  }

  if (props.to && props.to.startsWith('#')) {
    return {
      as: 'a',
      to: undefined,
      href: props.to,
      onClick: undefined,
      target: '',
    };
  }

  // react-router <Link>
  return {
    as: ReactRouterLink,
    to: props.to,
    href: undefined,
    onClick: undefined,
  };
};
