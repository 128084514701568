import { useRef } from 'react';
import styled from 'styled-components/macro';

import { Class, Program } from 'models';
import { ToggleType } from 'utils/useToggles';

import {
  IconChevronCircleLeft,
  IconChevronCircleRight,
  NonScrollingBodyStyles,
} from '@perts/ui';
import { ScheduleSort, SetSort } from '../';
import { ScheduleTableBatchEdit } from './ScheduleTableBatchEdit';
import { ScheduleTableHeader } from './ScheduleTableHeader';
import { ScheduleTableRows } from './ScheduleTableRows';
import theme from 'components/theme';

type ScheduleTableProps = {
  // Max cycles from all classes.
  currentMaxCycles: number;

  // Classes of the current page to be displayed.
  pagedClasses: Class[];

  // Unscheduled Classes from all classes.
  unscheduledClasses: Class[];

  // Is true if any item is either checked or indeterminate.
  someMarked: boolean;

  // Selected classes.
  selected: Class[];

  program: Program;

  // Toggle renders out the individual item toggle.
  Toggle: ToggleType;

  mayAddCycles: boolean;

  mayUpdateCycles: boolean;

  allPageIsSelected: boolean;

  FilterField: any;

  checked: { [key: string]: boolean };

  // Change how the table is sorted.
  setSort: SetSort;

  // Defines how the table is currently sorted.
  sort: ScheduleSort;
};

type ScheduleTablePropsContainer = {
  maxWidth: string;
  allPageIsSelected?: boolean;
  someMarked?: boolean;
  someUnscheduledClasses?: boolean;
  fixedHeight?: boolean;
};

export const ScheduleTableContainer = styled.div<ScheduleTablePropsContainer>`
  position: relative;
  max-width: ${({ maxWidth }) => maxWidth};
`;

export const ScheduleTableContent = styled.div<ScheduleTablePropsContainer>`
  position: relative;
  overflow: auto;
  scroll-behavior: smooth;
  z-index: 10;
  max-width: ${({ maxWidth }) => maxWidth};

  ${({ fixedHeight }) =>
    fixedHeight &&
    `
    // To calculate the available height to table we need to take the all size
    // and substract:
    // * TopBar Height: theme.units.appBarHeight
    // * Body padding x 2 (top and botton): theme.units.bodyPadding
    // * Button and Pagination actions height: It is a manual calculation considering
    // * Message to select/deselect all height: It is a manual calculation considering
    // * Message if there are unscheduled classes all height: It is a manual calculation considering
    // different values to different media queries
    // this is the last value in every calc(...);

    height: calc(
      100vh - ${theme.units.appBarHeight} - ${theme.units.bodyPadding} -
        ${theme.units.bodyPadding} - 75px -
        ${(props) => (props.allPageIsSelected ? '78px' : '0px')} -
        ${(props) => (props.someUnscheduledClasses ? '78px' : '0px')}
    );

    @media only screen and (max-width: ${theme.units.gridBreakpointSmall}px) {
      height: calc(
        100vh - ${theme.units.appBarHeight} - ${theme.units.bodyPaddingSm} -
          ${theme.units.bodyPaddingSm} - 80px - 40px -
          ${(props) => (props.allPageIsSelected ? '78px' : '0px')}
          ${(props) => (props.someUnscheduledClasses ? '78px' : '0px')}
      );
    }
  `}
`;

export const ButtonScroll = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  position: absolute;
  z-index: 15;
  top: 20px;
  background: ${theme.colors.white};
  border-radius: 50%;

  @media only screen and (max-width: ${theme.units.gridBreakpointSmall}px) {
    display: none;
  }
`;

export const ButtonScrollRight = styled(ButtonScroll)`
  right: 5px;
`;

export const ButtonScrollLeft = styled(ButtonScroll)`
  left: 245px;
`;

const TableHeaderFixedContainer = styled.div`
  position: sticky;
  top: 0px;
  background-color: ${theme.colors.appBackground};
  z-index: 100;
  width: fit-content;
  min-width: 100%;
`;

export const ScheduleTable = ({
  currentMaxCycles,
  pagedClasses = [],
  unscheduledClasses = [],
  Toggle,
  someMarked,
  checked,
  selected,
  program,
  mayAddCycles,
  mayUpdateCycles,
  allPageIsSelected,
  FilterField,
  setSort,
  sort,
}: ScheduleTableProps) => {
  const scrollContainer = useRef<HTMLDivElement>(null);

  const handleLeftScroll = () => {
    if (scrollContainer && scrollContainer.current) {
      scrollContainer.current.scrollLeft -= 250;
    }
  };

  const handleRightScroll = () => {
    if (scrollContainer && scrollContainer.current) {
      scrollContainer.current.scrollLeft += 250;
    }
  };

  // To calculate the max width to table we need to add width of the cols:
  // * Fist columns (class name): 250px
  // * Total cycle cols per width col PLUS ONE because the add cycle buttons
  //   occupy an extra column: (currentMaxCycles + 1) * 180

  const maxWidthTable = `calc(${250 + 180 * (currentMaxCycles + 1)}px)`;

  return (
    <>
      <NonScrollingBodyStyles />
      <ScheduleTableContainer maxWidth={maxWidthTable}>
        <ButtonScrollLeft
          role="button"
          onClick={handleLeftScroll}
          aria-label="Scroll left"
        >
          <IconChevronCircleLeft size={26} />
        </ButtonScrollLeft>

        <ButtonScrollRight
          role="button"
          onClick={handleRightScroll}
          aria-label="Scroll right"
        >
          <IconChevronCircleRight size={26} />
        </ButtonScrollRight>
        <ScheduleTableContent
          id="ScheduleTable"
          ref={scrollContainer}
          maxWidth={maxWidthTable}
          allPageIsSelected={allPageIsSelected}
          someUnscheduledClasses={unscheduledClasses.length > 0}
          fixedHeight={true}
        >
          <TableHeaderFixedContainer>
            <ScheduleTableHeader
              currentMaxCycles={currentMaxCycles}
              FilterField={FilterField}
              setSort={setSort}
              sort={sort}
            />

            <ScheduleTableBatchEdit
              currentMaxCycles={currentMaxCycles}
              mayUpdateCycles={mayUpdateCycles}
              program={program}
              someMarked={someMarked}
              selectedClasses={selected}
            />
          </TableHeaderFixedContainer>

          <ScheduleTableRows
            classes={pagedClasses}
            Toggle={Toggle}
            checked={checked}
            currentMaxCycles={currentMaxCycles}
            mayAddCycles={mayAddCycles}
            mayUpdateCycles={mayUpdateCycles}
            program={program}
          />
        </ScheduleTableContent>
      </ScheduleTableContainer>
    </>
  );
};
