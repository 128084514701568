import { Formik, Form } from 'formik';

import {
  Button,
  Card,
  Col,
  IconWarning,
  InfoBox,
  Row,
  Visible,
  Text,
  useFormError,
} from '@perts/ui';

import { useTerms } from 'components';
import { Metric, MetricSet } from 'models';
import {
  SurveysEditMetricsFormContent,
  useMetricsDataForm,
} from 'pages/Surveys/shared';
import useToggles, { ToggleStates } from 'utils/useToggles';

type Props = {
  onSubmit: Function;
  enabledMetricIds?: string[];
  reportMetrics: Metric[];
  metricSets: MetricSet[];
};

type FormValues = ToggleStates;

export const ReportSettingsEnabledMetricsForm = ({
  onSubmit,
  enabledMetricIds,
  reportMetrics,
  metricSets,
}: Props) => {
  const terms = useTerms();

  const [FormError, showFormError] = useFormError();

  // If enabled metrics is not set, that means the user has made no explicit
  // choice on the matter, and we treat ALL metrics as enabled so as not to hide
  // data in surprising ways.
  const selectedMetricIds =
    enabledMetricIds === undefined
      ? reportMetrics.map((m) => m.uid)
      : enabledMetricIds;

  const initialValues = Object.fromEntries(
    reportMetrics.map((metric) => [
      metric.uid,
      { checked: selectedMetricIds.includes(metric.uid), indeterminate: false },
    ]),
  );

  const { isChecked, isIndeterminate, toggleChecked } = useToggles(
    reportMetrics,
    'uid',
    { initialState: initialValues },
  );

  const {
    // MetricSets but with extra data embedded. Ignores the "selected" behavior
    // used in SurveysEditMetricsForm where different metric sets can be shown
    // or hidden based on a select menu. We just want all the metric sets to
    // show all the time.
    allMetricSets,
  } = useMetricsDataForm({
    isChecked,
    isIndeterminate,
    metrics: reportMetrics,
    metricSets,
  });

  const activeMetricSets = allMetricSets.filter((ms) => ms.metrics.length > 0);

  const allChecked = (formValues: FormValues) =>
    Object.values(formValues).every((toggleState) => toggleState.checked);
  const noneChecked = (formValues: FormValues) =>
    Object.values(formValues).every((toggleState) => !toggleState.checked);

  const validate = (values: FormValues) => {
    const errors: { [key: string]: string | undefined } = {};

    if (noneChecked(values)) {
      errors.noneChecked = 'noneChecked';
    }

    return errors;
  };

  return (
    <>
      <Card>
        <Card.Title>
          <Text as="h2">{terms.metrics} in Report</Text>
        </Card.Title>
        <Card.Content>
          <Formik
            initialValues={initialValues}
            enableReinitialize={true}
            onSubmit={async (toggleStates) => {
              try {
                // Clear existing form error.
                await showFormError(false);

                // Transform from ToggleStates to what the related mutations
                // expect.
                const values = Object.fromEntries(
                  Object.entries(toggleStates).map(
                    ([metricId, { checked }]) => [metricId, checked],
                  ),
                );

                // Perform form onSubmit.
                await onSubmit(values);
              } catch (error: any) {
                // Display form error.
                showFormError(true);
              }
            }}
            validate={validate}
          >
            {({
              dirty,
              errors,
              isSubmitting,
              isValid,
              setFieldValue,
              values,
            }) => (
              <Form>
                <Row>
                  <Col>
                    <Visible when={!allChecked(values)}>
                      <InfoBox>
                        <div>
                          <IconWarning /> Unselected{' '}
                          {terms.metrics.toLowerCase()} will be hidden in this
                          report.
                        </div>
                      </InfoBox>
                    </Visible>
                  </Col>
                </Row>

                <SurveysEditMetricsFormContent
                  selectedMetricSets={activeMetricSets}
                  isChecked={isChecked}
                  isIndeterminate={isIndeterminate}
                  toggleChecked={toggleChecked}
                  setFieldValue={setFieldValue}
                  isSubmitting={isSubmitting}
                  values={values}
                  isViewingActive={false}
                  errors={errors}
                  isDisabled={false}
                />

                <Row>
                  <Col>
                    <Visible when={Boolean(errors.noneChecked)}>
                      <InfoBox color="error">
                        Please select at least one {terms.metric}.
                      </InfoBox>
                    </Visible>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormError />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Button
                      type="submit"
                      fullWidth
                      disabled={!isValid || isSubmitting || !dirty}
                      loading={isSubmitting}
                      data-testid="submit-btn"
                    >
                      Save {terms.metrics} In Report
                    </Button>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </Card.Content>
      </Card>
    </>
  );
};
