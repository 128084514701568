import { useContext } from 'react';
import { Formik, Form } from 'formik';
import { Group } from 'models';
import { Button, Col, Row, Modal } from '@perts/ui';

import TermsContext from 'components/TermsContext';

export type GroupsCreateCourseFormProps = {
  close: () => void;
  onSubmit: () => void;
  group: Group;
};

export const GroupsCreateCourseForm = ({
  close,
  onSubmit,
  group,
}: GroupsCreateCourseFormProps) => {
  const terms = useContext(TermsContext);

  // Regarding onSubmit logic: We'll close the modal in the mutation, once
  // everything is done, rather than doing anything with the onSubmit function
  // here. This strategy intentionally blocks the interface while the server
  // works. Because it's an admin-only feature, we're spending less dev time on
  // things like optimistic updates.

  return (
    <Modal close={close}>
      <Modal.Title className="center">
        Create Course for {group.name}
      </Modal.Title>

      <Formik enableReinitialize={true} initialValues={{}} onSubmit={onSubmit}>
        {({ isSubmitting }) => (
          <Form>
            <Row>
              <Col>
                Are you sure you&rsquo;d like to create a course for this{' '}
                {terms.group}?
              </Col>
            </Row>

            <Row>
              <Col cols={6} colsSm={12}>
                <Button
                  fullHeight
                  type="button"
                  color="secondary"
                  fullWidth
                  onClick={close}
                  disabled={isSubmitting}
                >
                  Cancel
                </Button>
              </Col>

              <Col cols={6} colsSm={12}>
                <Button
                  type="submit"
                  fullWidth
                  disabled={isSubmitting}
                  loading={isSubmitting}
                >
                  Create Course
                </Button>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
