import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Parser as CsvParser } from 'json2csv';

import Loading from 'components/Loading';
import { ErrorMessageBox } from 'components/ErrorMessageBox';
import {
  useNetworkGetByParams,
  useClassGetByParams,
  useGroupGetByParams,
  useReportQueryByParams,
  useDatasetGetById,
} from 'models';
import { helpArticles } from 'config';
import { Button, Col, HelpText, Modal, Row } from '@perts/ui';
import { getMessageFromErrors } from '@perts/util';

export const convertDataToCsv = (fields, data) =>
  new CsvParser({ fields }).parse(data);

const getDownloadURI = (fields, data) =>
  `data:text/csv;charset=utf-8,${encodeURIComponent(
    data && data.length > 0 ? convertDataToCsv(fields, data) : '',
  )}`;

// eslint-disable-next-line complexity
export const ReportsExport = () => {
  const history = useHistory();

  // Close the modal.
  const closeModal = useCallback(() => {
    history.replace('./');
  }, [history]);

  // This same modal is use for all types of reports. We have to check multiple
  // kinds of data before we can know the name of the thing we're downloading.
  // Similarly, we can't expect all of these to be successful.
  const networkQuery = useNetworkGetByParams();
  const groupQuery = useGroupGetByParams();
  const classQuery = useClassGetByParams();

  // This data should always be available.
  const reportQuery = useReportQueryByParams();
  const datasetQuery = useDatasetGetById(reportQuery.data?.dataset_id, {
    enabled: Boolean(reportQuery.isSuccess),
  });

  // All queries should finish loading, either with data or null.
  if (
    networkQuery.isLoading ||
    groupQuery.isLoading ||
    classQuery.isLoading ||
    reportQuery.isLoading ||
    datasetQuery.isLoading
  ) {
    return <Loading />;
  }

  if (!reportQuery.isSuccess || !datasetQuery.isSuccess) {
    return (
      <Modal close={closeModal}>
        <Modal.Title className="center">Export Report Data to CSV</Modal.Title>
        <ErrorMessageBox>
          {getMessageFromErrors([reportQuery.error, datasetQuery.error])}
        </ErrorMessageBox>
      </Modal>
    );
  }

  const report = reportQuery.data;
  const { csv_download_cols, csv_download_df } = datasetQuery.data;

  // Pick appropriate name for the downloaded file.
  const parentName =
    networkQuery.data?.name ||
    classQuery.data?.name ||
    groupQuery.data?.name ||
    'Report';
  const downloadName = `${parentName} ${report.issue_date}.csv`;

  return (
    <Modal close={closeModal}>
      <Modal.Title className="center">Export Report Data to CSV</Modal.Title>
      <Row>
        <Col>
          <HelpText articleId={helpArticles.howToUseExportedData}>
            Access codebooks for exported data
          </HelpText>
          <Button
            to={getDownloadURI(csv_download_cols, csv_download_df)}
            download={downloadName}
            fullWidth
          >
            Download CSV File
          </Button>
        </Col>
      </Row>
    </Modal>
  );
};
