// Example usage:
//
// import useQueryParams from 'utils/useQueryParams';
//
// const NextPage = () => {
//   const { get, set } = useQueryParams();
//
//   const next = () => {
//     const cursor = parseInt(get('cursor'), 10);
//     set('cursor', (cursor + 10).toString())
//   }
//
//   return <div onClick={next}>Next</div>;
// }

import { useHistory, useLocation } from 'react-router-dom';

export const useQueryParams = () => {
  const history = useHistory();
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);

  return {
    get: (key: string) => searchParams.get(key),

    toString: () => searchParams.toString(),

    set: (key: string, value?: string) => {
      // update query parameter
      if (value) {
        searchParams.set(key, value);
      } else {
        searchParams.delete(key);
      }

      // and then update history so the route updates
      history.push({ search: String(searchParams) });
    },
    remove: (key: string) => {
      // delete query parameter
      searchParams.delete(key);
      // and then update history so the route updates
      history.push({ search: String(searchParams) });
    },
  };
};
