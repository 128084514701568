import { Button, Col, IconChevronLeft, Row, Text } from '@perts/ui';
import { useTerms } from 'components';
import { toClassReport, useParams } from 'pages';
import { ReportSettingsClassEnabledMetrics } from './ReportSettingsClassEnabledMetrics';
import { ReportNavigationStyled } from '../ReportNavigation/ReportNavigationStyled';
import { ReportNavigationCard } from '../ReportNavigation/ReportNavigationCard';

export const ReportSettingsClass = () => {
  const { classId, groupId } = useParams();
  const terms = useTerms();

  return (
    <>
      <ReportNavigationStyled role="navigation">
        <ReportNavigationCard>
          <ReportNavigationCard.Content>
            <Row>
              <Col>
                <Button
                  color="secondary"
                  iconLeft={<IconChevronLeft />}
                  to={toClassReport(groupId, classId)}
                >
                  Back to report
                </Button>
              </Col>
            </Row>
          </ReportNavigationCard.Content>
        </ReportNavigationCard>
      </ReportNavigationStyled>
      <Text as="h1">{terms.class} Report Settings</Text>
      <ReportSettingsClassEnabledMetrics />
    </>
  );
};
