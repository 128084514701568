import { noop } from 'lodash';
import styled from 'styled-components';
import { Button, IconTrash } from '@perts/ui';
import theme from 'components/theme';

const RangePickerFooterContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: ${theme.units.fieldPadding};
`;

export const RangePickerFooter = ({
  buttonText = 'Delete Cycle',
  disabled = false,
  onClick = noop,
}) => (
  <RangePickerFooterContainer>
    <Button
      fullWidth
      color="secondary"
      iconLeft={<IconTrash />}
      onClick={onClick}
      disabled={disabled}
    >
      {buttonText}
    </Button>
  </RangePickerFooterContainer>
);
