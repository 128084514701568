import { User } from 'models';

export const transformUsersRemoveGroup = (
  users: User[],
  groupIdToOmit: string,
) =>
  users.map((user) => ({
    ...user,
    owned_organizations: user.owned_organizations.filter(
      (uid) => uid !== groupIdToOmit,
    ),
    pinned_organizations: user.pinned_organizations.filter(
      (uid) => uid !== groupIdToOmit,
    ),
    managed_organizations: user.managed_organizations.filter(
      (uid) => uid !== groupIdToOmit,
    ),
  }));

export const transformUsersRemoveNetwork = (users: User[], networkId: string) =>
  users.map((user) => ({
    ...user,
    owned_networks: user.owned_networks.filter((uid) => uid !== networkId),
  }));
