import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import 'polyfills';

import selectors from 'state/selectors';

import AppRouter from 'routes/AppRouter';

// TODO: antd styles are conflicting with App styles. In particular, I notice
// some fonts and link styles are changed by including this. Need to fix, but
// not sure I'll have time to do this before the Copilot 1.3 release deadline.
import 'antd/dist/antd.css';
import './App.css';

export const App = ({ userIsLoggedIn }) => (
  <AppRouter userIsLoggedIn={userIsLoggedIn} />
);

App.propTypes = {
  userIsLoggedIn: PropTypes.bool,
};

const mapStateToProps = (state, props) => ({
  userIsLoggedIn: selectors.auth.user.isLoggedIn(state, props),
});

export default connect(mapStateToProps)(App);
