// https://docs.google.com/spreadsheets/d/1ZX4hcUol-_frFLfUYRnZ-UPEvpGsVOsWsJRw1g5tDBM

import keyBy from 'lodash/keyBy';

import { locales } from '../i18n';
import { CompositeMetricConfig, MetricItemConfig } from './types';
import { catalyze24CompletelyTrueOptions } from './answerOptions/catalyze24CompletelyTrueOptions';

export const plc24AlignedGoalsItemConfig: MetricItemConfig[] = [
  {
    label: 'plc_clear_school_vision',
    likertN: 4,
    minGood: 3,
    options: catalyze24CompletelyTrueOptions,
    prompts: {
      [locales.ENGLISH]:
        'My school’s vision for high quality instruction is clear to me.',
    },
  },
  {
    label: 'plc_aligned_school_vision',
    likertN: 4,
    minGood: 3,
    options: catalyze24CompletelyTrueOptions,
    prompts: {
      [locales.ENGLISH]:
        'My PLC is aligned with my school’s vision for high quality ' +
        'instruction.',
    },
  },
  {
    label: 'plc_aligned_personal_goals',
    likertN: 4,
    minGood: 3,
    options: catalyze24CompletelyTrueOptions,
    prompts: {
      [locales.ENGLISH]:
        'My own instructional goals and the goals of my PLC are closely ' +
        'aligned.',
    },
  },
];

export const plc24AlignedGoalsConfig: CompositeMetricConfig = {
  label: 'plc-aligned-goals',
  items: plc24AlignedGoalsItemConfig,
  itemIndex: keyBy(plc24AlignedGoalsItemConfig, 'label'),
  name: 'Aligned Goals',
  description:
    'Aligned goals ensure that all members are working collaboratively ' +
    'towards common objectives, leading to greater opportunities for shared ' +
    'learning and support towards improved instructional practice.',
};
