/**
 * Allows content to be shown or not shown without unmounting the `children`.
 * The parent div of the `children` get the CSS rule `display: hidden` when the
 * `when` prop evaluates to falsy.
 *
 * One of several show/hide components: Show (hide children by not rendering),
 * Visible (hide children with `visibility: hidden`), and Display (hide children
 * with `display: none`)
 */

import styled, { css } from 'styled-components/macro';
import { theme } from '../../';

export type DisplayWrapperProps = {
  /**
   * If `true`, then display the content. If `false`, then hide the content.
   * Default `false`.
   */
  when?: boolean | number;

  /**
   * If false, hides content in print media.
   * Default `true`.
   */
  whenPrint?: boolean | number;

  /**
   * If `true`, then display the content in screens with max-width `theme.units.gridBreakpointSmall`.
   * If `false`, then hide the content.
   * Default `false`.
   */
  whenSm?: boolean | number;

  /**
   * If `true`, then display the content in screens greater `theme.units.gridBreakpointSmall`
   * and max-width `theme.units.gridBreakpointMedium`.
   * Default `false`.
   */
  whenMd?: boolean | number;

  /**
   * If `true`, then display the content in screens greater `theme.units.gridBreakpointMedium`
   * and max-width `theme.units.gridBreakpointLarge`.
   * Default `false`.
   */
  whenLg?: boolean | number;

  /**
   * If `true`, then display the content in screens greater `theme.units.gridBreakpointLarge`.
   * Default `false`.
   */
  whenXl?: boolean | number;

  display?:
    | 'contents'
    | 'block'
    | 'inline'
    | 'inline-block'
    | 'flex'
    | 'inline-flex'
    | 'none'
    | 'inherit';
};

export type DisplayProps = DisplayWrapperProps & {
  /**
   * The content to display/hide.
   */
  children: React.ReactNode;
};

const breakSm = theme.units.gridBreakpointSmall;
const breakMd = theme.units.gridBreakpointMedium;
const breakLg = theme.units.gridBreakpointLarge;

const DisplayWrapper = styled.div<DisplayWrapperProps>`
  ${({ when, display }) =>
    css`
      display: ${when ? display : 'none'};
    `}

  ${({ whenPrint, display }) =>
    css`
      @media print {
        display: ${whenPrint ? display : 'none'};
      }
    `}

  ${({ when, whenSm, whenMd, whenLg, whenXl, display }) =>
    !when &&
    css`
      @media (max-width: ${breakSm}px) {
        display: ${whenSm ? display : 'none'};
      }

      @media (min-width: ${breakSm + 1}px) and (max-width: ${breakMd}px) {
        display: ${whenMd ? display : 'none'};
      }

      @media (min-width: ${breakMd + 1}px) and (max-width: ${breakLg}px) {
        display: ${whenLg ? display : 'none'};
      }

      @media (min-width: ${breakLg + 1}px) {
        display: ${whenXl ? display : 'none'};
      }
    `}
`;

export const Display = ({
  children,
  when,
  whenPrint,
  whenSm,
  whenMd,
  whenLg,
  whenXl,
  display = 'block',
}: DisplayProps) => (
  <DisplayWrapper
    when={Boolean(when)}
    whenPrint={Boolean(whenPrint)}
    whenSm={Boolean(whenSm)}
    whenMd={Boolean(whenMd)}
    whenLg={Boolean(whenLg)}
    whenXl={Boolean(whenXl)}
    display={display}
  >
    {children}
  </DisplayWrapper>
);
