import { Program } from 'models';

export const programNameAndCohort = (program: Program) => {
  const programLabelYear = program.label.slice(-2);
  const programNextYear = parseInt(programLabelYear, 10) + 1;

  // Check if program label ends in a number/year.
  if (isNaN(programNextYear)) {
    return program.name;
  }

  return `${program.name} ’${programLabelYear}-’${programNextYear}`;
};
