import { pick } from 'lodash';

import { Card, GuideBox, HelpText, Link, Show, Text } from '@perts/ui';
import { useTerms } from 'components/TermsContext';
import { helpArticles } from 'config';
import { Group, Program } from 'models';
import { toGroupSchedulesDefaultSettings } from 'pages';
import {
  RosterSignOnLockForm,
  RosterSignOnRuleForm,
  RosterSignOnTypeForm,
  RosterTargetGroupForm,
  getDefaultValuesSignOn,
} from 'pages/Classes/shared';
import { ClassesEditParentGroupsForm } from '../ClassesEditParentGroups/ClassesEditParentGroupsForm';

type ClassesDefaultSettingsRenderType = {
  program: Program;
  group: Group;
  userIsAdmin: boolean;
  onSubmitType: (values) => void;
  onSubmitRule: (values) => void;
  onSubmitLock: (values) => void;
  onSubmitTargetGroup: (values) => void;
  onSubmitParentGroups: (values) => void;
};

export const ClassesDefaultSettingsRender = ({
  program,
  group,
  userIsAdmin,
  onSubmitType,
  onSubmitRule,
  onSubmitLock,
  onSubmitTargetGroup,
  onSubmitParentGroups,
}: ClassesDefaultSettingsRenderType) => {
  const terms = useTerms();
  const submitButtonText = `Save Settings for New ${terms.classes}`;

  const initialValues = getDefaultValuesSignOn(group);
  const initialValuesType = pick(initialValues, ['portalType']);
  const initialValuesRule = initialValues;
  const initialValuesLock = pick(initialValues, [
    'rosterLocked',
    'ruleEnabled',
  ]);
  const initialValuesTargetGroup = pick(initialValues, [
    'targetGroupEnabled',
    'targetGroupName',
  ]);
  const { parent_organizations } = initialValues;

  return (
    <>
      <GuideBox
        action={
          <Text>
            Default {terms.class.toLowerCase()} settings apply to new{' '}
            {terms.classes.toLowerCase()} in {group.name}.
          </Text>
        }
        faq={
          <HelpText articleId={helpArticles.whatAreDefaults}>
            What are default {terms.class.toLowerCase()} settings?
          </HelpText>
        }
        next={
          <Link to={toGroupSchedulesDefaultSettings(group.uid)}>
            Default schedule
          </Link>
        }
      />

      <Card>
        <Card.Content>
          <RosterSignOnTypeForm
            initialValues={initialValuesType}
            // For default settings form, don't use the default match feature.
            defaultValues={null}
            submitButtonText={submitButtonText}
            onSubmit={onSubmitType}
          />
        </Card.Content>
      </Card>

      <Card>
        <Card.Content>
          <RosterSignOnRuleForm
            initialValues={initialValuesRule}
            // For default settings form, don't use the default match feature.
            defaultValues={null}
            userIsAdmin={userIsAdmin}
            submitButtonText={submitButtonText}
            onSubmit={onSubmitRule}
          />
        </Card.Content>
      </Card>

      <Card>
        <Card.Content>
          <RosterSignOnLockForm
            initialValues={initialValuesLock}
            // For default settings form, don't use the default match feature.
            defaultValues={null}
            submitButtonText={submitButtonText}
            onSubmit={onSubmitLock}
          />
        </Card.Content>
      </Card>

      <Show when={program && program.target_group_enabled}>
        <Card>
          <Card.Content>
            <RosterTargetGroupForm
              initialValues={initialValuesTargetGroup}
              // For default settings form, don't use the default match feature.
              defaultValues={null}
              submitButtonText={submitButtonText}
              onSubmit={onSubmitTargetGroup}
            />
          </Card.Content>
        </Card>
      </Show>

      <Show when={userIsAdmin}>
        <ClassesEditParentGroupsForm
          submitButtonText={submitButtonText}
          parent_organizations={parent_organizations}
          onSubmit={onSubmitParentGroups}
        />
      </Show>
    </>
  );
};
