import type { ProgramConf } from './types';

import {
  Race7PreferenceConfig,
  Gender3Config,
  NeedsSecurityConfig,
  TargetGroupConfig,
} from '../participantAttribute';

export const programs23: ProgramConf[] = [
  {
    uid: 'Program_ascend23',
    short_uid: 'ascend23',
    label: 'ascend23',
    name: 'Ascend',
    year: 23,
    participantAttributes: [
      Gender3Config,
      NeedsSecurityConfig,
      Race7PreferenceConfig,
      TargetGroupConfig,
    ],
    settings: {
      dwDatasets: true,
      notifyParticipationDashboard: true,
      notifyReportsUpdated: true,
      notifySchedulesSet: true,
      notifySurveyInstructions: true,
    },
  },
  {
    uid: 'Program_catalyze23',
    short_uid: 'catalyze23',
    label: 'catalyze23',
    name: 'Catalyze',
    year: 23,
    participantAttributes: [
      Gender3Config,
      Race7PreferenceConfig,
      TargetGroupConfig,
    ],
    settings: {
      dwDatasets: true,
      notifyParticipationDashboard: false,
      notifyReportsUpdated: false,
      notifySchedulesSet: false,
      notifySurveyInstructions: false,
    },
  },
  {
    uid: 'Program_elevate23',
    short_uid: 'elevate23',
    label: 'elevate23',
    name: 'Elevate',
    year: 23,
    canvasProgram: {
      name: 'ELS ’24',
      label: 'els24',
    },
    participantAttributes: [
      Gender3Config,
      Race7PreferenceConfig,
      TargetGroupConfig,
    ],
    settings: {
      dwDatasets: true,
      notifyParticipationDashboard: true,
      notifyReportsUpdated: true,
      notifySchedulesSet: true,
      notifySurveyInstructions: true,
    },
  },
  {
    uid: 'Program_sesTeacher23',
    short_uid: 'sesTeacher23',
    label: 'sesTeacher23',
    name: 'Teacher Pre/Post',
    year: 23,
    participantAttributes: [
      Gender3Config,
      Race7PreferenceConfig,
      TargetGroupConfig,
    ],
    settings: {
      dwDatasets: true,
      notifyParticipationDashboard: false,
      notifyReportsUpdated: false,
      notifySchedulesSet: false,
      notifySurveyInstructions: false,
    },
  },
  {
    uid: 'Program_sesStudent23',
    short_uid: 'sesStudent23',
    label: 'sesStudent23',
    name: 'Student Pre/Post',
    year: 23,
    participantAttributes: [
      Gender3Config,
      Race7PreferenceConfig,
      TargetGroupConfig,
    ],
    settings: {
      dwDatasets: true,
      notifyParticipationDashboard: false,
      notifyReportsUpdated: false,
      notifySchedulesSet: false,
      notifySurveyInstructions: false,
    },
  },
];
