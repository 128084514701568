import moment from 'moment';

import { getMessageFromErrors } from '@perts/util';
import Loading from 'components/Loading';
import {
  isClassDefault21Dataset,
  useClassGetById,
  useGroupGetByParams,
  useProgramsQuery,
  useReportGetById,
  useDatasetGetById,
  useMetricsQuery,
} from 'models';
import { useParams } from 'pages';
import { dateFormat } from 'config';
import { ErrorMessageBox } from 'components/ErrorMessageBox';

import { getReportMetrics } from '../components';

// eslint-disable-next-line complexity
export const ClassReportLoader = ({ children }) => {
  const { classId, reportId } = useParams();

  const groupQuery = useGroupGetByParams();
  const metricsQuery = useMetricsQuery();
  const programsQuery = useProgramsQuery();
  const reportQuery = useReportGetById(reportId);
  const classQuery = useClassGetById(classId, {
    enabled: Boolean(reportQuery.isSuccess),
  });
  const datasetQuery = useDatasetGetById(reportQuery.data?.dataset_id, {
    enabled: Boolean(reportQuery.isSuccess),
  });

  if (
    classQuery.isLoading ||
    datasetQuery.isLoading ||
    groupQuery.isLoading ||
    metricsQuery.isLoading ||
    programsQuery.isLoading ||
    reportQuery.isLoading
  ) {
    return <Loading />;
  }

  if (
    !classQuery.isSuccess ||
    !datasetQuery.isSuccess ||
    !groupQuery.isSuccess ||
    !metricsQuery.isSuccess ||
    !programsQuery.isSuccess ||
    !reportQuery.isSuccess
  ) {
    return (
      <ErrorMessageBox>
        {getMessageFromErrors([
          classQuery.error,
          datasetQuery.error,
          groupQuery.error,
          metricsQuery.error,
          programsQuery.error,
          reportQuery.error,
        ])}
      </ErrorMessageBox>
    );
  }

  const allMetrics = metricsQuery.data;
  const cls = classQuery.data;
  const dataset = datasetQuery.data;
  const group = groupQuery.data;
  const report = reportQuery.data;
  const program = programsQuery.data.find((p) => p.uid === cls.program_id);
  if (!program) {
    throw new Error(`Couldn't find program for team: ${cls.program_id}`);
  }

  if (!isClassDefault21Dataset(dataset)) {
    // TODO: style this
    return <>There's a problem with this report: dataset type error.</>;
  }

  const programMetricIds = program.metrics.map(({ uid }) => uid);
  const programMetrics = allMetrics.filter(({ uid }) =>
    programMetricIds.includes(uid),
  );

  const reportMetrics = getReportMetrics(dataset, allMetrics);

  const reportDate = moment(report.issue_date).format(dateFormat);

  return children({
    cls,
    dataset,
    group,
    program,
    programMetrics,
    reportDate,
    reportMetrics,
  });
};
