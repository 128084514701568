import styled from 'styled-components/macro';
import { Col, Link, Row, Show, theme } from '@perts/ui';

import { toGroupReports, useParams } from 'pages';
import { useTerms } from 'components/TermsContext';

const ArchivedWarningStyled = styled.div`
  font-size: 1.125rem;
  background-color: ${theme.colors.yellow};
  border-radius: ${theme.units.borderRadius};
  color: ${theme.colors.black};
  text-align: center;

  padding: ${theme.units.bodyPadding};

  @media (max-width: ${theme.units.gridBreakpointMedium}px),
    (max-height: ${theme.units.gridBreakpointMedium}px) {
    padding: ${theme.units.bodyPaddingMd};
  }

  @media (max-width: ${theme.units.gridBreakpointSmall}px),
    (max-height: ${theme.units.gridBreakpointSmall}px) {
    padding: ${theme.units.bodyPaddingSm};
  }

  a {
    color: ${theme.colors.secondary};
    text-decoration: underline;
  }
`;

type Props = {
  isReport?: boolean;
};

export const ArchivedWarning = ({ isReport = false }: Props) => {
  const { groupId } = useParams();
  const terms = useTerms();

  return (
    <Row>
      <Col>
        <ArchivedWarningStyled>
          You are viewing an <strong>archived</strong>{' '}
          {terms.group.toLowerCase()}.
          <Show when={!isReport}>
            <br />
            Some features are disabled. You may{' '}
            <Link to={toGroupReports(groupId)}>view reports</Link>.
          </Show>
        </ArchivedWarningStyled>
      </Col>
    </Row>
  );
};
