import { GuideBox, HelpText } from '@perts/ui';
import { helpArticles } from 'config';

export const ReportsGuideBox = () => (
  <GuideBox
    action={null}
    faq={
      <HelpText articleId={helpArticles.cantFindMyReports}>
        I can’t find my reports, or they didn’t update.
      </HelpText>
    }
  />
);
