import styled, { css } from 'styled-components';
import { Link } from '@perts/ui';

import theme from 'components/theme';

type StyledProps = {
  active?: boolean;
  disabled?: boolean;
};

export const PageNavLinkStyled = styled.span<StyledProps>`
  padding: 3px;
  display: flex;
  align-items: center;

  color: ${theme.colors.grayDark};
  cursor: pointer;

  ${(props) =>
    props.active &&
    css`
      font-weight: ${theme.units.boldWeight};
      color: ${theme.palette.white};
      background-color: ${theme.palette.secondaryDark};
      border-radius: ${theme.units.borderRadius};

      a,
      a:hover {
        color: ${theme.palette.white};
      }
    `};

  ${(props) =>
    props.disabled &&
    css`
      color: ${theme.colors.grayMedium};
      cursor: default;
      pointer-events: none;
    `};
`;

// We haven't typed the Link component yet, and it's complex enough that I
// don't want to right now.
const UntypedLink = Link as any;

const PageNavLink = ({ to, active, disabled, children, ...props }) => (
  <PageNavLinkStyled disabled={disabled} active={active}>
    <UntypedLink {...props} to={to} disabled={disabled}>
      {children}
    </UntypedLink>
  </PageNavLinkStyled>
);

export default PageNavLink;
