import styled from 'styled-components/macro';

export type CardContentProps = {
  // The content to be rendered.
  children: React.ReactNode;
};

export const CardContentStyled = styled.div<CardContentProps>`
  flex-grow: 1;
  width: 100%;

  @media print {
    button {
      display: none;
    }
  }
`;

export const CardContent = ({ children }: CardContentProps) => {
  return <CardContentStyled>{children}</CardContentStyled>;
};

CardContent.displayName = 'CardContent';
