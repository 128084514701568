import { useQueryClient, useMutation } from 'react-query';
import {
  User,
  Group,
  queryKeyCurrentUser,
  queryKeyGroupsByProgram,
  useCurrentUserUpdate,
  useProgramId,
} from 'models';
import { message } from 'antd';
import { GroupPinForm } from './GroupPinForm';

export type GroupPinProps = {
  user: User;
  group: Group;
  queryKeyParams: {
    name: string;
    pageIndex: number;
  };
};

export const GroupPin = ({ user, group, queryKeyParams }: GroupPinProps) => {
  const programId = useProgramId();
  const queryClient = useQueryClient();
  const queryUpdateFn = useCurrentUserUpdate();
  const queryKeyUser = queryKeyCurrentUser();

  const queryKeyGroups = queryKeyGroupsByProgram(programId || '', {
    pinned: true,
    ...queryKeyParams,
  });

  // Customize onSuccess/onError messages based on whether the user is pinning
  // or unpinning a community.
  const toPin = !user.pinned_organizations.includes(group.uid);
  const toPinTerm = toPin ? 'Pin' : 'Unpin';

  const onSuccess = (_data) => {
    message.success(`${toPinTerm}ned ${group.name}.`);
  };

  const onError = (err: Error, _values, context) => {
    if (context?.previousUser) {
      queryClient.setQueryData<User>(queryKeyUser, context.previousUser);
    }

    if (context?.previousGroups) {
      queryClient.setQueryData<Group[]>(queryKeyGroups, context.previousGroups);
    }

    message.error(
      `Had trouble ${toPinTerm.toLowerCase()}ning ${
        group.name
      }. Please try again.`,
    );
  };

  // react-query mutation function
  const mutation = useMutation(
    async (values: User) => {
      await queryUpdateFn(values);
    },
    {
      onMutate: async (values: User) => {
        // Cancel any outgoing refetches.
        //   (So they don't overwrite our optimistic update.)
        await queryClient.cancelQueries(queryKeyUser);
        await queryClient.cancelQueries(queryKeyGroups);

        // Snapshot previous.
        const previousUser = queryClient.getQueryData<User>(queryKeyUser);
        const previousGroups =
          queryClient.getQueryData<Group[]>(queryKeyGroups) || [];

        // Optimistically update user.
        queryClient.setQueryData<User>(queryKeyUser, values);

        // Which groups are pinned is calculated on client based on the
        // pinned_organizations property of user, so no optimistic updates on
        // the groups needs to occur here.

        // Return a context object with the snapshot value.
        return { previousUser, previousGroups };
      },

      // Handle successful mutation.
      onSuccess,

      // If the mutation fails, use the context returned to roll back.
      onError,

      // Always refetch after an error or success.
      onSettled: () => {
        queryClient.invalidateQueries(queryKeyUser);
        queryClient.invalidateQueries(queryKeyGroups);
      },
    },
  );

  // Formik onSubmit handler
  const onSubmit = async (values: User) => {
    await mutation.mutateAsync(values);
  };

  return <GroupPinForm user={user} group={group} onSubmit={onSubmit} />;
};
