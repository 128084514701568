import { groupBy, map } from 'lodash';

import {
  Cycle,
  Program,
  queryKeyCyclesByGroup,
  updateCycles,
  useMutationUpdates,
} from 'models';
import { useParams } from 'pages';
import { ClassWithCycles } from '../';

import {
  SchedulesEditCyclesForm,
  SchedulesEditCyclesFormValues,
} from './SchedulesEditCyclesForm';

export type SchedulesEditCyclesProps = {
  classes: ClassWithCycles[];
  program: Program;
  mayAddCycles: boolean;
  mayUpdateCycles: boolean;
  mayEditDefaultSettings: boolean;
};

export const SchedulesEditCycles = ({
  classes,
  program,
  mayAddCycles,
  mayUpdateCycles,
  mayEditDefaultSettings,
}: SchedulesEditCyclesProps) => {
  const { groupId } = useParams();
  const queryKey = queryKeyCyclesByGroup(groupId);

  const mutation = useMutationUpdates<Cycle, SchedulesEditCyclesFormValues>(
    // @ts-ignore
    async (cycles: Cycles[]) => {
      // We need to perform the updates in batches by cycle.team_id.
      const cyclesByTeamId = groupBy(cycles, 'team_id');

      await Promise.all(
        map(cyclesByTeamId, (teamCycles, teamId) =>
          updateCycles(teamCycles, teamId),
        ),
      );
    },
    queryKey,
    {
      optimisticallyUpdate: true,
      transformFormValues: (formValues) => formValues.cycles,
      toastSuccessMsg: 'Successfully updated cycles.',
      toastErrorMsg: 'Unable to update cycles.',
    },
  );

  const onSubmit = async ({ cycles }: SchedulesEditCyclesFormValues) => {
    await mutation.mutateAsync({ cycles });
  };

  return (
    <SchedulesEditCyclesForm
      classes={classes}
      onSubmit={onSubmit}
      program={program}
      mayAddCycles={mayAddCycles}
      mayUpdateCycles={mayUpdateCycles}
      mayEditDefaultSettings={mayEditDefaultSettings}
    />
  );
};
