import { OrganizationData, OrganizationUserData } from '@perts/model';
import { Card, HelpText, Text } from '@perts/ui';

import { helpArticles } from 'config';
import { User } from 'models';
import { useTerms } from 'components/TermsContext';
import { ClassParticipationTable } from 'components/ClassParticipationTable';

type Props = {
  dataset: OrganizationData | OrganizationUserData;
  users: User[];
};

export const DashboardClassParticipation = ({ dataset, users }: Props) => {
  const terms = useTerms();

  return (
    <Card>
      <Card.Title>
        <Text as="h2">Participation by {terms.class}</Text>
        <HelpText articleId={helpArticles.participationHowToUseCommunity}>
          How to use this dashboard
        </HelpText>
      </Card.Title>
      <Card.Content>
        <ClassParticipationTable
          classRespondentsByCycle={dataset.team_respondents_by_cycle}
          users={users}
        />
      </Card.Content>
    </Card>
  );
};
