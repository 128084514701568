import {
  Group,
  queryKeyGroup,
  selectUserIsAdmin,
  selectUserIsGroupManager,
  updateGroup,
  useCurrentUserQuery,
  useGroupGetByParams,
  useMutationUpdate,
  useProgramGetByParams,
} from 'models';
import { useParams } from 'pages';

import { getMessageFromErrors } from '@perts/util';

import Loading from 'components/Loading';
import { ErrorMessageBox } from 'components/ErrorMessageBox';
import { CycleType } from './SchedulesDefaultSettingsForm';
import { SchedulesDefaultSettingsRender } from './SchedulesDefaultSettingsRender';

export const SchedulesDefaultSettings = () => {
  const { groupId } = useParams();
  const queryKey = queryKeyGroup(groupId);

  const userQuery = useCurrentUserQuery();

  const groupQuery = useGroupGetByParams();

  const programQuery = useProgramGetByParams();

  // Mutation: Update Group schedules default settings
  const mutation = useMutationUpdate<Group, Group>(updateGroup, queryKey, {
    optimisticallyUpdate: true,
    transformFormValues: (formValues) => formValues,
    toastSuccessMsg: `Successfully updated schedules default settings.`,
    toastErrorMsg: `There was a problem schedules default settings.`,
  });

  // Display loading.
  if (userQuery.isLoading || groupQuery.isLoading || !programQuery.isSuccess) {
    return <Loading />;
  }

  // Display any errors.
  if (!groupQuery.isSuccess || !userQuery.isSuccess || !programQuery.data) {
    return (
      <ErrorMessageBox>
        {getMessageFromErrors([groupQuery.error, userQuery.error])}
      </ErrorMessageBox>
    );
  }

  const currentGroup = groupQuery.data;

  const currentDefaultSettings = currentGroup.default_settings;

  const onSubmit = async (values: CycleType[]) => {
    let updatedDefaultSettings = {
      ...currentDefaultSettings,
    };

    // If new value to schedules is empty,
    // we'll remove the prop of default settings
    if (values.length === 0) {
      delete updatedDefaultSettings.schedules;
    } else {
      updatedDefaultSettings = {
        ...currentDefaultSettings,
        schedules: values,
      };
    }

    const updatedGroup = {
      ...currentGroup,
      default_settings: updatedDefaultSettings,
    };
    await mutation.mutateAsync(updatedGroup);
  };

  const userIsManager = selectUserIsGroupManager(userQuery.data, groupId);
  const userIsAdmin = selectUserIsAdmin(userQuery.data);

  const mayEditDefaultSettings = userIsManager || userIsAdmin;

  if (!mayEditDefaultSettings) {
    return (
      <ErrorMessageBox>
        You don&rsquo;t have access to this page.
      </ErrorMessageBox>
    );
  }

  return (
    <SchedulesDefaultSettingsRender
      group={currentGroup}
      onSubmit={onSubmit}
      program={programQuery.data}
    />
  );
};
