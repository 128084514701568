import React from 'react';

import { StudentExperienceOverviewRow } from 'models';

// What's in props?
// https://github.com/tannerlinsley/react-table/blob/master/docs/api/useTable.md#user-content-column-object-cell

// What's in a row?
// https://github.com/tannerlinsley/react-table/blob/master/docs/api/useTable.md#user-content-row-properties

type CellProps = {
  row: { original: StudentExperienceOverviewRow };
};

export const CellRatedPositivelyAll = ({ row: { original } }: CellProps) => (
  <>{original.rated_positive_recent_all}</>
);
