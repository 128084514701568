import { findIndex, findLastIndex } from 'lodash';

import { isRealNum } from '@perts/util';

/**
 * Given an array of numbers representing data collected in successive cycles,
 * calculate the cumulative change or net difference over all data. Typically
 * this is the last value minus the first value, but this also handles cases
 * where there's no available (a null value) for some cycles.
 * @param {Array} cycleValues scores in cycles of data collection
 * @returns {number} cumulative change in scores.
 */
export const getCumulativeChange = (cycleValues: (number | null)[]) => {
  if (cycleValues.length <= 1) {
    return NaN;
  }

  const firstIndex = findIndex(cycleValues, isRealNum);
  const lastIndex = findLastIndex(cycleValues, isRealNum);
  const firstValue = cycleValues[firstIndex];
  const lastValue = cycleValues[lastIndex];

  const onlyOneValue = firstIndex === lastIndex;

  if (
    onlyOneValue ||
    typeof firstValue !== 'number' ||
    typeof lastValue !== 'number'
  ) {
    return NaN;
  }

  // We're typically dealing with percentages as decimals, like 0.64 for 64%.
  // The problem with doing addition and subtraction with them is that
  // JavaScript will often use floating points and wind up with things like
  // 0.7 - 0.64 = 0.05999999999999994. To avoid this, move the numbers into the
  // integers (where JS will not use floating points), find the difference, and
  // then convert back to decimal.
  return (Math.round(lastValue * 100) - Math.round(firstValue * 100)) / 100;
};
