// https://docs.google.com/spreadsheets/d/1ZX4hcUol-_frFLfUYRnZ-UPEvpGsVOsWsJRw1g5tDBM

import keyBy from 'lodash/keyBy';

import { CompositeMetricConfig, MetricItemConfig } from './types';
import { catalyze23AnswerOptions } from './answerOptions';

export const effectiveSystemsItemConfig: MetricItemConfig[] = [
  {
    label: 'effective_systems_information',
    likertN: 5,
    minGood: 4,
    options: catalyze23AnswerOptions,
    prompts: {
      en: 'The systems of communication ensure I have the information I need.',
    },
  },
  {
    label: 'effective_systems_resources',
    likertN: 5,
    minGood: 4,
    options: catalyze23AnswerOptions,
    prompts: {
      en: 'The resources I need to do my job well are provided to me.',
    },
  },
  {
    label: 'effective_systems_collab',
    likertN: 5,
    minGood: 4,
    options: catalyze23AnswerOptions,
    prompts: {
      en: 'There are systems in place to facilitate productive collaboration.',
    },
  },
  {
    label: 'effective_systems_feedback',
    likertN: 5,
    minGood: 4,
    options: catalyze23AnswerOptions,
    prompts: {
      en: 'There are systems in place to ensure staff receive regular, constructive feedback on their work.',
    },
  },
  {
    label: 'effective_systems_balance',
    likertN: 5,
    minGood: 4,
    options: catalyze23AnswerOptions,
    prompts: {
      en: 'There are systems in place to support an appropriate work-life balance.',
    },
  },
];

export const effectiveSystemsConfig: CompositeMetricConfig = {
  label: 'effective-systems',
  items: effectiveSystemsItemConfig,
  itemIndex: keyBy(effectiveSystemsItemConfig, 'label'),
};
