import { useEffect } from 'react';

// Adapted from https://stackoverflow.com/a/64352116/385132
export const useTitle = (title: string) => {
  useEffect(() => {
    const prevTitle = document.title;
    document.title = title;
    return () => {
      document.title = prevTitle;
    };
  });
};
