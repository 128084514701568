import { useContext } from 'react';

import {
  Class,
  classesRemoveFromGroup,
  queryKeyClassesByGroup,
  useClassesQueryByParams,
  useMutationDelete,
} from 'models';
import { useParams } from 'pages';
import { useCloseModal, useGetCheckedStates } from 'utils';

import {
  ClassesRemoveForm,
  ClassesRemoveFormValues,
} from './ClassesRemoveForm';
import Loading from 'components/Loading';
import TermsContext from 'components/TermsContext';
import { ErrorMessageBox } from 'components/ErrorMessageBox';

import { getMessageFromErrors } from '@perts/util';

export const ClassesRemove = () => {
  const { groupId } = useParams();
  const queryKey = queryKeyClassesByGroup(groupId);

  const checked = useGetCheckedStates();
  const terms = useContext(TermsContext);

  // Close the modal.
  const closeModal = useCloseModal();

  // Query for Classes of Group.
  const {
    isLoading: classesIsLoading,
    data: classes = [],
    isError: classesIsError,
    error: classesError,
  } = useClassesQueryByParams();

  // Determine classes that have been selected.
  const classesToRemove: Class[] = classes.filter((cls) => checked[cls.uid]);

  const mutation = useMutationDelete<Class>(
    classesRemoveFromGroup,
    classesToRemove,
    queryKey,
    {
      waitForSuccess: true,
      toastSuccessMsg: `Successfully deleted the ${terms.class.toLowerCase()}(es).`,
      toastErrorMsg:
        `There was a problem deleting the ` +
        `${terms.class.toLowerCase()}(es).`,
    },
  );

  // https://stackoverflow.com/questions/65760158/react-query-mutation-typescript
  // Formik onSubmit handler
  const onSubmit = async (values: ClassesRemoveFormValues) => {
    await mutation.mutateAsync(values);
  };

  // Display loading.
  const isLoading = classesIsLoading;

  if (isLoading) {
    return <Loading />;
  }

  // Display errors.
  if (classesIsError) {
    return (
      <ErrorMessageBox>{getMessageFromErrors([classesError])}</ErrorMessageBox>
    );
  }

  return (
    <ClassesRemoveForm
      close={closeModal}
      onSubmit={onSubmit}
      classesToRemove={classesToRemove}
    />
  );
};
