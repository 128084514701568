import styled from 'styled-components';

import { Show } from '@perts/ui';

import FakeLink from 'components/FakeLink';

// Can't toggle states in a printout, so hide it.
export const ToggleChangeStyled = styled.p`
  @media print {
    display: none;
  }
`;

type Props = {
  showChange: boolean;
  setShowChange: (show: boolean) => void;
};

export const ToggleChange = ({ showChange, setShowChange }: Props) => (
  <ToggleChangeStyled>
    <FakeLink onClick={() => setShowChange(!showChange)}>
      <Show when={showChange}>Hide ± change</Show>
      <Show when={!showChange}>Show ± change</Show>
    </FakeLink>{' '}
    since survey 1
  </ToggleChangeStyled>
);
