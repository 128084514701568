import styled from 'styled-components/macro';
import { css } from 'styled-components';
import theme from '../theme';

// Styles heavily influenced by Quasar's Grid Row:
// https://quasar.dev/layout/grid/row

type RowProps = {
  alignItems?:
    | 'center'
    | 'flex-start'
    | 'flex-end'
    | 'stretch'
    | 'baseline'
    | 'initial'
    | 'inherit';
  justifyContent?:
    | 'center'
    | 'flex-start'
    | 'flex-end'
    | 'space-between'
    | 'space-around'
    | 'initial'
    | 'inherit';

  sm?: boolean;
  md?: boolean;
  lg?: boolean;

  noPageBreakInside?: boolean;
};

export const Row = styled.div<RowProps>`
  // Ensure Row will expand when a child of another display: flex element.
  flex-grow: 1;
  // Ensure Row will expand when a child of another Row > Col.
  min-width: 100%;

  display: flex;
  flex-wrap: wrap;

  ${(props) =>
    props.alignItems &&
    css`
      align-items: ${props.alignItems};
    `};
  ${(props) =>
    props.justifyContent &&
    css`
      justify-content: ${props.justifyContent};
    `};

  margin-top: -${(props) => props.theme.units.gridDefaultGutter}px;
  margin-left: -${(props) => props.theme.units.gridDefaultGutter}px;
  :not(:last-child) {
    margin-bottom: ${(props) => props.theme.units.gridDefaultGutter}px;
  }

  /* Padding for narrower/mobile screens. */
  @media only screen and (max-width: ${(props) =>
      props.theme.units.gridBreakpointSmall}px) {
    margin-top: -${(props) => props.theme.units.gridDefaultGutter / 2}px;
    margin-left: -${(props) => props.theme.units.gridDefaultGutter / 2}px;
    :not(:last-child) {
      margin-bottom: ${(props) => props.theme.units.gridDefaultGutter / 2}px;
    }
  }

  > * {
    padding-top: ${(props) => props.theme.units.gridDefaultGutter}px;
    padding-left: ${(props) => props.theme.units.gridDefaultGutter}px;

    /* Padding for narrower/mobile screens. */
    @media only screen and (max-width: ${(props) =>
        props.theme.units.gridBreakpointSmall}px) {
      padding-top: ${(props) => props.theme.units.gridDefaultGutter / 2}px;
      padding-left: ${(props) => props.theme.units.gridDefaultGutter / 2}px;
    }
  }

  @media print {
    ${(props) =>
      props.noPageBreakInside &&
      css`
        break-inside: avoid !important;
        page-break-inside: avoid !important;
      `}
  }
`;

Row.displayName = 'Row';

Row.defaultProps = {
  theme,
};
