import { Show, Card } from '@perts/ui';
import { formatPercentagePoints } from '@perts/util';
import { HighlightedStat, HighlightedText } from './HighlightedStat';
import { useTerms } from 'components';

type MetricSectionHighlightProps = {
  name: string;
  cumulativeChange: number;
};

export const MetricSectionHighlight = ({
  name,
  cumulativeChange,
}: MetricSectionHighlightProps) => {
  const terms = useTerms();

  if (cumulativeChange <= 0) {
    return null;
  }

  return (
    <Card highlight highlightWithin>
      <Card.Content>
        <HighlightedText>
          <div>
            <Show when={cumulativeChange > 0}>
              <HighlightedStat good>
                +{formatPercentagePoints(cumulativeChange)} points
              </HighlightedStat>{' '}
              <strong>improvement in {name} since starting</strong>
            </Show>
          </div>

          <Show when={cumulativeChange >= 0.15}>
            <strong>Amazing progress!</strong> More of your{' '}
            {terms.participants.toLowerCase()} are experiencing{' '}
            {name.toLowerCase()}.
          </Show>

          <Show when={cumulativeChange >= 0.05 && cumulativeChange < 0.15}>
            <strong>Great progress!</strong> More of your{' '}
            {terms.participants.toLowerCase()} are experiencing{' '}
            {name.toLowerCase()}.
          </Show>

          <Show when={cumulativeChange < 0.05 && cumulativeChange > 0}>
            <strong>Keep it up!</strong> More of your{' '}
            {terms.participants.toLowerCase()} are experiencing{' '}
            {name.toLowerCase()}.
          </Show>
        </HighlightedText>
      </Card.Content>
    </Card>
  );
};
