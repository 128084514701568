// https://docs.google.com/spreadsheets/d/1EKKzZjI-eCtekB_S_SsNnBQpGEKVIKueZIkBD6W8QJc

import keyBy from 'lodash/keyBy';

import { locales } from '../i18n';
import { CompositeMetricConfig, MetricItemConfig } from './types';

export const schoolIdentitySafetyItemConfig: MetricItemConfig[] = [
  {
    label: 'school_identity_safety_well',
    likertN: 6,
    likertReverse: true,
    minGood: 5,
    prompts: {
      [locales.ENGLISH]: `People here would be surprised if I did well in school.`,
      [locales.SPANISH]: `La gente aquí se sorprendería si me fuera bien en la escuela.`,
    },
  },
];

export const schoolIdentitySafetyConfig: CompositeMetricConfig = {
  label: 'school-identity-safety',
  items: schoolIdentitySafetyItemConfig,
  itemIndex: keyBy(schoolIdentitySafetyItemConfig, 'label'),
};
