import { useState } from 'react';
import {
  Metric,
  MetricSet,
  MetricSetsWithMetrics,
  sortAlphaByName,
  sortMetricSets,
} from 'models';

type UseMetricsDataForm = {
  isChecked: (key: string) => boolean;
  isIndeterminate: (key: string) => boolean;
  metrics: Metric[];
  metricSets: MetricSet[];
};

export const useMetricsDataForm = ({
  isChecked,
  isIndeterminate,
  metrics,
  metricSets,
}: UseMetricsDataForm) => {
  // Sort Metric Sets and embed the Set's Metrics (`metrics` property).
  const metricSetsWithMetrics: MetricSetsWithMetrics[] = sortMetricSets(
    metricSets,
  ).map((metricSet) => ({
    ...metricSet,
    metrics: metricSet.metric_ids
      .map((metricUid) => metrics.find((m) => m.uid === metricUid))
      .filter((m): m is Metric => m !== undefined)
      .sort(sortAlphaByName),
    isActive: metricSet.metric_ids.some(
      (metricUid) => isChecked(metricUid) || isIndeterminate(metricUid),
    ),
  }));

  // Create select options from specific metric sets.
  const selectMetricSetOptions = metricSetsWithMetrics.map(({ uid, name }) => ({
    uid,
    name,
    metric_set_ids: [uid],
  }));

  // If there is more than one metric set associated with the program, then...
  const isMetricSetsFeature = metricSetsWithMetrics.length > 1;

  // ...add a select option to view Active Metric Sets.
  if (isMetricSetsFeature) {
    selectMetricSetOptions.unshift(
      // Show Active Metric Sets
      {
        uid: 'MetricSet_ActiveMetricSets',
        name: 'Active Sets',
        metric_set_ids: metricSetsWithMetrics
          .filter((m) => m.isActive)
          .map((m) => m.uid),
      },
    );
  }

  if (selectMetricSetOptions.length === 0) {
    throw new Error('No metric set options to view.');
  }

  const [view, setView] = useState<string>(selectMetricSetOptions[0].uid);
  const isViewingActive =
    isMetricSetsFeature && view === selectMetricSetOptions[0].uid;

  const selectedOption = selectMetricSetOptions.find(({ uid }) => uid === view);

  const selectedMetricSets = metricSetsWithMetrics.filter((metricSet) =>
    selectedOption?.metric_set_ids.includes(metricSet.uid),
  );

  return {
    isMetricSetsFeature,
    selectMetricSetOptions,
    view,
    setView,
    isViewingActive,
    selectedMetricSets,
    allMetricSets: metricSetsWithMetrics,
  };
};
