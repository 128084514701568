import { useContext } from 'react';

import { programs as programConfs } from '@perts/config';

import { Group } from 'models';
import {
  toHomeGroupsArchive,
  toHomeGroupsCreateCourse,
  toHomeGroupsRemove,
  toHomeGroupsCopy,
} from 'pages';
import { useOpenModal, usePermissions } from 'utils';

import {
  Dropdown,
  IconArchive,
  IconCube,
  IconCopy,
  IconTrash,
  IconCaretUp,
  Show,
} from '@perts/ui';
import { getShortUid } from '@perts/util';
import TermsContext from 'components/TermsContext';

type HomeGroupDropdownProps = {
  group: Group;
  onCollapse?: () => void;
};

export const HomeGroupDropdown = ({
  group,
  onCollapse,
}: HomeGroupDropdownProps) => {
  const openModal = useOpenModal();
  const terms = useContext(TermsContext);
  const { isAdmin } = usePermissions();

  const programConf = programConfs.find((c) => c.uid === group.program_id);

  // Must be a super admin, the group may not already have a course, and the
  // current program must be configured to have canvas features available.
  const mayCreateCourse = Boolean(
    isAdmin && !group.course_id && programConf?.canvasProgram,
  );

  return (
    <Dropdown aria-label={`Card group ${group.name} dropdown`}>
      <Show when={isAdmin}>
        <Dropdown.Item
          onClick={() => {
            openModal(toHomeGroupsCopy(getShortUid(group.program_id)), {
              groupId: group.short_uid,
            });
          }}
        >
          <IconCopy />
          <span>Copy {terms.group}</span>
        </Dropdown.Item>
      </Show>

      <Dropdown.Item
        onClick={() => {
          openModal(
            toHomeGroupsArchive(getShortUid(group.program_id), group.short_uid),
          );
        }}
      >
        <IconArchive />
        <span>
          <Show when={!group.archived}>Archive {terms.group}</Show>
          <Show when={group.archived}>Unarchive {terms.group}</Show>
        </span>
      </Dropdown.Item>

      <Show when={mayCreateCourse}>
        <Dropdown.Item
          onClick={() => {
            openModal(
              toHomeGroupsCreateCourse(
                getShortUid(group.program_id),
                group.short_uid,
              ),
            );
          }}
        >
          <IconCube />
          Create {programConf?.canvasProgram?.name} Course
        </Dropdown.Item>
      </Show>

      <Dropdown.Item
        onClick={() => {
          openModal(
            toHomeGroupsRemove(getShortUid(group.program_id), group.short_uid),
          );
        }}
      >
        <IconTrash />
        <span>Delete {terms.group}</span>
      </Dropdown.Item>

      <Show when={Boolean(onCollapse)}>
        <Dropdown.Item onClick={onCollapse}>
          <IconCaretUp />
          <span>Collapse card</span>
        </Dropdown.Item>
      </Show>
    </Dropdown>
  );
};
