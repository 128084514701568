import styled, { css } from 'styled-components/macro';
import { theme } from 'components';

export const ReactTableTr = styled.tr<{
  isFirstGroupRow?: boolean;
  isLastGroupRow?: boolean;
}>`
  ${(props) =>
    (props.isFirstGroupRow === undefined || props.isFirstGroupRow) &&
    css`
      border-top: 1px solid ${theme.colors.grayLight};

      td {
        padding-top: ${theme.units.paddingMd};
      }
    `};

  ${(props) =>
    (props.isLastGroupRow === undefined || props.isLastGroupRow) &&
    css`
      td {
        padding-bottom: ${theme.units.paddingMd};
      }
    `};
`;
