import { Show } from '@perts/ui';

import { Program } from 'models';
import { ClassWithCycles } from './';
import { SchedulesEditCycles } from './SchedulesEditCycles';
import { SchedulesGuideBox } from './SchedulesGuideBox';
import { ArchivedWarning } from 'components/ArchivedWarning';
import { EmptyList } from 'components/EmptyList';

export type SchedulesRenderProps = {
  archived: boolean;
  classes: ClassWithCycles[];
  program: Program;
  mayAddCycles: boolean;
  mayUpdateCycles: boolean;
  mayEditDefaultSettings: boolean;
};

export const SchedulesRender = ({
  archived,
  classes = [],
  program,
  mayAddCycles,
  mayUpdateCycles,
  mayEditDefaultSettings,
}: SchedulesRenderProps) => (
  <>
    <Show when={archived}>
      <ArchivedWarning />
    </Show>
    <SchedulesEditCycles
      classes={classes}
      program={program}
      mayAddCycles={mayAddCycles}
      mayUpdateCycles={mayUpdateCycles}
      mayEditDefaultSettings={mayEditDefaultSettings}
    />

    <Show when={classes.length === 0}>
      <EmptyList />
      <SchedulesGuideBox isClasses={false} />
    </Show>
  </>
);
