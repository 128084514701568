import React from 'react';
import { SelectFilterStyles } from '@perts/ui/src/components/SelectFilter/SelectFilterStyles';
import { SelectFilterTag } from '@perts/ui/src/components/SelectFilter/SelectFilterTags/SelectFilterTag';

type SortByDirectionToggleProps = {
  value: {
    name: string;
    value: string;
  };
  toggle: () => void;
};

export const SortByDirectionToggle: React.FC<SortByDirectionToggleProps> = ({
  value,
  toggle,
}) => (
  <SelectFilterStyles>
    <button onClick={toggle}>
      <SelectFilterTag label={value.name} />
    </button>
  </SelectFilterStyles>
);

export default SortByDirectionToggle;
