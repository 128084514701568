import React, { useMemo } from 'react';

import type { TargetGroupTableRow } from '@perts/model';

import { Table } from './Table';
import { useTerms } from 'components/TermsContext';

// // Retained so we can use consistent styles if subheadings are re-introduced.
// const SubHeader = styled.span`
//   display: block;
//   font-weight: normal;
//   color: ${theme.colors.grayLight};
// `;

type Props = {
  data: TargetGroupTableRow[];
};

export const TargetGroupTable = ({ data }: Props) => {
  const terms = useTerms();

  // https://react-table.tanstack.com/docs/quick-start for details on useMemo
  const columns = useMemo(
    () => [
      {
        Header: <>{terms.class}</>,
        id: 'team_name',
        accessor: 'team_name',
      },
      {
        Header: <>Focal Group Name</>,
        id: 'target_group_name',
        accessor: 'target_group_name',
      },
      {
        Header: <># Enrolled</>,
        id: 'participants_n',
        accessor: 'participants_n',
      },
    ],
    [terms],
  );

  // Don't memoize data b/c it's hard-coded by the server

  return <Table columns={columns} data={data} />;
};
