import { message } from 'antd';
import { uniqWith } from 'lodash';
import { useQueryClient, useMutation } from 'react-query';

import {
  User,
  queryKeyCurrentUser,
  queryKeyUsersByGroup,
  updateUsers,
  useCurrentUserQuery,
  useGroupGetByParams,
  useUsersQueryByParams,
} from 'models';
import { useParams } from 'pages';
import { useCloseModal, useGetCheckedStates } from 'utils';

import { Modal } from '@perts/ui';
import { getMessageFromErrors } from '@perts/util/src';

import { MembersManagerForm, uniqComparator } from './MembersManagerForm';
import { ErrorMessageBox } from 'components/ErrorMessageBox';
import { useTerms } from 'components/TermsContext';
import Loading from 'components/Loading';

export const MembersManager = () => {
  const terms = useTerms();
  const { groupId } = useParams();
  const checked = useGetCheckedStates();
  const closeModal = useCloseModal();

  const queryClient = useQueryClient();
  const queryUpdateFn = updateUsers;
  const queryKeyGroupUsers = queryKeyUsersByGroup(groupId);

  const {
    data: group,
    error: groupError,
    isLoading: groupIsLoading,
    isSuccess: groupIsSuccess,
  } = useGroupGetByParams();

  const {
    data: currentUser,
    error: currentUserError,
    isLoading: currentUserIsLoading,
    isSuccess: currentUserIsSuccess,
  } = useCurrentUserQuery();

  // Note: Since this is the Group Members page, the users are being referred to
  // as `members`, but they actually have the `User` type here since we don't
  // need the additional `GroupMember` entity properties.
  const {
    data: members = [],
    error: membersError,
    isLoading: membersIsLoading,
    isSuccess: membersIsSuccess,
  } = useUsersQueryByParams();

  const mutation = useMutation(
    async (updatedMembers: User[]) => {
      if (!group) {
        throw new Error('Group required to edit users.');
      }

      await queryUpdateFn(updatedMembers);
    },
    {
      onMutate: (updatedMembers: User[]) => {
        // Snapshot previous.
        const previousGroupUsers =
          queryClient.getQueryData<User[]>(queryKeyGroupUsers);
        const previousCurrentUser = queryClient.getQueryData<User>(
          queryKeyCurrentUser(),
        );

        // Optimistic update.
        const optimisticGroupUsers = previousGroupUsers
          ? // if existing cache, combine updated with existing cache value
            uniqWith([...updatedMembers, ...members], uniqComparator)
          : // else, just add update to the cache
            updatedMembers;
        const optimisticCurrentUser = updatedMembers.find(
          (user) => user.uid === currentUser?.uid,
        );

        queryClient.setQueryData<User[]>(
          queryKeyGroupUsers,
          optimisticGroupUsers,
        );
        // Note: the current user may not be among the group members, e.g. if
        // the group is being viewed by a network lead.
        if (optimisticCurrentUser) {
          queryClient.setQueryData<User>(
            queryKeyCurrentUser(),
            optimisticCurrentUser,
          );
        }

        // Return previous snapshots for rollbacks.
        return { previousGroupUsers, previousCurrentUser };
      },

      onSuccess: () => {
        message.success(
          `Successfully updated ${terms.groupManager.toLowerCase()} status.`,
        );
      },

      onError: (_errors, _data, context: any) => {
        message.error(
          `There was an error updating the ${terms.groupManager.toLowerCase()} status.`,
        );

        // Rollback any optimistic updates performed.
        if (context?.previousGroupUsers) {
          queryClient.setQueryData(
            queryKeyGroupUsers,
            context.previousGroupUsers,
          );
        }
        if (context?.previousCurrentUser) {
          queryClient.setQueryData(
            queryKeyCurrentUser(),
            context.previousCurrentUser,
          );
        }
      },

      // Always refetch after an error or success.
      onSettled: () => {
        queryClient.invalidateQueries(queryKeyGroupUsers);
      },
    },
  );

  const isLoading = groupIsLoading || membersIsLoading || currentUserIsLoading;

  if (isLoading) {
    return (
      <Modal close={closeModal}>
        <Loading />
      </Modal>
    );
  }

  if (!groupIsSuccess || !membersIsSuccess || !currentUserIsSuccess) {
    return (
      <ErrorMessageBox>
        {getMessageFromErrors([groupError, membersError, currentUserError])}
      </ErrorMessageBox>
    );
  }

  const checkedMembers = members.filter((m) => checked[m.uid]);

  const onSubmit = async (values: User[]) => {
    await mutation.mutateAsync(values);
  };

  return (
    <MembersManagerForm
      close={closeModal}
      allMembers={members}
      checkedMembers={checkedMembers}
      onSubmit={onSubmit}
    />
  );
};
