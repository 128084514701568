import { useParams } from 'react-router-dom';
import pluralize from 'pluralize';

import {
  Dropdown,
  IconUsergroup,
  IconLogin,
  IconTrash,
  IconTarget,
  IconBubbles,
  IconChevronDown,
  IconRule,
  Show,
  Text,
} from '@perts/ui';
import {
  PagesParams,
  toGroupClassesEditTargetGroup,
  toGroupClassesEditUsers,
  toGroupClassesRemove,
  toGroupClassesEditGroups,
  toGroupClassesRosterIDRule,
  toGroupClassesRosterLocking,
  toGroupClassesRosterSignOnType,
} from 'pages';
import { useOpenModal } from 'utils';
import { useTerms } from 'components/TermsContext';
import { IconRosterLocked } from './IconRosterLocked';

export const ClassesDropdownActions = ({
  totalSelected,
  checked,
  mayEditMembers,
  mayDeleteClasses,
  mayEditTargetGroup,
  mayEditGroups,
  mayEditRosterIDRule,
  mayLockRoster,
}) => {
  const { groupId } = useParams<PagesParams>();
  const openModal = useOpenModal();
  const terms = useTerms();

  const handleAction = (route: string) => {
    openModal(route, { checked });
  };

  // checked has structure { [uid]: boolean, ... }
  const anySelected = Object.values(checked).filter(Boolean).length > 0;

  return (
    <Dropdown
      menuButton={
        <>
          <span>
            Edit
            {` ${pluralize(
              terms.class,
              totalSelected,
              Boolean(totalSelected),
            )}`}
          </span>

          <IconChevronDown />
        </>
      }
      color="primary"
      aria-label={`Classes actions dropdown`}
      openDirection="left"
      fullWidth
    >
      <Show when={anySelected && mayEditRosterIDRule}>
        <Dropdown.Item
          onClick={() => handleAction(toGroupClassesRosterSignOnType(groupId))}
        >
          <IconLogin />
          <span>Edit {terms.participant} Sign-On Type</span>
        </Dropdown.Item>
      </Show>

      <Show when={anySelected && mayEditRosterIDRule}>
        <Dropdown.Item
          onClick={() => handleAction(toGroupClassesRosterIDRule(groupId))}
        >
          <IconRule />
          <span>Edit {terms.participant} Sign-On Rule</span>
        </Dropdown.Item>
      </Show>

      <Show when={anySelected && mayLockRoster}>
        <Dropdown.Item
          onClick={() => handleAction(toGroupClassesRosterLocking(groupId))}
        >
          <IconRosterLocked locked />
          <span>Edit Roster Locking</span>
        </Dropdown.Item>
      </Show>

      <Show when={anySelected && mayEditTargetGroup}>
        <Dropdown.Item
          onClick={() => handleAction(toGroupClassesEditTargetGroup(groupId))}
        >
          <IconTarget />
          <span>Edit Focal Group</span>
        </Dropdown.Item>
      </Show>

      <Show when={anySelected && mayEditMembers}>
        <Dropdown.Item
          onClick={() => handleAction(toGroupClassesEditUsers(groupId))}
        >
          <IconUsergroup />
          <span>Edit {terms.classManagers}</span>
        </Dropdown.Item>
      </Show>

      <Show when={anySelected && mayEditGroups}>
        <Dropdown.Item
          onClick={() => handleAction(toGroupClassesEditGroups(groupId))}
        >
          <IconBubbles />
          <span>Edit {terms.groups}</span>
        </Dropdown.Item>
      </Show>

      <Show when={anySelected && mayDeleteClasses}>
        <Dropdown.Item
          onClick={() => handleAction(toGroupClassesRemove(groupId))}
        >
          <IconTrash />
          <span>Delete</span>
        </Dropdown.Item>
      </Show>

      <Show when={!anySelected}>
        <Dropdown.Item disabled={true}>
          <Text as="p">
            <em>Select one or more {terms.classes.toLowerCase()} to edit.</em>
          </Text>
        </Dropdown.Item>
      </Show>
    </Dropdown>
  );
};
