import { useState, useEffect } from 'react';
import Truncate from 'react-truncate';
import { Tooltip } from 'antd';

import theme from 'components/theme';

export const BreadcrumbsName = ({ name }) => {
  const initialWidth =
    document.body.clientWidth < theme.units.gridBreakpointSmall ? 100 : 200;
  const [width, setWidth] = useState(initialWidth);

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      const updatedWidth =
        document.body.clientWidth < theme.units.gridBreakpointSmall ? 100 : 200;
      // Set window width/height to state
      setWidth(updatedWidth);
    }

    // Add event listener
    window.addEventListener('resize', handleResize);

    // Call handler right away so state gets updated with initial window size
    handleResize();

    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <Tooltip title={name} trigger={['hover', 'click']}>
      <Truncate lines={1} trimWhitespace width={width} aria-label={name}>
        {name}
      </Truncate>
    </Tooltip>
  );
};
