import { Formik, Form } from 'formik';
import { isEmpty } from 'lodash';

import {
  Button,
  CheckboxWithLabel,
  Col,
  InfoBox,
  Input,
  Modal,
  MultiSelectChip,
  Row,
  Show,
  useFormError,
} from '@perts/ui';

import { Group } from 'models';
import { useTerms } from 'components/TermsContext';

const isValidGroupId = (groupId: string) => {
  const regex = /^(Organization_)?[A-Za-z0-9-]+$/;
  return regex.test(groupId);
};

// GroupId validation for MultiSelectChip's `customValidation`.
const multiSelectChipValidation = (groupId: string) => {
  if (!isValidGroupId(groupId)) {
    return `Group ID "${groupId}" is not valid.`;
  }

  return undefined;
};

const getSelectedIds = (checkboxValues) =>
  Object.entries(checkboxValues)
    .filter(([, selected]) => selected)
    .map(([uid]) => uid);

const validateNetworkGroupsAddForm = (values, terms) => {
  // What super admins use. Valid if some group ID chips have been recognized,
  // or if there's text in the field that is valid.
  const idInputValid =
    !isEmpty(values.groupIds) ||
    (!isEmpty(values.currentValue) && isValidGroupId(values.currentValue));

  const checkboxesValid = getSelectedIds(values.groupCheckboxes).length > 0;

  if (idInputValid || checkboxesValid) {
    return {};
  }

  return {
    groupIds: `At least one ${terms.group.toLowerCase()} ID is required.`,
  };
};

export type NetworkGroupsAddFormValues = {
  // The only required prop b/c it's used to submit. When using the form, this
  // represents IDs recognized and displayed as chips.
  groupIds: string[];
  currentValue?: string; // text in the super admin ID input field
  groupCheckboxes?: { [uid: string]: boolean }; // for normal users
};

export type NetworkGroupsAddFormProps = {
  close: () => void;
  isAdmin: boolean;
  onSubmit: (values: NetworkGroupsAddFormValues) => void;
  eligibleGroups: Group[];
};

export const NetworkGroupsAddForm = ({
  close,
  isAdmin,
  onSubmit,
  eligibleGroups,
}: NetworkGroupsAddFormProps) => {
  const [FormError, showFormError] = useFormError();
  const terms = useTerms();

  const initialValues: NetworkGroupsAddFormValues = {
    groupIds: [],
    currentValue: '',
    groupCheckboxes: {},
  };

  return (
    <Modal close={close}>
      <Modal.Title className="center">Add Network {terms.groups}</Modal.Title>

      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validate={(values) => validateNetworkGroupsAddForm(values, terms)}
        onSubmit={async ({
          groupIds,
          currentValue = '',
          groupCheckboxes = {},
        }: NetworkGroupsAddFormValues) => {
          try {
            // Clear existing form error.
            await showFormError(false);

            // Combine the multiple ways we have of determining group IDs into
            // a standard property.
            const combinedGroupIds = [
              currentValue,
              ...groupIds,
              ...getSelectedIds(groupCheckboxes),
            ].filter(Boolean);

            await onSubmit({ groupIds: combinedGroupIds });

            // Close modal on successful form onSubmit.
            close();
          } catch (error) {
            // Display form error.
            showFormError(true);
          }
        }}
      >
        {({ isSubmitting, isValid, dirty, errors, setFieldValue, values }) => (
          <Form>
            <Show when={isAdmin}>
              <Input
                id="groupIds"
                name="groupIds"
                label={`${terms.group} IDs`}
                placeholder={`${terms.group} IDs`}
                labelPlacement="top"
                customValidation={multiSelectChipValidation}
                component={MultiSelectChip}
                disabled={isSubmitting}
                error={values.currentValue === '' && errors.groupIds}
              />
            </Show>

            <Show when={!isAdmin}>
              <Show when={eligibleGroups.length > 0}>
                {eligibleGroups.map((group: Group) => (
                  <Input
                    id={`groupCheckbox-${group.short_uid}`}
                    key={group.uid}
                    name={`groupCheckboxes.${group.uid}`}
                    label={group.name}
                    labelPlacement="top"
                    checked={(values.groupCheckboxes || {})[group.uid]}
                    component={CheckboxWithLabel}
                  />
                ))}
              </Show>
              <Show when={eligibleGroups.length === 0}>
                <Row>
                  <Col>
                    <InfoBox>
                      No {terms.groups.toLowerCase()} to add. You must be the{' '}
                      {terms.groupManager} before adding the{' '}
                      {terms.group.toLowerCase()}.
                    </InfoBox>
                  </Col>
                </Row>
              </Show>
            </Show>

            <Row>
              <Col>
                <FormError />
              </Col>
            </Row>

            <Row>
              <Col>
                <Button
                  fullHeight
                  type="button"
                  color="secondary"
                  fullWidth
                  onClick={close}
                  disabled={isSubmitting}
                >
                  Cancel
                </Button>
              </Col>

              <Col>
                <Show when={isAdmin || eligibleGroups.length > 0}>
                  <Button
                    type="submit"
                    fullWidth
                    disabled={!isValid || !dirty || isSubmitting}
                    loading={isSubmitting}
                  >
                    Add {terms.groups}
                  </Button>
                </Show>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
