import { ReportUpdatedFrequency, ReportFooter } from 'components';
import { ClassReportLoader } from './ClassReportLoader';
import { ClassReport23Render } from './ClassReport23Render';

export const ClassReport23 = () => (
  <ClassReportLoader>
    {({
      cls,
      group,
      lastRun,
      lastUpdatedResults,
      program,
      programMetrics,
      reportMetrics,
      results,
    }) => (
      <>
        <ClassReport23Render
          archived={Boolean(group.archived)}
          cls={cls}
          lastRun={lastRun}
          program={program}
          programMetrics={programMetrics}
          reportMetrics={reportMetrics}
          results={results}
        />

        <ReportFooter>
          <ReportUpdatedFrequency dateString={lastUpdatedResults} />
        </ReportFooter>
      </>
    )}
  </ClassReportLoader>
);
