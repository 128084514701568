import styled from 'styled-components/macro';
import { theme } from '../../';

export const CardDivider = styled.div`
  content: '';
  border: 1px solid ${theme.colors.grayLight};

  margin-left: -${theme.units.paddingLg};
  margin-right: -${theme.units.paddingLg};
  margin-bottom: ${theme.units.paddingLg};

  @media only screen and (max-width: ${theme.units.gridBreakpointMedium}px) {
    margin-left: -${theme.units.paddingMd};
    margin-right: -${theme.units.paddingMd};
    margin-bottom: ${theme.units.paddingMd};
  }

  @media only screen and (max-width: ${theme.units.gridBreakpointSmall}px) {
    margin-left: -${theme.units.paddingSm};
    margin-right: -${theme.units.paddingSm};
    margin-bottom: ${theme.units.paddingSm};
  }
`;

CardDivider.displayName = 'CardDivider';
