import { useEffect, useState } from 'react';

import {
  User,
  selectUserIsAdmin,
  useGroupsPagination,
  useNetworksByUserId,
  useNetworksPagination,
  useProgramId,
} from 'models';

import {
  Col,
  Row,
  SelectFilter,
  SelectFilterOption,
  SelectFilterSelected,
  Show,
  Text,
} from '@perts/ui';
import { Loading, PaginationUseQuery, useTerms } from 'components';
import { HomeNetworks } from './HomeNetworks';
import { HomeGroups } from './HomeGroups';
import { HomeGuideBox } from './HomeGuideBox';
import useFilterField from 'utils/useFilterField';

type HomeRenderProps = {
  user: User;
};

const selectArchivesOptions = [
  { name: 'Active', value: 'false' },
  { name: 'Archived', value: 'true' },
];

// eslint-disable-next-line complexity
export const HomeRender = ({ user }: HomeRenderProps) => {
  const terms = useTerms();
  const programId = useProgramId();
  const userIsAdmin = selectUserIsAdmin(user);

  // Scope to authenticated user when not super_admin (includes imitated).
  const user_id = !userIsAdmin ? { user_id: user.uid } : {};

  const [viewArchived, setViewArchived] = useState<SelectFilterOption>(
    selectArchivesOptions[0],
  );
  const setSelectedArchived = (value: SelectFilterSelected) => {
    if (value !== null && !Array.isArray(value)) {
      setViewArchived(value);
    }
  };
  const selectedModeLowerCase =
    typeof viewArchived?.name === 'string' && viewArchived.name.toLowerCase();

  const [pageIndexNetworks, setPageIndexNetworks] = useState(0);
  const [pageIndexGroups, setPageIndexGroups] = useState(0);

  const {
    filterTextDebounced: filterTextNetworks,
    FilterField: filterFieldNetworks,
  } = useFilterField([], ['name'], {
    label: `Filter by ${terms.network.toLowerCase()} name`,
  });

  const {
    filterTextDebounced: filterTextGroups,
    FilterField: filterFieldGroups,
  } = useFilterField([], ['name'], {
    label: `Filter by ${terms.group.toLowerCase()} name`,
  });

  useEffect(() => {
    // Reset to first page when filter text updates. We want to avoid the
    // scenario where the user is now viewing a page with no results because
    // their filter has reduced the total number of results.
    setPageIndexNetworks(0);
  }, [filterTextNetworks]);

  useEffect(() => {
    // Reset to first page when filter text updates. We want to avoid the
    // scenario where the user is now viewing a page with no results because
    // their filter has reduced the total number of results.
    setPageIndexGroups(0);
  }, [filterTextGroups]);

  const resultsNetworks = useNetworksPagination(
    {
      programId,
      name: filterTextNetworks,
      ...user_id,
      pageIndex: pageIndexNetworks,
      pageSize: 10,
    },
    {
      keepPreviousData: true,
    },
  );

  const { data: userNetworks, isLoading: isLoadingUserNetworks } =
    useNetworksByUserId();
  const userNetworksUidsInProgram = (userNetworks || [])
    .filter((n) => n.program_id === programId)
    .map((n) => n.uid);
  const userHasNetworkInProgram = user.owned_networks.some((networkId) =>
    userNetworksUidsInProgram.includes(networkId),
  );

  const resultsGroups = useGroupsPagination(
    {
      programId,
      name: filterTextGroups,
      ...user_id,
      pinned: true,
      archived: viewArchived.value === 'true',
      pageIndex: pageIndexGroups,
      pageSize: 10,
    },
    {
      keepPreviousData: true,
    },
  );

  const queryKeyParamsGroups = {
    name: filterTextGroups,
    pageIndex: pageIndexGroups,
  };

  const { data: networks = [], isLoading: isLoadingNetworks } = resultsNetworks;
  const { data: groups = [], isLoading: isLoadingGroups } = resultsGroups;
  const isLoading =
    isLoadingNetworks || isLoadingUserNetworks || isLoadingGroups;

  if (isLoading) {
    return <Loading />;
  }

  // We don't want to use the network results to determine whether to display
  // the networks section because filtering could reduce that number to 0.
  const showNetworks = userIsAdmin || userHasNetworkInProgram;

  return (
    <>
      <Show when={showNetworks}>
        <div data-testid="networks">
          <Text as="h1">{terms.networks}</Text>

          <Row>
            <Col>{filterFieldNetworks}</Col>
          </Row>

          <HomeNetworks
            user={user}
            networks={networks}
            emptyMessage={
              <Text as="p">
                <em>
                  No {terms.networks.toLowerCase()}
                  {filterTextNetworks ? ' match.' : '.'}
                </em>
              </Text>
            }
            pagination={
              <Row>
                <Col
                  vAlign="center"
                  hAlign="flex-end"
                  hAlignSm="center"
                  cols={12}
                >
                  <PaginationUseQuery
                    useQueryResults={resultsNetworks}
                    onChange={setPageIndexNetworks}
                  />
                </Col>
              </Row>
            }
          />
        </div>
      </Show>

      <div data-testid="groups">
        <Text as="h1">{terms.groups}</Text>

        <Row alignItems="flex-end">
          <Col>{filterFieldGroups}</Col>
          <Col shrink>
            <SelectFilter
              label="View"
              options={selectArchivesOptions}
              selected={viewArchived}
              setSelected={setSelectedArchived}
            />
          </Col>
        </Row>

        <HomeGroups
          user={user}
          groups={groups}
          queryKeyParams={queryKeyParamsGroups}
          emptyMessage={
            <Text as="p">
              <em>
                No {selectedModeLowerCase} {terms.groups.toLowerCase()}
                {filterTextGroups ? ' match.' : '.'}
              </em>
            </Text>
          }
          pagination={
            <Row>
              <Col
                vAlign="center"
                hAlign="flex-end"
                hAlignSm="center"
                cols={12}
              >
                <PaginationUseQuery
                  useQueryResults={resultsGroups}
                  onChange={setPageIndexGroups}
                />
              </Col>
            </Row>
          }
        />
      </div>

      <Show when={networks.length === 0 && groups.length === 0}>
        <Row>
          <Col>
            <HomeGuideBox />
          </Col>
        </Row>
      </Show>
    </>
  );
};
