import moment from 'moment';
import styled from 'styled-components/macro';

import { theme } from 'components';
import { dateFormat } from 'config';

const ReportCardDateStyled = styled.span`
  color: ${theme.colors.text};
`;

export const ReportCardDate = ({ date }: { date?: string | null }) => (
  <ReportCardDateStyled>
    {date &&
      moment(date)
        .format(dateFormat)
        // moment doesn't have a locale-sensitive format with a 2-digit year
        // so this just modifies the year portion
        // https://github.com/moment/moment/issues/2158#issuecomment-533443318
        .replace(/(\d{2})(\d{2})/, '$2')}
  </ReportCardDateStyled>
);
