import styled from 'styled-components/macro';
import { css } from 'styled-components';
import { theme } from '../../';

type ActionbarStyledProps = {
  // The margin below the Actionbar. Default `lg`.
  margin?: 'none' | 'sm' | 'md' | 'lg';

  // The padding left the Actionbar. Default we use `theme.units.padding*`.
  paddingLeft?: number;
};

const ActionbarStyled = styled.div<ActionbarStyledProps>`
  font-size: 1.125rem;
  padding: 0 0 0 ${theme.units.paddingLg};

  margin-bottom: ${theme.units.paddingLg};

  ${(props) =>
    props.margin === 'md' &&
    css`
      margin-bottom: ${theme.units.paddingMd};
    `};

  ${(props) =>
    props.margin === 'sm' &&
    css`
      margin-bottom: ${theme.units.paddingSm};
    `};

  @media only screen and (max-width: ${theme.units.gridBreakpointMedium}px),
    only screen and (max-height: ${theme.units.gridBreakpointMedium}px) {
    padding: 0 0 0 ${theme.units.paddingMd};
    margin-bottom: ${theme.units.paddingMd};
  }

  @media only screen and (max-width: ${theme.units.gridBreakpointSmall}px),
    only screen and (max-height: ${theme.units.gridBreakpointSmall}px) {
    padding: 0 0 0 ${theme.units.paddingSm};
    margin-bottom: ${theme.units.paddingSm};
  }

  ${({ paddingLeft }) =>
    Number.isInteger(paddingLeft) &&
    css`
      padding: 0 0 0 ${paddingLeft}px;

      @media only screen and (max-width: ${theme.units
          .gridBreakpointMedium}px) {
        padding: 0 0 0 ${paddingLeft}px;
      }

      @media only screen and (max-width: ${theme.units.gridBreakpointSmall}px) {
        padding: 0 0 0 ${paddingLeft}px;
      }
    `};
`;

type ActionbarProps = ActionbarStyledProps & {
  // The content rendered in the Actionbar.
  children: React.ReactNode;
};

export const Actionbar = ({ children, ...rest }: ActionbarProps) => (
  <ActionbarStyled {...rest}>{children}</ActionbarStyled>
);
