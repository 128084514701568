// https://docs.google.com/spreadsheets/d/1ZX4hcUol-_frFLfUYRnZ-UPEvpGsVOsWsJRw1g5tDBM

import keyBy from 'lodash/keyBy';

import { locales } from '../i18n';
import { CompositeMetricConfig, MetricItemConfig } from './types';
import { catalyze24CompletelyTrueOptions } from './answerOptions/catalyze24CompletelyTrueOptions';

export const plc24ReviewStudentWorkItemConfig: MetricItemConfig[] = [
  {
    label: 'plc_review_student_work',
    likertN: 4,
    minGood: 3,
    options: catalyze24CompletelyTrueOptions,
    prompts: {
      [locales.ENGLISH]:
        'This past month, to what extent have you experienced the following ' +
        'with your PLC colleagues: We reviewed student work and discussed ' +
        'how to improve instructional practices.',
    },
  },
];

export const plc24ReviewStudentWorkConfig: CompositeMetricConfig = {
  label: 'plc-review-student-work',
  items: plc24ReviewStudentWorkItemConfig,
  itemIndex: keyBy(plc24ReviewStudentWorkItemConfig, 'label'),
  name: 'Activities: Review of Student Work',
  description:
    'Reviewing student work allows educators to collectively analyze ' +
    "students' conceptualizations, reflect on teaching practice, and make " +
    'decisions about next steps in instruction and student support.',
};
