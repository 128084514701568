// Text component.
// Inspired by https://material-ui.com/components/typography/

import styled from 'styled-components/macro';
import { css } from 'styled-components';
import { theme } from '../../';

const inlineElements = ['a', 'span'];

export type TextProps = {
  // The element that will be rendered. Default `span`.
  // This is provided to styled-component's `as` polymorphic prop.
  // https://styled-components.com/docs/api#as-polymorphic-prop
  as?: 'a' | 'h1' | 'h2' | 'h3' | 'nav' | 'p' | 'span';

  // The text to display.
  children: React.ReactNode;

  // Example: '14px'.
  fontSize?: string;

  includeMargin?: boolean;

  overflowWrap?:
    | 'normal'
    | 'break-all'
    | 'break-word'
    | 'anywhere'
    | 'inherit'
    | 'initial'
    | 'revert'
    | 'unset';

  href?: string;

  onClick?: Function;
};

export const TextStyled = styled.span<TextProps>`
  ${({ as = 'span' }) =>
    !inlineElements.includes(as) &&
    css`
      display: flex;
      flex-direction: row;
      align-items: center;
      flex-wrap: wrap;
    `};

  > :not(:first-child) {
    margin-left: ${theme.units.paddingSm};
  }

  color: ${theme.colors.text};

  ${(props) =>
    props.overflowWrap &&
    css`
      overflow-wrap: ${props.overflowWrap};
    `};

  ${(props) =>
    props.as === 'a' &&
    css`
      color: ${theme.colors.primary};
      text-decoration: underline;
    `};

  ${({ as, includeMargin = true }) =>
    as === 'h1' &&
    css`
      font-size: 2rem;
      font-weight: 400;

      margin-bottom: ${includeMargin ? '1rem' : '0'};
    `};

  ${({ as, includeMargin = true }) =>
    as === 'h2' &&
    css`
      font-size: 1.4rem;
      font-weight: 600;

      margin-bottom: ${includeMargin ? '0.7rem' : '0'};
    `};

  ${({ as, includeMargin = true }) =>
    as === 'h3' &&
    css`
      font-size: 1.125rem;
      font-weight: 600;

      margin-bottom: ${includeMargin ? '0.5625rem' : '0'};
    `};

  ${({ as }) =>
    as === 'nav' &&
    css`
      font-weight: 600;

      a {
        color: ${theme.colors.primary};
      }
    `};

  ${({ as, includeMargin = true }) =>
    as === 'p' &&
    css`
      margin-bottom: ${includeMargin ? theme.units.paddingMd : '0'};
    `};

  ${({ fontSize }) =>
    fontSize &&
    css`
      font-size: ${fontSize};
    `};
`;

export const Text = ({
  children,
  ...rest
}: TextProps & React.HTMLAttributes<any>) => (
  <TextStyled {...rest}>{children}</TextStyled>
);
