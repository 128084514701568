import React from 'react';

import AuthWrapper from 'components/AuthWrapper';

const Unauthorized = ({ message, path }) => (
  <AuthWrapper>
    <h1 data-test="unauthorized">
      You&rsquo;re not authorized to view this page: {message}
    </h1>
    <p>
      {/*
        Don't use <Link> here because we don't want single-page-app-style
        routing. We want a hard refresh.
      */}
      Please try <a href={path}>signing in</a> to reach this page. Please
      contact <a href="mailto:copilot@perts.net">copilot@perts.net</a> for
      support.
    </p>
  </AuthWrapper>
);

export default Unauthorized;
