// @ts-nocheck
// https://github.com/vercel/next.js/issues/35986

import { oneAndOnlyOne } from '@perts/util';
import { IconExternalLink, Spacer } from '../..';
import { isExternal, linkProps } from '../shared';
import { StyledLink } from './StyledLink';

export type LinkProps = {
  /**
   * The aria-label attribute is used to define a string that labels the current
   * element. Use it in cases where a text label is not visible on the screen.
   * https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Attributes/aria-label
   */
  'aria-label'?: string;

  /**
   * To allow styled-component wrapping.
   */
  className?: string;

  /**
   * React Router Link's `to`.
   * https://v5.reactrouter.com/web/api/Link
   */
  to?: string;

  /**
   * The content of the Link.
   */
  children: React.ReactNode;

  /**
   * For test querying, when no better way to access the element exists.
   * https://testing-library.com/docs/queries/about/#priority
   */
  'data-testid'?: string;

  /**
   * If present, indicates that the author intends the hyperlink to be used for
   * downloading a resource vs displaying in the browser.
   * https://html.spec.whatwg.org/multipage/links.html#attr-hyperlink-download
   */
  download?: string;

  /**
   * `true` to disable the external link icon.
   */
  noIcon?: boolean;

  /**
   * onClick event handler.
   */
  onClick?: (event: React.FormEvent<HTMLButtonElement>) => void;

  /**
   * The HTML anchor/React Router's `target`.
   */
  target?: string;

  /**
   * The button type. Default 'button'.
   */
  type?: 'submit' | 'button';
};

export const Link = (props: LinkProps) => {
  const { to, onClick, type, children, noIcon, ...rest } = props;

  const isLink = Boolean(to);
  const isButton = Boolean(onClick);
  const isFormSubmit = type === 'submit';

  if (!oneAndOnlyOne([isLink, isButton || isFormSubmit])) {
    throw new Error(
      'You must provide either `to`, `onClick`/`type="submit"` to Button.',
    );
  }

  const externalLinkIcon = to && isExternal(to) && !noIcon && (
    <>
      <Spacer />
      <IconExternalLink />
    </>
  );

  return (
    <StyledLink {...rest} {...linkProps({ to, onClick, type })}>
      {children}
      {externalLinkIcon}
    </StyledLink>
  );
};
