import styled from 'styled-components/macro';

import theme from 'components/theme';

/**
 * A button that looks like an anchor, but without the href.
 * @type {StyledComponent}
 */
const FakeLink = styled.button`
  color: ${theme.colors.primary};
  text-decoration: underline;
  font-weight: 600;

  cursor: pointer;
  border: none;
  padding: 0;
  background: transparent;
`;

FakeLink.defaultProps = {
  // The default for a link-styled button should not be to submit a form.
  type: 'button',
};

export default FakeLink;
