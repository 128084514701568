import React from 'react';
import styled from 'styled-components/macro';
import { css } from 'styled-components';
import { Menu } from '@headlessui/react';
import { IconMenuEllipsis } from '../Icon';

import theme from '../theme';
import { DropdownItem } from './DropdownItem';
import { DropdownSection } from './DropdownSection';

type MenuContainerProps = {
  openDirection?: 'left' | 'right';
  fullWidth?: boolean;
};

type MenuItemsProps = {
  // Necessary for the menu to scroll on mobile. Short menus may not need it.
  dropdownHeight?: string; // e.g. '50vh'
  dropdownMaxHeight?: string; // e.g. '300px'
};

export type DropdownProps = MenuContainerProps &
  MenuItemsProps & {
    children: React.ReactNode;
    menuButton?: any;
    disabled?: boolean;

    // The color of the menu button. Default 'deafult'.
    color?: 'default' | 'primary' | 'secondary';
  };

const noForward = ['dropdownHeight', 'dropdownMaxHeight'];

const MenuItemsStyled = styled(Menu.Items).withConfig({
  // https://styled-components.com/docs/api#shouldforwardprop
  shouldForwardProp: (prop, defaultValidatorFn) =>
    !noForward.includes(prop) && defaultValidatorFn(prop),
})<MenuItemsProps>`
  position: absolute;
  right: 0;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
  background-color: white;
  border-radius: ${(props) => props.theme.units.borderRadius};
  overflow: auto;
  width: max-content;

  ${({ dropdownHeight }) =>
    dropdownHeight &&
    css`
      height: ${dropdownHeight};
    `}

  ${({ dropdownMaxHeight }) =>
    dropdownMaxHeight &&
    css`
      max-height: ${dropdownMaxHeight};
    `}

  &:focus {
    outline: 0;
    box-shadow: 0 0 0 1px ${(props) => props.theme.colors.grayLight};
  }
  z-index: 20;
`;

MenuItemsStyled.defaultProps = {
  theme,
};

const MenuContainer = styled.div<MenuContainerProps>`
  width: ${(props) => (props.fullWidth ? '100%' : '')};

  // Add styles to Menu Component
  > * {
    position: relative;
    display: inline-block;
    width: ${(props) => (props.fullWidth ? '100%' : '')};
  }

  // Set direction to open dropdown options
  & ${MenuItemsStyled} {
    ${(props) =>
      props.openDirection === 'right'
        ? css`
            right: 0;
          `
        : css`
            left: 0;
          `};
  }
`;

MenuContainer.defaultProps = {
  openDirection: 'right',
};

type MenuButtonStyledProps = {
  // The $ prefix marks the prop as "transient", i.e. not passed to the DOM.
  // https://styled-components.com/docs/api#transient-props
  fullWidth: boolean;
  disabled: boolean;
  children: React.ReactNode;
  color: 'default' | 'primary' | 'secondary';
  theme: any;
};

const MenuButtonStyled = styled(Menu.Button).withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) =>
    !['fullWidth'].includes(prop) && defaultValidatorFn(prop),
})<MenuButtonStyledProps>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  background-color: transparent;
  cursor: pointer;
  border: 0;
  outline: 0;
  font-size: 1.125rem;
  font-weight: 600;
  border-radius: ${(props) => props.theme.units.borderRadius};
  width: ${(props) => (props.fullWidth ? '100%' : '')};
  & > :not(:first-child) {
    margin-left: 6px;
  }
  min-height: ${(props) => props.theme.units.buttonHeight};

  &:focus {
    outline: 0;
    box-shadow: 0 0 0 4px ${(props) => props.theme.colors.primaryLight};
  }

  ${({ color, theme: t }) =>
    color === 'default' &&
    css`
      padding: ${t.units.paddingSm};

      &:hover {
        background: ${t.colors.grayLight};
      }

      &:active {
        background: ${t.colors.grayLight};
      }
    `}

  ${({ color, disabled, theme: t }) =>
    color === 'primary' &&
    css`
      color: ${t.colors.white};
      padding: 0 ${t.units.buttonPadding};

      background-color: ${disabled ? t.colors.disabled : t.colors.primary};

      &:enabled:hover {
        background: ${t.colors.buttonBgDarkBlueOnHover};
      }

      &:enabled:active {
        background: ${t.colors.buttonBgDarkBlueOnActive};
      }
    `}

  ${({ color, disabled, theme: t }) =>
    color === 'secondary' &&
    css`
      color: ${disabled
        ? t.colors.textColorLightGrayOnWhite
        : t.colors.primary};
      padding: 0 ${t.units.buttonPadding};

      background-color: ${t.colors.white};
      border: ${disabled
        ? `1px solid ${t.colors.textColorLightGrayOnWhite}`
        : `1px solid ${t.colors.primary}`};

      &:enabled:hover {
        color: ${t.colors.buttonBgDarkBlueOnHover};
        border: 1px solid ${t.colors.buttonBgDarkBlueOnHover};
        background: ${t.colors.white};
      }

      &:enabled:active {
        color: ${t.colors.buttonBgDarkBlueOnActive};
        border: 1px solid ${t.colors.buttonBgDarkBlueOnActive};
        background: ${t.colors.white};
      }
    `}

  @media only screen and (max-width: ${(props) =>
    props.theme.units.gridBreakpointMedium}px),
    only screen and (max-height: ${(props) =>
    props.theme.units.gridBreakpointMedium}px) {
    padding: 0 ${(props) => props.theme.units.buttonPaddingMd};
  }

  @media only screen and (max-width: ${(props) =>
      props.theme.units.gridBreakpointSmall}px),
    only screen and (max-height: ${(props) =>
      props.theme.units.gridBreakpointSmall}px) {
    font-size: 1rem;
    padding: 0 ${(props) => props.theme.units.buttonPaddingSm};
  }
`;

MenuButtonStyled.defaultProps = {
  theme,
};

const DropdownComponent = ({
  children,
  color = 'default',
  menuButton = <IconMenuEllipsis />,
  openDirection,
  fullWidth = false,
  disabled = false,
  dropdownHeight,
  dropdownMaxHeight,
  ...rest
}: DropdownProps) => {
  return (
    <MenuContainer openDirection={openDirection} fullWidth={fullWidth}>
      <Menu as="div">
        <MenuButtonStyled
          {...rest}
          color={color}
          disabled={disabled}
          fullWidth={fullWidth}
        >
          {menuButton}
        </MenuButtonStyled>
        <MenuItemsStyled
          dropdownHeight={dropdownHeight}
          dropdownMaxHeight={dropdownMaxHeight}
        >
          {children}
        </MenuItemsStyled>
      </Menu>
    </MenuContainer>
  );
};

export const Dropdown = Object.assign(DropdownComponent, {
  Item: DropdownItem,
  Section: DropdownSection,
});
