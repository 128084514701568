import { useContext } from 'react';
import { useParams } from 'react-router-dom';

import { Button, Col, IconAdd, Row, Show } from '@perts/ui';

import { selectUserIsAdmin, useCurrentUserQuery } from 'models';
import { PagesParams, SearchField } from 'pages';
import { toHomeGroupsAdd, toHomeNetworksAdd } from 'pages/routes';
import TermsContext from 'components/TermsContext';
import { useOpenModal } from 'utils';

export const HomeActionbar = ({ mayAddNetworks }) => {
  const { programId } = useParams<PagesParams>();
  const terms = useContext(TermsContext);

  const { data: user } = useCurrentUserQuery();
  const userIsAdmin = user ? selectUserIsAdmin(user) : false;

  const openModal = useOpenModal();

  return (
    <Row justifyContent="flex-end" alignItems="flex-end">
      {userIsAdmin && (
        <Col colsMd={12}>
          <SearchField />
        </Col>
      )}

      <Col shrink cols={0} colsSm={12}>
        <Button
          onClick={() => openModal(toHomeGroupsAdd(programId))}
          iconLeft={<IconAdd />}
          color="secondary"
          fullWidth
        >
          Create {terms.group}
        </Button>
      </Col>

      <Show when={mayAddNetworks}>
        <Col shrink cols={0} colsSm={12}>
          <Button
            onClick={() => openModal(toHomeNetworksAdd(programId))}
            iconLeft={<IconAdd />}
            color="secondary"
            fullWidth
          >
            Create Network
          </Button>
        </Col>
      </Show>
    </Row>
  );
};
