export const SET_AUTHENTICATING = 'SET_AUTHENTICATING';

export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';

export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE';

export const REGISTER_REQUEST = 'REGISTER_REQUEST';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAILURE = 'REGISTER_FAILURE';
export const REGISTER_RESET = 'REGISTER_RESET';

export const PASSWORD_SET_REQUEST = 'PASSWORD_SET_REQUEST';
export const PASSWORD_SET_SUCCESS = 'PASSWORD_SET_SUCCESS';
export const PASSWORD_SET_FAILURE = 'PASSWORD_SET_FAILURE';
export const PASSWORD_SET_RESET = 'PASSWORD_SET_RESET';

export const PASSWORD_RESET_REQUEST = 'PASSWORD_RESET_REQUEST';
export const PASSWORD_RESET_SUCCESS = 'PASSWORD_RESET_SUCCESS';
export const PASSWORD_RESET_FAILURE = 'PASSWORD_RESET_FAILURE';
export const PASSWORD_RESET_RESET = 'PASSWORD_RESET_RESET';

export const CHECK_TOKEN_REQUEST = 'CHECK_TOKEN_REQUEST';
export const CHECK_TOKEN_SUCCESS = 'CHECK_TOKEN_SUCCESS';
export const CHECK_TOKEN_FAILURE = 'CHECK_TOKEN_FAILURE';

export const IMPERSONATE_USER_REQUEST = 'IMPERSONATE_USER_REQUEST';
export const IMPERSONATE_USER_RESET = 'IMPERSONATE_USER_RESET';
