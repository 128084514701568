import { useFormikContext } from 'formik';
import { isEmpty } from 'lodash';
import { Button, InfoBox } from '@perts/ui';
import { useGroupGetByParams } from 'models';
import { FormValues } from './FormValues';
import { compareFormFields } from './compareFormFields';

export const DefaultSettingsWarning = ({
  defaultValues = {},
  toggleChecked,
  hideWhenErrors = true,
  message,
}: {
  /**
   * The default values to be compared against and the values that the form
   * will be set to when the user presses the "Match" button. It is important
   * that ALL form fields have a key:value pair in the object provided. A value
   * of `null` indicates no defaults have been saved so no warnings will be
   * displayed. `null` is used over `undefined` so we may determine intention.
   */
  defaultValues: FormValues | null;

  /**
   * Optional. When using with checkboxes controled by a `useToggles` hook,
   * provide the `toggleChecked` function.
   */
  toggleChecked?: Function;

  /**
   * If `true`, suppress displaying warnings when there are errors.
   * Default `true`.
   */
  hideWhenErrors?: boolean;

  /**
   * The warning message to display when the form does not match defaults.
   */
  message: React.ReactNode;
}) => {
  const { dirty, errors, values, setValues } = useFormikContext<FormValues>();
  const { data: group } = useGroupGetByParams();

  // No defaults have been saved, so do not display warnings.
  if (defaultValues === null) {
    return null;
  }

  const { diffFromDefaults, missingFromDefaults } = compareFormFields(
    values,
    defaultValues,
  );

  // Verify that every form field has a key:value pair in defaultValues.
  if (missingFromDefaults.length > 0) {
    // TODO How do we want to handle this misconfiguration/uncaught bug? Should
    // we throw an error? That'll break the app for users. But if defaultValues
    // is wrong, then users may "match" to the wrong defaults.

    // Log this case so that developers can address asap. If defaultValues
    // doesn't contain entries for every form field, then there isn't a method
    // for determining what the default is for those missing fields.
    console.error(
      `[DefaultSettingsWarning] All fields must have an entry in the defaultValues object. Missing: ${missingFromDefaults.join(
        ', ',
      )}`,
    );
  }

  // Form a { key: value } object that can be provided to Formik's setValues.
  const diffObjFromDefaults = (function () {
    const diffObj: FormValues = {};

    diffFromDefaults.forEach((diff) => {
      diffObj[diff.fieldName] = diff.value;
    });

    return diffObj;
  })();

  const setFormToDefaults = () => {
    // Update Formik
    setValues({
      ...values,
      ...diffObjFromDefaults,
    });

    // Update useToggles
    if (toggleChecked) {
      diffFromDefaults.forEach((diff) => {
        // Don't toggle a form field that isn't a checkbox.
        if (!('checked' in diff.value)) {
          return;
        }

        toggleChecked(diff.fieldName);
      });
    }
  };

  if (!group || !dirty || !values) {
    return null;
  }

  if (hideWhenErrors && !isEmpty(errors)) {
    return null;
  }

  if (diffFromDefaults.length === 0) {
    return null;
  }

  return (
    <InfoBox color="warning" flexDirection="row" justifyContent="space-between">
      <span>{message}</span>
      <Button color="text" onClick={setFormToDefaults}>
        Match
      </Button>
    </InfoBox>
  );
};
