import { useFormikContext } from 'formik';
import { Row, Col, Input, Switch } from '@perts/ui';
import { SurveysEditMetricsFormValues } from './SurveysEditMetricsForm';
import { useTerms } from 'components';

export const SurveysEditMetricsFormSwitch = ({
  programValues,
  toggleChecked,
}: {
  programValues: SurveysEditMetricsFormValues;
  toggleChecked: Function;
}) => {
  const terms = useTerms();
  const { values, setValues } =
    useFormikContext<SurveysEditMetricsFormValues>();

  const onChange = () => {
    const newMetricsEnabled = !values.metricsEnabled;

    // Note: Checkboxes need to be updated BOTH with Formik and with useToggles.
    // For both below, we only need to toggle (to program defaults) when the
    // user is disabling the feature.

    // useToggles Change
    if (!newMetricsEnabled) {
      // Only when the default metrics feature has been disabled, then update
      // the checkboxes to reflect the program defaults.
      Object.keys(programValues).forEach((metricUid) => {
        // useToggles doesn't export a method to set the toggle value, only a
        // method for toggling, so we need to check each checkbox to see if it
        // needs toggling.
        if (values[metricUid].checked !== programValues[metricUid].checked) {
          toggleChecked(metricUid);
        }
      });
    }

    // Formik Change
    const newValues = {
      // Retain any other values the form may have.
      ...values,
      // Toggle the feature switch.
      metricsEnabled: newMetricsEnabled,
      // Only when the default metrics feature has been disabled, then update
      // the checkboxes to reflect the program defaults.
      ...(!newMetricsEnabled ? programValues : {}),
    };

    setValues(newValues);
  };

  const label = values.metricsEnabled ? (
    <span>
      <strong>Enabled</strong>: Use {terms.metrics.toLowerCase()} selected below
    </span>
  ) : (
    <span>
      <strong>Disabled</strong>: Use program defaults
    </span>
  );

  return (
    <Row>
      <Col>
        <Input
          id="metricsEnabled"
          name="metricsEnabled"
          component={Switch}
          label={label}
          labelPlacement="end"
          checked={values.metricsEnabled}
          onChange={onChange}
        />
      </Col>
    </Row>
  );
};
