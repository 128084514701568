import useToggles from 'utils/useToggles';

import { Show, FixedActionbarContainer } from '@perts/ui';

import { Class } from 'models';
import { useParams } from 'pages';

import { ArchivedWarning } from 'components/ArchivedWarning';
import { ClassesActionbarAdd } from './ClassesActionbarAdd';
import { ClassesActionbar } from './ClassesActionbar';
import { ClassesGuideBox } from './ClassesGuideBox';
import { ClassCard } from './ClassCard';
import { EmptyList } from 'components/EmptyList';
import { filterTokenOptions } from './filterTokenOptions';
import { useClientPagination } from 'utils/useClientPagination';
import useFilterField from 'utils/useFilterField';
import getUserName from 'utils/getUserName';

import { useTerms } from 'components/TermsContext';

export type ClassesRenderProps = {
  // Whether the current group is archived.
  archived: boolean;

  // The Classes being displayed.
  classes: Class[];

  // Permissions to allow actions
  mayAddClasses: boolean;
  mayEditMembers: boolean;
  mayEditGroups: boolean;
  mayDeleteClasses: boolean;
  mayEditTargetGroup: boolean;
  mayLockRoster: boolean;
  mayNavigateToRoster: boolean;
  mayEditNameInPlace: boolean;
  mayEditRosterIDRule: boolean;
  mayEditDefaultSettings: boolean;

  SortByComponent?: React.FC<any>;
};

const getFacilitatorNames = ({ facilitators = [] }: Class) =>
  facilitators.map(getUserName);

export const ClassesRender = ({
  archived,
  classes = [],
  mayAddClasses,
  mayEditMembers,
  mayEditGroups,
  mayDeleteClasses,
  mayEditTargetGroup,
  mayLockRoster,
  mayNavigateToRoster,
  mayEditNameInPlace,
  mayEditRosterIDRule,
  mayEditDefaultSettings,
  SortByComponent = () => null,
}: ClassesRenderProps) => {
  const { groupId } = useParams();
  const terms = useTerms();

  const classesToFilter = classes.map((cl) => ({
    ...cl,
    facilitatorNames: getFacilitatorNames(cl).join(' '),
  }));

  const { filteredItems, FilterField } = useFilterField(
    classesToFilter,
    ['name', 'facilitatorNames'],
    {
      label:
        `Filter by ${terms.class.toLowerCase()} name or ` +
        `${terms.classManager.toLowerCase()}`,
    },
    filterTokenOptions,
  );

  const { currentPageItems, Pagination } = useClientPagination({
    data: filteredItems,
  });

  const {
    ControlToggle,
    Toggle,
    checked,
    selected,
    toggleAll,
    toggleAllFilter,
  } = useToggles(classes, 'uid', {
    pageItems: currentPageItems,
    filteredItems,
  });

  const mayDoSomething =
    mayAddClasses ||
    mayEditMembers ||
    mayEditGroups ||
    mayDeleteClasses ||
    mayEditTargetGroup ||
    mayLockRoster ||
    mayEditRosterIDRule ||
    mayEditDefaultSettings;

  return (
    <>
      <Show when={mayDoSomething}>
        <FixedActionbarContainer>
          <Show when={archived}>
            <ArchivedWarning />
          </Show>
          <ClassesActionbarAdd
            mayAddClasses={mayAddClasses && !archived}
            mayEditDefaultSettings={mayEditDefaultSettings}
            filterComponent={FilterField}
            SortByComponent={SortByComponent}
          />

          <Show when={classes.length !== 0}>
            <ClassesActionbar
              ControlToggle={ControlToggle}
              checked={checked}
              toggleAll={toggleAll}
              toggleAllFilter={toggleAllFilter}
              classes={classes}
              selected={selected}
              mayEditMembers={mayEditMembers}
              mayEditGroups={mayEditGroups}
              mayDeleteClasses={mayDeleteClasses}
              mayEditTargetGroup={mayEditTargetGroup}
              mayLockRoster={mayLockRoster}
              Pagination={Pagination}
              mayEditRosterIDRule={mayEditRosterIDRule}
              filteredItems={filteredItems}
            />
          </Show>
        </FixedActionbarContainer>
      </Show>

      <Show when={classes.length === 0}>
        <EmptyList />
      </Show>

      <div role="list" data-testid="class-list-container">
        {currentPageItems.map((cls) => (
          <ClassCard
            key={cls.uid}
            groupId={groupId}
            cls={cls}
            toggle={
              mayDoSomething && <Toggle id={cls.uid} aria-label={cls.name} />
            }
            mayNavigateToRoster={mayNavigateToRoster}
            mayEditNameInPlace={mayEditNameInPlace}
            mayEditTargetGroup={mayEditTargetGroup}
            role="listitem"
            checked={checked[cls.uid]}
          />
        ))}
      </div>

      <ClassesGuideBox />
    </>
  );
};
