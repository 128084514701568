import { Route, Switch } from 'react-router-dom';
import {
  Home,
  toHome,
  toHomeGroupsAdd,
  toHomeGroupsCopy,
  toHomeGroupsArchive,
  toHomeGroupsCreateCourse,
  toHomeGroupsRemove,
  toHomeNetworksAdd,
  toHomeNetworksRemove,
} from 'pages';
import { GroupsAdd } from './GroupsAdd';
import { GroupsCopy } from './GroupsCopy';
import { GroupsArchive } from './GroupsArchive';
import { GroupsCreateCourse } from './GroupsCreateCourse';
import { GroupsRemove } from './GroupsRemove';
import { NetworksAdd } from './NetworksAdd';
import { NetworksRemove } from './NetworksRemove';

export const HomeRouter = () => (
  <>
    <Route path={toHome()}>
      <Home />
    </Route>
    <Switch>
      <Route path={toHomeGroupsAdd()}>
        <GroupsAdd />
      </Route>
      <Route path={toHomeGroupsCopy()}>
        <GroupsCopy />
      </Route>
      <Route path={toHomeGroupsArchive()}>
        <GroupsArchive />
      </Route>
      <Route path={toHomeGroupsCreateCourse()}>
        <GroupsCreateCourse />
      </Route>
      <Route path={toHomeGroupsRemove()}>
        <GroupsRemove />
      </Route>
      <Route path={toHomeNetworksAdd()}>
        <NetworksAdd />
      </Route>
      <Route path={toHomeNetworksRemove()}>
        <NetworksRemove />
      </Route>
    </Switch>
  </>
);
