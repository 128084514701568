import { forEach } from 'lodash';
import { capitalize } from './capitalize';

// Prepend the entity term with a/an depending on term. Terms that begin with
// a, e, i, o, u will be prepended with `an`, all others with `a`.
export const prependTerms = (terms) => {
  const prependedTerms = {};

  forEach(terms, (term, key) => {
    const prependKey = `a${capitalize(key)}`;

    prependedTerms[prependKey] = ['a', 'e', 'i', 'o', 'u'].includes(
      term.charAt(0).toLowerCase(),
    )
      ? `an ${term}`
      : `a ${term}`;
  });

  return prependedTerms;
};
