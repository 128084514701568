import groupBy from 'lodash/groupBy';
import orderBy from 'lodash/orderBy';

import { Report } from 'models';
import {
  get,
  queryForUserByOrg,
  queryByNetwork,
} from 'services/triton/reports';

// -----------------------------------------------------------------------------
//   API Functions
// -----------------------------------------------------------------------------

// Get the Report with `reportId`.

type ReportGetById = (reportId: string) => Promise<Report>;

export const reportGetById: ReportGetById = get;

// Query all Reports that belong to Team with `classId`.

type ReportsQueryByUserIdAndGroupId = (
  userId: string,
  groupId: string,
) => Promise<Report[]>;

type ReportMap = { [index: string]: Report[] };

export const reportsQueryByUserIdAndGroupId: ReportsQueryByUserIdAndGroupId =
  async (userId, groupId) => {
    const allReports = await queryForUserByOrg(userId, groupId);

    // Server returns reports from all dates. We want to reduce it to one
    // report per parent_id, and choose the most recent one.
    const byParent: ReportMap = groupBy(allReports, 'parent_id');
    const recentReports: Report[] = [];
    for (const reports of Object.values(byParent)) {
      // The group by operation should guarantee there are no empty arrays.
      recentReports.push(orderBy(reports, 'issue_date', 'desc')[0]);
    }

    return recentReports;
  };

type ReportsQueryByNetworkId = (networkId: string) => Promise<Report[]>;

export const reportsQueryByNetworkId: ReportsQueryByNetworkId = queryByNetwork;
