import styled from 'styled-components/macro';
import { Actionbar, Col, Row, Show } from '@perts/ui';
import { BulkSelectMessage } from 'components';
import { SurveysDropdownActions } from './SurveysDropdownActions';

const ActionsContainer = styled.div`
  display: flex;
  & > :not(:first-child) {
    margin-left: 10px;
  }
`;

export const SurveysActionbar = ({
  ControlToggle,
  checked,
  selected,
  someMarked,
  mayEdit,
  Pagination,
  classes,
  toggleAll,
  toggleAllFilter,
  filteredItems,
}) => {
  const totalSelected = selected.length;
  const totalClasses = classes.length;
  const totalFilteredItems = filteredItems.length;
  const totalItems =
    totalFilteredItems < totalClasses ? totalFilteredItems : totalClasses;

  const handleToggleAll =
    totalFilteredItems < totalClasses ? toggleAllFilter : toggleAll;

  return (
    <>
      <Actionbar>
        <Row alignItems="center" justifyContent="space-between">
          <Show when={mayEdit}>
            <Col shrink colsSm={12} cols={0}>
              <ActionsContainer>
                <ControlToggle />

                <SurveysDropdownActions
                  totalSelected={totalSelected}
                  checked={checked}
                />
              </ActionsContainer>
            </Col>
          </Show>
          <Col colsSm={12} cols={0}>
            <BulkSelectMessage
              totalSelected={totalSelected}
              totalItems={totalItems}
              toggleAll={handleToggleAll}
            />
          </Col>

          <Col
            vAlign="center"
            hAlign={mayEdit ? 'flex-end' : 'center'}
            hAlignSm="center"
            colsSm={12}
          >
            <Pagination />
          </Col>
        </Row>
      </Actionbar>
    </>
  );
};
