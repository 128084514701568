import styled from 'styled-components/macro';
import theme from 'components/theme';

const Table = styled.table`
  th,
  td {
    border: 1px solid ${theme.palette.darkGray};
    padding: ${theme.units.tableSpacing};
  }
`;

export default Table;
