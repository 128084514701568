import { cloneDeep, clone, set } from 'lodash';
import { useFormikContext } from 'formik';

import { Program } from 'models';
import { antdLocale, dateFormatShort, serverDateFormat } from 'config';

import { Button, IconAdd, Input, RangePicker, Show } from '@perts/ui';

import theme from 'components/theme';
import { RangePickerFooter } from '../ScheduleTable/RangePickerFooter';
import { ScheduleTableRow } from '../ScheduleTable/ScheduleTableRow';
import { ScheduleTableCol } from '../ScheduleTable/ScheduleTableCol';
import { getUpdatedErrorsFromCycle } from '../SchedulesEditCycles/getUpdatedErrorsFromCycle';
import { getCyclesToAddOrRemoveFromErrors } from '../SchedulesEditCycles/getCyclesToAddOrRemoveFromErrors';

type SchedulesTableDefaultSettingsProps = {
  currentMaxCycles: number;
  program: Program;
  onSubmit: any;
};

// Callback function tirggered by a cycle update
const handleChangeCallback =
  (
    values: any,
    modifiedCycleId: string,
    errors: any,
    setErrors: (errors: any) => void,
  ) =>
  (start_date: string | null, end_date: string | null) => {
    let updatedErrors = cloneDeep(errors);

    const cyclesValues = clone(values);
    const cyclesToCompare: {
      [key: string]: {
        start_date: string | null;
        end_date: string | null;
      };
    } = clone(values);

    delete cyclesToCompare[modifiedCycleId];

    const [errorsToAddModifiedCycle, errorsToRemoveModifiedCycle] =
      getCyclesToAddOrRemoveFromErrors({
        cyclesToCompare,
        start_date,
        end_date,
      });

    for (const cycleId in cyclesValues) {
      const { [cycleId]: currentCycleErrors = [] } = updatedErrors;

      const updatedCycleErrors = getUpdatedErrorsFromCycle({
        cycleId,
        modifiedCycleId,
        currentCycleErrors,
        errorsToAddModifiedCycle,
        errorsToRemoveModifiedCycle,
      });

      // Update errors
      updatedErrors = set(updatedErrors, cycleId, updatedCycleErrors);
    }

    // Remove empty errors
    Object.keys(updatedErrors).forEach(
      (k) =>
        (!updatedErrors[k] || updatedErrors[k].length === 0) &&
        delete updatedErrors[k],
    );

    setErrors(updatedErrors);
  };

export const SchedulesTableDefaultSettings = ({
  currentMaxCycles,
  program,
  onSubmit,
}: SchedulesTableDefaultSettingsProps) => {
  const { values, errors, setErrors } = useFormikContext<any>();

  const allowedAddCycle = program.max_cycles > currentMaxCycles;

  return (
    <ScheduleTableRow backgroundColor={theme.colors.primaryLight}>
      <ScheduleTableCol>
        <span>Default schedule</span>
      </ScheduleTableCol>

      {Array.from({ length: currentMaxCycles }).map((_, index) => {
        const cycleId = `cycle-${index}`;

        return (
          <ScheduleTableCol key={index} justifyContent="center">
            <Input
              id={cycleId}
              name={cycleId}
              label=""
              labelPlacement="top"
              formatDate={serverDateFormat}
              formatDateToShow={dateFormatShort}
              component={RangePicker}
              onChangeCallback={handleChangeCallback(
                values,
                cycleId,
                errors,
                setErrors,
              )}
              disabledDate={(d) =>
                !d ||
                d.isBefore(program.start_date) ||
                d.isAfter(program.end_date)
              }
              renderExtraFooter={() => (
                <Show when={currentMaxCycles > program.min_cycles}>
                  <RangePickerFooter
                    onClick={() => {
                      // Get cycle values from the form
                      const updatedValues = { ...values };

                      // Remove selected cycle from object
                      delete updatedValues[cycleId];

                      // Transform cycles object to schedules array
                      const schedules = Object.values(updatedValues);
                      onSubmit(schedules);
                    }}
                  />
                </Show>
              )}
              antdLocale={antdLocale}
            />
          </ScheduleTableCol>
        );
      })}

      {/* Column to show add button cycle if is allowed */}
      <Show when={allowedAddCycle}>
        <ScheduleTableCol>
          <Button
            color="text"
            aria-label="Add Cycle"
            iconLeft={<IconAdd />}
            onClick={() => {
              // Get cycle values from the form
              const updatedValues = { ...values };

              // Add new empty cycle to object
              updatedValues[`cycle-${currentMaxCycles}`] = {
                start_date: null,
                end_date: null,
              };

              // Transform cycles object to schedules array
              const schedules = Object.values(updatedValues);
              onSubmit(schedules);
            }}
          >
            Add Cycle
          </Button>
        </ScheduleTableCol>
      </Show>

      {/* Empty columns to complete row size */}
      <Show when={!allowedAddCycle}>
        <ScheduleTableCol />
      </Show>
    </ScheduleTableRow>
  );
};
