// NOTE: We discussed the useMutation* hook pattern and decided that we won't
// be using the below function going forward. Please see useMutationUpdate and
// useMutationDelete for examples.

// TODO Update useMutationDelete to handle the below case: deleting multiple
// entities at once and updating a cache that contains an array.

import { Entity } from 'models';

// Function to be used in the `mutationFn` argument of a useMutation hook call,
// specifically when removing entities from some query key. It sends separate
// API calls to delete each entity.
//
// Includes optimistic updates and a rollback.
//
// Example:
//
//   const queryClient = useQueryClient();
//   const mutationFn = ({ cycles }) =>
//     mutationRemoveFn<Cycle>(
//       queryClient,
//       removeCycleOnServer,
//       cycles,
//       queryKey,
//     );
//   const mutation = useMutation(mutationFn);

const mutationRemoveFn = async <EntityType extends Entity>(
  queryClient,
  removeEntityOnServer,
  deletedEntities: EntityType[],
  queryKey: string[],
) => {
  // Cancel outgoing refetches; avoid overwriting our optimistic update.
  await queryClient.cancelQueries(queryKey);

  // Snapshot the previous state.
  const previousEntities: EntityType[] =
    queryClient.getQueryData(queryKey) || [];

  // Optimistically update to the new value.
  const deletedIds = deletedEntities.map((e) => e.uid);
  const optimisticEntities: EntityType[] = previousEntities.filter(
    (e) => !deletedIds.includes(e.uid),
  );
  queryClient.setQueryData(queryKey, optimisticEntities);

  try {
    // Update the server.
    await Promise.all(deletedEntities.map((e) => removeEntityOnServer(e.uid)));
  } catch (error) {
    // Roll back and rethrow so onError can handle.
    queryClient.setQueryData(queryKey, previousEntities);
    throw error;
  } finally {
    // Invalidate cache to force refetch.
    // Will execute even if error is rethrown above.
    queryClient.invalidateQueries(queryKey);
  }

  // Won't execute if an error is thrown above.
  return optimisticEntities;
};

export default mutationRemoveFn;
