import { useCallback, useState } from 'react';
import { SelectFilter, SelectFilterGroup } from '@perts/ui';
import { SortByDirectionToggle } from './components';

export type SortByOptions = {
  value: string;
  name: string;
  sortByDirectionOptions: { name: React.ReactNode; value: string }[];
}[];

export const useSortBy = (
  // Note: This MUST be memoized with useMemo. See note below.
  sortByOptions: SortByOptions,
) => {
  const [selectedField, setSelectedField] = useState<any>(sortByOptions[0]);
  const [selectedDirection, setSelectedDirection] = useState<any>(
    selectedField.sortByDirectionOptions[0],
  );

  const setSelectedFieldAndDirection = useCallback(
    (value) => {
      setSelectedField(value);
      setSelectedDirection(value.sortByDirectionOptions[0]);
    },
    [setSelectedField, setSelectedDirection],
  );

  const toggleDirection = useCallback(
    () =>
      setSelectedDirection((current) => {
        const currentIndex = selectedField.sortByDirectionOptions.findIndex(
          (opt) => opt === current,
        );
        const nextIndex =
          (currentIndex + 1) % selectedField.sortByDirectionOptions.length;
        return selectedField.sortByDirectionOptions[nextIndex];
      }),
    [selectedField.sortByDirectionOptions, setSelectedDirection],
  );

  // Note: To prevent the SelectFilter from rerendering repeatedly and causing
  // the dropdown to close, we need to memoize the SortBy component. This then
  // requires each function and value provided to the dependency array to also
  // be memoized.
  const SortBy = useCallback(
    () => (
      <SelectFilterGroup>
        <SelectFilter
          label="Sort By"
          icon={<></>}
          options={sortByOptions}
          selected={selectedField}
          setSelected={setSelectedFieldAndDirection}
        />
        <SortByDirectionToggle
          value={selectedDirection}
          toggle={toggleDirection}
        />
      </SelectFilterGroup>
    ),
    [
      sortByOptions,
      selectedField,
      setSelectedFieldAndDirection,
      selectedDirection,
      toggleDirection,
    ],
  );

  const [sortByField, sortByDirection] = selectedDirection.value.split(':');

  if (!sortByField || Array.isArray(sortByField)) {
    return {
      sortByField,
      sortByDirection,
      SortBy: () => null,
    };
  }

  return {
    sortByField,
    sortByDirection,
    SortBy,
  };
};
