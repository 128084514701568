import { useState, useEffect, useCallback } from 'react';
import { Pagination as PaginationUi } from '@perts/ui';
import { useQueryParams } from 'utils/useQueryParams';

type UseClientPagination = {
  data: any[];
  initialSize?: number;

  // Custom query parameter name, perPage, default `page`.
  paramNamePerPage?: string;

  // Custom query parameter name, elementId, default `elementId`.
  paramNameElementId?: string;
};

export const useClientPagination = ({
  data = [],
  initialSize = 10,
  paramNamePerPage = 'page',
  paramNameElementId = 'elementId',
}: UseClientPagination) => {
  const { get, set, remove } = useQueryParams();

  const [pageSize, setPageSize] = useState(initialSize);
  const currentPage = parseInt(get(paramNamePerPage) || '1', 10);
  const currentPageIndex = currentPage - 1;

  // Handle user clicking navigation. Update the current page.
  const onChange = (pageIndex: number) => {
    const page = pageIndex + 1;
    set(paramNamePerPage, page.toString());
    // After update search param page, clean the paramElementId if it exists
    remove(paramNameElementId);
  };

  const scrollToElementId = useCallback(
    (paramElementId?: string) => {
      const elementId = paramElementId || get(paramNameElementId);
      // If elementId exists, the string is searched as `id`
      // and make scroll view to that element and set focus in the element
      if (elementId) {
        document
          .getElementById(elementId)
          ?.scrollIntoView({ block: 'center', inline: 'nearest' });

        document.getElementById(elementId)?.focus();
      }
    },
    [get, paramNameElementId],
  );

  // This useEffect triggers scrolling if it is necessaery when the page load
  // by first time
  useEffect(() => {
    scrollToElementId();
  }, [scrollToElementId]);

  const setCurrentPage = (page: number, elementId?: string) => {
    onChange(page - 1);
    // If elementId is sent, set query string param on url
    if (elementId) {
      set(paramNameElementId, elementId);
      scrollToElementId(elementId);
    }
  };

  // Data to display belongs to currentPage
  const currentPageItems = data.slice(
    currentPageIndex * pageSize,
    currentPageIndex * pageSize + pageSize,
  );

  const Pagination = () => (
    <PaginationUi
      total={data.length}
      pageIndex={currentPageIndex}
      pageSize={pageSize}
      onChange={onChange}
    />
  );

  return {
    currentPageItems,
    currentPage,
    pageSize,
    setPageSize,
    setCurrentPage,
    Pagination,
  };
};
