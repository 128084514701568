import styled from 'styled-components/macro';
import { css } from 'styled-components';
import theme from '../theme';

// Setting a column value to a number from 1 to 12 tells that column to occupy
// that much width of a 12-column layout, typical of Bootstrap and other similar
// systems.
//
// Setting a column value of 0 tells that column to have variable or "auto"
// width, according to its content.
//
// Omitting any column prop tells that column to fill the remaining width.
//
// See also Row > Col Auto in storybook.

// Styles heavily influecned by Quasar's Grid Row:
// https://quasar.dev/layout/grid/row

type AlignType =
  | 'center'
  | 'flex-start'
  | 'flex-end'
  | 'space-between'
  | 'space-around'
  | 'initial'
  | 'inherit';

type Display =
  | 'block'
  | 'inline'
  | 'inline-block'
  | 'flex'
  | 'inline-flex'
  | 'none'
  | 'inherit';

type ColProps = {
  cols?: number;
  colsLg?: number;
  colsMd?: number;
  colsSm?: number;
  offset?: number;
  offsetLg?: number;
  offsetMd?: number;
  offsetSm?: number;
  order?: number;
  orderLg?: number;
  orderMd?: number;
  orderSm?: number;
  hAlign?: AlignType;
  hAlignLg?: AlignType;
  hAlignMd?: AlignType;
  hAlignSm?: AlignType;
  vAlign?: AlignType;
  vAlignLg?: AlignType;
  vAlignMd?: AlignType;
  vAlignSm?: AlignType;
  display?: Display;
  displayLg?: Display;
  displayMd?: Display;
  displaySm?: Display;
  flexGrow?: number;
  flexWrap?:
    | 'nowrap'
    | 'wrap'
    | 'wrap-reverse'
    | 'inherit'
    | 'initial'
    | 'revert'
    | 'unset';
  shrink: boolean;
};

export const Col = styled(
  ({
    cols,
    colsLg,
    colsMd,
    colsSm,
    offset,
    offsetLg,
    offsetMd,
    offsetSm,
    order,
    orderLg,
    orderMd,
    orderSm,
    hAlign,
    hAlignLg,
    hAlignMd,
    hAlignSm,
    vAlign,
    vAlignLg,
    vAlignMd,
    vAlignSm,
    display,
    displayLg,
    displayMd,
    displaySm,
    shrink,
    flexGrow,
    flexWrap,
    ...rest
  }) => <div {...rest} />,
)<ColProps>`
  height: auto;

  max-width: 100%;

  /* Not specifying cols* will cause the Col to take up remainder of width. */
  width: auto;
  flex: 10000 1 0%;

  ${(props) =>
    props.flexWrap &&
    css`
      flex-wrap: ${props.flexWrap};
    `};

  ${(props) =>
    // Specifying `shrink` will cause the Col to take up a minimum width.
    props.shrink &&
    css`
      flex: inherit;
    `};

  ${({ cols }) =>
    Number.isInteger(cols) &&
    css`
      width: ${cols ? `${(cols / theme.units.gridColumns) * 100}%` : 'auto'};
      flex: 0 0 auto;
    `};

  ${({ offset }) =>
    Number.isInteger(offset) &&
    css`
      margin-left: ${(offset / theme.units.gridColumns) * 100}%;
    `};

  ${(props) =>
    (props.vAlign || props.hAlign) &&
    css`
      display: flex;
    `};

  ${(props) =>
    props.vAlign &&
    css`
      align-items: ${props.vAlign};
    `};

  ${(props) =>
    props.hAlign &&
    css`
      justify-content: ${props.hAlign};
      ${props.hAlign === 'center' ? 'text-align: center' : ''}
    `};

  ${(props) =>
    props.order &&
    css`
      order: ${props.order};
    `};

  ${({ display }) =>
    display &&
    css`
      display: ${display};
    `};

  ${({ flexGrow }: ColProps) =>
    flexGrow !== undefined &&
    css`
      flex-grow: ${flexGrow};
    `};

  @media only screen and (max-width: ${theme.units.gridBreakpointLarge}px) {
    ${({ colsLg }) =>
      Number.isInteger(colsLg) &&
      css`
        width: ${colsLg
          ? `${(colsLg / theme.units.gridColumns) * 100}%`
          : 'auto'};
        flex: 0 0 auto;
      `};

    ${({ offsetLg }) =>
      Number.isInteger(offsetLg) &&
      css`
        margin-left: ${(offsetLg / theme.units.gridColumns) * 100}%;
      `};

    ${({ orderLg }) =>
      orderLg &&
      css`
        order: ${orderLg};
      `};

    ${({ hAlignLg, vAlignLg }) =>
      (hAlignLg || vAlignLg) &&
      css`
        display: flex;
      `};

    ${({ hAlignLg }) =>
      hAlignLg &&
      css`
        justify-content: ${hAlignLg};
      `};

    ${({ vAlignLg }) =>
      vAlignLg &&
      css`
        justify-content: ${vAlignLg};
      `};

    ${({ displayLg }) =>
      displayLg &&
      css`
        display: ${displayLg};
      `};

    ${({ flexGrow }: ColProps) =>
      flexGrow !== undefined &&
      css`
        flex-grow: ${flexGrow};
      `};
  }

  @media only screen and (max-width: ${theme.units.gridBreakpointMedium}px) {
    ${({ colsMd }) =>
      Number.isInteger(colsMd) &&
      css`
        width: ${colsMd
          ? `${(colsMd / theme.units.gridColumns) * 100}%`
          : 'auto'};
        flex: 0 0 auto;
      `};

    ${({ offsetMd }) =>
      Number.isInteger(offsetMd) &&
      css`
        margin-left: ${(offsetMd / theme.units.gridColumns) * 100}%;
      `};

    ${(props) =>
      props.orderMd &&
      css`
        order: ${props.orderMd};
      `};

    ${({ hAlignMd, vAlignMd }) =>
      (hAlignMd || vAlignMd) &&
      css`
        display: flex;
      `};

    ${({ hAlignMd }) =>
      hAlignMd &&
      css`
        justify-content: ${hAlignMd};
      `};

    ${({ displayMd }) =>
      displayMd &&
      css`
        display: ${displayMd};
      `};

    ${({ flexGrow }: ColProps) =>
      flexGrow !== undefined &&
      css`
        flex-grow: ${flexGrow};
      `};
  }

  @media only screen and (max-width: ${theme.units.gridBreakpointSmall}px) {
    ${({ colsSm }) =>
      Number.isInteger(colsSm) &&
      css`
        width: ${colsSm
          ? `${(colsSm / theme.units.gridColumns) * 100}%`
          : 'auto'};
        flex: 0 0 auto;
      `};

    ${({ offsetSm }) =>
      Number.isInteger(offsetSm) &&
      css`
        margin-left: ${(offsetSm / theme.units.gridColumns) * 100}%;
      `};

    ${(props) =>
      props.orderSm &&
      css`
        order: ${props.orderSm};
      `};

    ${({ hAlignSm, vAlignSm }) =>
      (hAlignSm || vAlignSm) &&
      css`
        display: flex;
      `};

    ${({ hAlignSm }) =>
      hAlignSm &&
      css`
        justify-content: ${hAlignSm};
      `};

    ${({ vAlignSm }) =>
      vAlignSm &&
      css`
        justify-content: ${vAlignSm};
      `};

    ${({ displaySm }) =>
      displaySm &&
      css`
        display: ${displaySm};
      `};

    ${({ flexGrow }: ColProps) =>
      flexGrow !== undefined &&
      css`
        flex-grow: ${flexGrow};
      `};
  }
`;

Col.displayName = 'Col';
