import { GuideBox, HelpText, Link, Text } from '@perts/ui';

import { useTerms } from 'components/TermsContext';
import { helpArticles } from 'config';
import { Group } from 'models';
import { toGroupClasses } from 'pages';

type SurveysDefaultSettingsGuideBoxProps = {
  group: Group;
};

export const SurveysDefaultSettingsGuideBox = ({
  group,
}: SurveysDefaultSettingsGuideBoxProps) => {
  const terms = useTerms();

  return (
    <>
      <GuideBox
        action={
          <Text>
            Default {terms.metrics.toLowerCase()} apply to new{' '}
            {terms.classes.toLowerCase()} in {group.name}.
          </Text>
        }
        faq={
          <HelpText articleId={helpArticles.whatAreDefaults}>
            What are default {terms.metrics.toLowerCase()}?
          </HelpText>
        }
        next={<Link to={toGroupClasses(group.uid)}>{terms.classes}</Link>}
      />
    </>
  );
};
