import { attemptInterval, maxWait } from './config';

/**
 * Try to load a single image and hook into is load and error events to
 * detect problems.
 * @param {string} url of image
 * @return {Promise<string>} describing result
 * a promise which will resolve with 'load', 'error', or 'timeout',
 *   and only after both the image is resolved and the attemptInterval has
 *   has passed.
 * See: https://stackoverflow.com/questions/1977871/check-if-an-image-is-loaded-no-errors-in-javascript
 */
export const loadImage = (url: string): Promise<string> =>
  new Promise((resolve) => {
    let timeout;

    const resolveLater = (result) =>
      setTimeout(() => {
        resolve(result);
      }, attemptInterval);

    setTimeout(() => {
      clearTimeout(timeout);
      resolve('timeout');
    }, maxWait);

    const unixTimestamp = Math.floor(Date.now() / 1000);
    const img = document.createElement('img');
    img.src = `${url}&nocache=${unixTimestamp}`;

    img.onload = () => {
      timeout = resolveLater('load');
    };

    img.onerror = () => {
      timeout = resolveLater('error');
    };
  });
