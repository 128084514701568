import styled from 'styled-components/macro';
import { css } from 'styled-components';
import { Show, theme } from '../../';
import {
  IconCheckboxCheck,
  IconClose,
  IconCheckboxIndeterminate,
} from '../Icon';

const switchBorderRadius = '5px';

type SwitchContainerProps = {
  disabled?: boolean;
};

export const SwitchLabel = styled.label<SwitchContainerProps>`
  display: inline-flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  cursor: pointer;

  @media print {
    display: none;
  }

  ${(props) =>
    props.disabled &&
    css`
      cursor: not-allowed;
    `}
`;

export const SwitchLabelText = styled.span`
  padding: 6px;
`;

export const SwitchHidden = styled.input.attrs({ type: 'checkbox' })`
  /*
    Hide the browser default input UI. Don't use 'display: hidden' or
    'visibility: hidden' because then the input fields will be removed from the
    flow of the page and ignored by screen readers.
  */
  opacity: 0;

  /*
    Absolute so the hidden radio field doesn't displace other elements.
  */
  position: absolute;

  /*
  Dimensions should match SwitchButtonStyled so the click area of the input
  completely overlaps the visual/styled checkbox (SwitchButtonStyled).
  
  Width: total width of SwitchButtonStyled, including padding. (48 + 2 + 2)
  */
  width: 52px;
  height: 32px;

  border-radius: ${switchBorderRadius};
  cursor: inherit;

  &:focus {
    + div {
      outline: 2px solid ${theme.colors.primary};
      outline-offset: 2px;
      border-radius: 3px;
    }
  }
`;

type SwitchButtonProps = { checked: boolean; indeterminate: boolean };

export const SwitchButtonStyled = styled.div<SwitchButtonProps>`
  display: flex;
  justify-content: center;
  align-items: center;

  ${(props) =>
    props.checked &&
    !props.indeterminate &&
    css`
      justify-content: flex-end;
    `};

  ${(props) =>
    !props.checked &&
    !props.indeterminate &&
    css`
      justify-content: flex-start;
    `};

  width: 48px;
  min-width: 48px;
  height: 32px;

  padding: 2px;

  border: 2px solid #4a4a4a;
  border-radius: ${switchBorderRadius};

  svg {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 22px;
    height: 22px;

    color: #ffffff;
    background: #4a4a4a;
    border-radius: ${switchBorderRadius};
  }
`;

export const SwitchButton = ({ checked, indeterminate }: SwitchButtonProps) => (
  <SwitchButtonStyled checked={checked} indeterminate={indeterminate}>
    <Show when={indeterminate}>
      <IconCheckboxIndeterminate />
    </Show>

    <Show when={checked && !indeterminate}>
      <IconCheckboxCheck />
    </Show>

    <Show when={!checked && !indeterminate}>
      <IconClose />
    </Show>
  </SwitchButtonStyled>
);
