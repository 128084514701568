import noop from 'lodash/noop';

let PERTS = {};
let Raven = {};

try {
  // Browser enivronment.
  PERTS = window.PERTS || {};
  Raven = window.Raven || { captureMessage: noop };
} catch (e) {
  if (!(e instanceof ReferenceError)) {
    // Something weird happened.
    throw e;
  }
  // Else NodeJS environment (e.g. npm run faker) b/c there's no `window`.
}

/**
 * Represents a non-200 response from the server.
 * @param  {number}    code    status code of response, e.g. 500
 * @param  {string}    message description of the error
 * @return {error}             to be used to throw or reject
 */
export class FetchError extends Error {
  constructor(code, message = '') {
    super(message);
    this.code = code;
    this.message = message;
    this.name = 'FetchError';
  }
}

// reportApiError will report an API fetch response error to Sentry, or write
// out to console.error if not in the production environment. `fetchResponse`
// is assumed to not contain an `ok` property with value true.

export const reportApiError = (
  // https://developer.mozilla.org/en-US/docs/Web/API/Response
  fetchResponse,
) =>
  PERTS.isProduction
    ? Raven.captureMessage(
        `${fetchResponse.status} ${fetchResponse.statusText}`,
      )
    : console.error(
        'reportApiError():',
        fetchResponse.status,
        fetchResponse.statusText,
        fetchResponse.url,
        fetchResponse,
      );

export const logToSentry = (message) =>
  PERTS.isProduction ? Raven.captureMessage(message) : console.error(message);
