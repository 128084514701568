import { Program } from '../';
import { get, query, queryByUser } from 'services/triton/programs';

// -----------------------------------------------------------------------------
//   API Functions
// -----------------------------------------------------------------------------

// Get the Program with `id`.

// TODO Combine Program and ProgramEntity.

type ProgramGetById = (groupId: string) => Promise<Program>;

export const programGetById: ProgramGetById = get;

// Query for all Programs.

type ProgramsQuery = () => Promise<Program[]>;

export const programsQuery: ProgramsQuery = query;

// Query for programs related to the user.

type ProgramsQueryByUser = (userId: string) => Promise<Program[]>;

export const programsQueryByUser: ProgramsQueryByUser = (userId) =>
  queryByUser(userId);
