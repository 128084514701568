import { useState } from 'react';

import type { ProgramMetricConfig } from '@perts/config';
import type { DataOverTime, ExperienceByAttribute } from '@perts/model';
import { Col, Row } from '@perts/ui';

import {
  ExperienceBySurveyChart,
  ExperienceChildData,
} from 'components/ExperienceBySurveyChart';
import { Subset } from '../ExperienceDisaggregation/MetricQuestionSelect';
import { SelectFilterShow } from '../ExperienceDisaggregation/SelectFilterShow';

type Props = {
  metricLabel: string;
  metricName: string;
  dataOverall: DataOverTime;
  dataByItem: {
    [metricItemLabel: string]: ExperienceByAttribute;
  };
  programMetricConfig: ProgramMetricConfig;
};

export const MetricTimeline = ({
  metricLabel,
  metricName,
  dataOverall,
  dataByItem,
  programMetricConfig,
}: Props) => {
  const [showBy, setShowBy] = useState<string>('metricAndQuestions');

  const getPrompt = (itemLabel: string) =>
    programMetricConfig.metricIndex[metricLabel]?.itemIndex[itemLabel]?.prompts
      .en || null;

  // The overall metric score, aka "composite", always listed first.
  const seriesOverall: ExperienceChildData = {
    childName: metricName,
    ratedPositive: dataOverall,
    styleType: 'primary',
  };
  // Item-level scores.
  const seriesByItem: ExperienceChildData[] = Object.entries(dataByItem).map(
    ([itemLabel, experienceByAttribute]) => ({
      childName: getPrompt(itemLabel) || '',
      ratedPositive: experienceByAttribute.all_participants,
      styleType: 'secondary',
    }),
  );

  const seriesBySubset: { [subset in Subset]: ExperienceChildData[] } = {
    metricAndQuestions: [seriesOverall, ...seriesByItem],
    metricOnly: [seriesOverall], // default
  };

  return (
    <>
      <Row noPageBreakInside>
        <Col cols={6} colsMd={12}>
          <SelectFilterShow showBy={showBy} setShowBy={setShowBy} />
        </Col>
      </Row>
      <ExperienceBySurveyChart
        ariaLabel={`${metricName} rated positively by survey`}
        experienceByChild={seriesBySubset[showBy]}
      />
    </>
  );
};
