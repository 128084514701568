import { useContext } from 'react';
import pluralize from 'pluralize';
import { Class } from 'models';
import { Row, Col, Show } from '@perts/ui';
import TermsContext from 'components/TermsContext';

type ListClassesToRemoveProps = {
  classes: Class[];
};

export const ListClassesToRemove = ({ classes }: ListClassesToRemoveProps) => {
  const terms = useContext(TermsContext);

  return (
    <Show when={classes.length > 0}>
      <Row>
        <Col>
          Are you sure you want to <strong>delete</strong>{' '}
          {pluralize(
            `${terms.group.toLowerCase()} ${terms.class.toLowerCase()}`,
            classes.length,
            true,
          )}
          ?
        </Col>
      </Row>

      <Row>
        <Col>
          <ul>
            {classes.map((cls) => (
              <li key={cls.uid}>{cls.name}</li>
            ))}
          </ul>
        </Col>
      </Row>
    </Show>
  );
};
