import { Prompt, PromptRule } from 'models';

const emailMatch = (strMatcher: string, userEmail: string = '//') => {
  try {
    // Stored in database as a regex string
    if (
      strMatcher.charAt(0) === '/' &&
      strMatcher.charAt(strMatcher.length - 1)
    ) {
      const trimmedStrMatcher = strMatcher.slice(1, -1);
      const r = new RegExp(trimmedStrMatcher);
      return r.test(userEmail);
    }

    // Stored in database as a non-regex string
    return userEmail.endsWith(strMatcher);
  } catch (e) {
    return false;
  }
};

const byPriority = (a: Prompt, b: Prompt) => a.priority - b.priority;

type PromptRuleChecks = {
  programId: string;
  groupId: string;
  userEmail: string;
};

type RuleCheck = (rule: PromptRule, checks: PromptRuleChecks) => boolean;

const isAllowed: RuleCheck = (rule, { programId, groupId, userEmail }) =>
  rule.allowed &&
  (rule.program_id === null || rule.program_id === programId) &&
  (rule.organization_id === null || rule.organization_id === groupId) &&
  (rule.user_email === null || emailMatch(rule.user_email, userEmail));

const isDisallowed: RuleCheck = (rule, { programId, groupId, userEmail }) =>
  (!rule.allowed &&
    rule.program_id !== null &&
    rule.program_id !== programId) ||
  (!rule.allowed &&
    rule.organization_id !== null &&
    rule.organization_id !== groupId) ||
  (!rule.allowed &&
    rule.user_email !== null &&
    emailMatch(rule.user_email, userEmail));

type ShouldDisplayPrompt = (
  prompt: Prompt,
  checks: PromptRuleChecks,
) => boolean;

const shouldDisplayPrompt: ShouldDisplayPrompt = (prompt, checks) =>
  // Can disable prompts by setting priority `0`.
  prompt.priority > 0 &&
  // At least 1 allow rule must match.
  prompt.rules.some((rule) => isAllowed(rule, checks)) &&
  // None of the forbid rules may match.
  !prompt.rules.some((rule) => isDisallowed(rule, checks));

type PromptToDisplay = (
  prompts: Prompt[] | undefined,
  checks: PromptRuleChecks,
) => Prompt | undefined;

export const promptToDisplay: PromptToDisplay = (prompts = [], checks) =>
  prompts
    .sort(byPriority)
    .find((prompt) => shouldDisplayPrompt(prompt, checks));
