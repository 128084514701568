// https://docs.google.com/spreadsheets/d/1ZX4hcUol-_frFLfUYRnZ-UPEvpGsVOsWsJRw1g5tDBM

import keyBy from 'lodash/keyBy';

import { locales } from '../i18n';
import { CompositeMetricConfig, MetricItemConfig } from './types';

export const relationshipEfficacyItemConfig: MetricItemConfig[] = [
  {
    label: 'relationship_efficacy_pressure',
    likertN: 6,
    likertReverse: true,
    minGood: 5,
    prompts: {
      [locales.ENGLISH]:
        'Given the pressures that teachers face, it’s not possible for me ' +
        'to prioritize strong relationships with my students.',
    },
  },
  {
    label: 'relationship_efficacy_time',
    likertN: 6,
    likertReverse: true,
    minGood: 5,
    prompts: {
      [locales.ENGLISH]:
        'There isn’t enough time to get to know students’ lives outside of ' +
        'school.',
    },
  },
];

export const relationshipEfficacyConfig: CompositeMetricConfig = {
  label: 'relationship-efficacy',
  items: relationshipEfficacyItemConfig,
  itemIndex: keyBy(relationshipEfficacyItemConfig, 'label'),
};
