import { useParams } from 'react-router-dom';

import {
  Actionbar,
  Button,
  Col,
  FixedActionbarContainer,
  IconUserDelete,
  IconAdd,
  Row,
  Show,
} from '@perts/ui';
import {
  PagesParams,
  toNetworkMembersAdd,
  toNetworkMembersRemove,
} from 'pages';
import { useTerms } from 'components/TermsContext';
import { useOpenModal } from 'utils';

export const NetworkMembersActionbar = ({
  ControlToggle,
  checked,
  disabled,
  mayAddMembers,
  mayRemoveMembers,
  filterComponent,
}) => {
  const { networkId } = useParams<PagesParams>();
  const openModal = useOpenModal();
  const terms = useTerms();

  const openNetworkMembersRemove = () => {
    openModal(toNetworkMembersRemove(networkId), { checked });
  };

  return (
    <FixedActionbarContainer>
      <Actionbar paddingLeft={0}>
        <Row justifyContent="space-between" alignItems="flex-end">
          <Col colsSm={12}>{filterComponent}</Col>
          <Show when={mayAddMembers}>
            <Col colsSm={12} cols={0}>
              <Button
                to={toNetworkMembersAdd(networkId)}
                iconLeft={<IconAdd />}
                color="secondary"
                fullWidth
              >
                Add {terms.networkMembers}
              </Button>
            </Col>
          </Show>
        </Row>
      </Actionbar>

      <Show when={mayRemoveMembers}>
        <Actionbar>
          <Row alignItems="center">
            <Col colsSm={2} cols={0}>
              <ControlToggle />
            </Col>
            <Col colsSm={10} cols={0}>
              <Button
                onClick={openNetworkMembersRemove}
                disabled={disabled}
                fullWidth
                iconLeft={<IconUserDelete />}
              >
                Remove {terms.networkMembers}
              </Button>
            </Col>
          </Row>
        </Actionbar>
      </Show>
    </FixedActionbarContainer>
  );
};
