import { Modal } from '@perts/ui';

type SurveysEditMetricsFormWrapperProps = {
  close: () => void;
  children: React.ReactNode;
};

export const SurveysEditMetricsFormWrapper = ({
  children,
  close,
}: SurveysEditMetricsFormWrapperProps) => (
  <Modal close={close}>{children}</Modal>
);
