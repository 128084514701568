import styled from 'styled-components/macro';
import { SelectFilterStyles } from './SelectFilterStyles';
import { theme } from '../../';

export const SelectFilterGroup = styled.div`
  display: flex;

  > ${SelectFilterStyles} {
    button {
      border-radius: 0;

      border: 1px solid ${theme.colors.grayMedium};
      border-left: 1px dashed ${theme.colors.grayLight};
      border-right: 1px dashed ${theme.colors.grayLight};
    }

    &:first-child button {
      border-radius: 0;
      border-top-left-radius: ${theme.units.borderRadius};
      border-bottom-left-radius: ${theme.units.borderRadius};

      border: 1px solid ${theme.colors.grayMedium};
      border-left: 1px solid ${theme.colors.grayMedium};
      border-right: 1px dashed ${theme.colors.grayLight};
    }

    &:last-child button {
      border-radius: 0;
      border-top-right-radius: ${theme.units.borderRadius};
      border-bottom-right-radius: ${theme.units.borderRadius};

      border: 1px solid ${theme.colors.grayMedium};
      border-left: 1px dashed ${theme.colors.grayLight};
      border-right: 1px solid ${theme.colors.grayMedium};
    }
  }
`;
