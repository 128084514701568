/**
 * This is a conventional value used in the data warehouse, passed through into
 * dataset payloads. It's used to describe the attributePath i.e. population
 * being reported on. For example, we can report on a statistic for
 * "gender3.men", or "needs-security.high", or "all_participants".
 */
export const DISAGGREGATE_BY_ALL = 'all_participants';

export const SHOW_BY_METRIC = 'metricOnly';

export const SHOW_BY_METRIC_AND_QUESTIONS = 'metricOnlyAndQuestions';
