import { OrganizationDataV1_1_0 } from '@perts/model';
import { Card, HelpText, Text } from '@perts/ui';

import { helpArticles } from 'config';
import { User } from 'models';
import { useTerms } from 'components/TermsContext';
import { MemberParticipationTable } from 'components/MemberParticipationTable';

type Props = {
  dataset: OrganizationDataV1_1_0;
  users: User[];
};

export const DashboardMemberParticipation = ({ dataset, users }: Props) => {
  const terms = useTerms();

  return (
    <Card>
      <Card.Title>
        <Text as="h2">Participation by {terms.classManager}</Text>
        <HelpText articleId={helpArticles.participationHowToUseCommunity}>
          How to use this dashboard
        </HelpText>
      </Card.Title>
      <Card.Content>
        <MemberParticipationTable
          memberRateByCycle={dataset.member_rate_by_cycle}
          users={users}
        />
      </Card.Content>
    </Card>
  );
};
