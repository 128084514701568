import { Col, Input, Row, TextField } from '@perts/ui';
import { useFormikContext } from 'formik';

export const TextFieldOther = ({
  name,
  label,
  disabled,
  when,
}: {
  name: string;
  label: string;
  disabled: boolean;
  when: (values: any) => boolean;
}) => {
  const { values } = useFormikContext();

  if (!when(values)) {
    return null;
  }

  return (
    <Row>
      <Col>
        <Input
          id={name}
          name={name}
          label={label}
          labelPlacement="top"
          component={TextField}
          disabled={disabled}
        />
      </Col>
    </Row>
  );
};
