import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import pluralize from 'pluralize';
import { MembersPinFormSwitch } from './MembersPinFormSwitch';

import { Button, HelpText, Modal, useFormError, Row, Col } from '@perts/ui';

import { useTerms } from 'components/TermsContext';
import { helpArticles } from 'config';
import { selectUserGroupIsPinned } from 'models';
import { useParams } from 'pages';

const ValidationSchema = Yup.object().shape({
  isPinned: Yup.boolean(),
  isPinnedIndeterminate: Yup.boolean(),
});

export const MembersPinForm = ({ close, group, members, onSubmit }) => {
  const [FormError, showFormError] = useFormError();
  const { groupId } = useParams();
  const terms = useTerms();

  const isPinnedForAllMembers = members.every((member) =>
    selectUserGroupIsPinned(member, groupId),
  );

  const isPinnedForSomeMembers = members.some((member) =>
    selectUserGroupIsPinned(member, groupId),
  );

  const initialValues = {
    isPinned: isPinnedForAllMembers,
    isPinnedIndeterminate: isPinnedForSomeMembers && !isPinnedForAllMembers,
  };

  return (
    <Modal close={close}>
      <Modal.Title className="center">Edit {terms.group} Pins</Modal.Title>

      <Formik
        initialValues={initialValues}
        validationSchema={ValidationSchema}
        onSubmit={async ({ isPinned }) => {
          try {
            const updatedMembers = members.map((member) => {
              const pinned_organizations = isPinned
                ? // Pin the Group for this User.
                  [...member.pinned_organizations, group.uid]
                : // Unpin the Group for this User.
                  member.pinned_organizations.filter(
                    (uid) => uid !== group.uid,
                  );

              const updatedMember = { ...member, pinned_organizations };

              return updatedMember;
            });

            // Clear existing form error.
            showFormError(false);

            // Perform form onSubmit.
            await onSubmit(updatedMembers);

            // Close modal on successful form onSubmit.
            close();
          } catch (error) {
            // Display form error.
            showFormError(true);
          }
        }}
      >
        {({ isSubmitting, isValid, dirty }) => (
          <Form>
            <MembersPinFormSwitch group={group} members={members} />

            <Row>
              <Col hAlign="flex-end">
                <HelpText articleId={helpArticles.whatArePins}>
                  What are {terms.group.toLowerCase()} pins?
                </HelpText>
              </Col>
            </Row>

            <Row>
              <Col>
                <FormError />
              </Col>
            </Row>

            <Row>
              <Col cols={6} colsSm={12}>
                <Button
                  type="button"
                  color="secondary"
                  fullWidth
                  onClick={close}
                  disabled={isSubmitting}
                  fullHeight
                >
                  Cancel
                </Button>
              </Col>

              <Col cols={6} colsSm={12} hAlign="flex-end">
                <Button
                  type="submit"
                  fullWidth
                  disabled={!isValid || isSubmitting || !dirty}
                  loading={isSubmitting}
                  data-testid="submit-btn"
                >
                  {`Save Changes to ${pluralize(
                    terms.groupMember,
                    members.length,
                    true,
                  )}`}
                </Button>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
