import Entity from '../../Entity';
import { TRITON_URL_PREFIX } from '../config';
import { fetchApi } from './fetchApi';
import {
  fetchOptionsDELETE,
  fetchOptionsGET,
  fetchOptionsPATCH,
  fetchOptionsPOST,
  fetchOptionsPUT,
} from './fetchOptions';
import { paginationParamsToServer } from './paginationParamsToServer';
import { urlWithQueryParams } from './urlWithQueryParams';

export type QueryParamsPagination = {
  pageSize?: number;
  pageIndex?: number;
  order?: string;
};

export type QueryParams = QueryParamsPagination & {
  programId?: string;
  userId?: string;
  name?: string;
  pinned?: boolean;
  archived?: boolean;
};

/**
 * Since most of the fetch functions for triton services end up looking very
 * similar this function generates the commons ones (query, get, post, update,
 * remove) and allows you to pass additional functions in using the
 * overrideFunctions parameter.
 *
 * Example, calling generateFetchFunctions('participants') will generate:
 *   participants.get
 *   participants.query
 *   participants.queryByUser
 *   participants.post
 *   participants.postBatch
 *   participants.update
 *   participants.remove
 */

export function generateFetchFunctions<E extends Entity>(
  entityName: string,
  overrideFunctions = {},
) {
  const baseFunctions = {
    get: (entityId: string) => {
      const url = `${TRITON_URL_PREFIX}/api/${entityName}/${entityId}`;
      const options = fetchOptionsGET();
      return fetchApi(url, options);
    },

    query: (queryParams: QueryParams) => {
      const url = `${TRITON_URL_PREFIX}/api/${entityName}`;
      const options = fetchOptionsGET();
      const queryParamsToServer = paginationParamsToServer(queryParams);
      const urlWithParams = urlWithQueryParams(url, queryParamsToServer);
      return fetchApi(urlWithParams, options);
    },

    queryByUser: (userId: string, queryParams: QueryParams) => {
      const url = `${TRITON_URL_PREFIX}/api/users/${userId}/${entityName}`;
      const options = fetchOptionsGET();
      const queryParamsToServer = paginationParamsToServer(queryParams);
      const urlWithParams = urlWithQueryParams(url, queryParamsToServer);
      return fetchApi(urlWithParams, options);
    },

    post: (entity: E) => {
      const url = `${TRITON_URL_PREFIX}/api/${entityName}`;
      const options = fetchOptionsPOST(entity);
      return fetchApi(url, options);
    },

    postBatch: (entities: E[]) => {
      const path = `/api/${entityName}`;
      const url = `${TRITON_URL_PREFIX}${path}`;
      const options = fetchOptionsPATCH();
      options.body = JSON.stringify(
        entities.map((e) => ({ method: 'POST', path, body: e })),
      );
      return fetchApi(url, options);
    },

    update: (entity: E) => {
      const url = `${TRITON_URL_PREFIX}/api/${entityName}/${entity.uid}`;
      const options = fetchOptionsPUT(entity);
      return fetchApi(url, options);
    },

    remove: (entityId: string) => {
      const url = `${TRITON_URL_PREFIX}/api/${entityName}/${entityId}`;
      const options = fetchOptionsDELETE();
      return fetchApi(url, options);
    },
  };

  // DF: Can't seem to figure out how to properly type the return so that
  // TypeScript infers the types of the overrideFunctions provided. This type
  // is generic enough to seem to work, but the overrideFunctions don't have
  // nice intellisense and TypeScript won't warn if you attempt to destructure
  // a function that you does not exist.
  return { ...baseFunctions, ...overrideFunctions } as typeof baseFunctions & {
    [functionName: string]: (...params) => Promise<any>;
  };
}
