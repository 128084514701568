import { Col, Row, Show } from '@perts/ui';
import { useTerms } from 'components/TermsContext';
import { HighlightedStat } from './HighlightedStat';

const FIDELITY_THRESHOLD = 80;

type Props = {
  fidelityHonest: number | null;
  fidelityMakeBetter: number | null;
};

export const SurveyFidelityAverage = ({
  fidelityHonest,
  fidelityMakeBetter,
}: Props) => {
  const terms = useTerms();

  const lowMakeBetter =
    fidelityMakeBetter !== null && fidelityMakeBetter < FIDELITY_THRESHOLD;
  const lowHonest =
    fidelityHonest !== null && fidelityHonest < FIDELITY_THRESHOLD;

  return (
    <>
      {fidelityMakeBetter === null ? (
        <Row>
          <Col cols={3} hAlign="center" vAlign="center">
            Not enough data
          </Col>
          <Col cols={9} vAlign="center">
            Fewer than five {terms.participants.toLowerCase()} answered the
            question about whether they understood the purpose of the survey.
          </Col>
        </Row>
      ) : lowMakeBetter ? (
        <Row>
          <Col cols={3} hAlign="center" vAlign="center">
            <HighlightedStat bad>{fidelityMakeBetter}%</HighlightedStat>
          </Col>
          <Col cols={9} vAlign="center">
            {terms.fidelityMakeBetter}
          </Col>
        </Row>
      ) : (
        <Row>
          <Col cols={3} hAlign="center" vAlign="center">
            <HighlightedStat good>{fidelityMakeBetter}%</HighlightedStat>
          </Col>
          <Col cols={9} vAlign="center">
            {terms.fidelityMakeBetter}
          </Col>
        </Row>
      )}
      {fidelityHonest === null ? (
        <Row>
          <Col cols={3} hAlign="center" vAlign="center">
            Not enough data
          </Col>
          <Col cols={9} vAlign="center">
            Fewer than five {terms.participants.toLowerCase()} answered the
            question about whether they were comfortable answering the questions
            honestly.
          </Col>
        </Row>
      ) : lowHonest ? (
        <Row>
          <Col cols={3} hAlign="center" vAlign="center">
            <HighlightedStat bad>{fidelityHonest}%</HighlightedStat>
          </Col>
          <Col cols={9} vAlign="center">
            of surveyed {terms.participants.toLowerCase()} felt comfortable
            answering the questions honestly.
          </Col>
        </Row>
      ) : (
        <Row>
          <Col cols={3} hAlign="center" vAlign="center">
            <HighlightedStat good>{fidelityHonest}%</HighlightedStat>
          </Col>
          <Col cols={9} vAlign="center">
            of surveyed {terms.participants.toLowerCase()} felt comfortable
            answering the questions honestly.
          </Col>
        </Row>
      )}
      <Show when={lowMakeBetter || lowHonest}>
        <Row>
          <Col>
            <em>
              Values below {FIDELITY_THRESHOLD}% indicate more conversation is
              needed with {terms.participants.toLowerCase()} to receive reliable
              survey data.
            </em>
          </Col>
        </Row>
      </Show>
    </>
  );
};
