import { SelectFilter, SelectFilterSelected } from '@perts/ui';
import { EquityGapIcon } from 'pages/Reports/components';

type SelectFilterShowProps = {
  showBy: string;
  setShowBy: (selected: string) => void;
  questionsHaveGaps?: boolean;
};

export const SelectFilterShow = ({
  /**
   * "Show by..." either composite metric only `metricOnly`, or include
   * questions/items `metricAndQuestions`.
   */
  showBy,
  setShowBy,
  questionsHaveGaps = false,
}: SelectFilterShowProps) => {
  const options = [
    {
      value: 'metricAndQuestions',
      name: <>Question Detail{questionsHaveGaps && <EquityGapIcon />}</>,
    },
    {
      value: 'metricOnly',
      name: 'Average',
    },
  ];

  const selected =
    // @ts-ignore
    options.find((option) => option.value === showBy) || options[0];

  const setSelected = (s: SelectFilterSelected) => {
    if (!s || Array.isArray(s)) {
      // SelectFilter supports types that aren't used here.
      return;
    }

    setShowBy(s.value);
  };

  return (
    <SelectFilter
      label="Show"
      options={options}
      selected={selected}
      setSelected={setSelected}
    />
  );
};
