import { Route, Switch } from 'react-router-dom';
import {
  toGroupMembers,
  toGroupMembersAdd,
  toGroupMembersRemove,
  toGroupMembersSendInvitations,
  toGroupMembersManager,
  toGroupMembersLeave,
  toGroupMembersPin,
  Members,
  MembersAdd,
  MembersRemove,
  MembersSendInvitation,
  MemberLeave,
  MembersPin,
  MembersManager,
} from '../';

export const MembersRouter = () => (
  <>
    <Route path={toGroupMembers()}>
      <Members />
    </Route>

    <Switch>
      <Route path={toGroupMembersAdd()}>
        <MembersAdd />
      </Route>

      <Route path={toGroupMembersRemove()}>
        <MembersRemove />
      </Route>

      <Route path={toGroupMembersSendInvitations()}>
        <MembersSendInvitation />
      </Route>

      <Route path={toGroupMembersManager()}>
        <MembersManager />
      </Route>

      <Route path={toGroupMembersLeave()}>
        <MemberLeave />
      </Route>

      <Route path={toGroupMembersPin()}>
        <MembersPin />
      </Route>
    </Switch>
  </>
);
