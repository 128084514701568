import { theme } from 'components';
import styled from 'styled-components/macro';

export const NetworkTestContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.units.paddingLg};

  h1,
  p {
    margin: 0;
  }
`;

export const NetworkTestErrors = styled.div`
  padding: ${theme.units.paddingSm};

  text-align: left;

  background: ${theme.colors.grayLighten65};
  border: 1px solid ${theme.colors.grayDark};

  code {
    white-space: unset;
  }
`;
