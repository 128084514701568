import { useState } from 'react';

import { Card, Col, HelpText, Row, Show, Text, useBroadcast } from '@perts/ui';

import { Class, ClassDefault21Dataset, Metric, Program } from 'models';
import { channelFullwindow, helpArticles } from 'config';
import { toArchiveClassReportIdSettings, useParams } from 'pages';
import {
  ChangeLegend,
  EquityGapLegend,
  getEnabledMetrics,
  MetricSection,
  removeDisabledMetricsFromDataset,
  ReportSettingsLink,
  ReportStyled,
  SurveyFidelityAverage,
} from '../components';
import {
  ReportNavigation,
  StudentExperienceTable,
  StudentExperienceOverviewTable,
  SurveySampleOverviewTable,
} from '../';
import { ArchivedWarning } from 'components/ArchivedWarning';
import { useTerms } from 'components/TermsContext';

type Props = {
  archived: boolean;
  cls: Class;
  dataset: ClassDefault21Dataset;
  program: Program;
  programMetrics: Metric[];
  reportDate: string;
  reportMetrics: Metric[];
};

export const ClassReportDefault21Render = ({
  archived,
  cls,
  dataset: unfilteredDataset,
  program,
  programMetrics,
  reportDate,
  reportMetrics,
}: Props) => {
  const { groupId, reportId } = useParams();
  const terms = useTerms();

  const [isFullwindow, setFullwindow] = useState(false);
  const { listen } = useBroadcast(channelFullwindow);
  listen((shouldExpand) => setFullwindow(shouldExpand));

  const enabledMetrics = getEnabledMetrics(cls, reportMetrics);

  const dataset = removeDisabledMetricsFromDataset<ClassDefault21Dataset>(
    unfilteredDataset,
    enabledMetrics,
  );

  const toSettings = toArchiveClassReportIdSettings(groupId, cls.uid, reportId);

  if (enabledMetrics.length === 0) {
    return null;
  }

  return (
    <>
      <ReportStyled fullscreen={isFullwindow}>
        <ReportNavigation metrics={enabledMetrics} program={program} />

        <Show when={archived}>
          <ArchivedWarning isReport={true} />
        </Show>

        <Row>
          <Col cols={1}></Col>
          <Col cols={10}>
            <h1>
              {program.name} {terms.class} Report for {cls.name}
            </h1>
          </Col>
          <Col cols={1} colsSm={12} vAlign="center" hAlign="center">
            <ReportSettingsLink
              enabledMetrics={enabledMetrics}
              reportMetrics={reportMetrics}
              to={toSettings}
            />
          </Col>
        </Row>
        <Row>
          <Col cols={1}></Col>
          <Col cols={10}>
            <h2 id="student-experience-overview">
              {terms.participant} Experience Overview
            </h2>
          </Col>
          <Col cols={1}></Col>
        </Row>

        <Card>
          <Card.Content>
            <Row>
              <Col cols={6}>
                <HelpText
                  articleId={helpArticles.studentExperienceOverviewTable}
                >
                  How Can I Use the {terms.participant} Experience Overview?
                </HelpText>
              </Col>
              <Col cols={6} hAlign="flex-end">
                <ChangeLegend />
              </Col>
            </Row>
            <StudentExperienceOverviewTable
              data={dataset.student_experience_overview}
              metrics={programMetrics}
              nResponded={dataset.n_responded_participants}
              pctResponded={dataset.pct_responded}
              reportDate={reportDate}
            />
          </Card.Content>
        </Card>

        <h2 id="equity-overview">Equity Overview</h2>

        <Card>
          <Card.Content>
            <Row>
              <Col cols={6}>
                <HelpText articleId={helpArticles.equityOverviewTable}>
                  How Can I Use the Equity Overview?
                </HelpText>
              </Col>
              <Col cols={6} hAlign="flex-end">
                <EquityGapLegend />
              </Col>
            </Row>
            <StudentExperienceTable
              data={dataset.student_experience_overview}
              metrics={programMetrics}
              nResponded={dataset.n_responded_participants}
              pctResponded={dataset.pct_responded}
              reportDate={reportDate}
            />
          </Card.Content>
        </Card>

        <h2 id="survey-response-rates">Survey Response Rates</h2>

        <Card>
          <Card.Content>
            <Row>
              <Col>
                <Text as="h3">Participation by Subpopulation</Text>
              </Col>
            </Row>
            <Row>
              <Col>
                <HelpText
                  articleId={helpArticles.participationBySubpopulationTable}
                >
                  How Can I Use the Participation by Subpopulation Table?
                </HelpText>
              </Col>
            </Row>
            <SurveySampleOverviewTable
              data={dataset.survey_sample_overview}
              reportDate={reportDate}
            />
          </Card.Content>
        </Card>

        <h2 id="survey-fidelity">Survey Fidelity</h2>

        <Card>
          <Card.Content>
            <SurveyFidelityAverage
              fidelityMakeBetter={dataset.fidelity_make_better}
              fidelityHonest={dataset.fidelity_honest}
            />
          </Card.Content>
        </Card>

        {dataset.metrics.map((metricData) => {
          const metric = reportMetrics.find(
            (m) => m.label === metricData.label.replace(/_/g, '-'),
          );

          return metric ? (
            <MetricSection
              metric={metric}
              metricData={metricData}
              key={metric.label}
            />
          ) : null;
        })}
      </ReportStyled>
    </>
  );
};
