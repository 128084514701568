import { MemberCycle } from '@perts/model';

import {
  getMemberCycleStatus,
  MemberCycleStatus,
} from './getMemberCycleStatus';

// Used in calculating whether a community member has classes that are "active"
// according to their schedule and participation rate.
export const getMemberCycleActive = (memberCycle: MemberCycle | undefined) => {
  const status = getMemberCycleStatus(memberCycle);
  return (
    status === MemberCycleStatus.sufficient ||
    status === MemberCycleStatus.excellent
  );
};
