import * as types from './actionTypes';

export const setAuthenticating = () => ({
  type: types.SET_AUTHENTICATING,
});

export const tokenLoginUser = (token, redirect) => ({
  type: types.LOGIN_REQUEST,
  token,
  redirect,
});

export const loginUser = (googleUser, redirect) => ({
  type: types.LOGIN_REQUEST,
  googleUser,
  redirect,
});

export const loginFailure = (error) => ({
  type: types.LOGIN_FAILURE,
  error,
});

export const logoutUser = (redirect) => ({
  type: types.LOGOUT_REQUEST,
  redirect,
});

export const register = (email) => ({ type: types.REGISTER_REQUEST, email });

export const registerReset = () => ({ type: types.REGISTER_RESET });

export const setPassword = (token, password, redirect) => ({
  type: types.PASSWORD_SET_REQUEST,
  token,
  password,
  redirect,
});

export const passwordSetReset = () => ({ type: types.PASSWORD_SET_RESET });

export const resetPassword = ({ email, captchaResponse }) => ({
  type: types.PASSWORD_RESET_REQUEST,
  email,
  captchaResponse,
});

export const passwordResetReset = () => ({ type: types.PASSWORD_RESET_RESET });

export const checkToken = (token) => ({
  type: types.CHECK_TOKEN_REQUEST,
  token,
});

export const impersonateUser = (uid) => ({
  type: types.IMPERSONATE_USER_REQUEST,
  uid,
});

export const impersonateUserReset = () => ({
  type: types.IMPERSONATE_USER_RESET,
});
