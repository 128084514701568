import { getMessageFromErrors } from '@perts/util';
import { isOrganizationData, isOrganizationUserData } from '@perts/model';

import Loading from 'components/Loading';
import {
  useCurrentUserQuery,
  useGroupGetByParams,
  useProgramsQuery,
  useDatasetGroup,
  useDatasetGroupUser,
  useMetricsQuery,
} from 'models';
import { usePermissions } from 'utils';
import { ErrorMessageBox } from 'components/ErrorMessageBox';

import { getAvailableMetrics, NoReportMessage } from '../components';

// eslint-disable-next-line complexity
export const GroupReportLoader = ({ children }) => {
  const { mayGroupDataView, mayGroupUserDataView } = usePermissions();

  const currentUserQuery = useCurrentUserQuery();
  const programsQuery = useProgramsQuery();
  const metricsQuery = useMetricsQuery();
  const groupQuery = useGroupGetByParams();

  // For super admins, network managers, and group managers.
  const groupDataEnabled = Boolean(groupQuery.isSuccess) && mayGroupDataView;
  // For group members.
  const groupUserDataEnabled =
    Boolean(groupQuery.isSuccess) &&
    Boolean(currentUserQuery.isSuccess) &&
    !mayGroupDataView &&
    mayGroupUserDataView;

  const datasetGroupQuery = useDatasetGroup(groupQuery.data?.uid, {
    enabled: groupDataEnabled,
  });
  const datasetGroupUserQuery = useDatasetGroupUser(
    groupQuery.data?.uid,
    currentUserQuery.data?.uid,
    { enabled: groupUserDataEnabled },
  );

  if (
    currentUserQuery.isLoading ||
    datasetGroupQuery.isLoading ||
    datasetGroupUserQuery.isLoading ||
    groupQuery.isLoading ||
    metricsQuery.isLoading ||
    programsQuery.isLoading
  ) {
    return <Loading />;
  }

  if (
    !currentUserQuery.isSuccess ||
    !groupQuery.isSuccess ||
    !metricsQuery.isSuccess ||
    !programsQuery.isSuccess ||
    (!datasetGroupQuery.isSuccess && !datasetGroupUserQuery.isSuccess)
  ) {
    return (
      <ErrorMessageBox>
        {getMessageFromErrors([
          currentUserQuery.error,
          groupQuery.error,
          metricsQuery.error,
          programsQuery.error,
        ])}
      </ErrorMessageBox>
    );
  }

  const allMetrics = metricsQuery.data;
  const group = groupQuery.data;
  const dataset = groupDataEnabled
    ? datasetGroupQuery.data
    : groupUserDataEnabled
    ? datasetGroupUserQuery.data
    : undefined;

  if (dataset === undefined) {
    throw new Error(`Couldn't load dataset based on enabled queries.`);
  }
  if (dataset === null || !dataset.results) {
    return <NoReportMessage />;
  }
  if (
    (groupDataEnabled && !isOrganizationData(dataset)) ||
    (groupUserDataEnabled && !isOrganizationUserData(dataset))
  ) {
    return (
      <ErrorMessageBox>
        Unexpected dataset type: "{(dataset as any).dataset_type}"
      </ErrorMessageBox>
    );
  }

  const program = programsQuery.data.find((p) => p.uid === group.program_id);
  if (!program) {
    throw new Error(`Couldn't find program for org: ${group.program_id}`);
  }

  const programMetricIds = program.metrics.map(({ uid }) => uid);
  const programMetrics = allMetrics.filter(({ uid }) =>
    programMetricIds.includes(uid),
  );

  const reportMetrics = getAvailableMetrics(dataset.results, allMetrics);

  return children({
    dataset,
    group,
    lastRun: dataset.last_run,
    lastUpdatedResults: dataset.last_updated_results,
    program,
    programMetrics,
    reportMetrics,
    results: dataset.results,
    user: currentUserQuery.data,
  });
};
