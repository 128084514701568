import { useEffect, useState } from 'react';

export function useDebounce<T>(value: T, delay?: number) {
  const [debouncedValue, setDebouncedValue] = useState<T>(value);

  useEffect(() => {
    const debounceTimer = setTimeout(
      () => setDebouncedValue(value),
      delay || 500,
    );
    return () => clearTimeout(debounceTimer);
  }, [value, delay]);

  return debouncedValue;
}
