import { Program } from 'models';
import { TRITON_URL_PREFIX } from './config';
import { fetchApi, fetchOptionsGET, getAuthorization } from './helpers';

export interface ProgramMetricConfig {
  uid: string;
  default_active: boolean;
}

// This code is still here in case we need to process a property that uses
// `-1` to signal infinite/unlimited.
// const negativeToInfinity = (x: number) => (x < 0 ? Infinity : x);
const translateInfinities = (sqlProgram: Program): Program => ({
  ...sqlProgram,
  // max_cycles: negativeToInfinity(sqlProgram.max_cycles),
  // max_team_members: negativeToInfinity(sqlProgram.max_team_members),
});

export const query = (): Promise<Program[]> => {
  const options = {
    method: 'GET',
    headers: {
      Authorization: getAuthorization(),
    },
  };

  const url = `${TRITON_URL_PREFIX}/api/programs`;

  return fetchApi(url, options).then((programs) =>
    programs.map(translateInfinities),
  );
};

export const queryByUser = (userId: string): Promise<Program[]> => {
  const url = `${TRITON_URL_PREFIX}/api/users/${userId}/programs`;
  const options = fetchOptionsGET();
  return fetchApi(url, options);
};

export const get = (programId: string): Promise<Program> => {
  const options = {
    method: 'GET',
    headers: {
      Authorization: getAuthorization(),
    },
  };

  const url = `${TRITON_URL_PREFIX}/api/programs/${programId}`;

  return fetchApi(url, options).then(translateInfinities);
};

export const search = (programLabel: string, searchQuery: string) => {
  const options = {
    method: 'GET',
    headers: {
      Authorization: getAuthorization(),
    },
  };

  const encodedQ = encodeURIComponent(searchQuery);
  const url = `${TRITON_URL_PREFIX}/api/programs/${programLabel}/search?q=${encodedQ}`;

  return fetchApi(url, options);
};
