import React from 'react';

import { Show } from '@perts/ui';
import { StudentExperienceOverviewRow } from 'models';
import { ChangeText } from '../components';

// What's in props?
// https://github.com/tannerlinsley/react-table/blob/master/docs/api/useTable.md#user-content-column-object-cell

// What's in a row?
// https://github.com/tannerlinsley/react-table/blob/master/docs/api/useTable.md#user-content-row-properties

type CellProps = {
  row: { original: StudentExperienceOverviewRow };
};

export const cellRatedPositively =
  (key, showChange) =>
  ({ row }: CellProps) => {
    const rp = 'rated_positive';
    const recentDisplay = row.original[`${rp}_recent_${key}`] || '';
    const changeDisplay = row.original[`${rp}_change_${key}`] || '';
    const changeNum = row.original[`${rp}_change_${key}_num`] || 0;

    return (
      <>
        {recentDisplay}
        <Show when={showChange}>
          <ChangeText data={changeNum}>{changeDisplay}</ChangeText>
        </Show>
      </>
    );
  };
