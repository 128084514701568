import styled from 'styled-components/macro';

import theme from 'components/theme';

export const ClassCardFacilitatorContainer = styled.div`
  display: inline-block;
  margin-left: 5px;
  font-size: ${theme.units.fontBase};
  word-break: break-word;
`;
