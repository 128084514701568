// https://docs.google.com/spreadsheets/d/1ZX4hcUol-_frFLfUYRnZ-UPEvpGsVOsWsJRw1g5tDBM

import keyBy from 'lodash/keyBy';

import { locales } from '../i18n';
import { CompositeMetricConfig, MetricItemConfig } from './types';
import { catalyze24CompletelyTrueOptions } from './answerOptions/catalyze24CompletelyTrueOptions';

export const plc24TeacherObservationsItemConfig: MetricItemConfig[] = [
  {
    label: 'plc_observed_peer_teaching',
    likertN: 4,
    minGood: 3,
    options: catalyze24CompletelyTrueOptions,
    prompts: {
      [locales.ENGLISH]:
        'This past month, to what extent have you experienced the following ' +
        'with your PLC colleagues: We observed each others’ teaching.',
    },
  },
  {
    label: 'plc_discussed_peer_teaching',
    likertN: 4,
    minGood: 3,
    options: catalyze24CompletelyTrueOptions,
    prompts: {
      [locales.ENGLISH]:
        'This past month, to what extent have you experienced the following ' +
        'with your PLC colleagues: We discussed our observations of each ' +
        'others’ teaching.',
    },
  },
];

export const plc24TeacherObservationsConfig: CompositeMetricConfig = {
  label: 'plc-teacher-observations',
  items: plc24TeacherObservationsItemConfig,
  itemIndex: keyBy(plc24TeacherObservationsItemConfig, 'label'),
  name: 'Activities: Teacher Observations',
  description:
    "Teacher observations of colleagues' classroom teaching can provide " +
    'invaluable insights into instructional practices, promote reflective ' +
    'teaching, and facilitate collaborative growth by allowing educators to ' +
    "learn from each other's experiences and expertise.",
};
