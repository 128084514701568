import styled from 'styled-components/macro';
import { Button } from '@perts/ui';

type ApplyToAllStyledButtonProps = {
  onClick?: () => void;
  noPaddingOnContainer?: boolean;
  noPaddingTop?: boolean;
};

const ApplyToAllStyledContainer = styled.div<ApplyToAllStyledButtonProps>`
  padding: ${(props) => (props.noPaddingOnContainer ? '0' : '16px 0 0 16px')};
  height: auto;
  border: none !important;
  box-shadow: none;
  box-sizing: border-box;

  button {
    height: 100%;
    padding: ${(props) => (props.noPaddingTop ? '0 0 8px' : '0 8px')};
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    white-space: nowrap;
    align-items: center;
    color: #464646;
    background: white;
    border: 1px solid #464646;
    border-radius: 5px;
    cursor: pointer;
    box-shadow: none;

    span {
      text-shadow: none;
    }
  }

  @media print {
    display: none;
  }
`;

export const ApplyToAllStyledButton = ({
  onClick,
  noPaddingOnContainer = false,
}: ApplyToAllStyledButtonProps) => (
  <ApplyToAllStyledContainer noPaddingOnContainer={noPaddingOnContainer}>
    <Button
      onClick={onClick}
      color="secondary"
      type="submit"
      aria-label="Apply Disaggregation To All"
      tabIndex={'0'}
    >
      Apply to all
    </Button>
  </ApplyToAllStyledContainer>
);
