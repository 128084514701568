import {
  selectUserClasses,
  useCurrentUserQuery,
  useClassesQueryByParams,
  useGroupGetByParams,
} from 'models';

import { getMessageFromErrors } from '@perts/util';

import { ReportsRender } from './ReportsRender';
import Loading from 'components/Loading';
import { ErrorMessageBox } from 'components/ErrorMessageBox';
import { usePermissions } from 'utils';

type ReportsProps = {
  toGroupReport?: (groupId?: string) => string;
  toClassReport?: (groupId?: string, classId?: string) => string;
};

export const Reports = ({ toGroupReport, toClassReport }: ReportsProps) => {
  const { isAdmin } = usePermissions();

  const currentUserQuery = useCurrentUserQuery();
  const groupQuery = useGroupGetByParams();
  const classesQuery = useClassesQueryByParams();

  if (
    groupQuery.isLoading ||
    classesQuery.isLoading ||
    currentUserQuery.isLoading
  ) {
    return <Loading />;
  }

  if (
    !classesQuery.isSuccess ||
    !currentUserQuery.isSuccess ||
    !groupQuery.isSuccess
  ) {
    return (
      <ErrorMessageBox>
        {getMessageFromErrors([
          classesQuery.error,
          currentUserQuery.error,
          groupQuery.error,
        ])}
      </ErrorMessageBox>
    );
  }

  const classes = classesQuery.data;
  const group = groupQuery.data;
  const user = currentUserQuery.data;

  // Filter to appropriate classes. Only "facilitators" aka class leads may
  // view class reports.
  const allowedClasses = isAdmin ? classes : selectUserClasses(user, classes);

  return (
    <ReportsRender
      group={group}
      classes={allowedClasses}
      toGroupReport={toGroupReport}
      toClassReport={toClassReport}
    />
  );
};
