import {
  cycleCompletionThreshold,
  cycleExcellentThreshold,
} from '@perts/config';
import { MemberCycle } from '@perts/model';

// Used for:
// * Displaying status in the participation dashboard
// * Calculating whether a community member has classes that are "active"
//   (see getMemberCycleActive)

export enum MemberCycleStatus {
  notStarted = 'notStarted',
  started = 'started',
  future = 'future',
  dne = 'dne',

  // These all count as "active" cycles, where there is enough participation
  // according to our minimum threshold. None of these are individually
  // named "complete" or "active" because you can't test just one to determine
  // that. See getMemberCycleActive.
  sufficient = 'sufficient',
  excellent = 'excellent',
}

export const getMemberCycleStatus = (
  memberCycle: MemberCycle | undefined,
): MemberCycleStatus => {
  // If startDate is not set, the user has no cycles for this ordinal.
  if (!memberCycle || !memberCycle.min_start_date) {
    return MemberCycleStatus.dne;
  }

  // If the cycle is in the future, we don't want to interpret particiation
  // rate. Interpret cycle dates in the user's local time.
  if (new Date(memberCycle.min_start_date) > new Date()) {
    return MemberCycleStatus.future;
  }

  // Otherwise, show info about the participation rate.
  if (memberCycle.max_participation_rate >= cycleExcellentThreshold) {
    return MemberCycleStatus.excellent;
  }
  if (memberCycle.max_participation_rate >= cycleCompletionThreshold) {
    return MemberCycleStatus.sufficient;
  }
  if (memberCycle.max_participation_rate > 0) {
    return MemberCycleStatus.started;
  }
  return MemberCycleStatus.notStarted;
};
