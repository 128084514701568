import React from 'react';
import styled from 'styled-components/macro';

import { useSortBy, useTable } from 'react-table';
import { DEFAULT_PAGE_SIZE } from 'config';
import { ResponseStatFooter } from '../components';
import DataTable from 'components/DataTable';
import { Col, Row, theme } from '@perts/ui';

const SubHeader = styled.span`
  font-weight: normal;
  color: ${theme.colors.grayLight};
`;

const Table = ({ columns, data, reportDate, nResponded, pctResponded }) => {
  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows } =
    useTable(
      {
        columns,
        data,
        initialState: {
          pageSize: DEFAULT_PAGE_SIZE,
          sortBy: [{ id: 'metric_label' }],
        },
      },
      useSortBy,
    );

  return (
    <>
      <DataTable {...getTableProps()}>
        <thead>
          <tr>
            <th>{/* empty cell above metric names */}</th>
            <th colSpan={2}>
              Rated Positively on Last Survey
              <br />
              <SubHeader>± change since survey 1</SubHeader>
            </th>
            <th>{/* empty cell above resources */}</th>
          </tr>
          {headerGroups.map((headerGroup) => (
            <tr
              {...headerGroup.getHeaderGroupProps([
                {
                  className: headerGroup.className,
                  style: headerGroup.style,
                },
              ])}
            >
              {headerGroup.headers.map((column) => (
                <DataTable.SortHeader
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                >
                  {column.render('Header')}
                  <DataTable.SortIcon {...column} />
                </DataTable.SortHeader>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps}>
          {rows.map((row) => {
            prepareRow(row);

            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => (
                  <td
                    {...cell.getCellProps([
                      {
                        className: cell.column.className,
                        style: cell.column.style,
                      },
                    ])}
                  >
                    {cell.render('Cell')}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </DataTable>
      <Row>
        <Col hAlign="flex-start">
          <em>Data refreshed {reportDate}</em>
        </Col>
        <Col hAlign="flex-end">
          <ResponseStatFooter
            pctResponded={pctResponded}
            nResponded={nResponded}
          />
        </Col>
      </Row>
    </>
  );
};

export default Table;
