import { useQuery } from 'react-query';
import { Metric, metricsQuery } from 'models';

// -----------------------------------------------------------------------------
//   queryKey Generators
// -----------------------------------------------------------------------------

export const queryKeyMetrics = () => ['metrics'];

// -----------------------------------------------------------------------------
//   API Hooks
// -----------------------------------------------------------------------------

export const useMetricsQuery = () =>
  useQuery<Metric[], Error>(
    // queryKey
    queryKeyMetrics(),
    // queryFn
    () => metricsQuery(),
  );
