import moment from 'moment';

import { programs as programConfs } from '@perts/config';
import { useTerms } from 'components';
import { Group, User } from 'models';
import {
  toGroupClasses,
  toGroupMembers,
  toGroupParticipation,
  toGroupReports,
  toGroupSchedules,
  toGroupSurveys,
} from 'pages';

import { HomeCardButton } from './HomeCardButton';
import { HomeEditGroupName } from './HomeEditGroupName';
import { HomeGroupDropdown } from './HomeGroupDropdown';
import { GroupArchiveIcon } from './GroupArchiveIcon';
import { GroupPin } from './GroupPin';

import {
  Card,
  Col,
  IconCalendar,
  IconConversation,
  IconClipboardList,
  IconAward,
  IconReport,
  IconUserVoice,
  IconUsergroup,
  Row,
  Show,
} from '@perts/ui';
import { CANVAS_DOMAIN, CANVAS_PROTOCOL } from 'services/triton/config';
import { dateFormatShort } from 'config';

type CardGroupProps = {
  user: User;
  group: Group;
  queryKeyParams?: {
    name: string;
    pageIndex: number;
  };
  mayPin?: boolean;
  mayEditNameInPlace: boolean;
  mayRemoveGroup: boolean;
  maySeeTotalClasses: boolean;
  onCollapse?: () => void;
};

export const CardGroup = ({
  user,
  group,
  queryKeyParams,
  mayPin = false,
  mayEditNameInPlace,
  mayRemoveGroup,
  maySeeTotalClasses,
  onCollapse,
}: CardGroupProps) => {
  const terms = useTerms();

  const programConf = programConfs.find((c) => c.uid === group.program_id);
  const canvasProgramName = programConf?.canvasProgram?.name || '';

  const reportDate = group.last_updated_results
    ? moment(group.last_updated_results).format(dateFormatShort)
    : null;

  return (
    <Card archived={group.archived}>
      <Card.Title>
        <Row>
          {mayPin && queryKeyParams && !group.archived && (
            <Col shrink vAlign="center">
              <GroupPin
                user={user}
                group={group}
                queryKeyParams={queryKeyParams}
              />
            </Col>
          )}
          <Show when={group.archived}>
            <Col shrink vAlign="center">
              <GroupArchiveIcon />
            </Col>
          </Show>
          <Col>
            <HomeEditGroupName
              group={group}
              mayEditNameInPlace={mayEditNameInPlace}
            />
          </Col>
          <Show when={mayRemoveGroup}>
            <Col shrink hAlign="flex-end" vAlign="center">
              <HomeGroupDropdown group={group} onCollapse={onCollapse} />
            </Col>
          </Show>
        </Row>
      </Card.Title>

      <Card.Content>
        <Show when={Boolean(group.course_id)}>
          <Row>
            <Col>
              <HomeCardButton
                to={`${CANVAS_PROTOCOL}://${CANVAS_DOMAIN}/courses/${group.course_id}`}
                iconLeft={<IconAward />}
              >
                Go to {canvasProgramName}
              </HomeCardButton>
            </Col>
          </Row>
        </Show>
        <Row>
          <Col colsSm={6}>
            <HomeCardButton
              to={toGroupMembers(group.short_uid)}
              iconLeft={<IconUsergroup />}
            >
              {terms.groupMembers} ({group.num_users})
            </HomeCardButton>
          </Col>
          <Col colsSm={6}>
            <HomeCardButton
              to={toGroupClasses(group.short_uid)}
              iconLeft={<IconUserVoice />}
            >
              {terms.classes}{' '}
              <Show when={maySeeTotalClasses}>({group.num_teams})</Show>
            </HomeCardButton>
          </Col>
          <Col colsSm={6}>
            <HomeCardButton
              to={toGroupSchedules(group.short_uid)}
              iconLeft={<IconCalendar />}
            >
              Schedules
            </HomeCardButton>
          </Col>
          <Col colsSm={6}>
            <HomeCardButton
              to={toGroupSurveys(group.short_uid)}
              iconLeft={<IconClipboardList />}
            >
              Surveys
            </HomeCardButton>
          </Col>
          <Col colsSm={6}>
            <HomeCardButton
              to={toGroupParticipation(group.short_uid)}
              iconLeft={<IconConversation />}
            >
              <span>Participation</span>
            </HomeCardButton>
          </Col>
          <Col colsSm={6}>
            <HomeCardButton
              to={toGroupReports(group.short_uid)}
              iconLeft={<IconReport />}
            >
              Reports {reportDate && <>({reportDate})</>}
            </HomeCardButton>
          </Col>
        </Row>
      </Card.Content>
    </Card>
  );
};
