// Returns a react-query mutation for updates of a single entity.

// Assumptions:
// - The update will be performed on a single entity.
// - The queryKey points to a cache storing a single entity.

// TODO
// - Can handle queryKey/optimistic updates when cache is an array of entities.

// See for more details:
//
// - Mutations
//   https://react-query.tanstack.com/guides/mutations
// - Optimistic Updates
//   https://react-query.tanstack.com/guides/optimistic-updates
// - Query Cancellation
//   https://react-query.tanstack.com/guides/query-cancellation

import {
  MutateFunction,
  QueryKey,
  useMutation,
  useQueryClient,
} from 'react-query';
import { message } from 'antd';
import { Entity } from 'models';

type Options<EntityType, FormValuesType> = {
  // Transform function to convert the value/shape coming from the form into the
  // value/shape that is expected by the updateFn.
  transformFormValues?: (formValues: FormValuesType) => EntityType;

  // Toast message to display on successful update.
  toastSuccessMsg?: string;

  // Toast message to display on error.
  toastErrorMsg?: string;

  onMutate?: (formValues: FormValuesType) => Promise<{ previous?: EntityType }>;

  // This function will fire any time the query is either successfully fetched
  // or errors and be passed either the data or error.
  onSettled?: () => void;

  // Determine if it necessary do optimistically update
  optimisticallyUpdate?: boolean;
};

export function useMutationUpdate<
  EntityType extends Entity,
  FormValuesType = EntityType,
>(
  // The update function.
  // This function will be provided the form values.
  updateFn: MutateFunction<EntityType, Error, FormValuesType>,

  // react-query Query Key
  // https://react-query.tanstack.com/guides/query-keys
  queryKey: QueryKey,
  {
    onMutate,
    onSettled,
    optimisticallyUpdate = true,
    transformFormValues,
    toastSuccessMsg,
    toastErrorMsg,
  }: Options<EntityType, FormValuesType> = {},
) {
  const queryClient = useQueryClient();

  type ContextType = {
    previous: EntityType;
  };

  // Fallback to identity function if no tranform function is provided.
  const transformFn = transformFormValues ? transformFormValues : (i) => i;

  return useMutation<EntityType, Error, FormValuesType, ContextType>(
    // Mutation function
    // @ts-ignore
    async function mutateFn(formValues: FormValuesType) {
      const values = transformFn(formValues);
      await updateFn(values);
    },
    {
      onMutate: async (formValues: FormValuesType) => {
        if (optimisticallyUpdate) {
          // Cancel any outgoing refetches
          // (so they don't overwrite our optimistic update)
          await queryClient.cancelQueries(queryKey);

          // Snapshot the previous value.
          const previous = queryClient.getQueryData<EntityType>(queryKey);

          // Optimistically update with new value.
          const values = transformFn(formValues);
          queryClient.setQueryData<EntityType>(queryKey, values);

          // Return a context object with the snapshot value.
          return onMutate ? onMutate(formValues) : { previous };
        }

        return onMutate ? onMutate(formValues) : {};
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries(queryKey);

        // Pop a success toast, if a message was provided.
        toastSuccessMsg && message.success(toastSuccessMsg);
      },
      onError: (err, formValues, context) => {
        queryClient.invalidateQueries(queryKey);

        // Pop an error toast, if a message was provided.
        toastErrorMsg && message.error(toastErrorMsg);

        // Rollback to previous if there's an error.
        if (context?.previous) {
          queryClient.setQueryData<EntityType>(queryKey, context.previous);
        }
      },
      onSettled: () => {
        onSettled?.();
      },
    },
  );
}
