import pluralize from 'pluralize';
import { User } from 'models';
import { Col, InfoBox, Row, Show } from '@perts/ui';
import { useTerms } from 'components/TermsContext';

type ListNetworkMembersToRemoveProps = {
  includesSelf: boolean;
  members: User[];
};

export const ListNetworkMembersToRemove = ({
  includesSelf,
  members,
}: ListNetworkMembersToRemoveProps) => {
  const terms = useTerms();

  return (
    <Show when={members.length > 0}>
      <Row>
        <Col>
          Are you sure you want to remove{' '}
          {pluralize(terms.networkMember.toLowerCase(), members.length, true)}?
        </Col>
      </Row>

      <Row data-testid="members-list-to-remove">
        <Col>
          <ul>
            {members.map((member) => (
              <li key={member.uid} data-testid="members-list-to-remove-item">
                {member.email}
              </li>
            ))}
          </ul>
        </Col>
      </Row>

      <Show when={includesSelf}>
        <Row>
          <Col>
            <InfoBox color="warning" data-testid="members-list-self">
              This action will remove you from the network.
            </InfoBox>
          </Col>
        </Row>
      </Show>
    </Show>
  );
};
