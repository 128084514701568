import { Actionbar, Col, Row, Show } from '@perts/ui';
import styled from 'styled-components/macro';

import { BulkSelectMessage } from 'components/BulkSelectMessage';
import { ClassesDropdownActions } from './ClassesDropdownActions';

const ActionToggleContainer = styled.div`
  display: flex;
  & > :not(:first-child) {
    margin-left: 10px;
  }
`;

// eslint-disable-next-line complexity
export const ClassesActionbar = ({
  classes,
  ControlToggle,
  checked,
  toggleAll,
  toggleAllFilter,
  selected,
  mayEditMembers,
  mayEditGroups,
  mayDeleteClasses,
  mayEditTargetGroup,
  mayLockRoster,
  Pagination,
  mayEditRosterIDRule,
  filteredItems,
}) => {
  const totalSelected = selected.length;
  const totalClasses = classes.length;
  const totalFilteredItems = filteredItems.length;
  const totalItems =
    totalFilteredItems < totalClasses ? totalFilteredItems : totalClasses;

  const handleToggleAll =
    totalFilteredItems < totalClasses ? toggleAllFilter : toggleAll;

  const mayDoSomething =
    mayEditMembers ||
    mayEditGroups ||
    mayDeleteClasses ||
    mayEditTargetGroup ||
    mayLockRoster ||
    mayEditRosterIDRule;

  return (
    <Actionbar>
      <Row justifyContent="space-between">
        <Show when={mayDoSomething}>
          <Col colsSm={12} cols={0} shrink>
            <ActionToggleContainer>
              <ControlToggle />

              <ClassesDropdownActions
                totalSelected={totalSelected}
                checked={checked}
                mayEditMembers={mayEditMembers}
                mayDeleteClasses={mayDeleteClasses}
                mayEditTargetGroup={mayEditTargetGroup}
                mayEditGroups={mayEditGroups}
                mayEditRosterIDRule={mayEditRosterIDRule}
                mayLockRoster={mayLockRoster}
              />
            </ActionToggleContainer>
          </Col>
        </Show>

        <Col colsSm={12} cols={0}>
          <BulkSelectMessage
            totalSelected={totalSelected}
            totalItems={totalItems}
            toggleAll={handleToggleAll}
          />
        </Col>

        <Col
          vAlign="center"
          hAlign={mayDoSomething ? 'flex-end' : 'center'}
          hAlignSm="center"
          colsSm={12}
        >
          <Pagination />
        </Col>
      </Row>
    </Actionbar>
  );
};
