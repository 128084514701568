import { isEmpty } from 'lodash';
import { History } from 'history';
import { QueryClient } from 'react-query';

import { queryKeyCurrentUser } from 'models';
import { toHomeNoProgram } from 'pages';

type Options = {
  // If defined the user will be redirected to this route.
  redirect?: string;
  // If defined these queryKeys will be removed from the query cache.
  queryKeys?: string[];
};

type ErrorHandler = (
  queryClient: QueryClient,
  history: History,
  options?: Options,
) => (error) => void;

const errorHandler: ErrorHandler =
  (queryClient, history, options = {}) =>
  (error) => {
    if (error?.code === 403) {
      const { queryKeys = [], redirect = toHomeNoProgram() } = options;

      // Remove the cache data related to current user
      // to force refetch user data
      queryClient.removeQueries(queryKeyCurrentUser());

      // If queryKeys is not empty, we remove the cache data
      // related to the queryKeys options
      if (!isEmpty(queryKeys)) {
        queryKeys.forEach((q) => queryClient.removeQueries(q));
      }

      history.push(redirect);
    }
  };

export default errorHandler;
