import { ReactNode } from 'react';
import Truncate from 'react-truncate';

export type TruncateTextProps = {
  children: ReactNode;
  // Number of lines to display when truncating text.
  lines?: number;
  // Default behavior is not to consider width, and just to consider lines.
  // Adding a width value will override.
  truncateWidth?: number;
};

export const TruncateText = ({
  children,
  lines = 1,
  truncateWidth = 0,
}: TruncateTextProps) => {
  return (
    <Truncate lines={lines} trimWhitespace width={truncateWidth}>
      {children}
    </Truncate>
  );
};
