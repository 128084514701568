import { Formik, Form } from 'formik';
import { Input, TextField } from '@perts/ui';

export const DisaggregationTableFilter = ({ by, column, setFilter }) => {
  const { id } = column;
  const initialValues = { [id]: '' };
  const onSubmit = (values) => values;

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit}>
      {({ setFieldValue, values }) => (
        <Form>
          <Input
            component={TextField}
            displayError={false}
            id={id}
            name={id}
            label={`Filter by ${by}`}
            labelPlacement="start"
            fullWidth={true}
            maxLength={200}
            value={values[id] || ''}
            onChange={(e) => {
              // update react-table
              setFilter(id, e.target.value || undefined);
              // update formik
              setFieldValue(id, e.target.value || undefined);
            }}
          />
        </Form>
      )}
    </Formik>
  );
};
