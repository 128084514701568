import { GuideBox, HelpText, Link, Text } from '@perts/ui';

import { DefaultSchedule, Group, Program } from 'models';
import { toGroupSurveysDefaultSettings } from 'pages';
import { SchedulesDefaultSettingsForm } from './SchedulesDefaultSettingsForm';

import { useTerms } from 'components/TermsContext';
import { helpArticles } from 'config';

type ClassesDefaultSettingsRenderType = {
  group: Group;
  onSubmit: (values) => void;
  program: Program;
};

export const SchedulesDefaultSettingsRender = ({
  group,
  onSubmit,
  program,
}: ClassesDefaultSettingsRenderType) => {
  const terms = useTerms();
  const defaultSettings = group.default_settings || {};

  const defaultSchedule: DefaultSchedule[] = Array.from({
    length: program.default_cycles,
  }).map(() => ({
    start_date: null,
    end_date: null,
  }));
  const { schedules = defaultSchedule } = defaultSettings;

  return (
    <>
      <GuideBox
        action={
          <Text>
            The default schedule applies to new {terms.classes.toLowerCase()} in{' '}
            {group.name}.
          </Text>
        }
        faq={
          <HelpText articleId={helpArticles.whatAreDefaults}>
            What is a default schedule?
          </HelpText>
        }
        next={
          <Link to={toGroupSurveysDefaultSettings(group.uid)}>
            Default survey
          </Link>
        }
      />

      <SchedulesDefaultSettingsForm
        cycles={schedules}
        program={program}
        onSubmit={onSubmit}
      />
    </>
  );
};
