// Standardizes use of colorWhen and icons for all student experience tables.
//
// * When changes are > 5 %, color in green.
// * When changes are < -5 %, color in red.
// * Any value > 0 gets an up-arrow icon
// * Any value < 0 gets a down-arrow icon
//
// Example:
//
// return (
//   <ChangeText data={row.original.rated_positive_change_all_num}>
//     {row.original.rated_positive_change_all}
//   </ChangeText>
// );
import React, { ReactNode } from 'react';
import { Tooltip } from 'antd';

import { theme, Show } from '@perts/ui';

import colorWhen from 'components/colorWhen';
import { EXPERIENCE_CHANGE_THRESHOLD } from 'config';

const whenHigh = (x) => x >= EXPERIENCE_CHANGE_THRESHOLD;
const whenLow = (x) => x <= -1 * EXPERIENCE_CHANGE_THRESHOLD;

export const ColorChangeText = colorWhen([
  {
    ariaLabel: 'Highlighted positive change',
    color: theme.colors.textColorGreenOnGreen,
    backgroundColor: theme.colors.textBgGreen,
    when: whenHigh,
  },
  {
    ariaLabel: 'Highlighted negative change',
    color: theme.colors.textColorRedOnRed,
    backgroundColor: theme.colors.textBgRed,
    when: whenLow,
  },
]);

type Props = { children: ReactNode; data: number | null };

// The text version of the data, with possible coloring.
const ChangeDisplayText = ({ children, data }: Props) =>
  data === null ? (
    <span>—</span>
  ) : (
    <ColorChangeText data={data}>
      <Show when={data > 0}>+</Show>
      {children}
    </ColorChangeText>
  );

const tooltipText = `Changes ≥ +${EXPERIENCE_CHANGE_THRESHOLD} or ≤ -${EXPERIENCE_CHANGE_THRESHOLD} are highlighted.`;

// Conditionally wraps data display in a tooltip.
export const ChangeText = ({ children, data }: Props) =>
  whenHigh(data) || whenLow(data) ? (
    <Tooltip title={tooltipText} trigger={['hover', 'click']}>
      {/* Extra span b/c antd doesn't play well with styled components */}
      <span>
        <ChangeDisplayText data={data}>{children}</ChangeDisplayText>
      </span>
    </Tooltip>
  ) : (
    <ChangeDisplayText data={data}>{children}</ChangeDisplayText>
  );

export const ChangeLegend = () => (
  <span>
    Changes &ge;{' '}
    <ColorChangeText data={EXPERIENCE_CHANGE_THRESHOLD}>
      +{EXPERIENCE_CHANGE_THRESHOLD}
    </ColorChangeText>{' '}
    or &le;{' '}
    <ColorChangeText data={-1 * EXPERIENCE_CHANGE_THRESHOLD}>
      -{EXPERIENCE_CHANGE_THRESHOLD}
    </ColorChangeText>{' '}
    are highlighted
  </span>
);
