// https://docs.google.com/spreadsheets/d/1ZX4hcUol-_frFLfUYRnZ-UPEvpGsVOsWsJRw1g5tDBM

import keyBy from 'lodash/keyBy';

import { locales } from '../i18n';
import { CompositeMetricConfig, MetricItemConfig } from './types';
import { catalyze24CompletelyTrueOptions } from './answerOptions/catalyze24CompletelyTrueOptions';

export const plc24JointLessonPlanningItemConfig: MetricItemConfig[] = [
  {
    label: 'plc_joint_lesson_planning',
    likertN: 4,
    minGood: 3,
    options: catalyze24CompletelyTrueOptions,
    prompts: {
      [locales.ENGLISH]:
        'This past month, to what extent have you experienced the following ' +
        'with your PLC colleagues: We engaged in lesson or unit planning ' +
        'together.',
    },
  },
];

export const plc24JointLessonPlanningConfig: CompositeMetricConfig = {
  label: 'plc-joint-lesson-planning',
  items: plc24JointLessonPlanningItemConfig,
  itemIndex: keyBy(plc24JointLessonPlanningItemConfig, 'label'),
  name: 'Activities: Joint Lesson Planning',
  description:
    'Joint lesson planning encourages collective sense-making and ' +
    'understanding of common curriculum materials, leading to educators who ' +
    'are aligned in their teaching objectives and methodologies and are ' +
    'supported in enacting high-quality instruction.',
};
