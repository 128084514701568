import { message } from 'antd';
import { uniqBy } from 'lodash';
import pluralize from 'pluralize';
import { useMutation, useQueryClient } from 'react-query';

import { getMessageFromErrors } from '@perts/util';
import { ErrorMessageBox, Loading, useTerms } from 'components';
import {
  Class,
  dataIsEmpty,
  queryKeyClassesByGroup,
  selectGroupProgram,
  updateGroupClasses,
  useClassesQueryByParams,
  useGroupGetByParams,
  useMetricsQuery,
  useProgramsQuery,
} from 'models';
import { useParams } from 'pages';
import { useCloseModal, useGetCheckedStates } from 'utils';

import {
  SurveysEditMetricsForm,
  SurveysEditMetricsFormValues,
  toggleStatesMetrics,
  validateSurveyMetrics,
} from 'pages/Surveys/shared';
import { SurveysEditMetricsFormWrapper } from './SurveysEditMetricsFormWrapper';
import { getDefaultValues } from '../shared/SurveysEditMetricsForm/getDefaultValues';

// eslint-disable-next-line complexity
export const SurveysEditMetrics = () => {
  const { groupId } = useParams();
  const checked = useGetCheckedStates();
  const closeModal = useCloseModal();

  const terms = useTerms();

  const queryClient = useQueryClient();
  const queryUpdateFn = updateGroupClasses;
  const queryKey = queryKeyClassesByGroup(groupId);

  const {
    data: group,
    error: groupError,
    isLoading: groupIsLoading,
    isSuccess: groupIsSuccess,
  } = useGroupGetByParams();

  // Query for Programs.
  const {
    data: programs = [],
    error: programsError,
    isLoading: programsIsLoading,
    isSuccess: programsIsSuccess,
  } = useProgramsQuery();

  // Query for Classes associated with Group.
  const {
    data: classes = [],
    error: classesError,
    isLoading: classesIsLoading,
    isSuccess: classesIsSuccess,
  } = useClassesQueryByParams();

  // Query for Metrics
  const {
    data: metrics = [],
    error: metricsError,
    isLoading: metricsIsLoading,
    isSuccess: metricsIsSuccess,
  } = useMetricsQuery();

  const mutation = useMutation(queryUpdateFn, {
    onMutate: (values: Class[]) => {
      // Snapshot previous.
      const previous = queryClient.getQueryData<Class[]>(queryKey);

      // Optimistic update.
      const optimisticCacheValue = previous
        ? // if existing cache, combine updated with existing cache value
          uniqBy([...values, ...classes], 'uid')
        : // else, just add update to the cache
          values;

      queryClient.setQueryData<Class[]>(queryKey, optimisticCacheValue);

      // Return previous snapshot for rollbacks.
      return { previous };
    },

    onSuccess: () => {
      message.success(
        `Successfully edited conditions for ${terms.classes.toLowerCase()}.`,
      );
    },

    onError: (_err: Error, _data, context) => {
      message.error(
        `Unable to edit conditions for ${terms.classes.toLowerCase()}.`,
      );

      // Rollback any optimistic updates performed.
      if (context?.previous) {
        queryClient.setQueryData(queryKey, context.previous);
      }
    },

    onSettled: () => {
      // Always refetch after an error or success.
      queryClient.invalidateQueries(queryKey);
    },
  });

  const isLoading =
    groupIsLoading || programsIsLoading || classesIsLoading || metricsIsLoading;

  if (isLoading) {
    <SurveysEditMetricsFormWrapper close={closeModal}>
      <Loading />
    </SurveysEditMetricsFormWrapper>;
  }

  // Display any errors.
  const displayError =
    !classesIsSuccess ||
    !groupIsSuccess ||
    !metricsIsSuccess ||
    !programsIsSuccess;

  if (displayError) {
    return (
      <ErrorMessageBox>
        {getMessageFromErrors([
          classesError,
          groupError,
          metricsError,
          programsError,
        ])}
      </ErrorMessageBox>
    );
  }

  if (
    !group ||
    dataIsEmpty(classes) ||
    dataIsEmpty(programs) ||
    dataIsEmpty(metrics)
  ) {
    // TODO Define how it should be handled undefined or empty necessary data
    return null;
  }

  const checkedClasses = classes.filter((c) => checked[c.uid]);

  const program = selectGroupProgram(group, programs);

  const programMetrics = metrics.filter(({ uid }) =>
    program.metrics.find((m) => m.uid === uid),
  );

  const submitButtonText = `Save Changes to ${pluralize(
    terms.class,
    checkedClasses.length,
    true,
  )}`;

  const onSubmit = async (values: SurveysEditMetricsFormValues) => {
    const metricUidsToInclude = Object.keys(values).filter(
      (uid) => values[uid].checked,
    );

    const metricUidsToExclude = Object.keys(values).filter(
      (uid) => !values[uid].checked && !values[uid].indeterminate,
    );

    const updatedClasses = checkedClasses.map((cls) => {
      const updatedClassMetrics =
        // Add any metric UIDs this class is missing.
        [...new Set([...cls.metrics, ...metricUidsToInclude])]
          // Remove any metric UIDs this class has.
          .filter((uid) => !metricUidsToExclude.includes(uid));

      return {
        ...cls,
        metrics: updatedClassMetrics,
        open_responses: updatedClassMetrics,
      };
    });

    await mutation.mutateAsync(updatedClasses);

    closeModal();
  };

  const initialValues = toggleStatesMetrics(programMetrics, checkedClasses);

  const defaultValues = getDefaultValues(program, group);

  return (
    <SurveysEditMetricsFormWrapper close={closeModal}>
      <SurveysEditMetricsForm
        close={closeModal}
        submitButtonText={submitButtonText}
        onSubmit={onSubmit}
        validate={validateSurveyMetrics}
        initialValues={initialValues}
        defaultValues={defaultValues}
        // MetricSet related props
        availableMetrics={programMetrics}
        initialMetrics={checkedClasses}
        metricSets={program.metric_sets}
      />
    </SurveysEditMetricsFormWrapper>
  );
};
