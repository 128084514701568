import { Switch, Route } from 'react-router-dom';
import {
  Schedules,
  SchedulesDefaultSettings,
  SchedulesRemove,
  toGroupSchedules,
  toGroupSchedulesDefaultSettings,
  toGroupSchedulesRemove,
} from 'pages';

export const SchedulesRouter = () => (
  <>
    <Switch>
      <Route path={toGroupSchedulesDefaultSettings()}>
        <SchedulesDefaultSettings />
      </Route>

      <Route path={toGroupSchedules()}>
        <Schedules />
      </Route>
    </Switch>

    <Switch>
      <Route path={toGroupSchedulesRemove()}>
        <SchedulesRemove />
      </Route>
    </Switch>
  </>
);
