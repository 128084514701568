import type { FormikErrors } from 'formik';

import {
  Card,
  CheckboxWithLabel,
  Col,
  HelpText,
  InfoBox,
  Row,
  Show,
} from '@perts/ui';

import { MetricSetsWithMetrics } from 'models';
import { useTerms } from 'components';
import type { ToggleStates } from 'utils/useToggles';

type Props = {
  selectedMetricSets: MetricSetsWithMetrics[];
  isChecked: (metricId: string) => boolean;
  isIndeterminate: (metricId: string) => boolean;
  toggleChecked: (key: string) => void;
  setFieldValue: (field: string, value: any) => void;
  isSubmitting: boolean;
  values: ToggleStates;
  isViewingActive: boolean;
  errors: FormikErrors<any>;
  isDisabled: boolean;
};

export const SurveysEditMetricsFormContent = ({
  selectedMetricSets,
  isChecked,
  isIndeterminate,
  toggleChecked,
  setFieldValue,
  isSubmitting,
  values,
  isViewingActive,
  errors,
  isDisabled = false,
}: Props) => {
  const terms = useTerms();

  return (
    <Row>
      <Col>
        {selectedMetricSets.map((metricSet) => (
          <Card key={metricSet.uid}>
            <Card.Title>
              <Row alignItems="center">
                <Col>
                  <h3>{metricSet.name}</h3>
                </Col>

                {metricSet.details.articleId && (
                  <Col shrink>
                    <HelpText articleId={metricSet.details.articleId}>
                      Learn more
                    </HelpText>
                  </Col>
                )}

                {metricSet.details.learnMoreLink && (
                  <Col shrink>
                    <HelpText to={metricSet.details.learnMoreLink}>
                      Learn More
                    </HelpText>
                  </Col>
                )}
              </Row>
            </Card.Title>
            <Card.Content>
              <div data-testid="SurveysDefaultSettingsCheckboxes">
                {metricSet.metrics.map((metric) => {
                  if (!metric) {
                    return null;
                  }

                  return (
                    <CheckboxWithLabel
                      key={metric.uid}
                      name={metric.uid}
                      value={metric.uid}
                      label={metric.name}
                      checked={isChecked(metric.uid)}
                      indeterminate={isIndeterminate(metric.uid)}
                      onChange={() => {
                        // Update useToggles
                        toggleChecked(metric.uid);

                        // Update Formik
                        setFieldValue(metric.uid, {
                          checked: !values[metric.uid].checked,
                          indeterminate: false,
                        });
                      }}
                      disabled={isSubmitting || isDisabled}
                      className=""
                    />
                  );
                })}
              </div>
            </Card.Content>
          </Card>
        ))}

        <Show when={isViewingActive && Boolean(errors.metrics)}>
          <InfoBox color="error">
            Please find available {terms.metrics} in the View menu above.
          </InfoBox>
        </Show>
      </Col>
    </Row>
  );
};
