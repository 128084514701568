import { createUid } from './createUid';

// Use only if you need return the long uid related
// or an empty empty string if the id doesn't exist
export const getLongUid = (kind, id) => {
  if (id) {
    return createUid(kind, id);
  }

  return '';
};
