import { Class } from 'models';

export const getInitialValues = (classes: Class[]) => {
  const hasDisabled = classes.some(
    // Ensure an explicit check against boolean true, not using coercion, as
    // non-empty string values will coerce to true, potentially leading to
    // unintended matches.
    (cls) => cls.meta.gender_question_disabled === true,
  );

  const hasEnabled = classes.some((cls) => !cls.meta.gender_question_disabled);

  return {
    genderQuestionEnabled:
      hasEnabled && hasDisabled
        ? // indeterminate when both enabled and disabled are found.
          null
        : hasEnabled,
  };
};
