import { Card } from '@perts/ui';
import { useTerms } from 'components';
import {
  Class,
  Group,
  queryKeyClass,
  updateClass,
  useMutationUpdate,
} from 'models';
import {
  RosterSignOnTypeForm,
  RosterSignOnTypeFormValues,
  getRosterSignOnTypeSettings,
  getDefaultValuesSignOn,
  getInitialValuesSignOn,
} from 'pages/Classes/shared';

export const ClassRosterSettingsSignOnTypeForm = ({
  group,
  cls,
}: {
  group: Group;
  cls: Class;
}) => {
  const terms = useTerms();

  const initialValues = getInitialValuesSignOn<RosterSignOnTypeFormValues>(
    [cls],
    ['portalType'],
  );
  const defaultValues = getDefaultValuesSignOn(group);

  const queryKey = queryKeyClass(cls.uid);

  const mutation = useMutationUpdate<Class, Class>(updateClass, queryKey, {
    optimisticallyUpdate: false,
    toastSuccessMsg: `Successfully updated ${terms.class.toLowerCase()} settings.`,
    toastErrorMsg: `There was a problem updating ${terms.class.toLowerCase()} settings.`,
  });

  const onSubmit = async (values: RosterSignOnTypeFormValues) => {
    const rosterSignOnProps = getRosterSignOnTypeSettings({
      values,
      terms,
      classes: [cls],
    });

    const updatedClass = {
      ...cls,
      ...rosterSignOnProps,
    };

    await mutation.mutateAsync(updatedClass);
  };

  return (
    <Card>
      <Card.Content>
        <RosterSignOnTypeForm
          initialValues={initialValues}
          defaultValues={defaultValues}
          submitButtonText="Save Settings"
          onSubmit={onSubmit}
        />
      </Card.Content>
    </Card>
  );
};
