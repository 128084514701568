import { isCycleUnscheduled } from './isUnscheduled';

export const createCyclesLookupFromFormValues = (formValues: any) => {
  const lookup: { [uid: string]: boolean } = {};
  const classesArray = Object.values(formValues.classes);
  classesArray
    .flatMap((cls: any) =>
      Object.values(cls.cycles).map((cycle: any) => ({
        uid: cycle.uid,
        unscheduled: isCycleUnscheduled(cycle),
      })),
    )
    .forEach((cycle) => {
      lookup[cycle.uid] = cycle.unscheduled;
    });

  return lookup;
};
