import { useContext } from 'react';
import { GroupMember } from 'models';
import { Row, Col, InfoBox, Show } from '@perts/ui';
import TermsContext from 'components/TermsContext';

type ListMembersWithClassesProps = {
  members: GroupMember[];
};

export const ListMembersWithClasses = ({
  members,
}: ListMembersWithClassesProps) => {
  const terms = useContext(TermsContext);

  return (
    <Show when={members.length > 0}>
      <Row>
        <Col>
          <InfoBox
            color="warning"
            alignItems="flex-start"
            data-testid="members-list-with-classes"
          >
            <Row>
              <Col>
                {`These ${terms.groupMembers.toLowerCase()} still have ${terms.classes.toLowerCase()} and won’t be removed:`}
              </Col>
            </Row>

            <Row>
              <Col>
                <ul>
                  {members.map((member) => (
                    <li
                      key={member.uid}
                      data-testid="members-list-with-classes-item"
                    >
                      {member.email}
                    </li>
                  ))}
                </ul>
              </Col>
            </Row>
          </InfoBox>
        </Col>
      </Row>
    </Show>
  );
};
