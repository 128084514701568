import React from 'react';
import styled, { css } from 'styled-components/macro';
import { theme } from '../../';

type SelectFilterStylesProps = {
  /**
   * Optional. `true` to render the options horizontall instead of stacked.
   */
  horizontal?: boolean;

  /**
   * Optional. `true` disables the component interaction.
   */
  disabled?: boolean;

  /**
   * Optional. Default `left`. If the drop down will anchor to the left or right
   * side of the SelectFilter button.
   */
  anchor?: 'left' | 'right';

  children: React.ReactNode;
};

export const SelectFilterStyles = styled(
  ({ horizontal: _, ...other }: SelectFilterStylesProps) => {
    return <div {...other} />;
  },
)`
  font-size: 1.125rem;
  max-height: 40px;
  position: relative;

  /*
    Apply the cursor: not-allowed to the wrapper div when disabled because the
    ponter-events: none on button disables all interactions, including cursor.
  */
  ${(props) =>
    props.disabled &&
    css`
      cursor: not-allowed;
    `};

  button {
    @media print {
      border: 0;
    }

    padding: 0;
    padding-bottom: 5px;
    padding-right: 8px;

    > * {
      margin-left: 8px;
      margin-top: 5px;
    }

    display: flex;
    flex-direction: row;

    flex-wrap: ${(props) => (props.horizontal ? 'wrap' : 'nowrap')};
    white-space: ${(props) => (props.horizontal ? 'unset' : 'nowrap')};

    align-items: center;

    color: ${theme.colors.text};
    background: white;
    border: 1px solid ${theme.colors.grayMedium};
    border-radius: ${theme.units.borderRadius};

    cursor: pointer;

    ${(props) =>
      props.disabled &&
      css`
        pointer-events: none;
        background: ${theme.colors.grayLighten65};
        color: ${theme.colors.grayLighten3};
      `};
  }

  ul {
    position: absolute;
    z-index: 5;

    ${(props) =>
      props.anchor === 'right'
        ? css`
            right: 0;
          `
        : css`
            left: 0;
          `}

    margin: 0;
    margin-top: 2px;
    padding: 6px;

    background: white;
    border: 1px solid ${theme.colors.grayLight};
    border-radius: ${theme.units.borderRadius};

    box-shadow: 2px 2px 0 0 ${theme.colors.grayLighten3};

    li {
      list-style-type: none;

      display: ${(props) => (props.horizontal ? 'inline-flex' : 'flex')};
      flex-direction: row;
      white-space: nowrap;
      align-items: center;

      height: 32px;
      gap: 4px;
      padding: 0 8px 0 8px;
      border-radius: ${theme.units.borderRadius};

      cursor: pointer;

      &.clear {
        justify-content: center;
        align-items: center;
      }

      &[data-headlessui-state='active'],
      &.active,
      &:active,
      &:focus,
      &:hover {
        background: ${theme.colors.grayLighten65};
      }

      a {
        text-decoration: none;
        color: ${theme.colors.text};
      }
    }
  }
`;
