import {
  useClassGetByParams,
  useParticipantsByParams,
  sortParticipantByStudentId,
  useProgramGetByParams,
} from 'models';

import { ClassRosterParticipantsForm } from './ClassRosterParticipantsForm';
import Loading from 'components/Loading';
import { ErrorMessageBox } from 'components/ErrorMessageBox';
import { getMessageFromErrors } from '@perts/util';

// eslint-disable-next-line complexity
export const ClassRosterParticipants = () => {
  // Query for Program
  const { data: program } = useProgramGetByParams();

  // Query for Class.
  const {
    isLoading: classIsLoading,
    isError: classIsError,
    data: cls,
    error: classError,
  } = useClassGetByParams();

  // Query for Participants associated with Class.
  const {
    isLoading: participantsIsLoading,
    isError: participantsIsError,
    data: participants = [],
    error: participantsError,
  } = useParticipantsByParams();

  // Display loading.
  if (classIsLoading || participantsIsLoading) {
    return <Loading />;
  }

  if (!cls) {
    // TODO Handle empty pages.
    return null;
  }

  // Display any errors.
  if (classIsError || participantsIsError) {
    return (
      <ErrorMessageBox>
        {getMessageFromErrors([classError, participantsError])}
      </ErrorMessageBox>
    );
  }

  const targetGroupEnabled = Boolean(program && program.target_group_enabled);

  const mayEditTargetGroup =
    targetGroupEnabled && Boolean(cls.target_group_name);

  const maySeeTargetGroup =
    targetGroupEnabled && Boolean(cls.target_group_name);

  return (
    <ClassRosterParticipantsForm
      participants={participants.sort(sortParticipantByStudentId)}
      mayEditTargetGroup={mayEditTargetGroup}
      maySeeTargetGroup={maySeeTargetGroup}
      targetGroupEnabled={targetGroupEnabled}
    />
  );
};
