import { useParams } from 'react-router-dom';
import pluralize from 'pluralize';

import { Class, Program } from 'models';
import { toGroupSchedulesRemove, PagesParams } from 'pages';
import { useOpenModal } from 'utils';

import { RangePickerFooter } from '../RangePickerFooter';
import { useTerms } from 'components';

export const Footer = ({
  cycleOrdinal,
  program,
  selectedClasses = [],
}: {
  cycleOrdinal: number;
  program: Program;
  selectedClasses: Class[];
}) => {
  const openModal = useOpenModal();
  const { groupId } = useParams<PagesParams>();
  const terms = useTerms();

  // Determine the Cycle UIDs that can be deleted.
  const cycleUidsToDelete = selectedClasses
    .map((cls) => {
      // First, ensure this class has enough Cycles to allow for deletion.
      const cycleCanBeDeletedForClass =
        cls.cycles && cls.cycles.length > program.min_cycles;

      if (!cycleCanBeDeletedForClass) {
        return false;
      }

      // If we can delete, find the requested Cycle based on ordinal.
      const cycleToDelete = cls.cycles?.find(
        (cycle) => cycle.ordinal === cycleOrdinal,
      );

      // If a Cycle was found, return its UID. It's possible that the ordinal
      // does not match a Cycle for this particular Class.
      return cycleToDelete?.uid || false;
    })
    // Remove any of the `false` array values (which indicates no Cycle can be
    // deleted for the Class).
    .filter(Boolean);

  const numOfCyclesToDelete = cycleUidsToDelete.length;

  // Handle clicking the delete button.
  const openConfirmationModal = () => {
    const route = toGroupSchedulesRemove(groupId);
    openModal(route, {
      cycleUids: cycleUidsToDelete,
    });
  };

  const canBeBatchDeleted = numOfCyclesToDelete >= 1;

  const buttonText = canBeBatchDeleted
    ? `Delete ${pluralize(
        'Cycles',
        numOfCyclesToDelete,
        false, // don't prefix with number
      )} in ${pluralize(
        terms.classes,
        numOfCyclesToDelete,
        true, // prefix with number
      )}`
    : 'No Cycles can be deleted';

  return (
    <RangePickerFooter
      buttonText={buttonText}
      onClick={openConfirmationModal}
      disabled={!canBeBatchDeleted}
    />
  );
};
