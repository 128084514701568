import { useState } from 'react';
import { toNetwork } from 'pages';

import { Card, Show, Row, Col, Link } from '@perts/ui';

import { CardNetwork } from 'pages/Home/CardNetwork';

export const SearchCardNetwork = ({
  network,
  mayEditNameInPlace,
  mayRemoveNetworks,
  mayExpandCard,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <>
      <Show when={isExpanded}>
        <CardNetwork
          network={network}
          mayRemoveNetworks={mayRemoveNetworks}
          mayEditNameInPlace={mayEditNameInPlace}
        />
      </Show>

      <Show when={!isExpanded}>
        <Card key={network.uid}>
          <Card.Title>
            <Row>
              <Col>{network.name}</Col>

              <Show when={mayExpandCard}>
                <Col shrink>
                  <Link
                    onClick={() => {
                      setIsExpanded(!isExpanded);
                    }}
                  >
                    Expand Card
                  </Link>
                </Col>
              </Show>
              <Col shrink>
                <Link to={toNetwork(network.uid)}>View</Link>
              </Col>
            </Row>
          </Card.Title>
        </Card>
      </Show>
    </>
  );
};
