import {
  Class,
  Group,
  Metric,
  Participant,
  Report,
  selectMostCurrentReport,
  Survey,
  User,
} from 'models';
import { keyBy, mapValues, maxBy } from 'lodash';

export const selectClassFacilitators = (cls: Class, users: User[] = []) =>
  users.filter((u) => u.owned_teams.includes(cls.uid));

export const selectClassGroups = (cls: Class, groups: Group[] = []) =>
  groups.filter((group) => cls.organization_ids.includes(group.uid));

export const selectClassMetrics = (cls: Class, metrics: Metric[] = []) =>
  metrics.filter((c) => cls.metrics.includes(c.uid));

export const selectClassMetricLabels = (cls: Class, metrics: Metric[] = []) =>
  mapValues(keyBy(metrics, 'uid'), 'label');

export const selectClassParticipants = (
  cls: Class,
  participants: Participant[] = [],
) => participants.filter((participant) => participant.team_id === cls.uid);

export const selectMostCurrentClassReport = (
  cls: Class,
  reports: Report[] = [],
) =>
  selectMostCurrentReport(
    reports.filter((report) => report.parent_id === cls.uid),
  );

export const selectClassSurvey = (cls: Class, surveys: Survey[] = []) =>
  surveys.find((survey) => survey.uid === cls.survey_id);

export const sortClassByName = (a, b) => a.name.localeCompare(b.name);

// Returns the Reports found in `reports` that are associated with `cls`.
// Note: Class-level reports are not associated with the Class.uid but instead
// with the Class.classroom_id for backward compatibility reasons.

export const selectClassReports = (cls: Class, reports: Report[]) =>
  reports.filter((report) => report.parent_id === cls.classroom_id);

// Returns the Reports found in `reports` that are associated with `classes`.

export const selectClassesReports = (classes: Class[], reports: Report[]) =>
  classes.flatMap((cls) => selectClassReports(cls, reports));

// Returns the latest Report found in `reports` that is associated with
// `classes`.

export const selectClassesReportsLatest = (
  classes: Class[],
  reports: Report[],
) =>
  classes.flatMap((cls) => {
    const reportsByClass = selectClassReports(cls, reports);
    return maxBy(reportsByClass, 'issue_date') || [];
  });
