import { keyBy } from 'lodash';
import pluralize from 'pluralize';

import { OrganizationExperienceResults } from '@perts/model';
import {
  Button,
  Card,
  CardExpander,
  Col,
  HelpText,
  Row,
  Show,
  Text,
  theme,
} from '@perts/ui';

import { FIDELITY_THRESHOLD, helpArticles } from 'config';
import { Group } from 'models';
import { getRecentCycleValue } from 'utils';
import { useTerms } from 'components/TermsContext';
import { FidelityChildTable } from '../FidelityChildTable';
import { BarChartRatedPositively, FidelityWarningIcon } from '../components';

type Props = {
  groups: Group[];
  resultsByGroup: {
    [organizationId: string]: OrganizationExperienceResults;
  };
};

const NotEnoughData = () => {
  const terms = useTerms();
  return (
    <Card>
      <Card.Title>
        <Row>
          <Col cols={6}>
            <Text as="h2">Communication Fidelity</Text>
          </Col>
          <Col vAlign="center" hAlign="center" cols={6}>
            Not enough data
          </Col>
        </Row>
      </Card.Title>
      <Card.Content>
        <Row>
          <Col>
            {terms.groups} in this {terms.network.toLowerCase()} have not yet
            collected data on the question, &ldquo;{terms.fidelityMakeBetter}
            &rdquo;
          </Col>
        </Row>
      </Card.Content>
    </Card>
  );
};

export const CommunicationFidelityNetwork = ({
  groups,
  resultsByGroup,
}: Props) => {
  const terms = useTerms();

  // The calculations are easier if we use the decimal form.
  const threshold = FIDELITY_THRESHOLD / 100;

  const groupIndex = keyBy(groups, 'uid');
  const fidelityChildData = Object.entries(resultsByGroup).map(
    ([groupId, groupResults]) => ({
      groupId,
      name: groupIndex[groupId]?.name || 'Unknown',
      makeBetterData: groupResults.fidelity.make_better,
    }),
  );
  const numChildren = fidelityChildData.length;

  if (numChildren === 0) {
    return <NotEnoughData />;
  }

  // Count how many meet the threshold.
  const childrenAtThreshold = fidelityChildData.filter((row) => {
    const recentMakeBetter = getRecentCycleValue(row.makeBetterData);
    return recentMakeBetter !== null && recentMakeBetter >= threshold;
  });
  const numAtThreshold = childrenAtThreshold.length;
  const numBelowThreshold = numChildren - numAtThreshold;

  // Whether the recent value is below desired threshold.
  const lowMakeBetter = numAtThreshold < numChildren;

  return (
    <>
      <Card checked={lowMakeBetter}>
        <Card.Title>
          <Row>
            <Col shrink vAlign="center">
              <Text as="h2">
                <Show when={lowMakeBetter}>
                  <FidelityWarningIcon title="Needs improvement" />
                </Show>
                Communication Fidelity
              </Text>
            </Col>

            <Col vAlign="center" hAlign="flex-end">
              <HelpText articleId={helpArticles.surveyFidelityTable}>
                Why fidelity matters
              </HelpText>
            </Col>
          </Row>
        </Card.Title>
        <Card.Content>
          <Row>
            <Col>
              <span>
                {lowMakeBetter ? '' : <strong>Great work!</strong>} At least{' '}
                {FIDELITY_THRESHOLD}% of {terms.participants.toLowerCase()} in{' '}
                {pluralize(terms.group.toLowerCase(), numAtThreshold, true)}{' '}
                agreed with: &ldquo;{terms.fidelityMakeBetter}&rdquo;{' '}
                <Show when={lowMakeBetter}>
                  {pluralize(
                    terms.group.toLowerCase(),
                    numBelowThreshold,
                    true,
                  )}{' '}
                  should work to improve communication fidelity. See which
                  below.{' '}
                </Show>
              </span>
            </Col>
          </Row>

          <Row>
            <Col cols={6}>
              <BarChartRatedPositively
                color={
                  lowMakeBetter ? theme.colors.yellow : theme.colors.greenDark
                }
                height={40}
                label="Rated positively on last survey"
                value={numAtThreshold / numChildren}
                valueDisplay={
                  <strong>
                    {numAtThreshold} of {numChildren}
                  </strong>
                }
              />
            </Col>

            <Show when={lowMakeBetter}>
              <Col vAlign="center" hAlign="flex-end">
                <Button articleId={helpArticles.surveyFidelityTable}>
                  How to Improve Fidelity
                </Button>
              </Col>
            </Show>
          </Row>
        </Card.Content>
      </Card>
      <CardExpander startExpanded={lowMakeBetter}>
        <Card.Content>
          <FidelityChildTable data={fidelityChildData} />
        </Card.Content>
      </CardExpander>
    </>
  );
};
