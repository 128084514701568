import {
  GroupMember,
  selectUserAccountIsPending,
  selectUserClasses,
  useClassesQueryByParams,
  useCurrentUserQuery,
  useGroupGetByParams,
  useUsersQueryByParams,
} from 'models';
import { useParams } from 'pages';

import { useSortByMembers, ErrorMessageBox, Loading } from 'components';
import { MembersRender } from './MembersRender';

import { getMessageFromErrors } from '@perts/util';
import { orderBy } from 'lodash';

// eslint-disable-next-line complexity
export const Members = () => {
  const { groupId } = useParams();

  // Sorting
  const { SortBy, sortByField, sortByDirection } = useSortByMembers();

  const groupQuery = useGroupGetByParams();
  const currentUserQuery = useCurrentUserQuery();
  const usersQuery = useUsersQueryByParams();
  const classesQuery = useClassesQueryByParams();

  if (
    groupQuery.isLoading ||
    usersQuery.isLoading ||
    classesQuery.isLoading ||
    currentUserQuery.isLoading
  ) {
    return <Loading />;
  }

  // Display any errors.
  if (
    !groupQuery.isSuccess ||
    !usersQuery.isSuccess ||
    !classesQuery.isSuccess ||
    !currentUserQuery.isSuccess
  ) {
    return (
      <ErrorMessageBox>
        {getMessageFromErrors([
          groupQuery.error,
          usersQuery.error,
          classesQuery.error,
          currentUserQuery.error,
        ])}
      </ErrorMessageBox>
    );
  }

  // Create GroupMembers from Users.
  // TODO Convert to a custom useQuery hook.
  // ISSUE https://github.com/PERTS/perts/issues/67
  const members: GroupMember[] = orderBy(
    usersQuery.data.map((user) => ({
      ...user,
      classes: selectUserClasses(user, classesQuery.data),
      isManager: user.managed_organizations.includes(groupId),
      isPendingInvite: selectUserAccountIsPending(user),

      // Properties added for sorting
      classesLength: selectUserClasses(user, classesQuery.data).length,
    })),
    // Always secondary sort by name even when sorting by metrics.
    [sortByField, 'name'],
    [sortByDirection, 'asc'],
  );

  return (
    <MembersRender
      group={groupQuery.data}
      members={members}
      SortByComponent={SortBy}
    />
  );
};
