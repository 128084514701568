import { Class, Group, Metric, Network } from 'models';

type ReportingUnit = Network | Group | Class;

export const getEnabledMetrics = (ru: ReportingUnit, reportMetrics: Metric[]) =>
  ru.report_settings.enabled_metrics === undefined
    ? reportMetrics
    : reportMetrics.filter((metric) =>
        (ru.report_settings.enabled_metrics || []).includes(metric.uid),
      );
