import { DEFAULT_PAGE_SIZE } from 'config';

export const getNextConflict = (classes, errors) => {
  const classIDs = classes.map((cls) => cls.uid);
  const { classes: classErrors = {} } = errors;

  for (const classId of classIDs) {
    if (classErrors[classId]) {
      const classIndex = classes.findIndex((cls) => cls.uid === classId);

      const page = Math.ceil((classIndex + 1) / DEFAULT_PAGE_SIZE);

      return {
        classId,
        page,
      };
    }
  }

  return '';
};
