import { IconButton, IconPin } from '../../';

type Props = {
  /**
   * `true` if the button is disabled.
   */

  disabled?: boolean;

  /**
   * Event handler.
   */

  onClick: (event: any) => void;

  /**
   * `true` if button is in the pinned state, `false` if unpinned.
   */

  isPinned: boolean;
};

export const PinButton: React.FC<Props> = ({ disabled, onClick, isPinned }) => (
  <IconButton
    aria-label={isPinned ? 'Click to unpin' : 'Click to pin'}
    icon={<IconPin />}
    state={isPinned ? 'active' : 'inactive'}
    onClick={onClick}
    disabled={disabled}
  />
);
